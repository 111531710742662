import Icon from "@ant-design/icons";

const HomeSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 22V12H15V22" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

const DashboardSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
<path d="M9 3H4C3.44772 3 3 3.44772 3 4V11C3 11.5523 3.44772 12 4 12H9C9.55228 12 10 11.5523 10 11V4C10 3.44772 9.55228 3 9 3Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 3H15C14.4477 3 14 3.44772 14 4V7C14 7.55228 14.4477 8 15 8H20C20.5523 8 21 7.55228 21 7V4C21 3.44772 20.5523 3 20 3Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 12H15C14.4477 12 14 12.4477 14 13V20C14 20.5523 14.4477 21 15 21H20C20.5523 21 21 20.5523 21 20V13C21 12.4477 20.5523 12 20 12Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 16H4C3.44772 16 3 16.4477 3 17V20C3 20.5523 3.44772 21 4 21H9C9.55228 21 10 20.5523 10 20V17C10 16.4477 9.55228 16 9 16Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

const RevenuePlanningSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 5C17.5 5 16.2 6.4 16 7C12.5 5.5 5 6.7 5 12C5 13.8 5 15 7 16.5V20H11V18H14V20H18V16C19 15.5 19.7 15 20 14H22V10H20C20 9 19.5 8.5 19 8V5Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 9V10C2 11.1 2.9 12 4 12H5" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

const BudgetSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

const ReportingSvg = () => (
    <svg style={{marginBottom: -2.5}} width="24" height="24" viewBox="0 0 26 28" fill="currentColor">
        <path
            d="M24.8667 6.09132L18.8667 0.26484C18.7333 0.0662101 18.5333 0 18.2667 0H3.2C1.4 0 0 1.39041 0 3.17808V23.8219C0 25.5434 1.4 27 3.2 27H21.9333C23.6667 27 25.1333 25.6096 25.1333 23.8219V6.62101C25.1333 6.42237 25 6.22374 24.8667 6.09132ZM19.1333 2.84703L22.1333 5.76027H19.4C19.2667 5.76027 19.1333 5.62785 19.1333 5.49543V2.84703ZM21.9333 25.3447H3.2C2.4 25.3447 1.73333 24.6826 1.73333 23.8881V3.17808C1.73333 2.38356 2.4 1.72146 3.2 1.72146H17.4667V5.56164C17.4667 6.62101 18.3333 7.54795 19.4667 7.54795H23.4667V23.8219C23.4 24.6826 22.7333 25.3447 21.9333 25.3447Z"/>
        <path
            d="M18.4665 10.2559H6.39987C5.9332 10.2559 5.5332 10.6531 5.5332 11.1166C5.5332 11.5801 5.9332 11.9773 6.39987 11.9773H18.4665C18.9332 11.9773 19.3332 11.5801 19.3332 11.1166C19.3332 10.6531 18.9999 10.2559 18.4665 10.2559Z"/>
        <path
            d="M6.39987 16.7215H16.2665C16.7332 16.7215 17.1332 16.3242 17.1332 15.8607C17.1332 15.3973 16.7332 15 16.2665 15H6.39987C5.9332 15 5.5332 15.3973 5.5332 15.8607C5.5332 16.3242 5.9332 16.7215 6.39987 16.7215Z"/>
        <path
            d="M17.4665 20H6.39987C5.9332 20 5.5332 20.3973 5.5332 20.8607C5.5332 21.3242 5.9332 21.7215 6.39987 21.7215H17.3999C17.8665 21.7215 18.2665 21.3242 18.2665 20.8607C18.2665 20.3973 17.9332 20 17.4665 20Z"/>
    </svg>
);

const CoachingSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7L15 10L10 13V7Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H20C21.1046 17 22 16.1046 22 15V5C22 3.89543 21.1046 3 20 3Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 17V21" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 21H16" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

const GotoMarketSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 5V9" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 9H8C7.44772 9 7 9.44772 7 10V14C7 14.5523 7.44772 15 8 15H10C10.5523 15 11 14.5523 11 14V10C11 9.44772 10.5523 9 10 9Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9 15V17" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17 3V5" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18 5H16C15.4477 5 15 5.44772 15 6V12C15 12.5523 15.4477 13 16 13H18C18.5523 13 19 12.5523 19 12V6C19 5.44772 18.5523 5 18 5Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17 13V16" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 3V21H21" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
);

const SalesAndMarketingSvg = () => (
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 14C20.49 12.54 22 10.79 22 8.5C22 7.04131 21.4205 5.64236 20.3891 4.61091C19.3576 3.57946 17.9587 3 16.5 3C14.74 3 13.5 3.5 12 5C10.5 3.5 9.26 3 7.5 3C6.04131 3 4.64236 3.57946 3.61091 4.61091C2.57946 5.64236 2 7.04131 2 8.5C2 10.8 3.5 12.55 5 14L12 21L19 14Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 5L9.03998 7.96C8.8368 8.16171 8.67554 8.40162 8.5655 8.66593C8.45546 8.93023 8.3988 9.2137 8.3988 9.5C8.3988 9.7863 8.45546 10.0698 8.5655 10.3341C8.67554 10.5984 8.8368 10.8383 9.03998 11.04C9.85998 11.86 11.17 11.89 12.04 11.11L14.11 9.21C14.6288 8.73919 15.3044 8.47839 16.005 8.47839C16.7056 8.47839 17.3811 8.73919 17.9 9.21L20.86 11.87" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 15L16 13" stroke="#475467" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 18L13 16" stroke="#475467" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);  

const HomeIcon = () => <Icon component={HomeSvg}/>;
const DashboardIcon = () => <Icon component={DashboardSvg}/>;
const RevenuePlanningIcon = () => <Icon component={RevenuePlanningSvg}/>;
const BudgetIcon = () => <Icon component={BudgetSvg}/>;
const ReportingIcon = () => <Icon component={ReportingSvg}/>;
const CoachingIcon = () => <Icon component={CoachingSvg}/>;
const SalesAndMarketingIcon = () => <Icon component={SalesAndMarketingSvg}/>;
const GotoMarketIcon = () => <Icon component={GotoMarketSvg}/>;

const SliderMenu = [
  {
    title: "Home",
    key: "home",
    icon: <HomeIcon />,
    path: "/",
    isSubMenu: false,
    subMenu: [],
  },
  { 
    title: "Go to Market Intelligence",
    key: "goToMarket",
    icon: <GotoMarketIcon />,
    path: "/go-to-market",
    onlyAdminAccess : false,
    isSubMenu: false,
    subMenu: [],
  },
  { 
    title: "Sales & Marketing Alignment",
    key: "salesAndMarking",
    icon: <SalesAndMarketingIcon />,
    path: "/sales-and-marketing",
    onlyAdminAccess : false,
    isSubMenu: false,
    subMenu: [],
  },
  {
    title: "Revenue Planning",
    key: "revenuePlanning",
    icon: <RevenuePlanningIcon />,
    path: "/revenue-planning",
    isSubMenu: false,
    subMenu: [],
  },
  {
    title: "Budget & Campaigns",
    key: "budgetProgram",
    icon: <BudgetIcon />,
    path: "/budget-campaigns",
    isSubMenu: false,
    subMenu: [],
  },
  {
    title: "Dashboards",
    key: "dashboards",
    icon: <DashboardIcon />,
    path: "/dashboards",
    isSubMenu: true,
    subMenu: [
     
      { title: "Executive", key: "executive", path: "/executive" },
      { title: "Sales & Marketing Leadership", key: "salesandmarketingleadership", path: "/sales-and-marketing-leadership" },
      { title: "Google Analytics", key: "analytics", path: "/analytics" },
    ],
  },
  {
    title: "Coaching",
    key: "coaching",
    icon: <CoachingIcon />,
    path: "/coaching",
    isSubMenu: false,
    subMenu: [],
  }
];

export {SliderMenu};
