import React from "react";
import { Liquid } from "@ant-design/charts";

const LiquidChart = (props) => {
  let remainingPercentage = 0;
  let liquidChartText;
  let percentageNagative;
  if (props.plannedBudget || props.totalBudget) {
    if (props.totalBudget - props.plannedBudget >= 0) {
      liquidChartText = "Left to Plan";
      percentageNagative = false;
      remainingPercentage =
        (props.totalBudget - props.plannedBudget) /
        props.totalBudget;
        if(remainingPercentage ===1) {
          remainingPercentage = parseFloat(remainingPercentage)+parseFloat(0.00001)
        }

    } else {
      liquidChartText = "Overrun";
      percentageNagative = true;
      remainingPercentage =
        (props.plannedBudget - props.totalBudget) /
        props.plannedBudget;
        if(remainingPercentage ===1) {
          remainingPercentage = parseFloat(remainingPercentage)+parseFloat(0.00001)
        }
    }
  } else {
    liquidChartText = "No Data";
    remainingPercentage = 0;
  }
  var config = {
    percent: remainingPercentage,
    statistic: {
      title: {
        formatter: function formatter(_ref) {
          var percent = _ref.percent;
          const colour = (percent * 100).toFixed(0) > 59 ? "white" : "rgba(44,53,66,0.85)"
          return (
            '<span style="color:' + colour + ';font-size:10px">' +
            liquidChartText +
            "<span/>"
          );
        },
        style: function style(_ref) {
          var percent = _ref.percent;
          return { fill: percent > 0.65 ? "white" : "rgba(44,53,66,0.85)" };
        },
      },
      content: {
        style: {
          fontSize: 25,
          fill: "black",
          lineHeight: 1,
        },
        customHtml: (container, view, { percent }) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const nSign = percentageNagative ? "-" : "";
          const text = `${nSign}${(percent * 100).toFixed(0)}%`;
          const colour=(percent * 100).toFixed(0)>50?"white":"rgba(44,53,66,0.85)"
          return `<div style="width:${d}px;display:flex;align-items:center;justify-content:center;color: ${colour}">${text}</div>`;
        },
      },
    },
    color: ({ type }) => {
      if (percentageNagative) {
        return "#cb4154";
      }
      return "rgb(91, 143, 249)";
    },
  };
  return <Liquid {...config} color="#0F61DB"/>;
};

export default LiquidChart;
