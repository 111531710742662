import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, Drawer, Tooltip, Select, Modal, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { createChannel, updateChannel, setServerError } from "store/actions/budgetAction";
import {preDefinedChannelNamesTypesTitle} from "../../utility/Constants"

const CreateChannel = (props) => {
  const [form] = Form.useForm();
  const [isModelOpen,setIsModelOpen] = useState(false)
  const [editedData,setEditedData] = useState({})
  const { Option } = Select;
  const dispatch = useDispatch();
  const account = useSelector((state) => state.loginReducer.groups);
  const serverError = useSelector((state) => state.budgetReducer.serverError);
  let yearList = props.listOfYear
  let temp = props.isEdit ? "Editing" : (props.isCopy ? "Copying" : "Creating" )

    if(!props.isEdit && !props.isCopy) {
      form.setFieldsValue({
        channelType: "OTHERS",
      });
    } 
     
  useEffect(() => {
    const obj = props.channelObj;
    form.setFieldsValue({
      name: obj?.["channel-campaign"],
    });
    form.setFieldsValue({
      channelType: obj?.["channelType"],
    });
  }, [form, props.channelObj]) // eslint-disable-line

  const handleOk = () => {
    if (props.isEdit) {
      dispatch(updateChannel(editedData));
      setIsModelOpen(false)

    } else {
      delete editedData["id"];
      dispatch(createChannel(editedData));
      setIsModelOpen(false)

    }
  };

  useEffect(() => {
    if (serverError === "success") {
      form.resetFields();
      props.handleCancel();
      dispatch(setServerError(""))
    }
  }, [serverError]); // eslint-disable-line

  const onSubmit = () => {
    let name = form.getFieldsValue(["name"])['name']? form.getFieldsValue(["name"])['name'].trim() : ""
    if (name.length === 0) {
      form.setFieldsValue({ name: name })
    }
    form
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
          account: account[0],
          id: props.channelObj?.["id"],
        };
        setEditedData(formatedValues)
        setIsModelOpen(true)
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onCancelClick = () => {
    props.handleCancel();
    setIsModelOpen(false)
  
  }
  return (
    <>
      <Drawer
        title={
          props.isEdit
            ? <strong>Edit Channel</strong>
            : props.isCopy
              ? <strong>Copy Channel</strong>
              : <strong>Create New Channel</strong>
        }
        className='CreateNewChannel'
        width={416}
        onClose={props.handleCancel}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          requiredMark={"optional"}
        >
          <Form.Item
            name="name"
            label={
              <span>
                <strong>Add Channel Name&nbsp;</strong>
                <Tooltip title="Set a custom label for this channel" placement="left">
                  <InfoCircleOutlined style={{ float: "right" }} />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
            extra="A channel is a place where you can group marketing campaigns that
            are similar in nature."
          >
            <Input style={{marginBottom:10}} placeholder="Channel Name" className="general-input" />
          </Form.Item>

          <Form.Item
            name="channelType"
            label={
              <span>
                <strong>Select Channel Type</strong>
                <Tooltip title="Specify which category this channel belongs to" placement="left">
                  <InfoCircleOutlined
                    style={{ float: "right" }}
                    className="font-color-budget"
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
          <Select
            
              size="large"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select channel"
              optionFilterProp="children"
              key="channelType"
              // defaultValue="OTHERS"
              className="general-input-select"
            >
              {preDefinedChannelNamesTypesTitle &&
                preDefinedChannelNamesTypesTitle.length > 0 &&
                preDefinedChannelNamesTypesTitle.map((item, indexval) => {
                  return (
                    <Option key={indexval} value={item.type}>
                      {item.typeTitle}
                    </Option>
                  );
                })}
            </Select>
            
          </Form.Item>

        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display:"flex",
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <Button className="secondaryButton" onClick={props.handleCancel} style={{  marginRight: 8, width: "48%" }}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary" style={{  width: "48%" }} className="primaryButton">
            Save
          </Button>
        </div>
      </Drawer>
       <Modal
          className="deleteCampaignModal"
          visible={isModelOpen}
          closable={false}
          centered
          footer={[
            <Button
              style={{
                height: "40px",
              }}
              type="secondary"
              key="cancel"
              onClick={onCancelClick}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              size="large"
              onClick={handleOk}
            >
              Save
            </Button>
          ]}
        >
          <Typography.Title level={4} >
          {`${temp} this channel will affect the following budgets`}
        </Typography.Title>
        <Typography.Text className="delete-warning">
          {`Budgets : ${yearList}
              Continue?`}
          </Typography.Text>
        </Modal>
    </>
  );
};

export default CreateChannel;
