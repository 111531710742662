/*
Wrapper for stats reporting.

Currently this is just used to wrap new relic `noticeError`
*/

class Stats {
  error(err, attr = {}) {
    if (typeof window.newrelic == 'object') {
      window.newrelic.noticeError(err, attr);
    }
  }
}

export default new Stats();
