/* eslint-disable array-callback-return */
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  Menu,
  message,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  Tooltip,
  Typography
} from "antd";
import React , {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import {CloseCircleFilled, InfoCircleOutlined, LoadingOutlined, MenuFoldOutlined} from "@ant-design/icons";
import TransformImg from "../../assets/Images/mapping_transaform.svg";
import sales_marketing_module_custom_stage_preview from "../../assets/Images/sales_marketing_module_custom_stage_preview.png";
import verticalTripleDotImg from "../../assets/Images/vertical_triple_dot.svg";

import './CustomStages.css';
import Modal from "antd/lib/modal/Modal";
import PreviewInfo from "commons/PreviewInfo";
import { createSalesAndMarketingStage, deleteSalesAndMarketingStage, getSalesAndMarketingStages, saveCustomStageMappings, updateSalesAndMarketingStage } from "store/actions/SalesAndMarketingAction";
import { setLoader } from "store/actions/reportAction";

export const CustomStagesStrong = styled.strong`
  ${({disabled}) => !!disabled ? 'color: #A4A4A4;' : ''}
`;

export const TableParagraph = styled(Typography.Paragraph).attrs({
  // italic: true,
})`
  &, & > * {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
  ${({disabled}) => !!disabled ? 'color: #A4A4A4;' : ''}
`

export const ExpandTextButton = styled(Button).attrs({
  type: 'link',
})`
  font-style: italic !important;
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 20px;
  height: unset !important;
`;

const IsLastSwitch = styled(Switch)`
  width: 40px !important;
  margin-left: 0 !important;
`;


const createDrawerFormTitleStyles = {
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '21px',
  color: '#303030',
  marginBottom: '10px',
};

const CustomStages = (
  {
    isLoading,
    form,
    tableCustomData,
    fiscalYearList,
    account,
    dispatch,
    salesAndMarketingStages,
    customAvailableMappings,
    crmConnections,
    customSavedMappings,
    hasAnyCRMConnected,
    getStagesLoader,
    MappingsDataLoader,
    ...props
  }
) => {

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [TableData, setTableData] = useState([])
  const [isDeleteStageModelOpen, setisDeleteStageModelOpen] = useState(false)
  const [stageToDelete, setstageToDelete] = useState({})
  const [stageToEdit, setstageToEdit] = useState({})
  const [addNewStageModal, setaddNewStageModal] = useState(false)
  const [editMode, seteditMode] = useState(false)
  const [savedData,setSavedData] = useState([])

  const { ConnectedCRMs } = useSelector((state) => state.reportReducer);
  //only showing connected CRMS into custom Stage drawer
  const connected_crms_names = Object.keys(ConnectedCRMs);

  const showDrawer = () => {
    setIsDrawerVisible(true)
  };

  const onDrawerClose = () => {
      setIsDrawerVisible(false)
  };

  const [isLastChangedShowWarning, setisLastChangedShowWarning] = useState(false)
  const onChange = () => {
    setisLastChangedShowWarning(true)
  }

  function TagRender(props,isEditable = false) {
    const {closable, onClose, value, label} = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={isEditable ? "#0F61DB" : label === "Hubspot" ? "#ff7a59" : "#179cd7"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          fontSize: 14,
          marginRight: 10,
          marginTop: 3,
          marginBottom: 3,
          padding: "5px 10px",
          borderRadius: "4px",
        }}
        closeIcon={<CloseCircleFilled style={{color: "#fff"}}/>}
      >
        {isEditable ? label : customAvailableKeyValuePair[value]}
      </Tag>
    );
  }

  const [customAvailableKeyValuePair, setcustomAvailableKeyValuePair] = useState({})
  const [getData,setData] = useState({})

  useEffect(() => {
   let dataObj = {}
  Object.entries(customAvailableMappings).map(([key,value])=> {
    if(value && value.values.length>0){
    dataObj[value?.title] = {type : value?.type , value : value?.values}
    }
  })
  delete dataObj[""]
  delete dataObj["undefined"]
  setData(dataObj)
  let tempCustomObj = {}
  Object.entries(dataObj).map(([key , value]) => {
    value.value.map(el => {
      let key = Object.values(el)[0]
      let value = Object.keys(el)[0]
      tempCustomObj[key] = value
    })
  })
  setcustomAvailableKeyValuePair(tempCustomObj);
  },[customAvailableMappings])


  const [customSavedMappingswithoutPlatform, setcustomSavedMappingswithoutPlatform] = useState({})

  useEffect(() => {
    let dataObj = {}
    Object.keys(customSavedMappings).map(key => {
      dataObj[key] = customSavedMappings?.[key]?.map(item => item.value)
    })
    setcustomSavedMappingswithoutPlatform(dataObj)
  },[customSavedMappings])



  useEffect(()=>{
      let funnelStageMappingsData = {}
      fiscalYearList?.map(year => {
        year?.baselineMetrics?.items?.map(segment =>{
          segment?.funnelConfigs?.items?.map(funnel => {
            funnel?.stages?.items?.map(stage =>{
              if(stage?.salesAndMarketingAlignment?.id){
                let dataobj = {
                  name: segment?.name,
                  year: year?.year
                }
                funnelStageMappingsData[stage?.salesAndMarketingAlignment?.id] = funnelStageMappingsData[stage?.salesAndMarketingAlignment?.id] ? [...funnelStageMappingsData[stage?.salesAndMarketingAlignment?.id],dataobj] : [dataobj]
              }
            })
          })
        })
      })
      let data = [...tableCustomData]
      data?.map(row => {
        let usage = funnelStageMappingsData[row?.id]
        row.usage = usage ? usage : []
        let mappingsData = []
        row?.selectedChannelStages.map((item) => {
          item?.forEach(value => !mappingsData.includes(value) && mappingsData.push(value))
        })
        row.selectedChannelStagesCustom = mappingsData
      })
      setTableData(data)

  },[fiscalYearList,salesAndMarketingStages, tableCustomData])


const closeDrawer = () => {
  setaddNewStageModal(false);
  form.resetFields();
  setisLastChangedShowWarning(false)
}
const handleAddstage = async (value) => {
  // dispatch(setLoader(true))
  let inputdata = {
      account:account[0],
      disabled : false,
      isDefault:false,
      order:-1,
      ...value
  }
  let stagesToUpdate = inputdata?.stages
  delete inputdata.stages

  if(editMode){


    inputdata.id = stageToEdit.id

    if(stageToEdit?.isLast === inputdata?.isLast){
      dispatch(updateSalesAndMarketingStage(inputdata, false)).then(async () => {
          await addCustomMappings(inputdata?.id,stagesToUpdate)
          message.success("Stage updated successfully")

      }).catch(err => {
          message.error("Something went wrong")
      }).then(()=>{
        closeDrawer()

      })

    }
    else{

      // LAST STAGE CHANGED

      // IF LAST IS TRUE
      if(inputdata.isLast){
          let lastStage = salesAndMarketingStages?.find(item => item?.isLast)
          if(lastStage){
              let lastStageUpdateData = {
                  id:lastStage?.id,
                  isLast:false
              }
              await dispatch(updateSalesAndMarketingStage(lastStageUpdateData, false))
          }
          dispatch(updateSalesAndMarketingStage(inputdata, false)).then(async () => {
              await addCustomMappings(inputdata?.id,stagesToUpdate)

              message.success("Stage updated successfully")
          }).catch(err => {
              message.error("Something went wrong")
          }).then(()=>{
            closeDrawer()

          })
      }
      // IF EDITED STAGE IS REMOVED FROM LAST
      else{
          let maxButNotDisabledStage = 0
          salesAndMarketingStages?.map(item => {
              if(!item?.disabled && item?.order > maxButNotDisabledStage && item?.id !== stageToEdit?.id){
                  maxButNotDisabledStage = item?.order
              }
          })
          let highestOrderStage = salesAndMarketingStages.find(item => item?.order === maxButNotDisabledStage)
          if(highestOrderStage){
              let lastStageUpdateData = {
                  id:highestOrderStage?.id,
                  isLast:true
              }
              await dispatch(updateSalesAndMarketingStage(lastStageUpdateData, false))
              dispatch(updateSalesAndMarketingStage(inputdata, false)).then(async() => {
                    await addCustomMappings(inputdata?.id,stagesToUpdate)

                  message.success("Stage updated successfully")
              }).catch(err => {
                  message.error("Something went wrong")
                }).then(()=>{
                  closeDrawer()

                })

              }else{
                message.error("Something went wrong")
                closeDrawer()
          }

      }
    }
  }
  else{
    if(inputdata.isLast){
      let lastStage = salesAndMarketingStages?.find(item => item?.isLast)
      if(lastStage){
          let lastStageUpdateData = {
              id:lastStage?.id,
              isLast:false
          }
      await dispatch(updateSalesAndMarketingStage(lastStageUpdateData, false))
      }
    }
    else{
        inputdata.isLast = false
    }
    dispatch(createSalesAndMarketingStage(inputdata, false)).then(async (res) => {
      await addCustomMappings(res?.data?.createSalesAndMarketingAlignment?.id, stagesToUpdate);
      message.success("Stage created successfully")

    }).catch(err=> {
      console.log(err);
      message.error("Something went wrong")
    }).then(()=>{
      closeDrawer()

    })

  }

};
const addCustomMappings = async (id,stagesToUpdate) => {
  if(hasAnyCRMConnected){
  let dataObj = {}
Object.keys(customAvailableMappings).map((key) => {
  let b = key.split(" ")
  b[1] = String(b[1]).toLowerCase()
  if(Array.isArray(customAvailableMappings?.[key])){
    customAvailableMappings[key]?.map((e) => {
      if(stagesToUpdate?.includes(e[Object.keys(e)?.[0]])){
        let id = e[Object.keys(e)?.[0]]
        dataObj[b[0]] = dataObj[b[0]] ? dataObj[b[0]] : {}
        dataObj[b[0]].platform_id = crmConnections[b[0]]
        dataObj[b[0]].values = dataObj[b[0]]?.values ? [...dataObj[b[0]]?.values, {id, type:b[1]}] : [{id, type:b[1]}]
      }
    })
  }


})
let crm = {}
Object.entries(getData).map(([key,value]) => {
  crm[key] = []
 value.value.map((i) => {
   if(savedData.includes(Object.values(i)[0])){
   }
 })
})

Object.keys(dataObj).map(key => {
  dataObj[key] = [dataObj[key]]
})
let inputData = {
  account:account[0],
  virtualStageId:id,
  ...intCrmMap,
  ...dataObj
}

dispatch(saveCustomStageMappings(inputData)).then(res=> {
  dispatch(getSalesAndMarketingStages(account))
})
  }
  else{
    dispatch(setLoader(false))
  }
}

const handleEditStage = (record) => {
  seteditMode(true)
  setstageToEdit(record);
        const {Stages: name, definition, howitcomes, thingstokeepinmind, isLast,showPipelineCoverage} = record;
        form.setFieldsValue({
            name,
            definition,
            howitcomes,
            thingstokeepinmind,
            stages: hasAnyCRMConnected ? customSavedMappingswithoutPlatform[record?.id] : [],
            isLast,
            showPipelineCoverage
        });
        setaddNewStageModal(true);
        onDrawerClose()
}
// TODO :Need to check all delete scenarios
const handleDeleteStage = async (record) => {
  let inputData = {
    id:record?.id
  }
  await dispatch(deleteSalesAndMarketingStage(inputData)).then(() => {
    message.success("Stage deleted successfully")
  }).catch(err => {
    console.log("Error", err);
    message.error("Something went wrong")
  }).then(() => {
    setisDeleteStageModelOpen(false);
    setstageToDelete({})

  })
}

const [stageToDisable, setstageToDisable] = useState({})
const [intCrmMap,setIntCrmMap] = useState([])

const handleHubSpoteData = (data) => {
    let textData= {}
  Object.entries(getData).map(([item,value]) => {let splitData = item.split(" ")[0]
  textData[splitData] = [{"platform_id" : crmConnections[splitData], values : []}]})
  Object.entries(getData).map(([item,value]) => {
    value.value !== undefined &&   value.value.map(el => {

      data.map(i => {
        if(Object.values(el)[0] === i){
          let dataObj = item.split(" ")[0]

          textData[dataObj][0]["values"].push({type :value.type , id : Object.values(el)[0] })
        }

      })
    })
  })
  setIntCrmMap(textData)
}


const onDisableStage = async (record) => {
  dispatch(setLoader(true))
  let inputData = {
      id:record.id,
      disabled:!record?.disabled
  }
  dispatch(updateSalesAndMarketingStage(inputData,false)).then(async (res) => {
    message.success(`Stage ${res?.data?.updateSalesAndMarketingAlignment?.disabled ? 'disabled' : 'enabled'} successfully`)
}).catch(err => {
    message.error("Something went wrong")
}).then(()=>{
    setstageToDisable({})

})
}

  return (
    <>

        {((props.userPermission?.["salesMarketing.customStages"] === "edit") || (props.account?.[2] === "admin" && (props.viewType !== "companyadmin" && props.viewType !== "companycontributor") )) && <div
            className="salesMarketing"
            data-tour = "tour_customStages"
            key="content"
            style={{
                background: "#fff",
                margin: "18px 0 0px 0px",
                width: "100%",
                borderRadius: 7,
                padding: 30,
            }}
            >
            {/* {isLoading && <div className="loading">Loading</div>} */}
            <Row >
                <div className="salesMarketingRow" style={{textAlign: 'center', width: '100%'}}>
                    <Typography.Text style={{fontSize: 24, fontWeight: 400}}>
                        Manage{' '}
                        <span style={{color: '#00abc2'}}>Custom stages</span>{' '}
                        with Demand Gen
                    </Typography.Text>
                    {props.userPermission?.["salesMarketing"] === "edit" ? <Button
            color='primary'
            type='secondary'
            style={{
              border: 'none',
              boxShadow: 'none',
              position: 'absolute',
              right: 0,
              transform: 'translate(-50%)',
            }}
            onClick={() => {
              setaddNewStageModal(true);
              seteditMode(false);
            }}
            className="createNewStageBtn"

          >
              <span
                style={{verticalAlign: 'middle', textDecoration: "underline", fontSize: 18, fontWeight: 500}}
              >
                + Create New
              </span>
          </Button>:<Button
            className="create-btn"
            color='primary'
            type='secondary'
            style={{
              border: 'none',
              boxShadow: 'none',
              position: 'absolute',
              right: 0,
              transform: 'translateX(-50%)',
            }}
            title = "View only permission"
          >
              <span
                style={{verticalAlign: 'middle', textDecoration: "underline", fontSize: 18, fontWeight: 500, color:"#727272"}}
              >
                + Create New
              </span>
          </Button>}
                </div>
            </Row>
            {tableCustomData && tableCustomData?.length > 0 ?
            (<div className="table-wrapper">
                    <div className="scrollBox">
                        <div style={{display:"flex", width:`${isDrawerVisible ? "calc(96% - 299px)" : "100%"}`, overflowX:"scroll"}}>
                        {/* default stages colummn  */}
                        <div className="defaultStages column"  >
                            <div className="header" style={{background: '#E9F2FF', display:'flex',justifyContent:"space-between", alignItems:"center"}}>
                                <strong style={{fontSize: 18, fontWeight: 600}} >Custom Stages</strong>
                                <Tooltip className="tooltip" placement="leftTop" color="#fff" key="#fff"
                            title={
                                <div style={{width:'100%', padding:"0px 20px"}}>
                                    <div className="hint-title hint-title-last-stage"> Funnel last stage</div>
                                    <div className="hint-title hint-title-show-pipeline"> Show pipeline coverage</div>
                                </div>
                            }>
                                <InfoCircleOutlined />
                                </Tooltip>
                            </div>

                                {tableCustomData?.map((item,index) => {
                                return (<div className="data" key={index} style={{display:"flex", justifyContent:"space-between", padding:"20ox 0px"}}>
                                    <CustomStagesStrong className="ant-table-thead" disabled={item.disabled}>{item.Stages}</CustomStagesStrong>
                                    <div  style={{display:"flex"}}>
                                    {item.isLast && <Tooltip title="Funnel Last Stage "><div className="sales-and-marketing-stages-dot last-stage-text" ></div></Tooltip>}
                                    {item.showPipelineCoverage && <Tooltip title="Show pipeline coverage "><div className="sales-and-marketing-stages-dot show-pipeline-text" style={{marginLeft:10}}></div></Tooltip>}
                                    </div>
                            </div>)
                            })}


                        </div>

                        {/* Transforming arrows  */}
                        <div className="arrows">
                                {/* 1st will be hidden  */}

                            <div className="data" style={{height: 48}}>
                                <img src={TransformImg} style={{visibility: 'hidden'}} alt=""/>
                                </div>
                                {tableCustomData?.map((item,index) => {
                                return ( <div className="data" key={index}>
                                <img src={TransformImg} alt=""/>
                            </div>)
                            })}

                        </div>
                        <div  style={{display:"flex"}}>
                            {/* defination column  */}
                            <div className="defination column mr-10">
                                <div className="header" style={{background: '#030D1D', color: 'white'}}>
                                    <strong style={{fontSize: 18, fontWeight: 500}} >Definition</strong>
                                    </div>
                                    {tableCustomData?.map((record,index) => {
                                        let data = record?.definition
                                    return (<div className="data" key={index}>
                                    <TableParagraph
                                            disabled={record?.disabled}
                                            ellipsis={!record?.definitionExpanded && data?.length >= '100' && {
                                                suffix: <ExpandTextButton
                                                    // onClick={() => {
                                                    //     record.definitionExpanded = true;
                                                    //     settableCustomData([...tableCustomData]);
                                                    // }}
                                                >
                                                    <Tooltip title={data}>
                                                   Learn More
                                                 </Tooltip>
                                                </ExpandTextButton>,
                                                rows: 3,
                                            }}
                                        >
                                            {data}{' '}
                                            {/* {record.definitionExpanded && (
                                                <ExpandTextButton
                                                    onClick={() => {
                                                        record.definitionExpanded = false;
                                                        settableCustomData([...tableCustomData]);
                                                    }}
                                                >
                                                    Learn Less
                                                </ExpandTextButton>
                                            )} */}
                                        </TableParagraph>
                                </div>)
                                })}
                            </div>

                            {/* how it comes in column  */}
                            <div className="howItComesIn column mr-10">
                                <div className="header" style={{background: '#030D1D', color: 'white'}}>
                                    <strong style={{fontSize: 18, fontWeight: 500}} >How it comes in</strong>
                                    </div>
                                    {tableCustomData?.map((record, index) => {
                                        let data = record?.howitcomes
                                    return (<div className="data" key={index}>
                                    <TableParagraph

                    disabled={record?.disabled}
                    ellipsis={!record?.howItComesInExpanded && data?.length >= '100' && {
                        suffix: <ExpandTextButton
                            // onClick={() => {
                            //     record.howItComesInExpanded = true;
                            //     settableCustomData([...tableCustomData])
                            // }}
                        >
                          <Tooltip title={data}>
                            Learn More
                          </Tooltip>
                        </ExpandTextButton>,
                        rows: 3,
                    }}
                >
                    {data}{' '}
                    {/* {record?.howItComesInExpanded && (
                        <ExpandTextButton
                            onClick={() => {
                                record.howItComesInExpanded = false;
                                settableCustomData([...tableCustomData])
                            }}
                        >
                            Learn Less
                        </ExpandTextButton>
                    )} */}
                </TableParagraph>
                                </div>)
                                })}
                            </div>

                            {/* things to keep in mind column  */}
                            <div className="thingToKeepInMind column">
                                <div className="header" style={{background: '#030D1D', color: 'white'}}>
                                    <strong style={{fontSize: 18, fontWeight: 500}} >Things to keep in mind</strong>
                                    </div>

                                {tableCustomData?.map((record,index) => {
                                        let data = record?.thingstokeepinmind
                                    return (<div className="data" key={index}>
                                    <TableParagraph

                    disabled={record?.disabled}
                    ellipsis={!record?.howItComesInExpanded && data?.length >= '100' && {
                        suffix: <ExpandTextButton
                            // onClick={() => {
                            //     record.howItComesInExpanded = true;
                            //     settableCustomData([...tableCustomData])
                            // }}
                        >
                           <Tooltip title={data}>
                            Learn More
                            </Tooltip>
                        </ExpandTextButton>,
                        rows: 3,
                    }}
                >
                    {data}{' '}
                    {/* {record?.howItComesInExpanded && (
                        <ExpandTextButton
                            onClick={() => {
                                record.howItComesInExpanded = false;
                                settableCustomData([...tableCustomData])
                            }}
                        >
                            Learn Less
                        </ExpandTextButton>
                    )} */}
                </TableParagraph>
                                </div>)
                                })}

                            </div>
                        </div>
                        </div>
                        <div className={`${isDrawerVisible  ? "CRMMapping" : "CRMMappingClosed"} mapping-drawer`}  >
                            <div className="header" style={{background: '#E9F2FF', display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <strong style={{fontSize: 18, fontWeight: 600}}>CRM Mapping</strong>
                                <Tooltip className="tooltip" placement="leftTop" color="#fff" key="#fff"
                            title={
                                <div style={{width:'100%', padding:"0px 20px"}}>
                                    {connected_crms_names.includes('Hubspot') && <div className="hint-title hint-title-hubspot"> Hubspot</div>}
                                    {connected_crms_names.includes('Salesforce') &&<div className="hint-title hint-title-salesforce"> SalesForce</div>}
                                </div>
                            }>
                                <InfoCircleOutlined />
                                </Tooltip>
                            </div>

                            {tableCustomData?.map((record, index) => (<Row className="data" key={index} style={{width: "100%", padding: '0 5px'}}>
                            <Tooltip title={hasAnyCRMConnected ? "" : "Please connect CRM to get actual data"}>
                            {MappingsDataLoader ?
                                <Skeleton paragraph={{rows:1}} active style={{width:'100%'}} />
                            :
                            <Select
                                    mode="multiple"
                                    showArrow={false}
                                    tagRender={TagRender}
                                    style={{width: "100%"}}
                                    size="large"
                                    placeholder="Please connect CRM to get actual data"
                                    allowClear
                                    optionFilterProp="children"
                                    value={hasAnyCRMConnected  ? customSavedMappings[record?.id]?.filter(obj => connected_crms_names.includes(obj.label)) : []}
                                    disabled

                                >
                                </Select>
                                }
                            </Tooltip>
                            </Row>))}

                            {/* </Drawer> */}
                        </div>
                        <div className="arrows fixed" >

                                <div className="data" style={{ height: 48 }}>

                                <Tooltip title="CRM Mapping">
                                    <Button
                                    color='primary'
                                    type='secondary'
                                    style={{
                                        border: 'none',
                                        boxShadow: 'none',
                                        fontWeight: 500,
                                        fontSize: 16,
                                    }}
                                    onClick={isDrawerVisible ? onDrawerClose : showDrawer}
                                    icon={<MenuFoldOutlined style={{fontSize: '22px' , transition: '.5s' , transform: isDrawerVisible ? "rotate(180deg)" : "none"}} />}
                                />
                                </Tooltip>
                            </div>

                                {TableData?.map((record,index) => {return (
                                    props.userPermission?.["salesMarketing"] === "edit" &&
                                <div className="data" key={index} >

                    <div >
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                <Menu.Item key='disable-stage' onClick={() => {setstageToDisable(record);}} disabled={record.isLast}>
                                <Tooltip title={record.isLast && "Can not disable last stage"} >
                                    {record.disabled ? 'Enable Stage' : 'Disable Stage'}
                                </Tooltip>

                                </Menu.Item>
                                <Menu.Item disabled={record.disabled} key='edit-stage' onClick={() => handleEditStage(record)}>
                                    Edit Stage
                                </Menu.Item>
                                <Menu.Item key='delete-stage' disabled={record.isLast} onClick={() => { setstageToDelete(record); setisDeleteStageModelOpen(true)}}>
                                <Tooltip title={record.isLast && "Make other stage as last to delete this stage"} >
                    Delete Stage

                </Tooltip>
                </Menu.Item>
                            </Menu>
                        }
                    >
                        <div style={{textAlign: 'center'}}>
                            <img
                                src={verticalTripleDotImg}
                                alt="dropdown"
                                title="Click to open dropdown"
                                style={{cursor: "pointer"}}
                            />
                        </div>
                    </Dropdown>
                                        </div>
                                        </div>

                )})}

                        </div>
                    </div>
            </div>)
            :
            getStagesLoader ?
            (<Row gutter={[30,30]} style={{marginTop:20}}>
              <Col span={8}>
     <Skeleton active paragraph={{ rows: 10 }}/>

              </Col>
              <Col span={8}>
     <Skeleton active paragraph={{ rows: 10 }}/>

              </Col>
              <Col span={8}>
     <Skeleton active paragraph={{ rows: 10 }}/>

              </Col>
     </Row>)

            :

            (<Empty
            style={{marginTop:20}}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  Custom stages not found
                </span>
              }
            >
              <Button type="link" onClick={() => {
              setaddNewStageModal(true);
              seteditMode(false);
            }}>Create Now</Button>
            </Empty>)
            }
        </div> }

        {/* {props.userPermission?.["salesMarketing.customStages"] === "preview"  && <div data-tour = "tour_customStages"><PreviewInfo img = {sales_marketing_module_custom_stage_preview} text = "Upgrade to View"  centerStyle = {{top:"50%", padding:20, marginLeft:"-25%"}} /> </div>} */}


          <Drawer
            title={
              <strong>
                {editMode ? "Edit" : "Add"} Stage {isLoading && <LoadingOutlined style={{marginLeft:10}} />}
              </strong>
            }
            width={416}
            onClose={() => {setaddNewStageModal(false);setisLastChangedShowWarning(false)}}
            visible={addNewStageModal}
            maskClosable={false}
            footer={[]}
            style={{borderRadius: "20px"}}
            className="Create-mapping-stage"
          >
            {" "}
            <div className="delete-modal-body">

              <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                autoComplete="off"
                requiredMark={false}
                style={{width: "100%"}}
                onFinish={handleAddstage}
                // onSubmitCapture={(e) => handelAddstage(e)}
              >

                  <Form.Item
                    name="name"
                    label={
                      <span>
                        <strong>Stage name&nbsp;</strong>
                        <Tooltip title="Stage names set here are referenced throughout the platform" placement="left">
                          <InfoCircleOutlined style={{ float: "right" }} />
                        </Tooltip>
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "This field can not be empty!",
                      },
                    ]}
                  >
                      <Input placeholder="Enter stage name" size="large"/>
                  </Form.Item>

                <div style={createDrawerFormTitleStyles}>Definition</div>
                <Form.Item
                  name="definition">
                  <Input.TextArea
                    autoSize={{minRows: 3, maxRows: 7}}
                            style={{fontSize:14}}
                            placeholder="Enter stage definition"
                  />
                </Form.Item>

                <div style={createDrawerFormTitleStyles}>How It Comes In</div>
                <Form.Item
                  name="howitcomes">
                  <Input.TextArea
                    autoSize={{minRows: 3, maxRows: 7}}
                            style={{fontSize:14}}
                            placeholder="Enter stage details how it comes in"
                  />
                </Form.Item>

                <div style={createDrawerFormTitleStyles}>Things To Keep In Mind</div>
                <Form.Item
                  name="thingstokeepinmind"
                >
                  <Input.TextArea
                    autoSize={{minRows: 3, maxRows: 7}}
                            style={{fontSize:14}}
                            placeholder="Enter stage details things to keep in mind"
                  />
                </Form.Item>

                <Form.Item
                        label={
                            <span>
                            <strong>Show Pipeline Coverage</strong>
                            <Tooltip title="Show pipeline coverage metrics for this stage. This is recommended for opportunity/deal-specific stages only." placement="left">
                                <InfoCircleOutlined style={{ float: "right" }} />
                            </Tooltip>
                            </span>
                        }
                        name="showPipelineCoverage"
                        valuePropName='checked'>

                            <IsLastSwitch/>
                    </Form.Item>

                <Form.Item
                    label={
                      <span>
                        <strong>Funnel Last Stage&nbsp;</strong>
                        <Tooltip title="Only one stage should be set as your last stage, impacting funnels in Revenue Planning" placement="left">
                          <InfoCircleOutlined style={{ float: "right" }} />
                        </Tooltip>
                      </span>
                    }
                    extra={
                      <>
                            {isLastChangedShowWarning &&
                              <div style={{fontSize: 12, color: 'red'}}>Your existing last stage will be replaced with this stage upon Update</div>
                            }
                            <div>Please note that changing your last stage will affect any existing funnels within Revenue Planning</div>
                            </>
                    }
                    name="isLast"
                    valuePropName='checked'>

                    <IsLastSwitch onChange={onChange}/>

                </Form.Item>

                  <Form.Item name="stages"
                    label={
                      <span>
                        <strong>CRM Mapping&nbsp;</strong>
                        <Tooltip title="If you have connected your CRM, you may map stages here" placement="left">
                          <InfoCircleOutlined style={{ float: "right" }} />
                        </Tooltip>
                      </span>
                    } >
                      <Select
                        mode="multiple"
                        showArrow
                        tagRender={e => TagRender(e, true)}
                        style={{width: "100%"}}
                        size="large"
                        placeholder={!hasAnyCRMConnected?"Please connect CRM to get actual data":"Select CRM stage to map to this stage"}
                        allowClear
                        optionFilterProp="children"
                        disabled={!hasAnyCRMConnected}
                        onChange={(e) => {
                          setSavedData(e)
                          handleHubSpoteData(e)
                        }
                        }

                      >
                        {
                          Object.keys(getData)?.map((key,index) => (
                           key !== "undefined" && key !== "" &&
                           <Select.OptGroup label={key}>
                              {
                                getData?.[key]?.value?.map((value,index) => {
                                let obj = Object.keys(value)
                                return(
                                  <Select.Option className="custom-option" value={value[obj[0]]}  >{Object.keys(value)?.[0]}</Select.Option>
                              )})}
                            </Select.OptGroup>
                          ))
                        }
                      </Select>
                  </Form.Item>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    zIndex: 2,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right",
                    display: "flex",
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    ghost
                    onClick={() => {
                      setaddNewStageModal(false);
                      form.resetFields();
                    }}
                    style={{
                      borderColor: "#0F61DB",
                      color: "#0F61DB",
                      fontSize: 18,
                      marginRight: 8,
                      width: "48%",
                    }}
                    disabled={isLoading}

                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    style={{fontSize: 18, width: "48%"}}
                    htmlType="submit"
                    disabled={isLoading}
                  >
                    {editMode ? "Update" : "Save"}
                  </Button>
                </div>
              </Form>
            </div>
          </Drawer>

          <Modal
          visible={isDeleteStageModelOpen}
          closable={false}
          centered
          footer={null}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
              {
                stageToDelete?.usage && stageToDelete?.usage?.length > 0 ?
                <>
                <Typography.Title level={5} >In order to delete {<span style={{color:"#0F61DB"}}>{ stageToDelete?.Stages }</span>} stage it must first be removed from These segments funnels (via Configure Stages)</Typography.Title>


                <div style={{width:"100%"}}>
                {stageToDelete?.usage?.map((data,index) =>
                  <div key={index}><Typography.Text style={{fontSize:16}}>{data?.name} (FY {data?.year})</Typography.Text></div>
                  )}
                  </div>
                </>
                :
                <>
                <Typography.Title level={5} >Are you sure you want to delete {<span style={{color:"#0F61DB"}}>{ stageToDelete?.Stages }</span>} Stage?</Typography.Title>

                </>
              }




            <div className="delete-modal-footer">
            <Button
                size="large"
                type="primary"
                onClick={() => {
                  handleDeleteStage(stageToDelete);
                }}
                loading={isLoading}
                disabled={stageToDelete?.usage && stageToDelete?.usage?.length > 0 }

              >
                Yes
              </Button>
              <Button
                type="primary"
                size="small"
                ghost
                disabled={isLoading}
                onClick={() => {setisDeleteStageModelOpen(false); setstageToDelete({})}}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                >
                No
              </Button>

            </div>
          </div>
        </Modal>

        <Modal
          visible={Object.keys(stageToDisable)?.length > 0}
          closable={false}
          centered
          footer={null}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
              {
                <>
                <Typography.Title level={5} >Are you sure you want to {stageToDisable?.disabled ? 'enable' : 'disable'}  {<span style={{color:"#0F61DB"}}>{ stageToDisable?.Stages }</span>} stage?</Typography.Title>

                </>
              }




            <div className="delete-modal-footer">
            <Button
                size="large"
                type="primary"
                onClick={async () => {
                  await onDisableStage(stageToDisable);
                  // setstageToDisable({});
                }}
                loading={isLoading}

              >
                Yes
              </Button>
              <Button
                type="primary"
                size="small"
                ghost
                disabled={isLoading}
                onClick={() => {setstageToDisable({})}}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
                >
                No
              </Button>

            </div>
          </div>
        </Modal>

    </>
  );
};

export default CustomStages;
