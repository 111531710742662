import React from 'react'

import thankyouImg from "assets/Images/plan-selected-success-fg.svg"
import { Button, Space, Typography } from 'antd'
import { useHistory } from 'react-router'
import logo from "assets/Images/wordmark_white.svg"
import { Auth } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { setPermission } from 'store/actions/loginAction'




const PlanSelected = (props) =>  {
    const history = useHistory()
    const dispatch = useDispatch()

    const SETPermission = async (session) =>{
      await dispatch(setPermission(session.idToken.payload))
    }
    const refreshTokenManualy = async () =>{
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

      cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
        if (session) {
          if(session?.idToken?.payload){
            await SETPermission(session)
            window.location.href = "/"

          }
        }
      });
    }
    return (
        <div className="thankyou-page" >
            <div style={{
                justifyContent: "center", width:"100%", display:"flex", paddingBottom:40}}>

                <div className="company_logo" style={{ height: "auto", marginTop: "10px" }}>
                <img
                    src={logo}
                    height={20}
                    alt="XFactor.io :: Demand Generation "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        history.push("/");
                    }}
                />
            </div>
            </div>

            <div className="thankyou-modal">
                <Space direction="vertical" align="center" size={10} style={{ paddingBottom: 20, paddingTop: 20, justifyContent: "center", alignContent: "center", width: "100%" }} >
            <img src={thankyouImg} alt="" />
            <Typography.Text strong style={{ fontSize: 20 }}>Thank you!</Typography.Text>
            <div style={{textAlign:"center"}}>
          <Typography.Text style={{ fontSize: 16, textAlign: "center" }}>Your subscription has been added.</Typography.Text>
          </div>
            <Button size="large" type="primary" className="btn-primary" onClick={() => {
                refreshTokenManualy()
          }}>Get Started</Button>
            </Space>
            </div>
        </div>
    )
}

export default PlanSelected
