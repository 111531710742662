/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Tooltip,
  Typography,
  Skeleton,
  Radio,
  Select,
  Button,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import BudgetPieChart from "../../commons/PieChart";
import BudgetLiquidChart from "../../commons/LiquidChart";
import { nFormatter, numberWithCommas } from "utility/context/CommonMethods";
import { setProspects } from "store/actions/budgetAction";
import { convertReverseArray } from "utility/context/CommonMethods";
import leadAnalysis from "assets/Images/lead_analysis.svg";
import revenueAnalysis from "assets/Images/revenue_analisys.svg";
import { Gauge } from "@ant-design/plots";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  DollarCircleOutlined,
  MoreOutlined,
  RightOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const BudgetSummaryNew = (props) => {
  const { userPermission, groups: account } = useSelector(
    (state) => state.loginReducer
  );
  const { getBudgetLoader, nameOfLeadObj } = props;
  const [totalRevenuegoal, settotalRevenuegoal] = useState();
  const [totalLeadsGoal, settotalLeadsGoal] = useState();
  const { Option } = Select;
  const [checkedItems, setCheckedItems] = useState({
    Q1: true,
    Q2: true,
    Q3: true,
    Q4: true,
  });
  const [prospectCount, setProspectCount] = useState(0);
  const dispatch = useDispatch();
  const { BudgetProfile, ChannelCampaignPlannedTotal, CampaignList } =
    useSelector((state) => state.budgetReducer);
  const [plannedLeadImpact, setPlannedLeadImpact] = useState();
  const [plannedBudgetImpact, setPlannedBudgetImpact] = useState();
  const [filteredBudgetAmount, setFilteredBudgetAmount] = useState();
  const [filteredBudgetAmountRemaining, setFilteredBudgetAmountRemaining] =
    useState();
  const { marketingSourcedCount, funnelStages } = useSelector(
    (state) => state.revenueReducer
  );
  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const [actualRevenuQ, setActaulRevenueQ] = useState();
  const [actualLeadsQ, setActaulLeadsQ] = useState();
  useEffect(() => {
    // filters allocated budget and camapaign total by selected quarters
    const countQuarter = Object.values(checkedItems).filter(function (value) {
      return value === true;
    }).length;

    countQuarter === 4 ? setCheckedAll(true) : setCheckedAll(false);
    const leadImpactQuarter =
      (checkedItems.Q1
        ? ChannelCampaignPlannedTotal.lead?.q1["projectedLeads"]
        : 0) +
      (checkedItems.Q2
        ? ChannelCampaignPlannedTotal.lead?.q2["projectedLeads"]
        : 0) +
      (checkedItems.Q3
        ? ChannelCampaignPlannedTotal.lead?.q3["projectedLeads"]
        : 0) +
      (checkedItems.Q4
        ? ChannelCampaignPlannedTotal.lead?.q4["projectedLeads"]
        : 0);

    setPlannedLeadImpact(leadImpactQuarter);
    const budgetAllocation = BudgetProfile?.budget_allocation
      ? JSON.parse(BudgetProfile.budget_allocation)
      : "";
    const budgetImpactQuarter =
      (checkedItems.Q1 && budgetAllocation?.budget_q1
        ? ChannelCampaignPlannedTotal.budget?.q1["projected"]
        : 0) +
      (checkedItems.Q2 && budgetAllocation?.budget_q2
        ? ChannelCampaignPlannedTotal.budget?.q2["projected"]
        : 0) +
      (checkedItems.Q3 && budgetAllocation?.budget_q3
        ? ChannelCampaignPlannedTotal.budget?.q3["projected"]
        : 0) +
      (checkedItems.Q4 && budgetAllocation?.budget_q4
        ? ChannelCampaignPlannedTotal.budget?.q4["projected"]
        : 0);
    setPlannedBudgetImpact(
      parseFloat(parseFloat(budgetImpactQuarter).toFixed(2))
    );

    const budgetProfileTotalQuarter =
      (checkedItems.Q1 ? parseInt(budgetAllocation?.budget_q1) : 0) +
      (checkedItems.Q2 ? parseInt(budgetAllocation?.budget_q2) : 0) +
      (checkedItems.Q3 ? parseInt(budgetAllocation?.budget_q3) : 0) +
      (checkedItems.Q4 ? parseInt(budgetAllocation?.budget_q4) : 0);

    const revenueQuarterActual =
      (checkedItems.Q1
        ? props.actaulRevenueLeadsQuarter?.Q1?.closed_deal_amount
        : 0) +
      (checkedItems.Q2
        ? props.actaulRevenueLeadsQuarter?.Q2?.closed_deal_amount
        : 0) +
      (checkedItems.Q3
        ? props.actaulRevenueLeadsQuarter?.Q3?.closed_deal_amount
        : 0) +
      (checkedItems.Q4
        ? props.actaulRevenueLeadsQuarter?.Q4?.closed_deal_amount
        : 0);
    setActaulRevenueQ(revenueQuarterActual);

    const leadsQuarterActual =
      (checkedItems.Q1 ? props.actaulRevenueLeadsQuarter?.Q1?.deals_count : 0) +
      (checkedItems.Q2 ? props.actaulRevenueLeadsQuarter?.Q2?.deals_count : 0) +
      (checkedItems.Q3 ? props.actaulRevenueLeadsQuarter?.Q3?.deals_count : 0) +
      (checkedItems.Q4 ? props.actaulRevenueLeadsQuarter?.Q4?.deals_count : 0);
    setActaulLeadsQ(leadsQuarterActual);

    setFilteredBudgetAmount(
      isFinite(budgetProfileTotalQuarter) ? budgetProfileTotalQuarter : 0
    );
    setFilteredBudgetAmountRemaining(
      isFinite(budgetProfileTotalQuarter - budgetImpactQuarter)
        ? parseFloat(
            parseFloat(budgetProfileTotalQuarter - budgetImpactQuarter).toFixed(
              2
            )
          )
        : 0
    );

    const totalrevenue = Number(
      (checkedItems.Q1 ? props.quarterlyRevenueGoal?.budget_q1 : 0) +
        (checkedItems.Q2 ? props.quarterlyRevenueGoal?.budget_q2 : 0) +
        (checkedItems.Q3 ? props.quarterlyRevenueGoal?.budget_q3 : 0) +
        (checkedItems.Q4 ? props.quarterlyRevenueGoal?.budget_q4 : 0)
    ).toFixed(0);
    settotalRevenuegoal(totalrevenue);

    const totalleads = Number(
      (checkedItems.Q1 ? props.TotalLeadsGoal?.budget_q1 : 0) +
        (checkedItems.Q2 ? props.TotalLeadsGoal?.budget_q2 : 0) +
        (checkedItems.Q3 ? props.TotalLeadsGoal?.budget_q3 : 0) +
        (checkedItems.Q4 ? props.TotalLeadsGoal?.budget_q4 : 0)
    ).toFixed(0);
    settotalLeadsGoal(totalleads);

    props.setCheckedQuarters(checkedItems);
  }, [checkedItems, ChannelCampaignPlannedTotal, BudgetProfile, CampaignList]); // eslint-disable-line

  // useEffect(() => {
  //   dispatch(getBudget(props.selectedProfile));
  // }, [props.selectedProfile]); // eslint-disable-line

  useEffect(() => {
    settotalRevenuegoal(
      Number(
        props.quarterlyRevenueGoal?.budget_q1 +
          props.quarterlyRevenueGoal?.budget_q2 +
          props.quarterlyRevenueGoal?.budget_q3 +
          props.quarterlyRevenueGoal?.budget_q4
      ).toFixed(0)
    );

    settotalLeadsGoal(
      parseInt(
        props.TotalLeadsGoal?.budget_q1 +
          props.TotalLeadsGoal?.budget_q2 +
          props.TotalLeadsGoal?.budget_q3 +
          props.TotalLeadsGoal?.budget_q4
      )
    );
  }, [props.quarterlyRevenueGoal, props.TotalLeadsGoal]);
  const checkboxes = [
    {
      name: "Q1",
      key: "1stQuarter",
      label: "Q1",
    },
    {
      name: "Q2",
      key: "2ndQuarter",
      label: "Q2",
    },
    {
      name: "Q3",
      key: "3rdQuarter",
      label: "Q3",
    },
    {
      name: "Q4",
      key: "4thQuarter",
      label: "Q4",
    },
  ];

  const getLeadsData = (funnelStages) => {
    let newChartData = [];
    let newConvertData = [...convertReverseArray(funnelStages)];
    newConvertData.map((item, index) => {
      let { name, conversion, id } = item;
      let newValue =
        index === 0
          ? !isFinite(marketingSourcedCount)
            ? 0
            : Number.parseInt(marketingSourcedCount)
          : Number.parseInt(
              (newChartData[index - 1].value * 100) /
                Number.parseInt(item.conversion)
            );
      let setWidth = index === 0 ? 250 : conversion * 10 + 250;
      let obj = {
        id: id,
        text: name,
        percentageValue: conversion,
        value: newValue,
        width: setWidth,
      };
      newChartData.push({ ...obj });
      return 0;
    });
    return [...convertReverseArray(newChartData)];
  };

  useEffect(() => {
    // set prospect count for selected budget profile
    const leadsData = getLeadsData(funnelStages);
    setProspectCount(leadsData[0]?.value);
    dispatch(setProspects(leadsData[0]?.value));
  }, [prospectCount, props.selectedProfile]); // eslint-disable-line

  useEffect(() => {
    const leadsData = getLeadsData(funnelStages);
    setProspectCount(leadsData[0]?.value);
    dispatch(setProspects(leadsData[0]?.value));
  }, [funnelStages]); // eslint-disable-line

  const quarterCheckboxDefault = checkboxes.map((item, index) => (
    <span key={index}>
      <input
        type="checkbox"
        name={item.name}
        checked={checkedItems[item.name]}
        onChange={handleChange}
        style={{ verticalAlign: "middle" }}
        className="filter_input"
      />
      <span style={{ paddingRight: 15, paddingLeft: 2 }}>{item.label}</span>
    </span>
  ));

  const [checkedAll, setCheckedAll] = useState(true);
  const handleAll = (val) => {
    setCheckedAll(!checkedAll);
    setCheckedItems({
      Q1: !checkedAll,
      Q2: !checkedAll,
      Q3: !checkedAll,
      Q4: !checkedAll,
    });
  };

  const { campaignActualsData } = useSelector((state) => state.budgetReducer);
  const [leadsActual, setleadsActual] = useState(0);
  useEffect(() => {
    let totalLeads = 0;
    let Campaigns = BudgetProfile?.campaigns?.items?.map(
      (item) => item?.campaign?.id
    );

    Object.keys(campaignActualsData)?.map((key) => {
      if (Campaigns?.includes(key))
        Object.keys(campaignActualsData[key]).map((month) => {
          totalLeads += !isNaN(campaignActualsData[key][month]?.lead_actual)
            ? campaignActualsData[key][month]?.lead_actual
            : 0;
        });
    });
    setleadsActual(totalLeads);
  }, [campaignActualsData, BudgetProfile]);

  const budgetTotal = filteredBudgetAmountRemaining + plannedBudgetImpact;
  const transformedBudgetTotal = numberWithCommas(budgetTotal.toFixed(0));

  /***** percentage calculation  *****/
  const plannedBudgetPercentage =
    (plannedBudgetImpact / filteredBudgetAmount) * 100;

  // Calculate the percentage remaining
  const remainingPercentage = (100 - plannedBudgetPercentage) / 100;

  const config = {
    percent: remainingPercentage,
    range: {
      color: "l(0) 0:#FD853A 0.5:#F63D68 1:#7A5AF8",
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: "13px",
          lineHeight: "18px",
          fontWeight: 400,
          color: "#475467",
        },
        formatter: () => "Budget Remaining",
      },
      content: {
        style: {
          fontSize: "30px",
          lineHeight: "38px",
          color: "#1D2939",
          fontWeight: 500,
        },
        formatter: () =>
          BudgetProfile?.budget
            ? `$${numberWithCommas(filteredBudgetAmountRemaining?.toFixed())}`
            : "$0",
      },
    },
  };

  return (
    <>
      <Modal
        className="budget_filter_modal"
        visible={props.isShowBudgetFilterModal}
        closable={false}
        width={430}
        footer={false}
        centered
        maskStyle={{
          backgroundColor: "rgba(255,255,255,0.5)",
          backdropFilter: "blur(2px)",
        }}
      >
        <Row>
          <Col span={24}>
            <div
              className="modal_top_section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
                borderBottom: "1px solid #ddd",
                paddingBottom: "15px",
              }}
            >
              <Typography.Title
                style={{
                  fontWeight: 500,
                  lineHeight: "38px",
                  fontSize: "30px",
                  color: "#1D2939",
                  marginBottom: 0,
                }}
              >
                Filters
              </Typography.Title>
              <Button
                style={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  fontZize: "16px",
                  padding: 0,
                }}
                onClick={() => props.setIsShowBudgetFilterModal(false)}
              >
                <CloseOutlined style={{ display: "flex" }} />
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <div className="modal_body">
              <Row gutter={[16, 16]} xs={24} md={24} lg={24}>
                <Col span={24} xs={24} md={24} lg={24}>
                  <div
                    className={`filterOptions`}
                    style={{ padding: "10px 0", marginBottom: "20px" }}
                  >
                    <div className="quarter-filter">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>
                          <input
                            type="checkbox"
                            name="all"
                            checked={checkedAll}
                            style={{ verticalAlign: "middle" }}
                            // onClick={handleAll}
                            onChange={handleAll}
                            className="filter_input"
                          />
                          <span style={{ paddingRight: 15, paddingLeft: 2 }}>
                            All
                          </span>
                        </span>
                        {quarterCheckboxDefault}
                      </div>
                    </div>
                  </div>
                  <div
                    className="filter_save_button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      className="primaryButton"
                      style={{ width: "100px" }}
                      onClick={() => props.setIsShowBudgetFilterModal(false)}
                    >
                      Ok
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>

      <Row gutter={[16, 16]} xs={24} md={24} lg={24}>
        <Col span={10} xs={24} md={24} lg={8}>
          <div
            className="select_budget_section panel-design"
            style={{ padding: "20px 15px", position: "relative" }}
          >
            {props?.budgetChangeLoader && !getBudgetLoader && (
              <div className="budget_select_year_loader">
                <LoadingOutlined style={{ fontSize: "40px" }} />
              </div>
            )}
            {getBudgetLoader ? (
              <Skeleton active paragraph={{ rows: 9 }} />
            ) : (
              <div>
                <div className="select_year" style={{ marginBottom: "20px" }}>
                  <Radio.Group
                    defaultValue={props.defaultYear}
                    onChange={(e) => props.handleYearChange(e)}
                    buttonStyle="solid"
                    className="year_select_cust"
                  >
                    {props?.yearDropdown.map((yearDropdownVal) => (
                      <Radio.Button
                        key={yearDropdownVal}
                        value={yearDropdownVal}
                      >
                        {yearDropdownVal}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </div>

                <div className="select_campaign">
                  {" "}
                  <Select
                    value={props.budgetPrifileDefault}
                    onSelect={(e) => props.onBudgetProfileChange(e)}
                    style={{
                      width: "100%",
                      color: "#1D2939",
                      marginBottom: "15px",
                    }}
                    placeholder="Select Budget name"
                    defaultValue={props.budgetPrifileDefault}
                    className="general-input-select campaign_select_cust"
                  >
                    {props?.burgetPrifleNames?.map((budgetVal) => (
                      <Option key={budgetVal.id}>{budgetVal.label}</Option>
                    ))}
                  </Select>
                </div>
                <div
                  className="new_budget"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                    padding: "5px 5px",
                  }}
                >
                  {userPermission?.["budgetCampaign.budgets"] !== "viewonly" &&
                    userPermission?.["budgetCampaign.budgets"] !== "hidden" &&
                    userPermission?.["budgetCampaign"] !== "preview" &&
                    userPermission?.["budgetCampaign"] !== "viewonly" && (
                      <div
                        data-tour="tour__createBudget"
                        className="non-print budget_link"
                      >
                        <span
                          type="text"
                          style={{
                            fontSize: "15px",

                            cursor: "pointer",
                          }}
                          onClick={() => {
                            TrackClickFunction(
                              "demand-gen-budget-and-campaigns-new-budget-hyperlink"
                            );
                            props.showModal();
                          }}
                          data-heap="demand-gen-budget-and-campaigns-new-budget-hyperlink"
                          title="Add New Budget"
                        >
                          <PlusOutlined /> New Budget
                        </span>
                      </div>
                    )}
                  <Tooltip title="Budget Total">
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#EC4A0A",
                      }}
                    >
                      ${numberWithCommas(BudgetProfile?.budget)}
                    </span>
                  </Tooltip>
                </div>

                <div
                  className="active_budget"
                  style={{
                    background: "#F9F5FF",
                    color: "#7F56D9",
                    marginBottom: "15px",
                    fontSize: "16px",
                    padding: "10px 5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {BudgetProfile?.active ? (
                    <div
                      className="text budget-active-status"
                      style={{
                        color: "#7F56D9",
                        fontSize: "16px",
                      }}
                    >
                      <CheckOutlined style={{ marginRight: "5px" }} /> Budget is
                      now active
                    </div>
                  ) : (
                    <div className="budget_sandbox">
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <span
                            style={{
                              fontSize: 16,
                              color: "#7F56D9",
                              fontWeight: "700",
                              marginBottom: "10px",
                              display: "inline-block",
                            }}
                          >
                            Sandbox
                          </span>
                          <h4
                            className="text"
                            style={{
                              marginBottom: 0,
                              color: "#475467",
                              fontWeight: "500",
                              fontSize: "13px",
                            }}
                          >
                            {BudgetProfile?.name} Budget Status
                          </h4>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="secondary "
                            style={{
                              height: "28px",
                              width: "120px",
                              fontSize: "16px",
                            }}
                            onClick={() =>
                              props.setActivateBudgetModelOpen(true)
                            }
                            className="primaryButton"
                          >
                            Activate
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <div>
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="edit-budget"
                            onClick={() => {
                              props.setEditBudget(true);
                            }}
                          >
                            <strong
                              style={{
                                color: "#1D2939",
                                fontWeight: 500,
                              }}
                            >
                              Edit Budget
                            </strong>
                          </Menu.Item>

                          <Menu.Item
                            key="delete-budget"
                            onClick={() => {
                              props.setisDeleteBudgetModelOpen(true);
                            }}
                            disabled={BudgetProfile?.active}
                          >
                            <Tooltip
                              title={
                                BudgetProfile?.active
                                  ? "Make another budget Active to delete this budget"
                                  : ""
                              }
                            >
                              <strong
                                style={{
                                  color: "#1D2939",
                                  fontWeight: 500,
                                }}
                              >
                                Delete Budget
                              </strong>
                            </Tooltip>
                          </Menu.Item>

                          <Menu.Item
                            onClick={() => props.showCopyModal()}
                            key="copy-budget"
                          >
                            <strong
                              style={{
                                color: "#1D2939",
                                fontWeight: 500,
                              }}
                            >
                              Copy Budget
                            </strong>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <MoreOutlined style={{ fontSize: "25px" }} />
                    </Dropdown>
                  </div>
                </div>

                <div>
                  <Typography
                    style={{
                      fontSize: "24px",
                      lineHeight: "32px",
                      color: "#1D2939",
                      fontWeight: "700",
                      marginBottom: "10px",
                    }}
                  >
                    Analysis
                  </Typography>
                  <table className="budget_analysis_table">
                    <tr>
                      <th></th>
                      <th>Goal</th>
                      <th>Planned</th>
                      <th>Actual</th>
                    </tr>
                    <tr>
                      <td>Leads</td>
                      <td>
                        {props.TotalLeadsGoal
                          ? nFormatter(totalLeadsGoal)
                          : "0"}
                      </td>
                      <td>
                        {nFormatter(
                          isNaN(plannedLeadImpact) ? 0 : plannedLeadImpact
                        )}
                      </td>
                      <td>
                        {!isFinite(leadsActual)
                          ? 0
                          : `${numberWithCommas(leadsActual)}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Revenue</td>
                      <td>
                        {!isFinite(totalRevenuegoal)
                          ? 0
                          : `$${nFormatter(Math.floor(totalRevenuegoal))}`}
                      </td>
                      <td>
                        {/* {!isFinite(actualRevenuQ)
                          ? 0
                          : `$${nFormatter(Math.floor(actualRevenuQ))}`} */}
                        {props.analysisPlannedValue}
                      </td>
                      {/* <td>{numberWithCommas(actualLeadsQ)}</td> */}
                      <td>{numberWithCommas(props.analysisActualValue)}</td>
                    </tr>
                  </table>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col span={8} xs={24} md={24} lg={8} style={{ display: "flex" }}>
          <div
            className="budget_remaining_chart panel-design"
            style={{ padding: "20px 15px", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #D0D5DD",
                paddingBottom: "10px",
                marginBottom: "15px",
              }}
            >
              <Typography className="sectionTitle">Budget Remaining</Typography>
              <Tooltip title="Budget Remaining">
                <InfoCircleOutlined
                  style={{
                    marginLeft: "15px",
                    fontSize: "18px",
                    color: "#9E9E9E",
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Row xs={24} md={24} lg={24} gutter={[20, 0]}>
                <Col xs={24} md={24} lg={24}>
                  {getBudgetLoader ? (
                    <Skeleton active paragraph={{ rows: 7 }} />
                  ) : (
                    <>
                      <div>
                        <Tooltip
                          title={`$${numberWithCommas(
                            filteredBudgetAmountRemaining
                          )} Remaining`}
                        >
                          <Gauge {...config} />
                        </Tooltip>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={8} xs={24} md={24} lg={8} style={{ display: "flex" }}>
          <div
            className="budget_planning_chart panel-design"
            style={{ padding: "20px 15px", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #D0D5DD",
                paddingBottom: "10px",
                marginBottom: "15px",
              }}
            >
              <Typography className="sectionTitle">Budget Planned</Typography>
              <Tooltip title="Budget Planned">
                <InfoCircleOutlined
                  style={{
                    marginLeft: "15px",
                    fontSize: "18px",
                    color: "#9E9E9E",
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Row xs={24} md={24} lg={24} gutter={[20, 0]}>
                <Col xs={24} md={24} lg={24}>
                  {getBudgetLoader ? (
                    <Skeleton active paragraph={{ rows: 7 }} />
                  ) : (
                    <div>
                      <BudgetPieChart
                        plannedBudget={ChannelCampaignPlannedTotal}
                        checkedItems={checkedItems}
                        totalspend={plannedBudgetImpact}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BudgetSummaryNew;
