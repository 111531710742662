import Auth from '@aws-amplify/auth';
import { Button, Result } from 'antd'
import React from 'react'

function AccountSuspended(props) {

    const handleSignOut = async () => {
        Auth.signOut({
          global: true,
        })
          .then((data) => {
              props.logOut();

          })
          .catch((err) => console.log(err));
    }
    return (
        <div>
            <Result
          status="warning"
          title="Account Suspended"
                subTitle={<p>Sorry, Your account is suspended <br /> Please contact XFactor.io support staff at <a href="mailto:support@xfactor.io">support@xfactor.io</a></p>}
                extra={<Button type="primary" onClick={handleSignOut}>Log out</Button>}
        ></Result>
        </div>
    )
}

export default AccountSuspended
