import React from "react";
import { Col, Row } from "antd";
import Blogs from "./Blogs";

const FunnelAnalisys = (props) => {
  return (
    <>
      {" "}
      <div
      >
        <div>
          <Row gutter={[12, 12]} style = {{margin:0}}>
            <Col xs={24} md={24} lg={24} className="dashabord-goal-charts-container panel-design">
            <Blogs loading={props?.isLoading} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FunnelAnalisys;
