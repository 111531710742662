import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row } from "antd";
import playIcon from "../../assets/Images/play_icon.svg";
import durationIcon from "../../assets/Images/duration.svg";
// import coursePdfIcon from "../../assets/Images/course_pdf.svg";
// import icnFileTypeDoc from "../../assets/Images/icn_file_type_doc.svg";
import SimilarCourses from "./SimilarCourses";
import { useParams } from "react-router-dom";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import { downloadBlob } from "./../../utility/context/CommonMethods";
import { Storage } from "aws-amplify";
import marked from "marked";
import { PaperClipOutlined, DownloadOutlined, FilePdfOutlined, FileZipOutlined, FileWordTwoTone, FileExcelOutlined } from "@ant-design/icons";
import Player from "./Player";
import { secondsToHms } from "utility/context/CommonMethods";
import { getCourseList } from "store/actions/coachingAction";
import { useLocation } from 'react-router-dom';



const CourseDetails = (props) => {
  const location = useLocation();
  const isContent = location.state?.isContent;

  console.log("iiiiiiii",isContent)
  
  const [courseDetails, setCourseDetails] = useState();
  const s3BannerRef = useRef(null);
  const [heightOfBanner, setHeightOfBanner] = useState(100);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const { CourseList } = useSelector((state) => state.coachingReducer);

  

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [videoUrls, setVideoUrls] = useState([]);
  const videoUrlsArray = []; // eslint-disable-line
  let { slug } = useParams();

  useEffect(() => {
    dispatch(getCourseList({ slug: { eq: slug } }));
  }, [slug]);  // eslint-disable-line

  useEffect(() => {
    if (CourseList.listCourses?.items?.length > 0) {
      setCourseDetails(CourseList.listCourses?.items?.[0]);
      if (CourseList.listCourses?.items?.[0].videos?.items.length > 0) {
        CourseList.listCourses?.items?.[0].videos?.items.map((videoData) => { // eslint-disable-line
          (async () => {
            let result = await Storage.get(videoData.name.split("/")[1], {
              level: "protected",
              identityId: videoData.name.split("/")[0],
            });
            videoUrlsArray.push({
              name: videoData.name,
              title: videoData.title,
              description: videoData.description,
              duration: videoData.duration,
              video_url: result,
            });
            setVideoUrls(videoUrlsArray);
          })();
        });
      } else {
        setVideoUrls([]);
      }
    }
  }, [CourseList]); // eslint-disable-line

  const [videoDataDetails, setVideoData] = useState([]);
  useEffect(() => {
    if (
      CourseList.listCourses?.items?.[0]?.videos?.items.length ===
      videoUrls.length
    ) {
      setVideoData(videoUrls);
    }
  }, [videoUrls]); // eslint-disable-line

  useEffect(() => {}, [videoUrlsArray]);
  const downloadAttachment = async (fileKey) => {
    const fileInfo = fileKey.split("/");
    const result = await Storage.get(fileInfo[1], {
      download: true,
      level: "protected",
      identityId: fileInfo[0],
    });
    downloadBlob(result.Body, fileInfo[1]);
  };

  const playVideo = async (fileKey) => {
    const fileInfo = fileKey.split("/");
    const videoResult = await Storage.get(fileInfo[1], {
      level: "protected",
      identityId: fileInfo[0],
    });
    showModal();
    setVideoUrl(videoResult);
  };

  

  function getCourseAttachmentDetails(varDetails){
    function colReturn(indexItem){
      return(   
        <> 
       <Col
       span={12}
       xs={24}
       md={12}
       lg={12}
       style={{ paddingLeft: 20, paddingTop: 10, paddingRight:20}}
     >
       <div style={{border:"2px solid #e8e8e8", borderRadius:7, padding:"10px", display:"inline-flex", width:"100%"}}>
       <div>
         {indexItem.split(".").pop() === "pdf" ||
         indexItem.split(".").pop() === "doc" ||
         indexItem.split(".").pop() === "docx" ||
         indexItem.split(".").pop() === "zip" || 
         indexItem.split(".").pop() === "xls" ||
         indexItem.split(".").pop() === "xlsx" ? (
          //  <img
          //    src={
                   indexItem.split(".").pop() === "pdf"
                 ? <FilePdfOutlined style={{fontSize:32, color:"red"}} />

                 : indexItem.split(".").pop() === "doc"
                 ? <FileWordTwoTone style={{fontSize:32, color:"blue"}}/>

                 : indexItem.split(".").pop() === "docx"
                 ? <FileWordTwoTone style={{fontSize:32, color:"blue"}}/>

                 : indexItem.split(".").pop() === "zip"
                 ? <FileZipOutlined style={{fontSize:32, color:"#A45C40"}}/>

                 : indexItem.split(".").pop() === "xls"
                 ? <FileExcelOutlined style={{fontSize:32, color:"green"}}/>

                 : indexItem.split(".").pop() === "xlsx"
                 ? <FileExcelOutlined style={{fontSize:32, color:"green"}}/>

                 : ""
            // }
          //    alt="course"
          //  />
         ) : (
           <PaperClipOutlined style={{fontSize:32}}/>
         )}
       </div>{" "}
       <div
         className="material-file-name"
         style={{ right:-30 ,display:"inline-block" ,paddingLeft: 10, textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden", maxWidth:"80%", paddingTop:7}}
       >
         {indexItem.split("/")[1]}

        

       </div>
       
       <div
         style={{float: "right", marginLeft:"auto"}}
         className="material-download"
         onClick={() => downloadAttachment(indexItem)}
       >
         <DownloadOutlined style={{ fontSize: '32px', color: '#0F61DB' }}/>
       </div>
       
       </div>
     </Col>
       {/* <Col
       span={2}
       xs={12}
       md={6}
       lg={2}
       style={{ textAlign: "center", paddingTop: 15, border: "2px solid #0f0" }}
     >
       <span
         className="material-download"
         onClick={() => downloadAttachment(indexItem)}
       >
         <DownloadOutlined style={{ fontSize: '32px', color: '#08c' }}/>
       </span>
     </Col> */}
       </>
      )
     }
    var returnValue = []
    for(var i=0; i<varDetails.length; i+=2){
      var itemOne = colReturn(varDetails[i]);
      var itemTwo = null;
      
      if((i+1)<varDetails.length){
        itemTwo = colReturn(varDetails[i+1])
      }
      var singleRow =  <Row
      gutter={[16, 16]}
      className="course-material"
      style={{ marginLeft: 20, marginRight: 20 }}
      >
        {itemOne}{itemTwo}
        </Row>
      
      returnValue.push(singleRow)
    }
    return returnValue;
  
  }

  useEffect(() => {
    const resizeHandler = () => {
      var b = document.getElementById("course-image-banner-div");
      if(b){        
        setHeightOfBanner(b.getBoundingClientRect().width * 0.43);
      }
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => window.removeEventListener('resize', resizeHandler);
  },[]);

  function getBannerHeight()
  {
    return `${heightOfBanner}px`
  }
  return (
    <>
      {CourseList.listCourses?.items.length > 0 ? (
        //<div style={{ background: "#fff", borderRadius: 7, paddingBottom: 10}}>
        <div style={{background:"#fff"}}>
          <div style={{ paddingBottom: 20}}>
            <Row className="course-banner">
              <Col
                span={24}
                xs={24}
                md={24}
                lg={24}
                style={{ marginBottom: -8
                //, border:"10px solid #0f0" 
              }}
              >
              {//<div ref= {s3BannerRef}  id="course-image-banner-div" className="ok-course" style={{borderRadius:7,  border:"10px solid #f00" }}>
              }
              <div ref= {s3BannerRef}  id="course-image-banner-div" className="ok-course" style={{ borderRadius:7}} >
               <AmplifyS3Image
                 
                 id="course-image-banner"
                 level="protected"
                 identityId={courseDetails?.featuredimage.split("/")[0]}
                 style={{
                   
                   "--height":getBannerHeight(),
                   //"--height":"366px",
                   "--width": "100%",
                   "--border-radius": "7px",
                   //paddingBottom: "42.85%",
                   //objectFit:"fill",
                   opacity:"0.8"
                   
                 }}
                 key = {courseDetails?.featuredimage.split("/")[1]}
                 path={`${courseDetails?.featuredimage.split("/")[1]}`}
                 
               />
               </div>

               <div className="course-div-titles">
               <div className="course-category-banner" style={{textTransform:"capitalize"}}>
                  {courseDetails?.categories?.items?.[0]?.courseCategory?.name}
                </div>
                <div className="course-title-banner" style={{color:"white", textTransform:"capitalize"}}>{courseDetails?.name}</div>

               </div>
               
               </Col>
               {/* <Col
                span={10}
                xs={24}
                md={10}
                lg={10}
                style={{ marginBottom: -7,  background:"black" }}
              >
                <div>

                <AmplifyS3Image
                 
                 level="protected"
                 identityId={courseDetails?.featuredimage.split("/")[0]}
                 style={{
                   "--height": "400px",
                   "--width": "80%",
                   "--border-radius": "7px",
                 }}
                 key = {courseDetails?.featuredimage.split("/")[1]}
                 path={`${courseDetails?.featuredimage.split("/")[1]}`}
               />

                </div>
                
               </Col> */}
            </Row>
            <Row>
              <Col span={24} xs={24} md={24} lg={24}>

                <div className="course-description-section" style={{borderBottomLeftRadius:7,borderBottomRightRadius:7, paddingLeft:40
                ,paddingRight:40}}>
                  <div className="course-description-title">
                    Course Description
                  </div>
                  <div
                    style={{ paddingTop: 20, fontSize:"18px"}}
                    dangerouslySetInnerHTML={{
                      __html: marked(
                        courseDetails?.description
                          ? courseDetails?.description
                          : ""
                      ),
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="title-course-video" style={{fontWeight:800, background:"#fff", paddingBottom:1, marginTop:40,
            color:"#303030", paddingLeft:40}}>Course Videos</div>
          {videoDataDetails.length > 0 ? (
            videoDataDetails.map((videosVal, index) => {
              return (
                <Row
                  gutter={[16, 16]}
                  className="course-video-section"
                  style={{
                    marginLeft: 40,
                    marginRight: 2,
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  {" "}
                  <Col
                    span={6}
                    xs={24}
                    md={12}
                    lg={6}
                    style={{ paddingLeft: 0 }}
                  >
                    <video
                      key={videosVal.video_url}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: 15}}
                    >
                      <source src={videosVal.video_url} />
                      Your browser does not support the video tag.
                    </video>
                  </Col>
                  <Col
                    span={18}
                    xs={24}
                    md={12}
                    lg={18}
                    style={{ paddingTop: "2%" }}
                  >
                    <div className="course-details-title">
                      {videosVal.title}
                    </div>
                    <div className="course-details-desc">
                      {videosVal.description}
                    </div>
                    <div style={{ paddingTop: 18 }}>
                      <span>
                        <Button
                          type="secondary"
                          style={{
                            height: 37,
                            marginRight: 5,
                            fontWeight: "normal",
                            width: 147,
                            borderWidth:"2px"
                          }}
                          onClick={() => playVideo(videosVal.name)}
                        >
                          <img
                            src={playIcon}
                            style={{ paddingRight: 10}}
                            alt="save"
                          ></img>{" "}
                          <span style={{ verticalAlign: "middle"}}>
                            Play now
                          </span>
                        </Button>
                      </span>
                      <span style={{ paddingLeft: 10 }}>
                        <img src={durationIcon} alt="duration" />{" "}
                        <span className="duration-text">
                          {secondsToHms(videosVal.duration)}
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <Row
              gutter={[16, 16]}
              className="course-material"
              style={{ marginLeft: 20, marginRight: 20 }}
            >
              <Col span={20} xs={24} md={12} lg={20} style={{ paddingLeft: 30, fontSize:"18px", margin:"10px", paddingTop:15, paddingBottom:15 }}>
                No videos available
              </Col>
            </Row>
          )}

          <div className="title-course-video" style={{marginBottom:20, background:"#fff", paddingBottom:10, marginTop:40,
            paddingLeft:40}}> Course material </div>
          <div style={{paddingBottom:30}}>

          {courseDetails?.attachments.length > 0 ? 
             getCourseAttachmentDetails(courseDetails.attachments)
             
           : (
            <Row
              gutter={[16, 16]}
              className="course-material"
              style={{ marginLeft: 20, marginRight: 20 }}
            >
              <Col span={20} xs={24} md={12} lg={20} style={{ paddingLeft: 30 , fontSize:"18px", marginLeft: 10, marginBottom:0, paddingBottom:0 }}>
                No materials available
              </Col>
            </Row>
          )}

          </div>
          
        </div>
      ) : (
        <div style={{ background: "#fff", borderRadius: 7, padding: 20,  fontSize:"16px" }}>
          Details not available
        </div>
      )}
      <SimilarCourses isContent={isContent}></SimilarCourses>
      <Player
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        videoUrl={videoUrl}
      />
    </>
  );
};

export default CourseDetails;
