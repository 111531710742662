import gotomarketImg from "assets/Images/PdfImages/go_to_market.png";
import wordmark_blue from "assets/Images/wordmark_blue.png";
import blue_logo from "assets/Images/xio-logo-short.svg";
import target_audience from "assets/Images/PdfImages/target_audience.png";
import target_market_segement_icon from "assets/Images/PdfImages/target_market_segement_icon.png";
import target_industry from "assets/Images/PdfImages/target_industry.png";
import target_region from "assets/Images/PdfImages/target_region.png";
import job_title from "assets/Images/PdfImages/job_title.png";
import job_functions from "assets/Images/PdfImages/job_functions.png";
import job_levels from "assets/Images/PdfImages/job_levels.png";
import pin_icon from "assets/Images/PdfImages/pin_icon.png";
import paint_point_solution_icon from "assets/Images/PdfImages/paint_point_solution_icon.png";
import persona_responsibility from "assets/Images/PdfImages/persona_responsibility.png";
import persona_message from "assets/Images/PdfImages/persona_message.png";
import system_process from "assets/Images/PdfImages/system_process.png";
import channel_icon from "assets/Images/PdfImages/channel_icon.png";
import email_icon from "assets/Images/PdfImages/email_icon.png";
import branded_icon from "assets/Images/PdfImages/branded_icon.png";
import non_branded_icon from "assets/Images/PdfImages/non_branded_icon.png";
import category_icon from "assets/Images/PdfImages/category_icon.png";
import sales_marketing_icon from "assets/Images/PdfImages/sales_marketing_icon.png";
import youtube_icon from "assets/Images/PdfImages/youtube_icon.png";
import fb_icon from "assets/Images/PdfImages/fb_icon.png";
import insta_icon from "assets/Images/PdfImages/insta_icon.png";
import linkedin_icon from "assets/Images/PdfImages/linkedin_icon.png";
import CompanyInfoImg from "assets/Images/PdfImages/company_info.png";
import ProductDescImg from "assets/Images/PdfImages/product_info.png";
import taglineImg from "assets/Images/PdfImages/company_tagline.png";
import keyDiffrenceImg from "assets/Images/PdfImages/key_diffrence.png";
import ValuePropositionImg from "assets/Images/PdfImages/value_proposition.png";
import AwardsImg from "assets/Images/PdfImages/offers.png";
import OffersImg from "assets/Images/PdfImages/offers.png";
import CompanyInfoCoverImg from "assets/Images/PdfImages/company_information.png";
import { current_marketing_activity_channels, email_marketing_programs, current_sales_marketing_tools } from 'utility/Constants';

const addBookMark = (pptx, top=false) => {
  pptx.addImage({
    path: wordmark_blue,
    x: "85%",
    y: !top ? "93%" : "5%",
    w: "10%",
    h: 0.2,
  });

  pptx.addText("Powered By", {
    color: "000000",
    fontSize: 12,
    x: "75%",
    y: !top ? "93%" : "5%",
    w: "50%",
    h:0.2,

  });
return pptx
}

export const MasterCoverPPT = (pptx,props) => {
  // Master Page Cover
  let MasterPageCover = pptx.addSlide();
  MasterPageCover = addBookMark(MasterPageCover)

  MasterPageCover.addImage({
    path: props.logoUrl,
    x: "7%",
    y: "30%",
    w: "30%",
    h: 0.5,
  });
  MasterPageCover.addText("Go To Market Intelligence", {
    color: "000000",
    fontSize: 24,
    bold: true,
    x: "7%",
    y: "45%",
    w: "50%",
    h:0.2
  });
  MasterPageCover.addText(
    "GTM Intelligence helps to gather all the data of your Company & Customers in one place to enrich the on demand gen experience.",
    { color: "000000", fontSize: 18, x: "7%", y: "60%", w: "50%", h:0.4 }
  );
  // MasterPageCover.addImage({
  //   path: gotomarketImg,
  //   x: "55%",
  //   y: "40%",
  //   w: "40%",
  //   h: "40%",
  // });

  return pptx;
};

export const CompanyInfoCoverPPT = (pptx,props) => {
  let CompanyInfoPageCover = pptx.addSlide();
  CompanyInfoPageCover = addBookMark(CompanyInfoPageCover)

  CompanyInfoPageCover.addImage({
    path: props.logoUrl,
    x: "7%",
    y: "30%",
    w: "30%",
    h: 0.5,
  });
  CompanyInfoPageCover.addText("Company Information", {
    color: "000000",
    fontSize: 24,
    bold: true,
    x: "7%",
    y: "45%",
    w: "50%",
    h: 0.2,
  });
  CompanyInfoPageCover.addText(
    "GTM Intelligence helps to gather all the data of your Company & Customers in one place to enrich the on demand gen experience.",
    { color: "000000", fontSize: 18, x: "7%", y: "60%", w: "50%", h: 0.3,}
  );
  // CompanyInfoPageCover.addImage({
  //   path: CompanyInfoCoverImg,
  //   x: "55%",
  //   y: "40%",
  //   w: "40%",
  //   h: "40%",
  // });

  return pptx;
};

export const CompanyInfoPPT = async (pptx, data,props) => {
  let companyInfoPage = pptx.addSlide();
  companyInfoPage = addBookMark(companyInfoPage)

  let base = {
    x: 0.3,
    y: 0.3,
  };
  companyInfoPage.addText("Company Information", {
    color: "#000000",
    fontSize: 24,
    bold: true,
    x: 3.5,
    y: base.y,
    breakLine: true,
    h:0.2
  });
  companyInfoPage.addText("Company Information", {
    color: props.primaryColor,
    fontSize: 18,
    bold: true,
    x: base.x,
    y: base.y + 0.4,
    breakLine: true,
    h:0.1
  });

  companyInfoPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4,
    x: base.x,
    h: 0.7,
    y: base.y + 0.6,
  });
  companyInfoPage.addImage({
    path: blue_logo,
    h: 0.2,
    w: 0.5,
    x: base.x + 0.2,
    y: base.y + 0.85,
  });
  companyInfoPage.addText(data?.name, {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.9,
    y: base.y + 0.8,
    breakLine: true,
    h:0.15
  });
  companyInfoPage.addText(data?.url, {
    color: "#000000",
    fontSize: 14,
    x: base.x + 0.9,
    y: base.y + 1.1,
    breakLine: true,
    h:0.1
  });

  base = {
    ...base,
    y: base.y + 1.5,
  };

  let companyDetail = data?.company_description
    ? data?.company_description
    : "-";
  companyInfoPage.addImage({
    path: CompanyInfoImg,
    h: 0.2,
    w: 0.2,
    y: base.y + companyDetail?.length / 1000 + 0.3,
    x: base.x,
  });
  companyInfoPage.addText("Company description", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + companyDetail?.length / 1000 + 0.3,
    breakLine: true,
    h:0.2
  });
  companyInfoPage.addText(truncate(companyDetail,330), {
    color: "#000000",
    fontSize: 14,
    x: base.x + 2.5,
    y: base.y + companyDetail?.length / 1000 + 0.2,
    w: 7,
    h:0.2
  });

  base = {
    ...base,
    y:
      base.y +
      companyDetail?.length / 1000 +
      companyDetail?.length / 1000 +
      0.5,
  };

  let ProductDetail = data?.product_description
    ? data?.product_description
    : "-";

  companyInfoPage.addImage({
    path: ProductDescImg,
    h: 0.2,
    w: 0.2,
    y: base.y + ProductDetail?.length / 1000 + 0.33,
    x: base.x,
  });
  companyInfoPage.addText("Product description", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + ProductDetail?.length / 1000 + 0.3,
    breakLine: true,
    h:0.2
  });
  companyInfoPage.addText(truncate(ProductDetail,330), {
    color: "#000000",
    fontSize: 14,
    x: base.x + 2.5,
    y: base.y + ProductDetail?.length / 1000 + 0.3,
    w: 7,
    h:0.3
  });

  base = {
    ...base,
    y:
      base.y +
      ProductDetail?.length / 1000 +
      ProductDetail?.length / 1000 +
      0.5,
  };

  let tagline = data?.tagline ? data?.tagline : "-";

  companyInfoPage.addImage({
    path: taglineImg,
    h: 0.2,
    w: 0.2,
    y: base.y + tagline?.length / 1000 + 0.33,
    x: base.x,
  });
  companyInfoPage.addText("Tagline", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + tagline?.length / 1000 + 0.3,
    breakLine: true,
    h:0.2
  });
  companyInfoPage.addText(tagline, {
    color: "#000000",
    fontSize: 14,
    x: base.x + 2.5,
    y: base.y + tagline?.length / 1000 + 0.3,
    w: 7,
    h:0.3
  });

  // -------------------------------------------------------------------------------------------------------------------
  // NEW SLIDE

  let competitivePage = pptx.addSlide();
  competitivePage = addBookMark(competitivePage)
  base = {
    x: 0.3,
    y: 0.3,
  };
  competitivePage.addText("Competitive and Company Positioning", {
    color: "#000000",
    fontSize: 24,
    bold: true,
    x: 2.5,
    y: base.y,
    breakLine: true,
    h:0.3
  });

  base.y = base.y - 0.3;

  // eslint-disable-next-line no-lone-blocks
  {
    data?.competitive?.map((item) => { //eslint-disable-line
      competitivePage.addShape(pptx.ShapeType.rect, {
        fill: { color: props?.backgroundColor},
        w: 4,
        x: base.x,
        h: 0.6,
        y: base.y + 0.7,
      });
      // competitivePage.addImage({
      //   path: blue_logo,
      //   h: 0.5,
      //   w: 0.5,
      //   x: base.x + 0.2,
      //   y: base.y + 0.7,
      // });
      competitivePage.addText(item?.competitor_name, {
        color: props?.primaryColor,
        fontSize: 14,
        bold: true,
        x: base.x ,
        y: base.y + 0.85,
        breakLine: true,
        h:0.1
      });
      competitivePage.addText(item?.website, {
        color: "#000000",
        fontSize: 14,
        x: base.x,
        y: base.y + 1.05,
        breakLine: true,
        h:0.1
      });

      base.y = base.y + 0.7;
    });
  }

  base.y = base.y + 0.5;

  let value_proposition = data?.value_proposition
    ? data?.value_proposition
    : "-";
  competitivePage.addImage({
    path: ValuePropositionImg,
    h: 0.2,
    w: 0.2,
    y: base.y + 0.3,
    x: base.x,
  });
  competitivePage.addText("Value Proposition", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.3,
    breakLine: true,
    h:0.2
  });
  competitivePage.addText(truncate(value_proposition,200), {
    color: "#000000",
    fontSize: 14,
    x: base.x+0.2,
    y: base.y + value_proposition?.length / 1000 + 0.6,
    w: 4.5,
    h:0.2
  });

  // base.y =
  //   base.y +
  //   value_proposition?.length / 1000 +
  //   value_proposition?.length / 1000 +
  //   1;

  let awards = data?.awards ? data?.awards : "-";
  competitivePage.addImage({
    path: AwardsImg,
    h: 0.2,
    w: 0.2,
    y: 0.8,
    x: base.x+5,
  });
  competitivePage.addText("Awards or accolade Received?", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 5.2,
    y: 0.8,
    breakLine: true,
    h:0.2
  });
  competitivePage.addText(truncate(awards,140), {
    color: "#000000",
    fontSize: 14,
    x: base.x+5.2,
    y: 1.1,
    w: 4.5,
    h:0.1
  });

  base.x = base.x + 5;
  base.y = 0.9;

  let key_differentiators = data?.key_differentiators
    ? data?.key_differentiators
    : "-";
  competitivePage.addImage({
    path: keyDiffrenceImg,
    h: 0.2,
    w: 0.2,
    y: base.y + 1,
    x: base.x,
  });
  competitivePage.addText("Key Differentiators", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 1,
    breakLine: true,
    h:0.2
  });
  competitivePage.addText(truncate(key_differentiators,240), {
    color: "#000000",
    fontSize: 14,
    x: base.x+0.2,
    y: base.y + 1.3,
    w: 4.5,
    h:0.1
  });

  base.y =
    base.y +
    key_differentiators?.length / 1000 +
    key_differentiators?.length / 1000 +
    1.8;

  let offers = data?.offers ? data?.offers : "-";
  competitivePage.addImage({
    path: OffersImg,
    h: 0.2,
    w: 0.2,
    y: base.y + 0.6,
    x: base.x,
  });
  competitivePage.addText("Offers or Incentive to Leverage?", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.6,
    breakLine: true,
    h:0.2
  });
  competitivePage.addText(truncate(offers,90), {
    color: "#000000",
    fontSize: 14,
    x: base.x+0.2,
    y: base.y + 0.9,
    w: 4.5,
    h:0.1
  });

  return pptx;
};

export const TargetAudienceCoverPPT = (pptx,props) => {

  let TargeAudienceCover = pptx.addSlide();
  TargeAudienceCover = addBookMark(TargeAudienceCover)

  TargeAudienceCover.addImage({
    path: props?.logoUrl,
    x: "7%",
    y: "30%",
    w: "30%",
    h: 0.5,
  });
  TargeAudienceCover.addText("Target Audience", {
    color: "000000",
    fontSize: 24,
    bold: true,
    x: "7%",
    y: "45%",
    w: "50%",
    h:0.2
  });
  TargeAudienceCover.addText(
    "GTM Intelligence helps to gather all the data of your Company & Customers in one place to enrich the on demand gen experience.",
    { color: "000000", fontSize: 18, x: "7%", y: "60%", w: "50%",h:0.3 }
  );
  // TargeAudienceCover.addImage({
  //   path: target_audience,
  //   x: "55%",
  //   y: "20%",
  //   w: "40%",
  //   h: "60%",
  // });

  return pptx;
};

export const TargetAudiencePPT = (pptx, data, Persona,props) => {

  let TargetAudienvePPT = pptx.addSlide();
  TargetAudienvePPT = addBookMark(TargetAudienvePPT)

  let base = {
    x: 0.5,
    y: 0.5,
  };
  TargetAudienvePPT.addText("Target Ideal Custom Profile", {
    color: "#000000",
    fontSize: 24,
    bold: true,
    x: 3.5,
    y: base.y,
    breakLine: true,
    h:0.2
  });

   base = {
    ...base,
    y: base.y+0.5,
  };

  TargetAudienvePPT.addImage({
    path: target_market_segement_icon,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  TargetAudienvePPT.addText("Target Market Segments", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.1,
    breakLine: true,
    h:0.1
  });
  TargetAudienvePPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 9,
    x: base.x,
    h: 0.8,
    y: base.y + 0.3,
  });
  TargetAudienvePPT.addText(data?.market_segments?.join(", "), {
    color: "#000000",
    fontSize: 14,
    x: base.x,
    y: base.y + 0.5,
    breakLine: true,
    w: 9,
    h:0.3
  });

   base = {
    ...base,
    y: base.y+1.4,
  };

  TargetAudienvePPT.addImage({
    path: target_industry,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  TargetAudienvePPT.addText("Target Industries", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.1,
    breakLine: true,
    h:0.1
  });
  TargetAudienvePPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 9,
    x: base.x,
    h: 0.8,
    y: base.y + 0.3,
  });
  TargetAudienvePPT.addText(data?.industries?.join(", "), {
    color: "#000000",
    fontSize: 14,
    x: base.x,
    y: base.y + 0.5,
    breakLine: true,
    w: 9,
    h:0.3
  });

   base = {
    ...base,
    y: base.y+1.4,
  };

  TargetAudienvePPT.addImage({
    path: target_region,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  TargetAudienvePPT.addText("Target Regions", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.1,
    breakLine: true,
    h:0.1
  });
  TargetAudienvePPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 9,
    x: base.x,
    h: 0.8,
    y: base.y + 0.3,
  });
  TargetAudienvePPT.addText(data?.regions?.join(", "), {
    color: "#000000",
    fontSize: 14,
    x: base.x,
    y: base.y + 0.5,
    breakLine: true,
    w: 9,
    h:0.3
  });


  // Persona slide Start

  Persona?.[0]?.personas?.forEach(value => {

  let PersonaPPT = pptx.addSlide();
  PersonaPPT = addBookMark(PersonaPPT,true)

  let basePersona = {
    x: 0.5,
    y: 0.5,
  };
  PersonaPPT.addText("User Personas", {
    color: "#000000",
    fontSize: 24,
    bold: true,
    x: 3.5,
    y: basePersona.y,
    breakLine: true,
    h:0.2
  });

  PersonaPPT.addText(value?.name, {
    color: props?.primaryColor,
    fontSize: 18,
    bold: true,
    x: 0.7,
    y: basePersona.y+0.5,
    breakLine: true,
    h:0.1
  });


  // Left Section
  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.8,
  };

  PersonaPPT.addImage({
    path: job_title,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.05,
    x: basePersona.x,
  });
  PersonaPPT.addText("Job Title", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(truncate(value?.job_title?.join(", "),60), {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 4,
    h:0.1
  });

  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.7,
  };

  PersonaPPT.addImage({
    path: job_functions,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.05,
    x: basePersona.x,
  });
  PersonaPPT.addText("Job Functions", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(truncate(value?.job_functions?.join(", "),60), {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 4,
    h:0.1
  });

  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.7,
  };

  PersonaPPT.addImage({
    path: job_levels,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.05,
    x: basePersona.x,
  });
  PersonaPPT.addText("Job Levels", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(truncate(value?.job_level?.join(", "),60), {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 4,
    h:0.1
  });

  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.75,
  };

  PersonaPPT.addImage({
    path: paint_point_solution_icon,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.07,
    x: basePersona.x,
  });
  PersonaPPT.addText("How does your product/solution solve these pain points?", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.15,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.6,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.pain_point_solution?truncate(value?.pain_point_solution,125):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 4,
    h:0.2
  });


  basePersona = {
    ...basePersona,
    y: basePersona.y + 1,
  };

  PersonaPPT.addImage({
    path: persona_message,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.07,
    x: basePersona.x,
  });
  PersonaPPT.addText("Messaging themes used for this persona", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.4,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.messaging_theme?.[0]?truncate(value?.messaging_theme?.[0],120):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.48,
    breakLine: true,
    w: 4,
    h:0.1
  });

  // Right Section
  basePersona = {
    x:basePersona.x+5,
    y: 1.3,
  };

  PersonaPPT.addImage({
    path: pin_icon,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.1,
    x: basePersona.x,
  });
  PersonaPPT.addText("Paint point 1", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.pain_points?.[0]?truncate(value?.pain_points?.[0],60):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 4,
    h:0.1
  });

  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.7,
  };

  PersonaPPT.addImage({
    path: pin_icon,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.1,
    x: basePersona.x,
  });
  PersonaPPT.addText("Pain Point 2", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.pain_points?.[1]?truncate(value?.pain_points?.[1],60):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.40,
    breakLine: true,
    w: 4,
    h:0.1
  });

  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.7,
  };

  PersonaPPT.addImage({
    path: pin_icon,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.1,
    x: basePersona.x,
  });
  PersonaPPT.addText("Paint Point 3", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.value?.pain_points?.[2]?truncate(value?.value?.pain_points?.[2],60):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 4,
    h:0.1
  });

  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.7,
  };

  PersonaPPT.addImage({
    path: persona_responsibility,
    h: 0.2,
    w: 0.2,
    y: basePersona.y+0.1,
    x: basePersona.x,
  });
  PersonaPPT.addText("Persona Details/Responsibilities", {
    color: props?.primaryColor,
    fontSize: 12,
    bold: true,
    x: basePersona.x + 0.2,
    y: basePersona.y + 0.1,
    breakLine: true,
    h:0.1
  });
  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.6,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.details_responsibilities?truncate(value?.details_responsibilities,125):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.55,
    breakLine: true,
    w: 4,
    h:0.1
  });


  basePersona = {
    ...basePersona,
    y: basePersona.y + 1,
  };


  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: basePersona.x,
    h: 0.4,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.messaging_theme?.[1]?truncate(value?.messaging_theme?.[1],120):"NA", {
    color: "#000000",
    fontSize: 10,
    x: basePersona.x,
    y: basePersona.y + 0.38,
    breakLine: true,
    w: 4,
    h:0.1
  });

  //Last row
  basePersona = {
    ...basePersona,
    y: basePersona.y + 0.5,
  };

  PersonaPPT.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 9.1,
    x: 0.5,
    h: 0.3,
    y: basePersona.y + 0.3,
  });
  PersonaPPT.addText(value?.messaging_theme?.[2]?truncate(value?.messaging_theme?.[2],140):"NA", {
    color: "#000000",
    fontSize: 10,
    x: 0.5,
    y: basePersona.y + 0.4,
    breakLine: true,
    w: 9,
    h:0.1
  });

});

  return pptx

};

export const SystemProcessCoverPPT = (pptx,props) => {
  let SystemProcessPageCover = pptx.addSlide();
  SystemProcessPageCover = addBookMark(SystemProcessPageCover)

  SystemProcessPageCover.addImage({
    path: wordmark_blue,
    x: "7%",
    y: "30%",
    w: "30%",
    h: 0.5,
  });
  SystemProcessPageCover.addText("Systems & Processes", {
    color: "000000",
    fontSize: 24,
    bold: true,
    x: "7%",
    y: "45%",
    w: "50%",
    h:0.2
  });
  SystemProcessPageCover.addText(
    "GTM Intelligence helps to gather all the data of your Company & Customers in one place to enrich the on demand gen experience.",
    { color: "000000", fontSize: 18, x: "7%", y: "60%", w: "50%", h:0.3 }
  );
  // SystemProcessPageCover.addImage({
  //   path: system_process,
  //   x: "55%",
  //   y: "40%",
  //   w: "40%",
  //   h: "40%",
  // });

  return pptx;
};

export const SystemProcessPPT = (pptx, data,props) => {
  let systemProcessPage = pptx.addSlide();
  systemProcessPage = addBookMark(systemProcessPage,true)

  let base = {
    x: 0.5,
    y: 0.5,
  };
  systemProcessPage.addText("Systems & Processes", {
    color: "#000000",
    fontSize: 24,
    bold: true,
    x: 3.5,
    y: base.y,
    breakLine: true,
    h:0.2
  });

  systemProcessPage.addText("Current Marketing Activity", {
    fontSize: 18,
    bold: true,
    x: base.x,
    y: base.y + 0.6,
    breakLine: true,
    h:0.1
  });

  systemProcessPage.addText("Curent Sales & Marketing Tools", {
    fontSize: 18,
    bold: true,
    x: base.x + 5,
    y: base.y + 0.6,
    breakLine: true,
    h:0.1
  });

  base = {
    ...base,
    y: base.y + 1,
  };

  systemProcessPage.addImage({
    path: channel_icon,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  systemProcessPage.addText("Channels", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.05,
    breakLine: true,
    h:0.1
  });
  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.7,
    y: base.y + 0.3,
  });

  let channelsValues = {}
  let swaped = swapKeyValue(current_marketing_activity_channels)
  channelsValues = data?.channels?.map(item => swaped[item])

  systemProcessPage.addText(channelsValues?.join(", "), {
    color: "#000000",
    fontSize: 12,
    x: base.x,
    y: base.y + 0.6,
    breakLine: true,
    w: 4.5,
    h:0.1
  });

  systemProcessPage.addImage({
    path: sales_marketing_icon,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x + 5,
  });
  systemProcessPage.addText("Sales & Marketing Tools", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 5.2,
    y: base.y + 0.05,
    breakLine: true,
    h:0.1
  });
  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.3,
    x: base.x + 5,
    h: 0.7,
    y: base.y + 0.3,
  });

  let emailMarketingTools = {}
  let swapedMarketingTool = swapKeyValue(current_sales_marketing_tools)
  emailMarketingTools = data?.marketing_tools?.map(item => swapedMarketingTool[item])

  systemProcessPage.addText(emailMarketingTools?.join(", "), {
    color: "#000000",
    fontSize: 12,
    x: base.x + 5,
    y: base.y + 0.6,
    breakLine: true,
    w: 4,
    h:0.1
  });

  base = {
    ...base,
    y: base.y + 1.2,
  };

  systemProcessPage.addImage({
    path: email_icon,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  systemProcessPage.addText("Email Marketing Progams", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.05,
    breakLine: true,
    h:0.1
  });
  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.9,
    y: base.y + 0.3,
  });


  let emailMarketingValues = {}
  let swapedMarketing = swapKeyValue(email_marketing_programs)
  emailMarketingValues = data?.email_marketing?.map(item => swapedMarketing[item])

  systemProcessPage.addText(emailMarketingValues?.join(", "), {
    color: "#000000",
    fontSize: 12,
    x: base.x,
    y: base.y + 0.75,
    breakLine: true,
    w: 4.5,
  });

  systemProcessPage.addText("Social media Accounts", {
    fontSize: 18,
    bold: true,
    x: base.x + 5,
    y: base.y,
    breakLine: true,
    h:0.1
  });

  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: base.x + 5,
    h: 0.4,
    y: base.y + 0.3,
  });
  systemProcessPage.addImage({
    path: fb_icon,
    h: 0.2,
    w: 0.2,
    y: base.y + 0.4,
    x: base.x + 5.2,
  });
  systemProcessPage.addText(data?.facebook ? data?.facebook_link : "-", {
    color: "#000000",
    fontSize: 14,
    x: base.x + 5.4,
    y: base.y + 0.45,
    breakLine: true,
    w: 4,
    h:0.1
  });

  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: base.x + 5,
    h: 0.4,
    y: base.y + 0.8,
  });
  systemProcessPage.addImage({
    path: insta_icon,
    h: 0.2,
    w: 0.2,
    y: base.y + 0.9,
    x: base.x + 5.2,
  });
  systemProcessPage.addText(data?.instagram ? data?.instagram_link : "-", {
    color: "#000000",
    fontSize: 14,
    x: base.x + 5.4,
    y: base.y + 0.95,
    breakLine: true,
    w: 4,
    h:0.1
  });

  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: base.x + 5,
    h: 0.4,
    y: base.y + 1.3,
  });
  systemProcessPage.addImage({
    path: linkedin_icon,
    h: 0.2,
    w: 0.2,
    y: base.y + 1.4,
    x: base.x + 5.2,
  });
  systemProcessPage.addText(data?.linkedin ? data?.linkedin_link : "-", {
    color: "#000000",
    fontSize: 14,
    x: base.x + 5.4,
    y: base.y + 1.45,
    breakLine: true,
    w: 4,
    h:0.1
  });

  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.1,
    x: base.x + 5,
    h: 0.4,
    y: base.y + 1.8,
  });
  systemProcessPage.addImage({
    path: youtube_icon,
    h: 0.2,
    w: 0.2,
    y: base.y + 1.9,
    x: base.x + 5.2,
  });
  systemProcessPage.addText(data?.youtube ? data?.youtube_link : "-", {
    color: "#000000",
    fontSize: 14,
    x: base.x + 5.4,
    y: base.y + 1.95,
    breakLine: true,
    w: 4,
    h:0.1
  });

  base = {
    ...base,
    y: base.y + 1.3,
  };

  systemProcessPage.addImage({
    path: branded_icon,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  systemProcessPage.addText("Branded Keywords", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.05,
    breakLine: true,
    h:0.1
  });
  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.4,
    y: base.y + 0.3,
  });
  systemProcessPage.addText(data?.branded_keywords?.length >0 ? truncate(data?.branded_keywords?.join(", "), 60):"NA", {
    color: "#000000",
    fontSize: 12,
    x: base.x,
    y: base.y + 0.4,
    breakLine: true,
    w: 4.5,
    h:0.1
  });

  base = {
    ...base,
    y: base.y + 0.8,
  };

  systemProcessPage.addImage({
    path: non_branded_icon,
    h: 0.2,
    w: 0.2,
    y: base.y,
    x: base.x,
  });
  systemProcessPage.addText("Non Branded Keywords", {
    color: props?.primaryColor,
    fontSize: 16,
    bold: true,
    x: base.x + 0.2,
    y: base.y + 0.05,
    breakLine: true,
    h:0.1
  });
  systemProcessPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 9.1,
    x: base.x,
    h: 0.4,
    y: base.y + 0.3,
  });
  systemProcessPage.addText(data?.non_branded_keywords?.length > 0 ? truncate(data?.non_branded_keywords?.join(", "),120):"NA", {
    color: "#000000",
    fontSize: 12,
    x: base.x,
    y: base.y + 0.4,
    breakLine: true,
    w: 9,
    h:0.1
  });



  let categoriesPage = pptx.addSlide();
  //categoriesPage = addBookMark(categoriesPage,true)
  categoriesPage.addImage({
    path: wordmark_blue,
    x: "7%",
    y: "45%",
    w: "50%",
    h:0.4
  });
  categoriesPage.addText("Systems & Processes", {
    color: "#000000",
    fontSize: 24,
    bold: true,
    x: 3.5,
    y: 0.7,
    breakLine: true,
    h:0.2
  });
  categoriesPage.addImage({
    path: category_icon,
    h: 0.2,
    w: 0.2,
    y: 2,
    x: base.x,
  });
  categoriesPage.addText(
    "Do you currently have Processes/Rules defing for these following categories?",
    {
      color: props?.primaryColor,
      fontSize: 16,
      bold: true,
      x: base.x + 0.2,
      y: 1.25,
      breakLine: true,
      h:0.1
    }
  );

  let baseSlide2 = {
    y: 1.5,
  };
  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Lead/Contact De-duplication", {
    color: "#000000",
    fontSize: 10,
    x: base.x,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 4.5,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.lead_contact_de_duplication
      ? truncate(data?.lead_contact_de_duplication_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x + 4.8,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Lead/Contact Assignment", {
    color: "#000000",
    fontSize: 10,
    x: base.x + 4.8,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 4.5,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.lead_contact_assignment
      ? truncate(data?.lead_contact_assignment_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x + 4.8,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  baseSlide2 = {
    y: baseSlide2.y + 0.9,
  };
  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Lead/Contact Status", {
    color: "#000000",
    fontSize: 10,
    x: base.x,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 4.5,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.lead_contact_status
      ? truncate(data?.lead_contact_status_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x + 4.8,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Lead/Contact Source", {
    color: "#000000",
    fontSize: 10,
    x: base.x + 4.8,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 4.5,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.lead_contact_source
      ? truncate(data?.lead_contact_source_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x + 4.8,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  baseSlide2 = {
    y: baseSlide2.y + 0.9,
  };
  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText(
    "Lead/Contact Creation (When to Create a Contact vs. Lead)",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.1,
      breakLine: true,
      w: 4.5,
      bold: true,
      h:0.1
    }
  );
  categoriesPage.addText(
    data?.lead_contact_creation
      ? truncate(data?.lead_contact_creation_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x + 4.8,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Lead/Contact Conversion (Lead to Contact)", {
    color: "#000000",
    fontSize: 10,
    x: base.x + 4.8,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 4.5,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.lead_contact_conversion
      ? truncate(data?.lead_contact_conversion_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x + 4.8,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );
  baseSlide2 = {
    y: baseSlide2.y + 0.9,
  };
  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText(
    "Account/Company Creation (When to Create an Account)",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.1,
      breakLine: true,
      w: 4.5,
      bold: true,
      h:0.1
    }
  );
  categoriesPage.addText(
    data?.account_company_creation
      ? truncate(data?.account_company_creation_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 4.6,
    x: base.x + 4.8,
    h: 0.8,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Opportunity/Deal Creation", {
    color: "#000000",
    fontSize: 10,
    x: base.x + 4.8,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 4.5,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.opportunity_deal_creation
      ? truncate(data?.opportunity_deal_creation_process, 220)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x + 4.8,
      y: baseSlide2.y + 0.5,
      breakLine: true,
      w: 4.6,
      h:0.1
    }
  );

  baseSlide2 = {
    y: baseSlide2.y + 0.9,
  };
  categoriesPage.addShape(pptx.ShapeType.rect, {
    fill: { color: props?.backgroundColor},
    w: 9.4,
    x: base.x,
    h: 0.45,
    y: baseSlide2.y,
  });
  categoriesPage.addText("Marketing & Sales SLA’s", {
    color: "#000000",
    fontSize: 10,
    x: base.x,
    y: baseSlide2.y + 0.1,
    breakLine: true,
    w: 9.4,
    bold: true,
    h:0.1
  });
  categoriesPage.addText(
    data?.marketing_sales_sla
      ? truncate(data?.marketing_sales_sla_process, 150)
      : "NA",
    {
      color: "#000000",
      fontSize: 10,
      x: base.x,
      y: baseSlide2.y + 0.3,
      breakLine: true,
      w: 9.4,
      h:0.1
    }
  );

  return pptx;
};

function truncate(str, max) {
  return str?.length > max ? str?.substr(0, max - 1) + "…" : str;
}

function swapKeyValue (obj)  {
  let ret = {}
  for (const key in obj) {
     ret[obj[key]] = key
  }
  return ret
}
