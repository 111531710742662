// import external modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import MainLayout from 'containers/MainLayout';
import { Auth } from 'aws-amplify';
import { logout } from 'store/actions/loginAction';
import config from '../../src/aws-exports';
import AccountSuspended from 'pages/user/Accounts/AccountSuspended';

class MainLayoutRoute extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  checkAwsToken() {
    const userClientId = config.aws_user_pools_web_client_id;
    const lastAuthUserKey = `CognitoIdentityServiceProvider.${userClientId}.LastAuthUser`;
    const lastAuthUser = localStorage.getItem(lastAuthUserKey);
    if (!lastAuthUser) {
      this.handleSignOut();
    }
  }

  handleSignOut = async () => {
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        this.props.logOut();
      })
      .catch((err) => console.log(err));
  };

  checkSuspension = (groups, accountDetails) => {
    return groups?.includes('suspend') || accountDetails?.disabled === true;
  };

  checkUserDeleted = async () => {
    const cognitoUser = await Auth.currentUserInfo();
    if (!cognitoUser || Object.keys(cognitoUser).length === 0) {
      this.handleSignOut();
    }
  };

  componentDidMount() {
    if (typeof window !== 'undefined' && window.userflow) {
      const { userData } = this.props;
      if (userData?.sub) {
        // Identify the user
        window.userflow.identify(userData.sub, {
          name: `${userData?.given_name || ''} ${userData?.family_name || ''}`.trim(),
          email: userData?.email,
          signed_up_at: new Date().toISOString(),
        });
      } else {
        console.error("User ID (sub) is undefined.");
      }
    }
  }

  render() {
    const { token, groups, render, logOut, accountDetails, userData, ...rest } =
      this.props;
    this.checkAwsToken();
    this.checkUserDeleted();

    const prevUrl = window.location.pathname;

    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {!token && (
              <Redirect
                from={matchProps.path}
                to={`/signin?redirect_url=${prevUrl}`}
              />
            )}
            {token && groups.length === 0 && (
              <Redirect from={matchProps.path} to="/setup" />
            )}
            {token && !this.checkSuspension(groups, accountDetails) ? (
              <MainLayout>{render(matchProps)}</MainLayout>
            ) : (
              <AccountSuspended logOut={logOut} />
            )}
          </Fragment>
        )}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    accountDetails: state.loginReducer.accountDetails,
    userData: state.loginReducer.userData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logOut: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayoutRoute);
