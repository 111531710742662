/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Col,
  Row,
  Form,
  Select,
  Button,
  Input,
  Table,
  Tooltip,
  Divider,
  Popover,
  Space,
  Radio,
  Modal,
  Empty,
  Typography,
  InputNumber,
  message,
  Popconfirm,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import basicSalaryIcon from "../../assets/Images/basicSalaryIcon.svg";
import variableCompensationIcon from "../../assets/Images/variableCompensationIcon.svg";
import variableIcon from "../../assets/Images/variableIcon.svg";
import OTEIcon from "../../assets/Images/OTEIcon.svg";
import RevenueTextField from "commons/RevenueTextField";
import {
  createSDRCompensationModel,
  listSDRCompensationModels,
  setLoader,
  updateSDRCompensationModel,
  listSdrMeetingAndOpportunityModelsData,
  deleteCompesationModelData,
} from "store/actions/sdrAction";
import { useDispatch, useSelector } from "react-redux";
import sdr_compansation_preview from "assets/Images/sdr_compansation_preview.png";
import PreviewInfo from "commons/PreviewInfo";
import { numberWithCommas } from "utility/context/CommonMethods";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";

const { Option } = Select;

export default function SdrCompensationModal(props) {
  const { HasUnsavedChanges, setHasUnsavedChanges } = props;

  const [ModelType, setModelType] = useState("OTE");
  const onChange = (e) => {
    setModelType(e.target.value);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [OTE, setOTE] = useState(0);
  const [baseSalary, setbaseSalary] = useState(0);
  const [VariableCompensation, setVariableCompensation] = useState(0);
  const [VariableCompensationRate, setVariableCompensationRate] = useState(0);

  const dispatch = useDispatch();
  //   table
  // Comes from SDR capacity after API integration

  const [TableData, setTableData] = useState([]);

  const [VarCompBreakdownTotal, setVarCompBreakdownTotal] = useState(0);

  const [StageMetricRateTotal, setStageMetricRateTotal] = useState(0);

  const [selectedModel, setselectedModel] = useState();

  const SDRModels = useMemo(
    () => ({ BASE_SALARY: "Base salary", OTE: "OTE" }),
    []
  );

  const columns = [
    {
      title: (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <span>Enter Each Stage Metric %</span>

          <Tooltip
            placement="bottomRight"
            color="black"
            title={
              <span>
                The percentages in which each represented stage influences
                variable compensation, which must total 100%. Note: Depending on
                the selected model type, this is either calculated or set.
              </span>
            }
          >
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 600,
                fontFamily: "cursive",
                color: "#A9A9A9",
                fontSize: 15,
                cursor: "pointer",
              }}
            >
              i
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "EachMetricRate",
      key: "StageMetricRate",
      render: (text, item) => (
        <Input
          value={item?.EachMetricRate}
          disabled={ModelType !== "OTE"}
          onChange={(e) => ChangeRate(e.target.value, item)}
          className="general-input"
        />
      ),
      width: 110,
    },
    {
      title: (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <span>Stage Metric</span>

          <Tooltip
            placement="bottomRight"
            color="black"
            title={
              <span>
                Based on your inputs from SDR Capacity Calculator, this is the
                list of stages in which your SDRs have an influence.
              </span>
            }
          >
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 600,
                fontFamily: "cursive",
                color: "#A9A9A9",
                fontSize: 15,
                cursor: "pointer",
              }}
            >
              i
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "StageMetric",
      key: "StageMetric",
      render: (text) => (
        <Tooltip placement="top" title={text}>
          <span style={{ color: "#7F56D9" }}>{text}</span>
        </Tooltip>
        
      ),
      width: 100,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <span>Reference</span>

          <Tooltip
            placement="bottomRight"
            color="black"
            title={
              <span>
                Based on your inputs from SDR Capacity Calculator, this is the
                expected amount that each SDR can help to deliver.
              </span>
            }
          >
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 600,
                fontFamily: "cursive",
                color: "#A9A9A9",
                fontSize: 15,
                cursor: "pointer",
              }}
            >
              i
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "Reference",
      key: "Reference",
      render: (text) => <>{numberWithCommas(text)}</>,
      width: 100,
    },
    {
      title: (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <span>Reference Rate</span>

          <Tooltip
            placement="bottomRight"
            color="black"
            title={
              <span>
                This represents the compensation rate if 100% of variable
                compensation was tied to this stage.
              </span>
            }
          >
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 600,
                fontFamily: "cursive",
                color: "#A9A9A9",
                fontSize: 15,
                cursor: "pointer",
              }}
            >
              i
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "ReferenceRate",
      key: "ReferenceRate",
      render: (text, record) => (
        <>
          {record?.StageMetric !== "revenue" && "$ "}
          {numberWithCommas(text)}
          {record?.StageMetric === "revenue" && " %"}
        </>
      ),
      width: 100,
    },
    {
      title: (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <span>Var Comp Breakdown</span>

          <Tooltip
            placement="bottomRight"
            color="black"
            title={
              <span>
                Based on your inputs here, this is the amount of variable
                compensation tied to each stage outcome.
              </span>
            }
          >
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 600,
                fontFamily: "cursive",
                color: "#A9A9A9",
                fontSize: 15,
                cursor: "pointer",
              }}
            >
              i
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "VarCompBreakdown",
      key: "VarCompBreakdown",
      render: (text) => <>$ {numberWithCommas(text)}</>,
      width: 100,
    },
    {
      title: (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 2,
          }}
        >
          <span>Stage Metric Rate</span>

          <Tooltip
            placement="bottomRight"
            color="black"
            title={
              <span>
                The compensation rate for each stage referenced. Note: Depending
                on the selected model type, this is either calculated or set.
              </span>
            }
          >
            <span
              style={{
                fontStyle: "italic",
                fontWeight: 600,
                fontFamily: "cursive",
                color: "#A9A9A9",
                fontSize: 15,
                cursor: "pointer",
              }}
            >
              i
            </span>
          </Tooltip>
        </div>
      ),
      dataIndex: "StageMetricRate",
      key: "StageMetricRate",
      render: (text, record) => (
        <>
          {ModelType !== "OTE" ? (
            <>
              <InputNumber
                value={text}
                onChange={(e) => setStageMetricRate(e, record)}
              ></InputNumber>
            </>
          ) : (
            <>
              {record?.StageMetric !== "revenue" && "$ "}{" "}
              {numberWithCommas(text)}
              {record?.StageMetric === "revenue" && " %"}
            </>
          )}
        </>
      ),
      width: 100,
    },
  ];

  const {
    SDRCompensationModels,
    SDRCalculatorSummary,
    SDRActivityTrackerList,
    isLoading,
  } = useSelector((state) => state.sdrReducer);
  const {
    groups: account,
    userPermission,
    viewType,
  } = useSelector((state) => state.loginReducer);

  useEffect(() => {
    dispatch(listSDRCompensationModels());
    dispatch(listSdrMeetingAndOpportunityModelsData());
  }, []);

  useEffect(() => {
    if (SDRCompensationModels?.length > 0) {
      setselectedModel(SDRCompensationModels?.[0]);
    } else {
      setselectedModel();
      setInitials();
    }
  }, [SDRCompensationModels]);

  const setInitials = () => {
    setOTE(60000);
    setStageMetricRateTotal(25);
  };

  const content = (
    <div>
      <Radio.Group onChange={onChange} value={ModelType}>
        <Space direction="vertical">
          <Radio value={"OTE"}>
            OTE, variable compensation % <br />
            and stage metric%
          </Radio>
          <Radio value={"BASE_SALARY"}>
            Base salary & Multiple stage <br />
            metric rate
          </Radio>
        </Space>
      </Radio.Group>
    </div>
  );

  useEffect(() => {
    if (ModelType === "OTE") {
      setbaseSalary(
        Number(OTE * (1 - VariableCompensationRate / 100)).toFixed(0)
      );
      setVariableCompensation(
        Number(Number((OTE * VariableCompensationRate) / 100).toFixed(0))
      );
    }
  }, [OTE, VariableCompensationRate]);

  useEffect(() => {
    if (ModelType === "OTE") {
      setTableData((old) => {
        return old.map((item) => {
          let multiple = item?.StageMetric === "revenue" ? 100 : 1;
          return {
            ...item,
            ReferenceRate: isFinite(
              parseFloat(
                (VariableCompensation / item.Reference) * multiple
              ).toFixed(2)
            )
              ? parseFloat(
                  (VariableCompensation / item.Reference) * multiple
                ).toFixed(2)
              : 0,
            VarCompBreakdown: isFinite(
              parseFloat(
                Number(
                  (VariableCompensation / item.Reference) *
                    item.EachMetricRate *
                    item.Reference
                ) / 100
              ).toFixed(2)
            )
              ? parseFloat(
                  Number(
                    (VariableCompensation / item.Reference) *
                      item.EachMetricRate *
                      item.Reference
                  ) / 100
                ).toFixed(2)
              : 0,
            StageMetricRate: isFinite(
              parseFloat(
                ((VariableCompensation / item.Reference) *
                  multiple *
                  item.EachMetricRate) /
                  100
              ).toFixed(2)
            )
              ? parseFloat(
                  (VariableCompensation / item.Reference) * multiple
                ).toFixed(2)
              : 0,
          };
        });
      });
    }
  }, [VariableCompensation]);

  const ChangeRate = (value, metric) => {
    if (value === "") {
      value = 0;
    }
    setHasUnsavedChanges(true);
    if (value <= 100 && value !== "") {
      value = Number(value);

      setTableData((old) => {
        return old.map((item) => {
          if (item.StageMetric === metric?.StageMetric) {
            item.EachMetricRate = value;
            item.VarCompBreakdown = parseFloat(
              Number(
                (VariableCompensation / item.Reference) *
                  item.EachMetricRate *
                  item.Reference
              ) / 100
            ).toFixed(2);
            item.StageMetricRate = parseFloat(
              (parseFloat(VariableCompensation / item.Reference).toFixed(2) *
                item.EachMetricRate) /
                100
            ).toFixed(2);
          }
          return item;
        });
      });
    }
  };

  const [EachStageMetricTotal, setEachStageMetricTotal] = useState(0);

  const [summaryData, setsummaryData] = useState([]);
  useEffect(() => {
    let VarCompBreakdownTotal = 0;
    let EachStageMetricTotal = 0;
    let StageMetricRateTotal = 0;
    TableData?.forEach((item) => {
      VarCompBreakdownTotal += Number(item?.VarCompBreakdown);
      EachStageMetricTotal += Number(item?.EachMetricRate);
      StageMetricRateTotal += Number(item?.StageMetricRate);
    });
    setVarCompBreakdownTotal(parseFloat(VarCompBreakdownTotal).toFixed(2));
    setEachStageMetricTotal(parseFloat(EachStageMetricTotal).toFixed(2));
    setStageMetricRateTotal(parseFloat(StageMetricRateTotal).toFixed(2));
  }, [TableData]);

  useEffect(() => {
    let data = [
      {
        title: "Base Salary",
        data: baseSalary,
      },
      ...TableData?.map((item) => {
        if (item?.StageMetric !== "revenue")
          return {
            title: `Variable compensation component per ${item?.StageMetric}`,
            data: item?.StageMetricRate,
          };
      }).filter((item) => item?.title),
      {
        title: "For Total OTE of",
        data: OTE,
      },
    ];
    setsummaryData(data);
  }, [baseSalary, OTE, TableData]);

  // ----------------------------------------------------------------------------------------------------------------------------
  //   Calculatios for ModelType BaseSalary
  useEffect(() => {
    if (ModelType !== "OTE") {
      setOTE(
        Number(Number(baseSalary) + Number(VariableCompensation)).toFixed(2)
      );
      setVariableCompensationRate(
        parseFloat(
          (VariableCompensation /
            (Number(baseSalary) + Number(VariableCompensation))) *
            100
        ).toFixed(2)
      );
    }
  }, [baseSalary, VariableCompensation]);

  const setStageMetricRate = useCallback((e, record) => {
    let value = e;
    if (value === "") {
      value = 0;
    }

    if (!isNaN(value)) {
      setTableData((old) => {
        return old.map((item) => {
          if (item?.StageMetric === record?.StageMetric) {
            item.StageMetricRate =
              item.StageMetricRate === 0 ? Number(value) : value;
            item.VarCompBreakdown = parseFloat(item.Reference * value).toFixed(
              2
            );
          }
          return item;
        });
      });
    }
  }, []);

  // Seting value of Stage Metric Rate on change total
  useEffect(() => {
    if (ModelType !== "OTE") {
      setTableData((old) => {
        return old.map((item) => {
          item.EachMetricRate = isNaN(
            parseFloat(
              (item.VarCompBreakdown / VarCompBreakdownTotal) * 100
            ).toFixed(2)
          )
            ? 0
            : parseFloat(
                (item.VarCompBreakdown / VarCompBreakdownTotal) * 100
              ).toFixed(2);
          return item;
        });
      });

      setVariableCompensation(VarCompBreakdownTotal);
    }
  }, [VarCompBreakdownTotal]);

  useEffect(() => {
    if (ModelType !== "OTE") {
      setTableData((old) => {
        return old.map((item) => {
          item.ReferenceRate = isNaN(
            parseFloat(
              100 * (item.StageMetricRate / item.EachMetricRate)
            ).toFixed(2)
          )
            ? 0
            : isFinite(
                parseFloat(
                  100 * (item.StageMetricRate / item.EachMetricRate)
                ).toFixed(2)
              )
            ? parseFloat(
                100 * (item.StageMetricRate / item.EachMetricRate)
              ).toFixed(2)
            : 0;
          return item;
        });
      });
    }
  }, [TableData?.[0]?.EachMetricRate]);

  const [form] = Form.useForm();

  const HandleAddModel = (values) => {
    if (String(values?.name).trim()?.length > 0) {
      dispatch(setLoader(true));

      let inputData = {
        account: account[0],
        ...values,
      };
      dispatch(createSDRCompensationModel(inputData)).then((res) => {
        form.resetFields();
        handleCancel();
      });
    } else {
      message.error("Please enter valid name");
      form.resetFields();
    }
  };

  useEffect(() => {
    if (selectedModel) {
      if (selectedModel?.SDRCompensationModelStageMetric) {
        let dataObj = JSON.parse(
          selectedModel?.SDRCompensationModelStageMetric
        );
        Object.keys(dataObj).forEach((key) => {
          setTableData((old) => {
            return old.map((item) => {
              if (item.StageMetric === key) {
                let multiple = item?.StageMetric === "revenue" ? 100 : 1;
                item.EachMetricRate = dataObj?.[key]?.EachMetricRate;
                item.StageMetricRate = dataObj?.[key]?.StageMetricRate;
                item.VarCompBreakdown = isFinite(
                  parseFloat(
                    Number(
                      (VariableCompensation / item.Reference) *
                        item.EachMetricRate *
                        item.Reference
                    ) / 100
                  ).toFixed(2)
                )
                  ? parseFloat(
                      Number(
                        (VariableCompensation / item.Reference) *
                          item.EachMetricRate *
                          item.Reference
                      ) / 100
                    ).toFixed(2)
                  : 0;
                item.ReferenceRate = isFinite(
                  parseFloat(
                    (VariableCompensation / item.Reference) * multiple
                  ).toFixed(2)
                )
                  ? parseFloat(
                      (VariableCompensation / item.Reference) * multiple
                    ).toFixed(2)
                  : 0;
              }
              return item;
            });
          });
        });
      } else {
        setTableData((old) => {
          return old.map((item) => {
            item.EachMetricRate = 0;
            return item;
          });
        });
      }
      selectedModel?.model_type && setModelType(selectedModel?.model_type);
      setOTE(selectedModel?.OTE ? selectedModel?.OTE : 60000);
      setbaseSalary(
        selectedModel?.base_salary ? selectedModel?.base_salary : 45000
      );
      setVariableCompensationRate(
        selectedModel?.variableCompensation
          ? selectedModel?.variableCompensation
          : 25
      );
    }
  }, [selectedModel]);

  useEffect(() => {
    let data = [];
    Object.keys(SDRCalculatorSummary).forEach((key, index) => {
      let dataObj = {
        key: index,
        StageMetric: key,
        Reference: !isNaN(SDRCalculatorSummary[key])
          ? SDRCalculatorSummary[key]
          : 0,
        // ReferenceRate: 0,
        VarCompBreakdown: 0,
        StageMetricRate: 0,
        EachMetricRate: 0,
      };
      data.push(dataObj);
    });

    setTableData(data);
    dispatch(listSDRCompensationModels());
    setVariableCompensation(VariableCompensation);
  }, [SDRCalculatorSummary]);

  const UpdateCompensationModel = () => {
    dispatch(setLoader(true));
    let dataObj = {};
    TableData.forEach((item) => {
      dataObj[item.StageMetric] = {
        EachMetricRate: Number(item?.EachMetricRate),
        StageMetricRate: Number(item?.StageMetricRate),
      };
    });
    let inputData = {
      id: selectedModel?.id,
      OTE: parseInt(OTE),
      base_salary: parseInt(baseSalary),
      variableCompensation: parseInt(VariableCompensationRate),
      model_type: ModelType,
      SDRCompensationModelStageMetric: JSON.stringify(dataObj),
      name: selectedModel?.name,
    };

    dispatch(updateSDRCompensationModel(inputData));
    setHasUnsavedChanges(false);
  };

  const onDeleteModel = (value) => {
    const matchModel = SDRActivityTrackerList?.some(
      (item) => item?.compensationModelID === value?.id
    );
    if (matchModel) {
      message.error(
        "This compensation model is associated with SDR. Please remove there first."
      );
    } else {
      return dispatch(deleteCompesationModelData({ id: value?.id })).then(
        (s) => s
      );
    }
  };

  return (
     
    <>
      <Row
        className="sdrCompensation"
        justify="center"
        style={{ padding: "10px 10px 0 10px", marginTop: "20px" }}
      >
        <Col span={18} lg={18} md={18} sm={24} xs={24}>
          <Row justify="center">
            <Col span={19} lg={19} md={19} sm={24} xs={24}>
              <Form layout="vertical" className="general-form">
                <Form.Item
                  label={
                    <small
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "20px",
                        marginBottom: "5px",
                        display: "inline-block",
                      }}
                    >
                      <b>Select Your Model</b>
                    </small>
                  }
                >
                  <Select
                    value={selectedModel?.id}
                    onChange={(e) =>
                      setselectedModel(
                        SDRCompensationModels?.find((item) => item.id === e)
                      )
                    }
                    style={{ width: "100%" }}
                    placeholder="Please select Model"
                    className="general-input-select"
                  >
                    {SDRCompensationModels?.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col
              span={5}
              lg={5}
              md={5}
              sm={24}
              xs={24}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                marginBottom: 24,
              }}
            >
              <Button
                onClick={() => {
                  TrackClickFunction(
                    "demand-gen-sales-and-marketing-sdr-compensation-model-save-button"
                  );
                  showModal();
                }}
                className="primaryButton"
                style={{ height: "45px", marginLeft: "15PX" }}
                data-heap="demand-gen-sales-and-marketing-sdr-compensation-model-save-button"
              >
                Add New Model
              </Button>
            </Col>
          </Row>

          {SDRCompensationModels?.length > 0 ? (
            <>
              <Row>
                <Col
                  span={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="panel-design"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f5f7fa",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <Typography.Title
                        style={{
                          marginBottom: 0,
                          margin: 15,
                          color: "#1D2939",
                        }}
                        level={4}
                        editable={{
                          onChange: (e) => {
                            setselectedModel((old) => ({ ...old, name: e }));
                          },
                        }}
                      >
                        {selectedModel?.name}
                      </Typography.Title>
                    </div>
                    <div>
                      <Popconfirm
                        title="Are you sure you want to delete this selected compensation model?"
                        onConfirm={() => onDeleteModel(selectedModel)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ size: "middle", type: "primary" }}
                        cancelButtonProps={{
                          size: "large",
                          type: "secondary",
                        }}
                        placement="topRight"
                        
                      >
                        <DeleteOutlined
                          style={{
                            color: "#7F56D9",
                            marginTop: 20,
                            fontSize: 17,
                          }}
                        />
                      </Popconfirm>
                    </div>
                  </div>

                  <Popover content={content} placement="bottomRight">
                    <Button
                      type="text"
                      className="selectModalTypeBtn"
                      style={{ color: "#7F56D9", background: "transparent" }}
                    >
                      <strong>
                        {SDRModels[ModelType]} <DownOutlined />
                      </strong>
                    </Button>
                  </Popover>
                </Col>
              </Row>

              <Form layout="vertical" className="general-form">
                {ModelType === "OTE" ? (
                  <Row style={{ width: "100%", marginTop: 30 }} gutter={10}>
                    <Col span={11} lg={11} md={11} sm={24} xs={24}>
                      <Form.Item
                        label={
                          <small>
                            <b>OTE</b>
                          </small>
                        }
                      >
                        <RevenueTextField
                          dollercirclestyle={{
                            position: "absolute",
                            zIndex: 1,
                            left: 8,
                          }}
                          className="revenue-text sdrRevenue-field"
                          style={{
                            margin: "0px",
                            padding: 0,
                          }}
                          size={"small"}
                          placeholder="Enter OTE"
                          onChange={(value) => {
                            if (!isNaN(value)) {
                              setOTE(value);
                              setHasUnsavedChanges(true);
                            }
                          }}
                          value={OTE === 0 ? "" : OTE}
                        />
                        {/* <Input value={OTE} onChange={e => {
                                    if(!isNaN(e.target.value))
                                    setOTE(e.target.value)}} prefix={<DollarCircleOutlined />} /> */}
                      </Form.Item>

                      <Form.Item
                      // label={<small><b>Variable Compensation %</b></small>}
                      >
                        <Row style={{ padding: 0 }} className="sdr-slider">
                          {/* <Col span={20}  md={20} lg={20} sm={20} xs={20} style={{margin:'auto', border: "1px solid #DEDEDE", borderRadius: 7 }} >
                                        <Slider defaultValue={30} />

                                    </Col>
                                    <Col className="pl-0" span={4} lg={4} md={4} sm={4} xs={4} style={{ paddingLeft: 5 }}>
                                    <InputNumber
                                    className="variableInput"
                                    min={1} max={100}
                                    defaultValue={30}
                                    formatter={(value) => `${value}%`}
                                    parser={(value) => value.replace("%", "")}
                                        style={{
                                        height:'100%',
                                        width: "100%",
                                        color: "#0F61DB",
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        }}
                                    />
                                    </Col> */}

                          <RevenueTextField
                            uperText={
                              <span>
                                <b>Variable Compensation %</b>
                              </span>
                            }
                            minVal={0}
                            maxVal={100}
                            name={"VariableCompensation"}
                            value={VariableCompensationRate}
                            onChange={(name, value) => {
                              setVariableCompensationRate(value);
                              setHasUnsavedChanges(true);
                            }}
                            isSlider={true}
                          />
                        </Row>
                      </Form.Item>
                    </Col>

                    <Col
                      span={13}
                      lg={13}
                      md={13}
                      sm={24}
                      xs={24}
                      style={{ height: "100%" }}
                      className="ModalTypebox"
                    >
                      <Row
                        style={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 7,
                          padding: "20px",
                        }}
                      >
                        <Col span={11} lg={11} md={11} sm={24} xs={24}>
                          <img src={basicSalaryIcon} alt="" />
                          <h3
                            style={{
                              marginBottom: 0,
                              marginTop: 10,
                              fontWeight: 600,
                            }}
                          >
                            Base salary
                          </h3>
                          <h2 style={{ color: "#0F61DB", fontWeight: 600 }}>
                            ${numberWithCommas(baseSalary)}
                          </h2>
                        </Col>

                        <Col span={11} lg={11} md={11} sm={24} xs={24}>
                          <img src={variableCompensationIcon} alt="" />
                          <h3
                            style={{
                              marginBottom: 0,
                              marginTop: 10,
                              fontWeight: 600,
                            }}
                          >
                            Variable compensation
                          </h3>
                          <h2 style={{ color: "#0F61DB", fontWeight: 600 }}>
                            ${numberWithCommas(VariableCompensation)}
                          </h2>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ width: "100%", marginTop: 30 }} gutter={10}>
                    <Col span={11} lg={11} md={11} sm={24} xs={24}>
                      <Form.Item
                        label={
                          <small>
                            <b>Base Salary</b>
                          </small>
                        }
                      >
                        <RevenueTextField
                          dollercirclestyle={{
                            position: "absolute",
                            zIndex: 1,
                            left: 8,
                          }}
                          className="revenue-text"
                          style={{
                            margin: "0px",
                            padding: 0,
                          }}
                          size="small"
                          placeholder="Enter Base Salary"
                          onChange={(value) => {
                            if (!isNaN(value)) setbaseSalary(value);
                          }}
                          value={baseSalary === 0 ? "" : baseSalary}
                        />
                        {/* <Input value={baseSalary} onChange={e => {
                                    if(!isNaN(e.target.value))
                                    setbaseSalary(e.target.value)}} prefix={<DollarCircleOutlined />} 
                                    

                                    formatter={(value) => {

                                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            }/> */}
                      </Form.Item>
                    </Col>

                    <Col
                      span={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ height: "100%" }}
                      className="ModalTypebox"
                    >
                      <Row
                        style={{
                          backgroundColor: "#FFFFFF",
                          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                          borderRadius: 7,
                          padding: "20px",
                        }}
                      >
                        <Col
                          span={8}
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={OTEIcon}
                            style={{ marginRight: 10 }}
                            alt=""
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <h3 style={{ marginBottom: 0, fontWeight: 600 }}>
                              OTE
                            </h3>
                            <h2
                              style={{
                                color: "#0F61DB",
                                fontWeight: 600,
                                marginBottom: 0,
                              }}
                            >
                              ${numberWithCommas(OTE)}
                            </h2>
                          </div>
                        </Col>

                        <Col
                          span={8}
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={variableIcon}
                            style={{ marginRight: 10 }}
                            alt=""
                          />
                          <div>
                            <h3 style={{ marginBottom: 0, fontWeight: 600 }}>
                              Variable %
                            </h3>
                            <h2
                              style={{
                                color: "#0F61DB",
                                fontWeight: 600,
                                marginBottom: 0,
                              }}
                            >
                              {VariableCompensationRate}%
                            </h2>
                          </div>
                        </Col>

                        <Col
                          span={8}
                          lg={8}
                          md={8}
                          sm={24}
                          xs={24}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={variableCompensationIcon}
                            style={{ marginRight: 10 }}
                            alt=""
                          />
                          <div>
                            <h3 style={{ marginBottom: 0, fontWeight: 600 }}>
                              Variable compensation
                            </h3>
                            <h2
                              style={{
                                color: "#0F61DB",
                                fontWeight: 600,
                                marginBottom: 0,
                              }}
                            >
                              ${numberWithCommas(VariableCompensation)}
                            </h2>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Form>

              <Row gutter={10} className="matricTable">
                <Col
                  span={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className="table-wrapperr"
                >
                  <Table
                    bordered
                    columns={columns}
                    pagination={false}
                    dataSource={TableData}
                    className="general-table"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop:"15px",
                      marginBottom:'15px'
                    }}
                  >
                    <div style={{border:'1px solid #D0D5DD', padding:'15px 10px', flex:1}}>
                      <p
                        style={{
                          color:
                            Number(EachStageMetricTotal) === 100
                              ? "#1C9A4E"
                              : "red",
                          fontWeight: 600,
                          margin:0
                        }}
                      >
                        Total:
                        {EachStageMetricTotal}%
                      </p>
                    </div>

                    <div style={{border:'1px solid #D0D5DD', padding:'15px 10px', flex:1, display:'flex', alignItems:'center', marginLeft:"15px"}}>
                      <p
                        style={{
                          color: "#1C9A4E",
                          fontWeight: 600,
                          width: 140,
                          margin:0
                        }}
                      >
                        Total:
                        {" " + numberWithCommas(VarCompBreakdownTotal)}
                      </p>
                      <p
                        style={{
                          color: "#1C9A4E",
                          fontWeight: 600,
                          width: 140,
                          visibility: "hidden",
                          margin:0
                        }}
                      >
                        Total:
                        {" " + numberWithCommas(StageMetricRateTotal)}
                      </p>
                    </div>
                  </div>
                </Col>
                <div style={{ display: "flex" }}>
                  <Button
                    type="primary"
                    loading={isLoading}
                    onClick={UpdateCompensationModel}
                    className="primaryButton"
                  >
                    <strong>Save</strong>
                  </Button>
                  {HasUnsavedChanges && (
                    <span
                      className="conversation-difference-dot"
                      style={{ opacity: 1, marginLeft: 12, width: 17 }}
                    ></span>
                  )}
                </div>
              </Row>

              <Divider />

              <h3 style={{ color: "#12B76A",fontSize:"16px", fontWeight: 700 }}>
                Based on your inputs above, each SDR with this compensation
                model will have
              </h3>
              <Row className="table">
                {summaryData.map((item, index) =>
                  item.data && item.data > 0 ? (
                    <Col
                      key={index}
                      span={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <Col
                        span={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        style={{ display: "flex" }}
                      >
                        <div className="title">
                          <p>
                            <strong style={{color:"#1D2939"}}>{item.title}</strong>
                          </p>
                        </div>
                      </Col>
                      <Col
                        span={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        style={{ display: "flex" }}
                      >
                        <div className="data">
                          <h4 style={{ color: "#7F56D9" }}>
                            {numberWithCommas(item.data)}
                          </h4>
                          {/* <Tooltip placement="bottomRight"  title="Display hint text to be shown here">
                                            <Button type="text" style={{fontStyle:'italic',fontFamily:'cursive',color:'#A9A9A9',fontSize:'20px',display:'flex',alignItems:'center'}}>i</Button>
                                        </Tooltip> */}
                        </div>
                      </Col>
                    </Col>
                  ) : (
                    ""
                  )
                )}
              </Row>
            </>
          ) : (
            <div style={{ padding: 20 }}>
              <Empty
                style={{ marginTop: 20, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No Models Found</span>}
              >
                <Button type="link" onClick={showModal} className="secondaryButton">
                  Create Now
                </Button>
              </Empty>
            </div>
          )}
        </Col>
      </Row>

      <Modal
        className="delete-revenue-model"
        visible={isModalVisible}
        centered
        footer={[]}
        width="350px"
        closable={false}
        style={{ borderRadius: "20px" }}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={HandleAddModel}
          requiredMark={false}
          form={form}
          name="create_new_model"
        >
          <Form.Item
            label={
              <small>
                <span style={{color:"#1D2939", fontSize:'18px', fontWeight:500}}>Enter model name</span>
              </small>
            }
            name="name"
            rules={[{ required: true, message: "Please enter model name " }]}
          >
            <Input placeholder="Eg: My Sales team model" className="general-input"/>
          </Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%", fontWeight: 600 }}
            className="primaryButton"
          >
            Save
          </Button>
        </Form>
      </Modal>
    </>
  );
}
