import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Input, message, Row } from "antd";
import { Auth } from "aws-amplify";
import backgroundImg from "assets/Images/login_signup_background.svg";
import Logo from "commons/Logo";
import { useHistory } from "react-router-dom";

function ConfirmEmail(props) {
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState("");
  const [msg, setmsg] = useState("Check your email for a confirmation code");
  const [email] = useState(props.location.state?.userName);
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!props.location.state) {
    if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm_sign_up")
      )
    {
      let Data = {}
      Data.code = window.location.href.split("confirmationcode=")[1].split("&email=")[0]
      Data.email = window.location.href.split("email=")[1]
        form.setFieldsValue({
          code: Data.code,
          email: Data.email
        })
        handleConfirmSignUp(form.getFieldsValue(["email", "code"]))
      }
    else {
      history.push("/")
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendCode = async () => {
    setloading(true);
    seterror("");
    try {
      await Auth.resendSignUp(email);
      setmsg("A new confirmation code has been sent.");
      setloading(false);
    } catch (error) {
      console.log("error signing up:", error);
      seterror(error.message);
      setmsg("")
      setloading(false);
    }
  };

  const handleConfirmSignUp = async (e) => {

    try {
      if (e.email && e.code) {
        setloading(true);
        seterror("");
        await Auth.confirmSignUp(e.email, e.code);
        setloading(false);
        history.push({
          pathname: "/signin",
          state: {
            feedback: "Sign in with your new account",
            userName: e.email,
          },
        });
      }
      else {
        message.error("something went wrong")
      }
    } catch (error) {
      if (error.message.includes("User cannot be confirmed. Current status is CONFIRMED")) {
        history.push("/")
        message.success("Already Verified")
      }
      console.log("error signing up:", error);
      setmsg("")
      seterror(error.message);
      setloading(false);
    }
  };

  return (
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={6} style={{ height: "100%" }}>
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: "100vh", minHeight:580 }}
          />
        </Col>
        <Col xs={24} md={24} lg={18} style={{ transform: "scale(0.88)" }}>
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight:580
            }}
          >
            <Card className="card-container" style={{ border: "none", width: "437px", padding: "0px" }}>
              <div style={{ marginBottom: 35 }}>
                <Logo />
              </div>
              {msg && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="success"
                  showIcon={false}
                  message={msg}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                  closable
                />
              )}
              <Form
                form={form}
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_signup"
                className="signup-form"
                onFinish={(e) => handleConfirmSignUp(e)}
              >
                <Form.Item
                  initialValue={email}
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Email</strong>
                    </small>
                  }
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input
                    value={email}
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Email"
                    disabled={localStorage.getItem("User")}
                    style={{ color: "gray", fontSize: "18px" }}
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 }}
                  label={
                    <Row>
                      <Col xs={12}>
                        <small>
                          <strong style={{
                        fontSize: "18px"
                      }}>Code</strong>
                        </small>
                      </Col>
                      <Col xs={12} style={{ textAlign: "end" }}>
                        <Button
                          type="link"
                          size="small"
                          onClick={handleResendCode}
                          disabled={loading}
                          style={{  color: "#0F61DB", fontSize: "16px", fontWeight:700 }}
                          tabIndex={4}
                        >
                          Resend Code
                        </Button>
                      </Col>
                    </Row>
                  }
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Confirmation Code!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    id="code"
                    key="code"
                    name="code"
                    placeholder="Enter your confirmation code"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={8}>
                      <Button
                        type="link"
                        size="small"
                        block
                        onClick={() => history.push("/signin")}
                        disabled={loading}
                        style={{ textAlign: "left" }}
                        tabIndex={6}
                      >
                        <span style={{ color: "#0F61DB", fontWeight: "600", fontSize: "18px" }}>
                          Sign In
                        </span>
                      </Button>
                    </Col>
                    <Col span={10}>
                      <Button
                        type="primary"
                        size="small"
                        htmlType="submit"
                        block
                        loading={loading}
                        tabIndex={5}
                      ><span  style={{ fontSize: "18px" }}>Confirm</span>

                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
            <div>
              <p
                style={{
                  color: "#646C79",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  userSelect: "none",
                  marginBottom: "0px",
                }}
              >
                Version{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "0.1.0"}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#646C79",
                  textAlign: "center",
                }}
              >
                &copy; {new Date().getFullYear()} XFactor.io copyright all
                rights reserved.
              </p>
            </div>
          </Row>
        </Col>
      </Row>
  );
}

export default ConfirmEmail;
