import React, { Component } from "react";
import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import { Auth } from "aws-amplify";
import backgroundImg from "assets/Images/login_signup_background.svg";
import Logo from "commons/Logo";
import { emailPattern } from "utility/Constants";

export class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      error: "",
      loading: false,
    };
  }

  handleResetPassword = (e) => {
    this.setState(
      {
        loading: true,
        error: "",
      },
      async () => {
        try {
          await Auth.forgotPassword(e.email);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.history.push({
                pathname: `/reset-password`,
                state: { userName: e.email },
              });
            }
          );
        } catch (error) {
          console.log("error resetting password:", error);
          this.setState({
            error: error.message,
            loading: false,
          });
        }
      }
    );
  };

  render() {
    const { error, feedback, loading } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={6} style={{ height: "100%" }}>
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: "100vh", minHeight:580 }}
          />
        </Col>
        <Col xs={24} md={24} lg={18} style={{ transform: "scale(0.88)" }}>
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight:580
            }}
          >
            <Card className="card-container"  style={{ border: "none", width: "437px" }}>
              <div style={{ marginBottom: 35 }}>
                <Logo />
              </div>
              {feedback && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="success"
                  showIcon={false}
                  message={feedback}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_reset"
                className="reset-form"
                onFinish={(e) => this.handleResetPassword(e)}
              >
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Email</strong>
                    </small>
                  }
                  labelCol={{ span: 24 }}
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      height: "44px",
                      fontSize: "18px",
                    }}
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Your email id"
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between">
                    <Col span={8}>
                      <Button
                        type="link"
                        size="small"
                        block
                        onClick={() => history.push("/signin")}
                        disabled={loading}
                        tabIndex={6}
                        style={{ textAlign: "left" }}
                      >
                        <span style={{ color: "#0F61DB", fontWeight: "600" }}>
                          Sign In
                        </span>
                      </Button>
                    </Col>
                    <Col span={10}>
                      <Button
                        type="primary"
                        size="small"
                        htmlType="submit"
                        block
                        loading={loading}
                        tabIndex={5}
                      >
                        Send Code
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>

            <div>
              <p
                style={{
                  color: "#646C79",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  userSelect: "none",
                  marginBottom: "0px",
                }}
              >
                Version{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "0.1.0"}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#646C79",
                  textAlign: "center",
                }}
              >
                &copy; {new Date().getFullYear()} XFactor.io copyright all
                rights reserved.
              </p>
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default ForgetPassword;
