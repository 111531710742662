import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { useSelector } from "react-redux";

const DropDownMenu = (props) => {
  const { handleSignOut,path } = props;
  const account = useSelector((state) => state.loginReducer.groups);
  const [selectedKey, setselectedKey] = useState("")

  useEffect(() => {
    const menukeys = ["/my-settings","/admin", "/settings"]
    if (menukeys.includes(path)) {
      setselectedKey(path?.split("/")[1])
    }
    else {
      setselectedKey("")
    }

  },[path])

  const handleSignOutFun = () => {
    
      const sessionStartTime = sessionStorage.getItem('sessionStartTime');
      if (sessionStartTime) {
        const sessionEndTime = Math.floor(Date.now() / 1000);  
        const sessionDuration = sessionEndTime - parseInt(sessionStartTime, 10);
        // Send session duration to Heap
        window.heap.track('SessionEnded', { duration: sessionDuration });
        // Clear session start time from sessionStorage
        sessionStorage.removeItem('sessionStartTime');
      }
      handleSignOut();
  }
console.log("account",account);
  return (
    <Menu
    // data-tour = "tour__settings"
      style={{
        width: "180px",
        boxShadow:
          "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
      }}
      selectedKeys={[selectedKey]}
      className="right-dropdown-menu"
    >
      <Menu.Item key="my-settings">
        <Link to={"/my-settings"}>My Settings</Link>
      </Menu.Item>
      <Menu.Item key="settings">
        <Link to={"/settings"}>Company Settings</Link>
      </Menu.Item>
      {account && account[2] === 'admin' && <Menu.Item key = "admin">
        <Link to={"/admin"}>Admin Settings</Link>
      </Menu.Item>}
      <Menu.Divider />
      <Menu.Item>
      <Link onClick={handleSignOutFun}>
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default DropDownMenu;