import { Image, Text, View } from "@react-pdf/renderer";
// import { styles } from "./pdfStyles";
import gotomarketImg from "assets/Images/PdfImages/go_to_market.png";
import wordmark_blue from "assets/Images/wordmark_blue.png";
import target_audience from "assets/Images/PdfImages/target_audience.png";
import target_market_segement_icon from "assets/Images/PdfImages/target_market_segement_icon.png";
import true_bullet_icon from "assets/Images/PdfImages/true_bullet_icon.png";
import target_industry from "assets/Images/PdfImages/target_industry.png";
import target_region from "assets/Images/PdfImages/target_region.png";
import job_title from "assets/Images/PdfImages/job_title.png";
import job_functions from "assets/Images/PdfImages/job_functions.png";
import job_levels from "assets/Images/PdfImages/job_levels.png";
import pin_icon from "assets/Images/PdfImages/pin_icon.png";
import paint_point_solution_icon from "assets/Images/PdfImages/paint_point_solution_icon.png";
import persona_responsibility from "assets/Images/PdfImages/persona_responsibility.png";
import persona_message from "assets/Images/PdfImages/persona_message.png";
import system_process from "assets/Images/PdfImages/system_process.png";
import channel_icon from "assets/Images/PdfImages/channel_icon.png";
import email_icon from "assets/Images/PdfImages/email_icon.png";
import branded_icon from "assets/Images/PdfImages/branded_icon.png";
import non_branded_icon from "assets/Images/PdfImages/non_branded_icon.png";
import category_icon from "assets/Images/PdfImages/category_icon.png";
import sales_marketing_icon from "assets/Images/PdfImages/sales_marketing_icon.png";
import youtube_icon from "assets/Images/PdfImages/youtube_icon.png";
import fb_icon from "assets/Images/PdfImages/fb_icon.png";
import insta_icon from "assets/Images/PdfImages/insta_icon.png";
import twitter_icon from "assets/Images/twitter.png";
import linkedin_icon from "assets/Images/PdfImages/linkedin_icon.png";
import CompanyInfoImg from "assets/Images/PdfImages/company_info.png";
import ProductDescImg from "assets/Images/PdfImages/product_info.png";
import taglineImg from "assets/Images/PdfImages/company_tagline.png";
import keyDiffrenceImg from "assets/Images/PdfImages/key_diffrence.png";
import ValuePropositionImg from "assets/Images/PdfImages/value_proposition.png";
import AwardsImg from "assets/Images/PdfImages/offers.png";
import OffersImg from "assets/Images/PdfImages/offers.png";
import CompanyInfoCoverImg from "assets/Images/PdfImages/company_information.png";
import {
  current_marketing_activity_channels,
  email_marketing_programs,
  current_sales_marketing_tools,
  persona_values
} from "utility/Constants";

export const HeaderPage = (props) => (
  <View
    style={{
      padding: 20,
      height: "87vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    }}
  >
    <PoweredByXDG style={{width:'90vw'}}/>
    <View style={{ width: "50%" }}>
    <Image src={props?.logoUrl?.logourl || `https://logo.clearbit.com/${props.logoUrl?.url}`} style={{ width: 250, maxHeight:80 }}></Image>
      <Text style={{ fontSize: 24, fontWeight: "bold", marginTop: "20px" }}>
        Go To Market Intelligence
      </Text>
      <Text style={{ fontSize: 16, fontWeight: "normal", marginTop: "20px" }}>
        GTM Intelligence helps to gather all the data of your Company &
        Customers in one place to enrich the on demand gen experience.
      </Text>
    </View>
    <View
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image style={{ width: "70%" }} src={gotomarketImg}></Image>
    </View>
  </View>
);

export const TargetAudienceCover = (props) => (
  <View
    style={{
      padding: "0 20px",
      height: "87vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      position:'relative'
    }}
  >
    <PoweredByXDG style={{width:'90vw'}}/>
    <View style={{ width: "50%" }}>
      <Image src={props?.logoUrl?.logourl || `https://logo.clearbit.com/${props.logoUrl?.url}`} style={{ width: 250, maxHeight:80 }}></Image>
      <Text style={{ fontSize: 24, fontWeight: "bold", marginTop: "20px" }}>
        Target Audience
      </Text>
      <Text style={{ fontSize: 16, fontWeight: "normal", marginTop: "20px" }}>
        GTM Intelligence helps to gather all the data of your Company &
        Customers in one place to enrich the on demand gen experience.
      </Text>
    </View>
    <View
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image style={{ width: "70%" }} src={target_audience}></Image>
    </View>
  </View>
);

export const TargetAudienceCustomreProfile = (props) => (
  <View
    style={{
      padding: "0 20px",
      height: "80vh",
      flexDirection: "column",
      display: "flex",
      width: "100%",
    }}
  >
    <PoweredByXDG />
    <View style={{ width: "100%", textAlign: "center", alignItems: "center" }}>
      <Text style={{ fontSize: 24, fontWeight: "bold" }}>
        Target Ideal Custom Profile
      </Text>
    </View>

    <View
      style={{
        padding: "0 20px 0px 20px",
        height: "auto",
        flexDirection: "row",
        display: "flex",
        width: "100%",
      }}
    >
      <View
        style={{
          padding: "20px 20px 0px 20px",
          paddingTop: 0,
          height: "auto",
          flexDirection: "column",
          display: "flex",
          width: "50%",
          marginBottom: 20,
          borderRadius: 24,
        }}
      >
        <View
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
            borderBottom: "1pt",
            borderBottomColor: props?.primaryColor,
          }}
        >
          <Image
            style={{ paddingTop: 10, marginRight: 5, width: 20 }}
            src={target_market_segement_icon}
          ></Image>
          <Text
            style={{
              fontSize: 18,
              marginTop: "20px",
              marginRight: 10,
              marginBottom: 10,
              color: props?.primaryColor,
            }}
          >
            Target Market Segments
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {props?.market_segments && props?.market_segments?.length ? (
            props?.market_segments?.map((item) => (
              <Text
                style={{
                  fontSize: 11,
                  width: "auto",
                  margin: "5px",
                  padding: 8,
                  backgroundColor: props?.backgroundColor,
                  borderRadius: 8,
                }}
              >
                {item}
              </Text>
            ))
          ) : (
            <Text>-</Text>
          )}
        </View>

        <View
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
            borderBottom: "1pt",
            borderBottomColor: props?.primaryColor,
          }}
        >
          <Image
            style={{ paddingTop: 10, marginRight: 5, width: 20 }}
            src={target_industry}
          ></Image>
          <Text
            style={{
              fontSize: 18,
              marginTop: "20px",
              marginRight: 10,
              marginBottom: 10,
              color: props?.primaryColor,
            }}
          >
            Target Industries
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {props?.industries && props?.industries?.length ? (
            props?.industries?.map((item) => (
              <Text
                style={{
                  fontSize: 11,
                  width: "auto",
                  margin: "5px",
                  padding: 8,
                  backgroundColor: props?.backgroundColor,
                  borderRadius: 8,
                }}
              >
                {item}
              </Text>
            ))
          ) : (
            <Text>-</Text>
          )}
        </View>
      </View>

      <View
        style={{
          padding: "20px 20px 0px 20px",
          paddingTop: 0,
          height: "auto",
          flexDirection: "column",
          display: "flex",
          width: "50%",
          marginBottom: 20,
          borderRadius: 24,
        }}
      >
        <View
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
            borderBottom: "1pt",
            borderBottomColor: props?.primaryColor,
          }}
        >
          <Image
            style={{ paddingTop: 10, marginRight: 5, width: 20 }}
            src={target_region}
          ></Image>
          <Text
            style={{
              fontSize: 18,
              marginTop: "20px",
              marginRight: 10,
              marginBottom: 10,
              color: props?.primaryColor,
            }}
          >
            Target Regions
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {props?.regions && props?.regions?.length ? (
            props?.regions?.map((item) => (
              <Text
                style={{
                  fontSize: 11,
                  width: "auto",
                  margin: "5px",
                  padding: 8,
                  backgroundColor: props?.backgroundColor,
                  borderRadius: 8,
                }}
              >
                {item}
              </Text>
            ))
          ) : (
            <Text>-</Text>
          )}
        </View>
      </View>
    </View>

  </View>
);

export const TargetAudienceUserPersona = (props) => (
  <View
    style={{
      padding: 20,
      height: "auto",
      flexDirection: "column",
      display: "flex",
      width: "100%",
    }}
    break
  >
    <View style={{ width: "100%", textAlign: "center", alignItems: "center" }}>
      <Text style={{ fontSize: 24, fontWeight: "bold" }}>User Personas</Text>
    </View>

    {props?.persona &&
      props?.persona?.length > 0 &&
      props?.persona?.map((item, index) => (
        <View
          style={{
            padding: "0 20px",
            height: "80vh",
            flexDirection: "row",
            display: "flex",
            width: "100%",
          }}
          break={index}
        >
          <PoweredByXDG style={{height:!index ? '83vh' : "90vh", width:'92vw'}}/>
          <View
            style={{
              padding: "20px 20px 0px 20px",
              paddingTop: 0,
              height: "auto",
              flexDirection: "column",
              display: "flex",
              width: "50%",
              marginBottom: 20,
              borderRadius: 24,
            }}
          >
            {/* Name */}
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {/* <Image
              style={{ paddingTop: 10, marginRight: 5, width: 20 }}
              src={test_user_img}
            ></Image> */}
              <Text
                style={{
                  fontSize: 18,
                  marginTop: "20px",
                  marginRight: 10,
                  marginBottom: 10,
                  color: props?.primaryColor,
                }}
              >
                {item?.name ? item?.name : "-"}
              </Text>
            </View>

            {/* Job TItle */}
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                style={{ paddingTop: 10, marginRight: 5, width: 13 }}
                src={job_title}
              ></Image>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: "10px",
                  paddingRight: 5,
                  color: props?.primaryColor,
                }}
              >
                Job Title
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {item?.job_title && item?.job_title?.length > 0 ? (
                item?.job_title?.slice(0, 6)?.map((value) => (
                  <Text
                    style={{
                      fontSize: 11,
                      width: "auto",
                      margin: "5px",
                      padding: 8,
                      backgroundColor: props?.backgroundColor,
                      borderRadius: 8,
                    }}
                  >
                    {value}
                  </Text>
                ))
              ) : (
                <Text>-</Text>
              )}
            </View>

            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                style={{ paddingTop: 10, marginRight: 5, width: 13 }}
                src={job_functions}
              ></Image>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: "10px",
                  paddingRight: 5,
                  color: props?.primaryColor,
                }}
              >
                Job Functions
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {item?.job_functions && item?.job_functions?.length > 0 ? (
                item?.job_functions?.slice(0, 6)?.map((value) => (
                  <Text
                    style={{
                      fontSize: 11,
                      width: "auto",
                      margin: "5px",
                      padding: 8,
                      backgroundColor: props?.backgroundColor,
                      borderRadius: 8,
                    }}
                  >
                    {persona_values?.job_functions?.find(item => item?.id === value)?.name}

                  </Text>
                ))
              ) : (
                <Text>-</Text>
              )}
            </View>
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                style={{ paddingTop: 10, marginRight: 5, width: 13 }}
                src={job_levels}
              ></Image>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: "10px",
                  paddingRight: 5,
                  color: props?.primaryColor,
                }}
              >
                Job Levels
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {item?.job_level && item?.job_level?.length > 0 ? (
                item?.job_level?.slice(0, 6)?.map((value) => (
                  <Text
                    style={{
                      fontSize: 11,
                      width: "auto",
                      margin: "5px",
                      padding: 8,
                      backgroundColor: props?.backgroundColor,
                      borderRadius: 8,
                    }}
                  >
                    {value}
                  </Text>
                ))
              ) : (
                <Text>-</Text>
              )}
            </View>

            <View
              style={{
                marginTop: 10,
              }}
            >
              {item?.pain_points ? (
                item?.pain_points?.map((value, index) => (
                  <View style={{ display: "flex", flexDirection: "column" }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: 10,
                      }}
                    >
                      <Image
                        style={{
                          marginTop: 5,
                          marginRight: 5,
                          width: 13,
                          height: 13,
                        }}
                        src={pin_icon}
                      ></Image>
                      <Text
                        style={{
                          fontSize: 12,
                          marginTop: "3px",
                          marginBottom: 0,
                          paddingRight: 5,
                          color: props?.primaryColor,
                        }}
                      >
                        {"Pain Point "}
                        {index + 1}
                      </Text>
                    </View>

                    <View style={{ alignItems: "left" }}>
                      <Text
                        style={{
                          fontSize: 12,
                          lineHeight: 1.3,
                          paddingLeft: 50,
                          color: "#303030",
                        }}
                      >
                        {truncate(value, 100)}
                      </Text>
                    </View>
                  </View>
                ))
              ) : (
                <Text>-</Text>
              )}
            </View>
          </View>

          <View
            style={{
              padding: "20px 20px 0 20px",
              marginLeft: 15,
              height: "auto",
              flexDirection: "column",
              width: "50%",
              marginBottom: 20,
              borderRadius: 24,
            }}
          >
            <View>
              <View
                style={{
                  display: "flex",
                  alignItems: "left",
                  textAlign: "left",
                  flexDirection: "row",
                  paddingLeft:5
                }}
              >
                <Image
                  style={{
                    marginTop: 10,
                    marginRight: 5,
                    width: 13,
                    height: 13,
                  }}
                  src={paint_point_solution_icon}
                ></Image>
                <Text
                  style={{
                    fontSize: 14,
                    marginTop: "10px",
                    paddingRight: 5,
                    color: props?.primaryColor,
                  }}
                >
                  {"How does your product/solution solve these pain points?"}
                </Text>
              </View>

              <View
                style={{ backgroundColor: "#FAFAFA", marginTop: 5, padding: 5 }}
              >
                {item?.pain_point_solution ? (
                  <View
                    style={{
                      display: "flex",
                      textAlign: "left",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {/* <Image
                      style={{ marginRight: 5, width: 13 }}
                      src={true_bullet_icon}
                    ></Image> */}
                    <Text
                      style={{ fontSize: 12, lineHeight: 1.3, paddingRight: 5 }}
                    >
                      {truncate(item?.pain_point_solution?.replace(/(\r\n|\n|\r)/gm, ""), 155)}
                    </Text>
                  </View>
                ) : (
                  <Text>-</Text>
                )}
              </View>

              <View
                style={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "left",
                  flexDirection: "row",
                  paddingLeft:5
                }}
              >
                <Image
                  style={{
                    marginTop: 22,
                    marginRight: 5,
                    width: 13,
                    height: 13,
                  }}
                  src={persona_responsibility}
                ></Image>
                <Text
                  style={{
                    fontSize: 14,
                    marginTop: "20px",
                    paddingRight: 5,
                    color: props?.primaryColor,
                  }}
                >
                  {"Persona Details/Responsibilities"}
                </Text>
              </View>

              <View
                style={{ backgroundColor: "#FAFAFA", marginTop: 5, padding: 5 }}
              >
                {item?.details_responsibilities ? (
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      textAlign: "left",
                    }}
                  >
                    {/* <Image
                      style={{ marginRight: 5, width: 13 }}
                      src={true_bullet_icon}
                    ></Image> */}
                    <Text
                      style={{ fontSize: 12, lineHeight: 1.3, paddingRight: 5 }}
                    >
                      {truncate(item?.details_responsibilities?.replace(/(\r\n|\n|\r)/gm, ""), 155)}
                    </Text>
                  </View>
                ) : (
                  <Text>-</Text>
                )}
              </View>
            </View>

            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                style={{ paddingTop: 20, marginRight: 5, width: 13 }}
                src={persona_message}
              ></Image>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: "20px",
                  paddingRight: 5,
                  color: props?.primaryColor,
                }}
              >
                {"Messaging themes used for this persona"}
              </Text>
            </View>

            <View
              style={{ }}
            >
              {item?.messaging_theme &&
                item?.messaging_theme?.map((value, index) => (
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      textAlign: "left",
                      backgroundColor: props?.backgroundColor, marginTop: 10, padding: 15
                    }}
                  >
                    <Text
                      style={{ fontSize: 12, lineHeight: 1.3, paddingRight: 5 }}
                    >
                      {/* {`Theme ${index}`} */}
                      {truncate(value, 140)}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
        </View>
      ))}

  </View>
);

export const SystemProcessCover = (props) => (
  <View
    style={{
      padding: 20,
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    }}
    // break
  >
    <PoweredByXDG style={{width:'90vw'}}/>

    <View style={{ width: "50%" }}>
    <Image src={props?.logoUrl?.logourl || `https://logo.clearbit.com/${props.logoUrl?.url}`} style={{ width: 250, maxHeight:100 }}></Image>
      <Text style={{ fontSize: 24, fontWeight: "bold", marginTop: "20px" }}>
        Systems & Processes
      </Text>
      <Text style={{ fontSize: 16, fontWeight: "normal", marginTop: "20px" }}>
        GTM Intelligence helps to gather all the data of your Company &
        Customers in one place to enrich the on demand gen experience.
      </Text>
    </View>
    <View
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image style={{ width: "70%" }} src={system_process}></Image>
    </View>
  </View>
);

export const SyestemProcess = (props) => (
  <View
  break
    style={{
      padding: 20,
      height: "auto",
      flexDirection: "column",
      display: "flex",
      width: "100%",

    }}
  >
    <PoweredByXDG style={{height:'85vh'}}/>

    <View>
      <Text
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        Systems and Processes
      </Text>
    </View>

    <View
      style={{
        padding: "0 20px 20px 20px",
        height: "auto",
        flexDirection: "row",
        display: "flex",
        width: "100%",
      }}
    >
      <View
        style={{
          padding: 20,
          height: "auto",
          flexDirection: "column",
          display: "flex",
          width: "50%",
          marginBottom: 20,
          borderRadius: 24,
        }}
      >
        <View
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text
            style={{
              fontSize: 18,
              marginTop: "20px",
              marginRight: 10,
              color: "#000000",
            }}
          >
            Current Marketing Activity
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <Image
            style={{ paddingTop: 10, marginRight: 5, width: 13 }}
            src={channel_icon}
          ></Image>
          <Text
            style={{
              fontSize: 14,
              marginTop: "10px",
              paddingRight: 5,
              color: props?.primaryColor,
            }}
          >
            Channels
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {props?.channels?.map((value) => (
            <Text
              style={{
                fontSize: 8,
                width: "auto",
                margin: "5px",
                padding: 8,
                backgroundColor: props?.backgroundColor,
                borderRadius: 8,
              }}
            >
              {getKeyByValue(current_marketing_activity_channels, value)}
            </Text>
          ))}
        </View>

        <View
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 10,
          }}
        >
          <Image
            style={{ paddingTop: 20, marginRight: 5, width: 13 }}
            src={email_icon}
          ></Image>
          <Text
            style={{
              fontSize: 14,
              marginTop: "20px",
              paddingRight: 5,
              color: props?.primaryColor,
            }}
          >
            Email Marketing Programs
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {props?.email_marketing?.map((value) => (
            <Text
              style={{
                fontSize: 8,
                width: "auto",
                margin: "5px",
                padding: 8,
                backgroundColor: props?.backgroundColor,
                borderRadius: 8,
              }}
            >
              {getKeyByValue(email_marketing_programs, value)}
            </Text>
          ))}
        </View>
      </View>

      <View
        style={{
          padding: 20,
          marginLeft: 15,
          height: "auto",
          flexDirection: "column",
          width: "50%",
          marginBottom: 20,
          borderRadius: 24,
        }}
      >
        <View
          style={{
            borderLeft: "1pt",
            borderLeftColor: "#A9A9A9",
          }}
        >
          <View style={{ display: "flex", flexDirection: "column" }}>
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: 15,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginTop: "20px",
                  marginRight: 10,
                  color: "#000000",
                }}
              >
                Current Sales & Marketing Tools
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: 15,
              }}
            >
              <Image
                style={{ paddingTop: 10, marginRight: 5, width: 13 }}
                src={sales_marketing_icon}
              ></Image>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: "10px",
                  paddingRight: 5,
                  color: props?.primaryColor,
                }}
              >
                Sales & Marketing Tools
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: 13,
              }}
            >
              {props?.marketing_tools?.map((value) => (
                <Text
                  style={{
                    fontSize: 8,
                    width: "auto",
                    margin: "5px",
                    padding: 8,
                    backgroundColor: props?.backgroundColor,
                    borderRadius: 8,
                  }}
                >
                  {getKeyByValue(current_sales_marketing_tools, value)}
                </Text>
              ))}
            </View>
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: 15,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  marginTop: "20px",
                  marginRight: 10,
                  marginBottom: 10,
                  color: "#000000",
                }}
              >
                Social media Accounts
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
                paddingLeft: 15,
              }}
            >
              {props?.facebook && (
                <Text
                  style={{
                    margin: "5px",
                    padding: 8,
                    backgroundColor: props?.backgroundColor,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      paddingTop: 20,
                      marginRight: 10,
                      width: 13,
                      height: 10,
                      marginTop:5
                    }}
                    src={fb_icon}
                  ></Image>{" "}
                  <Text style={{ color: props?.primaryColor, fontSize: 13 }}>
                    {props?.facebook_link}
                  </Text>
                </Text>
              )}

              {props?.instagram && (
                <Text
                  style={{
                    margin: "5px",
                    padding: 8,
                    backgroundColor: props?.backgroundColor,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      paddingTop: 20,
                      marginRight: 10,
                      width: 13,
                      height: 10,
                    }}
                    src={insta_icon}
                  ></Image>{" "}
                  <Text style={{ color: props?.primaryColor, fontSize: 13 }}>
                    {props?.instagram_link}
                  </Text>
                </Text>
              )}

              {props?.linkedin && (
                <Text
                  style={{
                    margin: "5px",
                    padding: 8,
                    backgroundColor: props?.backgroundColor,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      paddingTop: 20,
                      marginRight: 10,
                      width: 13,
                      height: 10,
                    }}
                    src={linkedin_icon}
                  ></Image>{" "}
                  <Text style={{ color: props?.primaryColor, fontSize: 13 }}>
                    {props?.linkedin_link}
                  </Text>
                </Text>
              )}

              {props?.youtube && (
                <Text
                  style={{
                    margin: "5px",
                    padding: 8,
                    backgroundColor: props?.backgroundColor,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      paddingTop: 20,
                      marginRight: 10,
                      width: 15,
                      height: 10,
                    }}
                    src={youtube_icon}
                  ></Image>{" "}
                  <Text style={{ color: props?.primaryColor, fontSize: 13 }}>
                    {props?.youtube_link}
                  </Text>
                </Text>
              )}

              {props?.twitter && (
                <Text
                  style={{
                    margin: "5px",
                    padding: 8,
                    backgroundColor: props?.backgroundColor,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{
                      paddingTop: 20,
                      marginRight: 10,
                      width: 13,
                      height: 10,
                    }}
                    src={twitter_icon}
                  ></Image>{" "}
                  <Text style={{ color: props?.primaryColor, fontSize: 13 }}>
                    {props?.twitter_link}
                  </Text>
                </Text>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>

    {/* dynamic part start */}

    <View break>
      <Text
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        Systems and Processes
      </Text>
    </View>
    <PoweredByXDG style={{height:'88vh'}}/>

    <View
      style={{
        padding: "0 20px 10px 20px",
        height: "auto",
        flexDirection: "row",
        display: "column",
        width: "100%",
      }}
    >
      <View
        style={{
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <Image
          style={{ paddingTop: 20, marginRight: 5, width: 13 }}
          src={branded_icon}
        ></Image>
        <Text
          style={{
            fontSize: 14,
            marginTop: "20px",
            paddingRight: 5,
            color: props?.primaryColor,
          }}
        >
          Branded Keywords
        </Text>
      </View>
    </View>

    <View
      style={{
        padding: "0 20px 10px 20px",
        height: "auto",
        flexDirection: "row",
        display: "column",
        width: "100%",
      }}
    >
      <View
        style={{
          display: "flex",
          flexWrap: "wrap",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {props?.branded_keywords?.slice(0, 23)?.map((value) => (
          <Text
            style={{
              fontSize: 10,
              width: "auto",
              margin: "5px",
              padding: 8,
              backgroundColor: props?.backgroundColor,
              borderRadius: 8,
            }}
          >
            {value}
          </Text>
        ))}
        {props?.branded_keywords?.length === 0 && (
          <Text
            style={{
              fontSize: 10,
              width: "auto",
              margin: "5px",
              padding: 8,
              backgroundColor: props?.backgroundColor,
              borderRadius: 8,
            }}
          >
           NA
          </Text>
        )}
      </View>
    </View>

    <View
      style={{
        padding: "0 20px 10px 20px",
        height: "auto",
        flexDirection: "row",
        display: "column",
        width: "100%",
      }}
    >
      <View
        style={{
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <Image
          style={{ paddingTop: 20, marginRight: 5, width: 13 }}
          src={non_branded_icon}
        ></Image>
        <Text
          style={{
            fontSize: 14,
            marginTop: "20px",
            paddingRight: 5,
            color: props?.primaryColor,
          }}
        >
          Non branded keywords
        </Text>
      </View>
    </View>

    <View
      style={{
        padding: "0 20px 20px 20px",
        height: "auto",
        flexDirection: "row",
        display: "column",
        width: "100%",
      }}
    >
      <View
        style={{
          display: "flex",
          flexWrap: "wrap",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {props?.non_branded_keywords?.slice(0, 23)?.map((value) => (
          <Text
            style={{
              fontSize: 10,
              width: "auto",
              margin: "5px",
              padding: 8,
              backgroundColor: props?.backgroundColor,
              borderRadius: 8,
            }}
          >
            {value}
          </Text>
        ))}
         {props?.non_branded_keywords?.length === 0 && (
          <Text
            style={{
              fontSize: 10,
              width: "auto",
              margin: "5px",
              padding: 8,
              backgroundColor: props?.backgroundColor,
              borderRadius: 8,
            }}
          >
            NA
          </Text>
        )}
      </View>
    </View>

    <View>
      <View
        style={{
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Text
          style={{
            fontSize: 18,
            marginTop: "20px",
            marginRight: 10,
            color: "#000000",
          }}
        >
          CRM or Marketing Automation System
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <Image
          style={{ paddingTop: 40, marginRight: 5, width: 13 }}
          src={category_icon}
        ></Image>
        <Text
          style={{
            fontSize: 14,
            marginTop: "40px",
            paddingRight: 5,
            color: props?.primaryColor,
            textAlign: "left",
          }}
        >
          {
            "Do you currently have Processes/Rules defining for these following categories?"
          }
        </Text>
      </View>

      {
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Lead/contact Assignment"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:40 }}>
              {props?.lead_contact_assignment
                ? truncate(props?.lead_contact_assignment_process, 300)
                : "NA"}
            </Text>
          </View>
        </View>
      }

      {
        <View
          style={{
            backgroundColor: props?.backgroundColor,
            marginTop: 30,
            padding: 10,
            marginBottom: 20,
          }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Opportunity/Deal Creation"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                fontSize: 11,
                marginTop: "10px",
                paddingRight: 5,
                maxLines: 2,
                height:40
              }}
            >
              {props?.opportunity_deal_creation
                ? truncate(props?.opportunity_deal_creation_process, 300)
                : "NA"}
            </Text>
          </View>
        </View>
      }

      {props?.lead_contact_status && (
        <View
          style={{ backgroundColor: props?.backgroundColor, padding: 10, marginTop: 20 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Lead/Contact Status"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.lead_contact_status_process, 300)}
            </Text>
          </View>
        </View>
      )}

      {props?.lead_contact_de_duplication && (
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Lead/Contact De-duplication"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.lead_contact_de_duplication_process, 300)}
            </Text>
          </View>
        </View>
      )}

      {props?.lead_contact_source && (
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Lead/Contact Source"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.lead_contact_source_process, 300)}
            </Text>
          </View>
        </View>
      )}

      {props?.lead_contact_creation && (
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Lead/Contact Creation"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.lead_contact_creation_process, 300)}
            </Text>
          </View>
        </View>
      )}

      {props?.lead_contact_conversion && (
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Lead/Contact Conversion"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.lead_contact_conversion_process, 300)}
            </Text>
          </View>
        </View>
      )}

      {props?.account_company_creation && (
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Account/Company Creation"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.account_company_creation_process, 300)}
            </Text>
          </View>
        </View>
      )}

      {props?.marketing_sales_sla && (
        <View
          style={{ backgroundColor: props?.backgroundColor, marginTop: 10, padding: 10 }}
        >
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 12, paddingRight: 5 }}>
              {"Marketing & Sales SLA’s"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              textAlign: "left",
              alignItems: "left",
              flexDirection: "row",
            }}
          >
            <Text style={{ fontSize: 11, marginTop: "10px", paddingRight: 5, height:20 }}>
              {truncate(props?.marketing_sales_sla_process, 300)}
            </Text>
          </View>
        </View>
      )}
    </View>
    {/* dynamic part end  */}
  </View>
);

export const CompanyInformation = (props) => {
  return (
    <View>
      <View style={{ padding: 20 }}>
   <PoweredByXDG />
        <Text
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Company Information
        </Text>
        <View style={{ padding: 20 }}>
          <Text style={{ color: props?.primaryColor }}>Company Information</Text>
          <View
            style={{
              display: "flex",
              padding: 10,
              height: 60,
              backgroundColor: props?.backgroundColor,
              marginTop: 10,
              borderRadius: 7,
              width: "40%",
              flexDirection: "row",
            }}
          >
            <Image src={props?.logoUrl || `https://logo.clearbit.com/${props?.url?.split("//")?.[1]}`}></Image>
            <View
              style={{
                paddingLeft: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ color: props?.primaryColor, fontSize: 13 }}>
                {props?.name}
              </Text>
              <Text style={{ fontSize: 11, textDecoration: "underline" }}>
                {props?.url}
              </Text>
            </View>
          </View>

          <View>
            <View
              style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
            >
              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  borderRight: "1px solid #A9A9A9",
                  paddingRight: 20,
                }}
              >
                <Image
                  src={CompanyInfoImg}
                  style={{ height: 15, width: 15 }}
                ></Image>
                <Text
                  style={{
                    fontSize: 13,
                    marginLeft: 10,
                    fontWeight: "bold",
                    color: props?.primaryColor,
                  }}
                >
                  Company Description
                </Text>
              </View>
              <View style={{ width: "70%", paddingLeft: 15 }}>
                <Text style={{ fontSize: 12, lineHeight: 1.3 }}>
                  {props?.company_description}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 20,
                  borderRight: "1px solid #A9A9A9",
                  paddingRight: 20,
                }}
              >
                <Image
                  src={ProductDescImg}
                  style={{ height: 15, width: 15 }}
                ></Image>
                <Text
                  style={{
                    fontSize: 13,
                    marginLeft: 10,
                    fontWeight: "bold",
                    color: props?.primaryColor,
                  }}
                >
                  Product Description
                </Text>
              </View>
              <View style={{ width: "70%", paddingTop: 20, paddingLeft: 15 }}>
                <Text style={{ fontSize: 12, lineHeight: 1.3 }}>
                  {props.product_description}
                </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 20,
                  borderRight: "1px solid #A9A9A9",
                  paddingRight: 20,
                }}
              >
                <Image
                  src={taglineImg}
                  style={{ height: 15, width: 15 }}
                ></Image>
                <Text
                  style={{
                    fontSize: 13,
                    marginLeft: 10,
                    fontWeight: "bold",
                    color: props?.primaryColor,
                  }}
                >
                  {" "}
                  Tagline
                </Text>
              </View>
              <View style={{ width: "70%", paddingTop: 20, paddingLeft: 15 }}>
                <Text style={{ fontSize: 12, lineHeight: 1.3 }}>
                  {props?.tagline}
                </Text>
              </View>
            </View>
            <View></View>
          </View>
        </View>
      </View>

      <View
        style={{
          padding: 20,
          height: props?.competitive?.length > 3 ? "" : "80vh",
        }}
        break
      >
   <PoweredByXDG />

        <Text
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Competitive and Company Positioning
        </Text>

        <View style={{ padding: 20, display: "flex", flexDirection: "row" }}>
          <View style={{ width: "50%" }}>
            {props?.competitive?.map((item) => (
              <View
                style={{
                  display: "flex",
                  padding: 10,
                  height: "auto",
                  backgroundColor: props?.backgroundColor,
                  marginTop: 10,
                  borderRadius: 7,
                  width: "80%",
                  flexDirection: "row",
                }}
              >
                <Image src={`https://logo.clearbit.com/${item?.website?.split("//")?.[1]}`} style={{ height: "30px" }}></Image>
                <View
                  style={{
                    paddingLeft: 20,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{ color: props?.primaryColor, fontSize: 13, paddingBottom: 5 }}
                  >
                    {item?.competitor_name}
                  </Text>
                  <Text style={{ fontSize: 12, textDecoration: "underline" }}>
                    {item?.website}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          <View
            style={{
              width: "50%",
              borderLeftColor: "grey",
              borderLeftWidth: 1,
              borderLeftStyle: "solid",
              padding: 15,
            }}
          >
            <View
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Image
                style={{ marginRight: 5, width: 13 }}
                src={keyDiffrenceImg}
              ></Image>
              <Text
                style={{
                  fontSize: 14,
                  paddingRight: 5,
                  color: props?.primaryColor,
                }}
              >
                {"Key Differentiators"}
              </Text>
            </View>

            <View
              style={{ backgroundColor: "#FAFAFA", marginTop: 20, padding: 10 }}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {props?.key_differentiators && (
                  <Image
                    style={{ paddingTop: 10, marginRight: 5, width: 13 }}
                    src={true_bullet_icon}
                  ></Image>
                )}
                <Text
                  style={{
                    fontSize: 11,
                    lineHeight: 1.3,
                    color: "#303030",
                    marginTop: props?.key_differentiators ? 10 : 0,
                    paddingRight: 15,
                  }}
                >
                  {props?.key_differentiators
                    ? props?.key_differentiators
                    : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
          <View style={{ width: "50%" }}>
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 15,
                }}
              >
                <Image
                  style={{ marginRight: 5, width: 15, height: 15 }}
                  src={ValuePropositionImg}
                ></Image>
                <Text
                  style={{
                    fontSize: 14,
                    color: props?.primaryColor,
                  }}
                >
                  {"Value Proposition"}
                </Text>
              </View>

              <View style={{ alignItems: "left" }}>
                <Text
                  style={{
                    fontSize: 11,
                    lineHeight: 1.3,
                    paddingLeft: 33,
                    color: "#303030",
                    width: "80%",
                    paddingTop: 10,
                  }}
                >
                  {props?.value_proposition ? props?.value_proposition : "-"}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 15,
                }}
              >
                <Image
                  style={{ marginRight: 5, width: 15, height: 15 }}
                  src={AwardsImg}
                ></Image>
                <Text
                  style={{
                    fontSize: 14,
                    color: props?.primaryColor,
                  }}
                >
                  {"Awards or Accolaes Received?"}
                </Text>
              </View>

              <View style={{ alignItems: "left" }}>
                <Text
                  style={{
                    fontSize: 11,
                    lineHeight: 1.3,
                    paddingLeft: 33,
                    color: "#303030",
                    width: "80%",
                    paddingTop: 10,
                  }}
                >
                  {props?.awards ? props?.awards : "-"}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: "50%" }}>
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 15,
                }}
              >
                <Image
                  style={{ marginRight: 5, width: 15, height: 15 }}
                  src={OffersImg}
                ></Image>
                <Text
                  style={{
                    fontSize: 14,
                    color: props?.primaryColor,
                  }}
                >
                  {"Offers or Incetives to Leveage?"}
                </Text>
              </View>

              <View style={{ alignItems: "left" }}>
                <Text
                  style={{
                    fontSize: 11,
                    lineHeight: 1.3,
                    paddingLeft: 33,
                    color: "#303030",
                    width: "80%",
                    paddingTop: 10,
                  }}
                >
                  {props?.offers ? props?.offers : "-"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const PoweredByXDG = ({style = {}}) => (<View style={{display:'flex', position:'absolute', flexDirection:"row", height:'90vh', width:"95vw",justifyContent:"flex-end",alignItems:"flex-end", ...style,zIndex:9999}}>
<Text style={{fontSize:10}} >Powered by </Text>
<Image src={wordmark_blue} style={{ height: 12, marginTop:10  }}></Image>
</View>)

export const CompanyInfoCover = (props) => {
  return (

  <View
    style={{
      padding: "0 20px",
      height: "87vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      position:'relative'
    }}
  >
   <PoweredByXDG style={{width:'90vw'}}/>
    <View style={{ width: "50%" }}>
      <Image src={props?.logoUrl?.logourl || `https://logo.clearbit.com/${props.logoUrl?.url}`} style={{ width: 250, maxHeight:80 }}></Image>
      <Text style={{ fontSize: 24, fontWeight: "bold", marginTop: "20px" }}>
        Company Information
      </Text>
      <Text style={{ fontSize: 16, fontWeight: "normal", marginTop: "20px" }}>
        GTM Intelligence helps to gather all the data of your Company &
        Customers in one place to enrich the on demand gen experience.
      </Text>
    </View>
    {/* <View
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image style={{ width: "70%" }} src={CompanyInfoCoverImg}></Image>
    </View> */}

  </View>
);
    }

export const AssetInfoCover = (props) => {
  return (
  <View
    style={{
      padding: "0 20px",
      height: "85vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    }}
  >
   <PoweredByXDG style={{width:'87vw'}}/>
    <View style={{ width: "50%" }}>
    <Image src={props?.logoUrl?.logourl || `https://logo.clearbit.com/${props.logoUrl?.url}`} style={{ width: 250, maxHeight:80 }}></Image>
      <Text style={{ fontSize: 24, fontWeight: "bold", marginTop: "20px" }}>
        Asset Library Information
      </Text>
      <Text style={{ fontSize: 16, fontWeight: "normal", marginTop: "20px" }}>
        GTM Intelligence helps to gather all the data of your Company &
        Customers in one place to enrich the on demand gen experience.
      </Text>
    </View>
    <View
      style={{
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image style={{ width: "70%" }} src={CompanyInfoCoverImg}></Image>
    </View>
  </View>
  )
    };

export const AssetLibraryInfo = (props) => (
  <View>
    <PoweredByXDG />
    {props.AppliedFilters && Object.keys(props.AppliedFilters)?.length > 0 && <View>
    <Text
        style={{
          width: "100%",
          textAlign: "left",
          fontSize: 18,
          fontWeight: "bold",
          padding:20
        }}
      >
        Applied Filters
      </Text>

      <View
          style={{
            width: "100%",
            padding:20,
            paddingTop:0
          }}
        >
          {Object.keys(props.AppliedFilters).map(key => {
            return <View style={{width:'100%', display:"flex",flexDirection:"row",marginTop:10}}>
          <View style={{ width: "50%" }}>
            <Text style={{ fontSize: 13, color: props?.primaryColor }}>{props.filterKeys[key]}</Text>
          </View>

          <View style={{ width: "50%", display:"flex", flexDirection:"row" }}>
            {props.AppliedFilters?.[key]?.map((item,index) => {
              return <Text style={{ fontSize: 12, paddingRight:10 }}>{item}{index === props.AppliedFilters?.[key]?.length - 1 ? "" : ","}</Text>
            })}
          </View>


            </View>
          })}

      </View>

    </View>
}
    <View style={{ padding: 20 }}>
      <Text
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: 24,
          fontWeight: "bold",
          marginBottom: 10,
        }}
      >
        Asset Library Information
      </Text>

      <View style={{ width: "100%" }}>
        <View
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View style={{ width: "15%" }}>
            <Text style={{ fontSize: 13, color: props?.primaryColor }}>Asset Type</Text>
          </View>

          <View style={{ width: "15%" }}>
            <Text style={{ fontSize: 13, color: props?.primaryColor }}>Asset Name</Text>
          </View>

          <View style={{ width: "20%" }}>
            <Text style={{ fontSize: 13, color: props?.primaryColor, textAlign:'center' }}>
            Abstract
            </Text>
          </View>
          <View style={{ width: "20%" }}>
            <Text style={{ fontSize: 13, color: props?.primaryColor, textAlign:'center' }}>
              Created Date
            </Text>
          </View>

          <View style={{ width: "30%" }}>
            <Text style={{ fontSize: 13, color: props?.primaryColor }}>
              Link to Hosted Content
            </Text>
          </View>

        </View>


        {props?.Assets?.map((item) => (
          <View
            style={{
              margin: "10px 0",
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View style={{ width: "15%" }}>
              <Text style={{ fontSize: 11 }}>{item.type}</Text>
            </View>

            <View style={{ width: "15%" }}>
              <Text style={{ fontSize: 11 }}>{item.name}</Text>
            </View>

            <View style={{ width: "20%" }}>
              <Text style={{ fontSize: 11, overflowWrap: "break-word",textAlign: 'center' }}>
                {item.abstract}
              </Text>
            </View>

            <View style={{ width: "20%" }}>
              <Text style={{ fontSize: 11, overflowWrap: "break-word",textAlign: 'center' }}>
                {item.created_date}
              </Text>
            </View>

            <View style={{ width: "30%" }}>
              <Text style={{ fontSize: 11, overflowWrap: "break-word", minWidth: '13%',  }}>
                {item.hosted_url}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  </View>
);


function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function truncate(str, max) {
  return str?.length > max ? str?.substr(0, max - 1) + "…" : str;
}
