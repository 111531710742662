import React from "react";

import Invite from "./Invite";
import Connect from "./Connect";
import AccountInfo from "./AccountInfo";

const ConnectAndInvite = (props) => {
  const {accountDetails} = props
  return (
    <>
    {/* For future reference  */}
      <div className="responsive-grid connectAndInvite" style={{display: 'flex' , gap:'10px', flexWrap: 'wrap', marginBottom:'20px'}}>
        {((props.userPermission?.["settings.integrations"] && props.userPermission?.["settings.integrations"] !== "hidden" && props.userPermission?.["settings.integrations"] !== "preview") || (props.account?.[2] === "admin" && (props.viewType !== "companyadmin" && props.viewType !== "companycontributor") )) && 
          
          <div style={{flex: 1 , flexBasis: 0}}>
              <Connect isEmpty={props.isEmpty} userPermission = {props.userPermission} isLoading={props?.isLoading}></Connect>
          </div>
        }

        {/* {((props.userPermission?.["settings.integrations"] === "preview" && props.account?.[2] === "admin" && props.viewType === "user") || (props.userPermission?.["settings.integrations"] === "preview" && props.account?.[2] !== "admin")) && 
            <div style={{margin : '0 8px 14px 0' , flexGrow: 1 , flexBasis: 0}}>
            <PreviewInfo img = {dashboard_connect_preview} text = "Upgrade to View" centerStyle = {{top:"50%", padding:10}}  />
            </div>
        } */}

        {((props.userPermission?.["dashboard.invite"] && props.userPermission?.["dashboard.invite"] !== "hidden") || (props.account?.[2] === "admin" && (props.viewType !== "companyadmin" && props.viewType !== "companycontributor") )) && 
            <div style={{flex: 1 , flexBasis: 0,  alignItems:'center'}}>
              <Invite isEmpty={props.isEmpty} isLoading ={props?.isLoading}></Invite>
            </div>
        }
        {/* <div style={{ flexGrow: 1 , flexBasis: 0}}>
          <AccountInfo accountDetails = {accountDetails} isLoading={props?.isLoading} isContent={props.isContent?true:false}/>
        </div> */}
      </div>

      {/* <Row gutter={[10, 15]}>
        {
          ((props.userPermission?.["settings.integrations"] && props.userPermission?.["settings.integrations"] !== "hidden" && props.userPermission?.["settings.integrations"] !== "preview") || (props.account?.[2] === "admin" && props.viewType !== "user")) && 
        <Col span={8} xs={24} md={24} sm={24} lg={8}>
        <Connect isEmpty={props.isEmpty} userPermission = {props.userPermission} viewType={props.viewType}></Connect>
        </Col>
        }
        
        {((props.userPermission?.["dashboard.invite"] && props.userPermission?.["dashboard.invite"] !== "hidden") || (props.account?.[2] === "admin" && props.viewType !== "user")) && 
        <Col span={8} xs={24} md={24} sm={24} lg={8} >
        <Invite isEmpty={props.isEmpty}></Invite>
        </Col>
        }

        <Col span={8} xs={24} md={24} sm={24} lg={8} >
        <AccountInfo accountDetails = {accountDetails}/>
        </Col>

      </Row> */}
    </>
  );
};

export default ConnectAndInvite;
