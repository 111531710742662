/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, message, Row, Skeleton, Modal, Radio } from "antd";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { getConnectedCRMs,} from "store/actions/reportAction";
import tick from "assets/Images/tick.svg";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import PreviewInfo from "commons/PreviewInfo";
import dashboard_connect_preview from "assets/Images/dashboard_connect_preview.png"
import { crmConnections, createConnectionURLs } from "utility/Constants";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'
import { useParams } from "react-router-dom";


const Connect = (props) => {
  const {isLoading} = props
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.loginReducer.groups);

  const{content} = useParams();

  let isContent = content?true:false;

  const [isSalesforceConnectModalVisible,setIsSalesforceConnectModalVisible] = useState(false);
  const [salesforceConnectValue, setSalesforceConnectValue] = useState("salesforceProduction");


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    async function fetchData(option) {
      if (params.has("code")) {
        const code = params.get("code");
        const isSalesforceSandbox = salesforceConnectValue === 'SalesforceSandbox' ? 1 : 0;
        var response = await API.get(
          "exploricsREST",
          `/integrations/${option}/auth?code=${code}&is_sandbox=${isSalesforceSandbox}`,
          {}
        );
        if (response.errors || response.error) {
          message.error(response.errors || response.error);
        } else {
          dispatch(getConnectedCRMs())
        }
      }
    }
    if (params.has("state")) {
      const option = params.get("state");
      fetchData(option);
    }
    for (var key of params.keys()) {
      params.delete(key);
    }
    history.replace({
      search: params.toString(),
    });
    // eslint-disable-next-line
  }, [window.location.search]);


  const {ConnectedCRMs} = useSelector(state => state.reportReducer)

  //const ConnectionUrls = useMemo(() => connectionURLs, [])


  useEffect(() => {
    let dataObj = JSON.parse(JSON.stringify(crmConnections));

    Object.keys(ConnectedCRMs)?.forEach(key => {
      dataObj[key].connected = ConnectedCRMs[key]?.length > 0
    })
    setAllCRMs(dataObj)

  },[ConnectedCRMs])

  const [AllCRMs, setAllCRMs] = useState({})

  const salesforceConnectChange = (e) => {
    setSalesforceConnectValue(e.target.value)
  }
  // CONNECT SALESFORCE
  const handleSalesforceConnect = () => {
    let parentUrl = window.location.href;
    const ConnectionUrls = createConnectionURLs(parentUrl);
    
    if(salesforceConnectValue === 'salesforceProduction'){
      window.open(ConnectionUrls['Salesforce']);
      setIsSalesforceConnectModalVisible(false)
    }else{
      window.open(ConnectionUrls['SalesforceSandbox']);
      setIsSalesforceConnectModalVisible(false)
    }
    
  }

 

  const HandleConnectCRM = (platform, customEvent) => {
    TrackClickFunction(customEvent);
    if(platform === 'Salesforce'){
      setIsSalesforceConnectModalVisible(true);
      return;
    }
    let parentUrl;
    
    if(window.self !== window.top){
      let parentUrlMain = document?.referrer;
      parentUrl = `${parentUrlMain}/demand-generation?page=dashboard`;
    }else{
      parentUrl = window.location.href;
    }
    //sessionStorage.setItem("connectRedirectUrl", window.location.href);
     const ConnectionUrls = createConnectionURLs(parentUrl);

   if(parentUrl){
       window.open(ConnectionUrls[platform]);
    }
    //sessionStorage.setItem("connectRedirectUrl", window.location.href);
  //   const ConnectionUrls = createConnectionURLs(parentUrl);

  //  if(parentUrl){
      
  //       if(platform === 'GA'){

  //         window.open(ConnectionUrls[`${platform}Content`]);
  //       }else{
  //         window.open(ConnectionUrls[platform]);
  //       }
      
  //   }
  }

  return (
    <div
      style={{
        padding:"20px 15px",
        background: "#fff",
        height: "100%",
        borderRadius:7

      }}
      className="panel-design"
    >
      
      <>
      {
        isLoading ? <Skeleton active /> :
      <>
      <div style={{padding:0, marginBottom:'10px'}}
        className={`${
          !props.isEmpty ? "connect-filled titleText" : ""
        } dashboard-card2-header titleText header2`}
      >
        Connect your accounts with us
      </div>
      <div className="dashboard-card-sub-header bodyText" style={{marginBottom:'20px'}}>
        Link your data directly with Demand Generation for easy access of analytics reports &
        other revenue metrics
      </div>

      <Row gutter={[16, 16]} style={{marginTop: 10, margin:"10px -6px 0px -6px"}}>
        {Object.keys(AllCRMs).map(key => {
          return  <Col span={12} xs={24} md={12} lg={12} style={{paddingBottom:0}}>
          <div style={{ paddingTop: 5 , display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' }}>
            <div className={AllCRMs[key]?.name} style={{ display: "flex" }}>
              <div style={{ display: "inline-block" }}>
                <div>
                  <img className="linkedImg" src={AllCRMs[key]?.logo} alt={key} style={{ paddingRight: AllCRMs[key]?.name === 'SalesForce' ? 0 : 8, height: AllCRMs[key]?.name === 'SalesForce' ? 16 : 22 }} />
                </div>
              </div>

              <div style={{display: "flex", alignItems: 'center'}}>
                <div
                  className={`connect-label titleText ${
                    !props.isEmpty ? "link-now-filled" : ""
                  }`}
                >
                  {AllCRMs[key]?.name}
                </div>
              </div>

            </div>

            {AllCRMs[key]?.connected ? (
              <>
              
              <div
                style={{display: "flex", justifyContent: 'flex-end', fontSize: 18 }}
              >
                <div style={{ paddingTop: 0 }}>
                  <div style={{ display: "inline-block" }}>
                    <img
                    className="linkedImg"
                      src={tick}
                      alt="linked"
                      style={{ height: 18, paddingLeft: 5 }}
                    />
                  </div>
                </div>
              </div>
              </>
            ) : (
              <div style={{ display: "flex", justifyContent: 'flex-end'}} className="link-icon">
                {props.isEmpty ? (
                  props.userPermission?.["settings.integrations"] !== "viewonly" && <Button
                    className="link-now-btn"
                    type="primary"
                    onClick={() => HandleConnectCRM(key)}
                    style={{ width: 107, height: 40, marginRight: 10 }}
                  >
                    <LinkOutlined />
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "inline-block",
                      float: "right",
                      paddingTop: 2,
                    }}
                  >
                    {props.userPermission?.["settings.integrations"] !== "viewonly" &&
                    <div className="link-now-link">
                      <Link onClick={() => {
                        switch (key) {
                          case 'GA':
                            HandleConnectCRM(key, 'demand-gen-connect-google-analytics');
                            break;
                          case 'GoogleAds':
                            HandleConnectCRM(key, 'demand-gen-connect-google-ads');
                            break;
                          case 'LinkedIn':
                            HandleConnectCRM(key, 'demand-gen-connect-linkedin');
                            break;
                          case 'Hubspot':
                            HandleConnectCRM(key, 'demand-gen-connect-hubspot');
                            break;
                          case 'Facebook':
                            HandleConnectCRM(key, 'demand-gen-connect-facebook');
                            break;
                            case 'Salesforce':
                              HandleConnectCRM(key, 'demand-gen-connect-salesforce');
                            break;  
                          default:
                            HandleConnectCRM(key);
                            break;
                        }
                      }} to="#" 
                      data-heap={(() => {
                        let dataHeap;
                        switch (key) {
                          case 'GA':
                            dataHeap = 'demand-gen-connect-google-analytics';
                            break;
                          case 'GoogleAds':
                            dataHeap = 'demand-gen-connect-google-ads';
                            break;
                          case 'LinkedIn':
                            dataHeap = 'demand-gen-connect-linkedin';
                            break;
                          case 'Hubspot':
                            dataHeap = 'demand-gen-connect-hubspot';
                            break;
                          case 'Facebook':
                            dataHeap = 'demand-gen-connect-facebook';
                            break;
                          case 'Salesforce':
                            dataHeap = 'demand-gen-connect-salesforce';
                            break;
                          default:
                            dataHeap = '';
                            break;
                        }
                        return dataHeap; // Return the calculated value
                      })()}>
                      <LinkOutlined />
                      </Link>
                    </div>}
                  </div>
                )}
              </div>
            )}
          </div>

        </Col>
        })}
      </Row>
      </>
}
      </>
      <Modal
        title="Which account do you want to connect with?"
        visible={isSalesforceConnectModalVisible}
        className="salesforce_connect_modal"
        footer={null}
        closable={false}
        centered
      >
        <div className="actions">
          <Radio.Group onChange={salesforceConnectChange} value={salesforceConnectValue}>
            <Radio value='salesforceProduction'>Salesforce Production</Radio>
            <Radio value='salesforceSandbox'>Salesforce Sandbox</Radio>
          </Radio.Group>   
        </div>
        <div className="footer_actions" style={{display:'flex', alignItems:'cnter',justifyContent:'center', borderTop:'1px solid #D0D5DD'}}>
          <Button
              type="primary"
              size="small"
              
              style={{
                
                marginRight: 8,
                width: "40%",
                height:'35px'
              }}
              onClick={() => setIsSalesforceConnectModalVisible(false)}
              disabled={isLoading}
              className="secondaryButton"
            >
              Cancel
            </Button>
            <Button
              
              size="large"
              type="primary"
              style={{ width: "40%",  height:'35px' }}
              onClick={() => handleSalesforceConnect()}
              disabled={isLoading}
              className="primaryButton"
            >
              Connect
            </Button>
        </div>  
      </Modal>
    </div>
  );
};
export default Connect;
