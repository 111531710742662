/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Empty,
  Form,
  Popover,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import moment from "moment";
import { useSelector } from "react-redux";
import { numberWithCommasAndRemovedNoDecimals } from "utility/context/CommonMethods";
import ColumnLineChart from "commons/Charts/ColumnLineChart";
import sdk from "sdk/Accounts";
import {
  getFiscalYearsList,
} from "store/actions/revenueAction";
import { useDispatch } from "react-redux";
import {Link} from 'react-router-dom'

const yearOptions = [
  {
    label: moment().year() - 2,
    value: moment().year() - 2,
  },
  {
    label: moment().year() - 1,
    value: moment().year() - 1,
  },
  {
    label: moment().year(),
    value: moment().year(),
  },
];

const quarterOptions = [
  {
    label: "Q1",
    value: "Q1"
  },
  {
    label: "Q2",
    value: "Q2"
  },
  {
    label: "Q3",
    value: "Q3"
  },
  {
    label: "Q4",
    value: "Q4"
  }
];

function CampaignDealAnalysis(props) {
  
  const account = useSelector((state) => state.loginReducer.groups);
  const dispatch = useDispatch();
  const { fiscalYearList } = useSelector((state) => state.revenueReducer);

  const [skeletonLoader, setskeletonLoader] = useState(true);
  const [skeletonLoaderChannel, setskeletonLoaderChannel] = useState(true);
  const [skeletonLoaderDeal, setskeletonLoaderDeal] = useState(true);
  const [skeletonLoaderYear, setskeletonLoaderYear] = useState(true);
  const [skeletonLoaderAccount, setskeletonLoaderAccount] = useState(true);
  const [allChannelCampaignData, setAllChannelCampaignData] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(props.selectedChannel);
  const [selectedCampaign, setSelectedCampaign] = useState(props.selectedCampaign);
  const [filterChanged, setFilterChanged] = useState(false);
  const [ChartData, setChartData] = useState({});
  const[contactQuarters, setcontactQuarters] = useState(["Q1","Q2","Q3","Q4"]);
  const[closedQuarters, setclosedQuarters] = useState(["Q1","Q2","Q3","Q4"]);
  const [dropDownYearList, setDropDownYearList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [acct, setAcct] = useState(null);
  const [yearFilter, setyearFilter] = useState();

  console.log("allChannelCampaignData",allChannelCampaignData);

  const getDashboardData = async (deal_closed_year, contact_created_year, signal) => {
    
    if(deal_closed_year && contact_created_year){
      try {
        setskeletonLoaderDeal(true);
        // const contactQuartersSelected = contactQuarters.length === 4;
        // const dealQuartersSelected = closedQuarters.length === 4;
        // const dealClosedQuartersParam = dealQuartersSelected ? '' : closedQuarters.join(',');
        // const contactCreatedQuartersParam = contactQuartersSelected ? '' : contactQuarters.join(',');

        let dashboardData = await API.get(
          "backendRest",
          `/mapping/get_campaign_deal_analysis?account_id=${account[0]}&platform=${props?.CRM}&deal_closed_year=${deal_closed_year}&contact_created_year=${contact_created_year}&channel_ids=${selectedChannel}&campaign_ids=${selectedCampaign}&deal_closed_quarters=${closedQuarters}&contact_created_quarters=${contactQuarters}`,
          {signal}
        );
        // const filteredChartData = dashboardData?.data?.pipeline_data?.filter((item)=> {
        //   return contactQuarters.some((contactQuarter) => item['Deal Closed'].includes(contactQuarter));
        // })

        // const filteredClosedChartData = dashboardData?.data?.deal_data?.filter((item)=> {
        //   return closedQuarters.some((closedQuarter) => item['Contact Created'].includes(closedQuarter));
        // })
        //setChartData({ ...dashboardData?.data, pipeline_data: filteredChartData,deal_data: filteredClosedChartData});
        setChartData(dashboardData?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setskeletonLoaderDeal(false);
        setFilterChanged(false);
      }
    }   
  };

  const getChannelsAndCampaign = async () => {
    
    try {
      setskeletonLoaderChannel(true);
      let channelCampaignData = await API.get(
        "backendRest",
        `/mapping/get_channel_campaigns?account_id=${account[0]}`
      );
      
      setAllChannelCampaignData(() => channelCampaignData?.data);
      setSelectedChannel(() => channelCampaignData?.data[0]?.channel_id);
      setAllCampaigns(() => channelCampaignData?.data[0]?.campaigns);
      setSelectedCampaign(
        () => channelCampaignData?.data[0]?.campaigns[0]?.campaign_id
      );
    } catch (error) {
      console.log(error);
    } finally {
      setskeletonLoaderChannel(false);
    }
  };

  useEffect(() => {
    
    if(skeletonLoaderChannel || skeletonLoaderDeal || skeletonLoaderYear || skeletonLoaderAccount){
      setskeletonLoader(true)
    }else{
      setskeletonLoader(false)
    }

  }, [skeletonLoaderChannel, skeletonLoaderDeal, skeletonLoaderYear, skeletonLoaderAccount])
  

  useEffect(()=> {
    setskeletonLoaderYear(true)
    if(fiscalYearList.length > 0 && (acct?.fiscalYearMonth === 1 || acct?.fiscalYearMonth === null)){
      setyearFilter({
        pipeline: moment().get("year"),
        closed_won: moment().get("year"),
      })
    }
    if(fiscalYearList.length > 0 && acct?.fiscalYearMonth > 1){
      
        setyearFilter({
          pipeline: moment().get("year") + 1,
          closed_won: moment().get("year") + 1,
        })
      }
      setskeletonLoaderYear(false)  
  }, [acct])

  const handleChannelChanges = (value) => {
    setSelectedChannel(()=> value);
    setFilterChanged(true);
    let filteredChannel = allChannelCampaignData?.filter(
      (obj) => obj.channel_id === value
    );
    
    setAllCampaigns(() =>filteredChannel[0]?.campaigns);
    setSelectedCampaign(() => filteredChannel[0]?.campaigns[0]?.campaign_id);
  };
  const handleCampaignChanges = (value) => {
    setSelectedCampaign(() => value);
    setFilterChanged(true);
  };

  useEffect(() => {
    getChannelsAndCampaign();
  },[])
  
  useEffect(async () => {
    
    const controller = new AbortController();
    const timeOut = setTimeout(()=> {
      
      getDashboardData(yearFilter?.["closed_won"], yearFilter?.["pipeline"], controller.signal);
    }, 1000)    

    return () => {
      console.log("Aborting previous API call");
      clearTimeout(timeOut);
      controller.abort();
      
    };
    
  }, [yearFilter, props?.CRM, contactQuarters, closedQuarters]);

  const OnYearChange = (key, value) => {
    
    setyearFilter((old) => ({ ...old, [key]: value }));
  };

  const onContactQuaterChange = (value) => {
    
    setcontactQuarters(() => value);
  }
  const onClosedQuaterChange = (value) => {
    
    setclosedQuarters(() => value);
  }

  let pipelineChartYAxis = ["Pipeline Amount", "Deals"];
  let pipelineChartXAxis = "Deal Closed";

  let closedWonChartYAxis = ["Closed Amount", "Deals"];
  let closedWonChartXAxis = "Contact Created";

  useEffect(async () => {
    
    (async () => {
      const fiscalYears = await dispatch(
        getFiscalYearsList({ account: { eq: account[0] } })
      );
      
      const dropdownYears = fiscalYears?.items
        ?.map((item, index) => {
          return {label : item.year, value:item.year};
        })
        ?.sort();
        let currentYear;
        let prevYear;
        let nextYear;
        if(acct?.fiscalYearMonth === 1 || acct?.fiscalYearMonth === null){
         
          currentYear = moment().get("year");
          prevYear = currentYear - 1;
          nextYear = currentYear + 1;
        }

        if(acct?.fiscalYearMonth > 1){
         
          currentYear = moment().get("year") + 1;
          prevYear = currentYear - 1;
          nextYear = currentYear + 1;
        }

        // Check and add missing years
        const yearsToAdd = [currentYear, prevYear, nextYear];
        yearsToAdd.forEach(year => {
          if (!dropdownYears.some(item => item.value === year)) {
            dropdownYears.push({ label: year, value: year });
          }
        });

        dropdownYears.sort((a, b) => a.value - b.value);
      // if (!dropdownYears?.includes(selectedYear)) {
      //   // New Logic
      //   const currentYear = new Date().getFullYear();
      //   let newYear = dropdownYears?.at(-1) ?? selectedYear;
      //   if (dropdownYears?.includes(currentYear)) {
      //     newYear = currentYear;
      //   }

      

      //   setselectedFiscalYear(newYear);
      //   handleFiscalYearChange(newYear);
      // }
      setDropDownYearList(dropdownYears);

      setskeletonLoader(false);

    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acct]);

  const fetchAccountDetails = async () => {
    
    const accountID = account.length > 0 ? account[0] : null;
    if (accountID) {
      setskeletonLoaderAccount(true);
      let account = await sdk.fetchAccount(accountID);

      setAcct(account);
      setskeletonLoaderAccount(false);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);
  return (
    <>
      {/* Pipeline Chart */}
      <div className="reporting-card-body" style={{marginTop:'20px'}}>
        <Form layout="vertical" style={{marginBottom:'20px'}}>
          <Row gutter={[18, 18]}>
            <Col span={12}
              lg={12}
              md={12}
              xs={24}
              sm={24}>
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text
                  style={{
                    fontSize: "20px",
                    color:'#1D2939',
                    marginLeft: "5px",
                    fontWeight: 700,
                  }}
                >
                  Channel
                </Typography.Text>
                <Select
                  placeholder={
                    <Typography.Text
                      style={{
                        fontSize: "16px",
                        fontWeight: 200,
                        marginBottom: "5px",
                      }}
                      
                    >
                      Select Channel
                    </Typography.Text>
                  }
                  className="general-input-select"
                  onChange={handleChannelChanges}
                  value={selectedChannel}
                  disabled={skeletonLoader}
                  style={{ width: '100%', padding: "10px 0px" }}
                >
                  {allChannelCampaignData?.length > 0 &&
                    allChannelCampaignData?.map((item) => (
                      <Select.Option value={item?.channel_id}>
                        {item?.channel_name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </Col>
            <Col span={12}
              lg={12}
              md={12}
              xs={24}
              sm={24}>
              <div className="year-selector panel-design" style={{border:'1px solid #D0D5DD', padding:'15px'}}>
                <Typography.Text
                  style={{
                    
                    marginLeft: "5px",
                    fontWeight: 600,
                    fontSize: "20px",
                    color:'#1D2939',
                  }}
                >
                  Campaign
                </Typography.Text>
                <Select
                  placeholder={
                    <Typography.Text
                      style={{
                        fontSize: "16px",
                        fontWeight: 200,
                        marginBottom: "5px",
                      }}
                    >
                      Select Campaign
                    </Typography.Text>
                  }
                  className="general-input-select"
                  disabled={skeletonLoader}
                  value={selectedCampaign}
                  onChange={handleCampaignChanges}
                  style={{ width: '100%', padding: "10px 0px" }}
                >
                  {allCampaigns?.length > 0 &&
                    allCampaigns?.map((item) => (
                      <Select.Option value={item?.campaign_id}>
                        {item?.campaign_name}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </Col>
            {filterChanged && (
              <Col>
                <Button
                  loading={skeletonLoader}
                  onClick={() => {
                    const controller = new AbortController();

                    getDashboardData(
                      yearFilter?.["closed_won"],
                      yearFilter?.["pipeline"],
                      controller.signal
                    );
                  }}
                  type="primary"
                  style={{ height: "32px", marginTop: "30px" }}
                >
                  {" "}
                  Apply Filter{" "}
                </Button>
              </Col>
            )}
          </Row>
        </Form>

        <Row justify="space-between" gutter={[18, 18]}>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Total Pipeline
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData?.["total_pipeline"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Deals (Pipeline)
              </Typography.Text>

              {ChartData?.["tooltip"]?.[0]?.pipeline?.length ? (
                <Popover
                  placement="topRight"
                  arrowPointAtCenter
                  title={<strong>Deals</strong>}
                  content={
                    <div style={{ maxHeight: 400, overflow: "scroll" }}>
                      {/* <Descriptions size={"small"} bordered>
                        {ChartData["tooltip"]?.[0]?.pipeline?.map(
                          (item, index) => (
                            <Descriptions.Item
                              span={3}
                              label={item?.id}
                              key={index}
                            >
                              <span>{item?.name}</span>
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions> */}
                      <table className="custom_description_item_table">
                        <tr>
                          <th>Account Name</th>
                          <th>Opportunity Name</th>
                        </tr>
                        {ChartData?.["tooltip"]?.[0]?.pipeline?.map(
                          (item, index) => (
                          <tr>
                            <td><a href={item?.title_url} target="_blank" rel="noreferrer">{item?.title}</a></td>
                            <td><a href={item?.value_url} target="_blank" rel="noreferrer">{item?.value}</a></td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  }
                >
                  <Button
                    type="text"
                    style={{
                      fontFamily: "cursive",
                      fontWeight: 600,
                      fontSize: "18px",
                      color: "#bdb6b6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: 10,
                      top: 3,
                    }}
                  >
                    i
                  </Button>
                </Popover>
              ) : null}

              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData?.["total_pipeline_deals"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={5}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Avg Deal Size (Pipeline)
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData?.["avg_pipeline_deal_size"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={12} xl={5}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Year (Contact Created)
              </Typography.Text>
              <Select
                value={yearFilter?.["pipeline"]? yearFilter?.["pipeline"] : moment().get('year')}
                placeholder="Year"
                style={{ width: 172, padding: "6px 0px", marginTop:'5px' }}
                options={dropDownYearList}
                onChange={(value) => OnYearChange("pipeline", value)}
                disabled={skeletonLoader}
                className="general-input-select"
              />
            </div>
          </Col>
          <Col span={4} xs={24} sm={12} md={24} lg={12} xl={6}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Quarter (Contact Created)
              </Typography.Text>
              
              <Select
                mode="multiple"
                size={'middle'}
                className="general-input-select"
                defaultValue={['Q1', 'Q2', 'Q3', 'Q4']}
                onChange={(value) => onContactQuaterChange(value)}
                style={{ width: 220, padding: "6px 0px", marginTop:'5px' }}
                options={quarterOptions}
            />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col span={24} className="analysis-chart-wrapper panel-design">
            <div style={{ width: "100%" }}>
              <Typography.Text style={{ fontSize: "20px", fontWeight: 500, color:"#1D2939" }}>
                Pipeline In Play
              </Typography.Text>
              <br />
              {skeletonLoader ? (
                <Skeleton.Button
                  style={{ width: "75vw", height: 400 }}
                  active
                />
              ) : ChartData && ChartData?.["pipeline_data"]?.length > 0 ? (
                <ColumnLineChart
                  data={ChartData?.["pipeline_data"] || []}
                  xField={pipelineChartXAxis}
                  yFields={pipelineChartYAxis}
                />
              ) : (
                <RenderEmptyChart />
              )}
            </div>
          </Col>
        </Row>

        <Divider />
        <Row justify="space-between" gutter={[18, 18]}>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Closed Won Revenue
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData?.["total_closed_won_revenue"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={4}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Deal (Closed Won)
              </Typography.Text>

              {ChartData?.["tooltip"]?.[0]?.close_won?.length ? (
                <Popover
                  placement="topRight"
                  arrowPointAtCenter
                  title={<strong>Deals</strong>}
                  content={
                    <div style={{ maxHeight: 400, overflow: "scroll" }}>
                      {/* <Descriptions size={"small"} bordered>
                        {ChartData["tooltip"]?.[0]?.close_won?.map(
                          (item, index) => (
                            <Descriptions.Item
                              span={3}
                              label={item?.id}
                              key={index}
                            >
                              <span>{item?.name}</span>
                            </Descriptions.Item>
                          )
                        )}
                      </Descriptions> */}
                      <table className="custom_description_item_table">
                        <tr>
                          <th>Account Name</th>
                          <th>Opportunity Name</th>
                        </tr>
                        {ChartData?.["tooltip"]?.[0]?.close_won?.map(
                          (item, index) => (
                          <tr>
                            <td><a href={item?.title_url} target="_blank" rel="noreferrer">{item?.title}</a></td>
                            <td><a href={item?.value_url} target="_blank" rel="noreferrer">{item?.value}</a></td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  }
                >
                  <Button
                    type="text"
                    style={{
                      fontFamily: "cursive",
                      fontWeight: 600,
                      fontSize: "18px",
                      color: "#bdb6b6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: 10,
                      top: 3,
                    }}
                  >
                    i
                  </Button>
                </Popover>
              ) : null}

              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData?.["total_closed_won_deals"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={5}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}>
                Avg Deal Size (Closed Won)
              </Typography.Text>
              {skeletonLoader ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontSize:"18px", fontWeight:700, textAlign:'center', marginTop:'5px'}}>
                  $
                  {ChartData
                    ? numberWithCommasAndRemovedNoDecimals(
                        ChartData?.["avg_closed_won_deal_size"] || 0
                      )
                    : 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={12} lg={12} xl={5}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
                
              >
                Year (Deal Closed)
              </Typography.Text>
              <Select
                value={yearFilter?.["closed_won"]?yearFilter?.["closed_won"]:moment().get('year')}
                disabled={skeletonLoader}
                placeholder="Year"
                style={{ width: 172, padding: "6px 0px", marginTop:'5px' }}
                options={dropDownYearList}
                onChange={(value) => OnYearChange("closed_won", value)}
                className="general-input-select"
              />
            </div>
          </Col>
          <Col span={4} xs={24} sm={12} md={24} lg={12} xl={6}>
            <div className="executive-analysis panel-design" style={{    alignItems: 'center'}}>
              <Typography.Text
                className="analysis-title"
                style={{color:"#1D2939",fontWeight:500, textAlign:"center", fontSize:'16px'}}
              >
                Quarter (Deal Closed)
              </Typography.Text>
              <Select
                mode="multiple"
                size={'middle'}
                defaultValue={['Q1', 'Q2', 'Q3', 'Q4']}
                onChange={(value) => onClosedQuaterChange(value)}
                style={{ width: 220, padding: "6px 0px", marginTop:'5px' }}
                options={quarterOptions}
                className="general-input-select"
            />
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col span={24} className="analysis-chart-wrapper panel-design">
            <div style={{ width: "100%" }}>
              <Typography.Text style={{ fontSize: "20px", fontWeight: 500, color:"#1D2939" }}>
                Closed Won Lookback
              </Typography.Text>
              <br />
              {skeletonLoader ? (
                <Skeleton.Button
                  style={{ width: "75vw", height: 400 }}
                  active
                />
              ) : ChartData && (ChartData?.["deal_data"] || [])?.length > 0 ? (
                <ColumnLineChart
                  data={ChartData?.["deal_data"] || []}
                  xField={closedWonChartXAxis}
                  yFields={closedWonChartYAxis}
                />
              ) : (
                <RenderEmptyChart />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CampaignDealAnalysis;

const RenderEmptyChart = () => (
  <Empty
    style={{
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
  />
);
