/* eslint-disable react-hooks/exhaustive-deps */
import { DualAxes, Pie } from "@ant-design/charts";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmptyChart from "commons/EmptyChart";
import { numberWithCommas } from "utility/context/CommonMethods";

const dateGranularityOptions = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Quarter",
    value: "quarter",
  },
];

const BreakDownOptions = [
  {
    label: "Channel Grouping",
    value: "channelgrouping",
  },
  {
    label: "Campaign",
    value: "campaign",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Source",
    value: "source",
  },
];

function AnalyticsStatistics() {
  const DualLineChartConfig = {
    xField: "Date Granularity",
    yField: ["Pageviews","Sessions"],
    height: 300,
    appendPadding: 20,
    xAxis: {
    title: {
      text: 'Date Granularity',
         position: 'bottom',
         style: {
           fontSize: 14,
           fontWeight : 600
      }
    },
    },
     yAxis: [
       {
      min : 0,
      title: {
      text: 'Pageviews (Sum)',
        position: 'bottom',
      style: {
           fontSize: 14,
           fontWeight : 600
      }
    },
            },
    
       {
      min : 0,
      title: {
      text: 'Sessions (Sum)',
        position: 'bottom',
      style: {
           fontSize: 14,
           fontWeight : 600
      }
    },
    },
  ],

  };

  const tableColumns = [
    {
      title: (
              <Tooltip title="Breakdown">
                <div className="header-cell" style={{fontSize:'15px'}}>
                  <b>Breakdown</b>
                </div>
              </Tooltip>
        ),
      dataIndex: "breakdown",
      key: "breakdown",
      width: "30%",
      ellipsis: true,
      render: (text) =><Typography.Text className="channel-text" style={{ whiteSpace: "normal"}}>{text}</Typography.Text>
    },
    
    {
      title: (
              <Tooltip title="Sessions">
                <div className="header-cell" style={{fontSize:'15px'}}>
                  <b>Sessions</b>
                </div>
              </Tooltip>
        ),
      dataIndex: "sessions",
      key: "sessions",
      width: "15%",
      render: (text) =><Typography.Text style={{ fontWeight: 500}}>{text}</Typography.Text>

    },
    
    {
      title: (
              <Tooltip title="Pageviews">
                <div className="header-cell" style={{fontSize:'15px'}}>
                  <b>Pageviews</b>
                </div>
              </Tooltip>
        ),
      dataIndex: "pageviews",
      key: "pageviews",
      width: "15%",
      render: (text) => <Typography.Text style={{ fontWeight: 500}}>{text}</Typography.Text>

    },
    
    {
      title: (
              <Tooltip title="Pageviews / Session">
                <div className="header-cell" style={{fontSize:'15px'}}>
                  <b>Pageviews / Session</b>
                </div>
              </Tooltip>
        ),
      dataIndex: "pageviews_per_session",
      key: "pageviews_per_session",
      width: "22%",
      render: (text) => <Typography.Text style={{ fontWeight: 500}}>{text}</Typography.Text>
               

    },
    
    
    {
      title: (
              <Tooltip title="Avg Session Duration">
                <div className="header-cell" style={{fontSize:'15px'}}>
                  <b>Avg Session Duration</b>
                </div>
              </Tooltip>
        ),
      dataIndex: "avg_session_duration_sec",
      key: "avg_session_duration_sec",
      width : "22%",
      render: (info) => <Typography.Text style={{ fontWeight: 500}}>{info} sec</Typography.Text>
    },
    
    {
      title: (
              <Tooltip title="Bounce Rate">
                <div className="header-cell" style={{fontSize:'15px'}}>
                  <b>Bounce Rate</b>
                </div>
              </Tooltip>
        ),
      dataIndex: "bounce_rate",
      key: "bounce_rate",
      width: "20%",
      render: (text) =><Typography.Text style={{ fontWeight: 500}}>{text}</Typography.Text>
    },
    
  ];

  const PieChartconfig = {
    appendPadding: 10,
    angleField: "sessions",
    colorField: "breakdown",
    radius: 0.9,
    height : 300,
    legend: {
      position: "top",
    },
    label: false,
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const account = useSelector((state) => state.loginReducer);
  console.log("account",account);

  const [isLoading, setisLoading] = useState(false);
  const [GAData, setGAData] = useState({});

  const [filterChanged, setfilterChanged] = useState(false);
  const [dateRange, setdateRange] = useState([
    moment().startOf("month"),
    moment(),
  ]);
  const [DateGranularity, setDateGranularity] = useState("month");
  const [BreakdownField, setBreakdownField] = useState("channelgrouping");

  useEffect(() => {
    getChartData();
  }, []);

  const getChartData = async () => {
    try {
      setisLoading(true);
      const fromDate = dateRange?.[0]?.format("YYYY-MM-DD");
      const toDate = dateRange?.[1]?.format("YYYY-MM-DD");
      let dashboardData = await API.get(
        "backendRest",
        `/mapping/get_google_analytics?account_id=${account?.accountDetails?.id}&breakdown_field=${BreakdownField}&date_granularity=${DateGranularity}&from_date=${fromDate}&to_date=${toDate}`
      );
      console.log(dashboardData?.data);
      setGAData(dashboardData?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
      setfilterChanged(false);
    }
  };



  return (
    <>
      <div className=" reporting-card-body">
        <Row gutter={[18, 18]} style={{ marginBottom: 20 }}>
          <Col span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}>
          <div className="panel-design" style={{border:'1px solid #D0D5DD', padding:'15px', height:'100%'}}>      
          <Typography.Title level={5} style={{color:"#1D2939", marginBottom:'10px', fontSize:'20px'}}>Date Range</Typography.Title>
            <DatePicker.RangePicker
              value={dateRange}
              onChange={(e) => {
                setdateRange(e);
                setfilterChanged(true);
              }}
              size="small"
            />
            </div>
          </Col>

          <Col span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}>
                <div className="panel-design"  style={{border:'1px solid #D0D5DD', padding:'15px'}}> 
          <Typography.Title level={5} style={{color:"#1D2939", marginBottom:'10px', fontSize:'20px'}}>Date Granularity</Typography.Title>
            <Select
              value={DateGranularity}
              onChange={(e) => {
                setDateGranularity(e);
                setfilterChanged(true);
              }}
              style={{width:'100%'}}
              className="general-input-select"

            >
              {dateGranularityOptions?.map((item) => (
                <Select.Option value={item?.value}>{item?.label}</Select.Option>
              ))}
            </Select>
            </div>
          </Col>

          <Col span={8}
              lg={8}
              md={8}
              xs={24}
              sm={24}>
                <div className="panel-design"  style={{border:'1px solid #D0D5DD', padding:'15px'}}> 
          <Typography.Title level={5} style={{color:"#1D2939", marginBottom:'10px', fontSize:'20px'}}>Breakdown</Typography.Title>
            <Select
              value={BreakdownField}
              onChange={(e) => {
                setBreakdownField(e);
                setfilterChanged(true);
              }}
              style={{width:'100%'}}
              className="general-input-select"
            >
              {BreakDownOptions?.map((item) => (
                <Select.Option value={item?.value}>{item?.label}</Select.Option>
              ))}
            </Select>
            </div>
          </Col>
          {filterChanged && (
            <Col>
              <Button loading={isLoading} onClick={getChartData} type="primary" style={{height: '32px', marginTop : "30px"}}>
                {" "}
                Apply Filter{" "}
              </Button>
            </Col>
          )}
        </Row>

        <Row justify="space-between" gutter={[18, 18]}>
          <Col span={6}  xs={24} sm={12} md={6} lg={6} xl={6} >
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px',fontWeight:700, textAlign:'center'}}>
                Sessions
              </Typography.Text>
              {isLoading ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                  {numberWithCommas(GAData?.total_sessions || 0)}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px',fontWeight:700, textAlign:'center'}}>
                Page Views
              </Typography.Text>
              {isLoading ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                  {numberWithCommas(GAData?.total_pageviews || 0)}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px',fontWeight:700, textAlign:'center'}}>
                Page Views Per Session
              </Typography.Text>
              {isLoading ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                  {GAData?.pageviews_per_session || 0}
                </Typography.Text>
              )}
            </div>
          </Col>
          <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
            <div className="executive-analysis panel-design">
              <Typography.Text className="analysis-title" style={{color:"#1D2939", marginBottom:'10px', fontSize:'18px', fontWeight:700, textAlign:'center'}}>
                Avg Session Duration
              </Typography.Text>
              {isLoading ? (
                <Skeleton.Button active style={{ margin: "8px 0px" }} />
              ) : (
                <Typography.Text className="analysis-value" style={{color:"#7F56D9", fontWeight:500, textAlign:'center'}}>
                  {GAData?.avg_session_duration || 0}
                </Typography.Text>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <div className=" reporting-card-body">
        <Row>
          <Col span={24}>
            <Typography.Text className="label-title-text header2">
              Sum of Sessions and Sum of Pageviews by Date Granularity
            </Typography.Text>
            {isLoading ? <Skeleton active /> : 
          <>  
            {(GAData?.session_pageview_date_granularity || [])?.length > 0 ? (
              <div>
                <DualAxes
                  {...DualLineChartConfig}
                  data={[GAData?.session_pageview_date_granularity ,GAData?.session_pageview_date_granularity]}
                />
              </div>
            ) : (
              <Empty />
            )}
            </>}
          </Col>
        </Row>

        <Divider />

        <Row gutter={[18,18]}>
          <Col span={18} xs={24} sm={24} md={18} lg={18} xl={18} >
            <Typography.Text  className="label-title-text header2" style={{marginBottom:'15px', display:'inline-block'}} >Session Breakdown Table</Typography.Text>
            {isLoading ? <Skeleton active /> :<Table
              dataSource={GAData?.session_breakdown_table || []}
              bordered
              columns={tableColumns}
              scroll={{
                    y: 300,
                    x: 1000,
                  }}
                className="nowrap custom-table"
              style={{ width: "100%" }}
              pagination={false}
            />}
          </Col>

          <Col span={6}  xs={24} sm={24} md={6} lg={6} xl={6} className="executive-analysis" >
           <Typography.Text className="label-title-text header2" >Session Breakdown</Typography.Text>
                {isLoading ? <Skeleton active /> : 
                <>
                  {(GAData?.session_breakdown || [])?.length > 0  ? <div><Pie {...PieChartconfig} data={GAData?.session_breakdown || []}/></div>  : 
                  <div className="emptychart-wrapper">
                    <EmptyChart />
                  </div>}
                </>
              }
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AnalyticsStatistics;
