import React from "react";
import { Col, Row, Table } from "antd";

const ColumnEmptyState = (props) => {
  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    obj.props.colSpan = 0;
    return obj;
  };

  const columns = [
    {
      title: <b>Channels</b>,
      dataIndex: "channel-campaign",
      key: "channel-campaign",
      // fixed: "left",
      width: 200,
      className: "budgetLeadsViewTableFirstCell",
      render: (text, record) => (
        <div style={{ flexGrow: 1 }}>
          <Row className="budgetLeadsViewTableRow">
            <Col span={22}>
              <span className="channel-text">{text}</span>
            </Col>
          </Row>
        </div>
      ),
    },

    {
      title: (
        <div>
          <b>Q1</b>
        </div>
      ),
      dataIndex: "q1",
      key: "q1",
      render: (text, record, index) => {
        return {
          children: (
            <>
           { (props.userPermission?.['budgetCampaign.campaigns.campaign'] && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'viewonly' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'preview' && props.userPermission?.['budgetCampaign.campaigns.campaign'] !== 'hidden' && props.userPermission?.['budgetCampaign'] && props.userPermission?.['budgetCampaign']  !== 'preview' && props.userPermission?.['budgetCampaign']  !== 'viewonly') &&   <span>
              {/* eslint-disable-next-line */}
              <a
                className="create-new"
                onClick={() => props.handleCampaignAddWithChannelClick(record)}
              >
                +Create{" "}
              </a>
              <span className="create-new-text">
                Your first campaign in this channel
              </span>
            </span>}
           { (props.userPermission?.['budgetCampaign.campaigns.campaign'] === 'viewonly' || props.userPermission?.['budgetCampaign.campaigns.campaign'] === 'preview' || props.userPermission?.['budgetCampaign']  === 'preview' || props.userPermission?.['budgetCampaign']  === 'viewonly') &&   <span>
              {/* eslint-disable-next-line */}
              <a
                className="create-new"
                disabled
                style = {{color:"#d3d3d3"}}
                title = "View only permission"
              >
                +Create{" "}
              </a>
              <span className="create-new-text">
                Your first campaign in this channel
              </span>
            </span>}
            </>
          ),
            
            
          props: {
            colSpan: 5,
          },
        };
      },
    },

    {
      title: (
        <div>
          <b>Q2</b>
        </div>
      ),
      dataIndex: "q2",
      key: "q2",
      render: renderContent,
    },
    {
      title: (
        <div className="selected-column">
          <b>Q3</b>
        </div>
      ),
      dataIndex: "q3",
      key: "q3",
      render: renderContent,
    },
    {
      title: (
        <div className="selected-column">
          <b>Q4</b>
        </div>
      ),
      dataIndex: "q4",
      key: "q4",
      render: renderContent,
    },
    {
      title: <b>FY Total</b>,
      dataIndex: "fy-totals",
      key: "fy-totals",
      render: renderContent,
    },
  ];

  props?.rows?.forEach(function (v) {
    delete v.children;
  });

  return (
    <>
      <Table
        columns={columns}
        className="campaign-table empty-state"
        dataSource={props.rows}
        pagination={false}
        bordered
      />
    </>
  );
};

export default ColumnEmptyState;
