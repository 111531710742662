import { message } from "antd";
import { ExploricApi } from "utility/Api";
import { GetSortOrder } from "utility/context/CommonMethods";
import {
  SET_COMPANY_POSITIONING,
  SHOW_LOADER,
  SET_SOCIAL_CHANNELS,
  SET_CURRENT_MARKET_ACTIVITY,
  SET_MARKETING_AUTOMATION_SYSTEM,
  SET_CURRENT_MARKET_TOOLS,
  SET_TARGET_IDEAL_CUSTOM_PROFILE,
  SET_PERSONAS,
  SET_ASSETS_LIBRARY
} from "./index";


export const setLoader = (data) => {
  return {
    type: SHOW_LOADER,
    payload: data,
  };
};
export const setCompanyPositioning = (data) => {
  return {
    type: SET_COMPANY_POSITIONING,
    payload: data,
  }
};
export const setCompanySocialChannels = (data) => {
  return {
    type: SET_SOCIAL_CHANNELS,
    payload: data,
  }
};
export const setCurrentMarketActivity = (data) => {
  return {
    type: SET_CURRENT_MARKET_ACTIVITY,
    payload: data,
  }
};
export const setCurrentMarketTools = (data) => {
  return {
    type: SET_CURRENT_MARKET_TOOLS,
    payload: data,
  }
};
export const setMarketingAutomationSystem = (data) => {
  return {
    type: SET_MARKETING_AUTOMATION_SYSTEM,
    payload: data,
  }
};
export const setTargetIdealCustomProfile = (data) => {
  return {
    type: SET_TARGET_IDEAL_CUSTOM_PROFILE,
    payload: data,
  }
};
export const setPersonas = (data) => {
  return {
    type: SET_PERSONAS,
    payload: data,
  }
};

export const setAssetLibrary = (data) => {
  return {
    type: SET_ASSETS_LIBRARY,
    payload: data,
  }
};

export const getCompanyPositioning = () => (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  return ExploricApi.getPositioningApi({ account: { eq: account[0] } }).then(response => {
    response?.data?.listPositionings?.items.sort(GetSortOrder("updatedAt"))
    dispatch(setCompanyPositioning(response?.data?.listPositionings?.items))
  }).catch(err => console.log("Error",err))
  
}

export const getCompanySocialChannelsList = () => (dispatch, getState) => {
  dispatch(setLoader(true))
  const account = getState().loginReducer.groups;
  return ExploricApi.getSocialChannelsListApi({account: {eq : account[0]}}).then(response => {
    dispatch(setCompanySocialChannels(response?.data?.listSocialChannelss?.items))
  dispatch(setLoader(false))
}).catch(err => {console.log("Error", err)
dispatch(setLoader(false))

})
}


export const getCurrentMarketActivitiesList = () => (dispatch, getState) => {
  dispatch(setLoader(true))
  const account = getState().loginReducer.groups;
  return ExploricApi.listCurrentMarketActivitiesApi({account: {eq : account[0]}}).then(response => {
    dispatch(setCurrentMarketActivity(response?.data?.listCurrentMarketActivitys?.items))
  dispatch(setLoader(false))
  }).catch(err => {console.log("Error", err)
  dispatch(setLoader(false))
})
}

export const getCurrentMarketToolsList = () => (dispatch, getState) => {
  dispatch(setLoader(true))
  const account = getState().loginReducer.groups;
  return ExploricApi.listCurrentMarketToolsApi({account: {eq : account[0]}}).then(response => {
    dispatch(setCurrentMarketTools(response?.data?.listCurrentSalesMarketingToolss?.items))
  dispatch(setLoader(false))
  }).catch(err => {console.log("Error", err)
  dispatch(setLoader(false))
})
}


export const getMarektingAutomationSystemList = () => (dispatch, getState) => {
  dispatch(setLoader(true))

  const account = getState().loginReducer.groups;
  return ExploricApi.listmarketingAtuomationSystemApi({account: {eq : account[0]}}).then(response => {
    dispatch(setMarketingAutomationSystem(response?.data?.listCrmMarketingAutomationSystems?.items))
  dispatch(setLoader(false))

  }).catch(err => {console.log("Error", err)
  dispatch(setLoader(false))

})
}

export const getTargetIdealCustomProfileList = () => (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  return ExploricApi.listTargetIdealCustomProfilesApi({account: {eq : account[0]}}).then(response => {
    dispatch(setTargetIdealCustomProfile(response?.data?.listTargetIdealCustomProfiles?.items))
  }).catch(err => console.log("Error", err))
}

export const getPersonasList = () => (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  return ExploricApi.listPersonasApi({account: {eq : account[0]}}).then(response => {
    dispatch(setPersonas(response?.data?.listPersonass?.items))
  }).catch(err => console.log("Error", err))
}

export const getAssetLibraryList = () => (dispatch, getState) => {
  dispatch(setLoader(true))
  const account = getState().loginReducer.groups;
  return ExploricApi.listAssetLibraryApi({account: {eq : account[0]}}).then(response => {
    dispatch(setAssetLibrary(response?.data?.listAssetLibrarys?.items))
  dispatch(setLoader(false))

}).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})
}

export const createCompanyPositioning =  (inputdata) => (dispatch) => {
  return ExploricApi.createPositioningApi(inputdata).then(response => {
    dispatch(getCompanyPositioning())

}).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})
  
}
export const UpdateCompanyPositioning =  (inputdata) => (dispatch) => {
  return ExploricApi.updatePositioningApi(inputdata).then(response => {
    dispatch(getCompanyPositioning())

  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}

export const createSocialChannel =  (inputdata) => (dispatch) => {
  return ExploricApi.createSocialChannelApi(inputdata).then(_ => {
    dispatch(getCompanySocialChannelsList())
    message.success("Saved successfully!")

  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const UpdateSocialChannel =  (inputdata) => (dispatch) => {
  return ExploricApi.updateSocialChannelApi(inputdata).then(response => {
    dispatch(getCompanySocialChannelsList())
    message.success("Saved successfully!")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const createCurrentMarketActivity =  (inputdata) => (dispatch) => {
  return ExploricApi.createCurrentMarketActivityApi(inputdata).then(response => {
    dispatch(getCurrentMarketActivitiesList())
    message.success("Saved successfully!")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const updateCurrentMarketActivity =  (inputdata) => (dispatch) => {
  return ExploricApi.updateCurrentMarketActivityApi(inputdata).then(response => {
    dispatch(getCurrentMarketActivitiesList())
    message.success("Saved successfully!")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}

export const createCurrentMarketTools =  (inputdata) => (dispatch) => {
  return ExploricApi.createCurrentMarketToolApi(inputdata).then(response => {
    dispatch(getCurrentMarketToolsList())
    message.success("Saved successfully!")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const updateCurrentMarketTools =  (inputdata) => (dispatch) => {
  return ExploricApi.updateCurrentMarketToolApi(inputdata).then(response => {
    dispatch(getCurrentMarketToolsList())
    message.success("Saved successfully!")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}

export const createMarketingAutomationSystem =  (inputdata) => (dispatch) => {
  return ExploricApi.createMarketingAutomationSystemApi(inputdata).then(response => {
    dispatch(getMarektingAutomationSystemList())
    message.success("Saved successfully!")

  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const updateMarketingAutomationSystem =  (inputdata) => (dispatch) => {
  return ExploricApi.updateMarketingAutomationSystemApi(inputdata).then(response => {
    dispatch(getMarektingAutomationSystemList())
    message.success("Saved successfully!")

  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}

export const createTargetIdealCustomProfile =  (inputdata) => (dispatch) => {
  return ExploricApi.createTargetIdealCustomProfileApi(inputdata).then(response => {
    dispatch(getTargetIdealCustomProfileList())
    message.success("Target audience profile created successfully")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const updateTargetIdealCustomProfile =  (inputdata) => (dispatch) => {
  return ExploricApi.updateTargetIdealCustomProfileApi(inputdata).then(response => {
    dispatch(getTargetIdealCustomProfileList())
    message.success("Target audience profile updated successfully")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}

export const createPersona =  (inputdata) => (dispatch) => {
  return ExploricApi.createPersonaApi(inputdata).then(response => {
    dispatch(getPersonasList())
    message.success("Persona created successfully")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const updatePersona =  (inputdata) => (dispatch) => {
  return ExploricApi.updatePersonaApi(inputdata).then(response => {
    dispatch(getPersonasList());
    message.success("Persona updated successfully")

  }).catch(err => console.log("Error", err))
}

export const createAssetLibrary =  (inputdata) => (dispatch) => {
  dispatch(setLoader(true))
  return ExploricApi.createAssetLibraryApi(inputdata).then(response => {
    dispatch(getAssetLibraryList())
    message.success("Asset created successfully")
  dispatch(setLoader(false))


}).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
export const updateAssetLibrary =  (inputdata) => (dispatch) => {
  dispatch(setLoader(true))
  return ExploricApi.updateAssetLibraryApi(inputdata).then(response => {
    dispatch(getAssetLibraryList())
    dispatch(setLoader(false))
    message.success("Asset updated successfully")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})


}
export const DeleteAssetLibrary =  (inputdata) => (dispatch) => {
  dispatch(setLoader(true))
  return ExploricApi.deleteAssetLibraryApi(inputdata).then(response => {
    dispatch(getAssetLibraryList())
    dispatch(setLoader(false))
    message.success("Asset deleted succesfully")
  }).catch(err => {console.log("Error", err); dispatch(setLoader(false)); message.error("Something went wrong")})

}
