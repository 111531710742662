import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryCourseList } from "store/actions/coachingAction";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import { useHistory,useParams } from "react-router-dom";

const SimilarCourses = (props) => {
  
  const dispatch = useDispatch();
  const { CategoryCouseList } = useSelector((state) => state.coachingReducer);
  const [heightOfPopulerCourse, setHeightOfPopulerCourse] = useState({})

  const history = useHistory();
  const handleCourseClick = (CourseDetails) => {
    if(props.isContent){
      history.push({
        pathname: `/coaching/content/course/${CourseDetails.course.slug}`,
        state: { isContent: true }
      });
    }else{
    history.push({
      pathname: `/coaching/course/${CourseDetails.course.slug}`,
    });
  }
  };

  useEffect(() => {
    dispatch(getCategoryCourseList());
  }, [dispatch]);

  const [popularCoachingData, setPopularCoachingData] = useState();
  useEffect(() => {
    const coachingArray = [];
    CategoryCouseList.listCourseCategorys?.items.map((CategoryCouseListVal, index) => { // eslint-disable-line
      CategoryCouseListVal.courses?.items.map((coachingCatValue) => { // eslint-disable-line
        coachingArray.push({details:coachingCatValue, category: CategoryCouseListVal.name});
      });
    });

    if (coachingArray.length > 3) {
      setPopularCoachingData(coachingArray.slice(-4));
    } else {
      setPopularCoachingData(coachingArray);
    }
  }, [CategoryCouseList]);


  useEffect(() => {
    const resizeHandler = () => {
      var CourseHeight = {};
      if(popularCoachingData && popularCoachingData?.length > 0)
      {
        popularCoachingData.forEach(element => {
          var b = document.getElementById(element.details.course.id);
          if(b){
            CourseHeight[element.details.course.id] = b.getBoundingClientRect().width * 0.75;
          }
        });
      }
      setHeightOfPopulerCourse(CourseHeight);
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => window.removeEventListener('resize', resizeHandler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function getBannerHeight(id)
  {
    if( heightOfPopulerCourse[id])
      return `${heightOfPopulerCourse[id]}px`
    return "172px";
  }

  return (
    <div
      className="site-card-wrapper"
      style={{
        background: "#fff",
          // background: "#B4FEE7",
        marginTop: 15,
        paddingTop: 10,
        borderRadius: 7,
      paddingRight:20, paddingLeft:20}}
    >
      <div className="coaching-title" style={{color:"", fontSize:"20px"}}>Other Similar Demand Generation Courses</div>

      <Row
        className="similar-courses"
        gutter={[16, 16]}
        style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 8 }}
      >
        {popularCoachingData?.length > 0 ? (
          popularCoachingData?.map((coursesVal, index) => {
            return (
              <Col
                span={6}
                style={{ padding: 0, marginBottom: 47 }}
                xs={24}
                md={12}
                lg={6}
                key={index}
              >
                <div>
                  {" "}
                  <div className="similar-blog-image" id={coursesVal.details.course.id} style={{ display: "inline-block", height: 170}}>
                    <AmplifyS3Image
                      level="protected"
                      identityId={coursesVal.details.course.featuredimage.split("/")[0]}
                      style={{
                        "--height": getBannerHeight(coursesVal.details.course.id),// "172px",
                        "--width": "95%",
                        "--alt": "image"
                      }}
                      path={coursesVal.details.course.featuredimage.split("/")[1]}
                      onClick={() => handleCourseClick(coursesVal.details)}
                    />
                  </div>
                  <div
                    className="coaching-title-link"
                    onClick={() => handleCourseClick(coursesVal.details)}
                  >
                    {coursesVal.details.course.name}
                  </div>
                  <div
                    title={coursesVal.details.course.short_description}
                    style={{ paddingTop: 8, width: "95%", height: 52 }}
                    className="coaching-desc card-description-dashboard-blog"
                  >
                    {coursesVal.details.course.short_description}
                  </div>
                </div>
              </Col>
            );
          })
        ) : (
          <div className="center">No Data Found </div>
        )}
      </Row>
    </div>
  );
};

export default SimilarCourses;
