import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import { Auth } from "aws-amplify";
import backgroundImg from "assets/Images/login_signup_background.svg";
import Logo from "commons/Logo";
import { useHistory } from "react-router";

function ConfirmPassword(props) {
  const [msg, setmsg] = useState({ text:"Check your email for a confirmation code", type:"success"});
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(props.location?.state ? props.location?.state?.userName : "");
  const history = useHistory();


  useEffect(() => {
    if (!props.location.state) {
    if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/reset-password") && window.location.href.includes("email=")
      )
    {
      let Data = {}
      Data.code = window.location.href.split("confirmationcode=")[1].split("&email=")[0]
        form.setFieldsValue({
          code: Data.code,
          password: ""
        })
        setemail(window.location.href.split("email=")[1]);
      }
    else {
      history.push("/")
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const resendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email).then(() => {
        setmsg("A new confirmation code has been sent.");
        setloading(false);
      });
    } catch (error) {
      console.log("error resend code :", error.message);
      setloading(false);
      setmsg({ text: error.message, type: "error" })
    }
  }

  const confirmReset = async (e) => {
    setloading(true);
    setmsg({ text: "", type: "" })
    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        history.push({
          pathname: "/signin",
          state: { feedback: "Signin with new password.", userName: "" },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      setloading(false);
      setmsg({ text: error.message, type: "error" })
    }
  }

  return (
    <>
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={6} style={{ height: "100%" }}>
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: "100vh", minHeight: 580 }}
          />
        </Col>
        <Col xs={24} md={24} lg={18} style={{ transform: "scale(0.88)" }}>
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight:580
            }}
          >
            <Card style={{ border: "none", width: "437px" }}>
              <div style={{ marginBottom: 35 }}>
                <Logo />
              </div>
              {msg?.text && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type={msg?.type}
                  showIcon={false}
                  message={msg.text}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_reset"
                className="reset-form"
                onFinish={(e) => confirmReset(e)}
                autoComplete="off"
                form={form}
              >
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={
                    <small>
                      {" "}
                      <strong
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Confirmation Code
                      </strong>
                    </small>
                  }
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Confirmation Code!",
                    },
                  ]}
                >
                  <Input
                    style={{ fontSize: 18 }}
                    id="code"
                    key="code"
                    name="code"
                    placeholder="Enter your confirmation code"
                    disabled={loading}
                    tabIndex={1}
                    type="number"
                  />
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 }}
                  style={{ fontSize: 18 }}

                  label={
                    <small>
                      <strong style={{ fontSize: 18 }}>Password</strong>
                    </small>
                  }
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    disabled={loading}
                    tabIndex={2}
                    autoFocus
                    autocomplete="new-password"
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={8}>
                      <Button
                        type="link"
                        size="small"
                        block
                        onClick={resendConfirmationCode}
                        disabled={loading}
                        style={{ textAlign: "left" }}
                        tabIndex={6}
                      >
                        <span style={{ color: "#0F61DB", fontWeight: "600" }}>
                          Resend Code
                        </span>
                      </Button>
                    </Col>
                    <Col span={10}>
                      <Button
                        style={{fontSize:18}}
                        type="primary"
                        size="small"
                        htmlType="submit"
                        block
                        loading={loading}
                        tabIndex={5}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
            <div>
              <p
                style={{
                  color: "#646C79",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  userSelect: "none",
                  marginBottom: "0px",
                }}
              >
                Version{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "0.1.0"}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#646C79",
                  textAlign: "center",
                }}
              >
                &copy; {new Date().getFullYear()} XFactor.io copyright all
                rights reserved.
              </p>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ConfirmPassword;
