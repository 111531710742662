import React, { useState, useRef, useEffect } from "react";
import {Funnel} from "@ant-design/charts";
import { Button, Typography, Divider } from "antd";
import ExecutiveDrawer from "pages/reports/Executive/V2/ExecutiveDrawer";
import { useSelector, useDispatch } from "react-redux";
import { setLeadsDrawer, setLeadsData } from "store/actions/reportAction";
const { Title } = Typography;

const FunnelChartsExecutive = (props) => {
    const {data,
        color,
        height,
        noStageText,
        conversionTag,
        labelColor,
        transform,
        isActual} = props;
    const dispatch = useDispatch();
  const [StageDrawerTitle, setStageDrawerTitle] = useState(null);
  const [StageDrawerCount, setStageDrawerCount] = useState(null);
  const {leadsDrawerOpen, leadsData} = useSelector((state) => state.reportReducer);
  const funnelChartRef = useRef(null);

  const onStageClick = (title, count) => {
    let mappedData = [];
    data.forEach((item)=>{
        if(item.text === title){
            mappedData.push(item.opportunities);
        }
      })
    setStageDrawerTitle(title);
    setStageDrawerCount(count)
    dispatch(setLeadsDrawer(true))
    dispatch(setLeadsData({
        [title]: mappedData
    }))
  };

  const onStageClose = () => {
    dispatch(setLeadsDrawer(false))
  };

  useEffect(()=>{
    dispatch(setLeadsDrawer(false));
  }, [])

  const config = {
    data,
    dynamicHeight: true,
    xField: "text",
    yField: "width",
    legend: false,
    conversionTag: conversionTag
      ? {
          offsetX: 2,
          offsetY: 0,
          formatter: (datum) => datum?.percentageValue + "%",
        }
      : false,
    height: height ? height : 600,
    appendPadding: conversionTag ? 31 : 20,
    minSize: 0.2,
    maxSize: 1,
    color: color
      ? color
      : [
          "#030D1D",
          "#2e004d",
          "#3d0066",
          "#4c0080",
          "#5c0099",
          "#6b00b3",
          "#7a00cc",
          "#8a00e6",
        ],
    label: {
      style: {
        fontSize: 14,
        lineWidth: 11,
        lineHeight: 20,
        textWrap: true,
        textOverfloe: "hide",
        fontWeight: 500,
        fill: labelColor ? labelColor : "#fff",
      },
      content: (action) => {
        if (noStageText) {
          return `${action.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (!action.value && !isActual) {
          return `${action.text}`;
        }
        return (
          `${action.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          `\n ${action.text}`
        );
      },
    },
    tooltip: {
      follow: true,
      enterable: true,
      showDelay: 200,
      customContent: (title, data) => {
        if (data[0]?.data && data[0]?.data?.opportunities?.length > 0) {
            return (
            <div style={{ padding: "5px 0" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Title level={5} style={{ margin: 0, fontSize: "14px" }}>{title}</Title>
                <Title level={5} style={{ margin: 0, fontSize: "14px", marginLeft: "30px" }}>{data[0]?.data?.value}</Title>
                </div>
                <Divider style={{ margin: "5px 0 0 0" }} />
                <div style={{ textAlign: "center" }}>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => onStageClick(title,data[0]?.data?.value)}
                    style={{ padding: 0, width: "100px", height: "25px", fontSize: "12px", marginTop: "10px" }}
                >View More</Button>
                </div>
            </div>
            );
        }else{
            return null;
        }
      },
    },
    point: {
      size: 5,
      shape: "Funnel",
    },
  };

  return (
    <>
      <div style={{ transform: transform ? "scale(1.1)" : "" }}>
        <Funnel {...config} chartRef={funnelChartRef} />
      </div>
      <ExecutiveDrawer leadsDrawerOpen={leadsDrawerOpen} title={StageDrawerTitle} count={StageDrawerCount} onDrawerClose={onStageClose} leadsData={leadsData}/>
    </>
  );
};

export default FunnelChartsExecutive;
