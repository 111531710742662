import { API } from 'aws-amplify';
import {
  getBaselineMetric,
  listFunnelConfigs,
  listStages,
  listCampaignChannels,
  listCampaigns,
  listCourseCategorys,
  listCourseCategoryConnections,
  listChartDatas,
  getAccount,
  getCrmSyncHistoryByDate,
  listPlans,
  listAccountRequests,
  listPositionings,
  listSocialChannelss,
  listCurrentMarketActivitys,
  listCrmMarketingAutomationSystems,
  listCurrentSalesMarketingToolss,
  listTargetIdealCustomProfiles,
  listPersonass,
  listAssetLibrarys,
  getMaterializerSyncHistoryByAccount,
  listSalesAndMarketingAlignments,
  listSDRCapacityCalculators,
  listSDRCompensationModels,
  listSDRMeetingAndOpportunityModels,
  listWebProfiless,
  listTerritorys
} from "graphql/queries";
import {
  createBaselineMetric,
  createFunnelConfig,
  createStage,
  deleteStage,
  updateBaselineMetric,
  updateFunnelConfig,
  updateStage,
  createBudget,
  createCampaignChannel,
  createCampaign,
  createBudgetCampaign,
  updateBudget,
  createCourseCategory,
  createCourse,
  createCourseCategoryConnection,
  deleteCourse,
  updateCourse,
  deleteCourseCategoryConnection,
  deleteCampaign,
  deleteBudgetCampaign,
  deleteBudget,
  updateCampaign,
  updateCampaignChannel,
  deleteCampaignChannel,
  createCourseVideo,
  createFiscalYear,
  updateFiscalYear,
  deleteFiscalYear,
  deleteBaselineMetric,
  deleteCourseVideo,
  createChartData,
  updateChartData,
  createPropertiesPackage,
  updatePropertiesPackage,
  createProperty,
  deleteProperty,
  updateProperty,
  createAccountPackage,
  createUserPackage,
  createAccountPackagesPropertiesPackageConnection,
  updateAccountPackagesPropertiesPackageConnection,
  createUserPackagesPropertiesPackageConnection,
  deleteUserPackage,
  deleteAccountPackage,
  deleteUserPackagesPropertiesPackageConnection,
  deleteAccountPackagesPropertiesPackageConnection,
  createCampaignDetail,
  createCampaignMonth,
  deleteCampaignDetail,
  deleteCampaignMonth,
  updateCampaignDetail,
  updateCampaignMonth,
  deleteFunnelConfig,
  updateAccount,
  createPositioning,
  updatePositioning,
  createSocialChannels,
  updateSocialChannels,
  updateCurrentMarketActivity,
  createCurrentMarketActivity,
  createCrmMarketingAutomationSystem,
  updateCrmMarketingAutomationSystem,
  createCurrentSalesMarketingTools,
  updateCurrentSalesMarketingTools,
  createTargetIdealCustomProfile,
  updateTargetIdealCustomProfile,
  createPersonas,
  updatePersonas,
  createAssetLibrary,
  updateAssetLibrary,
  deleteAssetLibrary,
  createSalesAndMarketingAlignment,
  updateSalesAndMarketingAlignment,
  deleteSalesAndMarketingAlignment,
  createSDRCapacityCalculator,
  updateSDRCapacityCalculator,
  createSDRCompensationModel,
  updateSDRCompensationModel,
  createSDRMeetingAndOpportunityModel,
  updateSDRMeetingAndOpportunityModel,
  deleteSDRCompensationModel,
  deleteSDRMeetingAndOpportunityModel,
} from 'graphql/mutations';

import {
  listbaselinetric,
  listBudgetCustom,
  listCourseCustom,
  listCategoryCoursesCustom,
  listBudgetCampaign,
  listFiscalYearsListCustom,
  getFiscalYearCustom,
  listPropertyPackages,
  listUserPackages,
  listAccountPackages,
  getBudgetCustom,
  getFunnelConfigCustom,
} from 'graphql/customQueries';

const getFiscalYearsList = (filter) => {
  return API.graphql({
    query: listFiscalYearsListCustom,
    variables: { filter: filter },
  });
};
const createFiscalYearApi = (inputData) => {
  return API.graphql({
    query: createFiscalYear,
    variables: { input: inputData },
  });
};

const updateFiscalYearApi = (inputData) => {
  return API.graphql({
    query: updateFiscalYear,
    variables: { input: inputData },
  });
};

const deleteFiscalYearApi = (inputData) => {
  return API.graphql({
    query: deleteFiscalYear,
    variables: { input: inputData },
  });
};

const getFiscalYearApi = (id) => {
  return API.graphql({
    query: getFiscalYearCustom,
    variables: { id: id },
  });
};

const getBaseLineMetricList = (filter, limit = 1000) => {
  return API.graphql({
    query: listbaselinetric,
    variables: { filter: filter, limit: limit },
  });
};

const getAccountRequests = () => {
  return API.graphql({ query: listAccountRequests });
};

const createBaselineMetricApi = (inputData) => {
  return API.graphql({
    query: createBaselineMetric,
    variables: { input: inputData },
  });
};

const updateBaseLineMetric = (inputData) => {
  return API.graphql({
    query: updateBaselineMetric,
    variables: { input: inputData },
  });
};

const deleteBaselineMetricApi = (inputData) => {
  return API.graphql({
    query: deleteBaselineMetric,
    variables: { input: inputData },
  });
};
const getBaselineMetricData = (id) => {
  return API.graphql({ query: getBaselineMetric, variables: { id: id } });
};

const createFunnelConfiguration = (inputData) => {
  return API.graphql({
    query: createFunnelConfig,
    variables: { input: inputData },
  });
};

const updateFunnelConfiguration = (inputData) => {
  return API.graphql({
    query: updateFunnelConfig,
    variables: { input: inputData },
  });
};

const getFunnelConfiguration = (id) => {
  return API.graphql({ query: getFunnelConfigCustom, variables: { id: id } });
};
const getMultipleFunnelConfiguration = (id) => {
  return API.graphql({ query: getFunnelConfigCustom, variables: { id: id } });
};
const getFunnelConfigsList = (filter, limit) => {
  return API.graphql({
    query: listFunnelConfigs,
    variables: { filter: { funnelConfigID: { eq: filter } }, limit: 10 },
  });
};

const createFunnelStages = (inputData) => {
  return API.graphql({ query: createStage, variables: { input: inputData } });
};

const updateFunnelStages = (inputData) => {
  return API.graphql({ query: updateStage, variables: { input: inputData } });
};

const deleteFunnelStages = (inputData) => {
  return API.graphql({ query: deleteStage, variables: { input: inputData } });
};
const deleteFunnelConfigApi = (inputData) => {
  return API.graphql({
    query: deleteFunnelConfig,
    variables: { input: inputData },
  });
};

const getFunnelStagesList = (filter, limit) => {
  return API.graphql({
    query: listStages,
    variables: { filter: { configID: { eq: filter } }, limit: 10 },
  });
};

const createBudgetAPI = (inputData) => {
  return API.graphql({ query: createBudget, variables: { input: inputData } });
};

const getBudgetListApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listBudgetCustom,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const getTerritorysApi = () => {
  return API.graphql({
    query: listTerritorys,
  });
};

const getListUserPackages = (filter, limit) => {
  return API.graphql({
    query: listUserPackages,
    variables: { filter: filter },
  });
};

const getListAccountPackages = (filter, limit) => {
  return API.graphql({
    query: listAccountPackages,
    variables: { filter: filter },
  });
};

const listPropertiesPackagesApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listPropertyPackages,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const getBudgetApi = (id) => {
  return API.graphql({ query: getBudgetCustom, variables: { id: id } });
};

const getBudgetInfoApi = (params) => {
  return API.graphql({
    query: listBudgetCustom,
    variables: { filter: params },
  });
};

const createChannelAPI = (inputData) => {
  return API.graphql({
    query: createCampaignChannel,
    variables: { input: inputData },
  });
};

const updateChannelAPI = (inputData) => {
  return API.graphql({
    query: updateCampaignChannel,
    variables: { input: inputData },
  });
};

const getChannelListApi = (filter, nextToken) => {
  return API.graphql({
    query: listCampaignChannels,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const createCampaignAPI = (inputData) => {
  return API.graphql({
    query: createCampaign,
    variables: { input: inputData },
  });
};

const createCampaignDetailsAPI = (inputData) => {
  return API.graphql({
    query: createCampaignDetail,
    variables: { input: inputData },
  });
};

const createCampaignMonthAPI = (inputData) => {
  return API.graphql({
    query: createCampaignMonth,
    variables: { input: inputData },
  });
};

const updateBudgetAPI = (inputData) => {
  return API.graphql({ query: updateBudget, variables: { input: inputData } });
};

const createBudgetCampaignAPI = (inputData) => {
  return API.graphql({
    query: createBudgetCampaign,
    variables: { input: inputData },
  });
};

const createCourseCategoryAPI = (inputData) => {
  return API.graphql({
    query: createCourseCategory,
    variables: { input: inputData },
  });
};

const createCourseAPI = (inputData) => {
  return API.graphql({ query: createCourse, variables: { input: inputData } });
};

const updateCourseAPI = (inputData) => {
  return API.graphql({ query: updateCourse, variables: { input: inputData } });
};

const createCourseCategoryConnectionAPI = (inputData) => {
  return API.graphql({
    query: createCourseCategoryConnection,
    variables: { input: inputData },
  });
};

const getCampaignsListApi = (filter, nextToken) => {
  return API.graphql({
    query: listCampaigns,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const getCoursesListApi = (filter, limit) => {
  return API.graphql({
    query: listCourseCustom,
    variables: { filter: filter },
  });
};

const getListChartData = (filter, limit) => {
  return API.graphql({
    query: listChartDatas,
    variables: { filter: filter },
  });
};

const getCategoryCoursesListApi = (filter, limit) => {
  return API.graphql({
    query: listCategoryCoursesCustom,
    variables: {},
  });
};

const getCoursesCategoryConnectionListApi = (inputData) => {
  return API.graphql({
    query: listCourseCategoryConnections,
    variables: { input: inputData },
  });
};

const getCoursesCategoryApi = (filter, limit) => {
  return API.graphql({
    query: listCourseCategorys,
    variables: {},
  });
};

const deleteCourseApi = (inputData) => {
  return API.graphql({ query: deleteCourse, variables: { input: inputData } });
};

const deleteCourseCategoryConnectionApi = (inputData) => {
  return API.graphql({
    query: deleteCourseCategoryConnection,
    variables: { input: inputData },
  });
};

const listBudgetCampaignApi = (filter, nextToken) =>
  API.graphql({
    query: listBudgetCampaign,
    variables: { filter: { account: { eq: filter } }, nextToken, limit: 50 },
  });

const deleteCampaignApi = (inputData) => {
  return API.graphql({
    query: deleteCampaign,
    variables: { input: inputData },
  });
};
const deleteCampaignDetailApi = (inputData) => {
  return API.graphql({
    query: deleteCampaignDetail,
    variables: { input: inputData },
  });
};
const deleteCampaignMonthApi = (inputData) => {
  return API.graphql({
    query: deleteCampaignMonth,
    variables: { input: inputData },
  });
};

const deleteBudgetCampaignApi = (inputData) => {
  return API.graphql({
    query: deleteBudgetCampaign,
    variables: { input: inputData },
  });
};

const deleteBudgetAPI = (inputData) => {
  return API.graphql({
    query: deleteBudget,
    variables: { input: inputData },
  });
};

const updateCampaignApi = (inputData) => {
  return API.graphql({
    query: updateCampaign,
    variables: { input: inputData },
  });
};
const updateCampaignDetailsApi = (inputData) => {
  return API.graphql({
    query: updateCampaignDetail,
    variables: { input: inputData },
  });
};
const updateCampaignMonthApi = (inputData) => {
  return API.graphql({
    query: updateCampaignMonth,
    variables: { input: inputData },
  });
};

const deleteCampaignChannelApi = (inputData) => {
  return API.graphql({
    query: deleteCampaignChannel,
    variables: { input: inputData },
  });
};
const deleteCourseVideoApi = (inputData) => {
  return API.graphql({
    query: deleteCourseVideo,
    variables: { input: inputData },
  });
};

const createCourseVideoApi = (inputData) => {
  return API.graphql({
    query: createCourseVideo,
    variables: { input: inputData },
  });
};

const createChartDataApi = (inputData) => {
  return API.graphql({
    query: createChartData,
    variables: { input: inputData },
  });
};

const updateChartDataApi = (inputData) => {
  return API.graphql({
    query: updateChartData,
    variables: { input: inputData },
  });
};

const getAccointApi = (id) => {
  return API.graphql({ query: getAccount, variables: { id: id } });
};

const createPropertiesPackageApi = (inputData) => {
  return API.graphql({
    query: createPropertiesPackage,
    variables: { input: inputData },
  });
};

const updatePropertiesPackageApi = (inputData) => {
  return API.graphql({
    query: updatePropertiesPackage,
    variables: { input: inputData },
  });
};

const createPropertyApi = (inputData) => {
  return API.graphql({
    query: createProperty,
    variables: { input: inputData },
  });
};

const deletePropertyApi = (inputData) => {
  return API.graphql({
    query: deleteProperty,
    variables: { input: inputData },
  });
};

const updatePropertyApi = (inputData) => {
  return API.graphql({
    query: updateProperty,
    variables: { input: inputData },
  });
};

const createAccountPackageApi = (inputData) => {
  return API.graphql({
    query: createAccountPackage,
    variables: { input: inputData },
  });
};

const CreateAccountPackagesPropertiesPackageConnectionApi = (inputData) => {
  return API.graphql({
    query: createAccountPackagesPropertiesPackageConnection,
    variables: { input: inputData },
  });
};

const UpdateAccountPackagesPropertiesPackageConnectionApi = (inputData) => {
  return API.graphql({
    query: updateAccountPackagesPropertiesPackageConnection,
    variables: { input: inputData },
  });
};

const createUserPackageApi = (inputData) => {
  return API.graphql({
    query: createUserPackage,
    variables: { input: inputData },
  });
};

const CreateUserPackagesPropertiesPackageConnectionApi = (inputData) => {
  return API.graphql({
    query: createUserPackagesPropertiesPackageConnection,
    variables: { input: inputData },
  });
};

const deleteUserPackageApi = (inputData) => {
  return API.graphql({
    query: deleteUserPackage,
    variables: { input: inputData },
  });
};

const deleteAccountPackageApi = (inputData) => {
  return API.graphql({
    query: deleteAccountPackage,
    variables: { input: inputData },
  });
};

const deleteUserPackagesPropertiesPackageConnectionApi = (inputData) => {
  return API.graphql({
    query: deleteUserPackagesPropertiesPackageConnection,
    variables: { input: inputData },
  });
};

const deleteAccountPackagesPropertiesPackageConnectionApi = (inputData) => {
  return API.graphql({
    query: deleteAccountPackagesPropertiesPackageConnection,
    variables: { input: inputData },
  });
};

const getCRMsyncHistoryApi = (inputData) => {
  return API.graphql({
    query: getCrmSyncHistoryByDate,
    variables: { ...inputData, limit: 999 },
  });
};

const getPlanListApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listPlans,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const updateAccountDetails = (inputData) => {
  return API.graphql({
    query: updateAccount,
    variables: { input: inputData },
  });
};

const createPositioningApi = (inputData) => {
  return API.graphql({
    query: createPositioning,
    variables: { input: inputData },
  });
};
const updatePositioningApi = (inputData) => {
  return API.graphql({
    query: updatePositioning,
    variables: { input: inputData },
  });
};

const getPositioningApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listPositionings,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const getSocialChannelsListApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listSocialChannelss,
    variables: { filter: filter, nextToken: nextToken },
  });
};
const listCurrentMarketActivitiesApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listCurrentMarketActivitys,
    variables: { filter: filter, nextToken: nextToken },
  });
};
const listCurrentMarketToolsApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listCurrentSalesMarketingToolss,
    variables: { filter: filter, nextToken: nextToken },
  });
};
const listmarketingAtuomationSystemApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listCrmMarketingAutomationSystems,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const listTargetIdealCustomProfilesApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listTargetIdealCustomProfiles,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const listPersonasApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listPersonass,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const listAssetLibraryApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listAssetLibrarys,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const createSocialChannelApi = (inputData) => {
  return API.graphql({
    query: createSocialChannels,
    variables: { input: inputData },
  });
};
const updateSocialChannelApi = (inputData) => {
  return API.graphql({
    query: updateSocialChannels,
    variables: { input: inputData },
  });
};
const createCurrentMarketActivityApi = (inputData) => {
  return API.graphql({
    query: createCurrentMarketActivity,
    variables: { input: inputData },
  });
};
const updateCurrentMarketActivityApi = (inputData) => {
  return API.graphql({
    query: updateCurrentMarketActivity,
    variables: { input: inputData },
  });
};
const createCurrentMarketToolApi = (inputData) => {
  return API.graphql({
    query: createCurrentSalesMarketingTools,
    variables: { input: inputData },
  });
};
const updateCurrentMarketToolApi = (inputData) => {
  return API.graphql({
    query: updateCurrentSalesMarketingTools,
    variables: { input: inputData },
  });
};
const createMarketingAutomationSystemApi = (inputData) => {
  return API.graphql({
    query: createCrmMarketingAutomationSystem,
    variables: { input: inputData },
  });
};
const updateMarketingAutomationSystemApi = (inputData) => {
  return API.graphql({
    query: updateCrmMarketingAutomationSystem,
    variables: { input: inputData },
  });
};

const createTargetIdealCustomProfileApi = (inputData) => {
  return API.graphql({
    query: createTargetIdealCustomProfile,
    variables: { input: inputData },
  });
};
const updateTargetIdealCustomProfileApi = (inputData) => {
  return API.graphql({
    query: updateTargetIdealCustomProfile,
    variables: { input: inputData },
  });
};

const createPersonaApi = (inputData) => {
  return API.graphql({
    query: createPersonas,
    variables: { input: inputData },
  });
};
const updatePersonaApi = (inputData) => {
  return API.graphql({
    query: updatePersonas,
    variables: { input: inputData },
  });
};

const createAssetLibraryApi = (inputData) => {
  return API.graphql({
    query: createAssetLibrary,
    variables: { input: inputData },
  });
};
const updateAssetLibraryApi = (inputData) => {
  return API.graphql({
    query: updateAssetLibrary,
    variables: { input: inputData },
  });
};
const deleteAssetLibraryApi = (inputData) => {
  return API.graphql({
    query: deleteAssetLibrary,
    variables: { input: inputData },
  });
};
const getLastActualSyncApi = (account) => {
  return API.graphql({
    query: getMaterializerSyncHistoryByAccount,
    variables: { account: account, limit: 1, sortDirection: 'DESC' },
  });
};
const getSalesAndMarketingStagesApi = (filter, nextToken) => {
  return API.graphql({
    query: listSalesAndMarketingAlignments,
    variables: { filter: filter, limit: 1000, nextToken: nextToken },
  });
};

const createSalesAndMarketingStageApi = (inputData) => {
  return API.graphql({
    query: createSalesAndMarketingAlignment,
    variables: { input: inputData },
  });
};
const updateSalesAndMarketingStageApi = (inputData) => {
  return API.graphql({
    query: updateSalesAndMarketingAlignment,
    variables: { input: inputData },
  });
};

const deleteSalesAndMarketingStageApi = (inputData) => {
  return API.graphql({
    query: deleteSalesAndMarketingAlignment,
    variables: { input: inputData },
  });
};

const saveSDRCalculation = (inputData) => {
  return API.graphql({
    query: createSDRCapacityCalculator,
    variables: { input: inputData },
  });
};

const updpateSDRCalculationApi = (inputData) => {
  return API.graphql({
    query: updateSDRCapacityCalculator,
    variables: { input: inputData },
  });
};

const listSdrCapacityCalculatorsApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listSDRCapacityCalculators,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const ListSDRCompensationModelsApi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listSDRCompensationModels,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const listSdrMeetingAndOpportunityModelsAPi = (filter, nextToken, limit) => {
  return API.graphql({
    query: listSDRMeetingAndOpportunityModels,
    variables: { filter: filter, nextToken: nextToken },
  });
};

const CreateSDRCompensationModelApi = (inputData) => {
  return API.graphql({
    query: createSDRCompensationModel,
    variables: { input: inputData },
  });
};

const UpdateSDRCompensationModelApi = (inputData) => {
  return API.graphql({
    query: updateSDRCompensationModel,
    variables: { input: inputData },
  });
};

const createActivityTrackerSDRProfileApi = (inputData) => {
  return API.graphql({
    query: createSDRMeetingAndOpportunityModel,
    variables: { input: inputData },
  });
};

const updateActivityTrackerSDRProfileApi = (inputData) => {
  return API.graphql({
    query: updateSDRMeetingAndOpportunityModel,
    variables: { input: inputData },
  });
};

const deleteCompesationModelApi = (inputData) => {
  return API.graphql({
    query: deleteSDRCompensationModel,
    variables: { input: inputData },
  });
};

const deleteSDRRecordApi = (inputData) => {
  return API.graphql({
    query: deleteSDRMeetingAndOpportunityModel,
    variables: { input: inputData },
  });
};

const getConnectedCRMsApi = (inputData, nextToken) => {
  return API.graphql({
    query: listWebProfiless,
    variables: { filter: inputData, nextToken: nextToken },
  });
};

const ChangeQuicksightPermissionPutEvent = (params) => {
  let init = {
    body: params,
  };
  return API.post('exploricsREST', `/scope/event_bus`, init);
};
export const ExploricApi = {
  ChangeQuicksightPermissionPutEvent,
  getConnectedCRMsApi,
  ListSDRCompensationModelsApi,
  CreateSDRCompensationModelApi,
  UpdateSDRCompensationModelApi,
  getSalesAndMarketingStagesApi,
  createSalesAndMarketingStageApi,
  updateSalesAndMarketingStageApi,
  deleteSalesAndMarketingStageApi,
  getLastActualSyncApi,
  listAssetLibraryApi,
  createAssetLibraryApi,
  updateAssetLibraryApi,
  deleteAssetLibraryApi,
  listPersonasApi,
  createPersonaApi,
  updatePersonaApi,
  listTargetIdealCustomProfilesApi,
  createTargetIdealCustomProfileApi,
  updateTargetIdealCustomProfileApi,
  listmarketingAtuomationSystemApi,
  createMarketingAutomationSystemApi,
  updateMarketingAutomationSystemApi,
  listCurrentMarketActivitiesApi,
  createCurrentMarketActivityApi,
  updateCurrentMarketActivityApi,
  createSocialChannelApi,
  updateSocialChannelApi,
  getSocialChannelsListApi,
  createPositioningApi,
  getPositioningApi,
  updatePositioningApi,
  updateAccountDetails,
  deleteCampaignChannelApi,
  updateCampaignApi,
  updateCampaignDetailsApi,
  updateCampaignMonthApi,
  listBudgetCampaignApi,
  getBaseLineMetricList,
  createBaselineMetricApi,
  updateBaseLineMetric,
  createFunnelStages,
  updateFunnelStages,
  deleteFunnelStages,
  deleteFunnelConfigApi,
  createFunnelConfiguration,
  updateFunnelConfiguration,
  getFunnelConfiguration,
  getBaselineMetricData,
  getFunnelStagesList,
  getFunnelConfigsList,
  createBudgetAPI,
  getBudgetListApi,
  getTerritorysApi,
  getBudgetApi,
  createChannelAPI,
  getChannelListApi,
  createCampaignAPI,
  createCampaignDetailsAPI,
  createCampaignMonthAPI,
  getCampaignsListApi,
  createBudgetCampaignAPI,
  updateBudgetAPI,
  createCourseCategoryAPI,
  createCourseAPI,
  createCourseCategoryConnectionAPI,
  getCoursesListApi,
  getCoursesCategoryApi,
  deleteCourseApi,
  deleteCampaignApi,
  deleteCampaignDetailApi,
  deleteCampaignMonthApi,
  deleteBudgetCampaignApi,
  updateCourseAPI,
  getCoursesCategoryConnectionListApi,
  deleteCourseCategoryConnectionApi,
  getCategoryCoursesListApi,
  updateChannelAPI,
  getBudgetInfoApi,
  deleteBudgetAPI,
  createCourseVideoApi,
  getFiscalYearsList,
  createFiscalYearApi,
  updateFiscalYearApi,
  deleteFiscalYearApi,
  getFiscalYearApi,
  deleteBaselineMetricApi,
  deleteCourseVideoApi,
  createChartDataApi,
  getListChartData,
  updateChartDataApi,
  getAccointApi,
  getCRMsyncHistoryApi,
  listPropertiesPackagesApi,
  createPropertiesPackageApi,
  updatePropertiesPackageApi,
  createPropertyApi,
  deletePropertyApi,
  updatePropertyApi,
  createAccountPackageApi,
  CreateAccountPackagesPropertiesPackageConnectionApi,
  createUserPackageApi,
  CreateUserPackagesPropertiesPackageConnectionApi,
  getListUserPackages,
  getListAccountPackages,
  deleteUserPackageApi,
  deleteAccountPackageApi,
  deleteUserPackagesPropertiesPackageConnectionApi,
  deleteAccountPackagesPropertiesPackageConnectionApi,
  UpdateAccountPackagesPropertiesPackageConnectionApi,
  getPlanListApi,
  getAccountRequests,
  createCurrentMarketToolApi,
  updateCurrentMarketToolApi,
  listCurrentMarketToolsApi,
  saveSDRCalculation,
  listSdrCapacityCalculatorsApi,
  updpateSDRCalculationApi,
  createActivityTrackerSDRProfileApi,
  updateActivityTrackerSDRProfileApi,
  listSdrMeetingAndOpportunityModelsAPi,
  deleteCompesationModelApi,
  deleteSDRRecordApi,
  getMultipleFunnelConfiguration,
};
