import {
    SET_ACTUAL_REPORT_DATA,
    SET_ACTUAL_REPORT_FUNNEL_DATA,
    SHOW_LOADER,
    SET_ACTUAL_REPORT_DATA_YEARLY,
    SET_INVITATION_SENT,
    SET_ACTUAL_REPORT_WEB_DATA,
    SET_CONFIGURED_CHART_INFO,
    SET_MAPPING_STAGES,
    SET_ACCOUNT_STAGE_MAPPING_DATA,
    SET_ACTUAL_REPORT_DATA_OTHERS,
    SET_FUNNEL_ACTUAL_DATA_LOADER,
    SET_LEADS_DRAWER,
    SET_LEADS_DATA,
    SET_ACTUAL_REPORT_FUNNEL_TRENDS_DATA,
    SET_PACKAGE_PROPERTY,
    SERVER_ERROR,
    SET_USER_PACKAGES,
    SET_SALES_AND_MARKETING_ACTUALS,
    SET_SALES_MAREKETING_LEADERSHIP_DATA,
    SET_SALES_MARKETING_STAGES,
    SET_EXECUTIVE_REPORTS_DATA,
    SET_CONNECTED_CRMS,
} from "./index";
import {ExploricApi} from "../../utility/Api";
import {message} from "antd";
import { API } from "aws-amplify";

export const setActaulReportData = (data) => {
    return {
        type: SET_ACTUAL_REPORT_DATA,
        payload: data,
    };
};

export const setFunnelActualDataLoader = (data) => {
    return {
        type: SET_FUNNEL_ACTUAL_DATA_LOADER,
        payload: data,
    };
};

export const setLeadsDrawer = (data) => {
    return {
        type: SET_LEADS_DRAWER,
        payload: data,
    };
};

export const setLeadsData = (data) => {
    return {
        type: SET_LEADS_DATA,
        payload: data,
    };
};

export const setActaulReportDataOthers = (data) => {
    return {
        type: SET_ACTUAL_REPORT_DATA_OTHERS,
        payload: data,
    };
};
export const setMappingStages = (data) => {
    return {
        type: SET_MAPPING_STAGES,
        payload: data,
    };
};

export const setPackagesProperties = (data) => {
    return {
        type: SET_PACKAGE_PROPERTY,
        payload: data,
    };
};

export const setUserPackages = (data) => {
    return {
        type: SET_USER_PACKAGES,
        payload: data,
    };
};

export const setSalesAndMarketingActualTableData = (data) => {
    return {
        type: SET_SALES_AND_MARKETING_ACTUALS,
        payload: data,
    };
};

export const setSalesAndMarketingStages = (data) => {
    return {
        type: SET_SALES_MARKETING_STAGES,
        payload : data
    }
}

export const setActaulReportWebData = (data) => {
    return {
        type: SET_ACTUAL_REPORT_WEB_DATA,
        payload: data,
    };
};

export const setActaulReportDataYearly = (data) => {
    return {
        type: SET_ACTUAL_REPORT_DATA_YEARLY,
        payload: data,
    };
};
export const setActaulReportFunnelData = (data) => {
    return {
        type: SET_ACTUAL_REPORT_FUNNEL_DATA,
        payload: data,
    };
};

export const setActaulReportFunnelTrendsData = (data) => {
    return {
        type: SET_ACTUAL_REPORT_FUNNEL_TRENDS_DATA,
        payload: data,
    };
};

export const setLoader = (data) => {
    return {
        type: SHOW_LOADER,
        payload: data,
    };
};
export const setInvitationSentData = (data) => {
    return {
        type: SET_INVITATION_SENT,
        payload: data,
    };
};

export const setConfiguredChartInfo = (data) => {
    return {
        type: SET_CONFIGURED_CHART_INFO,
        payload: data,
    };
};

export const setAccountStageMappingData = (data) => {
    return {
        type: SET_ACCOUNT_STAGE_MAPPING_DATA,
        payload: data,
    };
};

export const setSalesMarketingData = (data) => {
    return {
        type: SET_SALES_MAREKETING_LEADERSHIP_DATA,
        payload: data,
    };
};

export const setServerError = (data) => {
    return {
        type: SERVER_ERROR,
        payload: data,
    };
};
export const setExecutiveActuals = (data) => {
    return {
        type: SET_EXECUTIVE_REPORTS_DATA,
        payload: data,
    };
};
export const setConnectedCRMs = (data) => {
    return {
        type: SET_CONNECTED_CRMS,
        payload: data,
    };
};

export const configureChartDataInfo = (inputData) => (dispatch, getState) => {
    dispatch(setLoader(true));
    const account = getState().loginReducer.groups;
    const operation = inputData.id
        ? ExploricApi.updateChartDataApi
        : ExploricApi.createChartDataApi;
    operation(inputData)
        .then((response) => {
            const createdChartData = response.data;
            dispatch(setLoader(false));

            dispatch(
                getConfiguredChartData({
                    account: {eq: account[0]},
                })
            );

            message.success("Success!");
            return createdChartData;
        })
        .catch((e) => {
            dispatch(setLoader(false));
            console.log("error :", e);
        });
};

export const getConfiguredChartData = (filterObj) => (dispatch, getState) => {
    dispatch(setLoader(true));
    ExploricApi.getListChartData(filterObj)
        .then((response) => {
            const configuredChartData = response.data.listChartDatas;
            dispatch(setConfiguredChartInfo(configuredChartData));
            dispatch(setLoader(false));
            return configuredChartData;
        })
        .catch((e) => {
            dispatch(setLoader(false));
            console.log("error :", e);
        });
};
  
export const getCRMsyncHistory = (inputData) => async (dispatch, getState) => {
    let list = []
    let nextToken = null
    dispatch(setLoader(true));
    do {
        inputData.nextToken = nextToken
        let res = await ExploricApi.getCRMsyncHistoryApi(inputData, nextToken).catch((e) => {
            console.log("Error:", e);
        });
        nextToken = res?.data?.getCrmSyncHistoryByDate?.nextToken;
        // eslint-disable-next-line array-callback-return
        res?.data?.getCrmSyncHistoryByDate?.items?.map((item) => {
            if (!(list.length >= 10)) {
                list.push(item);
            }
        });
        if (list.length >= 10) {
            return list;
        }
        if (!nextToken) {
            dispatch(setLoader(false));
            return list;
        }
    } while (nextToken);
    return 
}

export const listPropertyPackageData =
    (inputData, d) => (dispatch, getState) => {
        dispatch(setLoader(true));
        return ExploricApi.listPropertiesPackagesApi()
            .then((response) => {
                dispatch(setLoader(false));
                dispatch(
                    setPackagesProperties(response.data.listPropertiesPackages.items)
                );
                return response
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.log("error", error);
                return error
            });
    };

export const listUserPackagesData =
    (inputData, d) => (dispatch, getState) => {
        dispatch(setLoader(true));
        return ExploricApi.getListUserPackages({owner: {eq: inputData}})
            .then((response) => {
                dispatch(setLoader(false));
                dispatch(
                    setUserPackages(response.data.listUserPackages.items)
                );
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.log("error", error);
            });
    };

export const listAccountPackagesData =
    (inputData, d) => (dispatch, getState) => {
        dispatch(setLoader(true));
        return ExploricApi.getListAccountPackages({account: {eq: inputData}})
            .then((response) => {
                dispatch(setLoader(false));
                dispatch(
                    setUserPackages(response?.data?.listAccountPackages?.items)
                );
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.log("error", error);
            });
    };

export const createPackageData = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));

    try {
        var createPackageResponse = await ExploricApi.createPropertiesPackageApi({
            name: inputData.name,
        });
    } catch (error) {
        console.log(error);
    }

    try {
        dispatch(createProperty(inputData, createPackageResponse?.data.createPropertiesPackage?.id))
    } catch (e) {
        console.log(e);
    }

};

export const updatePackageData = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));

    try {
        var updatePackageResponse = await ExploricApi.updatePropertiesPackageApi({
            id: inputData?.id,
            name: inputData.name,
        });
    } catch (error) {
        console.log(error);
    }

    inputData.existingProperties.map(async (val, index) => {
        try {
            await ExploricApi.deletePropertyApi({
                id: val?.id,
            });
        } catch (error) {
            console.log(error);
        }
        if (inputData.existingProperties.length === index + 1) {
            dispatch(createProperty(inputData, updatePackageResponse?.data.updatePropertiesPackage?.id))
        }
    });
};

export const createProperty = (inputData, updatePackageResponse) => async (dispatch, getState) => {

    inputData.properties.map(async (val, index) => {

        var typeValue = "STRING"
        if (typeof val[1] === "boolean")
            typeValue = "BOOLEAN"
        else if (typeof val[1] === "number")
            typeValue = "INTEGER"


        try {
            await ExploricApi.createPropertyApi({
                key: val?.name,
                propertiesPackageID:
                updatePackageResponse,
                type: typeValue,
                value: val?.value,
            });
        } catch (error) {
            console.log(error);
        }

        if (inputData.properties.length === index + 1) {
            dispatch(listPropertyPackageData());
            dispatch(setLoader(false));
            dispatch(setServerError("successPackageEdit"));
        }
    });
}

export const updatePackagePropertyValue = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));

    try {
        await ExploricApi.updatePropertyApi({
            id: inputData.id,
            value: inputData.property_value,
        });
        dispatch(setLoader(false));
        dispatch(setServerError("success"));
    } catch (error) {
        console.log(error);
        dispatch(setLoader(false));
        dispatch(setServerError("failed"));
    }
};

export const assignPackagesAccount = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    try {
        var createAccountPackageResponse =
            await ExploricApi.createAccountPackageApi({
                account: inputData.account,
            });
        dispatch(setLoader(false));
    } catch (error) {
        console.log(error);
        dispatch(setLoader(false));
    }

    inputData.packages.map(async (val, index) => {
        try {
            await ExploricApi.CreateAccountPackagesPropertiesPackageConnectionApi({
                accountPackagesPropertiesPackageConnectionAccountpackagesId:
                createAccountPackageResponse.data.createAccountPackage.id,
                accountPackagesPropertiesPackageConnectionPropertiespackagesId:
                val,
                sortOrder: index + 1
            });

            if (inputData.packages.length === index + 1) {
                dispatch(setLoader(false));
                message.success("Package assigned successfully");
                dispatch(setServerError("successAssign"));
            }
        } catch (error) {
            console.log(error);
            dispatch(setServerError("failedAssign"));
        }
    })
};

export const assignPackagesUser = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));

    try {
        var createUserPackageResponse =
            await ExploricApi.createUserPackageApi({
                owner: inputData.owner,
            });
        dispatch(setLoader(false));
    } catch (error) {
        console.log(error);
        dispatch(setLoader(false));
    }

    inputData.packages.map(async (val, index) => {
        try {
            await ExploricApi.CreateUserPackagesPropertiesPackageConnectionApi({
                userPackagesPropertiesPackageConnectionUserpackagesId:
                createUserPackageResponse.data.createUserPackage.id,
                userPackagesPropertiesPackageConnectionPropertiespackagesId:
                val,
                sortOrder: index + 1
            });

            if (inputData.packages.length === index + 1) {
                dispatch(setLoader(false));
                message.success("Package assigned successfully");
                dispatch(setServerError("successAssign"));
            }
        } catch (error) {
            console.log(error);
            dispatch(setServerError("failedAssign"));
        }
    })
};
export const editAssignPackagesUser = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    try {
        await ExploricApi.deleteUserPackageApi({
            id: inputData?.existing?.id,
        });
        if (inputData?.existing?.packages?.items?.length === 0)
            dispatch(setLoader(false));
    } catch (error) {
        console.log(error);
        dispatch(setLoader(false));
    }

    inputData?.existing?.packages?.items?.map(async (val, index) => {
        try {
            await ExploricApi.deleteUserPackagesPropertiesPackageConnectionApi({
                id: val?.propertiespackages?.id,
            });

            if (inputData?.existing?.packages?.items?.length === index + 1) {
                dispatch(assignPackagesUser(inputData));
            }

        } catch (error) {
            console.log(error);
            dispatch(setLoader(false));
        }
    })
};

export const editAssignPackagesAccount = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    try {
        await ExploricApi.deleteAccountPackageApi({
            id: inputData?.existing?.id,
        });
        if (inputData?.existing?.packages?.items?.length === 0)
            dispatch(setLoader(false));
    } catch (error) {
        console.log(error);

        dispatch(setLoader(false));
    }

    inputData?.existing?.packages?.items?.map(async (val, index) => {
        try {
            await ExploricApi.deleteAccountPackagesPropertiesPackageConnectionApi({
                id: val?.propertiespackages?.id,
            });

            if (inputData?.existing?.packages?.items?.length === index + 1) {
                dispatch(assignPackagesAccount(inputData));
            }

        } catch (error) {
            console.log(error);
            dispatch(setLoader(false));
        }
    })
};

export const updatePackagesSortOrder = (inputData) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    inputData?.map(async (val, index) => {
        try {
            await ExploricApi.UpdateAccountPackagesPropertiesPackageConnectionApi({
                id: val?.id,
                sortOrder: index + 1,
            });

            if (inputData?.length === index + 1) {
                dispatch(setLoader(false));
                message.success("Successfull")
            }

        } catch (error) {
            console.log(error);
            dispatch(setLoader(false));
        }
    })
};


export const getExecutiveReportData = (body)=> ()=> {
return API.post("backendRest", "/mapping/executiveReporting", {body})
}

export const getConnectedCRMs = () => async (dispatch, getState) => {
    const account = getState().loginReducer.groups;

    let list  = []
    let nextToken = null;
    do {
        let res = await ExploricApi.getConnectedCRMsApi({ account: {eq: account[0]}}, nextToken)
        list.push(...res?.data?.listWebProfiless?.items)
        nextToken = res?.data?.listWebProfiless?.nextToken
    } while (nextToken);

    let dataObj = {}
    list.forEach(item => {
        dataObj[item?.platform] = dataObj[item?.platform] ? [...dataObj[item?.platform], item] : [item]
    })
    dispatch(setConnectedCRMs({...dataObj}));
    return dataObj
}

export const getGoogleAnalyticsDashboardApi = (account) => {
    return API.get("exploricsREST", `/getEmbedUrl/getGoogleAnalyticsDashboard?account=${account[0]}`)
}

export const getSalesAndMarketingDashboardApi = (account) => {
    return API.get("exploricsREST", `/getEmbedUrl/getGoogleAnalyticsDashboard?account=${account[0]}`)
}

export const getQuicksightUrl = (type, account) => {

    let url = ""
    switch (type) {
        case 'executive':
            url = "/getEmbedUrl/executive"
            break;
    
        case 'sales-and-marketing':
            url = "/getEmbedUrl/salesandmarketing"
            break;
    
        case 'google-analytics':
            url = "/getEmbedUrl/googleanalytics"
            break;
            
        default:
            break;
    }
    if(url)
    return API.get("exploricsREST", `${url}?account=${account[0]}`)
}