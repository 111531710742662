/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Drawer , Form, Row, Select, Typography, Table, message, Dropdown, Menu, Popconfirm} from 'antd'
import React, { useState, useEffect } from 'react'
import userIcon from '../../assets/Images/userIcon.svg'
import sdr_tracker_preview from "assets/Images/sdr_tracker_preview.png"
import PreviewInfo from 'commons/PreviewInfo';
import {  useSelector, useDispatch } from 'react-redux'
import { listSDRCompensationModels, createActivityTrackerSDRProfileData, listSdrMeetingAndOpportunityModelsData, getSDRCalculatorData, updateActivityTrackerSDRProfileData, getHubspotOwnerList, getSDRTrackerActuals, setSDRActuals, deleteSDRRecordData } from 'store/actions/sdrAction'
import {numberWithCommas} from "utility/context/CommonMethods"
import settingsImg from "assets/Images/table_settings.svg";
import SdrMeetingAndOpportunityTrackerSkeleton from './SdrMeetingAndOpportunityTrackerSkeleton';
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

const {Option} = Select;

export default function SdrMeetingAndOpportunityTracker(props) {
    const {skeletonFlag, showSkeleton } = props

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [addNewSDR , setAddNewSDR] = useState()
    const { groups: account, userPermission, viewType } = useSelector((state) => state.loginReducer);
    const {SDRCompensationModels, SDRActivityTrackerList, SDRCalculatorValues, SDRCalculatorSummary, SDRActuals, isLoading} = useSelector(state => state.sdrReducer);
    const {ConnectedCRMs} = useSelector(state => state.reportReducer)
    const showDrawer = () => {
        setAddNewSDR(true)
    };
    
    const onDrawerClose = () => {
        setAddNewSDR(false)
        setMetricToEdit({})
        form.resetFields()
    };

    useEffect(() => {
      skeletonFlag(true)
      // dispatch(getSDRCalculatorData())
      // dispatch(listSdrMeetingAndOpportunityModelsData())
      // dispatch(listSDRCompensationModels())

      Promise.all([dispatch(getSDRCalculatorData()), dispatch(listSdrMeetingAndOpportunityModelsData()), dispatch(listSDRCompensationModels())]).then((values) => {
        skeletonFlag(false)
      });


    },[])

  const [HubSpotOwners, setHubSpotOwners] = useState({})

  const [hubspotList] = useState(ConnectedCRMs?.['Hubspot']);
  

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
          account: account[0],
        };
        
        handleOk(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleOk = (value) => {
    if(isEdit){
      value.id = MetricToEdit?.id
    }
    let action = isEdit ? updateActivityTrackerSDRProfileData(value) :  createActivityTrackerSDRProfileData(value)
    dispatch(action).then(res => {
      dispatch(listSdrMeetingAndOpportunityModelsData())
      onDrawerClose()
    }).catch(() => {
      onDrawerClose()
    }
    )  
    
  };


  const [trackerTableData, setTrackerTableData] = useState([])
  useEffect(() => {

    let tableData = []
    SDRActivityTrackerList.forEach(element => {
      let sdrName = HubSpotOwners[element?.hubOwnerId]?.firstname + " "  + HubSpotOwners[element?.hubOwnerId]?.lastname + " "+(HubSpotOwners[element?.hubOwnerId]?.firstname && HubSpotOwners[element?.hubOwnerId]?.lastname ? `(${HubSpotOwners[element?.hubOwnerId]?.email})` : HubSpotOwners[element?.hubOwnerId]?.email) ;
      let compasationModelSelected = SDRCompensationModels?.find((item) => item?.id === element?.compensationModelID);
      let selectedModelStages = compasationModelSelected?.SDRCompensationModelStageMetric ?  JSON.parse(compasationModelSelected?.SDRCompensationModelStageMetric) : {}
      let data = []
      let totalCompensation = 0
      Object.keys(SDRCalculatorSummary).forEach((key,index) => {
        if(selectedModelStages?.[key] && key !== "revenue") {
          let actulValue = SDRActuals?.[element?.hubOwnerId] ? SDRActuals?.[element?.hubOwnerId]?.[key]?.Total : 0
          let  dataObj = {
            key:element.id + index + " ",
            sdrName:sdrName,
            baseCompensation: compasationModelSelected?.base_salary,
            targetVar: compasationModelSelected?.OTE - compasationModelSelected?.base_salary,
            stageMetric: key,
            stageMetricRate: selectedModelStages?.[key]?.StageMetricRate,
            stageMetricActuals: actulValue,
            compensationActual: Number(parseFloat(selectedModelStages?.[key]?.StageMetricRate*actulValue).toFixed(2)),
            hubOwnerId:element.hubOwnerId,
            id:element.id,
            compensationModelID:element?.compensationModelID,
            addRowSpan: data?.length === 0
        }
        totalCompensation += Number(parseFloat(selectedModelStages?.[key]?.StageMetricRate*actulValue).toFixed(2))

        data.push(dataObj)
        }
   
      })
      data.forEach(item => {
        item.rowSpan = data?.length
        item.totalVar = Number(totalCompensation).toFixed(2)
        item.totalOte = Number(Number(item.baseCompensation + totalCompensation).toFixed(2))
      })
      tableData = [...tableData, ...data]
    });
    // if(tableData?.length > 0)
    setTrackerTableData(tableData)
    recursiveRemoveKey(HubSpotOwners, 'isTracked');
    tableData.forEach(item => {
      if(HubSpotOwners[item.hubOwnerId])
        HubSpotOwners[item.hubOwnerId].isTracked = true
    })
  }, [SDRActivityTrackerList, SDRCompensationModels, HubSpotOwners, SDRCalculatorValues, SDRActuals, SDRCalculatorSummary])

  const recursiveRemoveKey = (object, deleteKey) => {
    delete object[deleteKey];
    
    Object.values(object).forEach((val) => { 
      if (typeof val !== 'object') return;
      
      recursiveRemoveKey(val, deleteKey);
    })
  }

    const tableColumns = [
        {
          title: <h4 style={{ textAlign: "left", marginBottom: 0, background: "#fff", color: "#fff", userSelect: "none" }}><strong>.</strong></h4>,
          className:"sdr-table-head",
          children: [
            {
              title: <Typography.Text className="text_label" style={{ textAlign: "left", marginBottom: 0,fontSize: 14 }}><strong>SDR name</strong></Typography.Text>,
              dataIndex: "sdrName",
              key: "sdrName",
              width: 170,
              render: (data, record) => (
                <Row justify="space-between" style={{alignItems: 'start',display:'flex', width:"100%"}}>
                  <Col span={20}>
                  <Typography.Text className="text_label data" style={{color:'#0F61DB'}} >{data}</Typography.Text> 
                  </Col>
                  <Col span={2} style={{display:"flex", justifyContent:"center", alignItems:"flex-start"}}>
                  <Dropdown
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                            <Menu.Item onClick={() => setMetricToEdit(record)}>
                                                Edit
                                            </Menu.Item>
                                            <Popconfirm
                                                  title="Are you sure you want to delete this SDR record?"
                                                  onConfirm={() =>
                                                    onSDRDelete(record)
                                                  }
                                                  // onCancel={cancel}
                                                  okText="Yes"
                                                  cancelText="No"
                                                  okButtonProps={{ size: "middle", type: "primary" }}
                                                  cancelButtonProps={{
                                                    size: "large",
                                                    type: "secondary",
                                                  }}
                                                  placement="topRight"
                                                >
                                                  <Menu.Item
                                                  >
                                                Delete
                                            </Menu.Item>
                                          </Popconfirm>
                                          
                                    </Menu>
                                }
                            >
                                <img src={settingsImg} className="cursor-pointer" alt=""/> 
                            </Dropdown>
                            </Col>
                </Row>
              ),
              onCell: (item) => ({ rowSpan: item?.addRowSpan ? item?.rowSpan : 0 })
            },
          ], 
        
        },
        {
          title: <h4 style={{ textAlign: "left", marginBottom: 0, background: "#fff", color: "#fff", padding: 0, userSelect: "none" }}><strong>.</strong></h4>,
          className:"sdr-table-head",
          children: [
            {
              title: <Typography.Text className="text_label"  style={{ textAlign: "left", marginBottom: 0 ,fontSize: 14}}><strong>Base Compensation</strong></Typography.Text>,
              dataIndex: "baseCompensation",
              key: "baseCompensation",
              className:'background',
              onCell: (item) => ({ rowSpan: item?.addRowSpan ? item?.rowSpan : 0 }),
              render: (data) => <Typography.Text className="text_label data" style={{ textAlign: "left" }}>${numberWithCommas(data)}</Typography.Text>,
            }
          ], 
        },
        {
            title: <h4 style={{ textAlign: "left", marginBottom: 0, background: "#fff", color: "#fff", padding: 0, userSelect: "none" }}><strong>.</strong></h4>,
            className:'sdr-table-head',
            children: [
            {
              title: <Typography.Text className="text_label"  style={{textAlign: 'left',marginBottom:0,fontSize: 14}}><strong>Target Variable Compensation</strong></Typography.Text>,
              dataIndex: 'targetVar',
              key: 'targetVar',
              onCell: (item) => ({ rowSpan: item?.addRowSpan ? item?.rowSpan : 0 }),
              render: (data) => <Typography.Text  className="text_label data" style={{textAlign:"left",color:'#00C572'}}>${numberWithCommas(data)}</Typography.Text>,
            }
          ]
        },
    
        {
          title : <Typography.Text className="text_label"  style={{marginBottom:0}}><strong>Variable compensation breakdown</strong></Typography.Text>,
          children: [
            {
              title: <Typography.Text  className="text_label" style={{marginBottom:0,fontSize: 14,  textAlign:"left"}}><strong>Stage Metric</strong></Typography.Text>,
              dataIndex: 'stageMetric',
              key: 'stageMetric',
              render: (data) => <Typography.Text className="text_label data"  style={{textAlign:"left"}}>{data}</Typography.Text>,
            },
            {
              title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left",fontSize: 14}}><strong>Stage Metric Rate</strong></Typography.Text>,
              dataIndex: 'stageMetricRate',
              key: 'stageMetricRate',
              render: (data) => <Typography.Text className="text_label data"  style={{textAlign:"left"}}>${numberWithCommas(data)}</Typography.Text>,
            },
            {
              title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left",fontSize: 14}}><strong>Stage Metric Actuals</strong></Typography.Text>,
              dataIndex: 'stageMetricActuals',
              key: 'stageMetricActuals',
              className:'background',
              render: (data) => <Typography.Text className="text_label data"  style={{textAlign:"left",color:'#0F61DB'}}>{data}</Typography.Text>,
            },
            {
              title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left",fontSize: 14}}><strong>Variable Compensation Actual</strong></Typography.Text>,
              dataIndex: 'compensationActual',
              key: 'compensationActual',
              render: (data) => <Typography.Text className="text_label data"  style={{textAlign:"left"}}>${numberWithCommas(data)}</Typography.Text>,
            },
          ]
        },
        
        {
            title: <h4 style={{ textAlign: "left", marginBottom: 0, background: "#fff", color: "#fff", padding: 0, userSelect: "none" }}><strong>.</strong></h4>,
            className:"sdr-table-head",
            children: [
              {
                title: <Typography.Text  className="text_label" style={{marginBottom:0,  textAlign:"left",fontSize: 14}}><strong>Total Var. Compensation</strong></Typography.Text>,
                dataIndex: 'totalVar',
                key: 'totalVar',
                onCell: (item) => ({ rowSpan: item?.addRowSpan ? item?.rowSpan : 0 }),
                render: (data) => <Typography.Text className="text_label data"  style={{textAlign:"left",color:'#18BB59'}}>${numberWithCommas(isNaN(data) ? 0 : data)}</Typography.Text>,
              },
              {
                title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left",fontSize: 14}}><strong>Total OTE</strong></Typography.Text>,
                dataIndex: 'totalOte',
                key: 'totalOte',
                onCell: (item) => ({ rowSpan: item?.addRowSpan ? item?.rowSpan : 0 }),
                render: (data) => <Typography.Text className="text_label data"  style={{textAlign:"left"}}>${numberWithCommas(data)}</Typography.Text>,
              },
            ]
        },
    ];
     
      const [MetricToEdit, setMetricToEdit] = useState({})
      const [isEdit, setisEdit] = useState(false)
      useEffect(() => {
        setisEdit(Object.keys(MetricToEdit)?.length  > 0)
        if(Object.keys(MetricToEdit)?.length  > 0){
          form.setFieldsValue({hubOwnerId:MetricToEdit?.hubOwnerId,compensationModelID:MetricToEdit?.compensationModelID })
        }
      },[MetricToEdit])

      const [trackerTableDataSummary, settrackerTableDataSummary] = useState({})
      useEffect(() =>{
        let dataObj = {
          baseCompensation : 0,
          targetVar:0,
          stageMetricRate:0,
          stageMetricActuals:0,
          compensationActual:0,
          totalVar:0,
          totalOte:0
        }
        trackerTableData.forEach(item => {
          dataObj = {
            baseCompensation : Number(parseFloat(dataObj?.baseCompensation + (item?.addRowSpan ? item?.baseCompensation : 0 )).toFixed(2)),
            targetVar:Number( parseFloat(dataObj?.targetVar + (item?.addRowSpan ? item?.targetVar : 0 )).toFixed(2)),
            stageMetricRate:Number(parseFloat(dataObj?.stageMetricRate + item?.stageMetricRate).toFixed(2)),
            stageMetricActuals:Number(parseFloat(dataObj?.stageMetricActuals + item?.stageMetricActuals).toFixed(2)),
            compensationActual:Number(parseFloat(dataObj?.compensationActual +item?.compensationActual).toFixed(2)),
            totalVar:Number(parseFloat(dataObj?.totalVar + (item?.addRowSpan ? item?.totalVar : 0)).toFixed(2)),
            totalOte:Number(parseFloat(dataObj?.totalOte + (item?.addRowSpan ? item?.totalOte : 0)).toFixed(2)),
          }
        })
        settrackerTableDataSummary(dataObj)
      },[trackerTableData])
   

      useEffect(() => {
        let profileID = hubspotList?.map(item => item?.profile_id)
        if(profileID?.length > 0)
        dispatch(getHubspotOwnerList(profileID)).then(res=> {
          let dataObj = {}
          res?.forEach(item => {
            dataObj[item?.owner_id] = item
          })
          setHubSpotOwners(dataObj)

        }).catch(err => {
          console.log(err);
        })
      },[hubspotList])

    const [ActualApiCalled, setActualApiCalled] = useState(false)
  useEffect(() => {
    if(SDRActivityTrackerList?.length > 0 && SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID){
      let hubspotOwnersList = SDRActivityTrackerList.map(item => item?.hubOwnerId)
      // !ActualApiCalled && dispatch(getSDRTrackerActuals(hubspotOwnersList, SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID)).then(res => {
      //   dispatch(setSDRActuals(res))
      // setActualApiCalled(false)
      // }).catch(err => {
      //   message.error("Failed to fetch actuals data.")
      //   console.log(err);
      // })
      if(ActualApiCalled) {
        if(trackerTableData?.length === 0)
          skeletonFlag(true)
        dispatch(getSDRTrackerActuals(hubspotOwnersList, SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID)).then(res => {
          dispatch(setSDRActuals(res))
          skeletonFlag(false)
        setActualApiCalled(false)
        }).catch(err => {
          message.error("Failed to fetch actuals data.")
          console.log(err);
          skeletonFlag(false)
        })
      }


      setActualApiCalled(true)
    }
  
  },[SDRActivityTrackerList])

  const onSDRDelete = (value) => {
    return dispatch(deleteSDRRecordData({id:value?.id})).then(res => {
      dispatch(listSdrMeetingAndOpportunityModelsData());
    }).catch(() => {
    })
    };


  return (
    
     showSkeleton ? <>
     <SdrMeetingAndOpportunityTrackerSkeleton />
     </>:
    <>
    {trackerTableData?.length !== 0 ?
        <Row
            className="sdrCompensation"
            justify="center"
            style={{ padding: "10px 10px 0 10px" }}
        >
            <Col span={5} lg={5} md={5} sm={24} xs={24} className='text_label' style={{color:"#00AEC5", fontWeight:"bold"}}>
                      Fiscal Year:  {SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.fiscalYearID}
            </Col>
            <Col span={23} lg={23} md={23} sm={24} xs={24}>
                <Table
                    className="sdr-table"
                    pagination={false}
                    size="small"
                    columns={tableColumns}
                    dataSource={trackerTableData}
                    bordered
                    rowKey="key"
                    summary={() => {
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>Total</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>${numberWithCommas(trackerTableDataSummary?.baseCompensation)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>${numberWithCommas(trackerTableDataSummary?.targetVar)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>--</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>${numberWithCommas(trackerTableDataSummary?.stageMetricRate)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>{numberWithCommas(trackerTableDataSummary?.stageMetricActuals)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>${numberWithCommas(trackerTableDataSummary?.compensationActual)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>${numberWithCommas(trackerTableDataSummary?.totalVar)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell className="footer">
                              <Typography.Text className="text_label"><strong>${numberWithCommas(trackerTableDataSummary?.totalOte)}</strong></Typography.Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      )
                    }}
                />
                <Button type="primary" onClick={showDrawer} style={{margin: '20px 0'}}><strong>Add new SDR</strong></Button>
            </Col>
        </Row>
        :

        <div style={{height: '55vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}> 
            <img src={userIcon} alt='' style={{marginBottom: 10}} />
            <h3><strong>Get started</strong></h3>
            <p>
            Create new SDR to track actual & total <br/>
            compensation per each stage metric rates 
            </p>

            <Button type="primary" onClick={() => {
              TrackClickFunction('demand-gen-sales-and-marketing-add-sdr-button');
              showDrawer()
              
            }}className="primaryButton" data-heap="demand-gen-sales-and-marketing-add-sdr-button"><strong>Add new SDR</strong></Button>
        </div>
    }
        

        <Drawer
            title={
              <strong>
                {isEdit ? "Edit" : 'Create'} SDR
              </strong>
            }
            width={370}
            onClose={onDrawerClose}
            visible={addNewSDR || isEdit}
            closable={true}
            footer={[]}
            style={{borderRadius: "20px"}}
            className="Create-mapping-stage"
          >
                <Form layout="vertical" form={form}
                  requiredMark={false}
                  name="sdr_tracker">
                    <Form.Item 
                        label={<span><strong>Hubspot owner id</strong></span>}
                        name = "hubOwnerId"
                        rules={[
                          {
                            required: true,
                            message: "This field can not be empty!",
                          }
                        ]}
                    >
                        <Select placeholder="Select Hubspot owner Id" style={{ width: '100%' }} className="hubspot-select general-input-select" >
                          {
                            Object.keys(HubSpotOwners)?.map((key,index) =>  <Option disabled={!isEdit ? HubSpotOwners[key]?.isTracked : !(!HubSpotOwners[key]?.isTracked  || key === MetricToEdit?.hubOwnerId)} key={index} value={key}>{HubSpotOwners[key]?.firstname + " "  + HubSpotOwners[key]?.lastname + " "+(HubSpotOwners[key]?.firstname && HubSpotOwners[key]?.lastname ? `(${HubSpotOwners[key]?.email})` : HubSpotOwners[key]?.email)  } </Option>)
                          }
                        </Select>
                    </Form.Item>

                    <Form.Item 
                        label={<span><strong>Compensation model</strong></span>}
                        rules={[
                          {
                            required: true,
                            message: "This field can not be empty!",
                          }
                        ]}
                        name="compensationModelID"
                    >
                      <Select placeholder="Select Compensation model" style={{ width: '100%' }} className="compensetion-select general-input-select">
                                {
                                  SDRCompensationModels?.map((item,index) =>  <Select.Option disabled={!item?.OTE} key={index} value={item?.id}>{item?.name}</Select.Option>)
                                }
                      </Select>
                    </Form.Item>
               

                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    zIndex: 2,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right",
                    display: "flex",
                  }}
                >
                  <Button
                    type="primary"
                    size="small"
                    ghost
                    onClick={onDrawerClose}
                    style={{
                      borderColor: "#EC4A0A",
                      backgroundColor:'#EC4A0A',
                      color: "#fff",
                      fontSize: 18,
                      marginRight: 8,
                      width: "48%",
                    }}
                    className="secondaryButton"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    style={{fontSize: 18, width: "48%"}}
                    // htmlType="submit"
                    onClick={onSubmit}
                    loading = {isLoading}
                    className="primaryButton"
                  >
                    Save
                  </Button>
                </div>
                </Form>
          </Drawer>
    </>
  )
}
