import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Upload,
  Space,
  Typography,
  Tooltip
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asset_type, target_market_segments } from "utility/Constants";
import {
  createAssetLibrary,
  updateAssetLibrary,
} from "store/actions/goToMarketAction";
import moment from "moment";
import { API, Storage, Auth } from "aws-amplify";
import { LoadingOutlined, PictureOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { swapKeyValue } from "utility/context/CommonMethods";
import { AmplifyS3Image } from "@aws-amplify/ui-react";

const { Dragger } = Upload;
const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];

function CreateAsset(props) {
  const { isEdit, account, AssetToEdit, TargetIdealCustomProfile, Persona } =
    props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedAssetType, setselectedAssetType] = useState();
  const [thumbnailKey, setThumbnailKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentUserInfo, setCurrentUserInfo] = useState("");
  const isLoadingAPI = useSelector(
    (state) => state.goToMarketReducer.isLoading
  );

  useEffect(() => {
    if (AssetToEdit) {
      setselectedAssetType(AssetToEdit.type);
      if (AssetToEdit?.metadata) {
        AssetToEdit.description = JSON.parse(
          AssetToEdit?.metadata
        )?.description;
      }
    }
  }, [AssetToEdit]);

  const handleFormSubmit = (value) => {
    value.created_date = moment(value.created_date, "MMM").format("YYYY-MM-DD");
    let extras = {
      Img_Url: metaData?.image || AssetToEdit?.Img_Url,
      thumbnailKey: thumbnailKey || AssetToEdit?.thumbnailKey,
      description: metaData?.description || AssetToEdit?.Desc,
    };
    let inputData = {
      account: account[0],
      ...value,
      metadata: JSON.stringify(extras),
    };

    if (isEdit) {
      inputData.id = AssetToEdit?.id;
      if (inputData.id) {
        dispatch(updateAssetLibrary(inputData)).then(() => {
          form.resetFields();
          props.onClose();
        });
      } else {
        message.error("Something Went Wrong");
        form.resetFields();
        props.onClose();
      }
    } else {
      dispatch(createAssetLibrary(inputData)).then(() => {
        form.resetFields();
        props.onClose();
      });
    }
  };

  const [isLoading, setisLoading] = useState(false);

  const [metaData, setmetaData] = useState({});
  const getMeta = (e) => {
    let url = e.target.value;
    if (url?.length > 0) {
      setisLoading(true);
      API.get("exploricsREST", `/getOpenGraphData?url=${url}`)
        .then((data) => {
          setmetaData(data);
          if (data.title) {
            form.setFieldsValue({ name: data?.title });
          }
          setisLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setisLoading(false);
        });
    }
  };

  const MarketSemgnetsKeyValue = useMemo(
    () => swapKeyValue(target_market_segments),
    []
  );

  const SavedPersona = useMemo(
    () => Persona?.[0]?.personas?.map((item) => item.name).filter((i) => i),
    [Persona]
  );

  //Thumbnail Upload

  const beforeUpload = (file) => {
    const isValidFileSize = file.size / 1024 / 1024 <= 2;

    if (!validImageTypes.includes(file.type)) {
      message.error("Supported File Types: JPG,PNG");
      return false;
    }
    if (!isValidFileSize) {
      message.error(`File Size must be smaller than 2MB!`);
      return false;
    }

    storeFile(file);

    return true;
  };

  const getCurrentUserInfo = async () => {
    const currentUserVal = await Auth.currentUserInfo();
    setCurrentUserInfo(currentUserVal?.id);
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  const storeFile = async (file) => {
    if (thumbnailKey) {
      await Storage.remove(thumbnailKey.split("/")[1], { level: "protected" });
    }
    setLoading(true);
    try {
      const uploadFileKey = await Storage.put(file.name, file, {
        level: "protected",
        contentType: file.type,
      });
      setThumbnailKey((pre) => `${currentUserInfo}/${uploadFileKey.key}`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("file upload error: ", e);
    }
  };

  return (
    <Drawer
      title={
        <>
          {isEdit ? "Edit" : "Create"} Asset {isLoading && <LoadingOutlined />}{" "}
        </>
      }
      width={416}
      onClose={props.onClose}
      visible={true}
      className="createAssetModal"
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        name="basic"
        className="editAssetDrawer"
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        form={form}
        initialValues={{
          name: AssetToEdit?.name,
          type: AssetToEdit?.type,
          hosted_url: AssetToEdit?.hosted_url,
          creative_files_url: AssetToEdit?.creative_files_url,
          abstract: AssetToEdit?.abstract,
          created_date: AssetToEdit?.created_date
            ? moment(AssetToEdit?.created_date, "YYYY-MM-DD")
            : moment(),
          market_segments: AssetToEdit?.market_segments
            ? AssetToEdit?.market_segments
            : undefined,
          industries: AssetToEdit?.industries
            ? AssetToEdit?.industries
            : undefined,
          regions: AssetToEdit?.regions ? AssetToEdit?.regions : undefined,
          personas: AssetToEdit?.personas ? AssetToEdit?.personas : undefined,
        }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          name="name"
          label={<strong>Asset Name</strong>}
          rules={[
            {
              required: true,
              message: "Please enter asset name!",
            },
          ]}
        >
          <Input
            style={{ color: "black" }}
            size="large"
            placeholder="Asset Name"
            className="general-input"
          />
        </Form.Item>
        <Form.Item
          name="type"
          label={<strong>Asset Type</strong>}
          rules={[
            {
              required: true,
              message: "Please select Asset type!",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Asset Type"
            value={selectedAssetType}
            onChange={setselectedAssetType}
            className="general-input-select"
            size="large"
          >
            {Object.keys(asset_type).map((key) => {
              return (
                <Select.Option value={asset_type[key]}>{key}</Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="hosted_url"
          label={<strong>Link to Hosted Content</strong>}
          rules={[
            {
              required: true,
              message: "Please enter link!",
            },
            {
              type: "url",
              message: "Please enter valid url (including https://)",
            },
          ]}
        >
          <Input
            style={{ color: "black" }}
            size="large"
            placeholder="Paste URL here"
            onChange={getMeta}
            className="general-input"
          />
        </Form.Item>
        <Form.Item
          name="creative_files_url"
          label={<strong>Link to Creative Files</strong>}
          rules={[
            {
              type: "url",
              message: "Please enter valid url (including https://)",
            },
          ]}
        >
          <Input
            style={{ color: "black" }}
            size="large"
            placeholder="Paste URL here"
            className="general-input"
          />
        </Form.Item>
        <Form.Item name="abstract" label={<strong>Abstract</strong>}>
          <Input
            style={{ color: "black" }}
            size="large"
            placeholder="Enter Abstract"
            className="general-input"
          />
        </Form.Item>

        <Form.Item
          name="created_date"
          label={<strong>Created Date</strong>}
          rules={[
            {
              required: true,
              message: "Please select the date",
            },
          ]}
        >
          <DatePicker
            placeholder="Please select date"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="market_segments"
          label={<strong>Select Target Market Segment</strong>}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Target Market Segments"
            className="general-input-select"
          >
            {TargetIdealCustomProfile?.[0]?.market_segments?.map((item) => (
              <Select.Option value={item} key={item}>
                {MarketSemgnetsKeyValue[item]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="industries"
          label={<strong>Select Target Industries</strong>}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Target Industries"
            className="general-input-select"
          >
            {TargetIdealCustomProfile?.[0]?.industries.map((item) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="regions"
          label={<strong>Select Target Regions</strong>}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Target Regions"
            className="general-input-select"
          >
            {TargetIdealCustomProfile?.[0]?.regions.map((item) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="personas" label={<strong>Select Personas</strong>}>
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Select Personas"
            className="general-input-select"
          >
            {SavedPersona?.map((item) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

          <Form.Item label={
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',borderBottom: '1px solid #d9d9d9', paddingBottom: '10px',marginBottom: '10px'}}>
              <strong>Asset Thumbnail</strong>
              <Tooltip
                  title="Image size should be 300 X 300 pixels for optimal visual presentation"
                  placement="bottomRight"
                >
                  <InfoCircleOutlined  />
                </Tooltip>
            </div>
            }>
            <Dragger
              multiple={false}
              accept=".jpeg, .jpg, .png"
              style={{ maxWidth: "100%", height: "auto" }}
              beforeUpload={beforeUpload}
              showUploadList={false}
            >
              {loading ? (
                <Space direction="vertical">
                  <LoadingOutlined />
                </Space>
              ) : thumbnailKey ? (
                <AmplifyS3Image
                  className="img"
                  level="protected"
                  identityId={thumbnailKey.split("/")[0]}
                  style={{
                    "--height": "100%",
                    "--width": "100%",
                    "--alt": "image",
                  }}
                  path={thumbnailKey.split("/")[1]}
                />
              ) : (
                <Space direction="vertical">
                  <PictureOutlined
                    style={{ fontSize: "50px", color: "lightgrey" }}
                  />
                  <Typography.Text strong style={{ fontSize: "15px" }}>
                    Drop Your Thumbnail or{" "}
                    <span style={{ color: "#0f61db" }}>Browse</span>
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: "15px" }}>
                    Support JPG,PNG
                  </Typography.Text>
                </Space>
              )}
            </Dragger>
          </Form.Item>

        {/* <Form.Item
                name="created_date"
                label={<small><strong>Created Date</strong></small>}
                rules={
                    [
                        {
                            required:true,
                            message:"Please Select the date"
                        }
                    ]
                }
            >
                <DatePicker placeholder="Please select date" style={{width:'100%'}} />
            </Form.Item>
            
            <Form.Item
                name="created_date"
                label={<small><strong>Created Date</strong></small>}
                rules={
                    [
                        {
                            required:true,
                            message:"Please Select the date"
                        }
                    ]
                }
            >
                <DatePicker placeholder="Please select date" style={{width:'100%'}} />
            </Form.Item> */}

        {/* {
                TitleSubtitleType.includes(selectedAssetType) && <>
                
                <Form.Item
                name="description"
                label={<small>Description</small>}
                initialValue={AssetToEdit?.description}
                rules={
                    [
                        {
                            required:true,
                            message:"Please enter asset Description"
                        },
                    ]
                }
            >
                <Input.TextArea  autoSize={{minRows: 7, maxRows: 7}} style={{ color: 'black'}} size='large' placeholder='Enter Asset Description'></Input.TextArea>
            </Form.Item>

                
                </>
            } */}

        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <Button
            className="secondaryButton"
            onClick={props.onClose}
            style={{ fontSize: 18, marginRight: 8, width: "48%" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ fontSize: 18, width: "48%" }}
            htmlType="submit"
            loading={isLoadingAPI}
            className="primaryButton"
          >
            Save
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default CreateAsset;
