import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Typography,
  Row,
  Space,
  Form,
  Input,
  message,
} from "antd";
import sdk from "sdk/Accounts";
import RequestPending from "./RequestPending";
import Teamwork from "assets/Images/undraw_join_existing.svg";
import Modal from "antd/lib/modal/Modal";
import { CloseOutlined } from "@ant-design/icons";

const RequestAccess = ({ expand, select, reset, requestAccess, accountid }) => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (accountid) { 
      if (accountid.length === 36) {
        var patternId = new RegExp("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}");
        if (patternId.test(accountid)) {
          handleSubmit({ accountid: accountid })
          
        } 
      }
      else {
        message.error("Account id is not valid")
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accountid])

  const handleSubmit = async (e) => {
    setLoading(true);
    let response = await sdk.requestAccess(e.accountid);
    if (response.errors || response.error) {
      message.error(response.errors || response.error);
    } else {
      setPending(true);
      requestAccess();
      message.success("Your request has been submitted")
    }
    setLoading(false);
    reset();
  };

  return (
    <>
      {pending ? (
        <RequestPending accountid={accountid} />
      ) : (
        <Card style={{ border: "none" }}>
          <Row>
            <Col xs={24} md={24} style={{ maxWidth: 400 }}>
              <Typography.Title level={4} style={{ textAlign: "center", fontSize: "22px" }}>
                Join Existing Account
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                You can request access to an existing account if you know the
                account identification number.
              </Typography.Paragraph>

              <div style={{ width: "100%", margin: "20px 0px" }}>
                <img
                  src={Teamwork}
                  alt="request access"
                  style={{ width: "100%", maxHeight: "190px" }}
                />
              </div>

              <Button style={{fontSize: "18px", fontWeight: "500"}} type="primary" size="large" block onClick={select}>
                Request Access
              </Button>
            </Col>
          </Row>
        </Card>
      )}
      <Modal
        className="new-account-model"
        visible={expand}
        closable={true}
        footer={[]}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
            style={{ fontSize: 20, marginTop: 10 , color: "#ffffff", marginLeft: "60px", transform: "scale(1.5)"}}
            onClick={reset}
          />
          </strong>
        }
      >
        <Row justify="center">
          <Col span={20} lg={20} md={20} sm={24} xs={24}>
            <Typography.Title level={5} style={{ textAlign: "center" }}>
              &nbsp;
            </Typography.Title>

            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="request_access"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="accountid"
                  label={<strong>Customer ID</strong>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Account ID of your company",
                    },
                    {
                      pattern:
                        /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
                      message: "Please enter valid Account ID",
                    },
                  ]}
                >
                  <Input
                    defaultValue={accountid}
                    autoFocus
                    size="middle"
                    id="accountid"
                    key="accountid"
                    name="accountid"
                    placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    loading={loading}
                    tabIndex={2}
                  >
                    Get Started
                  </Button>
                </Form.Item>
              </Space>
            </Form>
            <center>
              <Typography.Text type="secondary">
                Employer approval is required*
              </Typography.Text>
            </center>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default RequestAccess;
