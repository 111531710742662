/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Steps from "./Steps";
import { API } from "aws-amplify";
import sdk from "sdk/Accounts";

// import Cards from "./Cards";
import "./dashboard.less";
import PopularCoaching from "./PopularCoaching";
import { useSelector, useDispatch } from "react-redux";
import { getBudgetInfo } from "store/actions/budgetAction";
import {
  getYearlyBaseline,
  getFiscalYearsList,
} from "store/actions/revenueAction";
import GoalToActualCharts from "./GoalToActualCharts";
import FunnelAnalisys from "./FunnelAnalysis";
import { Col, Row, Typography, DatePicker, Popconfirm, Button, Skeleton } from "antd";
import dashboardImg from "../../assets/Images/undraw_dashboard.svg";
import {
  getConfiguredChartData,
  configureChartDataInfo,
  // getAccountStageMappingList,
} from "store/actions/reportAction";
import LineChart from "./../reports/Analytics/LineChart";
import moment from "moment";

import deleteIcon from "assets/Images/ic_delete.svg";
import ConnectAndInvite from "./ConnectAndInvite";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

import { getaccountDetails, setDashboardTourOpen, UpdateTourData } from "store/actions/loginAction";
import Tour from "reactour";
import { useParams } from "react-router-dom";
import { listAccounts, listFiscalYears } from "graphql/queries";
import { updateAccount, updateFiscalYear } from "graphql/mutations";
import { filter } from "lodash";

// import BudgetAnalisys from "./BudgetAnalysis"; temporary commented need in future

const Dashboard = () => {

  const {content} = useParams(); 

  const {Title, Paragraph} = Typography

  const dispatch = useDispatch();
  const [marketingRevenueGoal, setmarketingRevenueGoal] = useState(0);
  const [budgetPlanned, setbudgetPlanned] = useState(0);
  const [leadsPlanned, setleadsPlanned] = useState(0);
  const [lastStageName, setlastStageName] = useState("");
  const { configuredChartInfo, isLoading } = useSelector(
    (state) => state.reportReducer
  );
  const { salesAndMarketingStages } = useSelector((state) => state.salesAndMarketingReducer);

  

  const { RangePicker } = DatePicker;

  const dateFormat = "YYYY-MM-DD";
  const [startDate, setStartDate] = useState(
    moment().subtract(12, "months").format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));

  const onDateRangeChange = (date, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  // useEffect(async () => {
  //   let data =  await sdk.fetchCompanies();
  //   let query = `
  //   query ListAccounts(
  //     $filter: ModelAccountFilterInput
  //     $nextToken: String
  //   ) {
  //     listAccounts(filter: $filter, limit: 999, nextToken: $nextToken) {
  //       items {
  //         id
  //         fiscalYearMonth
  //       }
  //     }
  //   }`
  //   let data =  await API.graphql({ query: query, variables: {limit:999} });
  //   const items = data?.data?.listAccounts?.items;
  //     for (let i = 0; i < items.length; i++) {
  //         const item = items[i];
  //         console.log("In 1212 ");
  //         API.graphql({
  //             query: updateAccount,
  //             variables: { input: { id: item['id'], fiscalYearMonth:1 } },
  //         });
  //         break
  //     }
  // }, [])

  useEffect(()=>{
    let lastStage = salesAndMarketingStages?.find(stage => stage?.internalStage === "Closed Won")
    setlastStageName(lastStage?.name ? lastStage?.name : "Closed Won" )
  },[salesAndMarketingStages])

  const { fiscalYearList} = useSelector((state) => state.revenueReducer);

  const { userPermission, viewType, accountDetails, groups:account, userData, token} = useSelector((state) => state.loginReducer);
  
  const { BudgetInfo } = useSelector((state) => state.budgetReducer);

  const [isEmpty, setIsEmpty] = useState();

  useEffect(() => {
    if (BudgetInfo?.items?.[0]?.year) dispatch(getYearlyBaseline());

    if (BudgetInfo?.items && BudgetInfo.items?.length > 0) {
      setIsEmpty(false);
      let totalBudgetPlanned = 0
      let totalLeadsPlanned = 0
      let campaigns = BudgetInfo.items[0].campaigns?.items;
      let campaign_months = []
      campaigns?.forEach(item=> {
          if(item?.campaign?.campaign_details?.items?.[0]?.campaign_months?.items){
            campaign_months = [...campaign_months, ...item?.campaign?.campaign_details?.items?.[0]?.campaign_months?.items]
          }
      })
      if(campaign_months?.length > 0 ){
        // eslint-disable-next-line array-callback-return
        campaign_months?.map((month)=>{
            totalBudgetPlanned+=month.budget
            totalLeadsPlanned+=month.leads
        })
      }


      setbudgetPlanned(parseInt(totalBudgetPlanned));
      setleadsPlanned(parseInt(totalLeadsPlanned))
    } else {
      setIsEmpty(true);
    }
  }, [BudgetInfo]); // eslint-disable-line

  useEffect(() => {
    dispatch(getFiscalYearsList());
    dispatch(
      getBudgetInfo({ active: { eq: true }, account: { eq: account[0] } })
    );
    // dispatch(getAccountStageMappingList())
    dispatch(
      getConfiguredChartData({
        account: { eq: account[0] },
        dashboard_screen: { eq: true },
      })
    );

    dispatch(getaccountDetails(account[0]))

  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    setQuarterlydata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYearList]);

  const setQuarterlydata = () => {
    let fiscalYear = fiscalYearList?.filter(
      (fiscalYear) =>
        fiscalYear?.year === new Date().getFullYear() &&
        account[0] === fiscalYear?.account
    )?.[0];
    let mrktingrevenue = 0;
    // eslint-disable-next-line array-callback-return
    fiscalYear?.baselineMetrics?.items?.map((baselineData) => {
      mrktingrevenue += parseInt(
        Number(
          getMktSrcCalculation(
            baselineData?.mrktSourcePercent,
            baselineData?.goal
          )
        ).toFixed(0)
      );
    });
    setmarketingRevenueGoal(mrktingrevenue);
  };
  const getMktSrcCalculation = (mrktsrc, value) => {
    return (value * mrktsrc) / 100;
  };

  const onRemoveChart = (configId) => {
    dispatch(
      configureChartDataInfo(
        { id: configId, dashboard_screen: false },
        "dashboard"
      )
    );
  };

  // const steps = [

  //   {
  //     selector: '[data-tour = "tour__home"]',
  //     content: () => (
  //       <div>
  //       <Title className="fuel-title" level={4}>
  //       Home
  //       </Title>
  //       <Paragraph>This is your home screen, showcasing important information and actions available to you within XFactor.io Demand Generation.</Paragraph>
  //       </div>
  //     ),
  //     action: (node) => {
  //       node?.focus()
  //       // node.scrollIntoView({ block: "start", behavior: "smooth"});
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__settings"]',
  //     content: () => (<div>
  //       <Title level={4}>
  //       Settings
  //       </Title>
  //       <Paragraph>This where you can view and modify Demand Generation settings for your User account and your Company account.</Paragraph>
  //     </div>),
  //     action: () => {
  //       let node  = document.getElementById("settings-menu-trigger-btn")
  //       // node?.click()
  //       node?.focus()
  //       // node.scrollIntoView({ block: "center", inline: "nearest" });
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__modules"]',
  //     content: () => (<div>
  //       <Title level={4}>
  //       Demand Generation Modules
  //       </Title>
  //       <Paragraph>Navigate through the various modules to explore all that Demand Generation has to offer!</Paragraph>
  //     </div>),
  //     action: (node) => {
  //       node?.focus()
  //       node?.scrollIntoView({ block: "nearest", inline: "nearest" });
  //     },
  //   },
  // ]

  // const [isTourOpen, setisTourOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [c, setc] = useState(1)

  const [accountMetadata, setaccountMetadata] = useState({});
  useEffect(() =>{
    let metadata = accountDetails?.metadata?JSON.parse(accountDetails?.metadata)?.[userData.sub]:""
    accountDetails?.metadata && setaccountMetadata(metadata)
  },[accountDetails, userData])

  // useEffect(() =>{
  //   if(account?.[2] !== "admin")
  //     setisTourOpen(!accountMetadata?.DashboardTourFinish)
  // },[accountMetadata])

  const updateTour = () => {
    dispatch(UpdateTourData(account, accountMetadata,"DashboardTourFinish", userData?.sub, JSON.parse(accountDetails?.metadata)));
  }

  // useEffect(() =>{
  //   dispatch(setDashboardTourOpen(isTourOpen))
  // },[isTourOpen])

  // const handleTourButtonClick = (customEvent) => { 
  //   TrackClickFunction(customEvent);
  //   setisTourOpen(true);
  // }

  return (
    <div>
      {/* {isLoading && <div className="loading">Loading;</div>} */}
       <Row
       className="aboutPage panel-design"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
          background: "#fff",
          margin: "0 0 20px 0px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
          padding:'30px 20px'
        }}
      >
        <Col
        className="details"
          style={{
            
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
          span={24}
          lg={24}
          md={24}
          xs={24}
          sm={24}
        >
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Typography.Text className="title fw700 headerMain" style={{ color:'#1D2939' }}>
              Demand Generation Home
            </Typography.Text>
            {/* <Typography.Text style={{ fontSize: 16 }}>
              This is where you get the latest updates on revenue planning, budget
              & campaigns and Demand Generation coaching sessions{" "}
            </Typography.Text> */}
            {/* <Button className="take-a-tour-btn non-print primaryButton" data-heap="demand-gen-take-a-tour-button-home" style={{height:'45px', marginTop:0}} onClick={() => handleTourButtonClick('demand-gen-take-a-tour-button')}>Take a tour</Button> */}
          </div>
        </Col>

        {/* <Col
          span={12}
          lg={12}
          md={12}
          xs={24}
          sm={24}
          style={{ paddingRight: 10, paddingTop: 10 }}>
          <Row justify="end">
            <img src={dashboardImg} alt="" />
          </Row>
        </Col> */}
      </Row>
        <div data-tour = "tour__home">
     <Steps
        BudgetInfo={BudgetInfo}
        marketingSourcedRevenue={marketingRevenueGoal}
        budgetPlanned={budgetPlanned}
        isEmpty={isEmpty}
        userPermission = {userPermission}
        account = {account}
        viewType = {viewType}
        leadsPlanned = {leadsPlanned}
        isContent={content?true:false}
      ></Steps>

      {marketingRevenueGoal !== 0 && (
        <GoalToActualCharts
          fiscalYearList={fiscalYearList}
          isLoading ={isLoading}
          BudgetInfo={BudgetInfo}
          isEmpty={isEmpty}
          lastStageName = {lastStageName}
        ></GoalToActualCharts>
      )}
      {<ConnectAndInvite userPermission = {userPermission} account = {account} viewType = {viewType} accountDetails ={accountDetails} isLoading={isLoading} isContent={content?true:false}/>}

        {((userPermission?.["dashboard.blogs"] && userPermission?.["dashboard.blogs"] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor"))) && <FunnelAnalisys
          isLoading={isLoading}
        ></FunnelAnalisys>}

      {/* {isEmpty && <Blogs loading={isLoading} />} */}
      {/* Commented For Launch and may used in future */}
      {/* {!isEmpty && <BudgetAnalisys></BudgetAnalisys>} */}
      {((userPermission?.["coaching"] && userPermission?.["coaching"] !== "hidden" && userPermission?.["coaching"] !== "preview") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor"))) && <PopularCoaching isLoading={isLoading} isContent={content?true:false}></PopularCoaching>}

      {configuredChartInfo?.items?.filter((val) => val.dashboard_screen === true)
        .length > 0 && ((userPermission?.["reporting.analytics"] && userPermission?.["reporting.analytics"] !== "hidden" && userPermission?.["reporting.analytics"] !== "preview") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor"))) &&
        account &&
        account[2] === "admin" && (
          <div style={{ background: "#fff", padding: 20, marginTop: 15 }}>


            <div style={{ padding: 10, paddingBottom: 30, borderBottom:"5px solid #E9F2FF" }}>
              <div
                style={{
                  display: "inline-block",
                  fontSize: 20,
                  paddingRight: 20,
                }}
                className = "coaching-title"
              >
                Google Analytics Charts
              </div>

              { isLoading ? <Skeleton active /> :
              <div style={{ display: "inline-block", float: "right" }}>
                <RangePicker
                  onChange={onDateRangeChange}
                  defaultValue={[
                    moment(startDate, dateFormat),
                    moment(endDate, dateFormat),
                  ]}
                />
              </div>
}
            </div>

            {configuredChartInfo.items.map((configuredChartInfoVal) => {
              return (
                configuredChartInfoVal.dashboard_screen && (
                  <div style = {{borderTop:"5px solid #E9F2FF", paddingBottom:25}}>
              { isLoading ? <Skeleton active /> :
                  <>
                    <div style={{ textAlign: "right", paddingTop:20 }}>
                      <div
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          display: "inline-block",
                          color:"#c4c4c4"
                        }}
                      >
                        Profile Type:{" "}
                        {JSON.parse(
                          configuredChartInfoVal.metadata
                        ).analytics_profile_type.toUpperCase()}
                      </div>
                      <div
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        // onClick={() => onRemoveChart(configuredChartInfoVal.id)}
                      >
                        <Popconfirm
                          title="Are you sure to remove this chart from home?"
                          onConfirm={() =>
                            onRemoveChart(configuredChartInfoVal.id)
                          }
                          // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                          okButtonProps={{ size: "middle", type: "primary" }}
                          cancelButtonProps={{
                            size: "large",
                            type: "secondary",
                          }}
                          placement="topRight"
                        >
                          <img
                            src={deleteIcon}
                            title="Remove from dashabord"
                            alt="remove"
                          />
                        </Popconfirm>
                      </div>
                    </div>
                    <LineChart
                      selectedProfile={
                        JSON.parse(configuredChartInfoVal.metadata).profile_id
                      }
                      startDate={startDate}
                      endDate={endDate}
                      byQeury={JSON.parse(
                        configuredChartInfoVal.metadata
                      ).key.toString()}
                      analysisType={
                        JSON.parse(configuredChartInfoVal.metadata)
                          .analytics_profile_type
                      }
                      durationType={
                        JSON.parse(configuredChartInfoVal.metadata)
                          .duration_type
                      }
                    ></LineChart>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          // float: "right",
                          fontWeight: "bold",
                          paddingRight: 10,
                          paddingTop: 10,
                        }}
                      >
                        {JSON.parse(
                          configuredChartInfoVal.metadata
                        ).duration_type.toUpperCase()}
                      </div>
                    </div>
                    </>
            }
                  </div>
                )
              );
            })}

          </div>
        )}
        </div>

        {/* <Tour
        steps={steps}
        accentColor={"#0F61DB"}
        isOpen={isTourOpen}
        onRequestClose={() => { setisTourOpen(false); updateTour()}}
        rounded={5}
        scrollDuration={100}
        disableInteraction={true}
        onAfterOpen={_ => (document.body.style.overflowY = 'hidden')}
        onBeforeClose={_ => (document.body.style.overflowY = 'auto')}
        lastStepNextButton={
        <Button type="primary" onClick={() => updateTour()}>
          Get Started
        </Button>
        }
        disableFocusLock={true}
        closeWithMask={false}
        getCurrentStep={(curr) => setc(curr)}
        startAt={0}
        /> */}
    </div>
  );
};

export default Dashboard;
