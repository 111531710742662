import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import CoachingSection from "./CoachingSection";
import { Tabs, Col, Row, Skeleton } from "antd";
import "./coaching.less";
import { getCategoryCourseList } from "store/actions/coachingAction";
import amCoachingBannerImg from "./../../assets/Images/4am_coaching_banner_img.svg"
import coaching_module_preview from "assets/Images/coaching_module_preview.png"
import PreviewInfo from "commons/PreviewInfo";
import config from "../../../src/aws-exports";
import { Storage } from "aws-amplify";
import { TrackClickFunction } from "commons/HeapTrack/TrackClickFunction";


const Coaching = () => {
  const {content} = useParams();
  
  
  const { TabPane } = Tabs;
  const [showSkeleton, setShowSkeleton] = useState(true)
  const dispatch = useDispatch();
  const { CategoryCouseList } = useSelector((state) => state.coachingReducer);
  const {userPermission, groups, viewType } = useSelector((state) => state.loginReducer);
  // eslint-disable-next-line no-unused-vars
  //const [fullPermission, setfullPermission] = useState(!(userPermission?.budgetCampaign === 'preview'))

  Storage.configure({ AWSS3: {
    bucket: config?.aws_user_files_s3_bucket,
    region: config.aws_user_files_s3_bucket_region
  }});

  useEffect(() => {
    dispatch(getCategoryCourseList()).then(()=>setShowSkeleton(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (

    <div>
      <div className = "coaching-header-section aboutPage panel-design" style={{padding:"30px 20px", marginBottom:'20px'}}>
        <Row gutter={[16, 16]}> 
          <Col span={6} xs={24} md={12} lg={15}>
            <div>
              <div className = "coaching-sub-header headerMain" style={{ color:'#1D2939', marginBottom:'15px', display:'block', padding:0 }}>Demand Generation Coaching</div>
              <div className = "coaching-header-description subHeaderMain" style={{ color:'#475467', padding:0 }}>
              Explore insights from our experts and discover a variety of materials to help educate you and your team on demand generation best practices.
              </div>
            </div>
          </Col>
          {/* <Col span={6} xs={24} md={12} lg={9}>
            <div className = "coaching-header-img">
              <img src = {amCoachingBannerImg} alt = "coaching" />
            </div>
          </Col> */}
        </Row>
      </div>

      {showSkeleton ? <>
      <div className="courses-tabs" style={{width:"100%", height:300,padding:30, paddingBottom:300}}>
        <Row justify="center" style={{width:'100%', marginBottom:20}}>
          <Skeleton.Button active style={{width:120}} />
        </Row>
        <Row gutter={[20,20]}>
          <Col span={8}>
            <Skeleton active paragraph={{rows:5}} />
          </Col>
          <Col span={8}>
            <Skeleton active paragraph={{rows:5}} />
          </Col>
          <Col span={8}>
            <Skeleton active paragraph={{rows:5}} />
          </Col>
        </Row>
      </div>

    </>:<Tabs className = "coaching-tab panel-design general-tab first-tab" centered animated>
        {CategoryCouseList.listCourseCategorys?.items.map(
          (courseCategoryVal) => {
            
            return (
              courseCategoryVal.courses?.items.length > 0 && (
                <Tabs.TabPane
                  tab={<div style={{fontWeight:700}} onClick={() => { 
                    if(courseCategoryVal.name === 'Sales & Marketing Alignment'){
                      return TrackClickFunction('demand-gen-coaching-sales-marketing-alignment-menu');
                    }
                    else if(courseCategoryVal.name === 'Marketers Toolbox '){
                      return TrackClickFunction('demand-gen-coaching-marketers-toolbox-menu');
                    }
                    else if(courseCategoryVal.name === 'Education/Justification'){
                      return TrackClickFunction('demand-gen-coaching-education-justification-menu');
                    }else{
                      return;
                    }
                    
                }}
                data-heap={(() => { 
                  if(courseCategoryVal.name === 'Sales & Marketing Alignment'){
                    return 'demand-gen-coaching-sales-marketing-alignment-menu';
                  }
                  else if(courseCategoryVal.name === 'Marketers Toolbox '){
                    return 'demand-gen-coaching-marketers-toolbox-menu';
                  }
                  else if(courseCategoryVal.name === 'Education/Justification'){
                    return 'demand-gen-coaching-education-justification-menu';
                  }else{
                    return;
                  }
                  
              })()}
                >{courseCategoryVal.name}</div>}
                  key={courseCategoryVal.id}
                  className="courses-tabs"
                  style = {{paddingTop:'30px'}}
                  
                >
                  <CoachingSection data={courseCategoryVal.courses?.items} category = {courseCategoryVal.name} isContent={content ? true : false}/>
                </Tabs.TabPane>
              )
            );
          }
        )}
      </Tabs> }
    </div>
  );
};

export default Coaching;
