import React from "react";
import { useSelector } from "react-redux";
import { Switch, BrowserRouter,Redirect } from "react-router-dom";
import CreateAccount from "pages/user/CreateAccount";
import ConfirmEmail from "pages/user/CreateAccount/ConfirmEmail";
import ConfirmPassword from "pages/user/ForgetPassword/ConfirmPassword";
import ForgetPassword from "pages/user/ForgetPassword";
import Login from "pages/user/Login";
import CreatePassword from "pages/user/Login/CreatePassword"
import TenantSelection from "pages/user/Accounts/Selection";
import PublicRoutes from "./PublicRoutes";
import MainLayoutRoute from "./MainLayoutRoute";
import AccountLayoutRoute from "./AccountRoute";
import { createBrowserHistory } from "history";
import { Dashboard } from "pages/dashboard";
import RevenuePlanning from "pages/RevenuePlanning";
import AccountDetails from "pages/account/Details";
import BudgetCampaignsOld from "pages/BudgetCampaignsOld";
import BudgetCampaigns from "pages/BudgetCampaigns";
import Coaching from "pages/Coaching";
import Admin from "pages/Admin";
import ExecutiveDashBoard from "pages/reports/Executive/V2";
import CourseDetails from "pages/Coaching/CourseDetails";
import SalesAndMarketing from "../pages/SalesAndMarketing";
import UserSettings from "pages/account/UserSettings";
import GoToMarket from "pages/goToMarket";
import SDR from "pages/SDR/SDR";
import SalesMarkeingLeadership from "pages/reports/SalesMarketingLeadership/V2/SalesMarkeingLeadership";
import Analytics from "pages/reports/Analytics/V2/Analytics";
import ContentLayoutRoute from "./ContentLayoutRoute";
import { jwtDecode } from 'jwt-decode';
import RevenueForecasting from "pages/RevenueForecasting";
import RevenueForecasting1 from "pages/RevenueForecasting1";
import TestComponent from "pages/test";

export const Routes = () => {
  const account = useSelector((state) => state.loginReducer.groups);
  const token = useSelector((state) => state.loginReducer.token);

  const { userPermission, viewType } = useSelector(
    (state) => state.loginReducer
  );
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const challenge = urlParams.get('challenge');
  if (email && challenge) {
    const decoded = jwtDecode(challenge);
    localStorage.setItem(
      'origin',
      decoded.origin ? decoded.origin : decoded.user_metadata.origin
    );
    localStorage.setItem('XPJWT', challenge);
  }



  return (
    <BrowserRouter basename="/" history={createBrowserHistory()}>
      <Switch>
        <PublicRoutes
          exact
          path={'/signin'}
          render={(matchprops) => <Login {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/create-password'}
          render={(matchprops) => <CreatePassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/signup'}
          render={(matchprops) => <CreateAccount {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/forgot-password'}
          render={(matchprops) => <ForgetPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/reset-password'}
          render={(matchprops) => <ConfirmPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/reset-password?confirmationcode=:code+email=:email'}
          render={(matchprops) => <ConfirmPassword {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/confirm_sign_up'}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />
        <PublicRoutes
          exact
          path={'/confirm_sign_up?confirmationcode=:code'}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />

        <AccountLayoutRoute
          exact
          path={'/setup?requestid=:id'}
          render={(matchprops) => <TenantSelection {...matchprops} />}
        />
        <AccountLayoutRoute
          exact
          path={'/setup'}
          render={(matchprops) => <TenantSelection {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={'/'}
          render={(matchprops) => <Dashboard {...matchprops} />}
        />
        <ContentLayoutRoute
          exact
          path={'/dashboard/:content'}
          render={(matchprops) => <Dashboard {...matchprops} />}
        />

        {((userPermission?.['revenuePlanning'] && userPermission?.['revenuePlanning'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") ))  && <MainLayoutRoute
          exact
          path={"/revenue-planning"}
          render={(matchprops) => <RevenuePlanning {...matchprops} />}
        />}
        {((userPermission?.['revenuePlanning'] && userPermission?.['revenuePlanning'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") ))  && <MainLayoutRoute
          exact
          path={"/revenue-forecasting"}
          render={(matchprops) => <RevenueForecasting {...matchprops} />}
        />}
        {((userPermission?.['revenuePlanning'] && userPermission?.['revenuePlanning'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") ))  && <MainLayoutRoute
          exact
          path={"/revenue-forecasting1"}
          render={(matchprops) => <RevenueForecasting1 {...matchprops} />}
        />}
        {((userPermission?.['revenuePlanning'] && userPermission?.['revenuePlanning'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") ))  && <ContentLayoutRoute
          exact
          path={"/revenue-planning/:content"}
          render={(matchprops) => <RevenuePlanning {...matchprops} />}
        />}
        {((userPermission?.['salesMarketing'] && userPermission?.['salesMarketing'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <MainLayoutRoute
          exact
          path={"/sales-and-marketing"}
          render={(matchprops) => (<SalesAndMarketing {...matchprops} />)}
        />}
        {((userPermission?.['salesMarketing'] && userPermission?.['salesMarketing'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <ContentLayoutRoute
          exact
          path={"/sales-and-marketing/:content"}
          render={(matchprops) => (<SalesAndMarketing {...matchprops} />)}
        />}

        {((userPermission?.['revenuePlanning'] &&
          userPermission?.['revenuePlanning'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <MainLayoutRoute
            exact
            path={'/revenue-planning'}
            render={(matchprops) => <RevenuePlanning {...matchprops} />}
          />
        )}
        {((userPermission?.['revenuePlanning'] &&
          userPermission?.['revenuePlanning'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <ContentLayoutRoute
            exact
            path={'/revenue-planning/:content'}
            render={(matchprops) => <RevenuePlanning {...matchprops} />}
          />
        )}
        {((userPermission?.['salesMarketing'] &&
          userPermission?.['salesMarketing'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <MainLayoutRoute
            exact
            path={'/sales-and-marketing'}
            render={(matchprops) => <SalesAndMarketing {...matchprops} />}
          />
        )}
        {((userPermission?.['salesMarketing'] &&
          userPermission?.['salesMarketing'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <ContentLayoutRoute
            exact
            path={'/sales-and-marketing/:content'}
            render={(matchprops) => <SalesAndMarketing {...matchprops} />}
          />
        )}

        {((userPermission?.['goToMarketIntelligence'] &&
          userPermission?.['goToMarketIntelligence'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <MainLayoutRoute
            exact
            path={'/go-to-market'}
            render={(matchprops) => <GoToMarket {...matchprops} />}
          />
        )}
        {((userPermission?.['goToMarketIntelligence'] &&
          userPermission?.['goToMarketIntelligence'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <ContentLayoutRoute
            exact
            path={'/go-to-market/:content'}
            render={(matchprops) => <GoToMarket {...matchprops} />}
          />
        )}

        {((userPermission?.['SDRMethodology'] &&
          userPermission?.['SDRMethodology'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <MainLayoutRoute
            exact
            path={'/sdr-methodology'}
            render={(matchprops) => <SDR {...matchprops} />}
          />
        )}

        <MainLayoutRoute
          exact
          path={'/my-settings'}
          render={(matchprops) => <UserSettings {...matchprops} />}
        />
        <ContentLayoutRoute
          exact
          path={'/my-settings/:content'}
          render={(matchprops) => <UserSettings {...matchprops} />}
        />
        <MainLayoutRoute
          exact
          path={'/settings'}
          render={(matchprops) => <AccountDetails {...matchprops} />}
        />
        <ContentLayoutRoute
          exact
          path={'/settings/:content'}
          render={(matchprops) => <AccountDetails {...matchprops} />}
        />
        {((userPermission?.['budgetCampaign'] && userPermission?.['budgetCampaign'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <MainLayoutRoute
          exact
          path={"/budget-campaignsOld"}
          render={(matchprops) => <BudgetCampaignsOld {...matchprops} />}
        />}
        {((userPermission?.['budgetCampaign'] && userPermission?.['budgetCampaign'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <MainLayoutRoute
          exact
          path={"/budget-campaigns"}
          render={(matchprops) => <BudgetCampaigns {...matchprops} />}
        />}


{((userPermission?.['budgetCampaign'] && userPermission?.['budgetCampaign'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <ContentLayoutRoute
         exact
          path={"/budget-campaigns/:content"}
          render={(matchprops) => <BudgetCampaigns {...matchprops} />}
        />}

        {((userPermission?.['coaching'] && userPermission?.['coaching'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <MainLayoutRoute
          exact
          path={"/coaching"}
          render={(matchprops) => <Coaching {...matchprops} />}
        />}
        {((userPermission?.['coaching'] && userPermission?.['coaching'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <ContentLayoutRoute
          exact
          path={"/coaching/:content"}
          render={(matchprops) => <Coaching {...matchprops} />}
        />}
        {((userPermission?.['coaching'] && userPermission?.['coaching'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <MainLayoutRoute
          path={"/coaching/course/:slug"}
          render={(matchprops) => <CourseDetails {...matchprops} />}
        />}
        {((userPermission?.['coaching'] && userPermission?.['coaching'] !== "hidden") || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && <ContentLayoutRoute
          path={"/coaching/content/course/:slug"}
          render={(matchprops) => <CourseDetails {...matchprops} />}
        />}

        {account && account[2] === "admin" && (
          <MainLayoutRoute
            exact
            path={'/budget-campaigns'}
            render={(matchprops) => <BudgetCampaigns {...matchprops} />}
          />
        )}

      {/* {account && account[2] === "admin" && (
          <MainLayoutRoute
            exact
            path={'/test'}
            render={(matchprops) => <TestComponent {...matchprops} />}
          />
        )} */}

        {((userPermission?.['budgetCampaign'] &&
          userPermission?.['budgetCampaign'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <ContentLayoutRoute
            exact
            path={'/budget-campaigns/:content'}
            render={(matchprops) => <BudgetCampaigns {...matchprops} />}
          />
        )}

        {((userPermission?.['coaching'] &&
          userPermission?.['coaching'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <MainLayoutRoute
            exact
            path={'/coaching'}
            render={(matchprops) => <Coaching {...matchprops} />}
          />
        )}
        {((userPermission?.['coaching'] &&
          userPermission?.['coaching'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <ContentLayoutRoute
            exact
            path={'/coaching/:content'}
            render={(matchprops) => <Coaching {...matchprops} />}
          />
        )}
        {((userPermission?.['coaching'] &&
          userPermission?.['coaching'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <MainLayoutRoute
            path={'/coaching/course/:slug'}
            render={(matchprops) => <CourseDetails {...matchprops} />}
          />
        )}
        {((userPermission?.['coaching'] &&
          userPermission?.['coaching'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            viewType !== 'companyadmin' &&
            viewType !== 'companycontributor')) && (
          <ContentLayoutRoute
            path={'/coaching/content/course/:slug'}
            render={(matchprops) => <CourseDetails {...matchprops} />}
          />
        )}

        {account && account[2] === 'admin' && (
          <MainLayoutRoute
            exact
            path={'/admin'}
            render={(matchprops) => <Admin {...matchprops} />}
          />
        )}
        {account && account[2] === 'admin' && (
          <ContentLayoutRoute
            exact
            path={'/admin/:content'}
            render={(matchprops) => <Admin {...matchprops} />}
          />
        )}
        {/* {((userPermission?.['reporting.executive'] && userPermission?.['reporting'] && userPermission?.['reporting.executive'] !== "hidden" && userPermission?.['reporting'] !== "hidden") || (account?.[2] === "admin" && viewType !== "user"))  && <MainLayoutRoute
          exact
          path={"/dashboards/executive"}
          render={(matchprops) => <Executive {...matchprops} />}
        />}
        {((userPermission?.['reporting.salesandmarketingleadership'] && userPermission?.['reporting'] && userPermission?.['reporting.salesandmarketingleadership'] !== "hidden" && userPermission?.['reporting'] !== "hidden") || (account?.[2] === "admin" && viewType !== "user"))  && <MainLayoutRoute
          exact
          path={"/dashboards/sales-and-marketing-leadership"}
          render={(matchprops) => <SalesMarketingLeadershipV1 {...matchprops} />}
        />}
        {((userPermission?.['reporting.analytics'] && userPermission?.['reporting'] && userPermission?.['reporting.analytics'] !== "hidden" && userPermission?.['reporting'] !== "hidden") || (account?.[2] === "admin" && viewType !== "user")) && <MainLayoutRoute
          exact
          path={"/dashboards/analytics"}
          render={(matchprops) => <AnalyticsV1 {...matchprops} />}
        />} */}

        {((userPermission?.['reporting.executive'] &&
          userPermission?.['reporting'] &&
          userPermission?.['reporting.executive'] !== 'hidden' &&
          userPermission?.['reporting'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            !(
              viewType === 'companyadmin' || viewType === 'companycontributor'
            ))) && (
          <MainLayoutRoute
            exact
            path={'/dashboards/executive'}
            render={(matchprops) => <ExecutiveDashBoard {...matchprops} />}
          />
        )}
        {((userPermission?.['reporting.executive'] &&
          userPermission?.['reporting'] &&
          userPermission?.['reporting.executive'] !== 'hidden' &&
          userPermission?.['reporting'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            !(
              viewType === 'companyadmin' || viewType === 'companycontributor'
            ))) && (
          <ContentLayoutRoute
            exact
            path={'/dashboards/executive/:content'}
            render={(matchprops) => <ExecutiveDashBoard {...matchprops} />}
          />
        )}

        {((userPermission?.['reporting.salesandmarketingleadership'] &&
          userPermission?.['reporting'] &&
          userPermission?.['reporting.salesandmarketingleadership'] !==
            'hidden' &&
          userPermission?.['reporting'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            !(
              viewType === 'companyadmin' || viewType === 'companycontributor'
            ))) && (
          <MainLayoutRoute
            exact
            path={'/dashboards/sales-and-marketing-leadership'}
            render={(matchprops) => <SalesMarkeingLeadership {...matchprops} />}
          />
        )}

        {((userPermission?.['reporting.salesandmarketingleadership'] &&
          userPermission?.['reporting'] &&
          userPermission?.['reporting.salesandmarketingleadership'] !==
            'hidden' &&
          userPermission?.['reporting'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            !(
              viewType === 'companyadmin' || viewType === 'companycontributor'
            ))) && (
          <ContentLayoutRoute
            exact
            path={'/dashboards/sales-and-marketing-leadership/:content'}
            render={(matchprops) => <SalesMarkeingLeadership {...matchprops} />}
          />
        )}

        {((userPermission?.['reporting.analytics'] &&
          userPermission?.['reporting'] &&
          userPermission?.['reporting.analytics'] !== 'hidden' &&
          userPermission?.['reporting'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            !(
              viewType === 'companyadmin' || viewType === 'companycontributor'
            ))) && (
          <MainLayoutRoute
            exact
            path={'/dashboards/analytics'}
            render={(matchprops) => <Analytics {...matchprops} />}
          />
        )}

        {((userPermission?.['reporting.analytics'] &&
          userPermission?.['reporting'] &&
          userPermission?.['reporting.analytics'] !== 'hidden' &&
          userPermission?.['reporting'] !== 'hidden') ||
          (account?.[2] === 'admin' &&
            !(
              viewType === 'companyadmin' || viewType === 'companycontributor'
            ))) && (
          <ContentLayoutRoute
            exact
            path={'/dashboards/analytics/:content'}
            render={(matchprops) => <Analytics {...matchprops} />}
          />
        )}

        <MainLayoutRoute render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
