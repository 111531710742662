import React, { Component } from "react";
import { Alert, Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { Auth } from "aws-amplify";
import backgroundImg from "assets/Images/login_signup_background.svg";
// import googleImg from "assets/Images/google_logo.svg"
import Logo from "commons/Logo";
import { CountryList, emailPattern } from "utility/Constants";
// import { InfoCircleOutlined } from '@ant-design/icons';

export class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      error: "",
      loading: false,
      Tnc :false
    };
  }

  handleSignUp = (e) => {
    if (e.firstName.length <= 1 || e.lastName.length <= 1) {
      this.setState({
        error: "Please enter valid name",
      });
    } else {
      this.setState(
        {
          loading: true,
          error: "",
        },
        async () => {
          try {
            await Auth.signUp({
              username: e.email,
              password: e.password,
              attributes: {
                email: e.email,
                given_name: e.firstName,
                family_name: e.lastName,
                "custom:country":e.country,
                "custom:job_function" :e.job_function,
                "custom:job_level" : e.job_level
              },
            });
            window?.freshpaint?.identify(e?.email, {
              "email": e?.email,
              "name": `${e?.firstName} ${e?.lastName}`,
              // "company": `${accountDetails?.data?.getAccount?.company}`
             });
            this.setState(
              {
                loading: false,
              },
              () => {
                // Go to ConfirmationAccount Screen
                this.props.history.push({
                  pathname: `/confirm_sign_up`,
                  state: { feedback: "", userName: e.email },
                });
                localStorage.setItem("User", window.btoa(e.email))
              }
            );
          } catch (error) {
            console.log("error signing up:", error);
            this.setState({
              error: error.message,
              loading: false,
            });
          }
        }
      );
    }
  };

  render() {
    const { error, loading, Tnc } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center" align="middle">
        <Col
          xs={0}
          md={0}
          lg={6}
        >
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: "100vh", minHeight:655 , position: 'fixed', top: 0, left: 0}}
          />
        </Col>
        <Col xs={24} md={24} lg={18} style={{ transform: "scale(0.88)" }}>
          <Row
            justify="center"
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              minHeight:580
            }}
          >
                <Logo />

            <Card className="card-container" style={{ border: "none", width: "45%", padding: "0px", height:"80vh"}}>


              {error && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_signup"
                className="signup-form"
                onFinish={(e) => this.handleSignUp(e)}
              >
                <Row style={{display:"flex", justifyContent:"space-between"}}>
                  <Col span={12}>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>First Name</strong>
                    </small>
                  }
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input
                    id="firstName"
                    key="firstName"
                    name="firstName"
                    placeholder="Your first name"
                    disabled={loading}

                  />
                </Form.Item>
                </Col>
                <Col span={11} >
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Last Name</strong>
                    </small>
                  }
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input
                    id="lastName"
                    key="lastName"
                    name="lastName"
                    placeholder="Your last name"
                    disabled={loading}

                  />
                </Form.Item>
                </Col>
                </Row>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Email</strong>

                    </small>
                  }
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Your email id"
                    disabled={loading}

                    type="email"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Country</strong>

                    </small>
                  }
                  name="country"

                  rules={[
                    { required: true, message: "Please Select Your Country" },
                  ]}
                >
                  <Select placeholder="Select country" showSearch size="large" style={{height:43}}
                    disabled={loading}
                  >
                  {CountryList && CountryList.map(country => {
                    return <Select.Option value={country.name} key = {country.code}>{country.name}</Select.Option>
                  })}
                  </Select>
                </Form.Item>
                <Row style={{display:"flex", justifyContent:"space-between"}}>
                  <Col span={12}>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Job Role</strong>

                    </small>
                  }
                  name="job_function"


                  rules={[
                    { required: true, message: "Enter your Job role" },
                  ]}
                >
                  <Select placeholder="Select your job role" showSearch size="large" style={{height:43}}
                  disabled={loading}
                  >
                  {['Marketing','Marketing Operations','Demand Generation/Growth','Revenue Operations','Sales','Sales Operations','Consulting','Customer Success','Finance','IT','Job Seeker/Student','Other'].map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                </Form.Item>
                  </Col>
                  <Col span={11}>

                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Job Level</strong>

                    </small>
                  }
                  name="job_level"

                  rules={[
                    { required: true, message: "Enter your Job Level" },
                  ]}
                >
                  <Select placeholder="Select your job Level" showSearch size="large" style={{height:43}}
                    disabled={loading}
                  >
                  {['Individual Contributor','Manager','Director','VP','C-Level'].map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                </Form.Item>
                  </Col>
                  </Row>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Create Password</strong>
                    </small>
                  }
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    placeholder="Create password "
                    disabled={loading}

                  />

                </Form.Item>

                  <Form.Item>
            <Checkbox onChange={e => this.setState({ Tnc : e.target.checked})} value={Tnc}>
              I agree to the{' '}
              <a href="https://4amdemand.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </Checkbox>
          </Form.Item>
          <div style={{paddingBottom:20}}>By registering, you agree to the processing of your personal data by XFactor.io as described in our <a href="https://4amdemand.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
          Privacy Policy
              </a>
          </div>
                <Form.Item style={{ marginBottom: "10px" }}>
                  <Row justify="space-between" align="middle">
                    <Col span={8}>
                      <Button
                        type="link"
                        size="large"
                        block
                        onClick={() => history.push("/signin")}
                        disabled={loading}
                        style={{ textAlign: "left" }}

                      >
                        <span style={{ color: "#0F61DB", fontWeight: "600", fontSize: "18px" }}>
                          Login
                        </span>
                      </Button>
                    </Col>
                    <Col span={10}>
                      <Button
                        type="primary"
                        size="small"
                        htmlType="submit"
                        block
                        loading={loading}

                        disabled={!Tnc}
                      ><span  style={{  fontSize: "18px" }}>
                        Sign Up
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>

              <div style={{marginTop:30}}>
                <p
                  style={{
                    color: "#646C79",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: "500",
                    userSelect: "none",
                    marginBottom: "0px",
                    marginTop: 4,
                  }}
                >
                  Version{" "}
                  {process.env.REACT_APP_VERSION
                    ? process.env.REACT_APP_VERSION
                    : "0.1.0"}
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#646C79",
                    textAlign: "center",
                  }}
                >
                  &copy; {new Date().getFullYear()} XFactor.io copyright all
                  rights reserved.
                </p>
              </div>
            </Card>

              {/* <Divider plain="true" style={{
                margin: "12px 0px ", color: "#A9A9A9"
              }}>or</Divider>

              <Button type="primary" block style={{ color: "white", backgroundColor: "#303030" }} > <img src={googleImg} alt="Google" style={{ height: "20px", paddingRight: "10px" }} /> Continue with Google</Button> */}

          </Row>
        </Col>
      </Row>
    );
  }
}

export default CreateAccount;
