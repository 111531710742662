export const TrackClickFunction = (customEvent, data) => {
    if (window.heap) {
        if(!data){
            window.heap.track(customEvent);
        }else{
            window.heap.track(customEvent,data);
        }

        console.log("called",customEvent);
        console.log("calledData",data);
    }
}