/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCrmSyncHistory = /* GraphQL */ `
  subscription OnCreateCrmSyncHistory {
    onCreateCrmSyncHistory {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const onUpdateCrmSyncHistory = /* GraphQL */ `
  subscription OnUpdateCrmSyncHistory {
    onUpdateCrmSyncHistory {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const onDeleteCrmSyncHistory = /* GraphQL */ `
  subscription OnDeleteCrmSyncHistory {
    onDeleteCrmSyncHistory {
      id
      account
      crm
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const onCreatePropertiesPackage = /* GraphQL */ `
  subscription OnCreatePropertiesPackage {
    onCreatePropertiesPackage {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePropertiesPackage = /* GraphQL */ `
  subscription OnUpdatePropertiesPackage {
    onUpdatePropertiesPackage {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePropertiesPackage = /* GraphQL */ `
  subscription OnDeletePropertiesPackage {
    onDeletePropertiesPackage {
      id
      name
      createdAt
      updatedAt
      properties {
        items {
          id
          propertiesPackageID
          key
          value
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      account {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserPackage = /* GraphQL */ `
  subscription OnCreateUserPackage {
    onCreateUserPackage {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUserPackage = /* GraphQL */ `
  subscription OnUpdateUserPackage {
    onUpdateUserPackage {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUserPackage = /* GraphQL */ `
  subscription OnDeleteUserPackage {
    onDeleteUserPackage {
      id
      owner
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  subscription OnCreateUserPackagesPropertiesPackageConnection {
    onCreateUserPackagesPropertiesPackageConnection {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  subscription OnUpdateUserPackagesPropertiesPackageConnection {
    onUpdateUserPackagesPropertiesPackageConnection {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserPackagesPropertiesPackageConnection = /* GraphQL */ `
  subscription OnDeleteUserPackagesPropertiesPackageConnection {
    onDeleteUserPackagesPropertiesPackageConnection {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      userpackages {
        id
        owner
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAccountPackage = /* GraphQL */ `
  subscription OnCreateAccountPackage {
    onCreateAccountPackage {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateAccountPackage = /* GraphQL */ `
  subscription OnUpdateAccountPackage {
    onUpdateAccountPackage {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteAccountPackage = /* GraphQL */ `
  subscription OnDeleteAccountPackage {
    onDeleteAccountPackage {
      id
      account
      createdAt
      updatedAt
      packages {
        items {
          id
          sortOrder
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  subscription OnCreateAccountPackagesPropertiesPackageConnection {
    onCreateAccountPackagesPropertiesPackageConnection {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  subscription OnUpdateAccountPackagesPropertiesPackageConnection {
    onUpdateAccountPackagesPropertiesPackageConnection {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteAccountPackagesPropertiesPackageConnection = /* GraphQL */ `
  subscription OnDeleteAccountPackagesPropertiesPackageConnection {
    onDeleteAccountPackagesPropertiesPackageConnection {
      id
      sortOrder
      createdAt
      updatedAt
      propertiespackages {
        id
        name
        createdAt
        updatedAt
        properties {
          nextToken
        }
        users {
          nextToken
        }
        account {
          nextToken
        }
      }
      accountpackages {
        id
        account
        createdAt
        updatedAt
        packages {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePositioning = /* GraphQL */ `
  subscription OnCreatePositioning {
    onCreatePositioning {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePositioning = /* GraphQL */ `
  subscription OnUpdatePositioning {
    onUpdatePositioning {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePositioning = /* GraphQL */ `
  subscription OnDeletePositioning {
    onDeletePositioning {
      id
      account
      key_differentiators
      value_proposition
      awards
      competitive {
        competitor_name
        website
      }
      offers
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTargetIdealCustomProfile = /* GraphQL */ `
  subscription OnCreateTargetIdealCustomProfile {
    onCreateTargetIdealCustomProfile {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTargetIdealCustomProfile = /* GraphQL */ `
  subscription OnUpdateTargetIdealCustomProfile {
    onUpdateTargetIdealCustomProfile {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTargetIdealCustomProfile = /* GraphQL */ `
  subscription OnDeleteTargetIdealCustomProfile {
    onDeleteTargetIdealCustomProfile {
      id
      account
      market_segments
      industries
      regions
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePersonas = /* GraphQL */ `
  subscription OnCreatePersonas {
    onCreatePersonas {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePersonas = /* GraphQL */ `
  subscription OnUpdatePersonas {
    onUpdatePersonas {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePersonas = /* GraphQL */ `
  subscription OnDeletePersonas {
    onDeletePersonas {
      id
      account
      personas {
        name
        person_type
        job_title
        job_functions
        job_level
        details_responsibilities
        pain_points
        pain_point_solution
        messaging_theme
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCurrentMarketActivity = /* GraphQL */ `
  subscription OnCreateCurrentMarketActivity {
    onCreateCurrentMarketActivity {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCurrentMarketActivity = /* GraphQL */ `
  subscription OnUpdateCurrentMarketActivity {
    onUpdateCurrentMarketActivity {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCurrentMarketActivity = /* GraphQL */ `
  subscription OnDeleteCurrentMarketActivity {
    onDeleteCurrentMarketActivity {
      id
      account
      channels
      email_marketing
      branded_keywords
      non_branded_keywords
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCurrentSalesMarketingTools = /* GraphQL */ `
  subscription OnCreateCurrentSalesMarketingTools {
    onCreateCurrentSalesMarketingTools {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCurrentSalesMarketingTools = /* GraphQL */ `
  subscription OnUpdateCurrentSalesMarketingTools {
    onUpdateCurrentSalesMarketingTools {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCurrentSalesMarketingTools = /* GraphQL */ `
  subscription OnDeleteCurrentSalesMarketingTools {
    onDeleteCurrentSalesMarketingTools {
      id
      account
      marketing_tools
      other
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCrmMarketingAutomationSystem = /* GraphQL */ `
  subscription OnCreateCrmMarketingAutomationSystem {
    onCreateCrmMarketingAutomationSystem {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCrmMarketingAutomationSystem = /* GraphQL */ `
  subscription OnUpdateCrmMarketingAutomationSystem {
    onUpdateCrmMarketingAutomationSystem {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCrmMarketingAutomationSystem = /* GraphQL */ `
  subscription OnDeleteCrmMarketingAutomationSystem {
    onDeleteCrmMarketingAutomationSystem {
      id
      account
      lead_contact_de_duplication
      lead_contact_de_duplication_process
      lead_contact_assignment
      lead_contact_assignment_process
      lead_contact_status
      lead_contact_status_process
      lead_contact_source
      lead_contact_source_process
      lead_contact_creation
      lead_contact_creation_process
      lead_contact_conversion
      lead_contact_conversion_process
      account_company_creation
      account_company_creation_process
      opportunity_deal_creation
      opportunity_deal_creation_process
      marketing_sales_sla
      marketing_sales_sla_process
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSocialChannels = /* GraphQL */ `
  subscription OnCreateSocialChannels {
    onCreateSocialChannels {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSocialChannels = /* GraphQL */ `
  subscription OnUpdateSocialChannels {
    onUpdateSocialChannels {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSocialChannels = /* GraphQL */ `
  subscription OnDeleteSocialChannels {
    onDeleteSocialChannels {
      id
      account
      facebook
      facebook_link
      instagram
      instagram_link
      linkedin
      linkedin_link
      youtube
      youtube_link
      twitter
      twitter_link
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAssetLibrary = /* GraphQL */ `
  subscription OnCreateAssetLibrary {
    onCreateAssetLibrary {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssetLibrary = /* GraphQL */ `
  subscription OnUpdateAssetLibrary {
    onUpdateAssetLibrary {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssetLibrary = /* GraphQL */ `
  subscription OnDeleteAssetLibrary {
    onDeleteAssetLibrary {
      id
      account
      name
      hosted_url
      creative_files_url
      abstract
      thumbnail
      type
      metadata
      created_date
      market_segments
      industries
      regions
      personas
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMaterializerSyncHistory = /* GraphQL */ `
  subscription OnCreateMaterializerSyncHistory {
    onCreateMaterializerSyncHistory {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const onUpdateMaterializerSyncHistory = /* GraphQL */ `
  subscription OnUpdateMaterializerSyncHistory {
    onUpdateMaterializerSyncHistory {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;
export const onDeleteMaterializerSyncHistory = /* GraphQL */ `
  subscription OnDeleteMaterializerSyncHistory {
    onDeleteMaterializerSyncHistory {
      id
      account
      source
      status
      createdAt
      detail
      updatedAt
    }
  }
`;