/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import {Button, Collapse, Drawer, Form, message, Select, Tooltip} from "antd";
import _, { isArray } from "lodash";
import React, {useEffect, useState} from "react";
import { getCampaignMappingHubspotFilter, getCampaignMappings, saveCampaignMappings } from "store/actions/budgetAction";
import './budget.less'
export default function MapCampaignDrawer(props) {

  const {isMapCampaignDrawerOpen,setIsMapCampaignDrawerOpen,campaignId,account, dispatch, ConnectedCRMs, linkRecordInfo} = props                                            
 

  useEffect(() =>{
    if(isMapCampaignDrawerOpen)
      getMappingsData()
    else{
      setavailableMappings([])
      setsavedMappings({})
      setavailableHubspotMappings({})
      setselectedorg_source([])
      setselectedorg_source_dd_1([])
      setselectedorg_source_dd_2([])

    }
    },[isMapCampaignDrawerOpen])

    const [availableMappings, setavailableMappings] = useState([])
    const [savedMappings, setsavedMappings] = useState({})
    const [hubspotLeadMap,setHubspotLeadMap] = useState({})
    const [hubspotOrgMap,setHubspotOrgMap] = useState({})

    const getMappingsData = () => {
      setIsLoading(true)

      let inputData = {
        account : account[0],
        data:[
          {
            "virtualCampaignId" : campaignId,
            "LinkedIn" : ConnectedCRMs['LinkedIn']?.filter(item => item.enabled)?.map(item => item?.profile_id),
            "Salesforce" : ConnectedCRMs['Salesforce']?.filter(item => item.enabled)?.map(item => item?.profile_id),
            "Hubspot" : ConnectedCRMs['Hubspot']?.filter(item => item.enabled)?.map(item => item?.profile_id),
            "GoogleAds" : ConnectedCRMs['GoogleAds']?.filter(item => item.enabled)?.map(item => item?.profile_id)
          }
        ]
      }
      let hubspotProfiles = ConnectedCRMs['Hubspot']?.map(item => item?.profile_id)

      hubspotProfiles?.map((hubspot) => {
        getHubspotMappingsFilter(hubspot)
      })

      dispatch(getCampaignMappings(inputData)).then(res=> {
        let available = []
        let saved = []
        let hubSpotSelected = []
        let hubspotLead = {}
        Object.keys(res).forEach(key => {
        if(key !== "virtualCampaignId"){
          res[key] && res[key]?.map((platform) => {
            if(platform){
              let platFormData = {}
              let platformInfo = getPlatformDetailsfromId(platform?.platform_id,key)
              if(platformInfo){
                platFormData.platform_name = key
                platFormData.name = `${key} (${platformInfo?.info?.profile_name})`
                platFormData.id = platform?.platform_id
                platFormData.available = platform?.availableMappings
                platFormData.saved = platform?.savedMappings?.actualCampaignnId
              }
              if(key !== "Hubspot")
              saved[platform.platform_id] = platform?.savedMappings?.actualCampaignnId?.map(item => item[0])
              else {
                platform?.availableMappings.map((i, index) => {
                  hubspotLead[i.key] = i.values
                  
                  
                })
                setavailableHubspotMappings(hubspotLead)
                if (platform.savedMappings?.[0]) {
                  setHubspotLeadMap(platform.savedMappings?.[0])
                  hubSpotSelected = platform.savedMappings?.[0]
                  setselectedorg_source(platform.savedMappings?.[0]?.org_source ? platform.savedMappings?.[0]?.org_source : [])
                  setselectedorg_source_dd_1(platform.savedMappings?.[0]?.org_source_dd_1 ? platform.savedMappings?.[0]?.org_source_dd_1 : [])
                  setselectedorg_source_dd_2(platform.savedMappings?.[0]?.org_source_dd_2 ? platform.savedMappings?.[0]?.org_source_dd_2 : [])
                }
              }
              available.push(platFormData)
            }
            })

          }
        });
      // setHubspotOrgMap(hubSpotSelected)
        setHubspotLeadMap(hubSpotSelected)

     
      let dataObj = {}
      available?.map(item => {
        item.available?.map(item => dataObj = {...dataObj, ...item})
      })
      setallAvailableMappings(dataObj)
      let allavailablereverted = _.invert(dataObj)

      Object.keys(saved).map(key => {
        saved[key] = saved[key]?.map(item => allavailablereverted[item])
      })
      let savedTemp = available?.map((item,index) => {if((saved?.[item?.id] && saved?.[item?.id]?.length > 0) || (item?.platform_name === "Hubspot" && hubSpotSelected?.length > 0)) {return index}})
      setSavedMappingKey(savedTemp)
      setavailableMappings(available)
      setsavedMappings(saved)


      setIsLoading(false)


      }).catch((err) => {
        console.log("Error", err)
      setIsLoading(false)

      })

      
    }

    const [allAvailableMappings, setallAvailableMappings] = useState({})
    const [availableHubspotMappings, setavailableHubspotMappings] = useState({})


    const getPlatformDetailsfromId = (id, platform) => {
      let data;
      Object.keys(ConnectedCRMs)?.forEach(key => {
        if(key === platform){
          data = ConnectedCRMs[key]?.find(item => item.profile_id === id)
          try {
            data.info = JSON.parse(data.metadata)
          } catch (error) {
            console.log(error);
          }
        }
      })
      return data;
    }


    const SaveMappings = () =>{
      setIsLoading(true)
      let inputData = {
        account:account[0],
        virtualCampaignId : campaignId
      }
      availableMappings.map(item => {
      
        let data = savedMappings[item.id]?.map(value => allAvailableMappings[value])
    
        let dataObj = {}
        if(item.platform_name !== "Hubspot"){
          dataObj[item.id] = data
        }
        else{
          dataObj[item.id] = hubspotLeadMap
        }
        inputData[item.platform_name] = []
        Object.keys(dataObj).map(keyy => {
          inputData[item.platform_name]?.push({platform_id:keyy, values:dataObj[keyy]})
        })
      })

      dispatch(saveCampaignMappings(inputData)).then(_=> {
      setIsLoading(false)
      message.success("Mappings saved successfully")
      setIsMapCampaignDrawerOpen(false);
      form.resetFields()
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        message.error("Unable to save mappings")
        setIsMapCampaignDrawerOpen(false);
      form.resetFields()
      })

    }

    const handleChangeSavedMappings = (value, id) => {
      value = value.map(item => isArray(item) ? item[0] : item)
      setsavedMappings(old => {
        let a = {...old}
        a[id] = value
      return a  
      })
    }

  const getHubspotMappingsFilter = (platform_id, orgsource, orgsource_dd_1) => {
      setIsLoading(true)

      let inputData = {
        account:account[0],
        platform_id:platform_id,
        source_1:orgsource,
        source_2 :orgsource_dd_1,
      }
      dispatch(getCampaignMappingHubspotFilter(inputData)).then((res) => {
        let data = {}
        res.available_mappings.map((item)=> {
          data[item.title] = item.values
        })
        setavailableHubspotMappings({
          org_source :  !orgsource ? data?.org_source : availableHubspotMappings?.org_source  ,
          org_source_dd_1 : !orgsource_dd_1?.length>0 ? data?.org_source_dd_1  :  availableHubspotMappings?.org_source_dd_1,
          org_source_dd_2 :data?.org_source_dd_2
        })
        setIsLoading(false)
        
      }).catch(err=> {
        console.log("Error", err)
        setIsLoading(false)
        
      })
    }


    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();



    const [selectedorg_source, setselectedorg_source] = useState([])
    const [selectedorg_source_dd_1, setselectedorg_source_dd_1] = useState([])
  const [selectedorg_source_dd_2, setselectedorg_source_dd_2] = useState([])

    const [SavedMappingKey, setSavedMappingKey] = useState()
    useEffect(() => {
      if(savedMappings){
        setSavedMappingKey(availableMappings?.map((item,index) => {if((savedMappings?.[item?.id] && savedMappings?.[item?.id]?.length > 0) || (selectedorg_source?.length > 0)) { return index}}))
      }
    },[availableMappings,savedMappings])

    return (
        <>
        {/* {isLoading && <div className="loading">Loading</div>} */}
        <Drawer
            onClose={() => setIsMapCampaignDrawerOpen(false)}
            visible={isMapCampaignDrawerOpen}
            title={<strong>Map Campaign { isLoading && <LoadingOutlined style={{marginLeft:10}} />}</strong>}
            width={416}
            bodyStyle={{paddingBottom: 80}}
        >
          <div style={{borderBottom: "2px solid #e9e9e9", paddingBottom:20, marginBottom:20}}>
            <span style={{fontStyle:"italic", fontWeight:"bold", paddingRight:5}}>Campaign:</span> <span className="italic-style">{linkRecordInfo?.['channel-campaign']}</span>
          </div>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                requiredMark={false}
                onFinish={SaveMappings}
              
            >
                <Form.Item name='mapping' >

                {availableMappings?.length > 0  && <Collapse ghost={true} className="collaspe-budget" expandIconPosition="right"  defaultActiveKey={SavedMappingKey}>
                  {
                    availableMappings?.map((platform,index) => (
                  <Collapse.Panel   header={platform?.name} key={index} >
                        { platform?.platform_name === "Hubspot" ? 
                          <>
                          {platform.available.map((item)=> {
                        return (

                            <Form.Item>
                            <label>{item?.title}</label>
                            {item.type === "Original" ? 
                            <>
                             <Select
                             mode="multiple"
                             className="select-value"
                             allowClear
                             size="large"
                             disabled={(availableHubspotMappings[item?.key] !== null && availableHubspotMappings[item?.key] !== undefined && Object.entries(availableHubspotMappings[item.key])?.length === 0) || isLoading}
                             style={{ width: '100%' }}
                             placeholder={`Select ${item?.title}`}
                             value={hubspotLeadMap[item.key]}
                              onChange={(value) => {
                                
                               setselectedorg_source(value)
                                if (value?.length === 0) { setselectedorg_source_dd_1([]); setselectedorg_source_dd_2([]) }
                                
                                 if (item.key === 'org_source') {
                                      getHubspotMappingsFilter(platform?.id,value,selectedorg_source_dd_1);
                                }
                                if (item.key === 'org_source_dd_1'){
                                      getHubspotMappingsFilter(platform?.id, selectedorg_source,value);
                                    }
                              setHubspotLeadMap({...hubspotLeadMap,[item?.key]:value})
                             }}
                             
                             >
                            {Object.entries(availableHubspotMappings[item.key] || {}).map(([key, value]) => (
                                <Select.Option key={key} value={key}>
                                  {value}
                                </Select.Option>
                              ))}
                         </Select>
                            </>
                            :
                              <Select
                                mode="multiple"
                                className="select-value"
                                allowClear
                                size="large"
                                disabled={isLoading}
                                style={{ width: '100%' }}
                                placeholder={`Select ${item?.title}`}
                                value={hubspotLeadMap[item.key]}
                                onChange={(e) => {
                                  setHubspotLeadMap({...hubspotLeadMap,[item?.key]:e})
                                }}
                              >
                                {
                                    Object.entries(item?.values).map(([key,value])=> {
                                    return  <Select.Option value={key} index={value}>
                                       {key}
                                      </Select.Option>
                                    })
                                }
                            </Select>
                            }
                        
                            </Form.Item>
                        )
                            })}
                        </>
                        :
                        <Select
                                mode="tags"
                                allowClear
                                size="large"
                                style={{ width: '100%' }}
                                placeholder={`Select ${platform.name}'s mappings`}
                                onChange={(value) => handleChangeSavedMappings(value,platform.id)}
                                value={savedMappings?.[platform?.id]
                                
                                }
                                className="general-input-select"
                                >
                                {
                                    platform?.available?.map((key,index) =>
                                      {
                                        let value = Object.keys(key)
                                        return <Select.Option key={value} ><Tooltip title={value}><p style={{margin:0}}>{value}</p></Tooltip></Select.Option>
                                      }
                                        )
                                }
                            </Select>}
                  </Collapse.Panel>
                ))
                }
                </Collapse> } 
                </Form.Item>

                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        borderTop: "1px solid #e9e9e9",
                        padding: "10px 16px",
                        background: "#fff",
                        textAlign: "right",
                        zIndex: 111,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}
                >
                    <Button
                        // disabled={isFormSubmitting}
                        className="secondaryButton"
                        onClick={() => {
                            setIsMapCampaignDrawerOpen(false);
                            form.resetFields()
                        }}
                        style={{marginRight: 8, fontSize: 18, width: "48%"}}
                        disabled={isLoading}

                    >
                        Cancel
                    </Button>
                    <Button
                        // loading={isFormSubmitting}
                        htmlType="submit"
                        type="primary"
                        style={{fontSize: 18, width: "48%"}}
                        disabled={isLoading}
                        className="primaryButton"

                    >
                        Save
                    </Button>
                </div> 
            </Form>
            </Drawer>
            </>
    )
}
