import * as actions from "../actions/index";

const initialState = {
  CourseList: [],
  CourseCategoryList: [],
  CategoryCouseList: [],
  isLoading: false,
  serverError: "",
};

const coachingReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actions.SET_COURSE_LIST:
      return { ...state, CourseList: action.payload };

    case actions.SET_COURSE_CATEGORY_LIST:
      return { ...state, CourseCategoryList: action.payload };

    case actions.SET_CATEGORY_COURSE_LIST:
      return { ...state, CategoryCouseList: action.payload };

    case actions.SHOW_LOADER:
      return { ...state, isLoading: action.payload };

    case actions.SERVER_ERROR:
      return { ...state, serverError: action.payload };

    default:
      return state;
  }
};

export default coachingReducer;
