import { Table, Space, Form, List, Skeleton } from "antd";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  listAccountPackagesData,
  updatePackagesSortOrder,
} from "store/actions/reportAction";
import { VerticalAlignMiddleOutlined } from "@ant-design/icons";
import _ from "lodash";

const AccountPackages = (props) => {
  const groups = useSelector((state) => state.loginReducer.groups);
  const dispatch = useDispatch();

  const { isLoading, userPackages } = useSelector(
    (state) => state.reportReducer
  );

  useEffect(() => {
    const accountID = groups.length > 0 ? groups[0] : null;
    dispatch(listAccountPackagesData(accountID));
  }, [dispatch]); // eslint-disable-line

  const handle_search = (value) => {
    setAccountPackages(backupAccountPackages);
    if (value?.search_value) {
      setAccountPackages(
        accountPakcages?.filter((val) =>
          String(val?.propertiespackages?.name)
            ?.toLowerCase()
            ?.includes(value?.search_value?.toLowerCase()?.trim())
        )
      );
    }
  };

  const [accountPakcages, setAccountPackages] = useState();
  const [backupAccountPackages, setBackupAccountPackages] = useState();
  useEffect(() => {
    if (
      userPackages?.length > 0 &&
      userPackages?.[0]?.packages?.items?.length > 0
    ) {
      const SortedPackages = _.sortBy(
        userPackages?.[0]?.packages?.items,
        (o) => o.sortOrder
      );
      setAccountPackages(SortedPackages);
      setBackupAccountPackages(SortedPackages);
    } else {
      setAccountPackages();
    }
  }, [userPackages]);

  const handleDragStart = (event, result) => {
    event.dataTransfer.setData("startId", event.target.id);
    event.dataTransfer.setData("parentId", event.target.parentElement.id);
  };

  const handleDragEnd = (event) => {
    const startIndex = event.dataTransfer.getData("startId");
    const lastIndex = event.target.id;

    let newArray = accountPakcages;
    if (startIndex > lastIndex) {
      const newObj = newArray[startIndex];
      newArray.splice(startIndex, 1);
      newArray.splice(lastIndex, 0, newObj);
    } else {
      const newObj = newArray[startIndex];
      newArray.splice(startIndex, 1);
      newArray.splice(lastIndex, 0, newObj);
    }
    dispatch(updatePackagesSortOrder(newArray));
    setAccountPackages([...newArray]);
  };

  const columns = [
    {
      title: "Assigned Packages",
      dataIndex: "name",
      key: "key",
      align: "center",
      render: (name, record, index) => {
        return (
          <List.Item
            draggable={true}
            style={{ border: "none", padding: " 5px 0", justifyContent:"start" }}
            onDragStart={(event) => handleDragStart(event)}
            onDrop={(event) => handleDragEnd(event)}
            onDragOver={(e) => e.preventDefault()}
            key={index}
            id={index}
          >
            <VerticalAlignMiddleOutlined
              style={{ fontSize: 26, color: "gray" , cursor:"move"}}
              title = "Drag and drop row to change order"
            />
            <div style={{ display: "flex", paddingLeft: "10%" }}>
              <div>{record?.propertiespackages?.name}</div>
            </div>
          </List.Item>
        );
      },
    },
  ];

  return (
    <>
      {" "}
      <div className="user-container" style={{ paddingTop: 40 }}>
        {/* {isLoading && <div className="loading">Loading;</div>} */}
        <Space direction="vertical" style={{ width: "100%" }} size={1}>
          <Form
            name="search_users"
            onFinish={handle_search}
          >
            <Space
              direction="horizontal"
              style={{
                padding: "0px 5px",
                paddingLeft: "20%",
                width: "100%",
                paddingRight: "20%",
              }}
            >
            </Space>
          </Form>

          {isLoading ? <Skeleton active paragraph={{rows:5}} style={{ paddingLeft: "20%", paddingRight: "20%" }} /> :
          <Table
            bordered
            size="middle"
            dataSource={accountPakcages}
            columns={columns}
            style={{ paddingLeft: "20%", paddingRight: "20%" }}
            className="package-list"
            pagination={false}
          ></Table>
        }
        </Space>
      </div>
    </>
  );
};

export default AccountPackages;
