import * as actions from "../actions/index";

const initialState = {
  isLoading: false,
  companyPositioning : [],
  companySocialChannels:[],
  CurrentMarketActivity:[],
  MarketingAutomationSystem: [],
  CurrentMarketTools: [],
  TargetIdealCustomProfile:[],
  Persona :[],
  AssetLibrary : []
};

const goToMarketReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actions.SHOW_LOADER:
        return { ...state, isLoading: action.payload };

    case actions.SET_COMPANY_POSITIONING:
        return { ...state, companyPositioning: action.payload };
    
    case actions.SET_SOCIAL_CHANNELS:
        return { ...state, companySocialChannels: action.payload };
    
    case actions.SET_CURRENT_MARKET_ACTIVITY:
        return { ...state, CurrentMarketActivity: action.payload };

    case actions.SET_CURRENT_MARKET_TOOLS:
        return { ...state, CurrentMarketTools: action.payload };
    
    case actions.SET_MARKETING_AUTOMATION_SYSTEM:
        return { ...state, MarketingAutomationSystem: action.payload };

    case actions.SET_TARGET_IDEAL_CUSTOM_PROFILE:
        return { ...state, TargetIdealCustomProfile: action.payload };

    case actions.SET_PERSONAS:
        return { ...state, Persona: action.payload };

    case actions.SET_ASSETS_LIBRARY:
        return { ...state, AssetLibrary: action.payload };

    default:
      return state;
  }
};

export default goToMarketReducer;
