import gaImage from "assets/Images/gaimage.png";
import googleAdsLogo from "assets/Images/google-ads-logo.png";
import hubImage from "assets/Images/hubspotimage.webp";
import linkedinImage from "assets/Images/linkedin_logo.svg";
import salesforcelogo from "assets/Images/Salesforce-logo.jpg";
import facebookLogo from "assets/Images/facebooklogo.png";


export const videoTypes = ["x-msvideo", "mp4", "mpeg"];
export const coachingImageTypes = ["image/jpeg", "image/png"];
export const coachingFileTypes = [
  "application/pdf",
  "application/zip",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application / vnd.ms - excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "video/x-msvideo",
  "video/mp4",
  "video/mpeg",
];

export const coachingAllowFileExt = ["pdf"];
export const emailPattern = /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/;

export const fourAMStages = [
  { name: "Leads", conversion: 50 },
  { name: "Marketing Qualified Leads", conversion: 50 },
  { name: "Sales Qualified Leads", conversion: 50 },
  { name: "Opportunity or Deals", conversion: 50 },
  { name: "Closed Won", conversion: 50 },
  { name: "Closed Lost", conversion: 50 },
];

export const DefaultMapping = {
  "Leads": ["lead", "Open - Not Contacted"],
  "Marketing Qualified Leads": ["marketingqualifiedlead", "Working - Contacted"],
  "Sales Qualified Leads": ["salesqualifiedlead",  "Closed – Converted"],
  "Opportunity or Deals": ["opportunity", "Qualified"],
  "Closed Won": ["closedwon", "Closed Won"],
  "Closed Lost": ["closedlost"],
};

export const DefaultMappingDefinition = {
  "Leads": "Any person that comes into our marketing funnel via a form fill out on website, organically, or paid lead generation that has shown interest in our content or offerings.",
  "Marketing Qualified Leads": "The right persona at the right company",
  "Sales Qualified Leads": "The prospect has agreed to take a meeting. The SDR or the Sales rep has set up the meeting. The SDR has qualified the prospect by using a set list of qualification questions and or BANT qualification. The SQL will be an opportunity in the CRM but it will not affect the forecast. It will be reported on at 0%.",
  "Opportunity or Deals": "The prospect has agreed to take next steps after the initial meeting.",
  "Closed Won": "Closed-Won account",
  "Closed Lost": "Account is not won",
};

export const DefaultMappingHowItComesIn = {
  "Leads": "Our Website, 3rd Party Content Promotion, Google Ads, LinkedIn Ads, Facebook Ads, Events, Webinars, Owned Social Media Channels",
  "Marketing Qualified Leads": "Our Website, 3rd Party Content Promotion, Google Ads, LinkedIn Ads, Facebook Ads, Events, Webinars, Owned Social Media Channels",
  "Sales Qualified Leads": "MQL's turn into Meetings",
  "Opportunity or Deals": "BDR or Rep creates an Opportunity with next steps and it will hit the forecast.",
  "Closed Won": "Signs an agreement",
  "Closed Lost": "Client expresses interest to not continue",
}

export const DefaultMappingThingsToKeepInMind = {
  "Leads": "Sometimes companies can confuse the definition of lead and opportunity. It is important when defining the marketing funnel stages that everyone on the Sales, Marketing and Leadership teams are all speaking the same language and understand the definitions. A Lead is not an opportunity or deal.",
  "Marketing Qualified Leads": "Clearly defining what a Marketing Qualified Lead lead is can be critical. Getting agreement on the definition between sales and marketing that a lead has reached MQL level means that it is considered ready by Sales and Marketing to begin pursuing with outreach either via phone or email or both.",
  "Sales Qualified Leads": "Make sure to define and agree what constitutes a good meeting when working with sales and marketing leadership. For example: If the prospects would like to see a demo that is a good meeting. Or, if the prospect has expressed BANT that constitutes a good meeting. This will help further down the road as these qualified leads hit the forecast. If there are issues with moving the prospect down the opportunity funnel. If the prospect does not want to move forward you should disposition the lead as Unqualified. Typical Unqualified reasons:Will Never Buy From UsNot the right personNo Show to MeetingTimingNo Budget.",
  "Opportunity or Deals": "",
  "Closed Won": "",
  "Closed Lost": "Be sure to have a closed lost reason field in your CRM so you can track why opportunities are being lost. Typical Closed Lost reasons: Competition, Price, Unresponsive, Budget, No Decision, Timing, Poor Qualification, Lost Momentum, Product Feature, Duplicate, Other, Other Reason - ",
}

export const DefaultMappingCategory = {
  "Leads": "CONTACTS",
  "Marketing Qualified Leads": "CONTACTS",
  "Sales Qualified Leads": "CONTACTS",
  "Opportunity or Deals": "CONTACTS",
  "Closed Won": "DEALS",
  "Closed Lost": "DEALS",
};

export const defaultStageOrder = {
  "Leads": 1,
  "Marketing Qualified Leads": 2,
  "Sales Qualified Leads": 3,
  "Opportunity or Deals": 4,
  "Closed Won": 998,
  "Closed Lost": 999,
};

export const preDefinedChannelNamesTypes = [
  {name: "Advertising", type: "DIGITAL_ADVERTISING"},
  {name: "Webinars", type: "WEBINARS"},
  {name: "Email Based", type: "EMAIL_BASED"},
  {name: "Content", type: "CONTENT_PROMOTION"},
  {name: "Events", type: "EVENTS"},
];

export const preDefinedChannelNamesTypesTitle = [
  {type: "DIGITAL_ADVERTISING", typeTitle: "DIGITAL ADVERTISING"},
  {type: "WEBINARS", typeTitle: "WEBINARS"},
  {type: "EMAIL_BASED", typeTitle: "EMAIL BASED"},
  {type: "CONTENT_PROMOTION", typeTitle: "CONTENT PROMOTION"},
  {type: "EVENTS", typeTitle: "EVENTS"},
  {type: "SOFTWARE", typeTitle: "SOFTWARE"},
  {type: "HEADCOUNT", typeTitle: "HEADCOUNT"},
  {type: "PR", typeTitle: "PR"},
  {type: "AR", typeTitle: "AR"},
  {type: "BRANDING", typeTitle: "BRANDING"},
  {type: "MARKETING_COLLATERAL", typeTitle: "MARKETING COLLATERAL"},
  {type: "RECRUITING", typeTitle: "RECRUITING"},
  {type: "FIELD_MARKETING", typeTitle: "FIELD MARKETING"},
  {type: "SWAG", typeTitle: "SWAG"},
  {type: "OTHERS", typeTitle: "OTHERS"}
];

export const CountryList = [ 
  {name: 'Afghanistan', code: 'AF'}, 
  {name: 'Åland Islands', code: 'AX'}, 
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'AndorrA', code: 'AD'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Anguilla', code: 'AI'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cape Verde', code: 'CV'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Cote D\'Ivoire', code: 'CI'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Eritrea', code: 'ER'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
  {name: 'Faroe Islands', code: 'FO'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'French Guiana', code: 'GF'}, 
  {name: 'French Polynesia', code: 'PF'}, 
  {name: 'French Southern Territories', code: 'TF'}, 
  {name: 'Gabon', code: 'GA'}, 
  {name: 'Gambia', code: 'GM'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Ghana', code: 'GH'}, 
  {name: 'Gibraltar', code: 'GI'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Grenada', code: 'GD'}, 
  {name: 'Guadeloupe', code: 'GP'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guernsey', code: 'GG'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Guinea-Bissau', code: 'GW'}, 
  {name: 'Guyana', code: 'GY'}, 
  {name: 'Haiti', code: 'HT'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Honduras', code: 'HN'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Jordan', code: 'JO'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
  {name: 'Madagascar', code: 'MG'}, 
  {name: 'Malawi', code: 'MW'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Maldives', code: 'MV'}, 
  {name: 'Mali', code: 'ML'}, 
  {name: 'Malta', code: 'MT'}, 
  {name: 'Marshall Islands', code: 'MH'}, 
  {name: 'Martinique', code: 'MQ'}, 
  {name: 'Mauritania', code: 'MR'}, 
  {name: 'Mauritius', code: 'MU'}, 
  {name: 'Mayotte', code: 'YT'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Niue', code: 'NU'}, 
  {name: 'Norfolk Island', code: 'NF'}, 
  {name: 'Northern Mariana Islands', code: 'MP'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Papua New Guinea', code: 'PG'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Reunion', code: 'RE'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'RWANDA', code: 'RW'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN'}, 
  {name: 'Saint Lucia', code: 'LC'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'San Marino', code: 'SM'}, 
  {name: 'Sao Tome and Principe', code: 'ST'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Sierra Leone', code: 'SL'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
  {name: 'Swaziland', code: 'SZ'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Timor-Leste', code: 'TL'}, 
  {name: 'Togo', code: 'TG'}, 
  {name: 'Tokelau', code: 'TK'}, 
  {name: 'Tonga', code: 'TO'}, 
  {name: 'Trinidad and Tobago', code: 'TT'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC'}, 
  {name: 'Tuvalu', code: 'TV'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Vanuatu', code: 'VU'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Virgin Islands, British', code: 'VG'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI'}, 
  {name: 'Wallis and Futuna', code: 'WF'}, 
  {name: 'Western Sahara', code: 'EH'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
]

export const industries = [
'Accounting',
'Airlines/Aviation',
'Alternative Dispute Resolution',
'Alternative Medicine',
'Animation',
'Apparel & Fashion',
'Architecture & Planning',
'Arts and Crafts',
'Automotive',
'Aviation & Aerospace',
'Banking',
'Biotechnology',
'Broadcast Media',
'Building Materials',
'Business Supplies and Equipment',
'Capital Markets',
'Chemicals',
'Civic & Social Organization',
'Civil Engineering',
'Commercial Real Estate',
'Computer & Network Security',
'Computer Games',
'Computer Hardware',
'Computer Networking',
'Computer Software',
'Internet',
'Construction',
'Consumer Electronics',
'Consumer Goods',
'Consumer Services',
'Cosmetics',
'Dairy',
'Defense & Space',
'Design',
'Education Management',
'E-Learning',
'Electrical/Electronic Manufacturing',
'Entertainment',
'Environmental Services',
'Events Services',
'Executive Office',
'Facilities Services',
'Farming',
'Financial Services',
'Fine Art',
'Fishery',
'Food & Beverages',
'Food Production',
'Fund-Raising',
'Furniture',
'Gambling & Casinos',
'Glass, Ceramics & Concrete',
'Government Administration',
'Government Relations',
'Graphic Design',
'Health, Wellness and Fitness',
'Higher Education',
'Hospital & Health Care',
'Hospitality',
'Human Resources',
'Import and Export',
'Individual & Family Services',
'Industrial Automation',
'Information Services',
'Information Technology and Services',
'Insurance',
'International Affairs',
'International Trade and Development',
'Investment Banking',
'Investment Management',
'Judiciary',
'Law Enforcement',
'Law Practice',
'Legal Services',
'Legislative Office',
'Leisure, Travel & Tourism',
'Libraries',
'Logistics and Supply Chain',
'Luxury Goods & Jewelry',
'Machinery',
'Management Consulting',
'Maritime',
'Market Research',
'Marketing and Advertising',
'Mechanical or Industrial Engineering',
'Media Production',
'Medical Devices',
'Medical Practice',
'Mental Health Care',
'Military',
'Mining & Metals',
'Motion Pictures and Film',
'Museums and Institutions',
'Music',
'Nanotechnology',
'Newspapers',
'Nonprofit Organization Management',
'Oil & Energy',
'Online Media',
'Outsourcing/Offshoring',
'Package/Freight Delivery',
'Packaging and Containers',
'Paper & Forest Products',
'Performing Arts',
'Pharmaceuticals',
'Philanthropy',
'Photography',
'Plastics',
'Political Organization',
'Primary/Secondary Education',
'Printing',
'Professional Training & Coaching',
'Program Development',
'Public Policy',
'Public Relations and Communications',
'Public Safety',
'Publishing',
'Railroad Manufacture',
'Ranching',
'Real Estate',
'Recreational Facilities and Services',
'Religious Institutions',
'Renewables & Environment',
'Research',
'Restaurants',
'Retail',
'Security and Investigations',
'Semiconductors',
'Shipbuilding',
'Sporting Goods',
'Sports',
'Staffing and Recruiting',
'Supermarkets',
'Telecommunications',
'Textiles',
'Think Tanks',
'Tobacco',
'Translation and Localization',
'Transportation/Trucking/Railroad',
'Utilities',
'Venture Capital & Private Equity',
'Veterinary',
'Warehousing',
'Wholesale',
'Wine and Spirits',
'Wireless',
'Writing and Editing',
]

export const companySize = [
'1-10 employees',
'11-50 employees',
'51-200 employees',
'201-500 employees',
'501-1000 employees',
'1001-5000 employees',
'5001-10,000 employees',
'10,001+ employees',
]

export const current_marketing_activity_channels = {
  'Website Forms' : "WEBSITE_FORMS",
  'Events':"EVENTS", 
  'Paid Media - LinkedIn': "PAID_MEDIA_LINKEDIN", 
  'Paid Media - Google': "PAID_MEDIA_GOOGLE", 
  'Paid Media - Facebook':"PAID_MEDIA_FACEBOOK", 
  'Paid Media - YouTube':"PAID_MEDIA_YOUTUBE", 
  'Content Syndication':"CONTENT_SYNDICATION", 
  'Webinars':"WEBINARS", 
  'Purchased Lists':"PURCHASED_LISTS", 
  'Sales Outbound Efforts':"SALES_OUTBOUND_EFFORTS", 
  'Other' : "OTHER"
}
export const current_sales_marketing_tools = {
  'Hubspot' : "HUBSPOT",
  'Salesforce':"SALESFORCE", 
  'Marketo': "MARKETO", 
  'Eloqua': "ELOQUA", 
  'Pardot':"PARDOT", 
  'Zoominfo':"ZOOMINFO", 
  '6Sense':"SIXSENSE", 
  'Bombora':"BOMBORA", 
  'Triblio':"TRIBLIO", 
  'Salesloft':"SALESLOFT", 
  'Outreach':"OUTREACH", 
  'Other' : "OTHER"
}
export const email_marketing_programs = {
  'Newsletter' : "NEWSLETTER", 
  'Welcome Series':"WELCOME_SERIES", 
  'Persona Specific Drip':"PERSONA_SPECIFIC_DRIP", 
  'Industry Specific Drip':"INDUSTRY_SPECIFIC_DRIP", 
  'Product Specific Drip':"PRODUCT_SPECIFIC_DRIP", 
  'Use Case Specific Drip':"USECASE_SPECIFIC_DRIP", 
  'Webinar Program' :"WEBINAR_PROGRAM"
}
   
export const target_market_segments  = {
  "SMB (1-100 employees)" : "SBM",
  "Mid-market (101-500 employees)" : "MID_MARKET",
  "Enterprise (500+ employees)" : "ENTERPRISE"
}

export const target_industries = {
  "Fitness & wellness" : "FITNESS_WELLNESS",
  "Import & Export" :"IMPORT_EXPORT",
  "Pharmaceutical" : "PHARMACEUTICAL"
}

export const target_regions = ['NA','EMEA','APAC','LATAM']

export const persona_values = {
  "persona_type" : {
    "Decision Maker" : "DECISION_MAKER",
    "Champion":"CAMPAIGN", 
    "Buyer" : "BUYER"
  },
  "job_title" : {
    "Brand Manager":"BRAND_MANAGER",
    "Marketing Manager":"MARKETING_MANAGER"
  },
  "job_functions" : [
    {name: 'Accounting / Auditing', id: 'acc'},
    {name: 'Administrative', id: 'adm'},
    {name: 'Advertising', id: 'advr'},
    {name: 'Analyst', id: 'anls'},
    {name: 'Art / Creative', id: 'art'},
    {name: 'Business Development', id: 'bd'},
    {name: 'Consulting', id: 'cnsl'},
    {name: 'Distribution', id: 'dist'},
    {name: 'Design', id: 'dsgn'},
    {name: 'Education', id: 'edu'},
    {name: 'Engineering', id: 'eng'},
    {name: 'Finance', id: 'fin'},
    {name: 'General Business', id: 'genb'},
    {name: 'HealthCare Provider', id: 'hcpr'},
    {name: 'Human Resources', id: 'hr'},
    {name: 'Information Technology', id: 'it'},
    {name: 'Legal', id: 'lgl'},
    {name: 'Management', id: 'mgmt'},
    {name: 'Manufacturing', id: 'mnfc'},
    {name: 'Marketing', id: 'mrkt'},
    {name: 'Other', id: 'othr'},
    {name: 'Public Relations', id: 'pr'},
    {name: 'Purchasing', id: 'prch'},
    {name: 'Product Management', id: 'prdm'},
    {name: 'Project Management', id: 'prjm'},
    {name: 'Production', id: 'prod'},
    {name: 'QualityAssurance', id: 'qa'},
    {name: 'Research', id: 'rsch'},
    {name: 'Sales', id: 'sale'},
    {name: 'Science', id: 'sci'},
    {name: 'Strategy / Planning', id: 'stra'},
    {name: 'Supply Chain', id: 'supl'},
    {name: 'Training', id: 'trng'},
    {name: 'Writing / Editing', id: 'wrt'},


],
 
}

export const asset_type = {
  "Video" : "VIDEO",
  "Ebook":"EBOOK",
  "White Paper":"WHITEPAPER",
  "Case Study":"CASESTUDY",
  "Webinar":"WEBINAR",
  "Blog":"BLOG",
  "Demo":"DEMO",
  "Trial":"TRIAL",
  "Other":"OTHER"
}

export const crmConnections = {
  GA : {name:"Google Analytics", logo:gaImage, type:"marketing"},
    Hubspot : {name:"Hubspot", logo:hubImage, type:"crm"},
    GoogleAds : {name:"Google Ads", logo:googleAdsLogo, type:"marketing"},
    Salesforce : {name:"SalesForce", logo:salesforcelogo, type:"crm"},
    LinkedIn : {name:"LinkedIn", logo:linkedinImage, type:"marketing"},
    Facebook : {name:"Facebook", logo:facebookLogo, type:"marketing"},
}
// export const connectionURLs = {
//   GA : "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=" +
//   encodeURI(process.env.REACT_APP_CLIENTID) +
//   "&redirect_uri=" +
//   encodeURI(process.env.REACT_APP_REDIRECT_URI) +
//   "&state=ga&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&access_type=offline&approval_prompt=force&flowName=GeneralOAuthFlow",
  
//   GAContent : "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=" +
//   encodeURI(process.env.REACT_APP_CLIENTID) +
//   "&redirect_uri=" +
//   encodeURI(process.env.REACT_APP_REDIRECT_URI_CONTENT) +
//   "&state=ga&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&access_type=offline&approval_prompt=force&flowName=GeneralOAuthFlow",
  

//   GoogleAds : "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=" +
//   encodeURI(process.env.REACT_APP_GOOGLEADS_CLIENTID) +
//   "&redirect_uri=" +
//   encodeURI(process.env.REACT_APP_REDIRECT_URI) +
//   "&state=googleads&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&access_type=offline&include_granted_scopes=true&approval_prompt=force&flowName=GeneralOAuthFlow",

//   Hubspot : "https://app.hubspot.com/oauth/authorize?client_id=" +
//   encodeURI(process.env.REACT_APP_HUBSPOT_CLIENTID) +
//   "&scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read&redirect_uri=" +
//   encodeURI(process.env.REACT_APP_REDIRECT_URI) +
//   "&state=hubspot",

//   Salesforce : "https://login.salesforce.com/services/oauth2/authorize?client_id=" +
//   encodeURI(process.env.REACT_APP_SALESFORCE_CLIENTID) +
//   "&scope=refresh_token+api&redirect_uri=" +
//   `${encodeURI(process.env.REACT_APP_REDIRECT_URI)}` +
//   "&state=salesforce&response_type=code",

//   SalesforceContent : "https://login.salesforce.com/services/oauth2/authorize?client_id=" +
//   encodeURI(process.env.REACT_APP_SALESFORCE_CLIENTID) +
//   "&scope=refresh_token+api&redirect_uri=" +
//   `${encodeURI(process.env.REACT_APP_REDIRECT_URI_CONTENT)}` +
//   "&state=salesforce&response_type=code",

//   LinkedIn : "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" +
//   encodeURI(process.env.REACT_APP_LINKEDIN_CLIENTID) +
//   "&redirect_uri=" +
//   encodeURI(process.env.REACT_APP_REDIRECT_URI) +
//   "&state=LinkedIn&scope=rw_organization_admin,r_organization_social,rw_ads,r_ads_reporting",

//   Facebook : "https://graph.facebook.com/oauth/authorize?response_type=code&client_id="+
//   174948361437642+
// //   encodeURI(process.env.REACT_APP_FACEBOOKADS_CLIENTID) +
//   "&redirect_uri="+
//   encodeURI("https://stage.4amdemand.com/settings") +
//   "&grant_type=authorization_code&state=facebook"

// }

export const createConnectionURLs = (redirectUrl) => ({
    GA: "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=" +
      encodeURIComponent(process.env.REACT_APP_CLIENTID) +
      "&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=ga&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&access_type=offline&approval_prompt=force&flowName=GeneralOAuthFlow",
  
    GAContent: "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=" +
      encodeURIComponent(process.env.REACT_APP_CLIENTID) +
      "&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=ga&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&access_type=offline&approval_prompt=force&flowName=GeneralOAuthFlow",
  
    GoogleAds: "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=" +
      encodeURIComponent(process.env.REACT_APP_GOOGLEADS_CLIENTID) +
      "&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=googleads&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&access_type=offline&include_granted_scopes=true&approval_prompt=force&flowName=GeneralOAuthFlow",
  
    Hubspot: "https://app.hubspot.com/oauth/authorize?client_id=" +
      encodeURIComponent(process.env.REACT_APP_HUBSPOT_CLIENTID) +
      "&scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=hubspot",
  
    Salesforce: "https://login.salesforce.com/services/oauth2/authorize?client_id=" +
      encodeURIComponent(process.env.REACT_APP_SALESFORCE_CLIENTID) +
      "&scope=refresh_token+api&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=salesforce&response_type=code",

    SalesforceSandbox: "https://test.salesforce.com/services/oauth2/authorize?client_id=" +
      encodeURIComponent(process.env.REACT_APP_SALESFORCE_CLIENTID) +
      "&scope=refresh_token+api&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=salesforce_sandbox&response_type=code",  
  
    SalesforceContent: "https://login.salesforce.com/services/oauth2/authorize?client_id=" +
      encodeURIComponent(process.env.REACT_APP_SALESFORCE_CLIENTID) +
      "&scope=refresh_token+api&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=salesforce&response_type=code",
  
    LinkedIn: "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" +
      encodeURIComponent(process.env.REACT_APP_LINKEDIN_CLIENTID) +
      "&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&state=LinkedIn&scope=rw_organization_admin,r_organization_social,rw_ads,r_ads_reporting",
  
    Facebook: "https://graph.facebook.com/oauth/authorize?response_type=code&client_id=" +
      encodeURIComponent(process.env.REACT_APP_FACEBOOKADS_CLIENTID) +
      "&redirect_uri=" +
      encodeURIComponent(redirectUrl) +
      "&grant_type=authorization_code&state=facebook"
  });
  

export const sampleBudgetCampaigns = [
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 12,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 2,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 10,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 6,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 3,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 8,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 9,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 1,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 4,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 7,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 5,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 11,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              }
                          ]
                      },
                      "date_end": "2022-12-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "Google Ads",
          'channel':"Advertising"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 7500,
                                  "leads": 150,
                                  "month": 4,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-04-30",
                      "date_start": "2022-04-01"
                  }
              ]
          },
          "name": "Event 2",
          "channel" : "Events"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 25000,
                                  "leads": 299,
                                  "month": 7,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-07-31",
                      "date_start": "2022-07-01"
                  }
              ]
          },
          "name": "Webinar 3",
          "channel" : "Webinars"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 5,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 3,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 4,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 11,
                                  "weight": 33.34,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 7,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4163,
                                  "leads": 30,
                                  "month": 12,
                                  "weight": 33.31,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 6,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 10,
                                  "weight": 33.34,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 9,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 2,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 8,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 4167,
                                  "leads": 30,
                                  "month": 1,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              }
                          ]
                      },
                      "date_end": "2022-12-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "Content Syndication - Tech Target",
          'channel':"Advertising"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 1,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 6,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 7,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 8,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 9,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 5,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 12,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 2,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 11,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 3,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 4,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 35,
                                  "month": 10,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              }
                          ]
                      },
                      "date_end": "2022-12-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "Content Syndication - IDG",
          'channel':"Advertising"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 18000,
                                  "leads": 150,
                                  "month": 1,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-01-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "IDG WhitePaper with Leads",
          "channel" : "Content"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 4,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 12,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 5,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 3,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 6,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 9,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 7,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 11,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 10,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 2,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 1,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 5000,
                                  "leads": 180,
                                  "month": 8,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              }
                          ]
                      },
                      "date_end": "2022-12-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "Content Syndication - True Influence",
          'channel':"Advertising"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 25000,
                                  "leads": 150,
                                  "month": 7,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-07-31",
                      "date_start": "2022-07-01"
                  }
              ]
          },
          "name": "Event 3",
          "channel" : "Events"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 4,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 11,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 1,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 3,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 9,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 5,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 2,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 6,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 10,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 12,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 7,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 50,
                                  "month": 8,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              }
                          ]
                      },
                      "date_end": "2022-12-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "Facebook",
          'channel':"Advertising"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 20000,
                                  "leads": 299,
                                  "month": 1,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-01-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "channel" : "Webinars",
          "name": "Webinar 1"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 15000,
                                  "leads": 150,
                                  "month": 2,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-02-28",
                      "date_start": "2022-02-01"
                  }
              ]
          },
          "name": "Event 1",
          "channel" : "Events"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 12,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 7,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 6,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 10,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 8,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 11,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 3,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 5,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 2,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 9,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 4,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              },
                              {
                                  "budget": 1500,
                                  "leads": 20,
                                  "month": 1,
                                  "weight": 33.33,
                                  "weight_lead": 33.33
                              }
                          ]
                      },
                      "date_end": "2022-12-31",
                      "date_start": "2022-01-01"
                  }
              ]
          },
          "name": "LinkedIn",
          'channel':"Advertising"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 20000,
                                  "leads": 299,
                                  "month": 4,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-04-30",
                      "date_start": "2022-04-01"
                  }
              ]
          },
          "channel" : "Webinars",
          "name": "Webinar 2"
      }
  },
  {
      "campaign": {
          "campaign_details": {
              "items": [
                  {
                      "campaign_months": {
                          "items": [
                              {
                                  "budget": 25000,
                                  "leads": 299,
                                  "month": 10,
                                  "weight": 100,
                                  "weight_lead": 100
                              }
                          ]
                      },
                      "date_end": "2022-10-31",
                      "date_start": "2022-10-01"
                  }
              ]
          },
          "channel" : "Webinars",
          "name": "Webinar 4"
      }
  }
]
export const sampleBudgetAmount = 400000



export const reportingHeadings = {
    "google-analytics" : {
                            heading:"Google Analytics",
                            subHeading : "Monitor your results to help communicate the right information to the right stakeholders. Explore your website trends below."
                         },
    "executive" : {
                    heading:"Executive",
                    subHeading : "Monitor your results to help communicate the right information to the right stakeholders. Use these recommended data visualizations to keep your Executive Leadership informed."
                   },
     "sales-and-marketing" : {
                            heading:"Sales And Marketing Leadership",
                            subHeading : "Monitor your results to help communicate the right information to the right stakeholders.  Use these recommended data visualizations to keep your Sales & Marketing Leadership informed."
                            },
}

export const TourFunnelData = [
    {
      text: 'Lead',
      value: 128,
      width: 70,
      percentageValue:50
    },
    {
      text: 'Marketing Qualified Lead',
      value: 64,
      width: 70,
      percentageValue:50
    },
    {
      text: 'Sales Qualified Lead',
      value: 32,
      width: 70,
      percentageValue:50
    },
    {
      text: 'Opportunity or Deal',
      value: 16,
      width: 70,
      percentageValue:50
    },
    {
      text: 'Closed Won',
      value: 8,
      width: 70,
      percentageValue:50
    },
  ];

export const CSVImportTemplateHeaders = [
    "Campaign",
    "Channel",
    "Jan(Budget)",
    "Jan(Leads)",
    "Feb(Budget)",
    "Feb(Leads)",
    "Mar(Budget)",
    "Mar(Leads)",
    "Apr(Budget)",
    "Apr(Leads)",
    "May(Budget)",
    "May(Leads)",
    "Jun(Budget)",
    "Jun(Leads)",
    "Jul(Budget)",
    "Jul(Leads)",
    "Aug(Budget)",
    "Aug(Leads)",
    "Sep(Budget)",
    "Sep(Leads)",
    "Oct(Budget)",
    "Oct(Leads)",
    "Nov(Budget)",
    "Nov(Leads)",
    "Dec(Budget)",
    "Dec(Leads)"
]

export const BudgetTemplateS3url = "https://4amdemand-company-profile-image-kalravdev.s3.us-east-2.amazonaws.com/4AM+Demand+-+Budget+Template.csv"

export const integrateData = {
    StageMapping : "Stage Mapping",
    SegmentMapping : "Segment Mapping",
    Market : "Marketing Sourced Mapping",
    Campaign : "Campaign Mapping",
    PipelineMapping: "Pipeline and Revenue Mapping"
}