import { useState, useEffect } from "react";
import * as echarts from "echarts";
import { reduce } from "lodash";

const StackGroupedChart = (props) => {

  useEffect(() => {
    const chart = echarts.init(document.getElementById("myChart"),null,{
        renderer: 'svg'
      });

    const data = [
      {
        date: "01/23",
        Pipeline_Stack1: 800,
        Pipeline_Stack2: 430,
        Pipeline_Stack3: 620,
        Pipeline_Stack4: 400,
        Pipeline_Stack5: 200,
        Pipeline_Stack6: 300,
        
        Revenue_Stack1: 400,
        Revenue_Stack2: 350,
        Revenue_Stack3: 540,
        Revenue_Stack4: 250,
        forecast: false,
      },
      {
        date: "02/23",
        Pipeline_Stack1: 600,
        Pipeline_Stack2: 490,
        Pipeline_Stack3: 620,
        Pipeline_Stack4: 400,
        Pipeline_Stack5: 400,
        Revenue_Stack1: 730,
        Revenue_Stack2: 320,
        Revenue_Stack3: 420,
        Revenue_Stack4: 520,
        Revenue_Stack5: 100,
        forecast: false,
      },
      {
        date: "03/23",
        Pipeline_Stack1: 130,
        Pipeline_Stack2: 220,
        Revenue_Stack1: 180,
        Revenue_Stack2: 270,
        forecast: false,
      },
      {
        date: "04/23",
        Pipeline_Stack1: 100,
        Pipeline_Stack2: 200,
        Pipeline_Stack3: 300,
        Pipeline_Stack4: 200,
        Pipeline_Stack5: 300,
        Revenue_Stack1: 150,
        Revenue_Stack2: 250,
        Revenue_Stack3: 150,
        Revenue_Stack4: 350,
        forecast: false,
      },
      {
        date: "05/23",
        Pipeline_Stack1: 120,
        Pipeline_Stack2: 180,
        Revenue_Stack1: 200,
        Revenue_Stack2: 220,
        forecast: false,
      },
      {
        date: "06/23",
        Pipeline_Stack1: 130,
        Pipeline_Stack2: 220,
        Revenue_Stack1: 180,
        Revenue_Stack2: 270,
        forecast: false,
      },
      {
        date: "07/23",
        Pipeline_Stack1: 100,
        Pipeline_Stack2: 200,
        Pipeline_Stack3: 300,
        Pipeline_Stack4: 200,
        Pipeline_Stack5: 300,
        Revenue_Stack1: 150,
        Revenue_Stack2: 250,
        Revenue_Stack3: 150,
        Revenue_Stack4: 350,
        forecast: false,
      },
      {
        date: "08/23",
        Pipeline_Stack1: 120,
        Pipeline_Stack2: 180,
        Revenue_Stack1: 200,
        Revenue_Stack2: 220,
        forecast: false,
      },
      {
        date: "09/23",
        Pipeline_Stack1: 130,
        Pipeline_Stack2: 220,
        Revenue_Stack1: 180,
        Revenue_Stack2: 270,
        forecast: false,
      },
      {
        date: "10/23",
        Pipeline_Stack1: 150,
        Pipeline_Stack2: 240,
        Revenue_Stack1: 170,
        Revenue_Stack2: 230,
        forecast: true,
      },
      {
        date: "11/23",
        Pipeline_Stack1: 140,
        Pipeline_Stack2: 200,
        Revenue_Stack1: 190,
        Revenue_Stack2: 260,
        forecast: true,
      },
      {
        date: "12/23",
        Pipeline_Stack1: 180,
        Pipeline_Stack2: 230,
        Revenue_Stack1: 290,
        Revenue_Stack2: 260,
        forecast: true,
      },
    ];

    // Extracting dates and stack names
    const dates = data.map((item) => item.date);
    const stackNames = Object.keys(data[0]).filter((key) => key !== "date");
    console.log("stackNames", stackNames);
   

    const pipelineStackNamesSet = new Set();
    const revenueStackNamesSet = new Set();

    data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key.startsWith("Pipeline_")) {
            pipelineStackNamesSet.add(key);
          } else if (key.startsWith("Revenue_")) {
            revenueStackNamesSet.add(key);
          }
        });
      });

      const pipeline_colors = [
        "#9C2A10",
        "#C4320A",
        "#EC4A0A",
        "#FB6514",
        "#FD853A",
        "#FEB273",
      ];
      const revenue_colors = [
        "#53389E",
        "#6941C6",
        "#7F56D9",
        "#9E77ED",
        "#B692F6",
        "#D6BBFB",
      ];  

      const pipelineStackNames = Array.from(pipelineStackNamesSet);
    const revenueStackNames = Array.from(revenueStackNamesSet);

    const pipelineSeriesData = pipelineStackNames.map((stackName, index) => {
        const stackData = data.map((item, i) => {
          const color = item.forecast ? `${pipeline_colors[index % pipeline_colors.length]}95` : pipeline_colors[index % pipeline_colors.length];
          const itemStyle = {
            color: color,
          };
        
          // Conditionally add decal property
          if (item.forecast) {
            itemStyle.decal = {
              show:true,
                symbol: 'pin',
                symbolKeepAspect: true,
                color:'rgba(255,255,255,0.7)',
                
            };
          }
          return {
            value: item[stackName] || 0,
            itemStyle: itemStyle,
            
          };
        });
  
        return {
          name: stackName,
          type: "bar",
          stack: "pipeline",
          data: stackData,
          barWidth: "auto",
          barGap: "0",
        };
      });

      const revenueSeriesData = revenueStackNames.map((stackName, index) => {
        const stackData = data.map((item) => {
          const color = item.forecast ? `${revenue_colors[index % revenue_colors.length]}95` : revenue_colors[index % revenue_colors.length];
          const itemStyle = {
            color: color,
          };
        
          // Conditionally add decal property
          if (item.forecast) {
            itemStyle.decal = {
              show:true,

            symbol: 'pin',

symbolKeepAspect: true ,
color:'rgba(255,255,255,0.7)'
            };
          }
          return {
            value: item[stackName] || 0,
            itemStyle: itemStyle,
          };
        });
  
        return {
          name: stackName,
          type: "bar",
          stack: "revenue",
          data: stackData,
          barWidth: "auto",
          barGap: "0",
        };
      });


    const options = {
      grid: {
        type: "bar",
        left: 40,
        right: 30,
        top: 30,
        bottom: 90,
      },
      tooltip: {},
      xAxis: {
        type: "category",
        data: dates,

        position: "top",

        axisLine: {
          show: false, // Hiding the y-axis line
        },
        axisTick: {
          alignWithLabel: true,
        },

        axisLabel: {
          fontSize: 13,
          color: "#475467",
          fontWeight: 400,
        },
        splitLine: {
          show: true, // Set to true to display the grid lines
          lineStyle: {
            color: "#D0D5DD", // Customize the color of the grid lines
            width: 1, // Customize the width of the grid lines
            type: "solid",
            // Customize the type of the grid lines (dashed, solid, etc.)
          },
          // Calculate interval based on number of data points
          interval: 1,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontSize: 13,
          color: "#98A2B3",
          fontWeight: 400,
        },
        axisTick: {
          // Adding axis tick configuration
          show: true, // Showing axis ticks
        },
        splitLine: {
          // Add splitLine configuration for x-axis
          show: true, // Set to true to display grid lines
          lineStyle: {
            color: "#D0D5DD", // Customize the color of the grid lines
            width: 1, // You can specify the color of grid lines
            type: "solid",
          },
        },
        axisLine: {
          show: false, // Hiding the y-axis line
        },

        min: 0,
        max: 3000,
      },
      legend: false,

      series: [...pipelineSeriesData, ...revenueSeriesData],
      
      animation: true,
      dataZoom: [
        {
          type: "slider", // Enable slider for zooming
          start: 0, // Slider start position
          end: 80, // Slider end position
          bottom:30,
          height:40
        },
      ],
    };

    chart.setOption(options);

    // Resize the chart when the window size changes
    window.addEventListener("resize", () => {
        chart.resize();
      });

      return () => {
        window.removeEventListener("resize", () => {
          chart.resize();
        });
        chart.dispose();
      };
  }, []);

  return (
    // <div style={{width:'100%', overflow:'auto'}}>
    <div
      className="chart-container"
      
    >
      <div
        id="myChart"
        className="forcast_chart_appache"
        style={{ width: "100%", height: "700px" }}
      />
    </div>
    // </div>
  );
};

export default StackGroupedChart;
