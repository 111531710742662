import { Auth } from 'aws-amplify';
import {
  LOGOUT,
  UPDATE_LOGIN,
  UPDATE_COMPANY_ACCOUNT,
  SET_ACCOUNT_DETAILS,
  SET_PERMISSION,
  SET_VIEW_TYPE,
  SET_ACCOUNT_REQUESTS,
  SET_DASHBOARD_TOUR_OPEN,
} from './index';
import { ExploricApi } from '../../utility/Api';
import { message } from 'antd';
// import {tieringAccess} from "../../../src/utility/Constants"
// import {setTieringInfo} from "../actions/reportAction"

export const login = (userName, password) => {
  return async (dispatch, getState) => {
    let response = await Auth.signIn(userName, password);
    
    if (response?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return response;
    }

    const { idToken } = await Auth.currentSession();
    dispatch(setPermission(idToken.payload));
    let userAccountId = [];
    if ('cognito:groups' in response.signInUserSession.accessToken.payload) {
      userAccountId =
        response.signInUserSession.accessToken.payload['cognito:groups'];
      if (
        userAccountId.includes('companyadmin') ||
        userAccountId.includes('companycontributor')
      ) {
        const adminIndex = userAccountId.indexOf('companyadmin');
        const contributorIndex = userAccountId.indexOf('companycontributor');

        const targetIndex = adminIndex !== -1 ? adminIndex : contributorIndex;
        if (targetIndex !== -1) {
          userAccountId.push(userAccountId.splice(targetIndex, 1)[0]);
        }
      }
      userAccountId.push(
        userAccountId.splice(userAccountId.indexOf('admin'), 1)[0]
      );
      if (userAccountId?.includes('stage')) {
        userAccountId.splice(userAccountId.indexOf('stage'), 1);
        userAccountId.push('stage');
      }
      // if(window.location.host === "stage.4amdemand.com" && !userAccountId?.includes('stage')){
      //   message.warning("Unable to login please connect admin!")
      //   localStorage.clear()
      //   return
      //   }
      let accountDetails = await ExploricApi.getAccointApi(userAccountId[0]);

      window?.freshpaint?.identify(idToken?.payload?.email, {
        email: idToken?.payload?.email,
        name: `${idToken?.payload?.given_name} ${idToken?.payload?.family_name}`,
        company: `${accountDetails?.data?.getAccount?.company}`,
      });

      window?.freshpaint?.group(accountDetails?.data?.getAccount?.company, {
        company: accountDetails?.data?.getAccount?.company,
        plan: accountDetails?.data?.getAccount?.plan?.name,
        create_date: accountDetails?.data?.getAccount?.createdAt,
      });

      console.log("accountDetails",accountDetails);

      dispatch(setAccountDetails(accountDetails.data.getAccount));
    }
    dispatch(setLoginData(response.signInUserSession, response.attributes));
    return response;
  };
};

export const getCustomerAccountRequests = () => (dispatch) => {
  ExploricApi.getAccountRequests()
    .then((response) => {
      dispatch(setAccountRequests(response.data?.listAccountRequests?.items));
    })
    .catch((e) => console.log(e));
};

export const getaccountDetails = (inputdata) => (dispatch) => {
  return ExploricApi.getAccointApi(inputdata)
    .then((response) => {
      dispatch(setAccountDetails(response.data.getAccount));
      return response;
    })
    .catch((err) => console.log(err));
};

export const updateAccount = (inputdata) => (dispatch) => {
  
  return ExploricApi.updateAccountDetails(inputdata)
    .then((data) => {
      dispatch(setAccountDetails(data?.data?.updateAccount));
    })
    .catch((err) => console.log(err));
};

export const updateAccountCompanyInfo = (inputdata) => (dispatch) => {
  return ExploricApi.updateAccountDetails(inputdata)
    .then((data) => {
      dispatch(setAccountDetails(data?.data?.updateAccount));
      message.success('Company data updated successfully');
    })
    .catch((err) => console.log(err));
};

export const UpdateTourData =
  (account, accountMetadata, update, user, accountOldData) =>
  async (dispatch) => {
    if (user) {
      let metaData = {
        ...accountMetadata,
      };
      metaData[update] = true;
      let data = { ...accountOldData };
      data[user] = metaData;
      let inputData = {
        id: account[0],
        metadata: JSON.stringify(data),
      };
      await dispatch(updateAccount(inputData));
      await dispatch(getaccountDetails(account[0]));
    }
  };

export const UpdateAccountMetaData = () => async (dispatch) => {};

export const setAccountRequests = (inputdata) => {
  return {
    type: SET_ACCOUNT_REQUESTS,
    payload: inputdata,
  };
};

export const setLoginData = (token, userData) => {
  return {
    type: UPDATE_LOGIN,
    payload: token,
    data: userData,
  };
};

export const setPermission = (data) => {
  return {
    type: SET_PERMISSION,
    payload: data,
  };
};

export const updateCompanyAccount = (data) => {
  return {
    type: UPDATE_COMPANY_ACCOUNT,
    payload: data,
  };
};

export const setViewType = (data) => {
  return {
    type: SET_VIEW_TYPE,
    payload: data,
  };
};

export const setAccountDetails = (data) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: data,
  };
};

export const setDashboardTourOpen = (data) => {
  return {
    type: SET_DASHBOARD_TOUR_OPEN,
    payload: data,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
