import * as actions from "../actions/index";

const initialState = {
  isLoading: false,
  SDRCalculatorValues : [],
  SDRCompensationModels : [],
  SDRActivityTrackerList : [],
  SDRCalculatorSummary:{},
  SDRActuals:{}
};

const sdrReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actions.SHOW_LOADER:
        return { ...state, isLoading: action.payload };

    case actions.SET_CALCULATOR_INFO:
        return { ...state, SDRCalculatorValues: action.payload };

    case actions.SET_SDR_COMPENSATION_MODELS:
        return { ...state, SDRCompensationModels : action.payload };

    case actions.SET_SDR_CALCULATOR_SUMMARY:
        return { ...state, SDRCalculatorSummary : action.payload };

    case actions.SET_SDR_ACTIVITY_TRACKER_DATA:
        return { ...state, SDRActivityTrackerList : action.payload };

    case actions.SET_SDR_ACTUAL_DATA:
        return { ...state, SDRActuals : action.payload };

    default:
      return state;
  }
};

export default sdrReducer;
