import {
  SET_CREATED_COURSE_CATEGORY_DATA,
  SET_CREATED_COURSE_DATA,
  SET_CREATED_COURSE_CATEGORY_CONNECTION_DATA,
  SET_COURSE_LIST,
  SET_COURSE_CATEGORY_LIST,
  SET_CATEGORY_COURSE_LIST,
  SHOW_LOADER,
  SERVER_ERROR,
} from "./index";
import { ExploricApi } from "../../utility/Api";
import { message } from "antd";

export const setCreateCourseCateoryData = (data) => {
  return {
    type: SET_CREATED_COURSE_CATEGORY_DATA,
    payload: data,
  };
};

export const setLoader = (data) => {
  return {
    type: SHOW_LOADER,
    payload: data,
  };
};

export const setCreateCourseData = (data) => {
  return {
    type: SET_CREATED_COURSE_DATA,
    payload: data,
  };
};

export const setCreateCourseCategoryConnectionData = (data) => {
  return {
    type: SET_CREATED_COURSE_CATEGORY_CONNECTION_DATA,
    payload: data,
  };
};

export const setCourseList = (data) => {
  return {
    type: SET_COURSE_LIST,
    payload: data,
  };
};

export const setCourseCategoryList = (data) => {
  return {
    type: SET_COURSE_CATEGORY_LIST,
    payload: data,
  };
};

export const setCategoryCourseList = (data) => {
  return {
    type: SET_CATEGORY_COURSE_LIST,
    payload: data,
  };
};

export const setServerError = (data) => {
  return {
    type: SERVER_ERROR,
    payload: data,
  };
};

export const createCourseCategory =
  (inputData, courseId) => (dispatch, getState) =>
    ExploricApi.createCourseCategoryAPI(inputData)
      .then((response) => {
        const { createdCourseCategoryData } = response.data;
        dispatch(setCreateCourseCateoryData(createdCourseCategoryData));
        // const account = getState().loginReducer.groups;
        dispatch(
          createCourseCategoryConnection({
            // account: account[0],
            courseCategoryConnectionCourseCategoryId:
              response.data.createCourseCategory.id,
            courseCategoryConnectionCourseId: courseId,
          })
        );

        return createdCourseCategoryData;
      })
      .catch((e) => {
        console.log("error :", e);
      });

const deletePreviousCourseCategory = async (data) => {
  Promise.all(
    data.map((preCategoryVal) => {
      ExploricApi.deleteCourseCategoryConnectionApi({ id: preCategoryVal.id });
      return true;
    })
  );
};

const createCategoryConnections = async (
  data,
  inputData,
  response,
  CourseCategoryList,
  account,
  dispatch
) => {
  Promise.all(
    data.map((categoryVal) => {
      if (
        !CourseCategoryList.listCourseCategorys.items.find(
          (categoryValList) => categoryValList.id === categoryVal
        )
      ) {
        dispatch(
          createCourseCategory(
            { name: categoryVal },
            inputData.id
              ? response.data.updateCourse.id
              : response.data.createCourse.id
          )
        );
      } else {
        dispatch(
          createCourseCategoryConnection({
            // account: account[0],
            courseCategoryConnectionCourseCategoryId: categoryVal,
            courseCategoryConnectionCourseId: inputData.id
              ? response.data.updateCourse.id
              : response.data.createCourse.id,
          })
        );
      }
      return true;
    })
  )
    .then((res) => {
      if (inputData.id) {
        message.success("Course updated successfully");
      } else {
        message.success("Course created successfully");
      }
    })
    .catch((error) => {
      message.error(`${error}`);
    });
};

export const createCourse =
  (inputData, inputDataObjCateory) => (dispatch, getState) => {
    const courseOperation = inputData.id
      ? ExploricApi.updateCourseAPI
      : ExploricApi.createCourseAPI;
    dispatch(setLoader(true));
    courseOperation(inputData)
      .then((response) => {
        const createdCourseData = response.data;
        dispatch(setCreateCourseData(createdCourseData));
        const { CourseCategoryList } = getState().coachingReducer;
        const account = getState().loginReducer.groups;
        if (inputData.id) {
          Promise.all(
            inputDataObjCateory.previousCourseVideos?.map((preVideos) => {
              ExploricApi.deleteCourseVideoApi({ id: preVideos.id });
              return true;
            })
          )
            .then((response) => {
              inputDataObjCateory?.items.map(function (videoDetails, index) { // eslint-disable-line

                if (videoDetails?.video_name) {
                  dispatch(
                    createCourseVedeo({
                      courseId: inputData.id,
                      title: videoDetails?.video_title,
                      description: videoDetails?.video_description,
                      name: videoDetails?.video_name,
                      duration: videoDetails?.video_duration,
                    })
                  );
                }
              });
            })
            .catch((e) => {
              console.log("error :", e);
            });
        } else {
          inputDataObjCateory?.items.map(function (videoDetails, index) { // eslint-disable-line

            if (videoDetails?.video_name) {
              dispatch(
                createCourseVedeo({
                  courseId: createdCourseData.createCourse.id,
                  title: videoDetails?.video_title,
                  description: videoDetails?.video_description,
                  name: videoDetails?.video_name,
                  duration: videoDetails?.video_duration,
                })
              );
            }
          });
        }
        if (inputData.id) {
          deletePreviousCourseCategory(
            inputDataObjCateory.previousCategories.items
          );
        }

        createCategoryConnections(
          inputDataObjCateory.course_categories,
          inputData,
          response,
          CourseCategoryList,
          account,
          dispatch
        );

        dispatch(setLoader(false));
        dispatch(setServerError("success"));
        return createdCourseData;
      })
      .catch((error) => {
        console.log(error);
        message.error(`${error.errors[0].errorType}`);
        dispatch(setLoader(false));
      });
  };

export const createCourseCategoryConnection =
  (inputData) => (dispatch, getState) =>
    ExploricApi.createCourseCategoryConnectionAPI(inputData)
      .then((response) => {
        const { createdCourseCategoryConnectionData } = response.data;
        dispatch(
          setCreateCourseCategoryConnectionData(
            createdCourseCategoryConnectionData
          )
        );
        dispatch(getCourseList());
        dispatch(getCourseCategoryList());
        return createdCourseCategoryConnectionData;
      })
      .catch((e) => {
        console.log("error :", e);
      });

export const createNewCourse = (inputData) => (dispatch, getState) => {
  const createCourseObj = {
    name: inputData.course_name,
    slug: inputData.slug,
    short_description: inputData.short_description,
    description: inputData.description,
    featuredimage: inputData.imageFileKey,
    attachments: inputData.attachmentFileKey,
  };
  if (inputData.id) createCourseObj.id = inputData.id;
  dispatch(getCourseList());
  const courseMasterList =
    getState().coachingReducer.CourseList.listCourses.items;
  const findCourseMatch = courseMasterList.find(
    (courseListMasterVal) =>
      courseListMasterVal.name.trim().toLowerCase() ===
      inputData.course_name.trim().toLowerCase()
  );
  if (!findCourseMatch || inputData.id) {
    return dispatch(createCourse(createCourseObj, inputData));
  } else {
    message.error(`Course name already exist!`, 5);
    return dispatch(setServerError("failed"));
  }
};

export const getCourseList = (filter) => (dispatch, getState) => {
  dispatch(setLoader(true));
  ExploricApi.getCoursesListApi(filter)
    .then((response) => {
      const courseList = response.data;
      dispatch(setCourseList(courseList));
      dispatch(setLoader(false));
      return courseList;
    })
    .catch((e) => {
      console.log("error :", e);
      dispatch(setLoader(false));
    });
  }

export const getCategoryCourseList = () => (dispatch, getState) =>
  ExploricApi.getCategoryCoursesListApi()
    .then((response) => {
      const categoryCourseList = response.data;
      dispatch(setCategoryCourseList(categoryCourseList));
      return categoryCourseList;
    })
    .catch((e) => {
      console.log("error :", e);
    });

export const getCourseCategoryList = () => (dispatch, getState) =>
  ExploricApi.getCoursesCategoryApi()
    .then((response) => {
      const courseCategoryList = response.data;
      dispatch(setCourseCategoryList(courseCategoryList));
      return courseCategoryList;
    })
    .catch((e) => {
      console.log("error :", e);
    });

export const deleteCourse = (courseDetails) => (dispatch, getState) => {
  dispatch(setLoader(true));
  ExploricApi.deleteCourseApi({ id: courseDetails.id })
    .then((response) => {
      deletePreviousCourseCategory(courseDetails.categories.items);
      message.success("Course deleted successfully");
      dispatch(getCourseList());
      dispatch(setLoader(false));
      return response;
    })
    .catch((e) => {
      console.log("error :", e);
      dispatch(setLoader(false));
    });
};

export const createCourseVedeo = (inputData) => (dispatch, getState) =>
  ExploricApi.createCourseVideoApi(inputData)
    .then((response) => {
      const createdCourseVidosData = response.data;
      return createdCourseVidosData;
    })
    .catch((e) => {
      console.log("error :", e);
    });
