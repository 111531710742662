/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  Row,
  Typography,
  Modal,
  Button,
  Col,
  InputNumber,
  message,
  Select,
  Skeleton
} from "antd";
import BaselineCompanyMetrics from "./BaselineCompanyMetrics";
import FunnelConversionRates from "./FunnelConversionRates";
import {
  createFiscalYear,
  updateFiscalYear,
  getFiscalYear,
  setFiscalYear,
  getFiscalYearsList,
  createBaseLineMetric,
  createFunnelStages,
  createFunnelConfiguration,
  setFunnelStagesList,
  getCalculationMetrics,
} from "store/actions/revenueAction";
import {
  QuestionCircleOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import revenuePlannigImg from "../../assets/Images/undraw_revenue_planning.svg";
import revenue_planning_module_preview from "../../assets/Images/revenue_planning_module_preview.png";
import "./RevenuePlanning.less";
import Tour from "reactour";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import _ from "lodash";

import PreviewInfo from "commons/PreviewInfo";
import { setLoader } from "store/actions/coachingAction";
import { getBudgetList } from "store/actions/budgetAction";
import { UpdateTourData } from "store/actions/loginAction";
import { getSalesAndMarketingStages } from "store/actions/SalesAndMarketingAction";
import { TrackClickFunction } from "../../commons/HeapTrack/TrackClickFunction";
import sdk from "sdk/Accounts";

const yearOptions = [
  new Date().getFullYear() - 1,
  new Date().getFullYear(),
  new Date().getFullYear() + 1,
]

const RevenuePlanning = (props) => {
  const [isFunnelChanges, setisFunnelChanges] = useState(false);
  const [isShowModal, setisShowModal] = useState(false);
  const [nextLocation, setnextLocation] = useState(null);
  const [confirmedNavigation, setconfirmedNavigation] = useState(false);
  const [annualRevenueGoal, setannualRevenueGoal] = useState(null);
  const [selectedFiscalYear, setselectedFiscalYear] = useState();
  const [dropDownYearList, setDropDownYearList] = useState([]);
  const [lastStageName, setlastStageName] = useState("");
  
  // const [createDefaultBudgetModalOpen, setcreateDefaultBudgetModalOpen] = useState(false)
  const [anualGoalSaveRemaning, setanualGoalSaveRemaning] = useState(false);

  const dispatch = useDispatch();
  const revenueData = useSelector((state) => state.revenueReducer);
  const {
    fiscalYearList,
    fiscalYear,
    funnelStages,
    marketingSourced,
    averageDealSize,
    newRevenueGoal,
  } = revenueData;
  const {
    userPermission,
    viewType,
    groups: account,
    accountDetails,
    userData,
  } = useSelector((state) => state.loginReducer);
  const { salesAndMarketingStages } = useSelector(
    (state) => state.salesAndMarketingReducer
  );
  const [funnelStageCustom, setfunnelStageCustom] = useState([]);
  const [acct, setAcct] = useState(null);
  const [loading, setLoading] = useState(true);
 
  console.log("fiscalYear",fiscalYear);
  useEffect(async () => {
    // setDropDownYearList([
    //   new Date().getFullYear() - 1,
    //   new Date().getFullYear(),
    //   new Date().getFullYear() + 1,
    // ]);
    (async () => {
      
        if(fiscalYearList?.length > 0){
          
          let fiscalYears = await dispatch(
            getFiscalYearsList({ account: { eq: acct?.id } })
          );
        console.log("fiscalYears", fiscalYears);
          let dropdownYears = fiscalYears?.items
            ?.map((item, index) => {
              return item.year;
            })
            ?.sort();
          
          // if (!dropdownYears?.includes(selectedFiscalYear)) {
          //   // New Logic
          //   const currentYear = new Date().getFullYear();
          //   let newYear = dropdownYears?.at(-1) ?? selectedFiscalYear;
          //   if (dropdownYears?.includes(currentYear)) {
          //     newYear = currentYear;
          //   }

          //   setselectedFiscalYear(newYear);
          //   handleFiscalYearChange(newYear);
          // }
          
            
          
          
          if(acct?.fiscalYearMonth === 1 || acct?.fiscalYearMonth == null){
            
            let prevYear = new Date().getFullYear() - 1;
            let currYear = new Date().getFullYear();
            let nextYear = new Date().getFullYear() + 1;

            
            if(!dropdownYears?.includes(prevYear)){
              dropdownYears.push(prevYear)
            }
            if(!dropdownYears?.includes(currYear)){
              dropdownYears.push(currYear)
            }
            if(!dropdownYears?.includes(nextYear)){
              dropdownYears.push(nextYear)
            }
            dropdownYears.sort();
            setselectedFiscalYear(currYear);
            handleFiscalYearChange(currYear);
            setLoading(false);
          }  
          
          if(acct?.fiscalYearMonth > 1 ){
            let currYear = new Date().getFullYear() + 1;
            let prevYear = currYear - 1;
            
            let nextYear = currYear + 1;

            if(!dropdownYears?.includes(prevYear)){
              dropdownYears.push(prevYear)
            }
            if(!dropdownYears?.includes(currYear)){
              dropdownYears.push(currYear)
            }
            if(!dropdownYears?.includes(nextYear)){
              dropdownYears.push(nextYear)
            }
            dropdownYears.sort();
            setselectedFiscalYear(currYear)
            handleFiscalYearChange(currYear);
            setLoading(false);
          }  
          
          setDropDownYearList(dropdownYears);
          console.log("dropDownYearList", dropDownYearList);
          setLoading(false);
        }else{
          setDropDownYearList(yearOptions);
          setLoading(false);
        }    
      

      // dispatch(getAccountStageMappingList({ account: { eq: account[0] } }));
      dispatch(getSalesAndMarketingStages(account));
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acct]);

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = account.length > 0 ? account[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);

      setAcct(account);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const fiscalYearListRef = useRef(fiscalYearList);
  if (!_.isEqual(fiscalYearListRef.current, fiscalYearList)) {
    fiscalYearListRef.current = fiscalYearList;
  }

  const [getFiscalYearListLoader, setgetFiscalYearListLoader] = useState(true);

  useEffect(() => {
    dispatch(getFiscalYearsList())
      .then((res) => {
        setgetFiscalYearListLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setgetFiscalYearListLoader(false);
      });
    dispatch(getBudgetList()); // callled this function to check on scoping to update reducer value which is used to check on first create budget and create default channel
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem("FiscalYear") && fiscalYear && fiscalYear?.year) {
      handleFiscalYearChange(fiscalYear.year);
    } else {
      handleFiscalYearChange(new Date().getFullYear());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYearListRef.current]);
  console.log("fiscalYearList",fiscalYearList);
  const handleFiscalYearChange = async (year) => {
    
    console.log("year", year);
    if (fiscalYearList.length) {
      var fy = fiscalYearList.filter((item) => item.year === year);
      if (fy.length > 0) {
        await localStorage.setItem("FiscalYear", window.btoa(fy[0]?.id));
        await dispatch(getFiscalYear(fy[0]?.id));
      } else {
        setannualRevenueGoal();
        await dispatch(setFiscalYear());
      }
    } else {
      setannualRevenueGoal(null);
      await dispatch(setFiscalYear());
    }
    await setselectedFiscalYear(year);
    
  };

  useEffect(() => {
    setannualRevenueGoal(fiscalYear.budget);
    //setselectedFiscalYear(fiscalYear.year);
  }, [fiscalYear]);

 
  
  const handleSave = async (data = false) => {
    !data && Segments === 1 && setsaveSegmentClicked(true);

    if (!annualRevenueGoal) {
      !annualRevenueGoal && message.error("Please enter ammount");
    } else {
      var currentYearExist = fiscalYearList.filter(
        (fiscalyear) => fiscalyear.year === selectedFiscalYear
      );
      var requestData = {
        year: selectedFiscalYear,
        budget: annualRevenueGoal,
        account: account[0],
        startMonth: acct?.fiscalYearMonth ? acct?.fiscalYearMonth : 1,
      };

      
      if (currentYearExist.length !== 0) {
        requestData = { ...requestData, id: currentYearExist[0].id };
        
        await dispatch(updateFiscalYear(requestData));
        await localStorage.setItem("FiscalYear", window.btoa(fiscalYear?.id));
      } else {
        let response = dispatch(createFiscalYear(requestData));
        response
          .then((data) => {
            createbasicbaseline(data);
          })
          .catch((error) => console.log("Error :", error));
        await localStorage.setItem("FiscalYear", window.btoa(fiscalYear?.id));
      }
      handleFunnelChanges(false);
      setanualGoalSaveRemaning(false);
      localStorage.removeItem("RevenueTourFinshed");
    }
    setsaveSegmentClicked(false);
    setremoveQuarterwiseData(false);
  };

  // const [defaultBudgetCreateData, setdefaultBudgetCreateData] = useState()

  const createbasicbaseline = async (data) => {
    let fiscalYearId = data?.createFiscalYear?.id;
    let Year = data?.createFiscalYear?.year;

    let requestData = {
      name: `${Year} Goal`,
      fiscalYearId: fiscalYearId,
      account: account[0],
      mrktSourcePercent: 50,
      segment_type: "OTHER",
      goal: data?.createFiscalYear?.budget,
    };
    let metricData = dispatch(createBaseLineMetric(requestData));
    metricData
      .then(async (response) => {
        // setdefaultBudgetCreateData(response)
        let funnelConfigData = await createFunnelConfig(response?.id);
        let funnelStage = await createFunnel(funnelConfigData?.id);
        if (funnelStage?.length > 0) {
          await dispatch(setFunnelStagesList(funnelStage));
        }
        await dispatch(getFiscalYear(fiscalYearId));
      })
      .catch((e) => console.log("error: ", e));
    dispatch(setLoader(true));
  };

  const createFunnelConfig = (metricId) => {
    const requestData = {
      baselineMetricId: metricId,
      name: "FunnelConfig",
      account: account[0],
    };

    return dispatch(createFunnelConfiguration(requestData))
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e;
      });
  };

  const createFunnel = async (id) => {
    let apiCall = [];
    funnelStageCustom.map((item, index) => {
      let requestData = {
        funnelConfigID: id,
        order: item?.order,
        name: item?.name,
        conversion: 50,
        account: account[0],
        salesAndMarketingAlignmentID: item?.salesAndMarketingAlignmentID,
      };
      apiCall.push(requestData);
      return 0;
    });
    return Promise.all(
      apiCall.map((item) => dispatch(createFunnelStages(item)))
    ).then((response) => {
      return response;
    });
  };

  useEffect(() => {
    let customStages = [];
    customStages = salesAndMarketingStages
      ?.map((stage) => {
        if (!stage.disabled && stage.internalStage !== "Closed Lost") {
          return {
            name: stage?.name,
            conversion: 50,
            salesAndMarketingAlignmentID: stage?.id,
            order: stage?.order,
          };
        }
      })
      .filter((item) => item !== undefined);

    let last = salesAndMarketingStages?.find(
      (stage) => stage?.internalStage === "Closed Won"
    );
    let incustom = customStages?.find(
      (stage) => stage.salesAndMarketingAlignmentID === last?.id
    );
    let maxorder = 0;
    let max = {};
    customStages?.forEach((stage) => {
      if (maxorder < stage.order) {
        maxorder = stage.order;
        max = stage;
      }
    });

    if (incustom) {
      let currentorder = customStages?.indexOf(incustom);
      let maximum = customStages?.indexOf(max);
      customStages[maximum].order = customStages[currentorder].order;
      customStages[currentorder].order = maxorder;
    } else {
      customStages?.push({
        name: last?.name,
        conversion: 50,
        salesAndMarketingAlignmentID: last?.id,
        order: maxorder,
      });
    }

    customStages?.sort(sortingOrder);
    setfunnelStageCustom(customStages);
    setlastStageName(last?.name ? last?.name : "Closed Won");
  }, [funnelStages, salesAndMarketingStages]);

  const sortingOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };
  const handleFunnelChanges = (value) => {
    setisFunnelChanges(value);
  };

  const showModal = (nextLocation) => {
    setisShowModal(true);
    setnextLocation(nextLocation);
    setconfirmedNavigation(true);
  };

  const onLeave = () => {
    setisShowModal(false);
    props.history.push(nextLocation);
  };

  const onStay = () => {
    setisShowModal(false);
    setconfirmedNavigation(false);
    document.getElementById("revenuePlanning").click();
  };

  const preventNavigation = (nextLocation) => {
    const { location } = props;

    if (location.pathname === nextLocation.pathname) {
      return false;
    }

    if (confirmedNavigation) {
      return true;
    }

    showModal(nextLocation.pathname);
    return false;
  };

  // const steps = [
  //   {
  //     selector: '[data-tour = "tour__fiscalYear"]',
  //     content: () => (
  //       <div>
  //         <Title className="fuel-title" level={4}>
  //           New Revenue Goal
  //         </Title>
  //         <Paragraph>
  //           {" "}
  //           Set your overall new revenue goal for the year here{" "}
  //         </Paragraph>
  //       </div>
  //     ),
  //     action: (node) => {
  //       node?.scrollIntoView(false);
  //       node?.focus();
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__createMetric"]',
  //     content: () => (
  //       <div>
  //         <Title level={4}>Additional Segments (if applicable)</Title>
  //         <Paragraph>
  //           {" "}
  //           If your revenue goal needs to be split across different products,
  //           regions, marketing personas, etc, create new segments here
  //         </Paragraph>
  //       </div>
  //     ),
  //     action: (node) => {
  //       node?.focus();
  //       node?.scrollIntoView({ block: "center", inline: "nearest" });
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__baselineMetrics"]',
  //     content: () => (
  //       <div>
  //         <Title level={4}>Segment</Title>
  //         <Paragraph>
  //           For a given segment, set average deal size and adjust the slider to
  //           indicate the percentage of revenue tied to your marketing efforts{" "}
  //         </Paragraph>
  //       </div>
  //     ),
  //     action: (node) => {
  //       node?.focus();
  //       node?.scrollIntoView({ block: "nearest", inline: "nearest" });
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour_funnelConfig"]',
  //     content: () => (
  //       <div>
  //         <Title level={4}>Funnel Configuration</Title>
  //         <Paragraph>
  //           {" "}
  //           Adjust the funnel sliders to indicate your anticipated conversion
  //           rates from one stage to the next
  //         </Paragraph>
  //       </div>
  //     ),
  //     action: (node) => {
  //       node?.focus();
  //       node?.scrollIntoView({ block: "center", inline: "nearest" });
  //     },
  //   },
  // ];

  // eslint-disable-next-line no-unused-vars
  const [c, setc] = useState(1);

  // const createDefaultBudget = () => {
  //     let budgetAllocation = {}

  //     let perQuarterBudget = fiscalYear?.budget/4
  //     let quarterlydiffrence = parseInt(perQuarterBudget) * 4
  //     if (quarterlydiffrence !== perQuarterBudget) {
  //       let Budget = {
  //         budget_q1 : parseInt(perQuarterBudget),
  //         budget_q2 : parseInt(perQuarterBudget),
  //         budget_q3 : parseInt(perQuarterBudget),
  //         budget_q4 : parseInt(perQuarterBudget)
  //       }
  //       if (fiscalYear?.budget - parseInt(quarterlydiffrence) === 1) {
  //           Budget.budget_q4 += 1
  //       }
  //       if (fiscalYear?.budget - parseInt(quarterlydiffrence) === 2) {
  //           Budget.budget_q4 += 1
  //           Budget.budget_q3 += 1
  //       }
  //       if (fiscalYear?.budget - parseInt(quarterlydiffrence) === 3) {
  //           Budget.budget_q4 += 1
  //           Budget.budget_q3 += 1
  //           Budget.budget_q2 += 1
  //       }
  //       budgetAllocation = JSON.stringify(Budget)
  //   }
  //   let budgetReqData = {
  //       BaselineMetricID: [defaultBudgetCreateData?.id],
  //       account: account[0],
  //       active: false,
  //       budget: defaultBudgetCreateData?.goal,
  //       budget_allocation:budgetAllocation,
  //       name: defaultBudgetCreateData?.name,
  //       year: selectedFiscalYear,
  //   }
  //   let response =  dispatch(createBudget(budgetReqData))
  //   response.then(data => {
  //     setcreateDefaultBudgetModalOpen(false)
  //   }).catch(error => {
  //     console.log("Error:", error);
  //     message.error("Something went wrong")
  //     setcreateDefaultBudgetModalOpen(false)
  //   })
  // }

  const [accountMetadata, setaccountMetadata] = useState({});

  useEffect(() => {
    let metadata = JSON.parse(accountDetails?.metadata)?.[userData.sub];
    accountDetails?.metadata && setaccountMetadata(metadata);
  }, [accountDetails, userData]);

  // useEffect(() => {
  //   if (account?.[2] !== "admin")
  //     setIsTourOpen(!accountMetadata?.RevenueTourFinish);
  // }, [accountMetadata]);

  const updateTour = () => {
    dispatch(
      UpdateTourData(
        account,
        accountMetadata,
        "RevenueTourFinish",
        userData?.sub,
        JSON.parse(accountDetails?.metadata)
      )
    );
  };

  const [Segments, setSegments] = useState(0);
  const setNumberOfSegments = (value) => {
    setSegments(value);
    if (value === 1) {
    }
  };

  useEffect(() => {
    if (Segments === 1) setannualRevenueGoal(newRevenueGoal);
    if (Segments === 0) setannualRevenueGoal(fiscalYear?.budget);
  }, [newRevenueGoal, Segments]);

  const [saveSegmentClicked, setsaveSegmentClicked] = useState(false);
  const [removeQuarterwiseData, setremoveQuarterwiseData] = useState(false);
  const [multiSelected, setMultiSelected] = useState(false);
  const [isSelectCheckbox, setIsSelectCheckbox] = useState(false);

  const handleMultiSelectedChange = (newValue) => {
    setMultiSelected(newValue);
  };
  const handleEmptySelect = (newValue) => {
    setIsSelectCheckbox(newValue);
  };

  return (
    <div id={"revenue_planning"}>
      {/* {isLoading && <div className="loading">Loading;</div>} */}
      <Prompt message={preventNavigation} when={isFunnelChanges} />
      <Modal
        className="preventNavModal"
        visible={isShowModal}
        closable={false}
        footer={[
          <Button
            key="back"
            onClick={onLeave}
            
            className="secondaryButton"
          >
            Leave Anyway
          </Button>,
          <Button key="stay" type="primary" onClick={onStay} className="primaryButton">
            Stay
          </Button>,
        ]}
      >
        <Row>
          <Col span={2}>
            <Typography.Title level={4}>
              <QuestionCircleOutlined style={{ color: "#faad14" }} />
            </Typography.Title>
          </Col>
          <Col span={22}>
            <Typography.Title level={5}>
              Are you sure you want to leave?
            </Typography.Title>
            <Typography.Text type="secondary">
              You have unsaved changes on this page. Leaving this page without
              saving may result in your changes being lost.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>

      <Row
        className="aboutPage panel-design"
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          marginBottom: "20px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
          padding: "30px 20px",
        }}
      >
        <Col
          className="details"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={24}
          lg={24}
          md={24}
          xs={24}
          sm={24}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{flex:1}}>
              <Typography.Text
                className="title fw700 headerMain"
                style={{
                  
                  color: "#1D2939",
                  marginBottom: "15px",
                  display: "block",
                }}
              >
                Revenue Planning
              </Typography.Text>
              <Typography.Text
                style={{ color: "#475467"}}
                className="subHeaderMain"
              >
                Establish how your Sales and Marketing team will hit your
                company's planned <br /> revenue growth goals by building and
                visualizing your marketing funnel.
              </Typography.Text>
            </div>
            {/* <Button
              className="take-a-tour-btn non-print primaryButton"
              style={{ height: "45px" }}
              onClick={() => {
                TrackClickFunction(
                  "demand-gen-revenue-planning-take-a-tour-button"
                );
                setIsTourOpen(true);
              }}
              data-heap="demand-gen-revenue-planning-take-a-tour-button"
            >
              Take a tour
            </Button> */}
          </div>
        </Col>

        {/* <Col span={12}
              lg={12}
              md={12}
              xs={24}
              sm={24} >
          <Row justify="end">
          <img src={revenuePlannigImg} alt="" />
          </Row>
        </Col> */}
      </Row>
       
      <Row
        style={{
          display: "flex",
          justifyContent: "left",
          background: "#fff",
          padding: "20px 15px",
          margin: "0 auto",
          width: "100%",
          borderRadius: 7,
          marginBottom: "20px",
        }}
        data-tour="tour__fiscalYear"
        className="p-10 panel-design"
      >
        <Col
          span={24}
          style={{
            padding: "15px 0px 15px 0",
            borderBottom: "1px solid #D0D5DD",
            
            fontStyle: "normal",
            fontWeight: "bold",
            marginBottom: "15px",
          }}
          className="revenue-sub-title header2"
        >
          <strong style={{ color: "#F04438" }}>Step 1</strong>
          <strong style={{ color: "#1D2939", fontWeight: "700" }}>
            {" "}
            - Set Your Annual Revenue Goal{" "}
          </strong>{" "}
        </Col>
         
        <Col span={24}>
          <Row justify="center" align="center" style={{ margin: "20px auto" }}>
            <Col span={8}>
              <Typography.Text
                strong
                style={{
                  fontSize: 24,
                  color: "#7F56D9",
                  marginBottom: "30px",
                  display: "inline-block",
                }}
              >
                Your Annual Target Of Revenue
              </Typography.Text>
            </Col>

            <Col span={24}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "15px 0 0 0 ",
                }}
              >
                {loading ? <div>
                            <Skeleton.Button
                              active
                              size="small"
                              style={{ marginBottom: 20, width:"150px" }}
                            />{" "}
                            <Skeleton.Button active size="small" block style={{marginRight:'15px'}}/>{" "}
                          </div> : (
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={4}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography.Text
                    className="text_label"
                    style={{ marginLeft: 6, color: "#1D2939", fontWeight: 500 }}
                  >
                    Select Fiscal Year
                  </Typography.Text>
                  <Select
                    className="yearSelector general-input-select"
                    size="large"
                    style={{ width: "97%", color: "#1D2939" }}
                    defaultValue={
                      dropDownYearList?.includes(selectedFiscalYear)
                        ? selectedFiscalYear
                        : dropDownYearList?.at(0)
                    }
                    // defaultValue={
                    //   selectedFiscalYear
                    // }
                    
                    value={selectedFiscalYear}
                    onChange={(e) => {
                      handleFiscalYearChange(e);
                    }}
                    placeholder="Select year"
                    disabled={loading}
                  >
                    
                    {dropDownYearList &&
                      dropDownYearList.length > 0 &&
                      dropDownYearList.map((item, indexval) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Col>
                )}
                {loading ? 
                          <div>
                            <Skeleton.Button
                              active
                              size="small"
                              style={{ marginBottom: 20,  width:"200px"}}
                            />{" "}
                            <Skeleton.Button active size="small" block  style={{marginLeft:"15px"}}/>{" "}
                          </div> : (
                <Col
                  xs={24}
                  sm={24}
                  md={10}
                  lg={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 10px ",
                  }}
                >
                  <Typography.Text
                    className="text_label"
                    style={{ color: "#1D2939", fontWeight: 500 }}
                  >
                    Set your annual new revenue goal
                  </Typography.Text>
                  <div>
                    <DollarCircleOutlined
                      className="optional_icon top63"
                      style={{
                        position: "absolute",
                        zIndex: 12,
                        fontSize: "18px",
                      }}
                    />
                    <InputNumber
                      size="large"
                      placeholder="Enter new revenue goal"
                      name="name"
                      value={annualRevenueGoal}
                      disabled={userPermission?.revenuePlanning === "viewonly" || loading}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onChange={(e) => {
                        setannualRevenueGoal(Math.floor(e));
                        handleFunnelChanges(true);
                        setanualGoalSaveRemaning(true);

                        if (Segments === 1) {
                          setremoveQuarterwiseData(true);
                          dispatch(
                            getCalculationMetrics(
                              Math.floor(e),
                              marketingSourced,
                              averageDealSize
                            )
                          );
                        }
                      }}
                      style={{
                        padding: "0 0 0 17px ",
                        width: "100%",
                        color: "black",
                      }}
                      className="general-input"
                    />
                  </div>
                </Col>
                )
              }
                <Col
                  xs={8}
                  sm={8}
                  md={4}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  {userPermission?.revenuePlanning !== "viewonly" ? (
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      {loading ? <div>
                            <Skeleton.Button
                              active
                              size="small"
                              style={{ marginBottom: 20,marginLeft:"20px",  width:"0"}}
                            />{" "}
                            <Skeleton.Button active size="small" block  style={{marginLeft:"40px", width:"130px"}}/>{" "}
                          </div> : (
                      <Button
                        type="primary"
                        onClick={() => {
                          TrackClickFunction(
                            "demand-gen-revenue-planning-annual-goal-save-button"
                          );
                          handleSave();
                        }}
                        style={{ width: "130px" }}
                        disabled={!annualRevenueGoal || loading}
                        className="non-print save-btn primaryButton"
                        data-heap="demand-gen-revenue-planning-annual-goal-save-button"
                      >
                        Save
                      </Button>
                      )}
                      <span
                        className="conversation-difference-dot"
                        style={{ opacity: anualGoalSaveRemaning ? 1 : 0 }}
                      ></span>
                    </div>
                  ) : (
                    <Button
                      type="primary"
                      // onClick={handleSave}
                      
                      disabled
                      className="non-print primaryButton"
                      title="View only permission"
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
         
      </Row>
     

      <BaselineCompanyMetrics
        lastStageName={lastStageName}
        isFunnelChanges={handleFunnelChanges}
        fiscalYear={fiscalYear}
        selectedFiscalYear={selectedFiscalYear}
        userPermission={userPermission}
        setNumberOfSegments={setNumberOfSegments}
        saveSegmentClicked={saveSegmentClicked}
        saveFiscalyear={handleSave}
        removeQuarterwiseData={removeQuarterwiseData}
        getFiscalYearListLoader={getFiscalYearListLoader}
        onMultiSelectedChange={handleMultiSelectedChange}
        onEmptySelect={handleEmptySelect}
      />
      {!isSelectCheckbox && (
        <FunnelConversionRates
          getFiscalYearListLoader={getFiscalYearListLoader}
          isFunnelChanges={handleFunnelChanges}
          userPermission={userPermission}
          account={account}
          viewType={viewType}
         
          multiSelected={multiSelected}
        />
      )}
      {/* <Tour
        steps={steps}
        accentColor={"#0F61DB"}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false);
          updateTour();
        }}
        rounded={5}
        scrollDuration={100}
        disableInteraction={true}
        onAfterOpen={(target) => (document.body.style.overflowY = "hidden")}
        onBeforeClose={(target) => (document.body.style.overflowY = "auto")}
        lastStepNextButton={
          <Button type="primary" onClick={() => updateTour()}>
            Get Started
          </Button>
        }
        disableFocusLock={true}
        closeWithMask={false}
        getCurrentStep={(curr) => setc(curr + 1)}
        startAt={0}
      /> */}

      {/* <Modal
        visible={createDefaultBudgetModalOpen}
        closable={false}
        centered
        footer={[]}
        style={{ borderRadius: "20px" }}
      >
        <div className="delete-modal-body">
          <Typography.Text
            style={{
              marginTop: "15px",
              fontSize: "20px",
              marginBottom: "15px",
            }}
          >
            Would you like to create budget for the default segment ?
          </Typography.Text>
          <div className="delete-modal-footer">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                createDefaultBudget()
                }}
                loading={isLoading}
            >
              Create Budget
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => setcreateDefaultBudgetModalOpen(false)}
              ghost
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default RevenuePlanning;
