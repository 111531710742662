import { Table, Modal } from "antd";
import { useState, useEffect } from "react";
import { Row, Col, Typography, Button, Popover, Skeleton, Tooltip } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import FilterPurpleIcon from "assets/Images/Filter.svg";
import EditIcon from "assets/Images/EditIcon.svg";

import StackGroupedChart from "pages/RevenueForecasting1/StackGroupedChart";
import StackBarChart from "pages/RevenueForecasting1/StackBarChart";
import SimpleBarChart from "pages/RevenueForecasting1/SimpleBarChart";

const RevenueForecasting = (props) => {
  const [chartOverlayVisible, setChartOverlayVisible] = useState(false);
  const [modalData, setModalData] = useState({ amount: "", id: "" });
  const [showChart, setShowChart] = useState(true);

  const handleCellClick = (amount, id) => {
    setModalData({ amount, id });
    setChartOverlayVisible(true);
    setShowChart(false); // Hide the chart
    setTimeout(() => {
      setShowChart(true); // Show the chart after one second
    }, 200);
  };

  const dataSource = [
    {
      key: "1",
      name: "All",
      "01/24": { id: "a1", title: "Amount 1", matured: true, value: 1000 },
      "02/24": { id: "a2", title: "Amount 2", matured: false, value: 2000 },
      "03/24": { id: "a3", title: "Amount 3", matured: true, value: 3000 },
      "04/24": { id: "a4", title: "Amount 4", matured: false, value: 3100 },
      "05/24": { id: "a5", title: "Amount 5", matured: true, value: 3200 },
      "06/24": { id: "a6", title: "Amount 6", matured: false, value: 3300 },
      "07/24": { id: "a7", title: "Amount 7", matured: true, value: 3400 },
      "08/24": { id: "a8", title: "Amount 8", matured: false, value: 3500 },
      "09/24": { id: "a9", title: "Amount 9", matured: true, value: 3600 },
      "10/24": { id: "a10", title: "Amount 10", matured: false, value: 3700 },
      "11/24": { id: "a11", title: "Amount 11", matured: true, value: 3800 },
      "12/24": { id: "a12", title: "Amount 12", matured: false, value: 3900 },
    },
    {
      key: "2",
      name: "Events",
      "01/24": { id: "b1", title: "Amount 1", matured: false, value: 4000 },
      "02/24": { id: "b2", title: "Amount 2", matured: true, value: 5000 },
      "03/24": { id: "b3", title: "Amount 3", matured: false, value: 6000 },
      "04/24": { id: "b4", title: "Amount 4", matured: true, value: 4100 },
      "05/24": { id: "b5", title: "Amount 5", matured: false, value: 4200 },
      "06/24": { id: "b6", title: "Amount 6", matured: true, value: 4300 },
      "07/24": { id: "b7", title: "Amount 7", matured: false, value: 4400 },
      "08/24": { id: "b8", title: "Amount 8", matured: true, value: 4500 },
      "09/24": { id: "b9", title: "Amount 9", matured: false, value: 4600 },
      "10/24": { id: "b10", title: "Amount 10", matured: true, value: 4700 },
      "11/24": { id: "b11", title: "Amount 11", matured: false, value: 4800 },
      "12/24": { id: "b12", title: "Amount 12", matured: true, value: 4900 },
    },
    {
      key: "3",
      name: "Webinars",
      "01/24": { id: "c1", title: "Amount 1", matured: true, value: 7000 },
      "02/24": { id: "c2", title: "Amount 2", matured: false, value: 8000 },
      "03/24": { id: "c3", title: "Amount 3", matured: true, value: 9000 },
      "04/24": { id: "c4", title: "Amount 4", matured: true, value: 5100 },
      "05/24": { id: "c5", title: "Amount 5", matured: false, value: 5200 },
      "06/24": { id: "c6", title: "Amount 6", matured: true, value: 5300 },
      "07/24": { id: "c7", title: "Amount 7", matured: false, value: 5400 },
      "08/24": { id: "c8", title: "Amount 8", matured: true, value: 5500 },
      "09/24": { id: "c9", title: "Amount 9", matured: false, value: 5600 },
      "10/24": { id: "c10", title: "Amount 10", matured: true, value: 5700 },
      "11/24": { id: "c11", title: "Amount 11", matured: false, value: 5800 },
      "12/24": { id: "c12", title: "Amount 12", matured: true, value: 5900 },
    },
  ];
  const dataKeys = Object.keys(dataSource[0]).filter(key => key !== 'key' && key !== 'name');

  // Column configuration for the table
  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: "200px",
      fixed: "left",
      render: (text) => (
        <div style={{ width: "150px" }}>
          <a>{text}</a>
        </div>
      ),
    },
    ...dataKeys.map(key => ({
      title: key,
      dataIndex: key,
      key: key,
      width: 120,
      render: (amount) => (
        <div className="table_cell_div forecasting_popover">
          <Popover
            placement="right"
            arrowPointAtCenter
            title={
              <strong style={{ fontSize: "16px", lineHeight: "18px" }}>
                Actual: July September 2023
              </strong>
            }
            style={{ padding: "10px 5px" }}
            content={
              <div
                className="popover_wrapper"
                style={{
                  maxHeight: "312px",
                  overflow: "hidden",
                  padding: "22px 20px",
                }}
              >
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#000000" }}
                    ></span>
                    <span className="name">Investment</span>
                  </span>
                  <span className="right">
                    <span className="amount">$17,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#FC8E6C" }}
                    ></span>
                    <span className="name">Total Actual Leads Generated</span>
                  </span>
                  <span className="right">
                    <span className="amount">$500</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#CECECE" }}
                    ></span>
                    <span className="name">Total Leads Forecasted</span>
                  </span>
                  <span className="right">
                    <span className="amount">$32,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#F55B11" }}
                    ></span>
                    <span className="name">Investment</span>
                  </span>
                  <span className="right">
                    <span className="amount">$17,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#8D66E3" }}
                    ></span>
                    <span className="name">Total Actual Leads Generated</span>
                  </span>
                  <span className="right">
                    <span className="amount">$500</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#FEE9DF" }}
                    ></span>
                    <span className="name">Total Leads Forecasted</span>
                  </span>
                  <span className="right">
                    <span className="amount">$32,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#000000" }}
                    ></span>
                    <span className="name">Investment</span>
                  </span>
                  <span className="right">
                    <span className="amount">$17,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#FC8E6C" }}
                    ></span>
                    <span className="name">Total Actual Leads Generated</span>
                  </span>
                  <span className="right">
                    <span className="amount">$500</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#CECECE" }}
                    ></span>
                    <span className="name">Total Leads Forecasted</span>
                  </span>
                  <span className="right">
                    <span className="amount">$32,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#F55B11" }}
                    ></span>
                    <span className="name">Investment</span>
                  </span>
                  <span className="right">
                    <span className="amount">$17,000</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#8D66E3" }}
                    ></span>
                    <span className="name">Total Actual Leads Generated</span>
                  </span>
                  <span className="right">
                    <span className="amount">$500</span>
                  </span>
                </div>
                <div className="popover_item">
                  <span className="left">
                    <span
                      className="color"
                      style={{ backgroundColor: "#FEE9DF" }}
                    ></span>
                    <span className="name">Total Leads Forecasted</span>
                  </span>
                  <span className="right">
                    <span className="amount">$32,000</span>
                  </span>
                </div>
              </div>
            }
          >
          <span
            onClick={() => handleCellClick(amount.value, amount.id)}
            style={{
              cursor: "pointer",
              width: "70px",
              display: "flex",
              height: "70px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={amount.matured ? "yellowForecastBg" : "greenForecastBg"}
          >
            {amount.value}
          </span>
          </Popover>
        </div>
      ),
    })),
  ];

  return (
    <>
      <div className="forecasting_content_main">
        <Row
          className="aboutPage panel-design"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
            background: "#fff",

            width: "100%",
            borderRadius: 7,
            alignContent: "center",
            padding: "30px 20px",
          }}
        >
          <Col
            className="details"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            span={24}
            lg={24}
            md={24}
            xs={24}
            sm={24}
          >
            <div>
              <div
                className="headsec_main"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="headsec_left">
                  <Typography.Text
                    className="title fw500 headerMain"
                    style={{
                      color: "#1D2939",
                      marginBottom: "5px",
                      display: "block",
                    }}
                  >
                    Revenue Forecasting
                  </Typography.Text>
                  <div className="breadcrumb_main">
                    <span className="breadcrumb_item home">
                      <Link to="/">Demand Generation</Link>
                    </span>
                    <span className="breadcrumb_item seperator">
                      <RightOutlined />
                    </span>
                    <span className="breadcrumb_item current">
                      Revenue Forecasting
                    </span>
                  </div>
                </div>
                <div
                  className="headsec_right"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    className="secondaryButton"
                    icon={
                      <img
                        src={EditIcon}
                        alt="Edit"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-2px",
                          marginRight: "8px",
                        }}
                      />
                    }
                    style={{ width: "130px" }}
                    //onClick={() => setIsShowBudgetPlannedModal(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    className="primaryButton"
                    icon={
                      <img
                        src={FilterPurpleIcon}
                        alt="Filter"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-2px",
                          marginRight: "8px",
                        }}
                      />
                    }
                    style={{ width: "130px", marginLeft: "8px" }}
                    //onClick={handleFilter}
                    //onClick={()=> setIsShowBudgetFilterModal(true)}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="panel-design">
          <div
            className="forecasting_details"
            style={{
              display: "flex",
              alignItems: "center",

              padding: "20px 15px 0 15px",
            }}
          >
            <div className="channels">
              <div
                style={{
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#101828",
                  fontWeight: "700",
                  width: "172px",
                  paddingBottom: "10px",
                }}
              >
                Channels
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                borderBottom: "1px solid #D0D5DD",
                paddingBottom: "10px",
              }}
            >
              <div
                className="channel_name"
                style={{
                  fontSize: "20px",
                  lineHeight: "32px",
                  color: "#101828",
                  fontWeight: "700",
                }}
              >
                Investments & Goals
              </div>
              <div
                className="indications"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="item">
                  <span
                    className="color"
                    style={{
                      backgroundColor: "#D1E9FF",
                      borderColor: "#1570EF",
                    }}
                  ></span>
                  <span className="name">Actual Good</span>
                </span>
                <span className="item">
                  <span
                    className="color"
                    style={{
                      backgroundColor: "#FEF3F2",
                      borderColor: "#FEE4E2",
                    }}
                  ></span>
                  <span className="name">Actual Average</span>
                </span>
                <span className="item">
                  <span
                    className="color"
                    style={{
                      backgroundColor: "#FECDD6",
                      borderColor: "#E31B54",
                    }}
                  ></span>
                  <span className="name">Actual Bad</span>
                </span>
                <span className="item">
                  <span
                    className="color"
                    style={{
                      backgroundColor: "#D1E9FF",
                      borderColor: "#84CAFF",
                    }}
                  ></span>
                  <span className="name">Forecast Good</span>
                </span>
                <span className="item">
                  <span
                    className="color"
                    style={{
                      backgroundColor: "#FFFEFE",
                      borderColor: "#FDE4E2",
                    }}
                  ></span>
                  <span className="name">Forecast Average</span>
                </span>
                <span className="item">
                  <span
                    className="color"
                    style={{
                      backgroundColor: "#FFE4E8",
                      borderColor: "#FEA3B4",
                    }}
                  ></span>
                  <span className="name">Forecast Bad</span>
                </span>
              </div>
            </div>
          </div>
          <div style={{ borderRadius: "7px" }} className="forecasting_table_wrapper">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              // rowClassName={(record) => (record.matured ? "matured-row" : "")}
              className="revenue_forecasting_table"
            />
          </div>
        </div>
        <div
          className={`forecasting_chart_overlay ${
            chartOverlayVisible ? "open" : "close"
          }`}
        >
          <div
            className="forecasting_chart_overlay_inner"
            style={{ position: "relative" }}
          >
            
              {/* <Button
                onClick={() => {
                  setChartOverlayVisible(false);
                  setShowChart(false);
                }}
                style={{ padding: "0 20px" }}
                className="overlay_back secondaryButton"
              >
                <span style={{ transform: "rotate(180deg)" }}>
                  <span
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <UpOutlined />
                  </span>
                  Back{" "}
                </span>
              </Button> */}
           
            <div className="overlay_chart_details" >
              <div className="overlay_chart_details_wrap" style={{display:'flex', alignItems:'center', padding:'0 20px', marginBottom:'30px'}}> 
                <div className="back_overlay" onClick={() => {
                  setChartOverlayVisible(false);
                  setShowChart(false);
                }}>
                  <Tooltip placement="top" title={'Back'}>
                    <LeftOutlined />
                  </Tooltip>
                </div>
                <div
                  className="forecasting_details"
                  style={{flex:1 }}
                >
                  
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        borderBottom: "1px solid #D0D5DD",
                        paddingBottom: "10px",
                        height: "43px",
                      }}
                    >
                      <div
                        className="channel_name"
                        style={{
                          fontSize: "20px",
                          lineHeight: "32px",
                          color: "#101828",
                          fontWeight: "700",
                        }}
                      >
                        Investments & Goals
                      </div>
                      <div
                        className="indications"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="item">
                          <span
                            className="color"
                            style={{
                              backgroundColor: "#D1E9FF",
                              borderColor: "#1570EF",
                            }}
                          ></span>
                          <span className="name">Actual Good</span>
                        </span>
                        <span className="item">
                          <span
                            className="color"
                            style={{
                              backgroundColor: "#FEF3F2",
                              borderColor: "#FEE4E2",
                            }}
                          ></span>
                          <span className="name">Actual Average</span>
                        </span>
                        <span className="item">
                          <span
                            className="color"
                            style={{
                              backgroundColor: "#FECDD6",
                              borderColor: "#E31B54",
                            }}
                          ></span>
                          <span className="name">Actual Bad</span>
                        </span>
                        <span className="item">
                          <span
                            className="color"
                            style={{
                              backgroundColor: "#D1E9FF",
                              borderColor: "#84CAFF",
                            }}
                          ></span>
                          <span className="name">Forecast Good</span>
                        </span>
                        <span className="item">
                          <span
                            className="color"
                            style={{
                              backgroundColor: "#FFFEFE",
                              borderColor: "#FDE4E2",
                            }}
                          ></span>
                          <span className="name">Forecast Average</span>
                        </span>
                      </div>
                    </div>
                
                </div>
              </div>
              {}
              {/* <ForecastLineChart /> */}
              <div className="chart_wrapper" style={{padding:'0 0 0 20px'}}>
              {showChart &&  <StackGroupedChart /> }
              {/* {showChart &&  <StackBarChart /> } */}
              {/* {showChart &&  <SimpleBarChart /> } */}
              
              
               </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueForecasting;
