/* eslint-disable react-hooks/exhaustive-deps */
import { Line } from "@ant-design/charts";
import {
  Col,
  Divider,
  Row,
  Select,
  Skeleton,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
  Dropdown,
  Button,
  Menu,
  Popover,
  Descriptions,
} from "antd";
import { API } from "aws-amplify";
import FunnelCharts from "commons/FunnelChart";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import verticalExpandImg from "assets/Images/vertical_expand.svg";
import verticalCollapseImg from "assets/Images/vertical_collapse.svg";
import { isNumber } from "lodash";
import {
  numberWithCommas,
  numberWithCommasAndRemovedNoDecimals,
} from "utility/context/CommonMethods";
import { CaretDownOutlined, FilterOutlined } from "@ant-design/icons";
import settings from "../../../../assets/Images/Settings.svg";
import { getFiscalYearsList } from "store/actions/revenueAction";
import { useDispatch } from "react-redux";
import { jsonData } from "./dunnmyData";
import sdk from "sdk/Accounts";

const RoiTooltipText = "ROI is calculated by looking at the total cost of the total closed won sales as a result of a Marketing program divided by the total cost of the Marketing program. For example if you generated $150,000 from your marketing program that was an event, and the event cost $36,000 you would have a 4X return on your investment.";
const PipelineTooltipText = "Is my marketing investment by channel creating enough pipeline to justify executing the program again? We may not have closed won deals but if we have enough pipeline we can justify the spend again. Investment to pipeline is calculated by looking at your planned or actual opportunity close rate and seeing if you have sufficient pipeline to cover the cost of the program. For example if your assumed or actual close rate is 20%, (configured on Revenue Planning). Your Marketing Programs have resulted in sufficient pipeline of 5X your investment meaning you have enough pipeline at the assumed planned 20% rate to cover the cost of the marketing program.";

function CampaignFunnelAnalysis(props) {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);
  const [isAccountLoading, setisAccountLoading] = useState(true);
  const [isAnalysisLoading, setisAnalysisLoading] = useState(true);
  const [stageView, setStageView] = useState(true);
  const [opportunityTypeOptions, setOpportunityTypeOptions] = useState([]);
  const [opportunityTypes, setOpportunityTypes] = useState([]);
  const [opportunityTypesFetched, setOpportunityTypesFetched] = useState(false);
  const [dropDownYearList, setDropDownYearList] = useState([]);
  const [totalRoi, setTotalRoi] = useState(0);
  const [acct, setAcct] = useState(null);
  const [loading, setLoading] = useState(true);

  

  const isStageView = (viewFlag) => {
    setStageView(!stageView);
  };
  const [selectedYear, setselectedYear] = useState();
  const account = useSelector((state) => state.loginReducer.groups);
  const { fiscalYearList } = useSelector((state) => state.revenueReducer);


  const fetchAccountDetails = async () => {
    
    
    const accountID = account.length > 0 ? account[0] : null;
    if (accountID) {
      setisAccountLoading(true);
      let account = await sdk.fetchAccount(accountID);

      setAcct(account);
      setisAccountLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const sortingOrder = (a, b) => {
    if (a?.order < b?.order) {
      return -1;
    }
    if (a?.order > b?.order) {
      return 1;
    }
    return 0;
  };

  const yearOptions = [
    {
      label: moment().year() - 2,
      value: moment().year() - 2,
    },
    {
      label: moment().year() - 1,
      value: moment().year() - 1,
    },
    {
      label: moment().year(),
      value: moment().year(),
    },
  ];

  const opportunityOptions = [];
  opportunityTypes?.map((item) => {
    return opportunityOptions.push({
      label: item,
      value: item,
    });
  });

  useEffect(() => {
   
    const controller = new AbortController();
    
      const timeOut = setTimeout(()=> {
        
        if (props?.CRM) {
          fetchGraphData(controller.signal);
        }
      }, 1000)
      
      return () => {
        console.log("Aborting previous API call");
        clearTimeout(timeOut);
        controller.abort();
        
      };
  }, [selectedYear, props?.CRM, opportunityTypeOptions]);

  const [graphData, setgraphData] = useState({});
  const fetchGraphData = async (signal) => {
    setisAnalysisLoading(true);
    try {
      let response = await API.get(
        "backendRest",
        `/mapping/get_campaign_funnel_analysis?year=${selectedYear}&account_id=${account[0]}&platform=${props?.CRM}&opp_types=${opportunityTypeOptions}`,
        {signal}
      );   
      
      let FunnelActualData = response?.data?.campaign_funnel_actual_data?.map(
        (item) => ({
          ...item,
          text: item?.stage,
          width: 70,
          percentageValue: 50,
        })
      );

      if (response?.data) {
        setgraphData({ ...response?.data, FunnelActualData });
        if (!opportunityTypesFetched) {
          setOpportunityTypes(response?.data?.opportunity_types);
          setOpportunityTypesFetched(true);
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log("Previous API call was aborted");
      } else {
        console.error(error);
        message.error("Something Went Wrong");
      }
      
    } finally {
      setisAnalysisLoading(false);
    }
  };

  useEffect(() => {
    if(isAccountLoading || isAnalysisLoading){
      setisLoading(true);
    }else{
      setisLoading(false);
    }
  }, [isAccountLoading,isAnalysisLoading]);

  console.log("isAccountLoading",isAccountLoading);
  console.log("isAnalysisLoading",isAnalysisLoading);

  const [campaignTableData, setcampaignTableData] = useState([]);
  const [roiTableData, setRoiTableData] = useState([]);
  const [campaignTableDatabackup, setcampaignTableDatabackup] = useState([]);
  const [campaignColumnData, setcampaignColumnData] = useState([]);
  const [roiColumnData, setroiColumnData] = useState([]);
  const [funnelChartData, setFunnelChartData] = useState([]);
  const [selectedChartFilter, setSelectedChartFilter] = useState("select_all");
  const [actualData, setActualData] = useState([]);
  const [recordData, setRecordDataData] = useState("");
  const [revenueActual, setRevenueActual] = useState(0);
  const [revenueActualRoi, setRevenueActualRoi] = useState(0);
  const [revenueActualRoiChild, setRevenueActualRoiChild] = useState(0);
  const [totalStagePipeLineValue, setTotalStagePipeLineValue] = useState(0);
  const [totalStagePipeLineRoiValue, setTotalStagePipeLineRoiValue] =
    useState(0);
  const [totalStagePipeLineRoiChildValue, setTotalStagePipeLineRoiChildValue] =
    useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(async () => {
    (async () => {
      
      const fiscalYears = await dispatch(
        getFiscalYearsList({ account: { eq: account[0] } })
      );

      const dropdownYears = fiscalYears?.items
        ?.map((item, index) => {
          return { label: item.year, value: item.year };
        })
        ?.sort();
        let currentYear;
        let prevYear;
        let nextYear;
        if(acct?.fiscalYearMonth === 1 || acct?.fiscalYearMonth === null){
         
          currentYear = moment().get("year");
          prevYear = currentYear - 1;
          nextYear = currentYear + 1;
        }

        if(acct?.fiscalYearMonth > 1){
         
          currentYear = moment().get("year") + 1;
          prevYear = currentYear - 1;
          nextYear = currentYear + 1;
        }
        // Check and add missing years
        const yearsToAdd = [currentYear, prevYear, nextYear];
        yearsToAdd.forEach(year => {
          if (!dropdownYears?.some(item => item.value === year)) {
            dropdownYears.push({ label: year, value: year });
          }
        });
    
        // Sort again after adding new years
        dropdownYears.sort((a, b) => a.value - b.value);
    
        
      // if (!dropdownYears?.includes(selectedYear)) {
      //   // New Logic
      //   const currentYear = new Date().getFullYear();
      //   let newYear = dropdownYears?.at(-1) ?? selectedYear;
      //   if (dropdownYears?.includes(currentYear)) {
      //     newYear = currentYear;
      //   }

      //   setselectedFiscalYear(newYear);
      //   handleFiscalYearChange(newYear);
      // }
      setDropDownYearList(dropdownYears);

      
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acct]);

  useEffect(() => {
    
    if(fiscalYearList?.length > 0 && (acct?.fiscalYearMonth === 1 || acct?.fiscalYearMonth === null)) {
      setselectedYear(moment().get("year"));
    } 
    if(fiscalYearList?.length > 0 && acct?.fiscalYearMonth > 1){
      setselectedYear(moment().get("year") + 1);
    }
    
  }, [acct, fiscalYearList]);

  const onYearChange = (year) => {
    setselectedYear(year);
  };

  const onStageChange = (value) => {
    setSelectedChartFilter(value);
    value === "select_all"
      ? setActualData(graphData?.campaign_funnel_funnel_trends)
      : setActualData(
          graphData?.campaign_funnel_funnel_trends
            ?.map((item) => ({ ...item, stage: item?.stage + " Actual" }))
            ?.filter((item) => item?.stage === `${value} Actual`)
        );
  };
 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const transformedData = actualData?.map(item => ({
      ...item,
      month: monthNames[item.month - 1]
  }));
  const LineChartConfig = {
    data: transformedData,
    xField: "month",
    yField: "value",
    seriesField: "stage",
    xAxis: {
      title: {
        text: "Month",
        position: "bottom",
        style: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    yAxis: {
      title: {
        text: "Actual Value",
        position: "bottom",
        style: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    
  };

  let selectedTest = "";
  const handleClick = (record) => {
    setFunnelChartData(
      graphData?.FunnelActualData.map((item) => ({
        text: item.stage,
        value: record[item.stage],
        width: item?.width,
      }))
    );
    setActualData(graphData?.campaign_funnel_funnel_trends);
  };

  const onTableRowClick = (record) => {
    selectedTest = record?.name;
    handleClick(record);
    setRecordDataData(record);

    
  };

  const [isDeltaVisible, setisDeltaVisible] = useState(false);

  const generateColumnData = () => {
    let columnData = [
      {
        title: (
          <span
            style={{
              textAlign: "left",
              marginBottom: 0,
              color: "##1D2939",
              fontWeight: 500,
              fontSize:'15px'
            }}
          >
            Name
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "15%",
        fixed: "left",
        render: (name, record) => {
          return (
            <div>
              {record?.children || record.name === "Total" ? (
                <span
                  className="channel-text"
                  style={{
                    color: "#7F56D9",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                >
                  {name}
                </span>
              ) : (
                <Typography.Text
                  className={`table-campaign-text ${
                    selectedTest && name === selectedTest
                      ? "campaign-selected"
                      : ""
                  }`}
                  style={{
                    color: "#12B76A",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {name}
                </Typography.Text>
              )}
            </div>
          );
        },
      },
    ];

    let PotentialColumns = graphData?.stage_order_data?.sort(sortingOrder);
    PotentialColumns?.forEach((item, index) => {
      let key = item?.name;
      const lastKey = PotentialColumns[index - 1]?.name;
      columnData.push({
        title: (
          <Tooltip title={key}>
          <div className="header-cell">
            <span
              style={{
                textAlign: "left",
                marginBottom: 0,
                color: "##1D2939",
                fontWeight: 500,
                fontSize:'15px'
              }}
            >
              {key}
            </span>
          </div>
          </Tooltip>
        ),
        dataIndex: key,
        key: key,
        width: "10%",
        render: (value, record, i) => {
          let deltaValue =
            lastKey && !isNaN(record[lastKey]) ? value - record[lastKey] : null;

          

          return (
            <div
              style={{ fontWeight: 500, display: "flex", alignItems: "center" }}
            >
              <Typography.Text style={{ marginRight: 5 }}>
                {isDeltaVisible && deltaValue ? (
                  <small style={{ color: "red" }}>
                    (
                    <CaretDownOutlined />
                    {deltaValue})
                  </small>
                ) : null}
              </Typography.Text>{" "}
              <div style={{fontSize:'15px'}}>
                {value}
                {record?.opportunities &&
                  Object.keys(record?.opportunities).length > 0 && (
                    <>
                      
                      {record?.opportunities[key]?.length > 0 && (
                        <Popover
                          placement="topRight"
                          arrowPointAtCenter
                          title={<strong>Opportunities</strong>}
                          content={
                            <div style={{ maxHeight: 400, overflow: "scroll" }}>
                              <Descriptions size={"small"} bordered>
                               
                                <Descriptions.Item span={3} label={'Account Name'} className="list_head">Opportunity Name</Descriptions.Item>
                                {record?.opportunities[key]?.map(
                                  (item, index) => (
                                    <>

                                    <Descriptions.Item
                                      span={3}
                                      label={<a href={item?.title_url} target="_blank" rel="noreferrer">{item?.title}</a>}
                                      key={index}
                                    >
                                      <a href={item?.value_url} target="_blank" rel="noreferrer">{item?.value}</a>
                                    </Descriptions.Item>




                                  </>
                                  )
                                )}
                              </Descriptions>
                            </div>
                          }
                        >
                          <Button
                            type="text"
                            style={{
                              fontFamily: "cursive",
                              fontWeight: 600,
                              fontSize: "18px",
                              color: "#bdb6b6",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              right: 10,
                              top: 3,
                            }}
                          >
                            i
                          </Button>
                        </Popover>
                      )}
                    </>
                  )}
              </div>
              &nbsp;
              <Typography.Text
                style={{ fontWeight: 500, color: "#12B76A" }}
                type={
                  showPipelineValue &&
                  (record?.["pipeline"]?.[key] >= 0 ||
                    record?.["actual_revenue"]?.[key] >= 0)
                    ? "secondary"
                    : ""
                }
              >
                {showPipelineValue && record?.["pipeline"]?.[key] >= 0
                  ? `($${numberWithCommas(record?.["pipeline"]?.[key])})`
                  : ""}
                {showPipelineValue && record?.["actual_revenue"]?.[key] >= 0
                  ? `($${numberWithCommas(record?.["actual_revenue"]?.[key])})`
                  : ""}
              </Typography.Text>{" "}
            </div>
          );
        },
      });
    });
    setcampaignColumnData(columnData);
  };

  const generateColumnDataRoi = () => {
    let columnDataRoi = [
      {
        title: (
          <span
            style={{
              textAlign: "left",
              marginBottom: 0,
              color: "##1D2939",
              fontWeight: 500,
            }}
          >
            Name
          </span>
        ),
        dataIndex: "name",
        key: "name",
        width: "15%",
        fixed: "left",
        render: (name, record) => {
          return (
            <div>
              {record?.children || record.name === "Total" ? (
                <span
                  className="channel-text"
                  style={{
                    color: "#7F56D9",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                >
                  {name}
                </span>
              ) : (
                <Typography.Text
                  className={`table-campaign-text ${
                    selectedTest && name === selectedTest
                      ? "campaign-selected"
                      : ""
                  }`}
                  style={{
                    color: "#12B76A",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {name}
                </Typography.Text>
              )}
            </div>
          );
        },
      },
      {
        title: (
          <span
            style={{
              textAlign: "left",
              marginBottom: 0,
              color: "##1D2939",
              fontWeight: 500,
            }}
          >
            Budget Verified
          </span>
        ),
        dataIndex: "budget_verified",
        key: "budgetVerified",
        width: "10%",
      },
      {
        title: (
          <span
            style={{
              textAlign: "left",
              marginBottom: 0,
              color: "##1D2939",
              fontWeight: 500,
            }}
          >
            Revenue Actual
          </span>
        ),
        dataIndex: "revenue_actual",
        key: "revenueActual",
        width: "10%",
      },
      {
        title: (
          <span
            style={{
              textAlign: "left",
              marginBottom: 0,
              color: "##1D2939",
              fontWeight: 500,
            }}
          >
            ROI
          </span>
        ),
        dataIndex: "roi",
        key: "roi",
        width: "10%",
      },
      {
        title: (
          <span
            style={{
              textAlign: "left",
              marginBottom: 0,
              color: "##1D2939",
              fontWeight: 500,
            }}
          >
            Investment to Pipeline
          </span>
        ),
        dataIndex: "investment_to_pipeline",
        key: "investmentToPipeline",
        width: "10%",
      },
    ];

    setroiColumnData(columnDataRoi);
  };

  const getTotalValues = (data) => {
    let tableDataTotal = {
      name: "Total",
      key: "Total",
      pipeline: {},
      actual_revenue: {},
    };

    data?.forEach((item) => {
      Object.keys(item)?.forEach((key) => {
        if (isNumber(item[key])) {
          tableDataTotal[key] = tableDataTotal[key] + item[key] || item[key];
        }
        if (isNumber(item?.["pipeline"]?.[key])) {
          tableDataTotal["pipeline"][key] =
            tableDataTotal["pipeline"][key] + item["pipeline"][key] ||
            item["pipeline"][key];
        }
        if (isNumber(item?.["actual_revenue"]?.[key])) {
          tableDataTotal["actual_revenue"][key] =
            tableDataTotal["actual_revenue"][key] +
              item["actual_revenue"][key] || item["actual_revenue"][key];
        }
      });
    });
    return tableDataTotal;
  };

  const [showZeroValue, setshowZeroValue] = useState(true);
  const [showPipelineValue, setshowPipelineValue] = useState(false);

  useEffect(() => {
    if (graphData?.campaign_funnel_analysis?.length > 0) {
      generateColumnData(graphData);
    }
  }, [showPipelineValue]);

  useEffect(() => {
    generateColumnData();
    generateColumnDataRoi();
  }, [isDeltaVisible]);

  useEffect(() => {
    // SetUp table columns Data
    if (graphData?.stage_order_data?.length > 0) {
      generateColumnData(graphData);
    }

    let tableData = graphData?.campaign_funnel_analysis;

    let tableDataTotal = getTotalValues(tableData);
    tableData?.push(tableDataTotal);

    // let modifiedTableData = tableData?.map((item, index) => {
    //   console.log("iitteemm", item);
    // })

    setcampaignTableData(tableData);
    setcampaignTableDatabackup(tableData);
  }, [graphData]);


  useEffect(() => {
    if (
      graphData &&
      graphData.campaign_funnel_analysis &&
      graphData.campaign_funnel_analysis.length > 0
    ) {
      let tableData = graphData?.campaign_funnel_analysis;

      let TotalRoi = 0;
      let TotalPipelineRoiCal = 0;
      let modifiedTableData = tableData.map((item, index) => {
        //let TotalStagePipelineRoi = 0;
        // Object.keys(item).forEach((key, index) => {
        //   console.log("itemmKey", item);
        //   let actualRevenueValueRoi = item?.actual_revenue?.[key];

        //   TotalStagePipelineRoi = +item?.actual_revenue?.[key];
        //   console.log("itemmTotalStagePipelineRoi", TotalStagePipelineRoi);
        //   if (actualRevenueValueRoi !== undefined) {
        //     console.log("itemmiinn",actualRevenueValueRoi);
        //     setRevenueActualRoi(() => actualRevenueValueRoi);
        //     console.log("itemmrevenueActualRoi", revenueActualRoi);
        //   }
        //   setTotalStagePipeLineRoiValue(() => TotalStagePipelineRoi);
        //   console.log("itemmtotalStagePipeLineRoiValue", totalStagePipeLineRoiValue);

        // });
      

        const roiCal = item?.verified_budget
          ? numberWithCommasAndRemovedNoDecimals(
              item?.actual_revenue?.["Closed Won"] / item?.verified_budget
            ) || 0
          : 0;
        

        if (item.key !== "Total") {
          TotalRoi += parseFloat(roiCal);
        }

        

        const pipelineKeys = Object.keys(item.pipeline);
        const dynamicKey = pipelineKeys[0]; // Assuming you need the first key
        const pipelineValue = item.pipeline[dynamicKey];

        const investmentToPipelineCal =
        pipelineValue && item?.verified_budget
            ? numberWithCommasAndRemovedNoDecimals(
              pipelineValue/ item?.verified_budget
              ) || 0
            : 0;

       

        if (item.key !== "Total") {
          TotalPipelineRoiCal += parseFloat(investmentToPipelineCal);
          
        }

        let budgetVerifiedCal =
          item.verified_budget !== 0
            ? `$${numberWithCommasAndRemovedNoDecimals(item.verified_budget)}`
            : `${numberWithCommasAndRemovedNoDecimals(item.verified_budget)}`;
        let revenueActualCal =
          item?.actual_revenue?.["Closed Won"] !== 0
            ? `$${numberWithCommasAndRemovedNoDecimals(
                item?.actual_revenue?.["Closed Won"]
              )}`
            : numberWithCommasAndRemovedNoDecimals(
                item?.actual_revenue?.["Closed Won"]
              );

        return {
          ...item,
          // Assuming these values need to be calculated from existing data

          budget_verified: budgetVerifiedCal,
          revenue_actual: revenueActualCal,
          roi: item.key === "Total" ? TotalRoi : roiCal,
          investment_to_pipeline:
            item.key === "Total"
              ? `${TotalPipelineRoiCal} X`
              : `${investmentToPipelineCal} X`,

          children: item.children?.map((childItem) => {
            // let TotalStagePipelineRoiChild = 0;
            // Object.keys(childItem).forEach((key, index) => {
            //   const actualRevenueValueRoiChild =
            //     childItem?.actual_revenue?.[key];
            //   TotalStagePipelineRoiChild = +childItem?.actual_revenue?.[key];
            //   if (actualRevenueValueRoiChild !== undefined) {
            //     setRevenueActualRoiChild(() => actualRevenueValueRoiChild);
            //   }
            //   setTotalStagePipeLineRoiChildValue(
            //     () => TotalStagePipelineRoiChild
            //   );
            // });
            const roiCalChild = childItem?.verified_budget
              ? numberWithCommasAndRemovedNoDecimals(
                  childItem?.actual_revenue?.["Closed Won"] /
                    childItem?.verified_budget
                ) || 0
              : 0;
            if (childItem.key !== "Total") {
              //setTotalRoi((prev) => +prev + parseFloat(roiCalChild));
              TotalRoi += parseFloat(roiCalChild);
            }
            const pipelineKeysChild = Object.keys(childItem.pipeline);
            const dynamicKeyChild = pipelineKeysChild[0]; // Assuming you need the first key
            const pipelineValueChild = childItem.pipeline[dynamicKeyChild];
            const investmentToPipelineCalChild =
            pipelineValueChild &&
              childItem?.verified_budget
                ? numberWithCommasAndRemovedNoDecimals(
                  pipelineValueChild /
                      childItem?.verified_budget
                  ) || 0
                : 0;

            if (childItem.key !== "Total") {
              TotalPipelineRoiCal += parseFloat(investmentToPipelineCalChild);
            }

            let budgetVerifiedCalChild =
              childItem.verified_budget !== 0
                ? `$${numberWithCommasAndRemovedNoDecimals(
                    childItem.verified_budget
                  )}`
                : `${numberWithCommasAndRemovedNoDecimals(
                    childItem.verified_budget
                  )}`;
            let revenueActualCalChild =
              childItem?.actual_revenue?.["Closed Won"] !== 0
                ? `$${numberWithCommasAndRemovedNoDecimals(
                    childItem?.actual_revenue?.["Closed Won"]
                  )}`
                : numberWithCommasAndRemovedNoDecimals(
                    childItem?.actual_revenue?.["Closed Won"]
                  );

            return {
              ...childItem,
              budget_verified: budgetVerifiedCalChild,
              revenue_actual: revenueActualCalChild,
              roi: roiCalChild,
              investment_to_pipeline: investmentToPipelineCalChild,
            };
          }),
        };
      });

      setRoiTableData(modifiedTableData);
    }
  }, [graphData]);

  useEffect(() => {
    if (stageView) {
      if (!showZeroValue) {
        let updatedValue = campaignTableDatabackup
          .filter((item) => item?.Leads !== 0)
          .map((item) => {
            let updatedChildren = [];
            if (item.children && Array.isArray(item.children)) {
              updatedChildren = item.children.filter(
                (child) => child?.Leads !== 0
              );
            }
            return {
              ...item,
              children: updatedChildren,
            };
          });

        setcampaignTableData(updatedValue);
      } else {
        setcampaignTableData(campaignTableDatabackup);
      }
    }
  }, [showZeroValue, campaignTableDatabackup]);

  useEffect(() => {
    let TotalStagePipeline = 0;
    Object.keys(recordData).forEach((key, index) => {
      
      const actualRevenueValue = recordData?.actual_revenue?.[key];
      const pipelineKeys = Object.keys(recordData?.pipeline);
  const dynamicKey = pipelineKeys[0]; // Assuming you need the first key
  const pipelineValue = recordData?.pipeline[dynamicKey];
      
     
      TotalStagePipeline = pipelineValue;
      if (actualRevenueValue !== undefined) {
        setRevenueActual(actualRevenueValue);
      }
    });
    
    setTotalStagePipeLineValue(TotalStagePipeline);
  }, [recordData]);

  const handleDropdownVisibleChange = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMenuClick = (e) => {
    if (e.key === "close") {
      setDropdownVisible(false);
    }
  };
  const onOpportunityTypeOptionsChange = (value) => {
    setOpportunityTypeOptions(() => value);
  };

  const filterItems = (
    <Menu onClick={handleMenuClick} className="filter__menu">
      <Menu.Item key="settings">
        <div className="filter__dropdown">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ marginRight: 20 }}>Show 0 Values </span>
            <Switch
              checked={showZeroValue}
              onChange={setshowZeroValue}
              size="small"
              disabled={!stageView}
            />
          </div>
          <Divider style={{ margin: "10px 0" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              title="On enable this toggle the columns will show the selected pipeline values (Under Sales and Marketing) and Actual Revenue for the Demand Gen Default Stage Closed Won"
              placement="left"
            >
              <span style={{ marginRight: 20 }}>
                Show Pipeline & Revenue Values
              </span>{" "}
              <Switch
                checked={showPipelineValue}
                onChange={setshowPipelineValue}
                size="small"
                disabled={!stageView}
              />
            </Tooltip>
          </div>
          <Divider style={{ margin: "10px 0" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ marginRight: 20 }}>Show Delta</span>{" "}
            <Switch
              checked={isDeltaVisible}
              onChange={setisDeltaVisible}
              size="small"
              disabled={!stageView}
            />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="reporting-card-body panel-design"
      style={{ marginTop: "20px" }}
    >
      <Row gutter={[10, 10]} justify="space-between">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="font-color-budget  custom-tab connect-tab">
              <span
                onClick={() => isStageView(true)}
                className={`budget ${
                  stageView
                    ? "custom-selected-tab-selected"
                    : "custom-selected-tab"
                }`}
              >
                Stages
              </span>
              <span
                onClick={() => isStageView(false)}
                
                className={`budget ${
                  stageView
                    ? "custom-selected-tab"
                    : "custom-selected-tab-selected"
                }`}
              >
                ROI
              </span>
            </div>
          </div>
        </Col>
        <Col display="flex" alignItems="center">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                marginRight: "15px",
              }}
            >
              <Typography.Text
                className="selection-header-title"
                style={{ margin: 0, color: "#1D2939", fontWeight: "500" }}
              >
                Opportunity Types
              </Typography.Text>
              <Select
                mode="multiple"
                style={{ width: 220 }}
                onChange={(value) => onOpportunityTypeOptionsChange(value)}
                options={opportunityOptions}
                disabled={isLoading}
                className="general-input-select"
              ></Select>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Typography.Text
                className="selection-header-title"
                style={{ margin: 0, color: "#1D2939", fontWeight: "500" }}
              >
                Year
              </Typography.Text>
              <Select
                placeholder="Please Select Data Source"
                style={{ width: 172 }}
                value={selectedYear}
                onChange={onYearChange}
                options={dropDownYearList}
                disabled={isLoading}
                className="general-input-select"
              ></Select>
            </div>
            <Dropdown
              overlay={filterItems}
              trigger={["click"]}
              placement="bottomLeft"
              visible={dropdownVisible}
            >
              <Button
                onClick={handleDropdownVisibleChange}
                style={{ border: "none" }}
              >
                <img src={settings} alt="Filters" />
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {isLoading ? (
        <Skeleton paragraph={{ rows: 6 }} active />
      ) : (
        <Row style={{ marginTop: 20, width: "100%" }}>
          <Col span={24}>
            {stageView ? (
              <Table
                dataSource={campaignTableData}
                columns={campaignColumnData}
                className="campaign-table non-print override-fixed-column-style custom-table general-table sales-table"
                style={{ width: "100%" }}
                size="small"
                pagination={false}
                scroll={{
                  x: 1000,
                }}
                rowClassName={(record, index) =>
                  record.name === "Total" ? "total-spend-text" : ""
                }
                onRow={(record) => ({
                  onClick: () => onTableRowClick(record),
                  style: recordData?.name &&
                    record.name === recordData?.name && {
                      backgroundColor: "#f0f0f0",
                      fontWeight: 600,
                    }, // add custom row color based on record.isSelected property
                })}
                bordered
                expandable={{
                  expandIcon: (props) => {
                    const style = {
                      marginTop: "2.5005px",
                      marginRight: "8px",
                      cursor: "pointer",
                      float: "right",
                      paddingTop: "6px",
                      display: "block",
                    };
                    if (!props?.record?.children) {
                      style.display = "none";
                    }
                    return props.expanded ? (
                      <span>
                        <img
                          style={style}
                          src={verticalCollapseImg}
                          onClick={(e) => props.onExpand(props.record, e)}
                          alt=""
                        />
                      </span>
                    ) : (
                      <span>
                        <img
                          style={style}
                          src={verticalExpandImg}
                          onClick={(e) => props.onExpand(props.record, e)}
                          alt=""
                        />
                      </span>
                    );
                  },
                }}
              />
            ) : (
              <Table
                dataSource={roiTableData}
                columns={roiColumnData}
                className="campaign-table non-print override-fixed-column-style custom-table general-table sales-table"
                style={{ width: "100%" }}
                size="small"
                bordered
                pagination={false}
                scroll={{
                  x: 1000,
                }}
                rowClassName={(record, index) =>
                  record.name === "Total" ? "total-spend-text" : ""
                }
                onRow={(record) => ({
                  onClick: () => onTableRowClick(record),
                  style: recordData?.name &&
                    record.name === recordData?.name && {
                      backgroundColor: "#f0f0f0",
                      fontWeight: 600,
                    }, // add custom row color based on record.isSelected property
                })}
                expandable={{
                  expandIcon: (props) => {
                    const style = {
                      marginTop: "2.5005px",
                      marginRight: "8px",
                      cursor: "pointer",
                      float: "right",
                      paddingTop: "6px",
                      display: "block",
                    };
                    if (!props?.record?.children) {
                      style.display = "none";
                    }
                    return props.expanded ? (
                      <span>
                        <img
                          style={style}
                          src={verticalCollapseImg}
                          onClick={(e) => props.onExpand(props.record, e)}
                          alt=""
                        />
                      </span>
                    ) : (
                      <span>
                        <img
                          style={style}
                          src={verticalExpandImg}
                          onClick={(e) => props.onExpand(props.record, e)}
                          alt=""
                        />
                      </span>
                    );
                  },
                }}
              />
            )}
          </Col>
        </Row>
      )}

      <Divider />

      {funnelChartData && funnelChartData.length > 0 && (
        <div>
          {stageView && (
            <>
              <Row
                className="analysis-chart-wrapper"
                style={{ border: "none", paddingLeft: 0 }}
              >
                {" "}
                <Typography.Text
                  className="label-title-text header2"
                  style={{ color: "#1D2939", fontWeight: 500 }}
                >
                  {!recordData?.children
                    ? recordData?.name === "Total"
                      ? ""
                      : "Campaign : "
                    : "Channel : "}
                  <span style={{ color: "#7F56D9" }}>{recordData?.name}</span>
                </Typography.Text>
              </Row>

              <Row
                justify="space-between"
                gutter={[20, 20]}
                style={{ marginBottom: "20px" }}
              >
                <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                  <div
                    className="executive-analysis panel-design"
                    style={{
                      marginBottom: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography.Text
                      className="analysis-title"
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      Budget Verified
                    </Typography.Text>
                    <Typography.Text
                      className="roi-Indicator"
                      style={{
                        color: "#7F56D9",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      $
                      {recordData?.verified_budget
                        ? numberWithCommasAndRemovedNoDecimals(
                            recordData?.verified_budget || 0
                          )
                        : 0}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                  <div
                    className="executive-analysis panel-design"
                    style={{
                      marginBottom: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography.Text
                      className="analysis-title"
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      Revenue Actual
                    </Typography.Text>
                    <Typography.Text
                      className="roi-Indicator"
                      style={{
                        color: "#7F56D9",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      $
                      {revenueActual
                        ? numberWithCommasAndRemovedNoDecimals(revenueActual)
                        : 0}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <Tooltip placement="top" title={RoiTooltipText}>
                  <div
                    className="executive-analysis panel-design"
                    style={{
                      marginBottom: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography.Text
                      className="analysis-title"
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      ROI
                    </Typography.Text>

                    <Typography.Text
                      className={
                        recordData?.verified_budget
                          ? revenueActual / recordData.verified_budget > 1
                            ? "roi-positive-Indicator"
                            : "roi-Indicator"
                          : "roi-Indicator"
                      }
                      style={{
                        color: "#7F56D9",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      {recordData?.verified_budget
                        ? numberWithCommasAndRemovedNoDecimals(revenueActual / recordData?.verified_budget) || 0
                        : 0}
                    </Typography.Text>
                  </div>
                  </Tooltip>
                </Col>
                <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                <Tooltip placement="top" title={PipelineTooltipText}>
                  <div
                    className="executive-analysis panel-design"
                    style={{
                      marginBottom: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography.Text
                      className="analysis-title"
                      style={{
                        color: "#1D2939",
                        fontWeight: 500,
                        fontSize: "18px",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      Investment to Pipeline
                    </Typography.Text>

                    <Typography.Text
                      className="roi-Indicator"
                      style={{
                        color: "#7F56D9",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      {totalStagePipeLineValue && recordData?.verified_budget
                        ? numberWithCommasAndRemovedNoDecimals(totalStagePipeLineValue / recordData?.verified_budget)
                        : 0}
                      X
                    </Typography.Text>
                  </div>
                  </Tooltip>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={[20, 20]}>
            {isLoading ? (
              <>
                <Col span={12}>
                  <Skeleton.Button
                    active
                    style={{ width: "36vw", height: 300 }}
                  />
                </Col>
                <Col span={12}>
                  <Skeleton.Button
                    active
                    style={{ width: "36vw", height: 300 }}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="analysis-chart-wrapper panel-design">
                    <Typography.Text
                      className="label-title-text header2"
                      style={{ color: "#1D2939", fontWeight: "500" }}
                    >
                      Sum of Actual Value by stage
                    </Typography.Text>
                    {funnelChartData.length > 0 && (
                      <FunnelCharts
                        data={funnelChartData}
                        color={[
                          // "#8becf9",
                          // "#75ECF9",
                          // "#4FE8FD",
                          // "#4DE1FD",
                          // "#0EE6FF",
                          // "#28DFF9",
                          // "#12D5EF",
                          // "#00C3DD",
                          // "#00B9D1",

                          // "#D6BBFB",
                          "#B692F6",
                          "#9E77ED",
                          "#7F56D9",
                          "#6941C6",
                          "#53389E",
                        ]}
                        // conversionTag= {true}
                        labelColor="#fff"
                        height={funnelChartData.length * 100}
                        transform={false}
                        isActual={true}
                      />
                    )}
                  </div>
                </Col>
                <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div
                    className="analysis-chart-wrapper panel-design"
                    style={{ height: "100%" }}
                  >
                    <div className="year-selector">
                      <div
                        style={{
                          borderBottom: "1px solid #D0D5DD",
                          marginBottom: "20px",
                        }}
                      >
                        <Typography.Text
                          style={{
                            fontSize: "20px",
                            marginLeft: "5px",
                            fontWeight: 600,
                            color: "#1D2939",
                            marginRight: "15px",
                          }}
                        >
                          Stage Selector
                        </Typography.Text>

                        <Select
                          value={selectedChartFilter}
                          disabled={isLoading}
                          placeholder={
                            <Typography.Text
                              style={{
                                fontSize: "16px",
                                fontWeight: 200,
                                marginBottom: "5px",
                              }}
                            >
                              Stage Selector
                            </Typography.Text>
                          }
                          style={{ width: 172, padding: "10px 0px" }}
                          onChange={onStageChange}
                        >
                          <Select.Option value="select_all">
                            Select All
                          </Select.Option>
                          {funnelChartData?.map((item) => (
                            <Select.Option value={item?.text}>
                              {item?.text}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <Typography.Text
                      className="label-title-text header2"
                      style={{ color: "#1D2939", fontWeight: 500 }}
                    >
                      Sum of Actual Value by Month and Stage
                    </Typography.Text>
                    <div style={{ marginTop: "20px" }}>
                      <Line {...LineChartConfig} />
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      )}
    </div>
  );
}

export default CampaignFunnelAnalysis;
