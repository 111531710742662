import * as actions from "../actions/index";

const initialState = {
  ActaualDataState: [],
  ActaualDataStateYear: [],
  ActaualDataFunnelState: {},
  ActualDataWebAnalytics: [],
  ActualDataWebGA4Analytics: [],
  isLoading: false,
  sentInvitation: "",
  configuredChartInfo: [],
  mappingSatges: {},
  accountStageMappingDetails: [],
  actualsReportDataOthers: [],
  funnelactualdataloader: false,
  funnelTrendsActualData: [],
  packagePropertyList: [],
  serverError:"",
  userPackages: [],
  SalesAndMarketingSelectedStage:[],
  SalesAndMarketingActualTableData: [],
  salesMareketingLeadershipData:[],
  ExecutiveReport:{},
  ConnectedCRMs :{},
  leadsDrawerOpen:false,
  leadsData: {

  }
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.SET_ACTUAL_REPORT_DATA:
      return { ...state, ActaualDataState: action.payload };
    
    case actions.SET_MAPPING_STAGES:
      return { ...state, mappingSatges: action.payload };

    case actions.SET_ACTUAL_REPORT_WEB_DATA:
      return { ...state, ActualDataWebAnalytics: action.payload };

    case actions.SET_ACTUAL_REPORT_DATA_YEARLY:
      return { ...state, ActaualDataStateYear: action.payload };

    case actions.SET_ACTUAL_REPORT_FUNNEL_DATA:
      return { ...state, ActaualDataFunnelState: action.payload };

    case actions.SHOW_LOADER:
      return { ...state, isLoading: action.payload };

    case actions.SET_INVITATION_SENT:
      return { ...state, sentInvitation: action.payload };

    case actions.SET_CONFIGURED_CHART_INFO:
      return { ...state, configuredChartInfo: action.payload };
      
    case actions.SET_ACCOUNT_STAGE_MAPPING_DATA:
      return { ...state, accountStageMappingDetails: action.payload };

    case actions.SET_ACTUAL_REPORT_DATA_OTHERS:
      return { ...state, actualsReportDataOthers: action.payload };
    
    case actions.SET_FUNNEL_ACTUAL_DATA_LOADER:
      return { ...state, funnelactualdataloader: action.payload };

    case actions.SET_LEADS_DRAWER:
        return { ...state, leadsDrawerOpen: action.payload };
        
    case actions.SET_LEADS_DATA:
          return { ...state, leadsData: action.payload };    

    case actions.SET_ACTUAL_REPORT_FUNNEL_TRENDS_DATA:
      return { ...state, funnelTrendsActualData: action.payload };

    case actions.SET_PACKAGE_PROPERTY:
      return { ...state, packagePropertyList: action.payload };

    case actions.SERVER_ERROR:
      return { ...state, serverError: action.payload };

    case actions.SET_USER_PACKAGES:
      return { ...state, userPackages: action.payload };
    
    case actions.SET_SALES_AND_MARKETING_ACTUALS:
      return { ...state, SalesAndMarketingActualTableData: action.payload };

    case actions.SET_SALES_MAREKETING_LEADERSHIP_DATA:
      return { ...state, salesMareketingLeadershipData: action.payload };
    
    case actions.SET_SALES_MARKETING_STAGES:
      return { ...state, SalesAndMarketingSelectedStage: action.payload };
    
    case actions.SET_EXECUTIVE_REPORTS_DATA:
      return { ...state, ExecutiveReport: action.payload };

    case actions.SET_CONNECTED_CRMS:
      return { ...state, ConnectedCRMs: action.payload };

    default:
      return state;
  }
};

export default reportReducer;
