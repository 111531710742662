import React, { Component } from "react";
import {Funnel} from "@ant-design/charts";

class FunnelCharts extends Component {
  render() {
    const {
      data,
      color,
      height,
      noStageText,
      conversionTag,
      labelColor,
      transform,
      isActual
    } = this.props;
    const config = {
      data,
      dynamicHeight: true,
      xField: "text",
      yField: "width",
      legend: false,
      conversionTag: conversionTag
        ? {
            offsetX: 2,
            offsetY: 0,
            formatter: (datum) => datum?.percentageValue + "%",
          }
        : false,
        
      height: height ? height : 600,
      appendPadding: conversionTag ? 31 : 20, 
      minSize: 0.2,
      maxSize: 1,
      color: color
        ? color
        : [
            // "#030D1D",
            // "#2e004d",
            // "#3d0066",
            // "#4c0080",
            // "#5c0099",
            // "#6b00b3",
            // "#7a00cc",
            // "#8a00e6",

            
            // "#D6BBFB",
            "#B692F6",
            "#9E77ED",
            "#7F56D9",
            "#6941C6",
            "#53389E",  
          ],
      label: {
        style: {
          fontSize: 14,
          lineWidth: 11,
          lineHeight: 22,
          textWrap: true,
          textOverfloe:"hide",
          fontWeight:500,
          fill: labelColor?labelColor:"#fff",
          
        },
        content: (action) => {
          if (noStageText) {
            return `${action.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        
          if (!action.value && !isActual) {
            return `${action.text}`;
          }
          return (
            `${action.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            `\n ${action.text}`
          );
        },
      },
      tooltip: null,
      point: {
        size: 5,
        shape: "Funnel",
      },
    };
    return (
      <div style={{ transform: transform ? "scale(1.1)" : "" }}>
        <Funnel {...config} chartRef={(ref) => (this.refs = ref)} />
      </div>
    );
  }
}
export default FunnelCharts;
