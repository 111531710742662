import * as actions from "../actions/index";

const initialState = {
    salesAndMarketingStages : []
  
};

const salesAndMarketingReducer = (state = initialState, action) => {
    switch(action.type){
        case actions.SET_SALES_AND_MARKETING_STAGES:
            return {
                ...state,
                salesAndMarketingStages: action.payload
              };
        default:
            return state;
    }
};

export default salesAndMarketingReducer;
