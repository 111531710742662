import { Column } from "@ant-design/charts";
import React from "react";

const GroupedColumnChart = (props) => {
  const { data } = props;
  const config = {
    data,
    isStack: true,
    xField: "x_axis",
    yField: "deals",
    seriesField: "break_by",
    legend: false,
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    color: "#7F56D9",
  };
  return <Column {...config} />;
};

export default GroupedColumnChart;
