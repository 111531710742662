/* eslint-disable react-hooks/exhaustive-deps */
import {
  MinusCircleTwoTone,
  PlusOutlined,
  Loading3QuartersOutlined,
  UploadOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  Tooltip,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCompanyPositioning,
  getCompanyPositioning,
  setLoader,
  UpdateCompanyPositioning,
} from "store/actions/goToMarketAction";
import {
  getaccountDetails,
  updateAccountCompanyInfo,
} from "store/actions/loginAction";
import pdfImg from "assets/Images/pdfImg.png";
import go_to_market_company_info_preview from "assets/Images/go_to_market_company_info_preview.png";
import { Document, Page } from "@react-pdf/renderer";
import { CompanyInfoCover, CompanyInformation } from "./PdfPages";
import PreviewInfo from "commons/PreviewInfo";
import ppt_icon from "../../assets/Images/ppt_icon.svg";
import { LightenDarkenColor } from "utility/context/CommonMethods";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

const { TextArea } = Input;

export default function CompanyInfo(props) {
  const {
    companyPositioning,
    hasUnsavedChanges,
    GTMMasterDoc,
    downloadPPTX,
    showSkeletonRoot,
    getSignedUrlImg,
  } = props;
  const [company_info_form] = Form.useForm();
  const [positioning_form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    accountDetails,
    groups: account,
    userPermission,
    viewType,
  } = useSelector((state) => state.loginReducer);
  const { isLoading } = useSelector((state) => state.goToMarketReducer);
  const [showSkeleton, setShowSkeleton] = useState(true);

  // GET COMPANY DETAILS
  useEffect(() => {
    // Object.keys(accountDetails)?.length === 0 &&
    // dispatch(getaccountDetails(account[0]))
    // dispatch(getCompanyPositioning())

    if (Object.keys(accountDetails)?.length === 0) {
      Promise.all([
        dispatch(getaccountDetails(account[0])),
        dispatch(getCompanyPositioning()),
      ]).then((values) => {
        setShowSkeleton(false);
      });
    } else {
      setShowSkeleton(false);
    }
  }, []);

  // SET COMPANY DETAILS
  useEffect(() => {
    company_info_form.setFieldsValue({
      company: accountDetails.company,
      url: accountDetails.url,
      company_description: accountDetails?.company_description
        ? accountDetails?.company_description
        : "",
      product_description: accountDetails?.product_description
        ? accountDetails?.product_description
        : "",
      tagline: accountDetails?.tagline ? accountDetails?.tagline : "",
    });
  }, [accountDetails, company_info_form]);

  // UPDATE COMPANY DETAILS
  const updateCompanyDetails = async (value) => {
    TrackClickFunction('demand-gen-gtm-intelligence-save-company-info-button');
    dispatch(setLoader(true));
    let inputdata = { id: account[0], ...value };
    await dispatch(updateAccountCompanyInfo(inputdata));
    dispatch(setLoader(false));
    props.sethasUnsavedChanges((old) => ({ ...old, companyDetails: false }));
  };

  //GET POSITIONING DETAILS
  const updatePositioning = async (value) => {
    TrackClickFunction('demand-gen-gtm-intelligence-save-competitive-positioning-button');
    dispatch(setLoader(true));
    let inputData = {
      account: account[0],
      ...value,
    };
    if (companyPositioning?.length > 0) {
      inputData = { ...inputData, id: companyPositioning[0]?.id };
      await dispatch(UpdateCompanyPositioning(inputData));
      dispatch(setLoader(false));
    } else {
      await dispatch(createCompanyPositioning(inputData));
    }
    dispatch(setLoader(false));
    props.sethasUnsavedChanges((old) => ({
      ...old,
      companyPositioning: false,
    }));
  };

  // SET POSITIONING DETAILS
  useEffect(() => {
    if (companyPositioning.length > 0) {
      positioning_form.setFieldsValue({
        ...companyPositioning[0],
      });
    } else {
      positioning_form.resetFields();
    }
  }, [companyPositioning]);

  const CompanyInfoDoc = useMemo(() => {
    return ({ logoUrl }) => {
      let companyData = {};
      if (companyPositioning?.length > 0) {
        companyData = { ...companyPositioning?.[0] };
      }
      companyData.name = accountDetails.company;
      companyData.url = accountDetails.url;
      companyData.company_description = accountDetails?.company_description
        ? accountDetails?.company_description
        : "-";
      companyData.product_description = accountDetails?.product_description
        ? accountDetails?.product_description
        : "-";
      companyData.tagline = accountDetails?.tagline
        ? accountDetails?.tagline
        : "-";
      companyData.primaryColor = accountDetails?.colors
        ? JSON.parse(accountDetails?.colors)?.[0]
        : "#0f61db";
      companyData.backgroundColor = LightenDarkenColor(
        accountDetails?.colors
          ? JSON.parse(accountDetails?.colors)?.[0]
          : "#0f61db",
        200
      );
      companyData.logoUrl = logoUrl;

      return (
        <Document>
          <Page
            orientation="landscape"
            style={{ paddingTop: 20, paddingBottom: 50, paddingHorzontal: 30 }}
          >
            <CompanyInfoCover
              logoUrl={{ logourl: logoUrl, url: accountDetails.url }}
            />
            <CompanyInformation {...companyData} />
          </Page>
        </Document>
      );
    };
  }, [companyPositioning, accountDetails]);

  return (
    <div>
      <Row justify="center" style={{ padding: "20px 10px" }}>
        <Col span={15} lg={15} md={20} sm={24} xs={24}>
          <h2 style={{ marginBottom: "40px", fontSize:'20px', background:"#f5f7fa", padding:"10px 5px", textAlign:'center'}} className="titleText fw500 panel-design">
            Company Information
          </h2>
          {showSkeleton || showSkeletonRoot ? (
            <Space style={{ width: "100%" }} direction="vertical">
              <Row justify="space-between">
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Input
                    active
                    style={{ width: "60vw" }}
                    size="large"
                  />
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 200, borderRadius: 7, marginTop: 10 }}
                  />
                </Col>
              </Row>
            </Space>
          ) : (
            <Form
              form={company_info_form}
              name="companyInfoForm"
              layout="vertical"
              autoComplete="off"
              onFinish={updateCompanyDetails}
              onChange={() => {
                props.sethasUnsavedChanges((old) => ({
                  ...old,
                  companyDetails: true,
                }));
              }}
              className="general-form"
            >
              <Row justify="space-between" gutter={32}>
                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="company"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Company Name
                      </small>
                    }
                  >
                    <Input
                      style={{ color: "#1D2939" }}
                      size="large"
                      disabled
                      placeholder="Company Name"
                      className="general-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="url"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Company Website
                      </small>
                    }
                  >
                    <Input
                      style={{ color: "#1D2939" }}
                      size="large"
                      disabled
                      placeholder="Company Website"
                      className="general-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="company_description"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Company Description
                      </small>
                    }
                  >
                    <TextArea
                      placeholder="Write short brief about company..."
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      className="general-input-textarea"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="product_description"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Product Description
                      </small>
                    }
                  >
                    <TextArea
                      placeholder="Mention short details about your product..."
                      autoSize={{ minRows: 4, maxRows: 5 }}
                      className="general-input-textarea"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="tagline"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Tagline
                      </small>
                    }
                  >
                    <Input
                      style={{ color: "#1D2939" }}
                      size="large"
                      placeholder="Tagline"
                      className="general-input"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Button loading={isLoading} type="primary" htmlType="submit" className="primaryButton" data-heap="demand-gen-gtm-intelligence-save-company-info-button">
                Save
              </Button>
              {hasUnsavedChanges?.companyDetails && (
                <span
                  className="conversation-difference-dot"
                  style={{ opacity: 1, marginLeft: 12 }}
                ></span>
              )}
            </Form>
          )}
          <Divider style={{ borderTopColor: "#D0D5DD !important" }} />
          <h2 className="titleText fw500 panel-design" style={{ margin: "30px 0", fontSize:'20px', background:"#f5f7fa", padding:"10px 5px", textAlign:'center' }}>
            Competitive and Company Positioning
          </h2>
          {showSkeleton || showSkeletonRoot ? (
            <Space>
              <Row justify="space-between">
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Input
                    active
                    style={{ width: "60vw" }}
                    size="large"
                  />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Input
                    active
                    style={{ width: "60vw" }}
                    size="large"
                  />
                </Col>
                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 100, borderRadius: 7, marginTop: 10 }}
                  />
                </Col>
              </Row>
            </Space>
          ) : (
            <Form
              name="basic"
              layout="vertical"
              form={positioning_form}
              onFinish={updatePositioning}
              autoComplete="off"
              requiredMark={false}
              onChange={() => {
                props.sethasUnsavedChanges((old) => ({
                  ...old,
                  companyPositioning: true,
                }));
              }}
              className="general-form"
            >
              <Row justify="space-between">
                {/* <Col span={11} lg={11} md={11} sm={24} xs={24}>
                            <Form.Item
                                name="name"
                                label={<small>Competitor Name</small>}
                            >
                                <Input style={{color: 'black'}} size='large' placeholder='Competitor Name'/>
                            </Form.Item>
                        </Col>
                        <Col span={11} lg={11} md={11} sm={22} xs={22}>
                            <Form.Item
                                name="website"
                                label={<small>Website URL</small>}
                            >
                                <Input  style={{ color: 'black'}} size='large' placeholder='Website URL'/>
                            </Form.Item>
                        </Col>
                        <Col span={1} lg={1} md={1} sm={2} xs={2} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <MinusCircleFilled style={{color:"grey", fontSize:18}}/>
                        </Col> */}
                <Form.List
                  name="competitive"
                  initialValue={[{ competitor_name: null, website: null }]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <>
                          <Col span={11} lg={11} md={11} sm={24} xs={24}>
                            <Form.Item
                              {...field}
                              name={[field.name, "competitor_name"]}
                              fieldKey={[field.fieldKey, "competitor_name"]}
                              label={
                                <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                                  Competitor Name
                                </small>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Competitor name!",
                                },
                              ]}
                            >
                              <Input
                                style={{ color: "#1D2939" }}
                                size="large"
                                placeholder="Competitor Name"
                                className="general-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={11} lg={11} md={11} sm={22} xs={22}>
                            <Form.Item
                              {...field}
                              name={[field.name, "website"]}
                              fieldKey={[field.fieldKey, "website"]}
                              label={
                                <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                                  Website URL
                                </small>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter competitor website",
                                },
                                {
                                  type: "url",
                                  message:
                                    "Please enter valid url (including https://)",
                                },
                              ]}
                            >
                              <Input
                                style={{ color: "#1D2939" }}
                                size="large"
                                placeholder="Website URL"
                                className="general-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={1}
                            lg={1}
                            md={1}
                            sm={2}
                            xs={2}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <MinusCircleTwoTone
                              onClick={() => remove(fields.indexOf(field))}
                              style={{ fontSize: 18}}
                              className="add-icon"
                            />
                          </Col>
                        </>
                      ))}
                      <Tooltip
                        title={
                          fields?.length >= 5
                            ? "You can only add upto 5 competitors"
                            : ""
                        }
                      >
                        <Button
                          type="text"
                          onClick={() => add()}
                          disabled={fields?.length >= 5}
                          style={{
                            color: fields?.length >= 5 ? "grey" : "#7F56D9",
                            borderBottom: `1px solid ${
                              fields?.length >= 3 ? "grey" : "#7F56D9"
                            }`,
                            padding: 0,
                            fontWeight: 700,
                            marginBottom: 30,
                          }}
                        >
                          <PlusOutlined />
                          Add More
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Form.List>

                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="key_differentiators"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Key Differentiators
                      </small>
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 4, maxRows: 5 }}
                      placeholder="What sets you apart from your competitors, both on a product and service level?"
                      className="general-input-textarea"
                    />
                  </Form.Item>
                </Col>

                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Form.Item
                    name="value_proposition"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Value Proposition
                      </small>
                    }
                  >
                    <Input
                      style={{ color: "#1D2939" }}
                      size="large"
                      placeholder="Value Proposition"
                      className="general-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Form.Item
                    name="awards"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Any awards or accolades received?
                      </small>
                    }
                  >
                    <Input
                      style={{ color: "#1D2939" }}
                      size="large"
                      placeholder="Awards"
                      className="general-input"
                    />
                  </Form.Item>
                </Col>

                <Col span={24} lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="offers"
                    label={
                      <small className="titleText fw700" style={{marginBottom:'5px', display:'block'}}>
                        Offers or incentives to leverage?
                      </small>
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 4, maxRows: 5 }}
                      placeholder="ex: 24/7 Support, Quick Onboarding, Free Setup, Dedicated Support, Any Guarantees, Free Trial, Gift Cards, Free Consultation/evaluation"
                      className="general-input-textarea"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Button loading={isLoading} type="primary" htmlType="submit" className="primaryButton" data-heap="demand-gen-gtm-intelligence-save-competitive-positioning-button">
                Save
              </Button>
              {hasUnsavedChanges.companyPositioning && (
                <span
                  className="conversation-difference-dot"
                  style={{ opacity: 1, marginLeft: 12 }}
                ></span>
              )}
            </Form>
          )}
          <Divider />

          <Row
            gutter={[10, 10]}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              borderRadius: 7,
              marginTop: 30,
              marginBottom: 15,
            }}
            data-tour="tour__Export"
          >
            <Col span={24} style={{ display: "flex" }}>
              <div style={{width:"100%"}}>
                <h3 className="panel-design" style={{ marginBottom: "30px", fontSize:'20px', background:"#f5f7fa", padding:"10px 5px", textAlign:'center', display:"block", fontWeight:500 }}>
                  Download PDFs & PPTx
                </h3>{" "}
              </div>
              <div style={{ paddingLeft: 10 }}>
                {props.loadingPDF && (
                  <Loading3QuartersOutlined
                    spin
                    style={{
                      fontSize: 18,
                      float: "right",
                      marginTop: 5,
                      marginRight: 8,
                    }}
                  />
                )}
              </div>
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <Col>
                  <div
                    style={{
                      background: "#f5f7fa",
                      padding: 15,
                      borderRadius: 10,
                    }}
                    className="panel-design"
                  >
                    <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                    <p>
                      <strong>Company Info PDF</strong>
                      <br /> with all the details
                      <br /> included above
                    </p>
                    <div style={{display:'flex', alignItems:"center"}}>
                      <Button
                        data-heap="demand-gen-gtm-intelligence-company-info-pdf-tile-company-info-tab"
                        onClick={async () => {
                          TrackClickFunction('demand-gen-gtm-intelligence-company-info-pdf-tile-company-info-tab');
                          let url = await getSignedUrlImg(
                            accountDetails?.profile_url
                          );
                          props.generatePdfDocument(
                            "Company information.pdf",
                            <CompanyInfoDoc logoUrl={url} />
                          );
                          
                        }}
                        className="download-btn tertiaryButton"
                        type="primary"
                        style={{ width:'49%', fontSize:'14px', height:'34px'}}
                        icon={
                          <UploadOutlined
                            style={{ fontSize: 13, verticalAlign: "middle" }}
                          />
                        }
                      >
                        Export
                      </Button>
                      <Button
                        className="download-btn tertiaryButton"
                        onClick={() => {
                          props.emailPdfDocument(
                            "Demand Generation - Go to Marketing Intelligence Company Information",
                            <CompanyInfoDoc />
                          );
                        }}
                        style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                        type="primary"
                        icon={
                          <MailOutlined
                            style={{ fontSize: 13, verticalAlign: "middle" }}
                          />
                        }
                      >
                        Email
                      </Button>
                    </div>
                  </div>
                </Col>
              )}
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <Col>
                  <div
                    style={{
                      background: "#f5f7fa",
                      padding: 15,
                      borderRadius: 10,
                    }}
                    className="panel-design"
                  >
                    <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                    <p>
                      <strong>Master PDF</strong> with all the <br />
                      GTM modules details
                      <br /> included in one PDF
                    </p>
                    <div style={{display:'flex', alignItems:"center"}}>
                    <Button
                      data-heap="demand-gen-gtm-intelligence-master-pdf-tile-company-info-tab"
                      onClick={async () => {
                        TrackClickFunction('demand-gen-gtm-intelligence-master-pdf-tile-company-info-tab');
                        let url = await getSignedUrlImg(
                          accountDetails?.profile_url
                        );
                        props.generatePdfDocument(
                          "Go to Marketing Master.pdf",
                          <GTMMasterDoc logoUrl={url} />
                        );
                      }}
                      className="download-btn tertiaryButton"
                      type="primary"
                      icon={<UploadOutlined style={{ fontSize: 13 }} />}
                      style={{ width:'49%', fontSize:'14px', height:'34px'}}
                    >
                      Export
                    </Button>
                    <Button
                      className="download-btn tertiaryButton"
                      onClick={() => {
                        props.emailPdfDocument(
                          "Demand Generation - Go to Marketing Intelligence Master",
                          <GTMMasterDoc />
                        );
                      }}
                      style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                      type="primary"
                      icon={
                        <MailOutlined
                          style={{ fontSize: 13, verticalAlign: "middle" }}
                        />
                      }
                    >
                      Email
                    </Button>
                    </div>
                  </div>
                </Col>
              )}
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <Col>
                  <div
                    style={{
                      background: "#f5f7fa",
                      padding: 15,
                      borderRadius: 10,
                    }}
                    className="panel-design"
                  >
                    <img src={ppt_icon} style={{ marginBottom: 10 }} alt="" />
                    <p>
                      <strong>Complete presentation</strong>
                      <br /> of all the GTM modules
                      <br /> details entered
                    </p>
                    <div style={{display:'flex', alignItems:"center"}}>
                    <Button
                      data-heap="demand-gen-gtm-intelligence-complete-presentation-title-company-info-tab"
                      className="download-btn tertiaryButton"
                      icon={
                        <UploadOutlined
                          style={{ fontSize: 13, verticalAlign: "middle" }}
                        />
                      }
                      type="primary"
                      onClick={() => {
                        TrackClickFunction('demand-gen-gtm-intelligence-complete-presentation-company-info-tab');
                        downloadPPTX();

                      }}
                      style={{ width:'49%', fontSize:'14px', height:'34px' }}
                    >
                      Export
                    </Button>
                    <Button
                      className="download-btn tertiaryButton"
                      style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                      icon={
                        <MailOutlined
                          style={{ fontSize: 13, verticalAlign: "middle" }}
                        />
                      }
                      type="primary"
                      onClick={props.emailPPTXDocument}
                    >
                      Email
                    </Button>
                    </div>
                  </div>
                </Col>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
