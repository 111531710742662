import { Pie } from "@ant-design/plots";
import { nFormatter, numberWithCommas } from "utility/context/CommonMethods";

const PieChart = (props) => {
  const {totalspend} = props
  let data = [];
  props.plannedBudget?.campaignDataBudget?.map((campaignData, index) => {
    if (index !== (props.plannedBudget?.campaignDataBudget.length-1) && index !== (props.plannedBudget?.campaignDataBudget.length -2) && campaignData["fy-totals"]) {
      let budgetPlannedQuarter = campaignData['fy-totals'].projected;
      if(budgetPlannedQuarter > 0) {
        data.push({
          type: campaignData["channel-campaign"],
          value: budgetPlannedQuarter,
        });
      }
    }
    return true;
  });
  
  data = data.length === 0 ? [{type: 'No data found', value:0}]:data
  console.log("props.plannedBudget",props.plannedBudget);
  var config = {
    appendPadding: 10,
    animation: false,
    data: data,
    meta: {
      value: {
        formatter: (v) => {
          return `${isNaN(((v*100)/totalspend).toFixed(2)) ? 0 : (((v*100)/totalspend).toFixed(2)) } %`;
        },
      },
    },

    angleField: "value",
    colorField: "type",
    color: ['#0fa4ec', '#7A5AF8', '#e5699c', '#fb6513', '#F63D68'],
    radius: 1,
    innerRadius: 0.7,
    legend: {
      position: "right",
    },
    // legend:false,
    
    label: {
      type: "inner",
      offset: "-50%",
      content: "",
      style: {
        textAlign: "center",
        fontSize: 9,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        formatter: function formatter() {
          return "";
        },
      },
    },

    annotations: [
      {
        type: "text",
        content: totalspend ? `$${numberWithCommas(totalspend)}` : 0, // Text to display in the center
        position: ["50%", "55%"],
        style: {
          textAlign: "center",
          fontSize: 28, 
          fill: "#555f6b", 
          fontWeight:"700"
        },
      },
      {
        type: "text",
        content: "Budget Planned", 
        position: ["50%", "45%"], 
        style: {
          fontSize: 13,
          lineHeight: "18px",
          fontWeight: '500',
          fill: "#475467",
          textAlign:'center'
        },
      },
    ],
    
    
  };
  return <Pie {...config} />;
};

export default PieChart;
