/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  AlignRightOutlined,
  DeleteFilled,
  EditFilled,
  FileOutlined,
  FilePdfOutlined,
  UploadOutlined,
  MailOutlined,
  FileWordOutlined,
  MoreOutlined,
  PlusOutlined,
  UndoOutlined,
  YoutubeFilled,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Input,
  Select,
  Button,
  Divider,
  Dropdown,
  Menu,
  List,
  Typography,
  Modal,
  Empty,
  Tooltip,
  Form,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useState, useMemo } from "react";
import "./gotomarket.less";
import CreateAsset from "./CreateAsset";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAssetLibrary,
  getAssetLibraryList,
} from "store/actions/goToMarketAction";
import binIcon from "../../assets/Images/bin_icon.svg";
import go_to_market_assets_preview from "../../assets/Images/go_to_market_assets_preview.png";
import PreviewInfo from "commons/PreviewInfo";
import { Page, Document } from "@react-pdf/renderer";
import pdfImg from "assets/Images/pdfImg.png";

//import defaultImage from "assets/Images/default4AM.png";
import defaultImage from "assets/Images/4amDefault/defaultXfactor.png"
import ppt_icon from "../../assets/Images/ppt_icon.svg";

import { AssetInfoCover, AssetLibraryInfo } from "./PdfPages";
import {
  LightenDarkenColor,
  removeDuplicateFromArray,
  toCapitalize,
} from "utility/context/CommonMethods";
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import ebookDefault from 'assets/Images/4amDefault/ebookDefault.png'
import videoDefault from 'assets/Images/4amDefault/videoDefault.png'
import webinarDefault from 'assets/Images/4amDefault/webinarDefault.png'
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'

const { Search } = Input;
const { Option } = Select;

export default function AssetsLibrary(props) {
  const { GTMMasterDoc, downloadPPTX, showSkeletonRoot, getSignedUrlImg } =
    props;

  const [visible, setVisible] = useState(false);
  const {
    groups: account,
    userPermission,
    viewType,
    accountDetails,
  } = useSelector((state) => state.loginReducer);
  const { AssetLibrary, TargetIdealCustomProfile, Persona } = useSelector(
    (state) => state.goToMarketReducer
  );
  const dispatch = useDispatch();
  const [Assets, setAssets] = useState({});
  const [AssetsBackup, setAssetsBackup] = useState({});
  const [AssetToDelete, setAssetToDelete] = useState({});
  const [AssetToEdit, setAssetToEdit] = useState({});
  const [searchText, setsearchText] = useState("");
  const [AssetCount, setAssetCount] = useState(0);


  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    dispatch(getAssetLibraryList()).then(() => {
      setShowSkeleton(false);
    });
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  const sortByDate = (a, b) => {
    if (a.created_date < b.created_date) {
      return -1;
    }
    if (a.created_date > b.created_date) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    let dataObj = {};
    AssetLibrary.sort(sortByDate);
    AssetLibrary.forEach((item) => {
      if (item?.metadata) {
        item.Desc = JSON.parse(item?.metadata)?.description;
        item.Img_Url = JSON.parse(item?.metadata)?.Img_Url
          ? JSON.parse(item?.metadata)?.Img_Url
          : "";
        item.thumbnailKey = JSON.parse(item?.metadata)?.thumbnailKey;
      }
      delete item.createdAt;
      delete item.updatedAt;
      delete item.account;
      delete item.thumbnail;
      delete item.metadata;

      if (String(item?.hosted_url).includes(".pdf")) {
        item.filetype = "pdf";
      } else if (
        String(item?.hosted_url).includes(".doc") ||
        String(item?.hosted_url).includes(".docx")
      ) {
        item.filetype = "doc";
      } else if (
        String(item?.hosted_url).includes("youtube") ||
        String(item?.hosted_url).includes("vimeo")
      ) {
        item.filetype = "video";
      } else {
        item.filetype = "default";
      }

      dataObj[item?.type] = dataObj[item?.type]
        ? [...dataObj[item?.type], item]
        : [item];
    });
    dataObj?.["VIDEO"]?.map((item) => {
      item.video_id = "-";
      item.video_id = String(item?.hosted_url).includes("youtube")
        ? String(item?.hosted_url).split("v=")[
            String(item?.hosted_url).split("v=")?.length - 1
          ]
        : "-";
    });
    let count = 0;
    Object.keys(dataObj).forEach((key) => {
      count += dataObj[key]?.length;
    });
    setAssetCount(count);

    setAssets(dataObj);
    setAppliedFilters({});
    setsearchText("");
    setAssetsBackup(dataObj);
  }, [AssetLibrary]);

  const handleDeleteAsset = () => {
    let inputData = {
      id: AssetToDelete.id,
    };
    dispatch(DeleteAssetLibrary(inputData));
    setAssetToDelete({});
  };

  const AssetLibraryPage = useMemo(() => {
    return ({ logoUrl }) => {
      let dataObj = [];
      Object.keys(Assets).map((item) => {
        dataObj.push(...Assets?.[item]);
      });
      dataObj.sort(sortByDate);
      let styleObj = {};
      styleObj.primaryColor = accountDetails?.colors
        ? JSON.parse(accountDetails?.colors)?.[0]
        : "#0f61db";
      styleObj.backgroundColor = LightenDarkenColor(
        accountDetails?.colors
          ? JSON.parse(accountDetails?.colors)?.[0]
          : "#0f61db",
        200
      );
      styleObj.logoUrl = logoUrl;

      return (
        <Document>
          <Page
            orientation="landscape"
            style={{ paddingTop: 20, paddingBottom: 50, paddingHorzontal: 30 }}
          >
            <AssetInfoCover {...styleObj} />
            <AssetLibraryInfo
              Assets={dataObj}
              AppliedFilters={AppliedFilters}
              filterKeys={{
                type: "Type",
                target_market_segment: "Target market segment",
                industries: "Industries",
                regions: "Regions",
                persona: "Persona",
              }}
              {...styleObj}
            />
          </Page>
        </Document>
      );
    };
  }, [Assets]);

  // Filters
  const [AppliedFilters, setAppliedFilters] = useState({});
  const [availableFilters, setavailableFilters] = useState({});
  const [ApplyFilterModelOpen, setApplyFilterModelOpen] = useState(false);
  const [hasFilterApplied, sethasFilterApplied] = useState(false);

  const [filterForm] = Form.useForm();

  const fetchAvailableFiltersFromData = (assets) => {
    let dataObj = {
      type: ["ALL"],
    };
    Object.keys(assets).forEach((key) => {
      dataObj["type"] = dataObj["type"] ? [...dataObj["type"], key] : [key];
      assets[key]?.map((item) => {
        if (item?.industries)
          dataObj["industries"] =
            item?.industries && dataObj["industries"]
              ? [...dataObj["industries"], ...item?.industries]
              : [...item?.industries];

        if (item?.market_segments)
          dataObj["market_segments"] =
            item?.market_segments && dataObj["market_segments"]
              ? [...dataObj["market_segments"], ...item?.market_segments]
              : [...item?.market_segments];

        if (item?.regions)
          dataObj["regions"] = dataObj["regions"]
            ? [...dataObj["regions"], ...item?.regions]
            : [...item?.regions];

        if (item?.personas)
          dataObj["personas"] =
            item?.personas && dataObj["personas"]
              ? [...dataObj["personas"], ...item?.personas]
              : [...item?.personas];
      });
    });
    Object.keys(dataObj).map((key) => {
      dataObj[key] = removeDuplicateFromArray(dataObj[key]);
    });
    setavailableFilters(dataObj);
  };
  useEffect(() => {
    fetchAvailableFiltersFromData(AssetsBackup);
  }, [AssetsBackup]);

  const HandleApplyFilters = (values) => {
    setAppliedFilters(values);
    setApplyFilterModelOpen(false);
  };
  const resetAppliedFilterForm = () => {
    filterForm.resetFields();
    setAppliedFilters({});
    setApplyFilterModelOpen(false);
  };

  const ApplySelectedFilters = () => {
    sethasFilterApplied(
      Boolean(Object.keys(AppliedFilters).length) || searchText
    );
    setAssetCount(0);
    let searched = String(searchText).toLowerCase();
    let dataObj = {};

    Object.keys(AppliedFilters).map((key) => {
      if (!AppliedFilters[key]) delete AppliedFilters[key];
    });

    Object.keys(AssetsBackup).map((key) => {
      let a = AssetsBackup[key].filter(
        (item) =>
          String(item["Desc"]).toLowerCase().includes(searched) ||
          String(item["filename"]).toLowerCase().includes(searched) ||
          String(item["name"]).toLowerCase().includes(searched) ||
          String(item["hosted_url"]).toLowerCase().includes(searched) ||
          String(item["type"]).toLowerCase().includes(searched)
      );
      if (
        AppliedFilters?.type?.includes("ALL") ||
        AppliedFilters?.type?.includes(key) ||
        Object.keys(AppliedFilters).length === 0
      ) {
        dataObj[key] = a;
      }
    });
    let data = dataObj;

    if (AppliedFilters["industries"]) {
      let filteredRegion = {};
      Object.keys(data).forEach((key) => {
        data[key]?.map((item) => {
          item["industries"]?.map((value) => {
            if (AppliedFilters["industries"]?.includes(value))
              if (!data[key]?.includes(item))
                data[key] = data[key] ? [...data[key], item] : [item];
          });
        });
      });
      data = filteredRegion;
    }

    if (AppliedFilters["regions"]) {
      let filteredRegion = {};
      Object.keys(data).forEach((key) => {
        data[key]?.map((item) => {
          item["regions"]?.map((value) => {
            if (AppliedFilters["regions"]?.includes(value))
              if (!filteredRegion[key]?.includes(item))
                filteredRegion[key] = filteredRegion[key]
                  ? [...filteredRegion[key], item]
                  : [item];
          });
        });
      });
      data = filteredRegion;
    }

    if (AppliedFilters["target_market_segment"]) {
      let filteredRegion = {};
      Object.keys(data).forEach((key) => {
        data[key]?.map((item) => {
          item["market_segments"]?.map((value) => {
            if (AppliedFilters["target_market_segment"]?.includes(value))
              if (!filteredRegion[key]?.includes(item))
                filteredRegion[key] = filteredRegion[key]
                  ? [...filteredRegion[key], item]
                  : [item];
          });
        });
      });
      data = filteredRegion;
    }

    if (AppliedFilters["persona"]) {
      let filteredRegion = {};
      Object.keys(data).forEach((key) => {
        data[key]?.map((item) => {
          item["personas"]?.map((value) => {
            if (AppliedFilters["persona"]?.includes(value))
              if (!filteredRegion[key]?.includes(item))
                filteredRegion[key] = filteredRegion[key]
                  ? [...filteredRegion[key], item]
                  : [item];
          });
        });
      });
      data = filteredRegion;
    }

    setAssets(Object.keys(AppliedFilters).length > 0 ? data : dataObj);
  };

  useEffect(() => {
    if (Boolean(Object.keys(AppliedFilters).length === 0)) {
      filterForm.resetFields();
    }
    ApplySelectedFilters();
  }, [AppliedFilters]);

  useEffect(() => {
    setAssetCount(Object.keys(Assets).length > 0 ? 1 : 0);
  }, [Assets]);

  return (
    // :
    //  showSkeleton || showSkeletonRoot ? <>
    //  <Skeleton  paragraph={{ rows: 20 }} style={{padding:20}}  size = "large"/>
    //  </>
    <div>
      {/* {isLoading && <div className="loading">Loading;</div>} */}
      <Row
        justify="center"
        style={{ padding: "10px" }}
        className="systemsProcess targetAudience AssetLibrary"
      >
        <Col span={15} lg={15} md={20} sm={24} xs={24}>
          {showSkeleton || showSkeletonRoot ? (
            <Space style={{ width: "100%" }} direction="vertical">
              <Row justify="space-between">
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 200, borderRadius: 7 }}
                  />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton.Button
                    active
                    size="large"
                    shape="round"
                    style={{ width: 200, borderRadius: 7 }}
                  />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
                <Col span={11} lg={11} md={11} sm={24} xs={24}>
                  <Skeleton active size="large" />
                </Col>
              </Row>
            </Space>
          ) : (
            <Row style={{marginTop:'30px'}}>
              <Col
                span={24}
                lg={24}
                md={24}
                xs={24}
                sm={24}
               
              >
                <h3 className="panel-design" style={{ marginBottom: "30px", fontSize:'20px', background:"#f5f7fa", padding:"10px 5px", textAlign:'center', display:"block", fontWeight:500 }}>All Assets</h3>
              </Col>

              <Col span={24} lg={24} md={24} xs={24} sm={24} style={{marginBottom:'30px'}}> 
                <div
                  className="SearchAssets"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Search
                    placeholder="Search asset"
                    className="assetSearch"
                    onSearch={ApplySelectedFilters}
                    style={{ width: "250px" }}
                    value={searchText}
                    onChange={(e) => {
                      setsearchText(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {Object.keys(AssetsBackup)?.length > 0 && hasFilterApplied && (
                      <Tooltip title={"Reset Filters"}>
                        <span
                          style={{
                            padding: "0px 0px",
                            fontSize: 22,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setAppliedFilters({});
                            filterForm.resetFields();
                            setsearchText("");
                          }}
                        >
                          {" "}
                          <UndoOutlined />
                        </span>
                      </Tooltip>
                    )}
                    {Object.keys(AssetsBackup)?.length > 0 && (
                      <Tooltip title={"Apply FIlters"}>
                        <span
                          style={{
                            padding: "0px 15px",
                            fontSize: 22,
                            cursor: "pointer",
                          }}
                          onClick={() => setApplyFilterModelOpen(true)}
                        >
                          {" "}
                          {hasFilterApplied && (
                            <span
                              className="conversation-difference-dot"
                              style={{ opacity: 1 }}
                            ></span>
                          )}{" "}
                          <AlignRightOutlined />
                        </span>
                      </Tooltip>
                    )}
                    <Button
                      type="primary"
                      style={{ height: "35px", width:'130px' }}
                      onClick={() => {
                        TrackClickFunction('demand-gen-gtm-intelligence-add-asset-button');
                        setVisible(true)
                      }}
                      icon={<PlusOutlined />}
                      className="secondaryButton"
                    >
                      Add Asset
                    </Button>
                  </div>
                </div>
              </Col>

              {Object.keys(AssetsBackup)?.length > 0 ? (
                <>
                  {Assets["VIDEO"] && Assets["VIDEO"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 600,
                            margin: "30px 0 20px 0",
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Videos
                        </h3>
                      </Col>

                      <Row gutter={[15, 15]} style={{ width: "100%" }}>
                        {Assets["VIDEO"]?.map((video) => (
                          <Col span={12} lg={12} md={12} xs={24} sm={24}>
                            <div className="VideoWrapper videos">
                              {video.thumbnailKey ? (
                                <div className="Amplify-image-wrapper Amplify-image-wrapper-video">
                                  <AmplifyS3Image
                                    className="AmplifyImage"
                                    level="protected"
                                    key={video.thumbnailKey.split("/")[1]}
                                    identityId={
                                      video.thumbnailKey.split("/")[0]
                                    }
                                    style={{
                                      "--height": "100%",
                                      "--width": "100%",
                                    }}
                                    path={video.thumbnailKey.split("/")[1]}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={
                                    video?.Img_Url
                                      ? video?.Img_Url
                                      : videoDefault
                                  }
                                  alt=""
                                  className="AssetImage"
                                  onClick={() => window.open(video?.hosted_url)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}

                              <Dropdown
                                className="edit"
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToEdit(video)}
                                      >
                                        <EditFilled /> Edit Asset
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToDelete(video)}
                                      >
                                        <DeleteFilled /> Delete Asset
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  shape="circle"
                                />
                              </Dropdown>
                            </div>
                            <Typography.Title
                              level={5}
                              style={{ marginTop: 10 }}
                            >
                              {video.name}
                            </Typography.Title>
                            <Typography.Text>{video?.Desc}</Typography.Text>
                          </Col>
                        ))}
                      </Row>

                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["EBOOK"] && Assets["EBOOK"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 700,
                            color:"#1D2939",
                            fontSize:'18px',
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Ebooks
                        </h3>
                      </Col>

                      <Col
                        span={24}
                        lg={24}
                        md={24}
                        xs={24}
                        sm={24}
                        className="whitepaper"
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={Assets["EBOOK"]}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Button
                                  style={{ color: "#7F56D9" }}
                                  type="text"
                                  onClick={() => {
                                    window.open(item?.hosted_url);
                                  }}
                                >
                                  Download
                                </Button>,
                                <Dropdown
                                  className="edit"
                                  overlay={
                                    <Menu>
                                      <Menu.Item>
                                        <span
                                          onClick={() => setAssetToEdit(item)}
                                        >
                                          <EditFilled /> Edit Asset
                                        </span>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <span
                                          onClick={() => setAssetToDelete(item)}
                                        >
                                          <DeleteFilled /> Delete Asset
                                        </span>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  placement="bottomRight"
                                >
                                  <Button
                                    type="text"
                                    icon={<MoreOutlined />}
                                    shape="circle"
                                  />
                                </Dropdown>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                  item.filetype === "pdf" ? (
                                    <FilePdfOutlined
                                      style={{ color: "#EB6566", fontSize: 35 }}
                                    />
                                  ) : item.filetype === "doc" ? (
                                    <FileWordOutlined
                                      style={{ color: "#0F61DB", fontSize: 35 }}
                                    />
                                  ) : item.filetype === "video" ? (
                                    <YoutubeFilled
                                      style={{ fontSize: 35, color: "#EB6566" }}
                                    />
                                  ) : (
                                    // <FileOutlined style={{ fontSize: 35 }} />
                                    <img src={ebookDefault} alt="Icon"/>
                                  )
                                }
                                title={item?.name}
                                description={item.filename}
                                style={{alignItems:'center'}}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>

                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["WHITEPAPER"] && Assets["WHITEPAPER"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 600,
                           
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          White Papers
                        </h3>
                      </Col>

                      <Col
                        span={24}
                        lg={24}
                        md={24}
                        xs={24}
                        sm={24}
                        className="whitepaper"
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={Assets["WHITEPAPER"]}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Button
                                  style={{ color: "#0F61DB" }}
                                  type="text"
                                  onClick={() => {
                                    window.open(item?.hosted_url);
                                  }}
                                >
                                  Download
                                </Button>,
                                <Dropdown
                                  className="edit"
                                  overlay={
                                    <Menu>
                                      <Menu.Item>
                                        <span
                                          onClick={() => setAssetToEdit(item)}
                                        >
                                          <EditFilled /> Edit Asset
                                        </span>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <span
                                          onClick={() => setAssetToDelete(item)}
                                        >
                                          <DeleteFilled /> Delete Asset
                                        </span>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  placement="bottomRight"
                                >
                                  <Button
                                    type="text"
                                    icon={<MoreOutlined />}
                                    shape="circle"
                                  />
                                </Dropdown>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                  item.filetype === "pdf" ? (
                                    <FilePdfOutlined
                                      style={{ color: "#EB6566", fontSize: 35 }}
                                    />
                                  ) : item.filetype === "doc" ? (
                                    <FileWordOutlined
                                      style={{ color: "#0F61DB", fontSize: 35 }}
                                    />
                                  ) : item.filetype === "video" ? (
                                    <YoutubeFilled
                                      style={{ fontSize: 35, color: "#EB6566" }}
                                    />
                                  ) : (
                                    // <FileOutlined style={{ fontSize: 35 }} />
                                    <img src={ebookDefault} alt="Icon"/>
                                  )
                                }
                                title={item?.name}
                                description={item.filename}
                                style={{alignItems:'center'}}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>

                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["CASESTUDY"] && Assets["CASESTUDY"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 600,
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Case Study
                        </h3>
                      </Col>

                      <Row gutter={[15, 15]} style={{ width: "100%" }}>
                        {Assets["CASESTUDY"]?.map((item) => (
                          <Col span={12} lg={12} md={12} xs={24} sm={24}>
                            <div className="VideoWrapper">
                            {item.thumbnailKey ? (
                                <div className="Amplify-image-wrapper">
                                  <AmplifyS3Image
                                    className="AmplifyImage"
                                    level="protected"
                                    key={item.thumbnailKey.split("/")[1]}
                                    identityId={
                                      item.thumbnailKey.split("/")[0]
                                    }
                                    style={{
                                      "--height": "100%",
                                      "--width": "100%",
                                    }}
                                    path={item.thumbnailKey.split("/")[1]}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={
                                    item?.Img_Url
                                      ? item?.Img_Url
                                      : videoDefault
                                  }
                                  alt=""
                                  className="AssetImage"
                                  onClick={() => window.open(item?.hosted_url)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                              <Dropdown
                                className="edit"
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToEdit(item)}
                                      >
                                        <EditFilled /> Edit Asset
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToDelete(item)}
                                      >
                                        <DeleteFilled /> Delete Asset
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  shape="circle"
                                />
                              </Dropdown>
                            </div>
                            <Typography.Title
                              level={5}
                              style={{ marginTop: 10 }}
                            >
                              {item.name}
                            </Typography.Title>
                            <Typography.Text>{item?.Desc}</Typography.Text>
                          </Col>
                        ))}
                      </Row>

                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["WEBINAR"] && Assets["WEBINAR"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 700,
                            color:"#1D2939",
                            fontSize:'18px',
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Webinars
                        </h3>
                      </Col>

                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <List
                          className="webinarList"
                          itemLayout="horizontal"
                          dataSource={Assets["WEBINAR"]}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Dropdown
                                  className="edit"
                                  overlay={
                                    <Menu>
                                      <Menu.Item>
                                        <span
                                          onClick={() => setAssetToEdit(item)}
                                        >
                                          <EditFilled /> Edit Asset
                                        </span>
                                      </Menu.Item>
                                      <Menu.Item>
                                        <span
                                          onClick={() => setAssetToDelete(item)}
                                        >
                                          <DeleteFilled /> Delete Asset
                                        </span>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  placement="bottomRight"
                                >
                                  <Button
                                    type="text"
                                    icon={<MoreOutlined />}
                                    shape="circle"
                                  />
                                </Dropdown>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                item.thumbnailKey ? (
                                    <div className="Amplify-image-wrapper Amplify-image-wrapper-webinar">
                                      <AmplifyS3Image
                                        className="AmplifyImage"
                                        level="protected"
                                        key={item.thumbnailKey.split("/")[1]}
                                        identityId={
                                          item.thumbnailKey.split("/")[0]
                                        }
                                        style={{
                                          "--height": "100%",
                                          "--width": "100%",
                                        }}
                                        path={item.thumbnailKey.split("/")[1]}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={
                                        item?.Img_Url
                                          ? item?.Img_Url
                                          : webinarDefault
                                      }
                                      alt=""
                                      className="AssetImage-webinar"
                                      onClick={() => window.open(item?.hosted_url)}
                                      style={{ cursor: "pointer",height: '100px',width: '100px', border:'1px solid #D0D5DD' }}
                                    />
                                  )
                                }
                                title={
                                  <span style={{ fontSize: 18, color:"#1D2939", fontWeight:700, marginBottom:'10px', display:'block' }}>
                                    {item.name}
                                  </span>
                                }
                                description={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      color:"#475467",
                                      lineHeight:'24px'
                                    }}
                                  >
                                    {item?.Desc}
                                  </div>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </Col>

                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["BLOG"] && Assets["BLOG"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 700,
                            color:"#1D2939",
                            fontSize:'18px',
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                            
                          }}
                          className="header2"
                        >
                          Blogs
                        </h3>
                      </Col>
                      <Row gutter={[15, 15]} style={{ width: "100%" }}>
                        {Assets["BLOG"]?.map((blog) => (
                          <Col span={12} lg={12} md={12} xs={24} sm={24}>
                            <div className="panel-design" style={{padding:'10px'}}>
                            <div className="VideoWrapper" style={{borderBottom:'1px solid #D0D5DD', paddingBottom:'10px', marginBottom:'10px'}}>
                            {blog.thumbnailKey ? (
                                <div className="Amplify-image-wrapper">
                                  <AmplifyS3Image
                                    className="AmplifyImage"
                                    level="protected"
                                    key={blog.thumbnailKey.split("/")[1]}
                                    identityId={
                                      blog.thumbnailKey.split("/")[0]
                                    }
                                    style={{
                                      "--height": "100%",
                                      "--width": "100%",
                                    }}
                                    path={blog.thumbnailKey.split("/")[1]}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={
                                    blog?.Img_Url
                                      ? blog?.Img_Url
                                      : videoDefault
                                  }
                                  alt=""
                                  className="AssetImage"
                                  onClick={() => window.open(blog?.hosted_url)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                              <Dropdown
                                className="edit"
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToEdit(blog)}
                                      >
                                        <EditFilled /> Edit Asset
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToDelete(blog)}
                                      >
                                        <DeleteFilled /> Delete Asset
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  shape="circle"
                                />
                              </Dropdown>
                            </div>
                            <Typography.Title
                              level={5}
                              style={{ marginTop: 10, color:"#1D2939", fontWeight:700 }}
                            >
                              {blog.name}
                            </Typography.Title>
                            <Typography.Text style={{color:"#475467", fontSize:'15px', lineHeight:'24px'}}>{blog?.Desc}</Typography.Text>
                            </div>
                          </Col>
                        ))}
                      </Row>

                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["DEMO"] && Assets["DEMO"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 600,
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Demo
                        </h3>
                      </Col>
                      <Row gutter={[15, 15]} style={{ width: "100%" }}>
                        {Assets["DEMO"]?.map((demo) => (
                          <Col span={12} lg={12} md={12} xs={24} sm={24}>
                            <div className="VideoWrapper">
                            {demo.thumbnailKey ? (
                                <div className="Amplify-image-wrapper">
                                  <AmplifyS3Image
                                    className="AmplifyImage"
                                    level="protected"
                                    key={demo.thumbnailKey.split("/")[1]}
                                    identityId={
                                      demo.thumbnailKey.split("/")[0]
                                    }
                                    style={{
                                      "--height": "100%",
                                      "--width": "100%",
                                    }}
                                    path={demo.thumbnailKey.split("/")[1]}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={
                                    demo?.Img_Url
                                      ? demo?.Img_Url
                                      : videoDefault
                                  }
                                  alt=""
                                  className="AssetImage"
                                  onClick={() => window.open(demo?.hosted_url)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                              <Dropdown
                                className="edit"
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToEdit(demo)}
                                      >
                                        <EditFilled /> Edit Asset
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToDelete(demo)}
                                      >
                                        <DeleteFilled /> Delete Asset
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  shape="circle"
                                />
                              </Dropdown>
                            </div>
                            <Typography.Title
                              level={5}
                              style={{ marginTop: 10 }}
                            >
                              {demo.name}
                            </Typography.Title>
                            <Typography.Text>{demo?.Desc}</Typography.Text>
                          </Col>
                        ))}
                      </Row>
                      <Divider
                        style={{ borderTopColor: "#dddddd !important" }}
                      />
                    </>
                  )}

                  {Assets["TRIAL"] && Assets["TRIAL"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 600,
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Trial
                        </h3>
                      </Col>
                      <Row gutter={[15, 15]} style={{ width: "100%" }}>
                        {Assets["TRIAL"]?.map((item) => (
                          <Col span={12} lg={12} md={12} xs={24} sm={24}>
                            <div className="VideoWrapper">
                            {item.thumbnailKey ? (
                                <div className="Amplify-image-wrapper">
                                  <AmplifyS3Image
                                    className="AmplifyImage"
                                    level="protected"
                                    key={item.thumbnailKey.split("/")[1]}
                                    identityId={
                                      item.thumbnailKey.split("/")[0]
                                    }
                                    style={{
                                      "--height": "100%",
                                      "--width": "100%",
                                    }}
                                    path={item.thumbnailKey.split("/")[1]}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={
                                    item?.Img_Url
                                      ? item?.Img_Url
                                      : videoDefault
                                  }
                                  alt=""
                                  className="AssetImage"
                                  onClick={() => window.open(item?.hosted_url)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                              <Dropdown
                                className="edit"
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToEdit(item)}
                                      >
                                        <EditFilled /> Edit Asset
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToDelete(item)}
                                      >
                                        <DeleteFilled /> Delete Asset
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  shape="circle"
                                />
                              </Dropdown>
                            </div>
                            <Typography.Title
                              level={5}
                              style={{ marginTop: 10 }}
                            >
                              {item.name}
                            </Typography.Title>
                            <Typography.Text>{item?.Desc}</Typography.Text>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                  {Assets["OTHER"] && Assets["OTHER"]?.length > 0 && (
                    <>
                      <Col span={24} lg={24} md={24} xs={24} sm={24}>
                        <h3
                          style={{
                            fontWeight: 600,
                            marginBottom: '15px',
                            borderBottom:'1px solid #D0D5DD',
                            paddingBottom:'10px'
                          }}
                          className="header2"
                        >
                          Other
                        </h3>
                      </Col>
                      <Row gutter={[15, 15]}>
                        {Assets["OTHER"]?.map((item) => (
                          <Col span={12} lg={12} md={12} xs={24} sm={24}>
                            <div className="VideoWrapper">
                            {item.thumbnailKey ? (
                                <div className="Amplify-image-wrapper">
                                  <AmplifyS3Image
                                    className="AmplifyImage"
                                    level="protected"
                                    key={item.thumbnailKey.split("/")[1]}
                                    identityId={
                                      item.thumbnailKey.split("/")[0]
                                    }
                                    style={{
                                      "--height": "100%",
                                      "--width": "100%",
                                    }}
                                    path={item.thumbnailKey.split("/")[1]}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={
                                    item?.Img_Url
                                      ? item?.Img_Url
                                      : videoDefault
                                  }
                                  alt=""
                                  className="AssetImage"
                                  onClick={() => window.open(item?.hosted_url)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                              <Dropdown
                                className="edit"
                                overlay={
                                  <Menu>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToEdit(item)}
                                      >
                                        <EditFilled /> Edit Asset
                                      </span>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <span
                                        onClick={() => setAssetToDelete(item)}
                                      >
                                        <DeleteFilled /> Delete Asset
                                      </span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  shape="circle"
                                />
                              </Dropdown>
                            </div>
                            <Typography.Title
                              level={5}
                              style={{ marginTop: 10 }}
                            >
                              {item.name}
                            </Typography.Title>
                            <Typography.Text>{item?.Desc}</Typography.Text>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}

                  {AssetCount === 0 && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      imageStyle={{
                        height: 60,
                      }}
                      style={{ width: "100%" }}
                      description={
                        <span>No asset found with these filters.</span>
                      }
                    ></Empty>
                  )}
                </>
              ) : (
                <Empty
                  style={{ width: "100%", marginTop: 10, textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}
                  imageStyle={{
                    height: 60,
                  }}
                  description={<span>No Assets Available</span>}
                >
                  <Button
                    type="primary"
                    onClick={() => setVisible(true)}
                    icon={<PlusOutlined />}
                    className="secondaryButton"
                  >
                    Create New
                  </Button>
                </Empty>
              )}
            </Row>
          )}
          <Divider />

          <Row
            gutter={[10, 10]}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              borderRadius: 7,
              marginTop: 30,
              marginBottom: 15,
            }}
            data-tour="tour__Export"
          >
            <Col span={24} style={{ display: "flex" }}>
              <div style={{width:'100%'}}>
                <h3 className="panel-design" style={{ marginBottom: "30px", fontSize:'18px', background:"#f5f7fa", padding:"10px 5px", textAlign:'center', display:"block", fontWeight:500 }}>
                  Download PDFs & PPTx
                </h3>{" "}
              </div>
              <div style={{ paddingLeft: 10 }}>
                {props.loadingPDF && (
                  <Loading3QuartersOutlined
                    spin
                    style={{
                      fontSize: 18,
                      float: "right",
                      marginTop: 5,
                      marginRight: 8,
                    }}
                  />
                )}
              </div>
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
                >
                  <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                  <p>
                    <strong>Asset Library PDF</strong>
                    <br /> with all the details
                    <br /> included above
                  </p>
                  <div style={{display:'flex', alignItems:"center"}}>
                  <Button
                    onClick={async () => {
                      let url = await getSignedUrlImg(
                        accountDetails?.profile_url
                      );
                      props.generatePdfDocument(
                        "Asset Library.pdf",
                        <AssetLibraryPage logoUrl={url} />
                      );
                    }}
                    className="download-btn tertiaryButton"
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                    type="primary"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={() => {
                      props.emailPdfDocument(
                        "Demand Generation - Go to Marketing Intelligence Asset Library",
                        <AssetLibraryPage />
                      );
                    }}
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                    type="primary"
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                  >
                    Email
                  </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
                >
                  <img src={pdfImg} style={{ marginBottom: 10 }} alt="" />
                  <p>
                    <strong>Master PDF</strong> with all the <br />
                    GTM modules details
                    <br /> included in one PDF
                  </p>
                  <div style={{display:'flex', alignItems:"center"}}>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={async () => {
                      let url = await getSignedUrlImg(
                        accountDetails?.profile_url
                      );
                      props.generatePdfDocument(
                        "Go to Marketing Master.pdf",
                        <GTMMasterDoc logoUrl={url} />
                      );
                    }}
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                    type="primary"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    onClick={() => {
                      props.emailPdfDocument(
                        "Demand Generation - Go to Marketing Intelligence Master",
                        <GTMMasterDoc />
                      );
                    }}
                    
                    type="primary"
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                  >
                    Email
                  </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col span={8} md={8} lg={8} sm={24} xs={24}>
              {showSkeleton || showSkeletonRoot ? (
                <Space style={{ width: "100%" }} direction="vertical">
                  <Skeleton active size="large" />
                </Space>
              ) : (
                <div
                style={{
                  background: "#f5f7fa",
                  padding: 15,
                  borderRadius: 10,
                }}
                className="panel-design"
                >
                  <img src={ppt_icon} style={{ marginBottom: 10 }} alt="" />
                  <p>
                    <strong>Complete presentation</strong>
                    <br /> of all the GTM modules
                    <br /> details entered
                  </p>
                  <div style={{display:'flex', alignItems:"center"}}>
                  <Button
                    className="download-btn tertiaryButton"
                    icon={<UploadOutlined style={{ fontSize: 13 }} />}
                    type="primary"
                    onClick={downloadPPTX}
                    style={{ width:'49%', fontSize:'14px', height:'34px'}}
                  >
                    Export
                  </Button>
                  <Button
                    className="download-btn tertiaryButton"
                    style={{ width:'49%', fontSize:'14px', height:'34px', marginLeft:'2%' }}
                    icon={
                      <MailOutlined
                        style={{ fontSize: 13, verticalAlign: "middle" }}
                      />
                    }
                    type="primary"
                    onClick={props.emailPPTXDocument}
                  >
                    Email
                  </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {visible && (
        <CreateAsset
          onClose={onClose}
          account={account}
          isEdit={false}
          TargetIdealCustomProfile={TargetIdealCustomProfile}
          Persona={Persona}
        />
      )}
      {Object.keys(AssetToEdit).length > 0 && (
        <CreateAsset
          onClose={() => setAssetToEdit({})}
          account={account}
          isEdit={true}
          AssetToEdit={AssetToEdit}
          TargetIdealCustomProfile={TargetIdealCustomProfile}
          Persona={Persona}
        />
      )}
      {Object.keys(AssetToDelete).length > 0 && (
        <Modal
          className="delete-budget-camp"
          visible={Object.keys(AssetToDelete).length > 0}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <img src={binIcon} alt="delete icon" />
            <Typography.Text style={{ marginTop: "10px", fontSize: "20px" }}>
              Are you sure you want to delete this asset?
            </Typography.Text>
            <div className="delete-modal-footer">
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  handleDeleteAsset();
                }}
                style={{ borderColor: "#0F61DB", color: "#0F61DB" }}
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => setAssetToDelete({})}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Modal
        className="delete-budget-camp"
        visible={ApplyFilterModelOpen}
        centered
        onCancel={() => {
          setApplyFilterModelOpen(false);
        }}
        footer={[]}
        style={{ borderRadius: "20px" }}
      >
        <div className="delete-modal-bodym">
          <Typography.Title level={4}>Assets Filters</Typography.Title>

          <Form
            form={filterForm}
            onFinish={HandleApplyFilters}
            onReset={resetAppliedFilterForm}
            initialValues={{ type: ["ALL"] }}
          >
            <Form.Item
              name="type"
              label={
                <small>
                  <strong>Asset Type</strong>
                </small>
              }
            >
              <Select
                mode="multiple"
                defaultValue="ALL"
                style={{ margin: "0 10px" }}
                placeholder="Filter by asset type"
              >
                {availableFilters?.["type"]?.map((item) => (
                  <Option value={item} key={item}>
                    {toCapitalize(item)}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="target_market_segment"
              label={
                <small>
                  <strong>Target market segment</strong>
                </small>
              }
            >
              <Select
                mode="multiple"
                style={{ margin: "0 10px" }}
                placeholder="Filter by target market segment"
              >
                {availableFilters?.["market_segments"]?.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="industries"
              label={
                <small>
                  <strong>Target industries</strong>
                </small>
              }
            >
              <Select
                mode="multiple"
                style={{ margin: "0 10px" }}
                placeholder="Filter by target industries"
              >
                {availableFilters?.["industries"]?.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="regions"
              label={
                <small>
                  <strong>Target regions</strong>
                </small>
              }
            >
              <Select
                mode="multiple"
                style={{ margin: "0 10px" }}
                placeholder="Filter by target regions"
              >
                {availableFilters?.["regions"]?.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="persona"
              label={
                <small>
                  <strong>Persona</strong>
                </small>
              }
            >
              <Select
                mode="multiple"
                style={{ margin: "0 10px" }}
                placeholder="Filter by persona"
              >
                {availableFilters?.["personas"]?.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Button type="primary" htmlType="reset">
              Reset Filters
            </Button>
            <Button
              type="primary"
              ghost
              htmlType="submit"
              style={{
                borderColor: "#0F61DB",
                color: "#0F61DB",
                marginLeft: 20,
              }}
            >
              Apply Filters
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
