import { Drawer, Space,Descriptions } from "antd";
import {Link} from 'react-router-dom'

const ExecutiveDrawer = ({leadsDrawerOpen, title, count, onDrawerClose, leadsData}) => {
    if (!leadsData || Object.keys(leadsData).length === 0) {
        return null; 
      }
    const dynamicKey = Object.keys(leadsData)[0];
    
    return (
        <Drawer
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Space>
              <div style={{ fontWeight: "700" }}>{title} {`(${count})`}</div>
              {/* {marketLoader && <LoadingOutlined style={{ marginLeft: 10 }} />} */}
            </Space>
          </Space>
        }
        visible={leadsDrawerOpen}
        className="createAssetModal market-drawer"
        onClose={onDrawerClose}
        width={450}
      >
        
            {/* <Descriptions size={"small"} bordered>
                {leadsData[dynamicKey][0]?.map((item, index) => {
                return (
                        <Descriptions.Item
                                span={3}
                                label={item?.id}
                                key={index}
                                >
                                <span>{item?.name}</span>
                                </Descriptions.Item>
                    )
                })}
            </Descriptions> */}

            <table className="custom_description_item_table">
            <tr>
              <th>Account Name</th>
              <th>Opportunity Name</th>
            </tr>
            {leadsData[dynamicKey][0]?.map((item, index) => (
              <tr>
                <td><a href={item?.title_url} target="_blank" rel="noreferrer">{item?.title}</a></td>
                <td><a href={item?.value_url} target="_blank" rel="noreferrer">{item?.value}</a></td>
              </tr>
            ))}
          </table>

            
        
      </Drawer>
    )
}

export default ExecutiveDrawer;