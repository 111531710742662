import { DualAxes } from "@ant-design/plots";
import React from "react";

const ColumnLineChart = (props) => {
  const { data, height, yFields, xField  } = props;

  const config = {
    data: [data, data],
    xField: xField || "quarter",
    yField: yFields || ["Deals","Pipeline"],
    height: height ? height : 350,
    legend: {
      position: "top",
    },
    geometryOptions: [
      {
        geometry: "column",
        color: "#7F56D9",
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
        color: "#EC4A0A",
      },
    ],
     xAxis: {
    title: {
      text: props?.xTitle ?? 'Group By : Quarter (Deal Close Date)',
         position: 'bottom',
         style: {
           fontSize: 14,
           fontWeight : 600
      }
    },
    },
     yAxis: [
    {
      title: {
      text: 'Pipeline Amount (Sum)',
        position: 'bottom',
      style: {
           fontSize: 14,
           fontWeight : 600
      }
    },
            },
    
    {
      title: {
      text: 'Deals (Sum)',
        position: 'bottom',
      style: {
           fontSize: 14,
           fontWeight : 600
      }
    },
    },
  ],
  };
  return <DualAxes {...config} />;
};

export default ColumnLineChart;
