/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BudgetSummary from "./BudgetSummary";
import BudgetPlanning from "./BudgetPlanning";
import Campaigns from "./Campaigns";
import moment from "moment";
import { Row, Col, Tabs, Card, Select, Button, Typography, Modal, Skeleton, Dropdown, Menu, message, Tooltip, } from "antd";
import "./budget.less";
import CreateBudgetProfile from "./CreateBudgetProfile";
import { getBudgetList, getBudget, createCampaign, createCampaignDetails, createCampaignMonth, flipOtherBudgetStatus, updateBudget, deleteCampaign, deleteCampaignDetails, deleteCampaignMonth, deleteBudget, createBudget } from "store/actions/budgetAction";
import {
  setPlanningPeriod,
  getFiscalYearsList,
  getFiscalYear,
  setFiscalYear,
} from "store/actions/revenueAction";
import create_budget_info from "assets/Images/create_budget_info.svg";
import SandboxImg from "assets/Images/sandbox.svg";
import { convertReverseArray, getFunnelCountsValue, numberWithCommas } from "utility/context/CommonMethods";
import budgetImg from "../../assets/Images/undraw_budget.png"
import budget_campaign_module_preview from "../../assets/Images/budget_campaign_module_preview.png"
import _ from "lodash";
import Tour from "reactour";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import {fourAMStages, sampleBudgetAmount, sampleBudgetCampaigns} from "../../utility/Constants";
import PreviewInfo from "commons/PreviewInfo";
import { UpdateTourData } from "store/actions/loginAction";
import { LoadingOutlined, PlusOutlined, QuestionCircleOutlined, DollarCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { Prompt } from "react-router-dom";

import TickImg from "assets/Images/tick.svg";
import { ExploricApi } from "utility/Api";
import binIcon from "../../assets/Images/bin_icon.svg";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'
import sdk from "sdk/Accounts";


const BudgetCampaignsOld = (props) => {
  const { TabPane } = Tabs;
  const { ChannelList,BudgetProfileList ,ChannelCampaignPlannedTotal,BudgetProfile,viewCampaignPlanningTotal,BudgetProfileListDetails} = useSelector((state) => state.budgetReducer);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [acct, setAcct] = useState(null);
  const [loading, setLoading] = useState(true);


  const {
    selectProfileYear,
    funnelStages,
    fiscalYear,
    fiscalYearList,
  } = useSelector(
    (state) => state.revenueReducer
    );

  const { salesAndMarketingStages } = useSelector((state) => state.salesAndMarketingReducer);

  const filteredLeadObj = salesAndMarketingStages.length && salesAndMarketingStages?.find(obj => obj?.internalStage === 'Leads');
  const nameOfLeadObj = filteredLeadObj ? filteredLeadObj?.name : "Lead";

  const fiscalYearListRef = useRef(fiscalYearList)
  if (!_.isEqual(fiscalYearListRef.current, fiscalYearList)) {
    fiscalYearListRef.current = fiscalYearList
  }
  useEffect(() => {
    const fn = async () => {
      let year;
      if (localStorage.getItem("FiscalYear") && (fiscalYear && fiscalYear?.year)) {
        year = fiscalYear.year
      } else {
        year = new Date().getFullYear()
      }
     
      if (fiscalYearList.length) {
        const fy = fiscalYearList.filter((item) => item.year === year);
        if (fy.length > 0) {
          await localStorage.setItem("FiscalYear", window.btoa(fy[0]?.id));
          await dispatch(getFiscalYear(fy[0]?.id));
        } else {
          await dispatch(setFiscalYear())
        }
      } else {
        await dispatch(setFiscalYear())
      }
    };

    fullPermission && fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  const { ActaualDataState } = useSelector(
    (state) => state.reportReducer
  );
  const {userPermission, viewType, groups:account, accountDetails, userData} = useSelector((state) => state.loginReducer);

  const [fullPermission] = useState(!(userPermission?.budgetCampaign === 'preview'))


  const [selectedValue, setSelectedValue] = useState();

  const [campaignPlanningEmpty, setCampaignPanningEmpty] = useState(false);
  //const [isTourOpen, setIsTourOpen] = useState(false);

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = account.length > 0 ? account[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);

      setAcct(account);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);

 


  useEffect(() => {
    setCampaignPanningEmpty(viewCampaignPlanningTotal);
  }, [viewCampaignPlanningTotal]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [campaignTotalSpend, setCampaignTotalSpend] = useState();


  const [getBudgetLoader, setgetBudgetLoader] = useState(true)

  useEffect(() => {
    setCampaignTotalSpend(ChannelCampaignPlannedTotal.budget?.["fy-totals"]);
  }, [ChannelCampaignPlannedTotal]);
  useEffect(async () => {
    
    fullPermission && dispatch(getFiscalYearsList())
    fullPermission && dispatch(getBudgetList()).then(res => {
      setgetBudgetLoader(false)
    }).catch(err => {
      setgetBudgetLoader(false)
    });
  }, [dispatch]);

  const [yearDropdown, setYearDropdown] = useState();
  const [budgetDropdown, setBudgetDropdown] = useState();
  
  const { Option } = Select;

  const [burgetPrifleNames, setBudgetProfilesData] = useState();
  const [defaultYear, setDefautlYear] = useState();
  const [budgetPrifileDefault, setBudgetProfileDefault] = useState();
  const [selectedQuarters, setselectedQuarters] = useState();
  const [isAnyBudgetDeleted, setisAnyBudgetDeleted] = useState(false)

  useEffect(() => {
    setQuarterlydata()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fiscalYearList,BudgetProfile])

  useEffect(() => {
    console.log("BudgetProfile",BudgetProfile);
    if (yearDropdown && yearDropdown.length > 0) {
      if (!_.isEmpty(BudgetProfile) && (account?.[0] === BudgetProfile?.account)) {
        setBudgetProfilesData(budgetDropdown[BudgetProfile.year]);
        setBudgetProfileDefault(BudgetProfile.name);
        setDefautlYear(BudgetProfile.year);
      } else {
        if(yearDropdown.includes(new Date().getFullYear())){
          let yearIndex = yearDropdown.indexOf(new Date().getFullYear())
          setBudgetProfilesData(budgetDropdown[yearDropdown[yearIndex]]);
          setBudgetProfileDefault(budgetDropdown[yearDropdown[yearIndex]][0].label);
          setDefautlYear(yearDropdown[yearIndex])
          onChange(budgetDropdown[yearDropdown[yearIndex]][0].id);
        }
        else{
          setBudgetProfilesData(budgetDropdown[yearDropdown[0]]);
          setBudgetProfileDefault(budgetDropdown[yearDropdown[0]][0].label);
          setDefautlYear(yearDropdown[0])
          onChange(budgetDropdown[yearDropdown[0]][0].id);
        }
      }
    } else {
      setBudgetProfilesData();
        setBudgetProfileDefault();
        setDefautlYear();
    }
  }, [budgetDropdown, yearDropdown, account[0], BudgetProfile]); // eslint-disable-line

 
console.log("BudgetProfileList",BudgetProfileList);
  useEffect(async() => {
    const getGroup = (groups, parent) => {
      let group = groups.find((g) => {
        return g.title === parent;
      });
      if (!group) {
        group = { title: parent, value: Math.random(), children: [] };
        groups.push(group);
      }
      return group;
    };

    let grouped = [];
    BudgetProfileList.forEach((item) =>
      getGroup(grouped, item.year).children.push(item)
    );
    // const fiscalYears = await dispatch(
    //   getFiscalYearsList({ account: { eq: acct?.id } })
    // );
    // console.log("fiscalYears", fiscalYears);
    // const dropdownYears = fiscalYears?.items
    //   ?.map((item, index) => {
    //     return item.year;
    //   })
    //   ?.sort();
    const yearList = [];
    const budgetNameListForYear = {};
    console.log("grouped",grouped);
    // eslint-disable-next-line array-callback-return
    grouped.map((groupedVal, index) => {
      // eslint-disable-line

      yearList.push(groupedVal.title);

      let childTitle = [];
      // eslint-disable-next-line array-callback-return
      groupedVal.children.map((childValue, index) => {
        // eslint-disable-line

        childTitle.push({ id: childValue.value, label: childValue.title });
      });
      budgetNameListForYear[groupedVal.title] = childTitle;
    });
    
    
    setYearDropdown(yearList.sort());
    
    setBudgetDropdown(budgetNameListForYear);
    let selected = grouped.find((group) => group.title === selectProfileYear);
    setSelectedValue(
      selectedValue ? selectedValue : selected?.children[0]?.value
    );
    if (!defaultYear) {
      if (BudgetProfileList.length > 0) {
        setDefautlYear(BudgetProfileList[0]?.year)
      }
    }
    if (isAnyBudgetDeleted && BudgetProfileList.length > 0) {
      onChange(BudgetProfileList[0]?.value)
      setisAnyBudgetDeleted(false)
    }
  }, [BudgetProfileList]); // eslint-disable-line

  const onChange = (selectedObj) => {
    setbudgetChangeLoader(true)
    localStorage.setItem("Budget", window.btoa(selectedObj));
    setSelectedValue(selectedObj);
    fullPermission && dispatch(getBudget(selectedObj)).then(res => {
      setbudgetChangeLoader(false)
    }).catch(err => {
      setbudgetChangeLoader(false)
    });

    if (
      BudgetProfileList.find((profile) => profile.value === selectedObj)?.year
    ) {
      fullPermission && dispatch(
        setPlanningPeriod(
          BudgetProfileList.find((profile) => profile.value === selectedObj)
            ?.year
        )
      );
    }
  };

  const [budgetChangeLoader, setbudgetChangeLoader] = useState(false)
  const handleYearChange = (value) => {
    setbudgetChangeLoader(true)
    setBudgetProfilesData(budgetDropdown[value]);
    setBudgetProfileDefault(budgetDropdown[value][0].id);
    setDefautlYear(value);
    onChange(budgetDropdown[value][0].id);
  };

  const onBudgetProfileChange = (value) => {
    setBudgetProfileDefault(value);
    onChange(value);
  };

  useEffect(() => {
    setQuarterlydata()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fiscalYearList, BudgetProfile?.year])

  useEffect(() => {

    if (window.atob(localStorage.getItem("Budget"))) {
      if(localStorage.getItem("Budget"))
        onChange(window.atob(localStorage.getItem("Budget")));
    } else {
    getbudgetData()
    }
    if (BudgetProfile) {
      setBudgetProfileDefault(BudgetProfile.name);
    }
  //   //    eslint-disable-next-line
  }, []);

  const getbudgetData = async () => {
    fullPermission && await dispatch(getBudgetList())
  }

  const [actualTotalLeadsData, setActualTotalLeadsData] = useState();
  const [actualTotalRevenueData, setActualTotalRevenueData] = useState();
  const [actaulRevenueLeadsQuarter, setActaulRevenueLeadsQuarter] = useState();

  useEffect(() => {

    const actualDataAggregate = {};
    ActaualDataState?.map((actualDataParsedToJsonVal, index) => {
      const Quarter = `Q${moment(actualDataParsedToJsonVal.quarter).quarter()}`;
      actualDataAggregate[Quarter] = {
        closed_deal_amount: actualDataParsedToJsonVal.closed_deal_amount,
        deals_count: actualDataParsedToJsonVal.deals_count,
        sales_closed_amount: actualDataParsedToJsonVal.sales_closed_amount,
        sales_deal: actualDataParsedToJsonVal.sales_deal,
      };
      return true;
    });

    setActaulRevenueLeadsQuarter(actualDataAggregate);

    const totalLeadActual =
      actualDataAggregate.Q1?.deals_count +
      actualDataAggregate.Q2?.deals_count +
      actualDataAggregate.Q3?.deals_count +
      actualDataAggregate.Q4?.deals_count;

    const totalRevenueActual =
      actualDataAggregate.Q1?.closed_deal_amount +
      actualDataAggregate.Q2?.closed_deal_amount +
      actualDataAggregate.Q3?.closed_deal_amount +
      actualDataAggregate.Q4?.closed_deal_amount;

    setActualTotalLeadsData(totalLeadActual);
    setActualTotalRevenueData(totalRevenueActual);
  }, [ActaualDataState]);

  const reloaddata = () => {
    setisAnyBudgetDeleted(true)
  };

  const [quarterlyRevenueGoal, setquarterlyRevenueGoal] = useState({
    budget_q1: 0,
    budget_q2: 0,
    budget_q3: 0,
    budget_q4: 0,
  })
  const [TotalLeadsGoal, setTotalLeadsGoal] = useState();

  const setQuarterlydata = () => {
    let fiscalYear = fiscalYearList.filter(fiscalYear => fiscalYear?.year === BudgetProfile?.year && account[0] === fiscalYear?.account)[0]

    let quarterwisedata = {
      budget_q1: 0,
      budget_q2: 0,
      budget_q3: 0,
      budget_q4: 0,
    }
    let marketingLeads = {
      budget_q1: 0,
      budget_q2: 0,
      budget_q3: 0,
      budget_q4: 0,
    }
    let filteredbaselineMetrics = []
    if (BudgetProfile?.BaselineMetricID && BudgetProfile?.BaselineMetricID.length > 0) {
     filteredbaselineMetrics = fiscalYear?.baselineMetrics.items.filter(item => BudgetProfile?.BaselineMetricID?.includes(item.id))
    }
    else {
      filteredbaselineMetrics = fiscalYear?.baselineMetrics?.items
    }


    // eslint-disable-next-line array-callback-return
    filteredbaselineMetrics?.map((baselineData) => {

      let totalProspectsGoal = getProspectsGoal(baselineData)
      let totalMarketingrevenue = parseInt(Number(getMktSrcCalculation( baselineData?.goal, baselineData.mrktSourcePercent )).toFixed(0))
      let quarterlyleads = {}


      // setting marketing revenue
      if (baselineData.budget_allocation) {
        let a = JSON.parse(baselineData.budget_allocation)

        let MetricWiseQuarterlyData = {
          q1 : parseInt(Number(getMktSrcCalculation( a?.budget_q1, baselineData.mrktSourcePercent )).toFixed(0)),
          q2 : parseInt(Number(getMktSrcCalculation( a?.budget_q2, baselineData.mrktSourcePercent )).toFixed(0)),
          q3 : parseInt(Number(getMktSrcCalculation( a?.budget_q3, baselineData.mrktSourcePercent )).toFixed(0)),
          q4 : parseInt(Number(getMktSrcCalculation( a?.budget_q4, baselineData.mrktSourcePercent )).toFixed(0))
        }

        quarterwisedata.budget_q1 += MetricWiseQuarterlyData["q1"]
        quarterwisedata.budget_q2 += MetricWiseQuarterlyData["q2"]
        quarterwisedata.budget_q3 += MetricWiseQuarterlyData["q3"]
        quarterwisedata.budget_q4 += MetricWiseQuarterlyData["q4"]

        let total =
                  MetricWiseQuarterlyData['q1'] +
                  MetricWiseQuarterlyData['q2'] +
                  MetricWiseQuarterlyData['q3'] +
                  MetricWiseQuarterlyData['q4']


          if (total !== totalMarketingrevenue) {
            let diff = Math.abs(totalMarketingrevenue - total)
            if (totalMarketingrevenue > total) {
                  quarterwisedata.budget_q4 += diff;
                }
                else {
                  quarterwisedata.budget_q4 -= diff;
            }
          }

        //totalProspectsGoal * ((quarterwisedata['budget_q2']*100)/totalMarketingrevenue) /100);

        // setting prospect leads

       // setting prospect leads
      quarterlyleads['q1'] = Math.floor((totalProspectsGoal * MetricWiseQuarterlyData['q1']) / totalMarketingrevenue)
      quarterlyleads['q2'] = Math.floor((totalProspectsGoal * MetricWiseQuarterlyData['q2']) / totalMarketingrevenue)
      quarterlyleads['q3'] = Math.floor((totalProspectsGoal * MetricWiseQuarterlyData['q3']) / totalMarketingrevenue)
      quarterlyleads['q4'] = Math.floor((totalProspectsGoal * MetricWiseQuarterlyData['q4']) / totalMarketingrevenue)
      }
      marketingLeads.budget_q1 +=quarterlyleads['q1'] ? quarterlyleads['q1'] : 0
      marketingLeads.budget_q2 += quarterlyleads['q2'] ? quarterlyleads['q2'] : 0
      marketingLeads.budget_q3 += quarterlyleads['q3'] ? quarterlyleads['q3'] : 0
      marketingLeads.budget_q4 += quarterlyleads['q4'] ? quarterlyleads['q4'] : 0

      let sum = (quarterlyleads['q1'] ? quarterlyleads['q1'] : 0) +
        (quarterlyleads['q2'] ? quarterlyleads['q2'] : 0) +
        (quarterlyleads['q3'] ? quarterlyleads['q3'] : 0) +
        (quarterlyleads['q4'] ? quarterlyleads['q4'] : 0)
      if (sum !== totalProspectsGoal) {
        let diff = Math.abs(totalProspectsGoal - sum)
        if (totalProspectsGoal > sum) {
              marketingLeads.budget_q4 += diff;
            }
            else {
              marketingLeads.budget_q4 -= diff;
            }
      }

    })
      function isFloat(n){
      return Number(n) === n && n % 1 !== 0;
    }
    let leadGoalValue = {}
    Object.entries(marketingLeads).map(([key,value])=> {

   let floatValue = isFloat(value)
   leadGoalValue[key] = floatValue ? ~~value : value
  })

    setquarterlyRevenueGoal(quarterwisedata)
    setTotalLeadsGoal(leadGoalValue)
  }
  const getMktSrcCalculation = (value, mrktsrc) => {
    return (value * mrktsrc) / 100
  }

  const sortingOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };
  const getProspectsGoal = (baselineData) => {
    let funnelStages = baselineData?.funnelConfigs?.items[0]?.stages?.items;
    let sortingFunnel = funnelStages?.sort(sortingOrder);
    let newConvertData = [...convertReverseArray(sortingFunnel)];

    let marketingSourcedCount = baselineData?.avgDealSize ? Number(getMktSrcCalculation(baselineData?.goal, baselineData?.mrktSourcePercent) / baselineData?.avgDealSize).toFixed(0) : 0
    let goal = marketingSourcedCount;

    // newConvertData.map((item, index) => {
    //   let { conversion } = item;
    //   let newValue =
    //     index === 0
    //       ? !isFinite(marketingSourcedCount)
    //         ? 0
    //         : Number.parseInt(marketingSourcedCount)
    //       :
    //         (goal * 100) /
    //         Number.parseInt(conversion)
    //       ;

    //   goal = newValue
    // });

    // eslint-disable-next-line array-callback-return
    newConvertData.map((item, index) => {
      let { conversion } = item;
      let newValue =
        index === 0
          ? !isFinite(marketingSourcedCount)
            ? 0
            : Number.parseInt(marketingSourcedCount)
          : index === 1
          ? Number.parseInt(
              getFunnelCountsValue(
                goal,
                conversion,
                true
              )
            )
          : Number.parseInt(
              getFunnelCountsValue(
                goal,
                conversion
              )
            );

        goal = newValue




    });
    
    return goal;
  }

  const handleQuarterSelection = (values) => {
    setselectedQuarters(values)
  }
  // const steps = [

  //   {
  //     selector: '[data-tour = "tour__budgets"]',
  //     content: () => (
  //       <div>
  //       <Title className="fuel-title" level={4}>
  //       Budget Selection
  //       </Title>
  //       <Paragraph>You can select any previously created budget here, which adjusts the views in the sections below.</Paragraph>
  //       </div>
  //     ),
  //     action: (node) => {
  //       node?.focus()
  //       node?.scrollIntoView({ block: "center", inline: "nearest"});
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__createBudget"]',
  //     content: () => (<div>
  //       <Title level={4}>
  //       Create New Budget
  //       </Title>
  //       <Paragraph>Click here to create a new budget and define its high-level details.</Paragraph>
  //     </div>),
  //     action: (node) => {
  //       node?.focus()
  //       node?.scrollIntoView({ block: "center", inline: "nearest" });
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__budgetAnalysis"]',
  //     content: () => (<div>
  //       <Title level={4}>
  //       Budget Overview
  //       </Title>
  //       <Paragraph> Budget Analysis shows information reflected by your planning inputs, and Budget Planning allows you to modify high-level budget details.</Paragraph>
  //     </div>),
  //     action: (node) => {
  //       node?.focus()
  //       node?.scrollIntoView({ block: "nearest", inline: "nearest" });
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__budgetCampaigns"]',
  //     content: () => (<div>
  //       <Title level={4}>
  //       Campaign Planning
  //       </Title>
  //       <Paragraph>Campaign Planning represents your detailed planning efforts, where you can allocate your budget across various channels and campaigns</Paragraph>
  //     </div>),
  //     action: (node) => {
  //       node?.focus()
  //       node?.scrollIntoView({ block: "center", inline: "nearest"});
  //     },
  //   },
  //   {
  //     selector: '[data-tour = "tour__createCampaign"]',
  //     content: () => (<div>
  //       <Title level={4}>
  //       Create Channel / Campaign
  //       </Title>
  //       <Paragraph>You can create any custom channel or campaign that you wish to fit your plan.</Paragraph>
  //     </div>),
  //     action: (node) => {
  //       node?.focus()
  //       node?.scrollIntoView({ block: "center", inline: "nearest"});
  //     },
  //   },


  // ]
  // eslint-disable-next-line no-unused-vars
  const [c, setc] = useState(1)

  const lastStageName = useMemo(() => funnelStages.length > 0
    ? funnelStages.find(({order}) => order === funnelStages.length - 1)?.salesAndMarketingAlignment?.name ?? fourAMStages[fourAMStages.length - 1]?.salesAndMarketingAlignment?.name
    : fourAMStages[fourAMStages.length - 1]?.salesAndMarketingAlignment?.name,[]);

    const [accountMetadata, setaccountMetadata] = useState({})

    useEffect(() =>{
      let metadata = JSON.parse(accountDetails?.metadata)?.[userData.sub]
      accountDetails?.metadata && setaccountMetadata(metadata)
    },[accountDetails, userData])

    // useEffect(() =>{
    //   if(account?.[2] !== "admin")
    //     setIsTourOpen(!accountMetadata?.BudgetTourFinish)
    // },[accountMetadata])

    const updateTour = () =>{
      dispatch(UpdateTourData(account, accountMetadata,"BudgetTourFinish", userData?.sub, JSON.parse(accountDetails?.metadata)))
    }


    // Navigation prevention

    const [hasUnsavedChanges, sethasUnsavedChanges] = useState(false);
  const [isShowNavModal, setisShowNavModal] = useState(false);
  const [nextLocation, setnextLocation] = useState(null);
  const [confirmedNavigation, setconfirmedNavigation] = useState(false);
  const [copyModal, setCopyModal] = useState(false);

    const showCopyModal = () => {
      setCopyModal(true);
    };

    const handleCopyModalCancel = () => {
      setCopyModal(false);
    };



    const showNavModal = (nextLocation) => {
      setisShowNavModal(true);
      setnextLocation(nextLocation);
      setconfirmedNavigation(true);
    };

    const onLeave = () => {
      setisShowNavModal(false);
      props.history.push(nextLocation);
    };

    const onStay = () => {
      setisShowNavModal(false);
      setconfirmedNavigation(false);
      document.getElementById("budgetProgram").click();
    };

    const preventNavigation = (nextLocation) => {
      const { location } = props;

      if (location.pathname === nextLocation.pathname) {
        return false;
      }

      if (confirmedNavigation) {
        return true;
      }

      showNavModal(nextLocation.pathname);
      return false;
    };

    const [askForSampleBudget, setaskForSampleBudget] = useState(false)
    const [createSampleBudgetLoader, setcreateSampleBudgetLoader] = useState(false)

    const afterCreateBudget = (data) => {
      onChange(data)
    }

    useEffect(() => {
      if(BudgetProfileList?.length === 1 && BudgetProfile?.campaigns?.items?.length === 0){
        setaskForSampleBudget(true)
      }
      else{
        setaskForSampleBudget(false)
      }
    },[BudgetProfileList,BudgetProfile])

    const createSampleBudget = () => {
      setcreateSampleBudgetLoader(true)
      let percent = (BudgetProfile?.budget*100) / sampleBudgetAmount

      // CEATE SINGLE CAMPAIGN
      let profileArray = [BudgetProfile?.id]

      sampleBudgetCampaigns?.forEach((item,index) => {
        let Channel = ChannelList.find(channel => channel.name === item?.campaign?.channel)
        let campaignInputData = {
          account: account[0],
            name:item?.campaign?.name,
            metadata : JSON.stringify({tableEdited : false})
        }
        if(Channel){
          campaignInputData.channel = Channel?.id
        }
        else return


          HandleCreateCampaign(campaignInputData, profileArray, true).then(campaign => {

            let inputDataforCampaignDetails = {
              account: account[0],
              campaign_id : campaign?.id,
              date_start : item?.campaign?.campaign_details?.items[0]?.date_start,
              date_end : item?.campaign?.campaign_details?.items[0]?.date_end
            }

            HandlecreateCampaignDetails(inputDataforCampaignDetails).then(campaignDetails => {

              let InputDataForCampaignMonth = []
              item?.campaign?.campaign_details?.items[0]?.campaign_months?.items?.forEach(campaignMonth => {
                    let dataobj = {
                          account: account[0],
                          campaign_detail_id : campaignDetails?.id,
                          month:campaignMonth?.month,
                          budget: parseFloat(((campaignMonth?.budget*percent)/100).toFixed(2)),
                          leads: parseInt((campaignMonth?.leads*percent) / 100),
                          weight:campaignMonth?.weight,
                          weight_lead : campaignMonth?.weight
                        }
                        InputDataForCampaignMonth.push(dataobj)
                      })

              HandleCreateCampaignMonth(InputDataForCampaignMonth).then(budget => {
                if((index === sampleBudgetCampaigns?.length - 1)){
                  dispatch(getBudget(BudgetProfile?.id))
                  setaskForSampleBudget(false)
                setcreateSampleBudgetLoader(false)

                  message.success("Sample budget created successfully")
                }
                }).catch(error => {
                console.log("Error In creating Campaign month", error);
                message.error("Something went wrong");
                setcreateSampleBudgetLoader(false)

              })

            }).catch(error => {
              console.log("Error In Create Campaign Details", error)
              setcreateSampleBudgetLoader(false)

            })

          }).catch(error => {
            console.log("Error In creating Campaign", error);
            message.error("Failed to create Campaign")
            setcreateSampleBudgetLoader(false)
          })


      })

    }

    const HandleCreateCampaign = (value, budgetProfiles,) => {
      delete value["id"];
      return dispatch(createCampaign(value, budgetProfiles,true));
    };

    const HandlecreateCampaignDetails = (inputData) => {
      return dispatch(createCampaignDetails(inputData))
    }

    const HandleCreateCampaignMonth = (inputData) => {
      return Promise.all(inputData.map(item => {
        return dispatch(createCampaignMonth(item))
      }))
    }

    const [EditBudget, setEditBudget] = useState(false)

    const [updateBudgetLoader, setupdateBudgetLoader] = useState(false)

    const onActiveClick = async () => {
      const activeBudget = await BudgetProfileListDetails.find(
       (budgetProfileListValue) => budgetProfileListValue.active === true
     );
     if (activeBudget)
       dispatch(flipOtherBudgetStatus({ id: activeBudget.id, active: false }));

     handleOk({ id: BudgetProfile?.id, active: true });
   };

  const [ActivateBudgetModelOpen, setActivateBudgetModelOpen] = useState(false)

   const handleOk = (value) => {
    setupdateBudgetLoader(true)
    dispatch(updateBudget(value)).then(res => {
    setupdateBudgetLoader(false)
    setActivateBudgetModelOpen(false)
    }).catch(err => {
      console.log(err);
    setupdateBudgetLoader(false)
    setActivateBudgetModelOpen(false)

    });

  };

  const [isDeleteBudgetModelOpen, setisDeleteBudgetModelOpen] = useState(false);
  const [deletebudgetLoading, setdeletebudgetLoading] = useState(false)

  const handledeleteCampaign = async (campaignToDelete, budgetCampaigns) => {
    let campaign = BudgetProfile?.campaigns?.items?.find(item => item?.campaign?.id === campaignToDelete?.id)
            if(campaign) {
                let campaignMonthsToDelete = []
                let campaignDetailsToDelete = []
                campaign?.campaign?.campaign_details.items?.map((campaign_details)=>{
                    campaignDetailsToDelete.push(campaign_details?.id)
                    campaign_details?.campaign_months?.items?.forEach(month => {
                        campaignMonthsToDelete.push(month?.id)
                    })

                })

                await Promise.all(campaignMonthsToDelete.map(month => dispatch(deleteCampaignMonth({id: month}))))
                await Promise.all(campaignDetailsToDelete.map(detail => dispatch(deleteCampaignDetails({id: detail}))))
                await Promise.all(budgetCampaigns.map((budgetCampaign) =>ExploricApi.deleteBudgetCampaignApi({id: budgetCampaign.id})));
                await dispatch(deleteCampaign({id: campaignToDelete.id}));

            }
  };

  const [copyBudgetLoader, setCopyBudgetLoader] = useState(false)
  const onCopyBudget = () => {
      setCopyBudgetLoader(true)
      let value = {}
      value.BaselineMetricID = BudgetProfile?.BaselineMetricID
      value.account = BudgetProfile?.account
      value.budget = BudgetProfile?.budget
      value.budget_allocation = BudgetProfile?.budget_allocation
      value.name = `${BudgetProfile?.name} (Copy)`
      value.year = BudgetProfile?.year
      value.active = false

      let response = dispatch(createBudget(value, true));

      response.then(data => {
        return Promise.all(BudgetProfile?.campaigns?.items.map(element => {
         return copyCampaign(element, data)
        })).then(() => {
          handleCopyModalCancel();
          setCopyBudgetLoader(false)
        })
      })
  }

  const getData = (record) => {
    return BudgetProfile?.campaigns?.items?.find(item => item?.campaign?.id === record?.campaign?.id)?.campaign
};

  const copyCampaign = (record, response) => {
    const data = getData(record);
    if (data) {
    data.associatedBudgets = [response?.createBudget?.id]
      let inputData = {
        account: account[0],
        name:`${data?.name}`,
        channel : data?.channel,
      }

      HandleCreateCampaign(inputData, data.associatedBudgets).then(campaign => {
        let campaign_details = data?.campaign_details?.items?.[0]
        let inputDataForCampaignDetails = {
          account: account[0],
          campaign_id : campaign?.id,
          date_start : campaign_details?.budget_start,
          date_end : campaign_details?.budget_end,
        }

        HandlecreateCampaignDetails(inputDataForCampaignDetails).then(campaignDetails => {
          let InputDataForCampaignMonth = []
            campaign_details?.campaign_months?.items?.map(month => {
          let dataobj = {
                account: account[0],
                campaign_detail_id : campaignDetails?.id,
                month:month?.month,
                budget:month?.budget ? month?.budget : 0,
                leads: month?.leads ? month?.leads : 0,
                weight:month?.weight ? month?.weight : 0,
                weight_lead : month?.weight_lead ? month?.weight_lead : 0
              }
              InputDataForCampaignMonth.push(dataobj)
            })
            HandleCreateCampaignMonthMultiple(InputDataForCampaignMonth).then(budget => {
            dispatch(getBudget(props.selectedValue))
          }).catch(error => message.error("Something went wrong"))
        }).catch(error => {
          console.log("Error In Create Campaign Details", error)
        })

      }).catch(error => {
        console.log("Error In creating Campaign", error);
        message.error("Failed to create Campaign")
      })
      .then(() => {
        // settableLoading(false)
      })

    } else {
        message.error("Something went wrong!");
    }
};


const HandleCreateCampaignMonthMultiple = (inputData) => {
  return Promise.all(inputData.map(item => {
    return dispatch(createCampaignMonth(item))
  }))
}


  const handleDeleteBudget = async () => {
    setdeletebudgetLoading(true)
    let totalCampaigns = 0
    // eslint-disable-next-line array-callback-return
    let a = ChannelCampaignPlannedTotal['campaignDataBudget']?.map((Channel) => {
        if (Channel.children) {
          return Channel.children.map(campaign => {
            campaign.associatedBudgets =
                BudgetProfileListDetails.filter(
                  (profile) =>
                    profile.campaigns.items.filter(
                      (i) => i.campaign.id === Channel.id
                    ).length > 0
              );
              totalCampaigns+=1
            return campaign
          })
      }
    }).filter(i => i)

    if (totalCampaigns === 0) {
      dispatch(deleteBudget({ id: BudgetProfile?.id })).then(res => {
        reloaddata();
        setdeletebudgetLoading(false)
        setisDeleteBudgetModelOpen(false);

      }).catch(err => {
        reloaddata();
        setdeletebudgetLoading(false)
        setisDeleteBudgetModelOpen(false);

      });
    }
    else {
      let nextToken = null;
      let budgetCampaigns = [];
       do {
        const res = await ExploricApi.listBudgetCampaignApi(account[0], nextToken);
        budgetCampaigns.push(...res.data.listBudgetCampaigns.items);
        nextToken = res.data.listBudgetCampaigns.nextToken;
      } while (!!nextToken);


    Promise.all(a.map(item => {
      if(item){
        return Promise.all(item.map(campaign => {
          let budgetCampaignsToDelete = budgetCampaigns
        .filter(
          (budgetCampaign) => budgetCampaign.campaign.id === campaign.id
        )
        return handledeleteCampaign(campaign, budgetCampaignsToDelete)
        })).then(res => {
          return res
        }).catch(err => {
          console.log(err);
        })
      }
    })).then(res => {
      dispatch(deleteBudget({ id: BudgetProfile?.id })).then(res => {
        reloaddata();
        setdeletebudgetLoading(false)
        setisDeleteBudgetModelOpen(false);

      }).catch(err => {
        reloaddata();
        setdeletebudgetLoading(false)
        setisDeleteBudgetModelOpen(false);

      });
    })
    }


  };

  // const handleTourButtonClick = () => {
  //   setIsTourOpen(true);
  // }

  return (
    <>
    <Prompt message={preventNavigation} when={hasUnsavedChanges} />
      <Modal
        className="preventNavModal"
        visible={isShowNavModal}
        closable={false}
        footer={[
          <Button key="back" onClick={onLeave} style={{
            borderRadius: 7,
            height: 40 }}>
            Leave Anyway
          </Button>,
          <Button key="stay" type="primary" onClick={onStay}>
            Stay
          </Button>,
        ]}
      >
        <Row>
          <Col span={2}>
            <Typography.Title level={4}>
              <QuestionCircleOutlined style={{ color: "#faad14" }} />
            </Typography.Title>
          </Col>
          <Col span={22}>
            <Typography.Title level={5}>
              Are you sure you want to leave?
            </Typography.Title>
            <Typography.Text type="secondary">
              You have unsaved changes on this page. Leaving this page without
              saving may result in your changes being lost.
            </Typography.Text>
          </Col>
        </Row>
      </Modal>
 <div id={"budget_campaigns"}>
    {" "}
    <Row
      className="aboutPage panel-design"
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: '20px',
        background: "#fff",
        
        width: "100%",
        borderRadius: 7,
        alignContent:"center",
        padding:'30px 20px'
      }}
    >

      <Col
        className="details"
        style={{ display:"flex", justifyContent:"center", flexDirection:"column"}}
        span={24}
        lg={24}
        md={24}
        xs={24}
        sm={24}
      >
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <div style={{flex:1}}>
        <Typography.Text className="title fw700 headerMain"  style={{ color:'#1D2939', marginBottom:'15px', display:'block' }}><strong>Budget & Planning</strong></Typography.Text>
        <Typography.Text className="subHeaderMain" style={{ color:'#475467'}}>
          Develop your annual plan by allocating your budget across your defined <br />channels and campaigns, in order to project how and when you'll achieve your marketing leads.
        </Typography.Text>
        </div>
        {/* <Button style={{height:'45px'}} className="take-a-tour-btn non-print primaryButton" onClick={() => {
          TrackClickFunction('demand-gen-budget-and-campaigns-take-a-tour-button') 
          handleTourButtonClick()
          }} data-heap="demand-gen-budget-and-campaigns-take-a-tour-button">Take a tour</Button> */}
          </div>
      </Col>

      {/* <Col span={10}
          lg={10}
          md={10}
          xs={24}
          sm={24} >
        <Row justify="end">
        <img src={budgetImg} alt="" />
        </Row>
      </Col> */}

    </Row>

    <CreateBudgetProfile visible={visible} handleCancel={handleCancel} fiscalYearList={fiscalYearList} isEdit={false} budgetData={BudgetProfile} setNewCreatedBudget={(data) => afterCreateBudget(data) }/>
    {burgetPrifleNames ? (
      <>
       <div className="site-card-wrapper panel-design"  style={{backgroundColor: 'white',borderRadius: 7, padding:"20px 5px", marginBottom:'20px'}}>
      <Row gutter={[16, 16]} xs={24} md={12} lg={24}>
        <Col span={24} xs={24} md={24} lg={24}  >
          <Row gutter={[16, 16]} style={{padding: '0 5px'}}>
            <Col span={9} xs={24} md={24} lg={9} >
              <div data-tour = "tour__budgets" style={{background:"#f5f7fa", height:"100%", border:"1px solid #D0D5DD", borderRadius: '7px'}}>
              <Card bordered={false} className="card-select-budget" style={{padding:0, background:'transparent'}}>
                {getBudgetLoader ? <Skeleton active style={{padding:0}} />
              :
              <>

                <span className="budget-campaign-title" style={{color:'#1D2939',fontSize:'20px', fontWeight:700, marginBottom:'10px', display:'inline-block', marginLeft:"5px"}}>
                  Select Year & Budget {budgetChangeLoader && <LoadingOutlined style={{marginLeft:10}} />}
                </span>
                <div className="float-container budget-year" >
                  <div className="float-child " style={{ width: "25%" }}>
                    <div>
                      {" "}
                      <Select
                        value={defaultYear}
                        onSelect={handleYearChange}
                        style={{ width: "100%", color:'#1D2939' }}
                        placeholder="Select year"
                        defaultValue={defaultYear}
                        className="general-input-select"
                      >
                        {yearDropdown?.map((yearDropdownVal) => (
                          <Option
                            key={yearDropdownVal}
                          >
                            {yearDropdownVal}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {((userPermission?.['budgetCampaign.budgets'] !== 'viewonly' && userPermission?.['budgetCampaign.budgets'] !== 'hidden') && (userPermission?.['budgetCampaign']  !== 'preview' && userPermission?.['budgetCampaign']  !== 'viewonly')) &&
                    <div data-tour = "tour__createBudget" className="non-print">
                      <Button
                        type="text"
                        style={{fontSize:'14px', height:"24px", width:"150px", marginTop:'15px'}}
                        onClick={() => {
                          TrackClickFunction('demand-gen-budget-and-campaigns-new-budget-hyperlink');
                          showModal()
                        }}
                        data-heap="demand-gen-budget-and-campaigns-new-budget-hyperlink"
                        className="primaryButton"
                      >
                        <PlusOutlined /> New Budget
                      </Button>
                    </div>}
                  </div>
                  <div
                    className="float-child"
                    style={{ marginLeft: 8, width: "calc(70% - 8px)" }}
                  >
                    <div>
                      {" "}
                      <Select
                        value={budgetPrifileDefault}
                        onSelect={onBudgetProfileChange}
                        style={{ width: "100%", color:'#1D2939' }}
                        placeholder="Select Budget name"
                        defaultValue={budgetPrifileDefault}
                        className="general-input-select"
                      >
                        {burgetPrifleNames?.map((budgetVal) => (
                          <Option
                            key={budgetVal.id}
                          >
                            {budgetVal.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                </>
              }
              </Card>
              </div>
            </Col>

            <Col span={4} xs={24} md={24} lg={4} style={{display: 'flex', alignItems: 'center'}}>

            {getBudgetLoader ? <Skeleton active style={{padding:0}} /> : <div style={{width: '100%',display: 'flex', alignItems: 'center',  border: '1px solid #D0D5DD', borderRadius: '7px', padding:'14px 10px', justifyContent:'center', textAlign:'center', height:'100%', background:"#f5f7fa"}}>
                <div >
                  <h4 className="primaryColor fw700" style={{fontSize:'20px'}}>
                    Budget Total
                  </h4>
                  <h3 className="secondaryColor fw700" style={{fontSize:'18px', marginTop:'15px'}}>
                    ${numberWithCommas(BudgetProfile?.budget)}
                  </h3>
                </div>
              </div> }
            </Col>

            <Col span={11} xs={24} md={24} lg={11} style={{display: 'flex', alignItems: 'center'}} className="sandbox-wrapper">

              {getBudgetLoader ? <Skeleton active style={{padding:0}} />:
              <Row style={{width: '100%',display: 'flex', alignItems: 'center', border:'1px solid #D0D5DD', borderRadius: 7, padding: '10px 15px 10px 15px', height:'100%', background:"#f5f7fa"}}>
                {/* <Col span={2}>
                <img src={BudgetProfile?.active ? TickImg : SandboxImg}  style={{height: 35}} alt="sandbox" />
                </Col> */}
                <Col span={24} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',flexWrap: 'wrap'}}>
                <Row justify="space-between" style={{width:"100%"}} align="center">
                <Col span={22} style={{display: 'flex', alignItems: 'center'}}>
                  {BudgetProfile?.active ?
                  <Col className="text budget-active-status" style={{ paddingLeft: "15px", color:"#7F56D9" }}>
                    Budget is now active
                  </Col>
                  :
                    <Row>
                      <Col span={18}>
                    <span style={{fontSize:18 ,color: '#7F56D9' , fontWeight: '700',marginBottom:'10px', display:'inline-block'}}>
                      Sandbox
                    </span>
                    <h4 className="text" style={{ marginBottom: 0, color:"#1D2939", fontWeight: '500' }}>
                    {BudgetProfile?.name} Budget Status
                    </h4>
                    </Col>
                    <Col span={6} style={{display: 'flex', alignItems: 'center'}}>
                    <Button
                    type="secondary "
                    style={{
                      height:"38px",
                      width:"120px",
                      fontSize: "16px",
                    }}
                    onClick={() => setActivateBudgetModelOpen(true)}
                    className="secondaryButton"
                    >
                      Activate
                    </Button>
                    </Col>
                    </Row>
                  }
                  </Col>
                  <Col span={2} style={{display: 'flex', alignItems: 'center', justifyContent:'flex-end'}}>
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="edit-budget"
                            onClick={() => {
                              setEditBudget(true)
                            }}
                          >
                            <strong style={{color:'#1D2939', fontWeight:500}}>Edit Budget</strong>
                          </Menu.Item>

                          <Menu.Item
                            key="delete-budget"
                            onClick={() => {
                              setisDeleteBudgetModelOpen(true);
                            }}
                            disabled={BudgetProfile?.active}
                          >
                              <Tooltip title={BudgetProfile?.active ? "Make another budget Active to delete this budget" : ""} >
                            <strong style={{color:'#1D2939', fontWeight:500}}>Delete Budget</strong>
                          </Tooltip>
                          </Menu.Item>

                          <Menu.Item
                            onClick={showCopyModal}
                            key="copy-budget"
                          >
                            <strong style={{color:'#1D2939', fontWeight:500}}>Copy Budget</strong>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <MoreOutlined style={{ fontSize: "25px" }} />
                    </Dropdown>
                  </Col>
                  </Row>
                </Col>
              </Row>}
            </Col>
          </Row>
        </Col>

        {(userPermission?.['budgetCampaign.budgets']  === 'viewonly' || userPermission?.['budgetCampaign']  === 'viewonly') && <Col span={8} xs={9} md={24} lg={8} className="non-print" >
          <Card bordered={false} className="card-select-budget">
            <div className="float-container" style={{ padding: 0 }}>
              <div style={{ marginLeft: 8 }}>
                <div className="budget-create-label">
                  Start with new budget
                </div>
                <div className="create-budget-btn" style={{ marginTop: 14 }} data-tour = "tour__createBudget">
                  <Button
                    type="primary"
                    className="create-budget-btn"
                    title = "View only permission"
                    disabled
                    // onClick={showModal}
                  >
                    Create New Budget
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Col>}
      </Row>
    </div>
        <Tabs
          className="budget-tab panel-design general-tab"
          style={{marginBottom: '20px'}}
          // tabBarExtraContent={totalBudgetAmount}
          centered
          animated
          data-tour = "tour__budgetAnalysis"
        >
          <TabPane tab="Budget Analysis" key="1" className="campaign-tabs"  >
            <BudgetSummary
            getBudgetLoader={getBudgetLoader}
              setCheckedQuarters = {handleQuarterSelection}
              selectedProfile={selectedValue}
              actualTotalLeadsData={actualTotalLeadsData}
              actualTotalRevenueData={actualTotalRevenueData}
              actaulRevenueLeadsQuarter={actaulRevenueLeadsQuarter}
              quarterlyRevenueGoal={quarterlyRevenueGoal}
              TotalLeadsGoal = {TotalLeadsGoal}
              lastStageName={lastStageName}
              nameOfLeadObj = {nameOfLeadObj}
            />
          </TabPane>
          <TabPane tab="Budget Planning" key="2" className="campaign-tabs"   >
            <BudgetPlanning
            getBudgetLoader={getBudgetLoader}
              selectedProfile={selectedValue}
              reloaddata={reloaddata}
              BudgetProfile={BudgetProfile}
              quarterlyRevenueGoal={quarterlyRevenueGoal}
              TotalLeadsGoal={TotalLeadsGoal}
              fiscalYearList={fiscalYearList}
              userPermission = {userPermission}
              EditBudget={EditBudget}
              setEditBudget={setEditBudget}
              nameOfLeadObj = {nameOfLeadObj}
            />
          </TabPane>
        </Tabs>
        <div data-tour = "tour__budgetCampaigns" >
          <Campaigns
          sethasUnsavedChanges={sethasUnsavedChanges}
            selectedYear = {defaultYear}
          selectedValue={selectedValue}
          campaignTotalSpend={campaignTotalSpend}
          actualTotalLeadsData={actualTotalLeadsData}
          campaignPlanningEmpty={campaignPlanningEmpty}
          selectedBudgetDetails={BudgetProfile}
          selectedQuarters={selectedQuarters}
          TotalLeadsGoal = {TotalLeadsGoal}
          userPermission = {userPermission}
          setaskForSampleBudget={setaskForSampleBudget}
          nameOfLeadObj={nameOfLeadObj}
          listOfYear = {yearDropdown}
          />{" "}
         </div>
      </>
    ) : (
      <div className="empty-budget-profile-view">
        <div className="no-budget-profile-img">
          <img src={create_budget_info} alt="" />
        </div>
        <div className="no-budget-profile-content-parent">
          <div className="no-budget-profile-content-child1">
            This is a place to create your marketing campaigns plan for the
            entire year.
          </div>
          <div className="no-budget-profile-content-child2">
          Add your annual marketing campaigns budget. This may include budget for content creation and software, but it should not include marketing headcount.
          </div>
          <div className="no-budget-profile-content-child3">
            {((userPermission?.['budgetCampaign.budgets'] !== 'viewonly' && userPermission?.['budgetCampaign.budgets'] !== 'hidden') && (userPermission?.['budgetCampaign']  !== 'preview' && userPermission?.['budgetCampaign']  !== 'viewonly')) && <Button className="btn-type3" onClick={showModal}>
              Create a budget to get started
            </Button>}
            {(userPermission?.['budgetCampaign.budgets']  === 'viewonly' || userPermission?.['budgetCampaign']  === 'viewonly') &&
              <Button disabled className="btn-type3" title="View only permission">
              Create a budget to get started
            </Button>
            }
          </div>
        </div>
      </div>
    )}
    {/* <Tour
      steps={steps}
      accentColor={"#0F61DB"}
      isOpen={isTourOpen}
      onRequestClose={() => {setIsTourOpen(false); updateTour()}}
      rounded={5}
      scrollDuration={100}
      disableInteraction={true}
      onAfterOpen={target => (document.body.style.overflowY = 'hidden')}
      onBeforeClose={target => (document.body.style.overflowY = 'auto')}
      lastStepNextButton={
        <Button type="primary" onClick={() => updateTour()}>
        Get Started
      </Button>
      }
      disableFocusLock={true}
      closeWithMask={false}
      getCurrentStep={(curr) => setc(curr + 1)}
      startAt={0}
    /> */}
  </div>



    <Modal closable={false}  className="delete-budget-camp" centered style={{ borderRadius: "20px" }} visible={copyModal} footer={[]} onCancel={handleCopyModalCancel}>
      <div className="delete-modal-body">
          <h2 style={{fontWeight: 600, marginBottom: 3, fontSize:"18px", color:"#1D2939", marginBottom:"10px"}}>Are you sure you want to copy this budget?</h2>
          <Typography.Text style={{ marginBottom: "10px", fontSize: "16px", textAlign: 'center', color:"#475467" }}>
          The budget generated from a copy will include all channels, campaigns, and budget/lead configurations. This budget will get copied in the same selected fiscal year {defaultYear}.
          </Typography.Text>
          <div className="delete-modal-footer">
            <Button
              size="large"
              type="primary"
              style={{borderColor: "#EC4A0A", color: "#fff", backgroundColor: '#EC4A0A'}}
              onClick={() => setCopyModal(false)}
              className="secondaryButton"
            >
              No
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={()=>onCopyBudget()}
              style={{ borderColor: "#0F61DB", color: "white" }}
              loading = {copyBudgetLoader}
              className="primaryButton"
            >
              Yes , Sure
            </Button>
          </div>
        </div>
    </Modal>

{EditBudget && <CreateBudgetProfile visible={EditBudget} handleCancel={()=> setEditBudget(false)} budgetData={BudgetProfile} fiscalYearList={fiscalYearList} isEdit={true}/>}


        <Modal
          className="delete-budget-camp"
          visible={askForSampleBudget}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <Typography.Text style={{ marginTop: "10px",marginBottom:20,fontSize:18 }}>
            Would you like to populate your budget with an example budget plan, based on Demand Generation’s best practice recommendations?
            </Typography.Text>
            <div className="delete-modal-footer" >
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  createSampleBudget();
                }}
                loading={createSampleBudgetLoader}
                >
                Yes
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => setaskForSampleBudget(false)}
                disabled={createSampleBudgetLoader}
                ghost

              >
                No
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          className="delete-budget-camp"
          visible={ActivateBudgetModelOpen}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <Typography.Text style={{ marginTop: "10px", fontSize: "20px", color:"#1D2939", textAlign:'center' }}>
            This will turn the currently active budget to sandbox
         mode and make this budget the active. Are you sure you want to continue?
            </Typography.Text>
            <div className="delete-modal-footer" style={{marginTop:'20px'}}>
              <Button
                type="primary"
                size="small"
                ghost
                onClick={() => {
                  onActiveClick();
                }}
                style={{  width:'150px', backgroundColor:"#7F56D9", color:'#fff' }}
                loading={updateBudgetLoader}
                className="primaryButton"
              >
                Yes
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => setActivateBudgetModelOpen(false)}
                disabled={updateBudgetLoader}
                className="secondaryButton"
                style={{width:'150px'}}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>

        {isDeleteBudgetModelOpen && (
        <Modal
          className="delete-budget-camp"
          visible={isDeleteBudgetModelOpen}
          closable={false}
          centered
          footer={[]}
          style={{ borderRadius: "20px" }}
        >
          <div className="delete-modal-body">
            <img src={binIcon} alt="delete icon" />
            <Typography.Text style={{ marginTop: "10px", fontSize: "20px", color:"#1D2939" }}>
              Are you sure you want to delete this budget?
            </Typography.Text>
            <div className="delete-modal-footer" style={{marginTop:"20px"}}>
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  handleDeleteBudget();
                }}
                loading={deletebudgetLoading}
                className="primaryButton"
                >
                Yes
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => setisDeleteBudgetModelOpen(false)}
                style={{backgroundColor:"#EC4A0A", color:"#fff",borderColor:"#F04438"}}
                disabled={deletebudgetLoading}
                ghost
                className="secondaryButton"
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      )}


  </>

  );
};

export default BudgetCampaignsOld;
