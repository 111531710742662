import * as actions from "../actions/index";

const initialState = {
  token: null,
  roles: [],
  userData: {},
  admin_groups_self: [],
  selectedCompanyName: "",
  accountDetails: "",
  userPermission:"",
  viewType:"",
  accountRequests : [],
  dashboardTourOpen :false
  
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LOGIN:
      let userData = { ...action.data };
      let groupValue = [];
      if ("cognito:groups" in action.payload.accessToken.payload) {
        groupValue = action.payload.accessToken.payload["cognito:groups"];
        groupValue.push(groupValue.splice(groupValue.indexOf("admin"), 1)[0]);
        if(groupValue?.includes('stage')){
          groupValue.splice(groupValue.indexOf("stage"), 1)
          groupValue.push("stage")
        }
      }

       let newData = [...groupValue];

      groupValue.forEach((item) => {
        if (item.length === 36) {
          newData[0] = item;
        } else if (item === "companyadmin" || item === "companycontributor") {
          newData[1] = item;
        } else if (item === "admin") {
          newData[2] = item;
        }
      });
      return {
        ...state,
        token: action.payload.accessToken.jwtToken,
        groups: newData,
        admin_groups_self: newData,
        userData: { ...userData },
      };

    case actions.UPDATE_COMPANY_ACCOUNT:
      const index = 0;
      const replacementItem = action?.payload?.id;
      let companyName = action?.payload?.company;
      let updatedAccount = Object.assign([], state.groups, {
        [index]: replacementItem,
      });
      localStorage.removeItem("FiscalYear");
      localStorage.removeItem("Budget");
      if (state.selectedCompanyName?.[0] === state.groups?.[0]) companyName = "";
      return {
        ...state,
        token: state.token,
        groups: updatedAccount,
        selectedCompanyName: companyName,
        userData: { ...state.userData },
      };

    case actions.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.payload
      };
    case actions.SET_DASHBOARD_TOUR_OPEN:
      return {
        ...state,
        dashboardTourOpen: action.payload
      };

    case actions.SET_PERMISSION:
      return {
        ...state,
        userPermission: action.payload
      };

    case actions.SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload
      };

      case actions.SET_ACCOUNT_REQUESTS:
        return {
          ...state,
          accountRequests: action.payload
        };


    case actions.LOGOUT:
      localStorage.removeItem("persist:root")
      localStorage.clear()
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;
