import { API, graphqlOperation } from "aws-amplify";
import {
  createAccountRequest,
  deleteAccountRequest,
  updateAccount,
} from "graphql/mutations";
import { getAccount,listAccounts } from "graphql/queries";
import { v4 as uuidv4 } from "uuid";

// Create a new account
const createAccount = async (company, url, users = [], isAdmin, industry, company_size, fiscalYearMonth = 1) => {
  const path = `/accounts`;
  if (!isAdmin) {
    isAdmin = false;
  }
  try {
    var result = await API.post("exploricsREST", path, {
      body: { company, url, users, isAdmin, industry,company_size, fiscalYearMonth },
    });
    return result;
  } catch (e) {
    console.log(e.response?.data?.error)
    return { error: e.response?.data?.error ? e.response?.data?.error : "Failed creating account"};
  }
};

const companyUpdate = async (inputdata) => {
  const path = "/updateaccount"
  try {
    var result = await API.put("exploricsREST", `${path}`, {
      body : {
        ...inputdata
      }
    })
    return result
  } catch (e) {
    console.log(e);
    return { errors: ["Failed to Update the Data"] };
  }
};


const fetchAccount = async (accountID) => {
  try {
    let account = await API.graphql(
      graphqlOperation(getAccount, { id: accountID })
    );
    return account.data.getAccount;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving account"] };
  }
};

const requestAccess = async (accountID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(createAccountRequest, {
        input: { id: uuidv4(), account: accountID },
      })
    );

    request = request.data.createAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const deleteRequest = async (requestID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(deleteAccountRequest, {
        input: { id: requestID },
      })
    );

    request = request.data.deleteAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const approveRequest = async (id) => {
  const path = `/approverequest/requests/${id}/approve`;
  try {
    var result = await API.post("exploricsREST", path);
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed approving request" };
  }
};

const sendEmailInvitation = async (value) => {
  const path = `/accountsmember/invite`;
  let body = {};
  body.emails = value.emails;
  if (value.account_id) {
    body.account_id = value.account_id;
  }
  if (value.name) {
    body.name = value.name;
  }
  try {
    var result = await API.post("exploricsREST", path, {
      body: body,
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed sending invitation" };
  }
};

const userSuspention = async (userid, suspend) => {
  let apiName = "exploricsREST";
  let path = "/admin/suspend";
  try {
    var result = await API.post(apiName, path, {
      body: { user_id: userid, suspend: suspend },
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed user suspension action" };
  }
};

const companySuspension = async (companyId, suspend) => {
  try {
    let request = await API.graphql(
      graphqlOperation(updateAccount, {
        input: {
          id: companyId,
          disabled: suspend,
        },
      })
    );

    request = request.data.updateAccount;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const listDefaultProperties = async (accountId) => {
  let prperty_path = `/properties/default_properties`;
  if(accountId) {
    prperty_path = `/properties/get_properties?account_id=${accountId}`;
  }
  const resultInArray = [];
  try {
    let result = await API.get("exploricsREST", prperty_path);
    if(accountId) {
      return result;
    } else {
      for (const [key, value] of Object.entries(result)) {
        resultInArray.push({ name: key, value: value["default_value"] });
      }
      return resultInArray;
    }
  } catch (e) {
    console.log("Error :", e);
    return { errors: ["Something went wrong"] };
  }
};


const fetchCompanies = async (nextToken,filter={}) => {
  try {
    let companyList
    if (Object.keys(filter).length > 0) {
      
      companyList = await API.graphql(
          graphqlOperation(listAccounts, { nextToken: nextToken, filter: filter})
        );
    }
    else {
      
      companyList = await API.graphql(
          graphqlOperation(listAccounts, { limit: 999, nextToken: nextToken})
        );
      
    }
    return companyList
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving Companies"] };
  }
};

const getOrgCheckoutSession = async (stripePriceId, priceId, accountId) => {
  let url = `/billing/checkout/org?stripePriceId=${stripePriceId}&internalPriceId=${priceId}&accountId=${accountId}`;

  try {
    let result = await API.get('exploricsREST', url);
    return result;
  } catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch session' };
  }
};


const DeleteUser = async (inputdata) => {
  let apiName = "exploricsREST";
  let path = "/delete/user";
  try {
    var result = await API.post(apiName, path, {
      body: inputdata,
    });
    if(result){
      return result;
    }
    else{
      return { error: "Failed to delete user" };
    }
    
  } catch (e) {
    console.log(e);
    return { error: "Failed to delete user" };
  }
};
const DeleteCompanyAccount = async (inputdata) => {
  let apiName = "exploricsREST";
  let path = "/delete/account";
  try {
    return API.post(apiName, path, {
      body: inputdata,
    }).then(res =>
      ({ success: "company deleted successfully." })
    ).catch(e => {console.log("Error", e);
    return { error: "Failed to delete company" };
  });
    
  } catch (e) {
    console.log(e);
    return { error: "Failed to delete company" };
  }
};

  const getSignedPutURL = async (filename) => {
    let url = `/getSignedPutURL?filename=${filename}`;
  
    try {
      let result = await API.get('exploricsREST', url);
      return result;
    } catch (e) {
      console.log('error retrieving data', e);
      return { error: 'Failed to fetch session' };
    }
  };

const sdk = {
  DeleteUser,
  DeleteCompanyAccount,
  createAccount,
  fetchAccount,
  requestAccess,
  deleteRequest,
  approveRequest,
  sendEmailInvitation,
  userSuspention,
  companySuspension,
  listDefaultProperties,
  fetchCompanies,
  getOrgCheckoutSession,
  companyUpdate,
  getSignedPutURL
};

export default sdk;
