import { message } from 'antd';
import { API } from 'aws-amplify';
import { ExploricApi } from 'utility/Api';
import {
  SHOW_LOADER,
  SET_CALCULATOR_INFO,
  SET_SDR_COMPENSATION_MODELS,
  SET_SDR_CALCULATOR_SUMMARY,
  SET_SDR_ACTIVITY_TRACKER_DATA,
  SET_SDR_ACTUAL_DATA,
} from './index';

export const setLoader = (data) => {
  return {
    type: SHOW_LOADER,
    payload: data,
  };
};
export const setCalculatorInfo = (data) => {
  return {
    type: SET_CALCULATOR_INFO,
    payload: data,
  };
};
export const SetSDRCompensationModels = (data) => {
  return {
    type: SET_SDR_COMPENSATION_MODELS,
    payload: data,
  };
};

export const SetSDRActivityTrackerList = (data) => {
  return {
    type: SET_SDR_ACTIVITY_TRACKER_DATA,
    payload: data,
  };
};

export const SDRCalculatorSummary = (data) => {
  return {
    type: SET_SDR_CALCULATOR_SUMMARY,
    payload: data,
  };
};

export const setSDRActuals = (data) => {
  return {
    type: SET_SDR_ACTUAL_DATA,
    payload: data,
  };
};

export const getSDRCalculatorData = () => (dispatch, getState) => {
  dispatch(setLoader(true));
  const account = getState().loginReducer.groups;
  return ExploricApi.listSdrCapacityCalculatorsApi({
    account: { eq: account[0] },
  })
    .then((response) => {
      dispatch(setCalculatorInfo(response?.data));
      dispatch(setLoader(false));
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const saveSDRCalculatorData = (inputdata) => (dispatch) => {
  dispatch(setLoader(true));
  return ExploricApi.saveSDRCalculation(inputdata)
    .then((response) => {
      dispatch(getSDRCalculatorData());
      dispatch(setLoader(false));
      message.success('Saved successfully');
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const updateSDRCalculatorData = (inputdata) => (dispatch) => {
  dispatch(setLoader(true));
  return ExploricApi.updpateSDRCalculationApi(inputdata)
    .then((response) => {
      dispatch(getSDRCalculatorData());
      dispatch(setLoader(false));
      message.success('Saved successfully');
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const createSDRCompensationModel = (inputdata) => (dispatch) => {
  return ExploricApi.CreateSDRCompensationModelApi(inputdata)
    .then((response) => {
      dispatch(listSDRCompensationModels());
      message.success('Model created successfully');
      return response;
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const createActivityTrackerSDRProfileData =
  (inputdata) => (dispatch) => {
    dispatch(setLoader(true));
    return ExploricApi.createActivityTrackerSDRProfileApi(inputdata)
      .then((response) => {
        // dispatch(listSDRCompensationModels())
        message.success('SDR created successfully');
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        console.log('Error', err);
        dispatch(setLoader(false));
        message.error('Something went wrong');
      });
  };

export const updateActivityTrackerSDRProfileData =
  (inputdata) => (dispatch) => {
    dispatch(setLoader(true));
    return ExploricApi.updateActivityTrackerSDRProfileApi(inputdata)
      .then((response) => {
        // dispatch(listSDRCompensationModels())
        message.success('SDR updated successfully');
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        console.log('Error', err);
        dispatch(setLoader(false));
        message.error('Something went wrong');
      });
  };

export const updateSDRCompensationModel = (inputdata) => (dispatch) => {
  return ExploricApi.UpdateSDRCompensationModelApi(inputdata)
    .then((response) => {
      dispatch(listSDRCompensationModels());
      message.success('Model updated successfully');
      return response;
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const deleteCompesationModelData = (inputdata) => (dispatch) => {
  dispatch(setLoader(true));
  return ExploricApi.deleteCompesationModelApi(inputdata)
    .then((response) => {
      dispatch(listSDRCompensationModels());
      message.success('Model deleted successfully');
      dispatch(setLoader(false));
      return response;
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const deleteSDRRecordData = (inputdata) => (dispatch) => {
  dispatch(setLoader(true));
  return ExploricApi.deleteSDRRecordApi(inputdata)
    .then((response) => {
      // dispatch(listSdrMeetingAndOpportunityModelsData())
      message.success('SDR record deleted successfully');
      dispatch(setLoader(false));
      return response;
    })
    .catch((err) => {
      console.log('Error', err);
      dispatch(setLoader(false));
      message.error('Something went wrong');
    });
};

export const listSDRCompensationModels = () => (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  return ExploricApi.ListSDRCompensationModelsApi({
    account: { eq: account[0] },
  })
    .then((response) => {
      response?.data?.listSDRCompensationModels?.items.sort(
        GetSortOrder('updatedAt')
      );
      dispatch(
        SetSDRCompensationModels(
          response?.data?.listSDRCompensationModels?.items
        )
      );
      dispatch(setLoader(false));
    })
    .catch((error) => {
      console.log('Error', error);
    });
};

export const listSdrMeetingAndOpportunityModelsData =
  () => (dispatch, getState) => {
    const account = getState().loginReducer.groups;
    return ExploricApi.listSdrMeetingAndOpportunityModelsAPi({
      account: { eq: account[0] },
    })
      .then((response) => {
        // response?.data?.ListSdrMeetingAndOpportunityModels?.items.sort(GetSortOrder("updatedAt"))
        dispatch(
          SetSDRActivityTrackerList(
            response?.data?.listSDRMeetingAndOpportunityModels?.items
          )
        );
        dispatch(setLoader(false));
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };
const GetSortOrder = (prop) => {
  return (a, b) => {
    if (a[prop] > b[prop]) {
      return -1;
    } else if (a[prop] < b[prop]) {
      return 1;
    }
    return 0;
  };
};

export const getHubspotOwnerList = (HubspotID) => () => {
  return API.get(
    'backendRest',
    `/mapping/getHubspotOwners?hub_id=${HubspotID}`
  );
};

export const getSDRTrackerActuals =
  (HubspotProfiles, SegmentID) => (dispatch, getState) => {
    const account = getState().loginReducer.groups;

    let payload = {
      body: {
        hubspot_owner: HubspotProfiles,
        segment_id: SegmentID,
        account_id: account[0],
      },
    };
    return API.post('backendRest', '/mapping/getHubspotOwnersActuals', payload);
  };
