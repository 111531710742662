/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Tooltip, Typography, Skeleton } from "antd";
import BudgetPieChart from "../../commons/PieChart";
import BudgetLiquidChart from "../../commons/LiquidChart";
import { nFormatter, numberWithCommas } from "utility/context/CommonMethods";
import { setProspects } from "store/actions/budgetAction";
import { convertReverseArray } from "utility/context/CommonMethods";
import leadAnalysis from "assets/Images/lead_analysis.svg";
import revenueAnalysis from "assets/Images/revenue_analisys.svg";

const BudgetAnalysis = (props) => {
  const { getBudgetLoader ,nameOfLeadObj} = props
  const [totalRevenuegoal, settotalRevenuegoal] = useState();
  const [totalLeadsGoal, settotalLeadsGoal] = useState();
  const [checkedItems, setCheckedItems] = useState({
    Q1: true,
    Q2: true,
    Q3: true,
    Q4: true,
  });
  const [prospectCount, setProspectCount] = useState(0);
  const dispatch = useDispatch();
  const { BudgetProfile, ChannelCampaignPlannedTotal, CampaignList } =
    useSelector((state) => state.budgetReducer);
  const [plannedLeadImpact, setPlannedLeadImpact] = useState();
  const [plannedBudgetImpact, setPlannedBudgetImpact] = useState();
  const [filteredBudgetAmount, setFilteredBudgetAmount] = useState();
  const [filteredBudgetAmountRemaining, setFilteredBudgetAmountRemaining] =
    useState();
  const { marketingSourcedCount, funnelStages } =
    useSelector((state) => state.revenueReducer);
  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };
  const [actualRevenuQ, setActaulRevenueQ] = useState();
  const [actualLeadsQ, setActaulLeadsQ] = useState();
  useEffect(() => {
    // filters allocated budget and camapaign total by selected quarters
    const countQuarter = Object.values(checkedItems).filter(function (value) {
      return value === true;
    }).length;

    countQuarter === 4 ? setCheckedAll(true) : setCheckedAll(false);
    const leadImpactQuarter =
      (checkedItems.Q1 ? ChannelCampaignPlannedTotal.lead?.q1['projectedLeads'] : 0) +
      (checkedItems.Q2 ? ChannelCampaignPlannedTotal.lead?.q2['projectedLeads'] : 0) +
      (checkedItems.Q3 ? ChannelCampaignPlannedTotal.lead?.q3['projectedLeads'] : 0) +
      (checkedItems.Q4 ? ChannelCampaignPlannedTotal.lead?.q4['projectedLeads'] : 0);

    setPlannedLeadImpact(leadImpactQuarter);
    const budgetAllocation = BudgetProfile?.budget_allocation
      ? JSON.parse(BudgetProfile.budget_allocation)
      : "";
    const budgetImpactQuarter =
      (checkedItems.Q1 && budgetAllocation?.budget_q1 ? ChannelCampaignPlannedTotal.budget?.q1['projected'] : 0) +
      (checkedItems.Q2 && budgetAllocation?.budget_q2 ? ChannelCampaignPlannedTotal.budget?.q2['projected'] : 0) +
      (checkedItems.Q3 && budgetAllocation?.budget_q3 ? ChannelCampaignPlannedTotal.budget?.q3['projected'] : 0) +
      (checkedItems.Q4 && budgetAllocation?.budget_q4 ? ChannelCampaignPlannedTotal.budget?.q4['projected'] : 0);
    setPlannedBudgetImpact(parseFloat(parseFloat(budgetImpactQuarter).toFixed(2)));


    const budgetProfileTotalQuarter =
      (checkedItems.Q1 ? parseInt(budgetAllocation?.budget_q1) : 0) +
      (checkedItems.Q2 ? parseInt(budgetAllocation?.budget_q2) : 0) +
      (checkedItems.Q3 ? parseInt(budgetAllocation?.budget_q3) : 0) +
      (checkedItems.Q4 ? parseInt(budgetAllocation?.budget_q4) : 0);

    const revenueQuarterActual =
      (checkedItems.Q1
        ? props.actaulRevenueLeadsQuarter?.Q1?.closed_deal_amount
        : 0) +
      (checkedItems.Q2
        ? props.actaulRevenueLeadsQuarter?.Q2?.closed_deal_amount
        : 0) +
      (checkedItems.Q3
        ? props.actaulRevenueLeadsQuarter?.Q3?.closed_deal_amount
        : 0) +
      (checkedItems.Q4
        ? props.actaulRevenueLeadsQuarter?.Q4?.closed_deal_amount
        : 0);
    setActaulRevenueQ(revenueQuarterActual);

    const leadsQuarterActual =
      (checkedItems.Q1 ? props.actaulRevenueLeadsQuarter?.Q1?.deals_count : 0) +
      (checkedItems.Q2 ? props.actaulRevenueLeadsQuarter?.Q2?.deals_count : 0) +
      (checkedItems.Q3 ? props.actaulRevenueLeadsQuarter?.Q3?.deals_count : 0) +
      (checkedItems.Q4 ? props.actaulRevenueLeadsQuarter?.Q4?.deals_count : 0);
    setActaulLeadsQ(leadsQuarterActual);

    setFilteredBudgetAmount(
      isFinite(budgetProfileTotalQuarter) ? budgetProfileTotalQuarter : 0
    );
    setFilteredBudgetAmountRemaining(
      isFinite(budgetProfileTotalQuarter - budgetImpactQuarter)
        ? parseFloat(parseFloat(budgetProfileTotalQuarter - budgetImpactQuarter).toFixed(2))
        : 0
    );

    const totalrevenue = Number((checkedItems.Q1 ? props.quarterlyRevenueGoal?.budget_q1 : 0) + (checkedItems.Q2 ? props.quarterlyRevenueGoal?.budget_q2 : 0) + (checkedItems.Q3 ? props.quarterlyRevenueGoal?.budget_q3 : 0) + (checkedItems.Q4 ? props.quarterlyRevenueGoal?.budget_q4 : 0)).toFixed(0)
    settotalRevenuegoal(totalrevenue)

    const totalleads = Number((checkedItems.Q1 ? props.TotalLeadsGoal?.budget_q1 : 0) + (checkedItems.Q2 ? props.TotalLeadsGoal?.budget_q2 : 0) + (checkedItems.Q3 ? props.TotalLeadsGoal?.budget_q3 : 0) + (checkedItems.Q4 ? props.TotalLeadsGoal?.budget_q4 : 0)).toFixed(0)
    settotalLeadsGoal(totalleads)

    props.setCheckedQuarters(checkedItems)

  }, [checkedItems, ChannelCampaignPlannedTotal, BudgetProfile, CampaignList]); // eslint-disable-line

  // useEffect(() => {
  //   dispatch(getBudget(props.selectedProfile));
  // }, [props.selectedProfile]); // eslint-disable-line
  
  
  useEffect(() => {
    settotalRevenuegoal(Number(props.quarterlyRevenueGoal?.budget_q1 + props.quarterlyRevenueGoal?.budget_q2 + props.quarterlyRevenueGoal?.budget_q3 + props.quarterlyRevenueGoal?.budget_q4).toFixed(0))
    
    settotalLeadsGoal(parseInt(props.TotalLeadsGoal?.budget_q1 + props.TotalLeadsGoal?.budget_q2 + props.TotalLeadsGoal?.budget_q3 + props.TotalLeadsGoal?.budget_q4))
  },[props.quarterlyRevenueGoal, props.TotalLeadsGoal])
  const checkboxes = [
    {
      name: "Q1",
      key: "1stQuarter",
      label: "Q1"
    },
    {
      name: "Q2",
      key: "2ndQuarter",
      label: "Q2",
    },
    {
      name: "Q3",
      key: "3rdQuarter",
      label: "Q3",
    },
    {
      name: "Q4",
      key: "4thQuarter",
      label: "Q4",
    },
  ];

  const getLeadsData = (funnelStages) => {
    let newChartData = [];
    let newConvertData = [...convertReverseArray(funnelStages)];
    newConvertData.map((item, index) => {
      let { name, conversion, id } = item;
      let newValue =
        index === 0
          ? !isFinite(marketingSourcedCount)
            ? 0
            : Number.parseInt(marketingSourcedCount)
          : Number.parseInt(
              (newChartData[index - 1].value * 100) /
                Number.parseInt(item.conversion)
            );
      let setWidth = index === 0 ? 250 : conversion * 10 + 250;
      let obj = {
        id: id,
        text: name,
        percentageValue: conversion,
        value: newValue,
        width: setWidth,
      };
      newChartData.push({ ...obj });
      return 0;
    });
    return [...convertReverseArray(newChartData)];
  };

  useEffect(() => {
    // set prospect count for selected budget profile
    const leadsData = getLeadsData(funnelStages);
    setProspectCount(leadsData[0]?.value);
    dispatch(setProspects(leadsData[0]?.value));
  }, [prospectCount, props.selectedProfile]); // eslint-disable-line

  useEffect(() => {
    const leadsData = getLeadsData(funnelStages);
    setProspectCount(leadsData[0]?.value);
    dispatch(setProspects(leadsData[0]?.value));
  }, [funnelStages]); // eslint-disable-line

  const quarterCheckboxDefault = checkboxes.map((item, index) => (
    <span key={index}>
      <input
        type="checkbox"
        name={item.name}
        checked={checkedItems[item.name]}
        onChange={handleChange}
        style={{ verticalAlign: "middle" }}
      />
      <span style={{ paddingRight: 15, paddingLeft: 2 }}>{item.label}</span>
    </span>
  ));

  const [checkedAll, setCheckedAll] = useState(true);
  const handleAll = (val) => {
    setCheckedAll(!checkedAll);
    setCheckedItems({
      Q1: !checkedAll,
      Q2: !checkedAll,
      Q3: !checkedAll,
      Q4: !checkedAll,
    });
  };

  const {campaignActualsData} = useSelector(
    (state) => state.budgetReducer
);
const [leadsActual, setleadsActual] = useState(0)
  useEffect(() =>{
    let totalLeads = 0
    let Campaigns = BudgetProfile?.campaigns?.items?.map(item => item?.campaign?.id)
    Object.keys(campaignActualsData)?.map(key => {
      if(Campaigns?.includes(key))
      Object.keys(campaignActualsData[key]).map(month => {
        totalLeads += !isNaN(campaignActualsData[key][month]?.lead_actual) ? campaignActualsData[key][month]?.lead_actual : 0
      })
    })
    setleadsActual(totalLeads)
  },[campaignActualsData, BudgetProfile])

  const budgetTotal = filteredBudgetAmountRemaining + plannedBudgetImpact;
  const transformedBudgetTotal = numberWithCommas(budgetTotal.toFixed(0));

  return (
    <>
    <table className="budget_analysis_table">
      <tr>
        <th></th>
        <th>Goal</th>
        <th>Planned</th>
        <th>Closed</th>
      </tr>
      <tr>
        <td>Leads</td>
        <td>{props.TotalLeadsGoal ? nFormatter(totalLeadsGoal) : "0"}</td>
        <td>{nFormatter(isNaN(plannedLeadImpact) ? 0 : plannedLeadImpact)}</td>
        <td>{!isFinite(leadsActual) ? 0 : `${numberWithCommas(leadsActual)}`}</td>
      </tr>
      <tr>
        <td>Revenue</td>
        <td>{!isFinite(totalRevenuegoal) ? 0: `$${nFormatter(Math.floor(totalRevenuegoal))}`}</td>
        <td>{!isFinite(actualRevenuQ) ? 0 : `$${nFormatter(Math.floor(actualRevenuQ))}`}</td>
        <td>{numberWithCommas(actualLeadsQ)}</td>
        
      </tr>
    </table>
      
    </>
  );
};

export default BudgetAnalysis;
