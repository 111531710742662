// import external modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import FullPageLayout from 'containers/FullPageLayout';
import { message } from 'antd';

class AccountLayoutRoute extends Component {
  componentDidMount() {
    console.log('Mounting AccountRoute')
    if (!this.props.token) {
      message.error('Please login');
    }
  }
  render() {
    const { token, groups, render, ...rest } = this.props;
    const prevUrl = window.location.pathname;

    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {!token && <Redirect from={matchProps.path} to={`/signin?redirect_url=${prevUrl}`} />}
            {token && groups.length > 0 && (
              <Redirect from={matchProps.path} to="/" />
            )}
            {token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </Fragment>
        )}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
  };
};

export default connect(mapStateToProps)(AccountLayoutRoute);
