/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Row , Tabs, Typography} from 'antd'
import React, { useState } from 'react'
import SdrCapacityCalculator from './SdrCapacityCalculator';
import './sdr.css'
import SdrCompensationModal from './SdrCompensationModal';
import SdrMeetingAndOpportunityTracker from './SdrMeetingAndOpportunityTracker';
import { useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Prompt, useHistory } from 'react-router-dom';
const { TabPane } = Tabs;


export default function SDR() {
  const {
    isLoading
  } = useSelector((state) => state.sdrReducer);
  const {groups:account, userPermission, viewType } = useSelector(state => state.loginReducer)


  // Navigation Prevention
  const [HasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isShowModal, setisShowModal] = useState(false);
  const [nextLocation, setnextLocation] = useState(null);
  const [confirmedNavigation, setconfirmedNavigation] = useState(false);

  const [NavigationMessages, setNavigationMessages] = useState("")

  const [nextTab, setnextTab] = useState("")

  const history = useHistory()

  const showModal = (nextLocation) => {
    setisShowModal(true);
    setnextLocation(nextLocation);
    setconfirmedNavigation(true);
  };

  const onLeave = () => {
    setisShowModal(false);
    if(currentTab !== nextTab){
      setcurrentTab(nextTab)
      setHasUnsavedChanges(false)
    }else{
      history.push(nextLocation);
    }

  };

  const onStay = () => {
    setisShowModal(false);
    setconfirmedNavigation(false);
    document.getElementById("salesAndMarking").click();
  };

  const preventNavigation = (nextLocation) => {
    let location = history.location

    if (location.pathname === nextLocation.pathname) {
      return false;
    }
    else{
      setnextTab(currentTab)

    }

    if (confirmedNavigation) {
      return true;
    }

    showModal(nextLocation.pathname);
    return false;
  };

  const changeTab = (e) => {
    setnextTab(e)
    if(HasUnsavedChanges){
      setisShowModal(true)
    }
    else{
      setcurrentTab(e)
    }
  }

  const [currentTab, setcurrentTab] = useState("1")

  const [showSkeleton, setShowSkeleton] = useState(true)


  return (
    <>
    
    <Prompt message={preventNavigation} when={HasUnsavedChanges} />
      <Modal
        className="preventNavModal"
        visible={isShowModal}
        closable={false}
        footer={[
          <Button key="back" onClick={onLeave} style={{ 
            borderRadius: 7,
            height: 40 }}>
            Leave Anyway
          </Button>,
          <Button key="stay" type="primary" onClick={onStay}>
            Stay
          </Button>,
        ]}
      >
        <Row>
          <Col span={2}>
            <Typography.Title level={4}>
              <QuestionCircleOutlined style={{ color: "#faad14" }} />
            </Typography.Title>
          </Col>
          <Col span={22}>
            <Typography.Title level={5}>
              Are you sure you want to leave?
            </Typography.Title>
            <Typography.Text type="secondary">
              
            {NavigationMessages?.length > 0 ?  NavigationMessages : "You have unsaved changes on this page. Leaving this page without saving may result in your changes being lost."}
            </Typography.Text>
          </Col>
        </Row>
      </Modal>


        {/* <Row
        className="aboutPage"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
          background: "#fff",
          margin: "0 0 15px 0px",
          width: "100%",
          borderRadius: 7,
          alignContent: "center",
        }}
      >

        <Col
          className="details"
          style={{
            padding: "0px 0px 0px 30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          span={15}
          lg={15}
          md={15}
          xs={24}
          sm={24}
        >
          <Typography.Text className="title" style={{ fontSize: 29 }}>
            <strong>SDR Methodology</strong>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 15 }}>
          Whether you call them SDRs or BDRs, one of the most important aspects of a well-run demand gen program is to make sure you have enough resources to help generate qualified opportunities.
          </Typography.Text> 
          <Button style={{borderRadius:7,}} className="take-a-tour-btn non-print" onClick={()=>setIsTourOpen(true)}>Take a tour</Button>

        </Col>

        <Col span={9} lg={9} md={9} xs={24} sm={24}>
          <Row justify="end">
            <img src={sdrImg} alt="" />
          </Row>
        </Col>
      </Row> */}

      <Row
        className="sdr"
        style={{
          marginBottom: 8,
          background: "#fff",
          width: "100%",
          borderRadius: 7,
        }}
      >
          <Tabs activeKey={currentTab} centered style={{ width: "100%" }} animated={false} onChange={changeTab}>
          {((userPermission?.['SDRMethodology.CapacityCalculator'] && userPermission?.['SDRMethodology.CapacityCalculator'] !== 'hidden' ) || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && 

            <TabPane tab={<span data-tour = "tour__capacityCalculator"><strong>SDR Capacity Calculator</strong></span>} key="1" >
                <SdrCapacityCalculator showSkeleton = {showSkeleton} skeletonFlag = {setShowSkeleton} HasUnsavedChanges={HasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNavigationMessages={setNavigationMessages} currentTab={currentTab}/>
            </TabPane> }
            {((userPermission?.['SDRMethodology.Compensation'] && userPermission?.['SDRMethodology.Compensation'] !== 'hidden' ) || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && 

            <TabPane tab={<span data-tour = "tour__compensationModel"><strong>SDR Compensation Model</strong></span>} key="2" currentTab={currentTab} >
                <SdrCompensationModal  isLoading={isLoading} HasUnsavedChanges={HasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} setNavigationMessages={setNavigationMessages} currentTab={currentTab}/>
            </TabPane> }
            
            {((userPermission?.['SDRMethodology.Tracker'] && userPermission?.['SDRMethodology.Tracker'] !== 'hidden' ) || (account?.[2] === "admin" && (viewType !== "companyadmin" && viewType !== "companycontributor") )) && 

            <TabPane tab={<span data-tour = "tour__meetingTracker"><strong>SDR Meeting & Opportunity Tracker</strong></span>} key="3" currentTab={currentTab} >
                <SdrMeetingAndOpportunityTracker showSkeleton = {showSkeleton} skeletonFlag = {setShowSkeleton} />
            </TabPane> }
            
          </Tabs>
      </Row>
    </>
  )
}
