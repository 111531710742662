const  dimensionParameters = [
    { key: "web_new_users", title: "New Users" },
    { key: "web_sessions", title: "Web Session" },
    { key: "web_pageviews", title: "Page Views" },
    { key: "web_hits", title: "Hits" },
    { key: "web_bounces", title: "Bounces" },
    { key: "web_users", title: "Users" },
    { key: "web_avg_session_duration", title: "Average Session Duration" },
    { key: "web_referral_links", title: "Referral" },
  ];

export default dimensionParameters;