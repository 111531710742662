import { Empty } from "antd";
import React from "react";

const EmptyChart = () => {
  return (
    <>
      <Empty
        style={{
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      />
    </>
  );
};

export default EmptyChart;
