import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Input, DatePicker, Drawer, Select,Tooltip, Checkbox } from "antd";
import { createBudget, updateBudget } from "store/actions/budgetAction";
import moment from "moment";
import RevenueTextField from "commons/RevenueTextField";
import { InfoCircleOutlined } from "@ant-design/icons";

const CreateBudgetProfile = (props) => {
  const {fiscalYearList, isEdit, budgetData} = props
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.loginReducer.groups);

  const [baselineMetricsFortheYear, setbaselineMetricsFortheYear] = useState([])
  const [selectedBaselineMetrics, setselectedBaselineMetrics] = useState(
    isEdit && budgetData ? 
    fiscalYearList?.filter(item => item?.year === budgetData?.year)[0]?.baselineMetrics?.items?.map(item => item).filter(item => budgetData?.BaselineMetricID?.includes(item.id)).map(item => item.id)
    : []
  )
  const [allbaselinechecked, setallbaselinechecked] = useState(false)
 
  const handleOk = (value) => {
    if (isEdit) {
      value.id = budgetData?.id
      value.active = budgetData?.active
      dispatch(updateBudget(value)).then(res => {
        setcreateBudgetLoader(false);
        props.handleCancel();
        form.resetFields();
      }).catch(err => {
        setcreateBudgetLoader(false);
        props.handleCancel();
        form.resetFields();
      })
      
    } else {
      value.active = false
      let response = dispatch(createBudget(value));
      response.then(data => {
    setcreateBudgetLoader(false);
    props.handleCancel();
    form.resetFields();
        
        props.setNewCreatedBudget(data?.createBudget?.id)}).catch(error => {console.log("error :", error);
    setcreateBudgetLoader(false)
    props.handleCancel();
    form.resetFields();


  })
    }
  };

  const disabledDate = (current) => {
    return current && current <= moment().subtract(1, "years").endOf("year");
  };

  const [createBudgetLoader, setcreateBudgetLoader] = useState(false)
  const onSubmit = () => {
    let name = form.getFieldsValue(["name"])['name']? form.getFieldsValue(["name"])['name'].trim() : ""
    if (name.length === 0) {
      form.setFieldsValue({ name: name })
    }
    form
      .validateFields()
      .then((values) => {
        setcreateBudgetLoader(true)
          let perQuarterBudget = values["budget"] / 4;
          const formatedValues = {
            ...values,
            year: parseInt(values["year"].format("YYYY")),
            account: account[0],
          };
          let quarterlydiffrence = parseInt(perQuarterBudget) * 4
          if (quarterlydiffrence !== perQuarterBudget) {
            let Budget = {
              budget_q1 : parseInt(perQuarterBudget),
              budget_q2 : parseInt(perQuarterBudget),
              budget_q3 : parseInt(perQuarterBudget),
              budget_q4 : parseInt(perQuarterBudget)
            }
            if (values["budget"] - parseInt(quarterlydiffrence) === 1) {
                Budget.budget_q4 += 1 
            }
            if (values["budget"] - parseInt(quarterlydiffrence) === 2) {
                Budget.budget_q4 += 1
                Budget.budget_q3 += 1
            }
            if (values["budget"] - parseInt(quarterlydiffrence) === 3) {
                Budget.budget_q4 += 1
                Budget.budget_q3 += 1
                Budget.budget_q2 += 1
            }
            formatedValues.budget_allocation = JSON.stringify(Budget)
          }
          if (selectedBaselineMetrics.length === 0) {
            formatedValues['BaselineMetricID'] = []
          }
          handleOk(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const BudgetYearChanged = (year) => {
    form.setFieldsValue({BaselineMetricID : []})
    setselectedBaselineMetrics([])
    setallbaselinechecked(false)
    
    let baselineMetrics = []
    let fiscalyear = fiscalYearList?.filter(item => item?.year === year)
    if (fiscalyear?.length > 0) {
      fiscalyear[0]?.baselineMetrics?.items?.map(item => baselineMetrics.push(item))
      setbaselineMetricsFortheYear(baselineMetrics)
      form.setFieldsValue({ "BaselineMetricID": baselineMetrics.map(item => item.id) })  
      setallbaselinechecked(true)
      setselectedBaselineMetrics(baselineMetricsFortheYear?.map(item => item.id))
    }
    else {
      setbaselineMetricsFortheYear([])
    }

  }

  const setAllBaselineMetrics = (e) => {
    if (!allbaselinechecked) {
      form.setFieldsValue({ "BaselineMetricID": baselineMetricsFortheYear.map(item => item.id) })   
      setselectedBaselineMetrics(baselineMetricsFortheYear.map(item => item.id))   
    }
    else {
      form.setFieldsValue({"BaselineMetricID" : []})
    }
    setallbaselinechecked(!allbaselinechecked)
  }
  
  useEffect(() => {
    if (isEdit) {
      BudgetYearChanged(budgetData?.year)
      let budgetStatus = budgetData?.active?"Active":"Sandbox"
      setselectedBaselineMetrics(baselineMetricsFortheYear?.map(item => item.id))
      form.setFieldsValue({ name: budgetData?.name, budget: budgetData?.budget, year: moment(budgetData?.year, "yearFormat"), BaselineMetricID: selectedBaselineMetrics, active:budgetStatus })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (isEdit) {
      let baselineMetricsYear = []
      let fiscalyear = fiscalYearList?.filter(item => item?.year === budgetData?.year)
      if (fiscalyear?.length > 0) {
        fiscalyear[0]?.baselineMetrics?.items?.map(item => baselineMetricsYear.push(item))
      }

      let selectedSegment = fiscalYearList?.filter(item => item?.year === budgetData?.year)[0]?.baselineMetrics?.items?.map(item => item).filter(item => budgetData?.BaselineMetricID?.includes(item.id)).map(item => item.id)

      if(selectedSegment?.length > 0 && (selectedSegment?.length === baselineMetricsYear?.length)) {
        setallbaselinechecked(true)
      } else {
        setallbaselinechecked(false)  
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <Drawer
        title={<strong>{isEdit ? "Edit Budget Profile" :"Create Budget Profile"}</strong>}
        width={416}
        onClose={props.handleCancel}
        className="CreateBudgetProfile"
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label={<strong>Budget Profile Name</strong>}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
            extra="A custom label to help you refer to your budget. You can change this later"
          >
            <Input id="name" key="name" name="name" placeholder="Budget Name" autoFocus />
          </Form.Item>
          <Form.Item
            name="year"
            label={<strong>Calendar Year</strong>}
            extra=" This budget profile's calendar year."
            rules={[
              {
                required: true,
                type: "object",
                message: "This field can not be empty!",
              },
            ]}
          >
            <DatePicker
              getPopupContainer={trigger => trigger.parentElement}
              style={{ width: "100%" }}
              picker="year"
              disabledDate={disabledDate}
              onChange={(e) => BudgetYearChanged(moment(e).year())}
            />
          </Form.Item>

          <Form.Item
            name="budget"
            label={<strong>Total Marketing Programs Budget</strong>}
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
              {
                pattern: /^[+-]?\d+(\.\d+)?$/,
                message: "Value should contain just number",
              },
            ]}
            extra="This may include budget for content creation and software. It should not include marketing headcount."
          >
            <RevenueTextField
              placeholder="Total Budget"
              dollercirclestyle={{
                position: "absolute",
                left: 9,
              }}
            />
          </Form.Item>

          <div className="select-BaselineMetricID">
          <Form.Item
          name="BaselineMetricID"
          label={<span>
            <strong>Select Segment</strong>
            <Tooltip title={`Select Segment for year ${ form.getFieldValue("year") ? moment(form.getFieldValue("year")).year() : ""} `} placement="left">
              <InfoCircleOutlined
                style={{ paddingLeft:10 }}
                className="font-color-budget"
              />
            </Tooltip>
            {baselineMetricsFortheYear.length ? <Checkbox style={{ float: "right" }} checked={allbaselinechecked} onChange={setAllBaselineMetrics}>All</Checkbox> : ""}
          </span>}>
            
            <Select
              disabled={!baselineMetricsFortheYear?.length}
                mode="multiple"
                key="BaselineMetricID"
                placeholder="Select Segment"
              allowClear
                value={selectedBaselineMetrics}
                defaultValue={selectedBaselineMetrics}
              onChange={e => {
                setselectedBaselineMetrics(e)
                if (e.length === baselineMetricsFortheYear.length) {
                  setallbaselinechecked(true)
                } else {
                  setallbaselinechecked(false)
                }
              }}
            >

              {
                baselineMetricsFortheYear.map((item) => (<Select.Option value={item?.id} >{ item?.name }</Select.Option>))
              }

            </Select>
            

          </Form.Item>
          </div>

          <Form.Item
            name="active"
            label={<strong>Status</strong>}
            initialValue="Sandbox"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },              
            ]}
            extra="All new budgets are created in sandbox mode. You can set any budget to active from the Budget Planning tab."
          >
            <Input
              value="Sandbox"
              disabled
              id="active"
              key="active"
              name="active"
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
              zIndex:111,
              display:'flex',
               alignItems:'center'
            }}
          >
            <Button
              className="secondaryButton"
              onClick={() => { props.handleCancel(); form.resetFields(); setallbaselinechecked(false)}}
              style={{ marginRight: 8,width: "48%" }}
              disabled={createBudgetLoader}
              
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              type="primary"
              loading={createBudgetLoader}
              style={{ width: "48%" }}
              className="primaryButton"
            >
              Save
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateBudgetProfile;
