import Icon from "@ant-design/icons";

const PenSVG = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12.3127 0.803228C13.3827 -0.267743 15.1248 -0.267743 16.1956 0.803228C16.7141 1.32152 16.9997 2.01136 16.9997 2.74505C16.9997 3.47875 16.7141 4.16859 16.1956 4.68588L14.6755 6.20615L10.7927 2.3233L12.3127 0.803228ZM0.78722 12.4591C0.809143 12.3537 0.860831 12.256 0.937659 12.1792L9.62932 3.48871L13.5122 7.37135L4.82171 16.0618C4.74589 16.1388 4.64815 16.1903 4.54055 16.2124L0.657698 16.989C0.621496 16.9966 0.585093 16.9998 0.550098 16.9998C0.406095 16.9998 0.265511 16.9439 0.161129 16.8395C0.0316073 16.7088 -0.0255111 16.5232 0.0106907 16.3432L0.78722 12.4591Z" fill="#959595"/>
</svg>
);


export const PenIcon = () => <Icon component={PenSVG} />;
