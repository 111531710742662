import { API } from "aws-amplify";
import { ExploricApi } from "utility/Api"
import { DefaultMapping, DefaultMappingDefinition, DefaultMappingHowItComesIn, DefaultMappingThingsToKeepInMind, defaultStageOrder } from "utility/Constants";
import { SET_SALES_AND_MARKETING_STAGES } from ".";
import { setLoader } from "./reportAction";



export const setSalesAndMarketingStages = (data) => {
    return {
        type: SET_SALES_AND_MARKETING_STAGES,
        payload: data,
    };
}


export const getSalesAndMarketingStages = (account) => async (dispatch,getState) => {
    dispatch(setLoader(true))
    let nextToken = null;
        let list = [];
        let isError = false
        do {
            let res = await ExploricApi.getSalesAndMarketingStagesApi(
                {account: {eq: account?.[0]}},
                nextToken
            // eslint-disable-next-line no-loop-func
            ).catch((e) => {
                console.log("Error:", e);
                dispatch(setLoader(false));
                isError = true
                return 
            });
            nextToken = res?.data?.listSalesAndMarketingAlignments?.nextToken;
            // eslint-disable-next-line array-callback-return
            res?.data?.listSalesAndMarketingAlignments?.items?.map((item) => {
                list.push(item);
            });

            if (!nextToken) {
                dispatch(setLoader(false));
                break;
            }
        } while (nextToken);
        await dispatch(setSalesAndMarketingStages(list))
        if(list.length === 0 && !isError){
            let defaultSalesAndMarketingInputData = Object.keys(DefaultMapping).map(key => ({
                    account:account[0],
                    name : key,
                    disabled:false,
                    isDefault:true,
                    isLast:key === 'Closed Won',
                    showPipelineCoverage: key === "Opportunity or Deal",
                    order:defaultStageOrder[key],
                    howitcomes:DefaultMappingHowItComesIn[key],
                    thingstokeepinmind:DefaultMappingThingsToKeepInMind[key],
                    definition: DefaultMappingDefinition[key],
                    stageType : "DEFAULT",
                    internalStage : key
                }))
            Promise.all(defaultSalesAndMarketingInputData?.map(item => dispatch(createSalesAndMarketingStage(item)))).then(response => {
                dispatch(getSalesAndMarketingStages(account))
            }).catch(err => {
                console.log("Error", err)
            })
        }
        return list
}

export const createSalesAndMarketingStage = (inputData, getAll = false) => (dispatch,getState) => {
    dispatch(setLoader(true))
    if(!getAll){
        return ExploricApi.createSalesAndMarketingStageApi(inputData).then(async (res) => {
        const account = getState().loginReducer.groups;
            await dispatch(getSalesAndMarketingStages(account))
            return res
        }).catch(err => {
            console.log("Error", err)
        dispatch(setLoader(false))
        })
    }
    else{
        return ExploricApi.createSalesAndMarketingStageApi(inputData).then(async (res) => {
                return res
            }).catch(err => {
                console.log("Error", err)
            dispatch(setLoader(false))
            })
    }
}

export const deleteSalesAndMarketingStage = (inputData ) => (dispatch,getState) => {
    dispatch(setLoader(true))
    return ExploricApi.deleteSalesAndMarketingStageApi(inputData).then(async (res) => {
    const account = getState().loginReducer.groups;
        await dispatch(getSalesAndMarketingStages(account))
        return res
    }).catch(err => {
        console.log("Error", err)
    dispatch(setLoader(false))

    })
}

export const updateSalesAndMarketingStage = (inputData, getAll = true) => (dispatch,getState) => {
    dispatch(setLoader(true))
    const account = getState().loginReducer.groups;
    if(!getAll)
    {
        return ExploricApi.updateSalesAndMarketingStageApi(inputData).then(async (res) => {
            await dispatch(getSalesAndMarketingStages(account))
            return res;
        }).catch(err => {
            console.log("Error", err)
        dispatch(setLoader(false))
        })
    }
    else{
        return ExploricApi.updateSalesAndMarketingStageApi(inputData).then(async (res) => {
            return res;
        }).catch(err => {
            console.log("Error", err)
        dispatch(setLoader(false))
        })
    }
}

export const getDefaultMappingStages = (Hubspot_Id) => () => {  
    return API.get("backendRest", `/mapping/getDefaultStageMappings?hub_id=${Hubspot_Id}`)
}

export const getCustomStageMappings = (inputData) => () => {
    let init = {
        body: inputData,
      };
    return API.post("backendRest", "/mapping/getStageMappings", init)
}

export const saveCustomStageMappings = (inputData) => () => {
    let init = {
        body: inputData,
      };
    return API.post("backendRest", "/mapping/saveStageMappings", init)
}