/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Input, Row, Select } from "antd";
import { API, Auth } from "aws-amplify";
import backgroundImg from "assets/Images/login_signup_background.svg";
import Logo from "commons/Logo";
import { useHistory } from "react-router";
import {message} from "antd";
import { CountryList } from "utility/Constants";

function CreatePassword(props) {
  const [msg, setmsg] = useState({ text:"", type:"success"});
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(props.location?.state ? props.location?.state?.userAttributes?.email : "");
  const history = useHistory();

  useEffect(() => {
    if (props.location?.state) {
      let email = props.location?.state?.userAttributes?.email
      if (email) {
        setemail(email)
      }
      else {
        history.push("/")
      }
    } else {
      history.push("/")

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const handleUpdateProfile = async (value) =>{
    let init = {
        body: {
            ...value
        },
      };

    API.post('exploricsREST', `/user/update`, init).then(data => {

    }).catch(error => {
        console.log(error);
    })    }

    const confirmChangePassoword =  (values) => {
      setloading(true)
        let oldPassword = props.location?.state?.oldPassword
      if (oldPassword) {
        let newPassword = form.getFieldValue("password")
        Auth.signIn(email, oldPassword).then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            Auth.completeNewPassword(user, newPassword, [])
              .then(async (user) => {
                    delete values.password
                    await handleUpdateProfile(values)
                  message.success("Login with new password.")
                  history.push("/")
                 }).catch(err => {console.log(err)
                setmsg({ text:err, type:"error"})
                });
      }})
        }
    }
  return (
    <>
      <Row justify="center" align="middle">
        <Col xs={0} md={0} lg={6} style={{ height: "100%" }}>
          <img
            src={backgroundImg}
            alt="background"
            style={{ height: "100vh", minHeight: 580 }}
          />
        </Col>
        <Col xs={24} md={24} lg={18} style={{ transform: "scale(0.88)" }}>
          <Row
            justify="center"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100vh",
              minHeight:580
            }}
          >
                <Logo />

            <Card className="card-container" style={{ border: "none", width: "45%", padding: "0px", height:"80vh"}}>

              {msg?.text && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type={msg?.type}
                  showIcon={false}
                  message={msg.text}
                  banner
                />
              )}
              <Form
                labelCol={{ xs: 24 }}
                requiredMark={false}
                layout="vertical"
                name="normal_reset"
                className="reset-form"
                onFinish={(e) => confirmChangePassoword(e)}
                autoComplete="off"
                form={form}
              >
                <Row style={{display:"flex", justifyContent:"space-between"}}>
                  <Col span={12}>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>First Name</strong>
                    </small>
                  }
                  name="given_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input
                    id="firstName"
                    key="firstName"
                    name="firstName"
                    placeholder="Your first name"
                    disabled={loading}

                  />
                </Form.Item>
                </Col>
                <Col span={11}>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Last Name</strong>
                    </small>
                  }
                  name="family_name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input
                    id="lastName"
                    key="lastName"
                    name="lastName"
                    placeholder="Your last name"
                    disabled={loading}

                  />
                </Form.Item>
                </Col>
                </Row>

                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Email</strong>
                    </small>
                  }
                  labelCol={{ span: 24 }}
                  initialValue={email}
                  name="email"
                >
                  <Input
                    autoFocus
                    value={email}
                    id="userName"
                    key="userName"
                    name="userName"
                    placeholder="Email"
                    disabled
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Country</strong>

                    </small>
                  }
                  name="custom:country"

                  rules={[
                    { required: true, message: "Please Select Your Country" },
                  ]}
                >
                  <Select placeholder="Select country" showSearch size="large" style={{height:43}} >
                  {CountryList && CountryList.map(country => {
                    return <Select.Option value={country.name} key = {country.code}>{country.name}</Select.Option>
                  })}
                  </Select>
                </Form.Item>

                <Row style={{display:"flex", justifyContent:"space-between"}}>
                  <Col span={12}>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Job Role</strong>

                    </small>
                  }
                  name="custom:job_function"


                  rules={[
                    { required: true, message: "Enter your Job role" },
                  ]}
                >
                  <Select placeholder="Select your job role" showSearch size="large" style={{height:43}}>
                  {['Marketing','Marketing Operations','Demand Generation/Growth','Revenue Operations','Sales','Sales Operations','Consulting','Customer Success','Finance','IT','Job Seeker/Student','Other'].map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                </Form.Item>
                </Col>

                <Col span={11}>
                <Form.Item
                  label={
                    <small>
                      {" "}
                      <strong style={{
                        fontSize: "18px"
                      }}>Job Level</strong>

                    </small>
                  }
                  name="custom:job_level"

                  rules={[
                    { required: true, message: "Enter your Job Level" },
                  ]}
                >
                  <Select placeholder="Select your job Level" showSearch size="large" style={{height:43}}>
                  {['Individual Contributor','Manager','Director','VP','C-Level'].map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                </Form.Item>
                </Col>
                </Row>
                <Form.Item
                  labelCol={{ span: 24 }}
                  style={{ fontSize: 18 }}

                  label={
                    <small>
                      <strong style={{ fontSize: 18 }}>Create Password</strong>
                    </small>
                  }
                  name="password"
                  rules={[
                    { required: true, message: "Please Enter Password" },
                  ]}
                >
                  <Input.Password
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    disabled={loading}
                    tabIndex={2}
                    autocomplete="new-password"
                    size="middle"
                  />
                </Form.Item>
                <Form.Item>
                      <Button
                        style={{fontSize:18}}
                        type="primary"
                        size="small"
                        htmlType="submit"
                        block
                        loading={loading}
                        tabIndex={5}
                      >
                        Submit
                      </Button>
                </Form.Item>
              </Form>
            <div>
              <p
                style={{
                  color: "#646C79",
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  userSelect: "none",
                  marginBottom: "0px",
                }}
              >
                Version{" "}
                {process.env.REACT_APP_VERSION
                  ? process.env.REACT_APP_VERSION
                  : "0.1.0"}
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#646C79",
                  textAlign: "center",
                }}
              >
                &copy; {new Date().getFullYear()} XFactor.io copyright all
                rights reserved.
              </p>
            </div>
            </Card>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CreatePassword;
