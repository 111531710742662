import axios from 'axios';

const interceptor = axios.create({
  baseURL: `${process.env.REACT_APP_RESTAPI_URL}`,
});

interceptor.interceptors.response.use(
  async res => {
    return res;
  },
  async err => {
    console.log('Api error: ' , { err });
    throw err;
  }
)

export const getActulsForCampaigns = async (year, profile_id, campaign, AccountID, start_date, end_date) => {
    let path = `/analytics/salesmarketing/stagecontacts?year=${year}&profile_id=${profile_id}&campaign_id=${campaign}&account=${AccountID}&start_date=${start_date}&end_date=${end_date}`
    return await interceptor.get(path);
    };

export const getActualSpends = async (year, camapignID) => {
  let path = `/mappings/getActualSpends/${camapignID}?year=${year}`
  return await interceptor.get(path)
}

export const uploadFile = async (file, url, fileMimeType) => {

  var config = {
    method: 'put',
    url: url,
    headers: { 
      'x-amz-acl': 'public-read', 
      'Content-Type': fileMimeType
    },
    data : file
  };
  
  axios(config)
  .then(function (response) {
  })
  .catch(function (error) {
    console.log(error);
  });


}