/* eslint-disable react-hooks/exhaustive-deps */

import { Col, Row, Skeleton} from 'antd'
import React from 'react'

export default function SdrMeetingAndOpportunityTrackerSkeleton() {
  return (
    
        <Row
            className="sdrCompensation"
            justify="center"
            style={{ padding: "10px 10px 0 10px" }}
        >
            <Col span={5} lg={5} md={5} sm={24} xs={24} className='text_label' style={{color:"#00AEC5", fontWeight:"bold"}}>
            <Skeleton.Input
                    active
                    style={{ width: "10vw", marginBottom: 10 }}
                    size="large"
                  />
            </Col>
            <Col span={23} lg={23} md={23} sm={24} xs={24}>
            <Skeleton
                    active
                    style={{ marginBottom: 30 }}
                  />
            <Skeleton
                    active
                    style={{ marginBottom: 30 }}
                  />
            <Skeleton
                    active
                    style={{ marginBottom: 30 }}
                  />
                 <Skeleton.Button active shape="round" size="large"  style={{ marginBottom: 30 }}/>
            </Col>
        </Row>
 
  )
}