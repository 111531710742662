import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import revenueReducer from './revenueReducer';
import budgetReducer from './budgetReducer';
import coachingReducer from './coachingReducer';
import reportReducer from './reportReducer';
import goToMarketReducer from './goToMarketReducer';
import salesAndMarketingReducer from './salesAndMaketingReducer';
import sdrReducer from './sdrReducer';

const appReducer = combineReducers({
  loginReducer,
  revenueReducer,
  budgetReducer,
  coachingReducer,
  reportReducer,
  goToMarketReducer,
  salesAndMarketingReducer,
  sdrReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = { };
  }
  return appReducer(state, action);
};

export default rootReducer;
