import SimpleBarChart from "./SimpleBarChart";
import StackBarChart from "./StackBarChart";
import StackGroupedChart from "./StackGroupedChart";

const RevenueForecasting1 = (props) => {
  return (
    <>
        {/* <SimpleBarChart /> */}
        {/* <StackBarChart /> */}
        <StackGroupedChart />
    </>
  );
};

export default RevenueForecasting1;
