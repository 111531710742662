import * as actions from "../actions/index";

const initialState = {
  BudgetProfileList: [],
  BudgetProfile: {},
  LatestProfile: "",
  ProspectData: "",
  ChannelList: [],
  CampaignList: [],
  BudgetProfileListDetails:[],
  ChannelCampaignPlannedTotal: {},
  createdBudgetData: [],
  createdCampaignData: {},
  isLoading: false,
  totalSpend: "",
  viewCampaignPlanningTotal: false,
  createdChannelData:"",
  serverError:"",
  BudgetInfo: "",
  CRMOriginalSource:[],
  campaignActualsData:{},
  campaignActualsAmountData:[],
  CRMOriginalSourceDD1:[],
  CRMOriginalSourceDD2:[],
  Territorys:[]
  
};

const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_BUDGET_LIST_DATA:
      let budgetList = [];
      action.payload.forEach((item) => {
        budgetList.push({ value: item.id, title: item.name, year: item.year });
      });
      return {
        ...state,
        BudgetProfileList: [...budgetList],
        BudgetProfileListDetails: action.payload
      };

    case actions.SET_BUDGET_DATA:
      return { ...state, BudgetProfile: action.payload };

    case actions.SET_BUDGET_INFO_DATA:
      return { ...state, BudgetInfo: action.payload };

    case actions.SET_CREATED_CHANNEL_DATA:
      return { ...state, createdChannelData: action.payload };

    case actions.SET_CREATED_BUDGET_DATA:
      return { ...state, createdBudgetData: action.payload };

    case actions.SET_CREATED_CAMPAIGN_DATA:
      return { ...state, createdCampaignData: action.payload };

    case actions.SET_CAMPAIGN_CHANNEL_PLANNED:
      return { ...state, ChannelCampaignPlannedTotal: action.payload };

    case actions.SET_TOTAL_SPEND:
      return { ...state, totalSpend: action.payload };

    case actions.SET_PROSPECT_DATA:
      return { ...state, ProspectData: action.payload };

    case actions.SET_CHANNEL_LIST_DATA:
      return { ...state, ChannelList: action.payload };

    case actions.SET_CAMPAIGN_LIST_DATA:
      return { ...state, CampaignList: action.payload };

    case actions.SHOW_LOADER:
      return { ...state, isLoading: action.payload };
      
    case actions.SERVER_ERROR:
      return { ...state, serverError: action.payload };

    case actions.SET_VIEW_TOTAL:
      return { ...state, viewCampaignPlanningTotal: action.payload };

    case actions.SET_ORIGINAL_CRM_SOURCE:
      return { ...state, CRMOriginalSource: action.payload };

    case actions.SET_ORIGINAL_CRM_SOURCE_DD1:
      return { ...state, CRMOriginalSourceDD1: action.payload };

    case actions.SET_ORIGINAL_CRM_SOURCE_DD2:
      return { ...state, CRMOriginalSourceDD2: action.payload };

    case actions.SET_CAMPAIGN_ACTUALS:
      return { ...state, campaignActualsData: action.payload };

    case actions.SET_CAMPAIGN_ACTUALS_AMOUNT:
      return { ...state, campaignActualsAmountData: action.payload };

    case actions.SET_TERRITORYS_LIST_DATA:
        return { ...state, Territorys: action.payload };  

    default:
      return state;
  }
};

export default budgetReducer;
