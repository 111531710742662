import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Tag, Space, Button, Popconfirm } from "antd";
import CreateCourse from "./CreateCourse";
import { getCourseList, deleteCourse } from "store/actions/coachingAction";
import { Link } from "react-router-dom";
import editIcon from "assets/Images/table_settings.svg";
import deleteIcon from "assets/Images/ic_delete.svg";

const Coaching = (props) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.budgetReducer.isLoading);

  const { CourseList } = useSelector((state) => state.coachingReducer);

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = () => {
    setSelectedCouse();
    setVisible(false);
  };

  useEffect(() => {
    dispatch(getCourseList());
  }, [dispatch]);

  const onDeleteCourse = (courseId) => {
    dispatch(
      deleteCourse(
        CourseList.listCourses.items.find(
          (courseIdVal) => courseIdVal.id === courseId
        )
      )
    );
  };

  const [selectedCourse, setSelectedCouse] = useState();
  const onUpdateCourse = (courseId) => {
    setSelectedCouse(
      CourseList.listCourses.items.find(
        (courseIdVal) => courseIdVal.id === courseId
      )
    );
    showModal();
  };

  const columns = [
    {
      className:"admin-table-header",
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      className:"admin-table-header",
      title: "Category",
      key: "category",
      dataIndex: "category",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag color="green" key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      width: 235,
      //ellipsis: true,
    },

    {
      className:"admin-table-header",
      title: "Short Description",
      dataIndex: "short_description",
      key: "short_description",
      //ellipsis: true,
      width: 450,
    },
    {
      className:"admin-table-header",
      title: "Action",
      width:"10%",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link to="#" onClick={() => onUpdateCourse(record.key)}>
            <img src={editIcon} alt="edit" />
          </Link>
          <Popconfirm
            title="Are you sure you want to delete this course?"
            onConfirm={() => onDeleteCourse(record.key)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ size: "middle", type: "primary" }}
            cancelButtonProps={{ size: "large", type: "secondary" }}
            placement="topRight"
          >
            <Link to="#">
              <img src={deleteIcon} alt="Delete" />
            </Link>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const [courseListData, setCourselistData] = useState([]);
  useEffect(() => {
    const CourseTableData = [];
    CourseList.listCourses?.items.map((courseVal, index) => {
      const categoryTags = [];

      courseVal.categories.items.map((categoryVal) => {
        categoryTags.push(categoryVal.courseCategory.name);
        return true;
      });

      CourseTableData.push({
        key: courseVal.id,
        name: courseVal.name,
        category: categoryTags,
        short_description: courseVal.short_description,
      });
      return true;
    });
    setCourselistData(CourseTableData);
  }, [CourseList]);

  return (
    <>
      {/* {isLoading && <div className="loading">Loading;</div>}{" "} */}
      <div className="table-wrapper">
        <Table
          columns={columns}
          dataSource={courseListData}
          className="course-list-admin general-table coaching-company-table"
          pagination={{
            defaultPageSize: 5,
            pageSize: 10,
            size: "small",
            showTotal: (total) => (
              <Button
                type="primary"
                onClick={showModal}
                style={{marginLeft:'8px'}}
                className="primaryButton"
              >
                Create New
              </Button>
            ),
          }}
        />
      </div>
  
      {courseListData.length <= 0 && <div style={{ paddingTop: 10, paddingLeft: "40%" }}>
        <Button
          type="primary"
          onClick={showModal}
          style={{ height: 30, fontSize: 12 }}
        >
          Create New
        </Button>
      </div>}

      <CreateCourse
        visible={visible}
        handleCancel={handleCancel}
        selectedCourse={selectedCourse}
        isLoading={isLoading}
      />
    </>
  );
};

export default Coaching;
