import React, { useEffect, useState } from 'react';
import logo from 'assets/Images/xio-logo.svg';
import logoshort from 'assets/Images/xio-logo-short.svg';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sdk from 'sdk/Accounts';

const Logo = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [acct, setAcct] = useState(null);
  const { groups } = useSelector((state) => state.loginReducer);

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups?.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      setAcct(account);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAccountDetails();
  }, []);

  return (
    <div className="company_logo" style={{ height: 'auto', margin:"20px 0 0 0",paddingBottom:'20px', borderBottom:'1px solid rgba(208, 213, 221, 1)' }}>
      <img
        src={props.datafromparent?.collapsed ? logoshort : logo}
        className={
          props.datafromparent?.collapsed ? 'logo-short' : 'logo-not-short'
        }
        width={props.datafromparent?.collapsed ? 'auto' : 200}
        height={props.datafromparent?.collapsed ? 20 : 'auto'}
        alt="XFactor.io :: Demand Gen"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (Object.keys(props).length > 0) {
            props.handleChangeKey('home');
          }
          if (history.location.pathname !== '/setup') {
            history.push('/');
          }
        }}
      />
    </div>
  );
};

export default Logo;
