import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Skeleton, Table, Typography } from "antd";
import { numberWithCommas } from "utility/context/CommonMethods";
import Text from "antd/lib/typography/Text";
  
  const GoalToActualCharts = (props) => {
  const {isLoading} = props
  const {
    planningPeriod,
  } = useSelector((state) => state.revenueReducer);

  const summaryTableColumns = [
    {
      title: <h4 style={{ textAlign: "left", marginBottom: 0, color: "#F9FAFB", userSelect: "none" }}><strong>.</strong></h4>,
      className:"revenue-table-head",
      children: [
        {
          title: <Typography.Text className="text_label" style={{ textAlign: "left", marginBottom: 0, fontSize:"15px" }}>Name of Segment</Typography.Text>,
          dataIndex: "name",
          key: "name",
          render: (data, record) => (
            <Row justify="space-between">
              <Col><Typography.Text className="text_label" >{data}</Typography.Text></Col>
            </Row>
          ),
        },
      ], 
    },
    {
      title: <h4 style={{ textAlign: "left", marginBottom: 0, color: "#F9FAFB", padding: 0, userSelect: "none" }}><strong>.</strong></h4>,
      className:"revenue-table-head",
      children: [
        {
          title: <Typography.Text className="text_label"  style={{ textAlign: "left", marginBottom: 0, fontSize:"15px" }}>Goal defined</Typography.Text>,
          dataIndex: "goal",
          key: "goal",
          render: (data) => <Typography.Text className="text_label" style={{ textAlign: "left" }}>${numberWithCommas(Number(data).toFixed(0))}</Typography.Text>,
        }
      ], 
    },
    {
      title : <Typography.Text className="text_label"  style={{marginBottom:0, fontWeight:'700', fontSize:'18px'}}>Marketing sourced</Typography.Text>,
      children: [
        {
          title: <Typography.Text className="text_label"  style={{marginBottom:0, fontSize:"15px"}}>Pct.</Typography.Text>,
          dataIndex: 'marketing_percent',
          key: 'marketing_percent',
          render: (data) => <Typography.Text  className="text_label" style={{textAlign:"left"}}>{data}%</Typography.Text>,
        },
        {
          title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left", fontSize:"15px"}}>Revenue</Typography.Text>,
          dataIndex: 'marketing_revenue',
          key: 'revenue',
          render: (data) => <Typography.Text className="text_label"  style={{textAlign:"left"}}>${numberWithCommas(Number(data).toFixed(0))}</Typography.Text>,
        },
        {
          title: <Typography.Text  className="text_label" style={{marginBottom:0, textAlign:"left", fontSize:"15px"}}>{props.lastStageName}</Typography.Text>,
          dataIndex: 'marketing_deals',
          key: 'marketing_deals',
          render: (data) => <Typography.Text className="text_label"  style={{textAlign:"left"}}>{Number.parseInt(data)}</Typography.Text>,
        },
      ]
    },

    {
      title : <Typography.Text className="text_label"  style={{marginBottom:0, fontWeight:'700', fontSize:'18px'}}>Sales sourced</Typography.Text>,
      children: [
        {
          title: <Typography.Text  className="text_label" style={{marginBottom:0,  textAlign:"left", fontSize:"15px"}}>Pct.</Typography.Text>,
          dataIndex: 'sales_percent',
          key: 'sales_percent',
          render: (data) => <Typography.Text className="text_label"  style={{textAlign:"left"}}>{data}%</Typography.Text>,
        },
        {
          title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left", fontSize:"15px"}}>Revenue</Typography.Text>,
          dataIndex: 'sales_revenue',
          key: 'sales_revenue',
          render: (data) => <Typography.Text className="text_label"  style={{textAlign:"left"}}>${numberWithCommas(Number(data).toFixed(0))}</Typography.Text>,
        },
        {
          title: <Typography.Text className="text_label"  style={{marginBottom:0, textAlign:"left", fontSize:"15px"}}>{props.lastStageName}</Typography.Text>,
          dataIndex: 'sales_deals',
          key: 'sales_deals',
          render: (data) => <Typography.Text className="text_label"  style={{textAlign:"left" ,display: 'flex' , alignItems: 'center' , paddingBottom: 0}}>{Number.parseInt(data)}</Typography.Text>,
        },
      ]
    },
  ];

  const [summaryTableData, setsummaryTableData] = useState([]);
  const GetSortOrder = (prop) => {    
    return (a, b) => {    
        if (a[prop] > b[prop]) {    
            return -1;    
        } else if (a[prop] < b[prop]) {    
            return 1;    
        }    
        return 0;    
    }    
  }
  const [fiscalYear, setfiscalYear] = useState()
  useEffect(() => {
    let currentYearRevenueData = props.fiscalYearList.find(data => data.year === new Date().getFullYear())
    if(currentYearRevenueData)
    setfiscalYear(currentYearRevenueData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.fiscalYearList, planningPeriod])
  
  const HandelTableDataChange = () => {
    let baselineMetrics = fiscalYear?.baselineMetrics
    if (baselineMetrics?.items.length > 0) {
      baselineMetrics.items.sort(GetSortOrder("updatedAt"))
      var tableData = baselineMetrics.items.map((item) => {
        let tableRow = {};
        tableRow["key"] = item?.id;
        tableRow["name"] = item?.name;
        tableRow["goal"] = item?.goal ? item?.goal : 0;
        tableRow['segment_type'] = item?.segment_type;
        tableRow["marketing_revenue"] =
          item?.goal && item?.mrktSourcePercent
            ? ((item?.goal * item?.mrktSourcePercent) / 100).toFixed(0)
            : 0;
        tableRow["marketing_deals"] =
            tableRow["marketing_revenue"] && item?.avgDealSize
              ? (tableRow["marketing_revenue"]/ item?.avgDealSize).toFixed(0)
            : 0;
        
        tableRow["marketing_percent"] = item?.mrktSourcePercent;
        
        tableRow["sales_percent"] = 100 - item?.mrktSourcePercent;
        tableRow["sales_revenue"] =
          item?.goal && tableRow["sales_percent"]
            ? ((item?.goal * tableRow["sales_percent"]) / 100).toFixed(0)
            : 0;
      
            tableRow["sales_deals"] =
            item?.goal && item?.mrktSourcePercent && item?.avgDealSize
              ? (item?.goal / item?.avgDealSize) - tableRow["marketing_deals"]
            : 0;
        let sum = parseInt(tableRow['marketing_revenue']) + parseInt(tableRow['sales_revenue'])
        if (sum !== item?.goal) {
            tableRow['sales_revenue'] -=sum - item?.goal
        }
        return tableRow;
      });
      setsummaryTableData(tableData);
    } else {
    }
  };

  useEffect(() => {
    HandelTableDataChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fiscalYear])

  return (
    <>
      {" "}
      <div className="panel-design" style={{ marginBottom: "20px", background:'#fff', padding:"20px 0" }}>
        <div>
          <Row gutter={[16, 16]} style={{ margin: 0, }}>
            <Col span={8}
              xs={24}
              md={24}
              lg={24}
              style={{ width: "calc(100vw - 318px)" , padding: '20px 15px'}}
            className="revenue-table dashboard-table ">
              {isLoading ? <Skeleton active /> : <Table
            pagination={false}
            size="small"
            dataSource={summaryTableData}
            columns={summaryTableColumns}
            bordered
            borderColor='red'
            className="baselineMetricsTable general-table"
            summary={(pageData) => {
              let totalGoal = 0;
              let totalmrktingGoal = 0;
              let totalsalesGoal = 0;
              pageData.forEach(({ goal, marketing_revenue, sales_revenue }) => {
                totalGoal += goal;
                totalmrktingGoal += Number(marketing_revenue);
                totalsalesGoal += Number(sales_revenue);
              });
              return (
                <>
                  {pageData.length > 0 && (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Typography.Text className="text_label"  style={{textAlign:"left",color:'#7F56D9', fontWeight:500, fontSize:'15px'}}>Total Value</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell >
                        <Typography.Text className="text_label"style={{
                          textAlign: "center", display:"block", paddingBottom:1, paddingTop:5,  }}  >
                          <Text  style={{color:'#EC4A0A', fontWeight:'500', fontSize:'15px'}}>${numberWithCommas(totalGoal)}</Text><br/>
                          {
                            (fiscalYear?.budget - totalGoal) > 0 ?
                              (<small style={{ color: "#F04438" }}>(${numberWithCommas(fiscalYear.budget - totalGoal)})</small>) :
                              
                              (fiscalYear?.budget - totalGoal) < 0 ?
                                (<small style={{ color: "#42D77E" }}>(${numberWithCommas(Math.abs(fiscalYear.budget - totalGoal))})</small>) : ""
                          }
                        </Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={3} className="textCenter">
                        <Typography.Text className="text_label"><Text  style={{color:'#EC4A0A', fontWeight:'500', fontSize:'15px'}}>$ {numberWithCommas(totalmrktingGoal)}</Text></Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell colSpan={3} className="textCenter">
                        <Typography.Text className="text_label" style={{
                          textAlign: "center" }} ><Text style={{color:'#EC4A0A', fontWeight:'500', fontSize:'15px'}}>$ {numberWithCommas(totalsalesGoal)}</Text></Typography.Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                </>
              );
            }}
          />}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default GoalToActualCharts;
