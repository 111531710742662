import React from "react";
import { Modal } from "antd";
import ReactPlayer from "react-player";

const Player = (props) => {
  return (
    <div className = "coaching-video">
      <Modal
        visible={props.isModalVisible}
        onCancel={props.handleCancel}
        width={640}
        footer={null}
      >
        <ReactPlayer
          url= {props.videoUrl}
          width="100%"
          height="calc(100vh - 200px)"
          playing={props.isModalVisible}
          controls={true}
        />
      </Modal>
    </div>
  );
};

export default Player;
