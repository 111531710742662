import { convertReverseArray } from "utility/context/CommonMethods";
import {
  UPDATE_PLOT_DATA_LIST,
  ADD_PLOT_DATA_ITEM,
  REMOVE_PLOT_DATA_ITEM,
  DELETE_STAGE_DATA,
  SET_PLANNING_PERIOD,
  SET_YEAR_LIST,
  UPDATE_METRICS_CALCULATION,
  SET_BASELINE_DATA,
  SET_FUNNEL_CONFIG,
  SET_FUNNEL_STAGES_LIST,
  SET_BASELINE_LIST_DATA,
  SET_FISCAL_YEAR_LIST,
  SET_FISCAL_YEAR,
  SHOW_LOADER,
  SHOW_CHECK_LOADER,
  SET_ACCOUNT_MASTER_STAGES,
  TOTAL_PIPELINE_COVERAGE,
} from "./index";
import { ExploricApi } from "./../../utility/Api";
import { message } from "antd";

export const updatePlotDataList = (updatedArray) => {
  return {
    type: UPDATE_PLOT_DATA_LIST,
    payload: updatedArray,
  };
};
export const setLoader = (data) => {
  return {
    type: SHOW_LOADER,
    payload: data,
  };
};
export const setCheckLoader = (data) => {
  return {
    type: SHOW_CHECK_LOADER,
    payload: data,
  };
};
export const setPipelineCoverage = (data) => {
  return {
    type: TOTAL_PIPELINE_COVERAGE,
    payload: data,
  };
};

export const addPlotDataItem = (text) => {
  return {
    type: ADD_PLOT_DATA_ITEM,
    payload: text,
  };
};

export const removePlotDataItem = (id) => {
  return {
    type: REMOVE_PLOT_DATA_ITEM,
    payload: id,
  };
};

export const setBaseLineData = (data) => {
  return {
    type: SET_BASELINE_DATA,
    payload: data,
  };
};

export const setBaselineListData = (data) => {
  return {
    type: SET_BASELINE_LIST_DATA,
    payload: data,
  };
};

const updateCalculation = (data) => {
  return {
    type: UPDATE_METRICS_CALCULATION,
    payload: data,
  };
};

const setFunnelConfig = (data) => {
  return {
    type: SET_FUNNEL_CONFIG,
    payload: data,
  };
};

export const setFunnelStagesList = (data) => {
  return {
    type: SET_FUNNEL_STAGES_LIST,
    payload: data,
  };
};

export const deleteStageData = (data) => {
  return {
    type: DELETE_STAGE_DATA,
    payload: data,
  };
};

export const setPlanningPeriod = (data) => {
  return {
    type: SET_PLANNING_PERIOD,
    payload: data,
  };
};

export const setYearList = (data) => {
  return {
    type: SET_YEAR_LIST,
    payload: data,
  };
};

export const setFiscalYearList = (data) => {
  return {
    type: SET_FISCAL_YEAR_LIST,
    payload: data,
  };
};

export const setFiscalYear = (data) => {
  return {
    type: SET_FISCAL_YEAR,
    payload: data,
  };
};

export const setAccountMasterStages = (data) => {
  return {
    type: SET_ACCOUNT_MASTER_STAGES,
    payload: data,
  };
};

export const getFiscalYearsList = (filter) => (dispatch, getState) => {
  dispatch(setLoader(true));
  const account = getState().loginReducer.groups;
  return ExploricApi.getFiscalYearsList({ account: { eq: account[0] } })
    .then((response) => {
      const { listFiscalYears } = response.data;
      dispatch(setFiscalYearList(listFiscalYears.items));
      dispatch(setLoader(false));
      return listFiscalYears;
    })
    .catch((e) => {
      console.log("error", e);
      dispatch(setLoader(false));
      return e;
    });
};

export const deleteBaselineMetric = (inputData) => (dispatch, getState) => {
  dispatch(setLoader(true));
  return ExploricApi.deleteBaselineMetricApi(inputData)
    .then((response) => {
      const { fiscalYear } = getState().revenueReducer;
      dispatch(getFiscalYear(fiscalYear.id));
      message.success("Segment Deleted Successfully");
      dispatch(setLoader(false));
      return response.data;
    })
    .catch((e) => {
      console.log("error: ,", e);
      dispatch(setLoader(false));
      message.error("Something went wrong");
    });
};

export const createFiscalYear = (inputData) => (dispatch, getState) => {
  dispatch(setLoader(true));
  return ExploricApi.createFiscalYearApi(inputData)
    .then((response) => {
      dispatch(getFiscalYearsList());
      dispatch(getFiscalYear(response.data.createFiscalYear.id));
      message.success("Annual goal set successfully");
      dispatch(setLoader(false));
      return response.data;
    })
    .catch((e) => {
      console.log("error :", e);
      message.error("Something went wrong");
      dispatch(setLoader(false));
    });
};

export const updateFiscalYear = (inputData, from) => (dispatch, getState) => {
  ExploricApi.updateFiscalYearApi(inputData)
    .then((response) => {
      dispatch(getFiscalYearsList());
      if(!from === "companyUpdate"){
       message.success("Data updated successfully");
       
      }
      
      return response.data;
    })
    .catch((e) => {
      console.log("error :", e);
      message.error("Something went wrong");
    });
};

export const getFiscalYear = (id, segmentId) => (dispatch, getState) => {
  dispatch(setLoader(true));
  ExploricApi.getFiscalYearApi(id)
    .then((response) => {
      const { getFiscalYear } = response.data;
      console.log("getFiscalYearrr",getFiscalYear);
      dispatch(setFiscalYear(getFiscalYear));
      const { baselineMetrics } = getFiscalYear;
      dispatch(setPlanningPeriod(getFiscalYear.year));
      if (
        baselineMetrics &&
        baselineMetrics.items &&
        baselineMetrics.items.length > 0
      ) {
        baselineMetrics.items.sort(GetSortOrder("updatedAt"));
        if (segmentId) dispatch(getBaselineMetricData(segmentId));
        else dispatch(getBaselineMetricData(baselineMetrics?.items[0].id));
      } else {
        dispatch(setBaseLineData({}));
        getCalculationMetrics();
      }
      dispatch(setLoader(false));
      return getFiscalYear;
    })
    .catch((e) => {
      console.log("error :", e);
      dispatch(setLoader(false));
    });
};

const GetSortOrder = (prop) => {
  return (a, b) => {
    if (a[prop] > b[prop]) {
      return -1;
    } else if (a[prop] < b[prop]) {
      return 1;
    }
    return 0;
  };
};

export const getCalculationMetrics = (
  newRevenueGoal = 0,
  marketingSourced = 1,
  averageDealSize = 0,
  marketingQuarterBudgetAllocation,
  salesQuarterBudgetAllocation,
  marketingQuarterWonLeads,
  salesQuarterWonLeads
) => {
  return (dispatch, getState) => {
    
    let marketingSourcedRevenue;
    if (marketingQuarterBudgetAllocation) {
      const marketingParseData = JSON.parse(marketingQuarterBudgetAllocation);
      marketingSourcedRevenue =
        marketingParseData.revenue_q1 +
        marketingParseData.revenue_q2 +
        marketingParseData.revenue_q3 +
        marketingParseData.revenue_q4;
    } else {
      marketingSourcedRevenue = (
        (newRevenueGoal * marketingSourced) /
        100
      ).toFixed(0);
    }
    let marketingSourcedCount;
    if (marketingQuarterWonLeads) {
      const marketingWonParseData = JSON.parse(marketingQuarterWonLeads);
      marketingSourcedCount =
        marketingWonParseData.closed_q1 +
        marketingWonParseData.closed_q2 +
        marketingWonParseData.closed_q3 +
        marketingWonParseData.closed_q4;
    } else {
      marketingSourcedCount =
        isNaN(marketingSourcedRevenue / averageDealSize) ||
        !isFinite(marketingSourcedRevenue / averageDealSize)
          ? 0
          : (marketingSourcedRevenue / averageDealSize).toFixed(0);
    }
    let salesSourced = 100 - marketingSourced;
    let salesSourcedRevenue;
    if (salesQuarterBudgetAllocation) {
      const salesParseData = JSON.parse(salesQuarterBudgetAllocation);
      salesSourcedRevenue =
        salesParseData.revenue_q1 +
        salesParseData.revenue_q2 +
        salesParseData.revenue_q3 +
        salesParseData.revenue_q4;
    } else {
      salesSourcedRevenue = ((newRevenueGoal * salesSourced) / 100).toFixed(0);
    }
    let saleSourcedCount;
    if (salesQuarterWonLeads) {
      const salesWonParseData = JSON.parse(salesQuarterWonLeads);
      saleSourcedCount =
        salesWonParseData.closed_q1 +
        salesWonParseData.closed_q2 +
        salesWonParseData.closed_q3 +
        salesWonParseData.closed_q4;
    } else {
      saleSourcedCount = (salesSourcedRevenue / averageDealSize).toFixed(0);
    }

    let sum = parseInt(marketingSourcedRevenue) + parseInt(salesSourcedRevenue);
    if (sum !== newRevenueGoal) {
      salesSourcedRevenue -= sum - newRevenueGoal;
    }

    dispatch(
      updateCalculation({
        newRevenueGoal,
        marketingSourced,
        averageDealSize,
        marketingSourcedCount,
        marketingSourcedRevenue,
        salesSourcedRevenue,
        saleSourcedCount,
      })
    );
  };
};

export const updatePlottingData = (plotData) => {
  let newPlotData = convertReverseArray(plotData);
  newPlotData.map((item, index) => {
    if (item.text !== "Won Deals") {
      item.value = Number.parseInt(
        (newPlotData[index - 1].value * 100) /
          Number.parseInt(item.percentageValue)
      );
    }
    return item;
  });
  return convertReverseArray(newPlotData);
};

export const getBaseLineMetricList = (filter) => (dispatch, getState) => {
  
  return ExploricApi.getBaseLineMetricList(filter)
    .then((response) => {
      
      const { listBaselineMetrics } = response.data;
      let newList = [...listBaselineMetrics.items];
      let year = new Date().getFullYear();
      let yearList = [Number(year), Number(year) + 1];
      newList.forEach((item) => {
        if (!yearList.includes(Number(item.year))) {
          yearList.push(Number(item.year));
        }
      });
      dispatch(setYearList(yearList));
      dispatch(setBaselineListData(listBaselineMetrics.items));
      dispatch(getYearlyBaseline());
      return listBaselineMetrics;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getYearlyBaseline = () => (dispatch, getState) => {
  const { planningPeriod, baselineList } = getState().revenueReducer;
  const newList = baselineList
    ? baselineList.filter((item) => Number(item.year) === planningPeriod)
    : [];
  if (newList.length > 0) {
    dispatch(setBaseLineData(newList[newList.length - 1]));
    dispatch(
      setFunnelConfig(newList[newList.length - 1].funnelConfigs.items[0])
    );
    dispatch(
      getCalculationMetrics(
        newList[newList.length - 1].goal,
        newList[newList.length - 1].mrktSourcePercent,
        newList[newList.length - 1].avgDealSize
      )
    );
    dispatch(
      setFunnelStagesList(
        newList[newList.length - 1].funnelConfigs.items[0].stages.items
      )
    );
  } else {
    dispatch(setBaseLineData({}));
    dispatch(setFunnelConfig({}));
    dispatch(setFunnelStagesList([]));
    dispatch(getCalculationMetrics(0, 50, 0));
  }
};

export const getBaselineMetricData = (id) => (dispatch, getState) => {
  dispatch(setLoader(true));

  return ExploricApi.getBaselineMetricData(id)
    .then((response) => {
      
      const { getBaselineMetric } = response.data;
      getBaselineMetric['selectedIds'] = [id];
      dispatch(setBaseLineData(getBaselineMetric));
      dispatch(
        getFunnelConfiguration(getBaselineMetric.funnelConfigs?.items[0]?.id)
      );
      dispatch(
        getCalculationMetrics(
          getBaselineMetric.goal,
          getBaselineMetric.mrktSourcePercent,
          getBaselineMetric.avgDealSize
        )
      );
      dispatch(setLoader(false));
      return response;
    })
    .catch((e) => {
      console.log("error :", e);
      dispatch(setLoader(false));
    });
};

export const getBaselineMetricMultipleData = (data) => (dispatch, getState) => {
  const { marketingSourcedCount } = getState().revenueReducer;
  dispatch(setCheckLoader(true));
  const idFilter = { or: data?.map((id) => ({ id: { eq: id } })) ?? [] };
  

  return ExploricApi.getBaseLineMetricList(idFilter)
    .then((response) => {
      
      const { listBaselineMetrics } = response.data;
      // to get data in same order same like ids in data
      const orderedResult = data?.map((id) =>
        listBaselineMetrics?.items.find((item) => item.id === id)
      );
      
      console.log("orderedResult",orderedResult);
      const combineData = (items) => {
       
        let arr = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        
        let firstId;
        return items.reduce((combined, currentItem, index, array) => {
          for (const key in currentItem) {
            if (currentItem.hasOwnProperty(key)) {
              if (key === "id") {
                // Store the value of "id" from the first response
                
                firstId = currentItem[key];
              } else if (
                key === "funnelConfigs" &&
                currentItem[key] &&
                currentItem[key].items
              ) {
                // Special handling for funnelConfigs and its items array
                combined[key] = combined[key] || { items: [] };
                const itemIds = currentItem[key].items.map((item) => item.id);
                combined[key].items = combined[key].items.concat(itemIds);
              } else if (Array.isArray(currentItem[key])) {
                // Ensure the property is an array
                combined[key] =
                  index === array.length - 1 ? currentItem[key] : combined[key];
              } else if (
                (key === "avgDealSize" || key === "mrktSourcePercent") &&
                typeof currentItem[key] === "number"
              ) {
                combined[key] = (combined[key] || 0) + currentItem[key];
                if (index === array.length - 1) {
                  // Round up the average value
                  combined[key] = Math.ceil(combined[key] / array.length);
                }
              } else if (key === "name") {
                // Accumulate all values for the "name" field in an array
                combined[key] = combined[key] || [];
                combined[key].push(currentItem[key]);
              } else if (key === "budget_allocation") {
                const parseData = JSON.parse(currentItem[key]);
                
                arr.push(parseData);
                let result = {};
                arr.forEach((item) => {
                  for (const key in item) {
                    if (
                      item.hasOwnProperty(key) &&
                      key.startsWith("budget_q")
                    ) {
                      result[key] = (result[key] || 0) + item[key];
                    }
                  }
                });
                
                combined[key] = JSON.stringify(result);
              } else if (key === "mrkt_qtr_budget_allocation") {
                const parseData = JSON.parse(currentItem[key]);
                
                arr1.push(parseData);
                let result = {};
                arr1.forEach((item) => {
                  for (const key in item) {
                    if (
                      item.hasOwnProperty(key) &&
                      key.startsWith("revenue_q")
                    ) {
                      result[key] = (result[key] || 0) + item[key];
                    }
                  }
                });
                
                combined[key] = JSON.stringify(result);
              } else if (key === "mrkt_qtr_won_leads") {
                const parseData = JSON.parse(currentItem[key]);
                
                arr2.push(parseData);
                let result = {};
                arr2.forEach((item) => {
                  for (const key in item) {
                    if (
                      item.hasOwnProperty(key) &&
                      key.startsWith("closed_q")
                    ) {
                      result[key] = (result[key] || 0) + item[key];
                    }
                  }
                });
                
                combined[key] = JSON.stringify(result);
              } else if (key === "sales_qtr_budget_allocation") {
                const parseData = JSON.parse(currentItem[key]);
                
                arr3.push(parseData);
                let result = {};
                arr3.forEach((item) => {
                  for (const key in item) {
                    if (
                      item.hasOwnProperty(key) &&
                      key.startsWith("revenue_q")
                    ) {
                      result[key] = (result[key] || 0) + item[key];
                    }
                  }
                });
                
                combined[key] = JSON.stringify(result);
              } else if (key === "sales_qtr_won_leads") {
                const parseData = JSON.parse(currentItem[key]);
                
                arr4.push(parseData);
                let result = {};
                arr4.forEach((item) => {
                  for (const key in item) {
                    if (
                      item.hasOwnProperty(key) &&
                      key.startsWith("closed_q")
                    ) {
                      result[key] = (result[key] || 0) + item[key];
                    }
                  }
                });
                
                combined[key] = JSON.stringify(result);
              } else if (typeof currentItem[key] === "number") {
                // For other integer values, perform addition
                combined[key] = (combined[key] || 0) + currentItem[key];
              } else {
                combined[key] = currentItem[key];
              }
            }
          }

          if (firstId !== undefined) {
            combined.id = firstId;
          }
          return combined;
        }, {});
      };

      const combinedDataResult = combineData(orderedResult);
      combinedDataResult['selectedIds'] = data;
      
      console.log("combinedDataResult",combinedDataResult);
      dispatch(setBaseLineData(combinedDataResult));

      if (
        combinedDataResult.funnelConfigs &&
        combinedDataResult.funnelConfigs.items
      ) {
        const promises = combinedDataResult.funnelConfigs.items.map((id) =>
          dispatch(getMultipleFunnelConfiguration(id))
        );

        Promise.all(promises.map((promise) => promise.catch(() => undefined)))
          .then((funnelConfigResponses) => {
            // Filter out undefined responses
            const validResponses = funnelConfigResponses.filter(
              (response) => response !== undefined
            );

            // Combine all valid responses into a single array or object
            const combinedFunnelConfigObject = validResponses.reduce(
              (combinedFun, config) => {
                // Combine other details at the top level
                for (const key in config) {
                  if (config.hasOwnProperty(key) && key !== "stages") {
                    combinedFun[key] = config[key];
                  }
                }

                // Combine stages items
                if (config.stages && config.stages.items) {
                  config.stages.items.forEach((stageItem) => {
                    // Find the existing stage item with the same order in the combined object
                    const existingStageItem = combinedFun.stages.items.find(
                      (combinedItem) => combinedItem.order === stageItem.order
                    );

                    if (existingStageItem) {
                      // Combine values based on the type (integer, string, or numeric)
                      existingStageItem.order = stageItem.order;
                      existingStageItem.name = stageItem.name;

                      for (const fieldKey in stageItem) {
                        if (
                          stageItem.hasOwnProperty(fieldKey) &&
                          fieldKey !== "order" &&
                          fieldKey !== "name"
                        ) {
                          if (
                            fieldKey === "conversion" &&
                            typeof stageItem[fieldKey] === "number"
                          ) {
                            // For the 'conversion' field, accumulate the values
                            existingStageItem[fieldKey] =
                              (existingStageItem[fieldKey] || 0) +
                              stageItem[fieldKey];
                          } else if (typeof stageItem[fieldKey] === "number") {
                            // For numeric fields other than 'conversion', perform addition
                            existingStageItem[fieldKey] =
                              (existingStageItem[fieldKey] || 0) +
                              stageItem[fieldKey];
                            // Round up the numeric value
                            existingStageItem[fieldKey] = Math.ceil(
                              existingStageItem[fieldKey]
                            );
                          } else {
                            // For other fields, take the value from the last item
                            existingStageItem[fieldKey] =
                              stageItem[fieldKey] ||
                              existingStageItem[fieldKey];
                          }
                        }
                      }
                    } else {
                      // If the order doesn't exist in the combined object, add the stage item
                      combinedFun.stages.items.push({
                        order: stageItem.order,
                        name: stageItem.name,
                        // Add other fields as needed
                        ...stageItem,
                      });
                    }
                  });
                }
                // Remove other properties at the top level as needed

                return combinedFun;
              },
              { stages: { items: [] } }
            );
            console.log("combinedFunnelConfigObject",combinedFunnelConfigObject);  
            // Calculate the average for the "conversion" field after processing all items
            combinedFunnelConfigObject.stages.items.forEach((stageItem) => {
              if (
                stageItem.hasOwnProperty("conversion") &&
                typeof stageItem.conversion === "number"
              ) {
                stageItem.conversion /= validResponses.length;
                // Round up the average value
                stageItem.conversion = Math.ceil(stageItem.conversion);
              }
            });

            console.log()
            dispatch(setFunnelConfig(combinedFunnelConfigObject));

            if (combinedFunnelConfigObject.stages.items.length > 0) {
              let newPlotData = convertReverseArray(
                combinedFunnelConfigObject.stages.items
              );
              newPlotData.map((item, index) => {
                if (index === 0) {
                  item.value = !isFinite(marketingSourcedCount)
                    ? 0
                    : Number.parseInt(marketingSourcedCount);
                } else {
                  // Use the averaged value for the "conversion" field
                  item.value = Number.parseInt(item.conversion);
                }
                return item;
              });
              
              dispatch(setFunnelStagesList(convertReverseArray(newPlotData)));
            }
          })
          .catch((error) => {
            console.error("Error combining funnelConfig responses", error);
          })
          .finally(() => {
            // Dispatch any final actions or handle completion as needed
          });
      } else {
        console.error("funnelConfigs or funnelConfigs.items is undefined");
      }

      dispatch(
        getCalculationMetrics(
          combinedDataResult.goal,
          combinedDataResult.mrktSourcePercent,
          combinedDataResult.avgDealSize
        )
      );
      dispatch(setCheckLoader(false));
      return response;
    })
    .catch((e) => {
      console.log("error :", e);
      dispatch(setCheckLoader(false));
    });
};

// export const getBaselineListData = () => (dispatch, getState) => {
//   dispatch(setLoader(true));
//   return ExploricApi.getBaseLineMetricList()
//     .then((response) => {
//       console.log("resList",response);
//       return response;
//     })
//     .catch((e) => {
//       console.log("error :", e);
//       dispatch(setLoader(false));
//     });
// };

export const createBaseLineMetric = (inputData) => (dispatch, getState) =>
  ExploricApi.createBaselineMetricApi(inputData)
    .then((response) => {
      const { createBaselineMetric } = response.data;
      dispatch(setBaseLineData(createBaselineMetric));
      dispatch(
        getCalculationMetrics(
          createBaselineMetric.goal,
          createBaselineMetric.mrktSourcePercent,
          createBaselineMetric.avgDealSize
        )
      );
      message.success("New Segment created");
      return createBaselineMetric;
    })
    .catch((e) => {
      console.log("error :", e);
    });

export const updateBaseLineMetric = (inputData) => (dispatch, getState) => {
  dispatch(setLoader(true));
  ExploricApi.updateBaseLineMetric(inputData)
    .then((response) => {
      const { updateBaselineMetric } = response.data;
      dispatch(setBaseLineData(updateBaselineMetric));
      dispatch(
        getCalculationMetrics(
          updateBaselineMetric.goal,
          updateBaselineMetric.mrktSourcePercent,
          updateBaselineMetric.avgDealSize
        )
      );
      return updateBaselineMetric;
    })
    .catch((e) => {
      console.log("error :", e);
      message.error("Something went wrong");
      dispatch(setLoader(false));
    });
};

export const createFunnelConfiguration = (inputData) => (dispatch, getState) =>
  ExploricApi.createFunnelConfiguration(inputData)
    .then((response) => {
      const { createFunnelConfig } = response.data;
      dispatch(setFunnelConfig(createFunnelConfig));
      return createFunnelConfig;
    })
    .catch((e) => {
      console.log("Error : ", e);
      return e;
    });

export const updateFunnelConfiguration =
  (inputData) => (dispatch, getState) => {
    return ExploricApi.updateFunnelConfiguration(inputData)
      .then((response) => {
        const { updateFunnelConfig } = response.data;
        dispatch(setFunnelConfig(updateFunnelConfig));
        return updateFunnelConfig;
      })
      .catch((e) => {
        console.log("Error : ", e);
      });
  };

export const getFunnelConfiguration = (id) => (dispatch, getState) => {
  const { marketingSourcedCount } = getState().revenueReducer;
  return ExploricApi.getFunnelConfiguration(id)
    .then((response) => {
     
      const { getFunnelConfig } = response.data;
      dispatch(setFunnelConfig(getFunnelConfig));
      
      if (getFunnelConfig.stages.items.length > 0) {
        let newPlotData = convertReverseArray(getFunnelConfig.stages.items);
        newPlotData.map((item, index) => {
          if (index === 0) {
            item.value = !isFinite(marketingSourcedCount)
              ? 0
              : Number.parseInt(marketingSourcedCount);
          } else {
            item.value = Number.parseInt(
              (newPlotData[index - 1].value * 100) /
                Number.parseInt(item.conversion)
            );
          }
          return item;
        });
        
        dispatch(setFunnelStagesList(convertReverseArray(newPlotData)));
      }
      return getFunnelConfig;
    })
    .catch((e) => {
      console.log("Error : ", e);
    });
};

export const getMultipleFunnelConfiguration = (id) => (dispatch, getState) => {
  return ExploricApi.getFunnelConfiguration(id)
    .then((response) => {
      console.log("ressFunelConfigg", response);
      const { getFunnelConfig } = response.data;
      // dispatch(setFunnelConfig(getFunnelConfig));
      return getFunnelConfig;
    })
    .catch((e) => {
      console.log("Error : ", e);
    });
};

export const getFunnelConfigList = (id) => (dispatch, getState) => {
  return ExploricApi.getFunnelConfigsList(id)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log("Error : ", e);
    });
};

export const createFunnelStages = (inputData) => (dispatch, getState) =>
  ExploricApi.createFunnelStages(inputData)
    .then((response) => {
      const { createStage } = response.data;
      return createStage;
    })
    .catch((e) => {
      console.log("Error : ", e);
      return e;
    });

export const updateFunnelStages = (inputData) => (dispatch, getState) => {
  return ExploricApi.updateFunnelStages(inputData)
    .then((response) => {
      const { updateStage } = response.data;
      return updateStage;
    })
    .catch((e) => {
      console.log("Error : ", e);
    });
};

export const deleteFunnelStages = (inputData) => (dispatch, getState) => {
  return ExploricApi.deleteFunnelStages(inputData)
    .then((response) => {
      const { deleteStage } = response.data;
      return deleteStage;
    })
    .catch((e) => {
      console.log("Error : ", e);
    });
};
export const deleteFunnelConfig = (inputData) => (dispatch, getState) => {
  return ExploricApi.deleteFunnelConfigApi(inputData)
    .then((response) => {
      const { DeleteFunnelConfig } = response.data;
      return DeleteFunnelConfig;
    })
    .catch((e) => {
      console.log("Error : ", e);
    });
};

export const getFunnelStagesList = (id) => (dispatch, getState) => {
  return ExploricApi.getFunnelStagesList(id)
    .then((response) => {
      const { listStages } = response.data;
      dispatch(setFunnelStagesList(listStages.items));
    })
    .catch((e) => {
      console.log("error : ", e);
    });
};

export const getListStageMapingData = (filter) => (dispatch, getState) => {
  dispatch(setLoader(true));
  ExploricApi.getListStageMapingApi(filter)
    .then((response) => {
      const listStages = response.data;
      dispatch(setAccountMasterStages(listStages));
      dispatch(setLoader(false));
      return listStages;
    })
    .catch((e) => {
      console.log("error :", e);
      dispatch(setLoader(false));
    });
};

export const getPlanList = () => async (dispatch, getState) => {
  let nextToken = null;
  let list = [];
  do {
    let res = await ExploricApi.getPlanListApi();
    res?.data?.listPlans?.items?.map((item) => list.push(item));
    nextToken = res?.data?.listPlans?.nextToken;
  } while (nextToken);
  return list;
};
