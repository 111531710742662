import React, { useEffect, useState } from 'react';
import Coaching from './Coaching';
import Users from './Users';
import Companies from './Companies';
import { Tabs } from 'antd';
import './admin.less';
import { useDispatch } from 'react-redux';
import {
  updateCompanyAccount,
  setPermission,
  setViewType,
} from 'store/actions/loginAction';
import { setPlanningPeriod } from 'store/actions/revenueAction';
import PackageSettings from './PackageSettings';
import sdk from 'sdk/Accounts';
import config from '../../../src/aws-exports';
import { Storage } from 'aws-amplify';
import { useParams } from 'react-router-dom';

const Admin = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { content } = useParams();

  Storage.configure({
    AWSS3: {
      bucket: config?.aws_user_files_s3_bucket,
      region: config.aws_user_files_s3_bucket_region,
    },
  });

  const getProperties = async (accountId) => {
    const permissionObj = {};
    let listProperty = await sdk.listDefaultProperties(accountId);
    if (!accountId) {
      listProperty?.forEach((itemVal) => {
        permissionObj[itemVal?.name] = itemVal?.value;
      });
      dispatch(setPermission(permissionObj));
    } else {
      dispatch(setPermission(listProperty));
    }
  };

  const selectCompany = async (accountId, view) => {
    if (view === 'companyadmin' || view === 'companycontributor') {
      await getProperties(accountId?.id);
    } else {
      await getProperties();
    }

    dispatch(updateCompanyAccount(accountId));
    dispatch(setViewType(view));
    dispatch(setPlanningPeriod(new Date().getFullYear()));

    setTimeout(() => {
      if (content === 'content') {
        window.location.href = '/dashboard/content';
      } else {
        window.location.href = '/';
      }
    }, 500);
  };

  const [UsersFilter, setUsersFilter] = useState({});

  const [SelectedTab, setSelectedTab] = useState('company');

  useEffect(() => {
    if (Object.keys(UsersFilter).length > 0) {
      setSelectedTab('user');
    }
  }, [UsersFilter]);

  return (
    <div className="sub-container panel-design">
      {/* {loadingCompanyInfo && <div className="loading">Loading;</div>} */}
      <Tabs
        centered
        className="course-admin-tab general-tab first-tab"
        activeKey={SelectedTab}
        onChange={setSelectedTab}
      >
        <TabPane tab="Companies" key="company">
          <Companies
            selectCompany={selectCompany}
            setUsersFilter={setUsersFilter}
          />
        </TabPane>
        <TabPane tab="Users" key="user">
          <Users
            selectCompany={selectCompany}
            setUsersFilter={setUsersFilter}
            UsersFilter={UsersFilter}
          />
        </TabPane>
        {/* <TabPane tab="Package Settings" key="package-settings">
          <PackageSettings  />
        </TabPane> */}
        <TabPane tab="Coaching" key="coaching">
          <Coaching data={'Coaching'} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Admin;
