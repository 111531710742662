import React, { useEffect, useState } from "react";
import LineGraph from "commons/Charts/LineGraph";
import { useSelector } from "react-redux";
import {
  // getActaulReportWeb,
  // getActaulReportWebGA4,
} from "store/actions/reportAction";
import dimensionParameters from './data'

const LineChart = (props) => {
  // const dispatch = useDispatch();
  

  const { ActualDataWebAnalytics } = useSelector(
    (state) => state.reportReducer
  );

  // useEffect(() => {

  //     if (props.selectedProfile) {
  //       const query = {
  //         // ad_group_ids: "158385732",
  //         ad_group_ids: props.selectedProfile,
  //         start_date: props.startDate,
  //         end_date: props.endDate,
  //         fields: props.byQeury,
  //         verbose: true,
  //       };
  //       if (props.analysisType === "GA4") {
  //         dispatch(getActaulReportWebGA4(query));
  //       } else {
  //         dispatch(getActaulReportWeb(query));
  //       }
  //     }
  // }, [ // eslint-disable-line
  //   props.startDate,
  //   props.endDate,
  //   props.byQeury,
  //   props.analysisType,
  //   props.selectedProfile,
  //   props.durationType,
  // ]); 

  const [analyticsData, setAnalyticsData] = useState();
  const [chartDimension, setChartDimension] = useState();
  useEffect(() => {
    const parsedData =  ActualDataWebAnalytics?.results;
  
    if(parsedData?.length > 0) {
      if (parsedData?.length > 0 && props.durationType && Object.keys(parsedData?.[0]).includes(props.durationType) && props.analysisType.toLowerCase() === ActualDataWebAnalytics.type.toLowerCase()) {
        let chartCat = props.byQeury.split(",");
        let forDeletion = ["day", "week", "month", "year", "quarter"];
        chartCat = chartCat.filter((item) => !forDeletion.includes(item));
        const lineChartArray = [];
        chartCat.map((catVal) => { //eslint-disable-line
          parsedData.map((data) => { //eslint-disable-line
            lineChartArray.push({
              category: dimensionParameters.find((val) => val.key === catVal).title,
              duration: data[props.durationType],
              value: data[catVal],
            });
          });
        });

        const dimensionArray = [];
        props.byQeury.split(",").forEach(element => {
          if(dimensionParameters.find((val) => val.key === element))
            dimensionArray.push(dimensionParameters.find((val) => val.key === element)?.title)
        });
        setChartDimension(dimensionArray.join(", "))
        setAnalyticsData(lineChartArray);
      } 
    } else {
      setAnalyticsData();
    }
  }, [ActualDataWebAnalytics,props.durationType, props.byQeury, props.analysisType]); // eslint-disable-line

  return (
    <div>
      <div
        className="site-card-wrapper"
        style={{
          background: "#fff",
          marginTop: 0,
          paddingTop: 0,
          paddingLeft: 0,
        }}
      >
        <LineGraph data={analyticsData} dimensions = {chartDimension}></LineGraph>
      </div>
      
    </div>
  );
};

export default LineChart;
