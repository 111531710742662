import {
  SET_BUDGET_LIST_DATA,
  SET_CREATED_CAMPAIGN_DATA,
  SET_CREATED_BUDGET_DATA,
  SET_CREATED_CHANNEL_DATA,
  SET_BUDGET_DATA,
  SET_PROSPECT_DATA,
  SET_CHANNEL_LIST_DATA,
  SET_CAMPAIGN_LIST_DATA,
  SET_CAMPAIGN_CHANNEL_PLANNED,
  SHOW_LOADER,
  SET_TOTAL_SPEND,
  SET_VIEW_TOTAL,
  SERVER_ERROR,
  SET_BUDGET_INFO_DATA,
  SET_ORIGINAL_CRM_SOURCE,
  SET_CAMPAIGN_ACTUALS,
  SET_ORIGINAL_CRM_SOURCE_DD1,
  SET_ORIGINAL_CRM_SOURCE_DD2,
  SET_CAMPAIGN_ACTUALS_AMOUNT,
  SET_TERRITORYS_LIST_DATA,
} from './index';
import { ExploricApi } from '../../utility/Api';
import { message } from 'antd';
import { preDefinedChannelNamesTypes } from './../../utility/Constants';
import { API } from 'aws-amplify';

export const setCreatedBudgetData = (data) => {
  return {
    type: SET_CREATED_BUDGET_DATA,
    payload: data,
  };
};
export const setServerError = (data) => {
  return {
    type: SERVER_ERROR,
    payload: data,
  };
};
export const setCreatedChannelData = (data) => {
  return {
    type: SET_CREATED_CHANNEL_DATA,
    payload: data,
  };
};
export const setCreatedCampaignData = (data) => {
  return {
    type: SET_CREATED_CAMPAIGN_DATA,
    payload: data,
  };
};
export const setLoader = (data) => {
  return {
    type: SHOW_LOADER,
    payload: data,
  };
};

export const setBudgetListData = (data) => {
  return {
    type: SET_BUDGET_LIST_DATA,
    payload: data,
  };
};

export const setTerritorys = (data) => {
  return {
    type: SET_TERRITORYS_LIST_DATA,
    payload: data,
  };
};

export const setChannelListData = (data) => {
  return {
    type: SET_CHANNEL_LIST_DATA,
    payload: data,
  };
};
export const setCampaignListData = (data) => {
  return {
    type: SET_CAMPAIGN_LIST_DATA,
    payload: data,
  };
};

export const setBudgetData = (data) => {
  return {
    type: SET_BUDGET_DATA,
    payload: data,
  };
};

export const setBudgetInfo = (data) => {
  return {
    type: SET_BUDGET_INFO_DATA,
    payload: data,
  };
};

export const setChannelCampaignPlannedTotals = (data) => {
  return {
    type: SET_CAMPAIGN_CHANNEL_PLANNED,
    payload: data,
  };
};

export const setTotalSpend = (data) => {
  return {
    type: SET_TOTAL_SPEND,
    payload: data,
  };
};

export const setViewTotal = (data) => {
  return {
    type: SET_VIEW_TOTAL,
    payload: data,
  };
};

export const setOriginalSource = (data) => {
  return {
    type: SET_ORIGINAL_CRM_SOURCE,
    payload: data,
  };
};

export const setOriginalSourceDD1 = (data) => {
  return {
    type: SET_ORIGINAL_CRM_SOURCE_DD1,
    payload: data,
  };
};

export const setOriginalSourceDD2 = (data) => {
  return {
    type: SET_ORIGINAL_CRM_SOURCE_DD2,
    payload: data,
  };
};

export const setCampaignActuals = (data) => {
  return {
    type: SET_CAMPAIGN_ACTUALS,
    payload: data,
  };
};

export const setCampaignActualsAmount = (data) => {
  return {
    type: SET_CAMPAIGN_ACTUALS_AMOUNT,
    payload: data,
  };
};

export const createBudget =
  (inputData, copy = false) =>
  async (dispatch, getState) => {
    const existingBudgetList = getState().budgetReducer.BudgetProfileList;
    // const channelListMaster = getState().budgetReducer.ChannelList;
    await dispatch(getChannelList());
    const account = getState().loginReducer.groups;
    if (
      existingBudgetList.length === 0 ||
      getState().budgetReducer.ChannelList?.length === 0
    ) {
      await preDefinedChannelNamesTypes.map((channelVal) => {
        ExploricApi.createChannelAPI({
          account: account[0],
          name: channelVal?.name,
          channelType: channelVal?.type,
        })
          .then((response) => {})
          .catch((e) => {
            console.log('error :', e);
          });
        return true;
      });
      inputData.active = true;
      dispatch(getChannelList());
    }

    return await ExploricApi.createBudgetAPI(inputData)
      .then(async (response) => {
        const createdBudgetData = response.data;
        await dispatch(setCreatedBudgetData(createdBudgetData));
        await dispatch(getBudgetList());
        dispatch(setChannelCampaignPlannedTotals({}));
        dispatch(getBudget(createdBudgetData.createBudget.id));
        if (copy) message.success(`Budget copied successfully`, 5);
        else message.success(`Budget profile created successfully`, 5);
        return createdBudgetData;
      })
      .catch((e) => {
        console.log('error :', e);
      });
  };

export const updateBudget = (inputData) => (dispatch, getState) =>
  ExploricApi.updateBudgetAPI(inputData)
    .then((response) => {
      const createdBudgetData = response.data.updateBudget;
      dispatch(setCreatedBudgetData(createdBudgetData));
      dispatch(getBudgetList());
      dispatch(getBudget(inputData.id));
      message.success(`Budget profile updated successfully`, 5);
      return createdBudgetData;
    })
    .catch((e) => {
      console.log('error :', e);
    });

export const updateCampaign = (inputData) => (dispatch) =>
  ExploricApi.updateCampaignApi(inputData).then(() => {
    dispatch(getCapmaignList());
    dispatch(getBudgetList());
  });

export const updateCampaignDetails = (inputData) => (dispatch) =>
  ExploricApi.updateCampaignDetailsApi(inputData);

export const updateCampaignMonth = (inputData) => (dispatch) =>
  ExploricApi.updateCampaignMonthApi(inputData);

export const flipOtherBudgetStatus = (inputData) => (dispatch, getState) =>
  ExploricApi.updateBudgetAPI(inputData)
    .then((response) => {
      const createdBudgetData = response.data.updateBudget;
      return createdBudgetData;
    })
    .catch((e) => {
      console.log('error :', e);
    });

export const getBudgetList = () => async (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  let nextToken = null;
  let List = [];
  do {
    let res = await ExploricApi.getBudgetListApi(
      { account: { eq: account[0] } },
      nextToken
    );
    const budgetList = res.data.listBudgets;
    budgetList?.items.map((item) => List.push(item));
    nextToken = budgetList.nextToken;
  } while (nextToken);
  List.sort(GetSortOrder('updatedAt'));
  dispatch(setBudgetListData(List));
  return List;
};

export const getTerritorysList = () => async (dispatch, getState) => {
  return ExploricApi.getTerritorysApi()
    .then((response) => {
      dispatch(setTerritorys(response?.data?.listTerritorys?.items));
      console.log('ress', response.data.listTerritorys);
      return response;
    })
    .catch((e) => {
      console.log('error :', e);
      dispatch(setLoader(false));
    });
};

const GetSortOrder = (prop) => {
  return (a, b) => {
    if (a[prop] > b[prop]) {
      return -1;
    } else if (a[prop] < b[prop]) {
      return 1;
    }
    return 0;
  };
};
export const getBudget = (id) => (dispatch, getState) => {
  dispatch(setLoader(true));

  return ExploricApi.getBudgetApi(id)
    .then((response) => {
      const budgetData = response.data.getBudget;
      dispatch(setBudgetData(budgetData));
      dispatch(setLoader(false));
    })
    .catch((e) => {
      console.log('error :', e);
      dispatch(setBudgetData({}));
      dispatch(setLoader(false));
    });
};
export const getBudgetInfo = (params) => (dispatch, getState) => {
  dispatch(setLoader(true));

  ExploricApi.getBudgetInfoApi(params)
    .then((response) => {
      const budgetDataInfo = response.data.listBudgets;
      dispatch(setBudgetInfo(budgetDataInfo));
      dispatch(setLoader(false));
      return budgetDataInfo;
    })
    .catch((e) => {
      console.log('error :', e);
      dispatch(setBudgetInfo({}));
      dispatch(setLoader(false));
    });
};

export const setProspects = (data) => {
  return {
    type: SET_PROSPECT_DATA,
    payload: data,
  };
};

export const createChannel =
  (inputData, showMessage = true) =>
  async (dispatch, getState) => {
    dispatch(setLoader(true));
    dispatch(getChannelList());
    const channelListMaster = getState().budgetReducer.ChannelList;
    const findChannelMatch = channelListMaster.find(
      (channelListMasterVal) =>
        channelListMasterVal.name.trim().toLowerCase() ===
        inputData.name.trim().toLowerCase()
    );
    if (!findChannelMatch) {
      return ExploricApi.createChannelAPI(inputData)
        .then(async (response) => {
          const createdChannelData = response.data;
          await dispatch(getChannelList());
          // dispatch(setCreatedChannelData(createdBudgetData));
          dispatch(setLoader(false));
          showMessage && message.success(`Channel created successfully`, 5);
          dispatch(setCreatedChannelData(createdChannelData));
          dispatch(setServerError('success'));
          return response;
        })
        .catch((e) => {
          console.log('error :', e);
          dispatch(setLoader(false));
          dispatch(setServerError('failed'));
          return e;
        });
    } else {
      message.error(`Channel name already exist!`, 5);
      dispatch(setLoader(false));
      dispatch(setServerError('failed'));
      return;
    }
  };

export const updateChannel = (inputData) => (dispatch, getState) => {
  dispatch(setLoader(true));
  const channelListMaster = getState().budgetReducer.ChannelList;
  const findMatchChannel = channelListMaster?.filter(
    (channelsInfo) =>
      channelsInfo?.name?.toLowerCase() === inputData?.name?.toLowerCase() &&
      channelsInfo?.id !== inputData?.id
  );
  if (findMatchChannel?.length === 0) {
    ExploricApi.updateChannelAPI(inputData)
      .then((response) => {
        const { updateCampaignChannel } = response.data;
        dispatch(getChannelList());
        dispatch(setLoader(false));
        message.success(`Channel updated successfully`, 5);
        dispatch(setServerError('success'));
        return updateCampaignChannel;
      })
      .catch((e) => {
        console.log('error :', e);
        dispatch(setServerError('failed'));
        dispatch(setLoader(false));
      });
  } else {
    message.error(`Channel name already exist!`, 5);
    dispatch(setServerError('failed'));
    dispatch(setLoader(false));
  }
};

export const getChannelList = () => async (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  let nextToken = null;
  let list = [];
  do {
    let res = await ExploricApi.getChannelListApi(
      { account: { eq: account[0] } },
      nextToken
    ).catch((e) => console.log('Error:', e));
    nextToken = res?.data?.listCampaignChannels?.nextToken;
    // eslint-disable-next-line array-callback-return
    res?.data?.listCampaignChannels?.items?.map((item) => {
      list.push(item);
    });
    if (!nextToken) {
      break;
    }
  } while (nextToken);
  dispatch(setChannelListData(list));
  return list;
};

export const deleteCampaign = (inputData) => (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  return ExploricApi.deleteCampaignApi(inputData)
    .then(() =>
      ExploricApi.getCampaignsListApi({ account: { eq: account[0] } })
    )
    .then((res) => {
      dispatch(setCampaignListData(res.data.listCampaigns.items));
      dispatch(getBudgetList());
      return res;
    });
};

export const deleteCampaignDetails = (inputData) => (dispatch, getState) => {
  return ExploricApi.deleteCampaignDetailApi(inputData);
};

export const deleteCampaignMonth = (inputData) => (dispatch, getState) => {
  return ExploricApi.deleteCampaignMonthApi(inputData);
};

export const deleteBudget = (inputData) => (dispatch) =>
  ExploricApi.deleteBudgetAPI(inputData)
    .then(async (response) => {
      dispatch(getBudgetList());
      response && message.success('Budget deleted successfully');
      return response;
    })
    .catch((err) => {
      message.error('Something went wrong');
      return err;
    });

export const deleteBudgetCampaign = (inputData) => (dispatch) =>
  ExploricApi.deleteBudgetCampaignApi(inputData).then((response) => {
    dispatch(getBudgetList());
  });

export const createCampaign =
  (inputData, budgetProfiles, noMessage = false) =>
  (dispatch, getState) => {
    dispatch(setLoader(true));

    return ExploricApi.createCampaignAPI(inputData)
      .then(async (response) => {
        const createdCampaignData = response.data.createCampaign;
        const account = getState().loginReducer.groups;
        await Promise.all(
          budgetProfiles.map((budgetProfile) => {
            return dispatch(
              createBudgetCampaign({
                account: account[0],
                budgetCampaignBudgetId: budgetProfile,
                budgetCampaignCampaignId: createdCampaignData.id,
              })
            );
            // return true;
          })
        )
          .then(() => dispatch(getCapmaignList()))
          .then(() => dispatch(getBudgetList()));
        dispatch(setCreatedCampaignData(createdCampaignData));
        !noMessage && message.success(`Campaign created successfully`, 5);
        return createdCampaignData;
      })
      .catch((e) => {
        console.log('error :', e);
        dispatch(setLoader(false));
      });
  };

export const createCampaignDetails = (inputData) => (dispatch, getState) => {
  return ExploricApi.createCampaignDetailsAPI(inputData).then((response) => {
    const createCampaingDetails = response.data.createCampaignDetail;
    return createCampaingDetails;
  });
};

export const createCampaignMonth = (inputData) => (dispatch, getState) => {
  return ExploricApi.createCampaignMonthAPI(inputData).then((response) => {
    const createCampaignMonth = response.data.createCampaignMonth;
    return createCampaignMonth;
  });
};

export const createBudgetCampaign = (inputData) => (dispatch, getState) =>
  ExploricApi.createBudgetCampaignAPI(inputData)
    .then((response) => {
      const { createdBudgetCampaignData } = response.data;
      // dispatch(getBudgetList());
      return createdBudgetCampaignData;
    })
    .catch((e) => {
      console.log('error :', e);
    });

export const getCapmaignList = () => async (dispatch, getState) => {
  const account = getState().loginReducer.groups;
  let list = [];
  let nextToken = null;

  do {
    let response = await ExploricApi.getCampaignsListApi(
      { account: { eq: account[0] } },
      nextToken
    );
    list.push(response?.data?.listCampaigns?.items);
    nextToken = response?.data?.listCampaigns?.nextToken;

    if (!nextToken) {
      let campaignList = [];
      list.map((item) => item.map((data) => campaignList.push(data)));
      dispatch(setCampaignListData(campaignList));
      break;
    }
  } while (nextToken);
};

export const deleteCampaignChannel = (inputData) => (dispatch) =>
  ExploricApi.deleteCampaignChannelApi(inputData).then(() =>
    dispatch(getChannelList())
  );

// export const getCRMOriginalSource = (query) => (dispatch, getState) => {
//   dispatch(setLoader(true));
//   ExploricApi.getActualDataReportApi(query)
//     .then((response) => {
//       const originalSourceData = response.data;
//       dispatch(
//         setOriginalSource( JSON.parse(originalSourceData.getActualData.results)[
//           "query_results"
//         ])
//       );
//       dispatch(setLoader(false));
//       return originalSourceData;
//     })
//     .catch((e) => {
//       console.log("error :", e);
//       dispatch(setOriginalSource());
//       dispatch(setLoader(false));
//     });
// };

// export const getCRMOriginalSourceCascadeDD = (query,dd) => (dispatch, getState) => {
//   dispatch(setLoader(true));
//   ExploricApi.getActualDataReportApi(query)
//     .then((response) => {
//       const originalSourceData = response.data;

//       if(dd === "hs_org_source_dd_1") {
//         dispatch(
//           setOriginalSourceDD1( JSON.parse(originalSourceData.getActualData.results)[
//             "query_results"
//           ])
//         );
//       } else {
//         dispatch(
//           setOriginalSourceDD2( JSON.parse(originalSourceData.getActualData.results)[
//             "query_results"
//           ])
//         );
//       }

//       dispatch(setLoader(false));
//       return originalSourceData;
//     })
//     .catch((e) => {
//       console.log("error :", e);
//       if(dd === "hs_org_source_dd_1") {
//         dispatch(setOriginalSourceDD1());
//       } else {
//         dispatch(setOriginalSourceDD2());
//       }

//       dispatch(setLoader(false));
//     });
// };

// export const getCampaignActuals = (query, camapignID) => (dispatch, getState) => {
//   dispatch(setLoader(true));
//   ExploricApi.getActualDataReportApi(query)
//     .then((response) => {
//       const originalSourceDataCampaign = response.data;

//       let campaignactual = {}
//       campaignactual['camapignID'] = camapignID

//       let res = JSON.parse(originalSourceDataCampaign.getActualData.results)["query_results"]['hs_num_contacts']
//       let data = {}
//       Object.keys(res).forEach((key) => {
//         let spilted = key?.split("created_at");
//         let month = moment(
//           spilted[1].split(">= '")[1].split("'")[0]
//         ).format("MMM")
//         let sum = 0
//         res[key].map(item => item.map(data => sum += data))
//         data[month] = sum
//       })
//       campaignactual['data'] = data

//       let actualcampaigndata = getState().budgetReducer.campaignActualsData ? getState().budgetReducer.campaignActualsData : [];
//       let camapignswithactuals = actualcampaigndata.map(item => item.camapignID)
//       if (!camapignswithactuals.includes(camapignID)) {
//         actualcampaigndata.push(campaignactual)
//       }
//       else {
//         let campaign = actualcampaigndata.find(item => item.camapignID ===
//           camapignID)
//         if (campaign?.data !== campaignactual['data']) {
//           // actualcampaigndata.push(campaignactual)
//           actualcampaigndata[actualcampaigndata.indexOf(campaign)].data = campaignactual['data']
//         }
//       }
//       dispatch(setCampaignActuals(actualcampaigndata));

//       // FOR ACTUALS AMOUNT CAMPAIGN TABLE
//       let campaignactual2 = {}
//       campaignactual2['camapignID'] = camapignID
//       let res2 = JSON.parse(originalSourceDataCampaign.getActualData.results)["query_results"]['closed_deal_amount_query']
//       let data2 = {}
//       Object.keys(res2).forEach((key) => {
//         let spilted = key?.split("created_at");
//         let month = moment(
//           spilted[1].split(">= '")[1].split("'")[0]
//         ).format("MMM")
//         let sum = 0
//         res2[key].map(item => item.map(data2 => sum += data2))
//         data2[month] = sum
//       })
//       campaignactual2['data'] = data2

//       let actualcampaigndata2 = getState().budgetReducer.campaignActualsAmountData ? getState().budgetReducer.campaignActualsAmountData : [];
//       let camapignswithactuals2 = actualcampaigndata2.map(item => item.camapignID)
//       if (!camapignswithactuals2.includes(camapignID)) {
//         actualcampaigndata2.push(campaignactual2)
//       }
//       else {
//         let campaign = actualcampaigndata2.find(item => item.camapignID ===
//           camapignID)
//         if (campaign?.data !== campaignactual2['data']) {
//           // actualcampaigndata.push(campaignactual)
//           actualcampaigndata2[actualcampaigndata2.indexOf(campaign)].data = campaignactual2['data']
//         }
//       }
//       dispatch(setCampaignActualsAmount(actualcampaigndata2));
//       dispatch(setLoader(false));
//       return originalSourceDataCampaign;
//     })
//     .catch((e) => {
//       console.log("error :", e);
//       dispatch(setCampaignActuals());
//       dispatch(setCampaignActualsAmount());
//       dispatch(setLoader(false));
//     });
// };

export const getLastActualSync = (account) => (dispatch, getState) => {
  return ExploricApi.getLastActualSyncApi(account[0])
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getCampaignMappings = (inputData) => (dispatch, getState) => {
  let init = {
    body: inputData,
  };
  return API.post('backendRest', '/mapping/getMappings', init);
};

export const saveCampaignMappings = (inputData) => () => {
  let init = {
    body: inputData,
  };
  return API.post('backendRest', '/mapping/saveMappings', init);
};
export const getCampaignMappingHubspotFilter = (inputData) => () => {
  let init = {
    body: inputData,
  };
  return API.post(
    'backendRest',
    '/mapping/CampaignReportingHubspotFilter',
    init
  );
};

export const getCampaignActualData = (inputData) => () => {
  let init = {
    body: inputData,
  };
  return API.post('backendRest', '/mapping/getCampaignReporting', init);
};
