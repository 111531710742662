/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Form, Select, Divider, Popover, Input, Button, InputNumber } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import mikeIcon from "../../assets/Images/mikeIcon.svg";
import handsIcon from "../../assets/Images/handsIcon.svg";
import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import {
  getFiscalYear,
  setFiscalYear,
  getFiscalYearsList,
  getBaselineMetricData,
} from "store/actions/revenueAction";
import { getSDRCalculatorData, saveSDRCalculatorData, SDRCalculatorSummary, updateSDRCalculatorData } from "store/actions/sdrAction";
import { convertReverseArray } from "utility/context/CommonMethods";
import _ from "lodash"
import sdr_calculator_preview from "assets/Images/sdr_calculator_preview.png"
import PreviewInfo from 'commons/PreviewInfo';
import { numberWithCommas } from "utility/context/CommonMethods";
import SdrCapacityCalculatorSkeleton from "./SdrCapacityCalculatorSkeleton";
import {TrackClickFunction} from '../../commons/HeapTrack/TrackClickFunction'
import sdk from "sdk/Accounts";

const { Option } = Select;

export default function SdrCapacityCalculator(props) {
  const {HasUnsavedChanges, setHasUnsavedChanges,currentTab, skeletonFlag, showSkeleton } = props
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    marketingSourcedCount,
    funnelStages,
    fiscalYearList,
    fiscalYear,
    baseLineData,
    averageDealSize
  } = useSelector((state) => state.revenueReducer);

  const { groups: account, userPermission, viewType } = useSelector((state) => state.loginReducer);

  const {
    SDRCalculatorValues,
    isLoading
  } = useSelector((state) => state.sdrReducer);

  const [dropDownYearList, setDropDownYearList] = useState([]);
  const [acct, setAcct] = useState(null);
  const [loading, setLoading] = useState(true);

  const stringCapitalize = (str) => {
    if (str) {
      str = str.charAt(0).toUpperCase() + str.slice(1);
      return str;
    }
  };

  const sortingOrder = (a, b) => {
    if (a?.salesAndMarketingAlignment?.order < b?.salesAndMarketingAlignment?.order) {
      return -1;
    }
    if (a?.salesAndMarketingAlignment?.order < b?.salesAndMarketingAlignment?.order) {
      return 1;
    }
    return 0;
  };

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = account.length > 0 ? account[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);

      setAcct(account);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);
  
  

  useEffect(() => {
    setDropDownYearList([
      new Date().getFullYear() - 1,
      new Date().getFullYear(),
      new Date().getFullYear() + 1,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleYearChange = (value) => {
    if(SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.fiscalYearID == value)       // eslint-disable-line
      handleFiscalYearChange(value, SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID);
    else 
      handleFiscalYearChange(value);
    // dispatch(getSalesAndMarketingStages(account));
  };


  useEffect(() => {
    dispatch(getFiscalYearsList()).then(() =>{
      skeletonFlag(false)
    })
  }, [dispatch]);

  const fiscalYearListRef = useRef(fiscalYearList)
  if (!_.isEqual(fiscalYearListRef.current, fiscalYearList)) {
    fiscalYearListRef.current = fiscalYearList
  }

  useEffect(() => {
      handleFiscalYearChange(new Date().getFullYear())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalYearListRef.current])


  const handleFiscalYearChange = async (year, segmentId) => {
    if (fiscalYearList.length) {
      var fy = fiscalYearList.filter((item) => item.year === year);
      if (fy.length > 0) {
        await localStorage.setItem("FiscalYear", window.btoa(fy[0]?.id));
        await dispatch(getFiscalYear(fy[0]?.id, segmentId));
      }
      else {
        // setannualRevenueGoal();
        await dispatch(setFiscalYear())
      }
    } else {
      // setannualRevenueGoal(null);
      await dispatch(setFiscalYear())
    }
    // await setselectedFiscalYear(year);
  };

  const [selectedbaselineMetrics, setselectedbaselineMetrics] = useState();
  useEffect(() => {
    // handleBaselineMeticsChange(baseLineData?.id)
    setselectedbaselineMetrics(baseLineData?.id);
    form.setFieldsValue({
      baselineMetricID:baseLineData?.id
    });
  }, [baseLineData]);

  const handleBaselineMeticsChange = (value) => {
    setselectedbaselineMetrics(value);
    dispatch(getBaselineMetricData(value));
  };


  const getChartData = (funnelStages) => {
    let newChartData = [];
    let newConvertData = [...convertReverseArray(funnelStages)];
    newConvertData.map((item, index) => {
      let { name, conversion, id } = item;
      let newValue =
        index === 0
          ? !isFinite(marketingSourcedCount)
            ? 0
            : Number.parseInt(marketingSourcedCount)
          : Number.parseInt(
              (newChartData[index - 1].value * 100) /
                Number.parseInt(item.conversion)
            );
      let setWidth =
        index === newConvertData.length - 1 ||
        index === newConvertData.length - 2
          ? 100
          : 70;
      let obj = {
        id: id,
        text: item.salesAndMarketingAlignment?.name ?? name,
        percentageValue: conversion,
        value: newValue,
        width: setWidth,
        disabled: item.disabled,
      };
      newChartData.push({ ...obj });
      return 0;
    });
    return [...convertReverseArray(newChartData)];
  };

  const [stages, setStages] = useState();
  const [selectStageTransition, setSelectStageTransition] = useState();

  const [startStage, setStartStage] = useState();
  const [nextStage, setNextStage] = useState();

  const [remainingStages, setRemainingStage] = useState();
  const [remainingFunnelStages, setRemainingFunnelStage] = useState();

  //1.Total Weeks in Period
  const [totalWeekValue, setTotalWeekValue] = useState(52);
  const [WeeklyTotalStartStage, setWeeklyTotalStartStage] = useState(0);
  const [WeeklyTotalNextStage, setWeeklyTotalNextStage] = useState();
  //2.Targeted no. of Calls     
  const [targetCallStartStage, setTargetCallStartStage] = useState(6);
  const [WeeklyTotalCallTargetStartStage, setWeeklyTotalCallTargetStartStage] = useState();
  //3.Daily Call Capacity per SDR    
  const [dailyCallCapacity, setDailyCallCapacity] = useState(40);
  const [weeklyCallCapacityPerSDR, setWeeklyCallCapacityPerSDR] = useState();
  const [SDRNeededtoAchieveTargetedTouches, setSDRNeededtoAchieveTargetedTouches] = useState();
  const [weeklyTotalCalls, setWeeklyTotalCalls] = useState();
  const [dailyTotalCalls, setDailyTotalCalls] = useState();
  const [annualTotalCalls, setAnnualTotalCalls] = useState();
  const [monthlyTotalCalls, setMonthlyTotalCalls] = useState();
  //4. Estimated Connect Rate
  const [estimatedConnectRate, setEstimatedConnectRate] = useState(10);
  const [weeklyTotalConnects, setWeeklyTotalConnects] = useState(0);
  const [dailyTotalConnects, setDailyTotalConnects] = useState(0);
  const [annualTotalConnects, setAnnualTotalConnects] = useState();
  const [monthlyTotalConnects, setMonthlyTotalConnects] = useState();

  //5. Connect to Next Stage Rate
  const [connectToNextStageRate, setConnectToNextStageRate] = useState(25);
  const [weeklyExpected, setWeeklyExpected] = useState();
  const [dailyExpected, setDailyExpected] = useState();
  const [annualExpected, setAnnualExpected] = useState();
  const [monthlyExpected, setMonthlyExpected] = useState();

  // 6. Next Stage No Show Rate
  const [noShowRate, setNoShowRate] = useState(20); 
  const [weeklyAttained, setWeeklyAttained] = useState();
  const [dailyAttained, setDailyAttained] = useState();
  const [annualAttained, setAnnualAttained] = useState();
  const [monthlyAttained, setMonthlyAttained] = useState();

  useEffect(() => {
    dispatch(getSDRCalculatorData())
  }, [dispatch])

  useEffect(() => {
   
  },[currentTab])


  useEffect(() => {

    let onLoadFormValues ={};
    if(SDRCalculatorValues?.listSDRCapacityCalculators?.items?.length > 0) {
      var calculatorValues = SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0];
      onLoadFormValues = {
        totalWeeksInPeriod: calculatorValues?.totalWeeksInPeriod,
        targetNoOfCalls: calculatorValues?.targetNoOfCalls,
        dailyCallCApacity: calculatorValues?.dailyCallCApacity,
        estimatedConnectRate: calculatorValues?.estimatedConnectRate,
        connectToMarketingQualifiedLeadRate: calculatorValues?.connectToMarketingQualifiedLeadRate,
        marketingQualifiedLeadToShowRate: calculatorValues?.marketingQualifiedLeadToShowRate,
      }

      let sortingFunnel = funnelStages.sort(sortingOrder);
      let stageData = getChartData(sortingFunnel);
      setStages(stageData);


      let matchStageEdit = stageData?.find((item) => item?.text === "Marketing Qualified Leads")
      if(!matchStageEdit) {
        matchStageEdit = stageData?.find(
          (item) => item?.id === stageData?.[0]?.id
        );
      } 

      const matchStage = stageData?.find((item) => item?.id === calculatorValues?.stageID)?stageData?.find((item) => item?.id === calculatorValues?.stageID):matchStageEdit;


      let matchStageIndexEdit = stageData?.findIndex(
        (item) => item?.text === "Marketing Qualified Leads"
      );
      if(matchStageIndexEdit === -1) {
        matchStageIndexEdit = 0
      }
      const matchStageIndex = stageData?.findIndex((item) => item?.id === calculatorValues?.stageID) !== -1?stageData?.findIndex((item) => item?.id === calculatorValues?.stageID):matchStageIndexEdit;

      const nextStage = stageData?.[matchStageIndex + 1];
      setStartStage(matchStage);
      setNextStage(nextStage);
      setSelectStageTransition(matchStage?.id);


      setRemainingStage(stageData?.slice(matchStageIndex+1))
      setRemainingFunnelStage(funnelStages?.slice(matchStageIndex+1))


    } else {
      onLoadFormValues = {
        totalWeeksInPeriod: 52,
        targetNoOfCalls: 6,
        dailyCallCApacity: 40,
        estimatedConnectRate: 10,
        connectToMarketingQualifiedLeadRate: 25,
        marketingQualifiedLeadToShowRate: 20,
      }

      let sortingFunnel = funnelStages.sort(sortingOrder);
      let stageData = getChartData(sortingFunnel);
      setStages(stageData);

      let matchStage = stageData?.find((item) => item?.text === "Marketing Qualified Leads")
      if(!matchStage) {
         matchStage = stageData?.find(
          (item) => item?.id === stageData?.[0]?.id
        );
      } 

      let matchStageIndex = stageData?.findIndex(
        (item) => item?.text === "Marketing Qualified Leads"
      );
      if(matchStageIndex === -1) {
         matchStageIndex = stageData?.findIndex(
          (item) => item?.id === stageData?.[0]?.id
        );
      }

      const nextStage = stageData?.[matchStageIndex + 1];
      setStartStage(matchStage);
      setNextStage(nextStage);
      setSelectStageTransition(matchStage?.id);


      setRemainingStage(stageData?.slice(matchStageIndex+1))
      setRemainingFunnelStage(funnelStages?.slice(matchStageIndex+1))

    }
    setTotalWeekValue(onLoadFormValues?.totalWeeksInPeriod)
    setTargetCallStartStage(onLoadFormValues?.targetNoOfCalls)
    setDailyCallCapacity(onLoadFormValues?.dailyCallCApacity)
    setEstimatedConnectRate(onLoadFormValues?.estimatedConnectRate)
    setConnectToNextStageRate(onLoadFormValues?.connectToMarketingQualifiedLeadRate)
    setNoShowRate(onLoadFormValues?.marketingQualifiedLeadToShowRate)

      form.setFieldsValue(onLoadFormValues);

     
  }, [SDRCalculatorValues, funnelStages, currentTab])
  
  useEffect(() => {
    if(SDRCalculatorValues?.listSDRCapacityCalculators?.items?.length > 0) {
      let segementOnLoad = SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID;
    if(fiscalYear?.baselineMetrics?.items?.find(item => item.id === segementOnLoad)){
      form.setFieldsValue({
       baselineMetricID:SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID
     });
    }
     else {
      form.setFieldsValue({
        baselineMetricID:selectedbaselineMetrics
      });
    }

    }
     
  }, [SDRCalculatorValues])
  
  useEffect(() => {
      // form.setFieldsValue({
      //   fiscalYearID:planningPeriod
      // });

      let selectedYear = new Date().getFullYear()
      if(SDRCalculatorValues?.listSDRCapacityCalculators?.items?.length > 0) {
        selectedYear = SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.fiscalYearID;
       form.setFieldsValue({
        fiscalYearID:selectedYear
       });
     } else {
        form.setFieldsValue({
          fiscalYearID:selectedYear
        });
      }

      handleFiscalYearChange(Number(selectedYear),  SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.baselineMetricID)

  }, [SDRCalculatorValues])


  useEffect(() => {
      // form.setFieldsValue({
      //   stageID:selectStageTransition
      // });


      let selectedStage = selectStageTransition
      if(SDRCalculatorValues?.listSDRCapacityCalculators?.items?.length > 0) {
        selectedStage = SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.stageID;
        if(funnelStages?.find(item => item.id === selectedStage)){
          form.setFieldsValue({
           stageID:selectedStage
          });
        }
        else{
          // setSelectStageTransition();
          form.setFieldsValue({
            stageID:selectStageTransition
           });
        }
     } else {
        form.setFieldsValue({
          stageID:selectedStage
        });
     }

  }, [selectStageTransition, SDRCalculatorValues])



  useEffect(() => {
    if (funnelStages.length > 0) {

      // let sortingFunnel = funnelStages.sort(sortingOrder);
      // sortingFunnel = moveIsLastStageToLast(sortingFunnel);
      // let stageData = getChartData(sortingFunnel);
      // setStages(stageData);
      // const matchStage = stageData?.find(
      //   (item) => item?.id === stageData?.[0]?.id
      // );
      // const matchStageIndex = stageData?.findIndex(
      //   (item) => item?.id === stageData?.[0]?.id
      // );
      // const nextStage = stageData?.[matchStageIndex + 1];
      // setSelectStageTransition(matchStage?.id);
      // // setStartStage(matchStage);
      // // setNextStage(nextStage);

      // setRemainingStage(stageData?.slice(matchStageIndex+1))
      // setRemainingFunnelStage(funnelStages?.slice(matchStageIndex+1))

      //1. Total Weeks in Period
      let weeklyTotalStartStageTemp = (startStage?.value / totalWeekValue)?.toFixed(1)
      setWeeklyTotalStartStage(weeklyTotalStartStageTemp);
      setWeeklyTotalNextStage((nextStage?.value / totalWeekValue)?.toFixed(1));

      //2. Targeted no. of Calls
      setWeeklyTotalCallTargetStartStage((parseFloat(weeklyTotalStartStageTemp)*targetCallStartStage).toFixed(1))
      //3. Daily Call Capacity per SDR
      setWeeklyCallCapacityPerSDR(dailyCallCapacity*5)
      setSDRNeededtoAchieveTargetedTouches(((weeklyTotalStartStageTemp*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2));
      let weeklyTotalCallTemp = (dailyCallCapacity*5)*Math.ceil(((weeklyTotalStartStageTemp*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))
      setWeeklyTotalCalls(weeklyTotalCallTemp)
      let dailyTotalCallTemp = (dailyCallCapacity)*Math.ceil(((weeklyTotalStartStageTemp*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))
      setDailyTotalCalls(dailyTotalCallTemp)
      setAnnualTotalCalls((dailyCallCapacity*5)*Math.ceil(((weeklyTotalStartStageTemp*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))*totalWeekValue)
      setMonthlyTotalCalls((((dailyCallCapacity*5)*Math.ceil(((weeklyTotalStartStageTemp*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))*totalWeekValue)/12)?.toFixed(1));
      //4. Estimated Connect Rate
      let weeklyTotalConnectTemp = weeklyTotalCallTemp*((estimatedConnectRate/100));
      setWeeklyTotalConnects(weeklyTotalConnectTemp)
      setDailyTotalConnects(dailyTotalCallTemp*((estimatedConnectRate/100)))
      setAnnualTotalConnects((weeklyTotalCallTemp*((estimatedConnectRate/100)))*totalWeekValue)
      setMonthlyTotalConnects(((weeklyTotalCallTemp*((estimatedConnectRate/100)))*totalWeekValue/12).toFixed(1))
      // 5. Connect to Next Stage Rate
      setWeeklyExpected(weeklyTotalConnectTemp*(connectToNextStageRate/100))
      setDailyExpected(dailyTotalCallTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))
      setAnnualExpected((weeklyTotalConnectTemp*(connectToNextStageRate/100))*totalWeekValue)
      setMonthlyExpected((((weeklyTotalConnectTemp*(connectToNextStageRate/100))*totalWeekValue)/12)?.toFixed(1))

        // 6. Next Stage No Show Rate
        setWeeklyAttained((weeklyTotalConnectTemp*(connectToNextStageRate/100))*(1-(noShowRate/100)))
        setDailyAttained(((dailyTotalConnects*(connectToNextStageRate/100))*(1-(noShowRate/100)))?.toFixed(1))
        setAnnualAttained(((weeklyTotalConnectTemp*(connectToNextStageRate/100))*totalWeekValue*(1-(noShowRate/100)))?.toFixed(1))
        setMonthlyAttained((((weeklyTotalConnectTemp*(connectToNextStageRate/100))*totalWeekValue*(1-(noShowRate/100)))/12)?.toFixed(1))

    }
  }, [funnelStages, totalWeekValue, targetCallStartStage, dailyCallCapacity, estimatedConnectRate, connectToNextStageRate, noShowRate,startStage]);


  const handleStageTransitionChange = (value) => {
    const matchStage = stages?.find((item) => item?.id === value);
    const matchStageIndex = stages?.findIndex((item) => item?.id === value);
    const nextStage = stages?.[matchStageIndex + 1];
    setStartStage(matchStage);
    setNextStage(nextStage);

    setRemainingStage(stages?.slice(matchStageIndex+1))
    setRemainingFunnelStage(funnelStages?.slice(matchStageIndex+1))


    //1. Total Weeks in Period
    setWeeklyTotalStartStage((matchStage?.value / totalWeekValue)?.toFixed(1));
    setWeeklyTotalNextStage((nextStage?.value / totalWeekValue)?.toFixed(1));
    let WeeklyTotalStartStage = (matchStage?.value / totalWeekValue)?.toFixed(1)

    //2.Targeted no. of Calls
    setWeeklyTotalCallTargetStartStage((WeeklyTotalStartStage*targetCallStartStage).toFixed(1))

     //3. Daily Call Capacity per SDR
     setWeeklyCallCapacityPerSDR(dailyCallCapacity*5)
     setSDRNeededtoAchieveTargetedTouches(((WeeklyTotalStartStage*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2));
     let weeklyTotalCallsTemp = (dailyCallCapacity*5)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))
     setWeeklyTotalCalls(weeklyTotalCallsTemp);
     let dailyTotalCallTemp = (dailyCallCapacity)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))
     setDailyTotalCalls(dailyTotalCallTemp);
     setAnnualTotalCalls((dailyCallCapacity*5)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))*totalWeekValue)
     setMonthlyTotalCalls((((dailyCallCapacity*5)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(dailyCallCapacity*5))?.toFixed(2))*totalWeekValue)/12)?.toFixed(1));

      //4. Estimated Connect Rate
      setWeeklyTotalConnects(weeklyTotalCallsTemp*((estimatedConnectRate/100)))
      setDailyTotalConnects(dailyTotalCallTemp*((estimatedConnectRate/100)))
      setAnnualTotalConnects((weeklyTotalCallsTemp*((estimatedConnectRate/100)))*totalWeekValue)
      setMonthlyTotalConnects(((weeklyTotalCallsTemp*((estimatedConnectRate/100)))*totalWeekValue/12).toFixed(1))

      // 5. Connect to Next Stage Rate
      setWeeklyExpected(weeklyTotalCallsTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))
      setDailyExpected(dailyTotalCallTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))
      setAnnualExpected((weeklyTotalCallsTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))*totalWeekValue)
      setMonthlyExpected((((weeklyTotalCallsTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))*totalWeekValue)/12)?.toFixed(1))

      // 6. Next Stage No Show Rate
      setWeeklyAttained(weeklyTotalCallsTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100)*(1-(noShowRate/100)))
      setDailyAttained((dailyTotalCallTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100)*(1-(noShowRate/100)))?.toFixed(1))
      setAnnualAttained(((weeklyTotalCallsTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))*totalWeekValue*(1-(noShowRate/100)))?.toFixed(1))
      setMonthlyAttained((((weeklyTotalCallsTemp*((estimatedConnectRate/100))*(connectToNextStageRate/100))*totalWeekValue*(1-(noShowRate/100)))/12)?.toFixed(1))


  };

  //1. Total Weeks in Period
  const handleOnChangetotalWeek = (event) => {
    setHasUnsavedChanges(true)
    setTotalWeekValue(event.target.value);
    setWeeklyTotalStartStage(
      (startStage?.value / event.target.value)?.toFixed(1)
    );
    setWeeklyTotalNextStage(
      (nextStage?.value / event.target.value)?.toFixed(1)
    );
    setAnnualTotalCalls(weeklyTotalCalls*event.target.value)

    setAnnualExpected(weeklyExpected*event.target.value)

  };
  //2. Targeted no. of Calls
  const handleOnChangeWeeklyTotalCall = (event) => {
    setHasUnsavedChanges(true)

    setTargetCallStartStage(event.target.value);
    setWeeklyTotalCallTargetStartStage((WeeklyTotalStartStage*event.target.value).toFixed(1))
  };
  //3. Daily Call Capacity per SDR
  const handleDailyCallCapacity = (event) => {
    setHasUnsavedChanges(true)

    setDailyCallCapacity(event.target.value);
    setWeeklyCallCapacityPerSDR(event.target.value*5)  
    setSDRNeededtoAchieveTargetedTouches(((WeeklyTotalStartStage*targetCallStartStage)/(event.target.value*5))?.toFixed(2));
    setWeeklyTotalCalls((event.target.value*5)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(event.target.value*5))?.toFixed(2)));
    setDailyTotalCalls((event.target.value)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(event.target.value*5))?.toFixed(2)));
    setAnnualTotalCalls((event.target.value*5)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(event.target.value*5))?.toFixed(2))*totalWeekValue)
    setMonthlyTotalCalls((((event.target.value*5)*Math.ceil(((WeeklyTotalStartStage*targetCallStartStage)/(event.target.value*5))?.toFixed(2))*totalWeekValue)/12)?.toFixed(1));
};
 //4. Estimated Connect Rate
 const handleEstimatedConnectRate = (value) => {
  setHasUnsavedChanges(true)

    setEstimatedConnectRate(value);
    setWeeklyTotalConnects(weeklyTotalCalls*(((value/100))))
    setDailyTotalConnects(dailyTotalCalls*((value/100)))
    setAnnualTotalConnects((weeklyTotalCalls*((value/100)))*totalWeekValue);
    setMonthlyTotalConnects((((weeklyTotalCalls*((value/100)))*totalWeekValue)/12).toFixed(1))
 }

 //5. Connect to Next Stage Rate
 const handleConnectNextStageRate = (value) => {
  setHasUnsavedChanges(true)

  setConnectToNextStageRate(value)
  setWeeklyExpected(((weeklyTotalConnects)*(value/100)).toFixed(1))
  setDailyExpected(((dailyTotalConnects)*(value/100)).toFixed(1))
  setAnnualExpected(((weeklyTotalConnects)*(value/100)).toFixed(1))
  setMonthlyExpected(((((weeklyTotalConnects)*(value/100)).toFixed(1))/12)?.toFixed(1))
  
 }

   // 6. Next Stage No Show Rate
    const handleNoShowRate = (value) => {
    setHasUnsavedChanges(true)

      setNoShowRate(value)
      setWeeklyAttained(weeklyExpected*(1-(value/100)))
      setDailyAttained((dailyExpected*(1-(value/100)))?.toFixed(1))
      setAnnualAttained((annualExpected*(1-(value/100)))?.toFixed(1))
      setMonthlyAttained(((annualExpected*(1-(value/100)))/12)?.toFixed(1))
    }

  const [basedOnInput, setBasedOnInput] = useState()
  useEffect(() => {
    let remainingStagesValues = [];
    remainingFunnelStages?.forEach((element, index) => {
      let val = "";
      if(index === 0) {
        val = annualAttained/Math.ceil(SDRNeededtoAchieveTargetedTouches)
      } else {
        val = remainingStagesValues?.[index-1]?.value * (1-(element?.conversion/100))
      }
      remainingStagesValues.push({name: element?.salesAndMarketingAlignment?.name, value: val?.toFixed(1) })
    });

    let lastStageValue = remainingStagesValues?.[remainingStagesValues?.length-1]?.value
    let revenue = averageDealSize*parseFloat(lastStageValue)
    remainingStagesValues.push({name:"revenue", value: revenue?.toFixed(0) })
    setBasedOnInput(remainingStagesValues)
   
  }, [remainingStages])

  const onSubmit = () => {
    TrackClickFunction('demand-gen-sales-and-marketing-sdr-capacity-calculator-save-button');
  
    form
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
          account: account[0],
        };
        
        handleOk(formatedValues);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleOk = (value) => {

    if(SDRCalculatorValues?.listSDRCapacityCalculators?.items?.length > 0) {
      value.id = SDRCalculatorValues?.listSDRCapacityCalculators?.items?.[0]?.id

      dispatch(updateSDRCalculatorData(value));
    setHasUnsavedChanges(false)

   } else {
    dispatch(saveSDRCalculatorData(value));
    setHasUnsavedChanges(false)

   }
  };

  useEffect(()=>{
    let dataObj = {}
    basedOnInput?.map(item => {
      dataObj[item?.name] = item?.value
    })
    dispatch(SDRCalculatorSummary(dataObj))
  },[basedOnInput])

  const annualGoalHitYes = (
    <div>
      <CheckCircleOutlined style={{ color: '#00FF00' }} /> <span style={{paddingLeft:3}}>Yes</span> 
    </div>
  );
  const annualGoalHitNo = (
    <div>
       <CloseCircleOutlined style={{ color: '#FF0000' }} /> <span style={{paddingLeft:3}}>No. You may need additional SDRs to achieve your goal </span>
    </div>
  );

  const [hitGoal, setHitGoal] = useState()
  useEffect(() => {

    if(parseFloat(annualAttained) >= parseFloat(nextStage?.value)) {
      setHitGoal(annualGoalHitYes)
    } else {
      setHitGoal(annualGoalHitNo)
    }

  }, [annualAttained, nextStage?.value])

  


  return (
    
    showSkeleton ? <>
    <SdrCapacityCalculatorSkeleton />
    </>:
    <>
    <Form layout="vertical"  
    form={form}
    requiredMark={false}
    name="sdr_calculator"
    className="general-form"  
    >
      <Row justify="center" style={{ padding: "10px 10px 0 10px", marginTop:'20px' }}>
        <Col span={18} lg={18} md={18} sm={24} xs={24}>
          
            <Row gutter={10} className="fields" style={{marginTop:10}}>
              <Col span={12} lg={12} md={12} sm={24} xs={24}>
             <Form.Item
                  label={
                    <>
                      <h5 style={{marginBottom:0, fontWeight:700}}>Select Year</h5>
                      {isLoading && <Loading3QuartersOutlined  spin style={{fontSize:18, float:"right", marginTop:5, marginRight:8}} />}
                    </>
                  }
                  name = "fiscalYearID"
                >
                  <Select
                    style={{ width: "100%" }}
                    // initialValue={planningPeriod}
                    // value={planningPeriod}
                    onChange={handleYearChange}
                    // size="large"
                    className="fiscal-year-select general-input-select"
                  >
                    {dropDownYearList &&
                      dropDownYearList.length > 0 &&
                      dropDownYearList.map((item, index) => {
                        return (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                 label={
                  <div className="title">
                    <h5>
                     Select Segment
                    </h5>
                    <Popover
                      placement="top"
                      content="Select existing segment from your Revenue Plan"
                    >
                      <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                    </Popover>
                  </div>
                }
                  name = "baselineMetricID"
                  
                >
                  <Select
                    value={selectedbaselineMetrics}
                    onChange={handleBaselineMeticsChange}
                    // size="large"
                    style={{ width: "100%" }}
                    placeholder="Select Segment Here"
                    className="general-input-select"
                  >
                    {fiscalYear?.baselineMetrics?.items &&
                      fiscalYear?.baselineMetrics?.items.length > 0 &&
                      fiscalYear?.baselineMetrics?.items.map((item, index) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                      })}
                  </Select>
                </Form.Item>
              </Col>
             
            </Row>

            {fiscalYear?.baselineMetrics?.items.length > 0 && <Row gutter={10} className="fields" style={{marginTop:0}}>
              {" "}
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  label={
                    <div className="title">
                      <h5>
                      Select Stage Transition
                      </h5>
                      <Popover
                        placement="top"
                        content="Select what your SDRs will be working (i.e. Leads, MQLs, etc)"
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }
                  name="stageID"
                >
                  <Select
                    style={{ width: "100%" }}
                    onChange={handleStageTransitionChange}
                    value={selectStageTransition}
                    placeholder="Select Stage Transition"
                    className="general-input-select"
                  >
                    {funnelStages.sort(sortingOrder) &&
                      funnelStages.sort(sortingOrder)?.length > 0 &&
                      funnelStages.sort(sortingOrder)?.map((value, index) => {
                        if (
                          index < funnelStages.sort(sortingOrder)?.length - 1 &&
                          value.id
                        )
                          return (
                            <Option key={value.id} value={value.id}>
                              {`${value.salesAndMarketingAlignment?.name} to ${stringCapitalize(
                                funnelStages.sort(sortingOrder)?.[index + 1]
                                  ?.salesAndMarketingAlignment?.name
                              )} `}
                            </Option>
                          );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>}
        
          {fiscalYear?.baselineMetrics?.items.length > 0 && <div>
          

          <Row
            gutter={16}
            
          >
            <Col
              span={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              
            >
              <div className="panel-design" style={{width:'100%', padding:'40px 15px'}}>
              {/* <img src={mikeIcon} style={{ marginRight: 10 }} alt='' /> */}
              <h3 style={{ marginBottom: 0, fontWeight: 500, fontSize:'24px', color:"#1D2939", textAlign:'center' }}>
                {startStage?.text}
                <br />
                <span style={{ color: "#7F56D9", fontWeight:700, display:"block", marginTop:"15px" }}>{startStage?.value?.toLocaleString()}</span>
               </h3>
               </div>
            </Col>

            <Col
              span={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              
            >
              <div className="panel-design" style={{width:'100%', padding:'40px 15px'}}>
              {/* <img src={handsIcon} style={{ marginRight: 10 }} alt=""/> */}
              <h3 style={{ marginBottom: 0, fontWeight: 500, fontSize:'24px', color:"#1D2939", textAlign:'center' }}>
                {nextStage?.text}
                <br />
                <span style={{ color: "#F04438", fontWeight:700, display:"block", marginTop:"15px"  }}>{nextStage?.value?.toLocaleString()}</span>
              </h3>
              </div>
            </Col>
          </Row>

          <div >
            <Row className="fields">
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                name = "totalWeeksInPeriod"
                  label={
                    <div className="title">
                      <h5>
                        <span style={{color:"#7F56D9", display:"inline-block", marginRight:'5px', fontWeight:700}}>1.</span> Total Weeks in Period
                      </h5>
                      <Popover
                        placement="top"
                        content="Set how many weeks during the selected year that you expect your SDRs to be active"
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "This field can not be empty!",
                    },
                    {
                      pattern: /^(?:[1-9]|[1-4][0-9]|5[0-2])$/,
                      message: "This value should be between 1 to 52",
                    },
                  ]}
                >
                  <Input
                    value={totalWeekValue}
                    onChange={handleOnChangetotalWeek}
                    className="general-input"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="table">
              <Col
                span={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Col
                  span={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}
                  style={{ display: "flex" }}
                >
                  <div className="title">
                    <span>{`Weekly Total ${startStage?.text} to Work`}</span>
                  </div>
                </Col>
                <Col
                  span={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}
                  style={{ display: "flex" }}
                >
                  <div className="data">
                    <h4 >{isFinite(WeeklyTotalStartStage) ? numberWithCommas(WeeklyTotalStartStage?.toLocaleString()) : 0}</h4>
                    {/* <Popover
                      placement="top"
                      content="Display hint text to be shown here"
                    >
                      <Button
                        type="text"
                        style={{
                          fontStyle: "italic",
                          fontFamily: "cursive",
                          color: "#A9A9A9",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        i
                      </Button>
                    </Popover> */}
                  </div>
                </Col>
              </Col>
              <Col
                span={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <Col
                  span={15}
                  lg={15}
                  md={15}
                  sm={24}
                  xs={24}
                  style={{ display: "flex" }}
                >
                  <div className="title">
                    <p>{`Weekly Total ${nextStage?.text} Needed`}</p>
                  </div>
                </Col>
                <Col
                  span={9}
                  lg={9}
                  md={9}
                  sm={24}
                  xs={24}
                  style={{ display: "flex" }}
                >
                  <div className="data">
                    <h4>{isFinite(WeeklyTotalNextStage) ? numberWithCommas(WeeklyTotalNextStage?.toLocaleString()) : 0} </h4>
                    {/* <Popover
                      placement="top"
                      content="Display hint text to be shown here"
                    >
                      <Button
                        type="text"
                        style={{
                          fontStyle: "italic",
                          fontFamily: "cursive",
                          color: "#A9A9A9",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        i
                      </Button>
                    </Popover> */}
                  </div>
                </Col>
              </Col>
            </Row>

            <Row className="fields">
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                name = "targetNoOfCalls"
                  label={
                    <div className="title">
                      <h5>
                        <span style={{color:"#7F56D9", display:"inline-block", marginRight:'5px', fontWeight:700}}>2.</span> {`Targeted number of Activities per ${startStage?.text}`}
                      </h5>
                      <Popover
                        placement="top"
                        content="Set how many estimated activities (i.e. calls, emails, etc) are needed to get a response from the given stakeholder. At least 6 activities are recommended as a best practice."
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "This field can not be empty!",
                    },
                  ]}
                >
                  <Input value={targetCallStartStage} onChange={handleOnChangeWeeklyTotalCall} className="general-input"/>
                </Form.Item>
              </Col>
            </Row>

            <Row className="table">
              {/* {Field2.map((item, index) => {
                return ( */}
                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Weekly Total Activities Target</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(WeeklyTotalCallTargetStartStage) ? numberWithCommas(WeeklyTotalCallTargetStartStage?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>
                {/* );
              })} */}
            </Row>

            <Row className="fields">
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                name = "dailyCallCApacity"
                  label={
                    <div className="title">
                      <h5>
                        <span style={{color:"#7F56D9", display:"inline-block", marginRight:'5px', fontWeight:700}}>3.</span> Daily Activities Capacity per SDR
                      </h5>
                      <Popover
                        placement="top"
                        content="Set how many calls/emails per day, on average, that your SDRs can make."
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "This field can not be empty!",
                    }
                  ]}
                >
                  <Input value={dailyCallCapacity} onChange={handleDailyCallCapacity} className="general-input"/>
                </Form.Item>
              </Col>
            </Row>

            <Row className="table">
                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Weekly Total Activities</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(weeklyTotalCalls) ? numberWithCommas(weeklyTotalCalls?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Daily Total Activities</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{ isFinite(dailyTotalCalls) ? numberWithCommas(dailyTotalCalls?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Annual Total Activities</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(annualTotalCalls) ? numberWithCommas(annualTotalCalls?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Monthly Total Activities</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(monthlyTotalCalls) ? numberWithCommas(monthlyTotalCalls?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>
               
            </Row>

            <Row className="table">
            
            <Col
              span={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Col
                span={15}
                lg={15}
                md={15}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <div className="title">
                  <p>Weekly Activities Capacity per SDR</p>
                </div>
              </Col>
              <Col
                span={9}
                lg={9}
                md={9}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <div className="data">
                  <h4>{isFinite(weeklyCallCapacityPerSDR) ? numberWithCommas(weeklyCallCapacityPerSDR?.toLocaleString()) : 0 }</h4>
                  {/* <Popover
                    placement="top"
                    content="Display hint text to be shown here"
                  >
                    <Button
                      type="text"
                      style={{
                        fontStyle: "italic",
                        fontFamily: "cursive",
                        color: "#A9A9A9",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      i
                    </Button>
                  </Popover> */}
                </div>
              </Col>
            </Col>

            <Col
              span={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Col
                span={15}
                lg={15}
                md={15}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <div className="title">
                  <p>Number of SDRs Needed to Achieve Targeted Touches</p>
                </div>
              </Col>
              <Col
                span={9}
                lg={9}
                md={9}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <div className="data">
                  <h4>{isFinite(SDRNeededtoAchieveTargetedTouches) ? numberWithCommas(SDRNeededtoAchieveTargetedTouches?.toLocaleString()) : 0 }</h4>
                  {/* <Popover
                    placement="top"
                    content="Display hint text to be shown here"
                  >
                    <Button
                      type="text"
                      style={{
                        fontStyle: "italic",
                        fontFamily: "cursive",
                        color: "#A9A9A9",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      i
                    </Button>
                  </Popover> */}
                </div>
              </Col>
            </Col>

            <Col
              span={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Col
                span={15}
                lg={15}
                md={15}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <div className="title" style={{fontWeight:"bold"}}>
                  <p>Number of SDRs Needed (rounded up)</p>
                </div>
              </Col>
              <Col
                span={9}
                lg={9}
                md={9}
                sm={24}
                xs={24}
                style={{ display: "flex" }}
              >
                <div className="data" style={{fontWeight:"bold"}}>
                  <h4>{isFinite(Math.ceil(SDRNeededtoAchieveTargetedTouches)) ? numberWithCommas(Math.ceil(SDRNeededtoAchieveTargetedTouches)?.toLocaleString()) : 0 }</h4>
                  <Popover
                    placement="top"
                    content="This is the estimated number of SDRs you will need to achieve your targeted touches "
                  >
                    <Button
                      type="text"
                      style={{
                        fontStyle: "italic",
                        fontFamily: "cursive",
                        color: "#A9A9A9",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      i
                    </Button>
                  </Popover>
                </div>
              </Col>
            </Col>

          

          

          
         
      </Row>

            <Row className="fields">
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                name = "estimatedConnectRate"
                  label={
                    <div className="title">
                      <h5>
                        <span style={{color:"#7F56D9", display:"inline-block", marginRight:'5px', fontWeight:700}}>4.</span> Estimated Connect Rate
                      </h5>
                      <Popover
                        placement="top"
                        content="Set the estimated rate at which your calls/emails are answered (including voicemails)."
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }

                  rules={[
                    {
                      required: true,
                      message: "This field can not be empty!",
                    },
                    {
                      pattern: /^[1-9][0-9]?$|^100$/,
                      message: "This value should be between 1 to 100",
                    },
                  ]}
                  
                >
                <InputNumber
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                value={estimatedConnectRate} 
                onChange={(value)=>handleEstimatedConnectRate(value)}
                
                className="general-input"
              />
                </Form.Item>
              </Col>
            </Row>

            <Row className="table">
              
                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Weekly Total Connects</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(weeklyTotalConnects)?.toFixed(1)) ? numberWithCommas(parseFloat(weeklyTotalConnects)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Daily Total Connects</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(dailyTotalConnects)?.toFixed(1)) ? numberWithCommas(parseFloat(dailyTotalConnects)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Annual Total Connects</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(annualTotalConnects)?.toFixed(1)) ? numberWithCommas(parseFloat(annualTotalConnects)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>Monthly Total Connects</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(monthlyTotalConnects) ? numberWithCommas(monthlyTotalConnects?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>
               
            </Row>

            <Row className="fields">
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                name="connectToMarketingQualifiedLeadRate"
                  label={
                    <div className="title">
                      <h5>
                        <span style={{color:"#7F56D9", display:"inline-block", marginRight:'5px', fontWeight:700}}>5.</span> {`Connect to ${nextStage?.text} Rate`}
                      </h5>
                      <Popover
                        placement="top"
                        content="Set the estimated rate in which your connects deliver the needed outcome"
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "This field can not be empty!",
                    },
                    {
                      pattern: /^[1-9][0-9]?$|^100$/,
                      message: "This value should be between 1 to 100",
                    },
                  ]}
                >
                  {/* <Input value={connectToNextStageRate} onChange={handleConnectNextStageRate} /> */}
                  <InputNumber
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    value={connectToNextStageRate} 
                    onChange={(value)=>handleConnectNextStageRate(value)}
                    className="general-input"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="table">
                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p> {`Weekly Expected ${nextStage?.text}`} </p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(weeklyExpected)?.toFixed(1)) ? numberWithCommas(parseFloat(weeklyExpected)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>  {`Daily Expected ${nextStage?.text}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(dailyExpected)?.toFixed(1)) ? numberWithCommas(parseFloat(dailyExpected)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p> {`Annual Expected ${nextStage?.text}`} </p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(annualExpected)?.toFixed(1)) ? numberWithCommas(parseFloat(annualExpected)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p>{`Monthly Expected  ${nextStage?.text}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(monthlyExpected) ? numberWithCommas(monthlyExpected?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>
            </Row>

            <Row className="fields">
              <Col span={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                name = "marketingQualifiedLeadToShowRate"
                  label={
                    <div className="title">
                      <h5>
                        <span style={{color:"#7F56D9", display:"inline-block", marginRight:'5px', fontWeight:700}}>6.</span> {`${nextStage?.text} No Show Rate`}
                      </h5>
                      <Popover
                        placement="top"
                        content="Set your estimated no show rate if you know it, otherwise start with 20% and adjust as you begin to track it."
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}}/>
                      </Popover>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "This field can not be empty!",
                    },
                    {
                      pattern: /^[1-9][0-9]?$|^100$/,
                      message: "This value should be between 1 to 100",
                    },
                  ]}
                >
                  {/* <Input value={noShowRate} onChange={handleNoShowRate} /> */}
                  <InputNumber
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    value={noShowRate} 
                    onChange={(value)=>handleNoShowRate(value)}
                    className="general-input"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="table">
            
                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p> {`Weekly Attained  ${nextStage?.text}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(parseFloat(weeklyAttained)?.toFixed(1)) ? numberWithCommas(parseFloat(weeklyAttained)?.toFixed(1)?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p> {`Daily Attained  ${nextStage?.text}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(dailyAttained) ? numberWithCommas(dailyAttained?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p> {`Annual Attained  ${nextStage?.text}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(annualAttained) ? numberWithCommas(annualAttained?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>

                  <Col
                    span={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Col
                      span={15}
                      lg={15}
                      md={15}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="title">
                        <p> {`Monthly Attained  ${nextStage?.text}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={9}
                      lg={9}
                      md={9}
                      sm={24}
                      xs={24}
                      style={{ display: "flex" }}
                    >
                      <div className="data">
                        <h4>{isFinite(monthlyAttained) ? numberWithCommas(monthlyAttained?.toLocaleString()) : 0 }</h4>
                        {/* <Popover
                          placement="top"
                          content="Display hint text to be shown here"
                        >
                          <Button
                            type="text"
                            style={{
                              fontStyle: "italic",
                              fontFamily: "cursive",
                              color: "#A9A9A9",
                              fontSize: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            i
                          </Button>
                        </Popover> */}
                      </div>
                    </Col>
                  </Col>
               
            </Row>

            <Button className="primaryButton" type="primary" onClick={onSubmit} loading = {isLoading} data-heap="demand-gen-sales-and-marketing-sdr-capacity-calculator-save-button"> 
              <strong>Save</strong>
            </Button>
            {HasUnsavedChanges &&
                  <span className="conversation-difference-dot" style={{ opacity: 1, marginLeft:12 }}></span>
            }
          </div>

          <Divider />

          <div style={{display:"flex"}}>
            <div>
            <h3 style={{ color: "#12B76A",fontSize:"16px", fontWeight: 700 }}>
            {`Based on your inputs, will you hit your Annual ${nextStage?.text} goal?`} 
          </h3>
            </div>
            <div style={{color:"#0f61db", paddingLeft:5, fontSize:17}}>
            <Popover
                        placement="top"
                        content="This describes whether or not the number of SDRs needed for your targeted activities is also sufficient to hit your desired outcomes (i.e. SQL or meeting goals)."
                        
                      >
                        <InfoCircleOutlined style={{color:'#7F56D9'}} />
                </Popover>
            </div>
          </div>

          
          {<Row className="table">
                <Col
                  span={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Col
                    span={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ display: "flex" }}
                  > 
                    <div className="title">
                      <p>
                        {hitGoal}
                      </p>
                    </div>
                  </Col>
                </Col>
          </Row>}

          <Divider />

<h3 style={{ color: "#12B76A",fontSize:"16px", fontWeight: 700 }}>
Based on your inputs here, each SDR can annually help to
</h3>
<Row className="table">
  {basedOnInput?.map((item, index) => {
    return (
      <Col
        key={index}
        span={12}
        lg={12}
        md={12}
        sm={24}
        xs={24}
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <Col
          span={15}
          lg={15}
          md={15}
          sm={24}
          xs={24}
          style={{ display: "flex" }}
        >
          <div className="title">
            <p>
              <strong>{`Deliver this ${basedOnInput?.length === index+1?"much":"many"} ${item?.name}s`}</strong>
            </p>
          </div>
        </Col>
        <Col
          span={9}
          lg={9}
          md={9}
          sm={24}
          xs={24}
          style={{ display: "flex" }}
        >
          <div className="data">
            <h4 style={{ color: "#7F56D9" }}>{`${basedOnInput?.length === index+1 ? isNaN(parseInt(item?.value))? "$"+ 0 : "$" + Math.round(item?.value)?.toLocaleString() : isNaN(item?.value) ? 0 : Math.round(item?.value).toLocaleString()}`}</h4>
            {/* <Popover
              placement="top"
              content="Display hint text to be shown here"
            >
              <Button
                type="text"
                style={{
                  fontStyle: "italic",
                  fontFamily: "cursive",
                  color: "#A9A9A9",
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                i
              </Button>
            </Popover> */}
          </div>
        </Col>
      </Col>
    );
  })}
</Row>

          </div>}
        </Col>
      </Row>
    </Form>
    </>
  );
}
