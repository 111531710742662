/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Col, Row, Form, InputNumber, Skeleton, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { numberWithCommas } from 'utility/context/CommonMethods';
import { updateBudget } from 'store/actions/budgetAction';
import ResetIcon from 'assets/Images/reset_icon.svg';
import SaveIcon from 'assets/Images/save_icon.svg';

const BudgetPlanning = (props) => {
  const { getBudgetLoader, nameOfLeadObj } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const account = useSelector((state) => state.loginReducer.groups);

  const [quarterlyRevenueGoal, setquarterlyRevenueGoal] = useState({
    budget_q1: 0,
    budget_q2: 0,
    budget_q3: 0,
    budget_q4: 0,
  });
  useEffect(() => {
    // get allocated budget and set it per quarter
    let budgetAllocation = props.BudgetProfile?.budget_allocation
      ? JSON.parse(props.BudgetProfile?.budget_allocation)
      : '';
    form.setFieldsValue({
      budget_name: props.BudgetProfile?.name,
      budget_q1: budgetAllocation?.budget_q1,
      budget_q2: budgetAllocation?.budget_q2,
      budget_q3: budgetAllocation?.budget_q3,
      budget_q4: budgetAllocation?.budget_q4,
    });
    setTotalBudgetUpdated(props.BudgetProfile?.budget);
  }, [props.BudgetProfile]); // eslint-disable-line

  useEffect(() => {
    setquarterlyRevenueGoal(props.quarterlyRevenueGoal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.quarterlyRevenueGoal]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formatedValues = {
          ...values,
          account: account[0],
          id: props.BudgetProfile?.id,
          name: values['budget_name'],
          budget:
            parseInt(values['budget_q1']) +
            parseInt(values['budget_q2']) +
            parseInt(values['budget_q3']) +
            parseInt(values['budget_q4']),
          budget_allocation: JSON.stringify({
            budget_q1: values['budget_q1'],
            budget_q2: values['budget_q2'],
            budget_q3: values['budget_q3'],
            budget_q4: values['budget_q4'],
          }),
        };
        delete formatedValues['budget_name'];
        delete formatedValues['budget_q1'];
        delete formatedValues['budget_q2'];
        delete formatedValues['budget_q3'];
        delete formatedValues['budget_q4'];
        handleOk(formatedValues);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleReset = () => {
    let budgetAllocation = props.BudgetProfile?.budget_allocation
      ? JSON.parse(props.BudgetProfile?.budget_allocation)
      : '';
    form.setFieldsValue({
      budget_name: props.BudgetProfile?.name,
      budget_q1: budgetAllocation?.budget_q1,
      budget_q2: budgetAllocation?.budget_q2,
      budget_q3: budgetAllocation?.budget_q3,
      budget_q4: budgetAllocation?.budget_q4,
    });
    setQ1Diff({
      budget_q1: 0,
      budget_q2: 0,
      budget_q3: 0,
      budget_q4: 0,
    });
  };

  const handleOk = (value) => {
    dispatch(updateBudget(value))
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    setQ1Diff({
      budget_q1: 0,
      budget_q2: 0,
      budget_q3: 0,
      budget_q4: 0,
    });
  };

  const [q1Diff, setQ1Diff] = useState({
    budget_q1: 0,
    budget_q2: 0,
    budget_q3: 0,
    budget_q4: 0,
  });

  const handleBudgetQ1 = (e) => {
    const value = form.getFieldValue(e.target.name)?.toString();
    setQ1Diff({
      ...q1Diff,
      [e.target.name]:
        parseInt(value) -
        (props.BudgetProfile?.budget_allocation
          ? JSON.parse(props.BudgetProfile?.budget_allocation)?.[e.target.name]
          : ''),
    });
  };

  const [totalBudgetUpdated, setTotalBudgetUpdated] = useState(
    props.BudgetProfile?.budget
  );

  useEffect(() => {
    setTotalBudgetUpdated(
      parseInt(form.getFieldsValue()?.budget_q1) +
        parseInt(form.getFieldsValue()?.budget_q2) +
        parseInt(form.getFieldsValue()?.budget_q3) +
        parseInt(form.getFieldsValue()?.budget_q4)
    );
    setQ1Diff(q1Diff);
    // keep commented if need to show case difference flag in future.
    // if (
    //   q1Diff.budget_q1 !== 0 ||
    //   q1Diff.budget_q2 !== 0 ||
    //   q1Diff.budget_q3 !== 0 ||
    //   q1Diff.budget_q4 !== 0
    // )
    //   setUnSavedFlag(true);
    // else setUnSavedFlag(false);
  }, [q1Diff]); // eslint-disable-line

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="quarterly_budget"
        initialValues={{
          modifier: 'public',
          budget_name: props.BudgetProfile?.name,
          budget_q1: props.BudgetProfile?.budget_allocation
            ? JSON.parse(props.BudgetProfile?.budget_allocation)?.budget_q1
            : 0,
          budget_q2: props.BudgetProfile?.budget_allocation
            ? JSON.parse(props.BudgetProfile?.budget_allocation)?.budget_q2
            : 0,
          budget_q3: props.BudgetProfile?.budget_allocation
            ? JSON.parse(props.BudgetProfile?.budget_allocation)?.budget_q3
            : 0,
          budget_q4: props.BudgetProfile?.budget_allocation
            ? JSON.parse(props.BudgetProfile?.budget_allocation)?.budget_q4
            : 0,
        }}
        requiredMark={false}
      >
        {' '}
        <Row xs={24} md={12} lg={24} gutter={[20, 20]}>
          <Col xs={24} md={24} lg={24} className="budget-planning">
            <Row xs={24} md={24} lg={24} gutter={[12, 12]}>
              <Col
                xs={24}
                md={12}
                lg={12}
                span={12}
                style={{ padidngLeft: '0 !important' }}
              >
                <div
                  style={{
                    padding: '10px 10px',
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                  }}
                >
                  {/* <div style={{ display: "inline-block" }}>
                    <img src={ProspectsGoalsImg} alt="" />
                  </div> */}
                  <div>
                    <div
                      className="budget-card-text"
                      style={{
                        display: 'block',
                        color: '#667085',
                        textAlign: 'left',
                        fontWeight: 500,
                        fontSize: '20px',
                        lineHeight: '32px',
                      }}
                    >
                      {nameOfLeadObj} Goal
                    </div>
                    <div
                      className="budget-card-number-planned"
                      style={{
                        display: 'block',
                        fontSize: '32px',
                        lineHeight: '44px',
                        marginTop: '5px',
                        color: '#1D2939',
                        textAlign: 'right',
                      }}
                    >
                      {numberWithCommas(
                        props.TotalLeadsGoal.budget_q1 +
                          props.TotalLeadsGoal.budget_q2 +
                          props.TotalLeadsGoal.budget_q3 +
                          props.TotalLeadsGoal.budget_q4
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12} span={12}>
                <div
                  style={{
                    padding: '10px 10px',
                    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                  }}
                >
                  {/* <div style={{ display: "inline-block" }}>
                    <img src={RevenueGoalImg} alt="" />
                  </div> */}
                  <div>
                    <div
                      className="budget-card-text"
                      style={{
                        display: 'block',
                        color: '#667085',
                        textAlign: 'left',
                        fontWeight: 500,
                        fontSize: '20px',
                        lineHeight: '32px',
                      }}
                    >
                      Revenue Goal
                    </div>
                    <div
                      className="budget-card-number-achieved"
                      style={{
                        display: 'block',
                        fontSize: '32px',
                        lineHeight: '44px',
                        marginTop: '5px',
                        color: '#1D2939',
                        textAlign: 'right',
                      }}
                    >
                      {!isFinite(
                        quarterlyRevenueGoal.budget_q1 +
                          quarterlyRevenueGoal.budget_q2 +
                          quarterlyRevenueGoal.budget_q3 +
                          quarterlyRevenueGoal.budget_q4
                      )
                        ? 0
                        : `$${numberWithCommas(
                            Number(
                              quarterlyRevenueGoal.budget_q1 +
                                quarterlyRevenueGoal.budget_q2 +
                                quarterlyRevenueGoal.budget_q3 +
                                quarterlyRevenueGoal.budget_q4
                            ).toFixed(0)
                          )}`}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row xs={24} md={12} lg={24}>
              <div
                className="budget-planning-sub-title"
                style={{ color: '#1D2939', fontSize: '13px', fontWeight: 400 }}
              >
                Marketing Sourced Goals
              </div>
              <Tooltip
                placement="top"
                title={
                  'Information in this section is based on your inputs from Revenue Planning'
                }
              >
                <InfoCircleOutlined
                  className="optional_icon"
                  style={{ marginTop: 14, marginLeft: 10, color: '#667085' }}
                />
              </Tooltip>
            </Row>
          </Col>
        </Row>
        <Row xs={24} md={12} lg={24} gutter={[12, 12]}>
          <Col xs={24} md={24} lg={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                className="budget-help-text"
                style={{
                  color: '#1D2939',
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 500,
                }}
              >
                Allocate your annual budget across quarters
              </div>
              <div>
                {props.userPermission?.[
                  'budgetCampaign.budgets.allocateBudget'
                ] !== 'viewonly' &&
                  props.userPermission?.[
                    'budgetCampaign.budgets.allocateBudget'
                  ] !== 'hidden' &&
                  props.userPermission?.['budgetCampaign'] !== 'preview' &&
                  props.userPermission?.['budgetCampaign'] !== 'viewonly' && (
                    <div className="non-print" onClick={handleReset}>
                      {/* <span style={{ paddingRight: 5 }}>
                      <img src={ResetIcon} alt="" />
                    </span> */}
                      <Button
                        className="tertiaryButton"
                        style={{ width: '120px', height: '30px' }}
                      >
                        Reset
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive">
          <div className="wrapper" style={{ fontSize: 18 }}>
            {getBudgetLoader ? (
              <Skeleton active />
            ) : (
              <>
                <Row
                  xs={24}
                  md={12}
                  lg={24}
                  gutter={[10, 10]}
                  className="budget-planning-table-header"
                  style={{
                    fontWeight: '500',
                    color: '#303030',
                    borderRadius: 7,
                    backgroundColor: 'transparent !important',
                    padding: '10px 0',
                    borderBottom: '1px solid #D0D5DD',
                  }}
                >
                  <Col xs={10} md={10} lg={10}>
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#1D2939',
                        lineHeight: '24px',
                        fontWeight: 500,
                        textAlign: 'right',
                      }}
                    >
                      Allocate budget
                    </div>
                  </Col>

                  <Col xs={7} md={7} lg={7}>
                    <div
                      className="tag1"
                      style={{
                        fontSize: '16px',
                        color: '#1D2939',
                        lineHeight: '24px',
                        fontWeight: 500,
                        textAlign: 'right',
                      }}
                    >
                      {nameOfLeadObj} goal
                    </div>
                  </Col>
                  <Col xs={7} md={7} lg={7}>
                    <div
                      className="tag2"
                      style={{
                        fontSize: '16px',
                        color: '#1D2939',
                        lineHeight: '24px',
                        fontWeight: 500,
                        textAlign: 'right',
                      }}
                    >
                      Revenue goal
                    </div>
                  </Col>
                </Row>

                <Row
                  xs={24}
                  md={24}
                  lg={24}
                  gutter={[12, 12]}
                  className="budget-row"
                  style={{ padding: '10px 0' }}
                >
                  <Col
                    xs={10}
                    md={6}
                    lg={10}
                    className="font-budget-header budget-cell"
                  >
                    <div
                      className="budget-planning-allocate-cell"
                      style={{
                        fontSize: '16px',
                        color: '#667085',
                        fontWeight: 700,
                      }}
                    >
                      Q1
                    </div>
                    <div
                      className="budget-planning-allocate-cell"
                      style={{ flex: 1, marginLeft: '35px' }}
                    >
                      <Form.Item
                        name="budget_q1"
                        style={{ marginBottom: 0 }}
                        label=""
                        rules={[
                          {
                            required: true,
                            message: 'This field can not be empty!',
                          },
                          {
                            pattern: /^[0-9.]+$/,
                            message: 'Value should contain just number',
                          },
                          {
                            pattern: /^\d+$/,
                            message: 'Value should not in fraction.',
                          },
                        ]}
                        onChange={handleBudgetQ1}
                      >
                        <InputNumber
                          size="large"
                          name="budget_q1"
                          formatter={(value) => {
                            if (!isNaN(value)) {
                              return `$ ${Math.floor(value)}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              );
                            }
                          }}
                          style={{
                            width: '100%',
                            color: 'black',
                            border: '1px solid #D0D5DD',
                          }}
                          disabled={
                            !(
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'viewonly' &&
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'hidden' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'preview' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'viewonly'
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      {Math.sign(q1Diff.budget_q1) === 1 && (
                        <>
                          <span
                            className="over-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(q1Diff.budget_q1)}
                          </span>{' '}
                        </>
                      )}
                      {Math.sign(q1Diff.budget_q1) === -1 && (
                        <>
                          <span
                            className="under-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(Math.abs(q1Diff.budget_q1))}
                          </span>{' '}
                        </>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    {numberWithCommas(props.TotalLeadsGoal.budget_q1)}
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    $
                    {numberWithCommas(
                      Number(quarterlyRevenueGoal.budget_q1).toFixed(0)
                    )}
                  </Col>
                </Row>

                <Row
                  xs={24}
                  md={12}
                  lg={24}
                  gutter={[12, 12]}
                  className="budget-row"
                  style={{ padding: '10px 0' }}
                >
                  <Col
                    xs={10}
                    md={12}
                    lg={10}
                    className="font-budget-header budget-cell"
                  >
                    <div
                      className="budget-planning-allocate-cell"
                      style={{
                        fontSize: '16px',
                        color: '#667085',
                        fontWeight: 700,
                      }}
                    >
                      Q2
                    </div>
                    <div
                      className="budget-planning-allocate-cell"
                      style={{ flex: 1, marginLeft: '35px' }}
                    >
                      <Form.Item
                        name="budget_q2"
                        style={{ marginBottom: 0 }}
                        label=""
                        rules={[
                          {
                            required: true,
                            message: 'This field can not be empty!',
                          },
                          {
                            pattern: /^[0-9.]+$/,
                            message: 'Value should contain just number',
                          },
                          {
                            pattern: /^\d+$/,
                            message: 'Value should not in fraction.',
                          },
                        ]}
                        onChange={handleBudgetQ1}
                      >
                        <InputNumber
                          size="large"
                          name="budget_q2"
                          formatter={(value) => {
                            if (!isNaN(value)) {
                              return `$ ${Math.floor(value)}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              );
                            }
                          }}
                          style={{ width: '100%', color: 'black' }}
                          disabled={
                            !(
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'viewonly' &&
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'hidden' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'preview' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'viewonly'
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      {Math.sign(q1Diff.budget_q2) === 1 && (
                        <>
                          <span
                            className="over-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(q1Diff.budget_q2)}
                          </span>{' '}
                        </>
                      )}
                      {Math.sign(q1Diff.budget_q2) === -1 && (
                        <>
                          <span
                            className="under-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(Math.abs(q1Diff.budget_q2))}
                          </span>{' '}
                        </>
                      )}
                      {/* {!q1Diff.budget_q2 ? "~" : ""} */}
                    </div>
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    {numberWithCommas(props.TotalLeadsGoal.budget_q2)}
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    $
                    {numberWithCommas(
                      Number(quarterlyRevenueGoal.budget_q2).toFixed(0)
                    )}
                  </Col>
                </Row>

                <Row
                  xs={24}
                  md={12}
                  lg={24}
                  gutter={[12, 12]}
                  className="budget-row"
                  style={{ padding: '10px 0' }}
                >
                  <Col
                    xs={10}
                    md={12}
                    lg={10}
                    className="font-budget-header budget-cell"
                  >
                    <div
                      className="budget-planning-allocate-cell"
                      style={{
                        fontSize: '16px',
                        color: '#667085',
                        fontWeight: 700,
                      }}
                    >
                      Q3
                    </div>
                    <div
                      className="budget-planning-allocate-cell"
                      style={{ flex: 1, marginLeft: '35px' }}
                    >
                      <Form.Item
                        name="budget_q3"
                        style={{ marginBottom: 0 }}
                        label=""
                        rules={[
                          {
                            required: true,
                            message: 'This field can not be empty!',
                          },
                          {
                            pattern: /^[0-9.]+$/,
                            message: 'Value should contain just number',
                          },
                          {
                            pattern: /^\d+$/,
                            message: 'Value should not in fraction.',
                          },
                        ]}
                        onChange={handleBudgetQ1}
                      >
                        <InputNumber
                          size="large"
                          name="budget_q3"
                          formatter={(value) => {
                            if (!isNaN(value)) {
                              return `$ ${Math.floor(value)}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              );
                            }
                          }}
                          style={{ width: '100%', color: 'black' }}
                          disabled={
                            !(
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'viewonly' &&
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'hidden' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'preview' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'viewonly'
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      {Math.sign(q1Diff.budget_q3) === 1 && (
                        <>
                          <span
                            className="over-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(q1Diff.budget_q3)}
                          </span>{' '}
                        </>
                      )}
                      {Math.sign(q1Diff.budget_q3) === -1 && (
                        <>
                          <span
                            className="under-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(Math.abs(q1Diff.budget_q3))}
                          </span>{' '}
                        </>
                      )}
                      {/* {!q1Diff.budget_q3 ? "~" : ""} */}
                    </div>
                  </Col>

                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    {numberWithCommas(props.TotalLeadsGoal.budget_q3)}
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    $
                    {numberWithCommas(
                      Number(quarterlyRevenueGoal.budget_q3).toFixed(0)
                    )}
                  </Col>
                </Row>

                <Row
                  xs={24}
                  md={12}
                  lg={24}
                  gutter={[12, 12]}
                  className="budget-row"
                  style={{ padding: '10px 0' }}
                >
                  <Col
                    xs={10}
                    md={12}
                    lg={10}
                    className="font-budget-header budget-cell"
                  >
                    <div
                      className="budget-planning-allocate-cell"
                      style={{
                        fontSize: '16px',
                        color: '#667085',
                        fontWeight: 700,
                      }}
                    >
                      Q4
                    </div>
                    <div
                      className="budget-planning-allocate-cell"
                      style={{ flex: 1, marginLeft: '35px' }}
                    >
                      <Form.Item
                        name="budget_q4"
                        style={{ marginBottom: 0 }}
                        label=""
                        rules={[
                          {
                            required: true,
                            message: 'This field can not be empty!',
                          },
                          {
                            pattern: /^[0-9.]+$/,
                            message: 'Value should contain just number',
                          },
                          {
                            pattern: /^\d+$/,
                            message: 'Value should not in fraction.',
                          },
                        ]}
                        onChange={handleBudgetQ1}
                      >
                        <InputNumber
                          size="large"
                          name="budget_q4"
                          formatter={(value) => {
                            if (!isNaN(value)) {
                              return `$ ${Math.floor(value)}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              );
                            }
                          }}
                          style={{ width: '100%', color: 'black' }}
                          disabled={
                            !(
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'viewonly' &&
                              props.userPermission?.[
                                'budgetCampaign.budgets.allocateBudget'
                              ] !== 'hidden' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'preview' &&
                              props.userPermission?.['budgetCampaign'] !==
                                'viewonly'
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div style={{ display: 'inline-block' }}>
                      {Math.sign(q1Diff.budget_q4) === 1 && (
                        <>
                          <span
                            className="over-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(q1Diff.budget_q4)}
                          </span>{' '}
                        </>
                      )}
                      {Math.sign(q1Diff.budget_q4) === -1 && (
                        <>
                          <span
                            className="under-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ${numberWithCommas(Math.abs(q1Diff.budget_q4))}
                          </span>{' '}
                        </>
                      )}
                      {/* {!q1Diff.budget_q4 ? "~" : ""} */}
                    </div>
                  </Col>

                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    {numberWithCommas(props.TotalLeadsGoal.budget_q4)}
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    $
                    {numberWithCommas(
                      Number(quarterlyRevenueGoal.budget_q4).toFixed(0)
                    )}
                  </Col>
                </Row>

                <Row
                  xs={24}
                  md={12}
                  lg={24}
                  gutter={[12, 12]}
                  className="budget-row"
                  style={{ padding: '10px 0', backgroundColor: '#FCFAFF' }}
                >
                  <Col
                    xs={10}
                    md={12}
                    lg={10}
                    className="font-budget-header budget-cell"
                  >
                    <div
                      className="budget-planning-allocate-cell"
                      //style={{ verticalAlign: "middle", color:"#303030" }}
                      style={{
                        justifyContent: 'flex-end',
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      FY Total
                    </div>
                    <div
                      className="budget-planning-allocate-cell budget-card-number-achieved"
                      style={{
                        flex: 1,
                        textAlign: 'right',
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      ${numberWithCommas(totalBudgetUpdated)}
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      {Math.sign(
                        totalBudgetUpdated - props.BudgetProfile?.budget
                      ) === 1 && (
                        <>
                          <span
                            className="over-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ($
                            {numberWithCommas(
                              totalBudgetUpdated - props.BudgetProfile?.budget
                            )}
                            )
                          </span>{' '}
                        </>
                      )}
                      {Math.sign(
                        totalBudgetUpdated - props.BudgetProfile?.budget
                      ) === -1 && (
                        <>
                          <span
                            className="under-budget"
                            style={{ marginLeft: '5px', fontWeight: 700 }}
                          >
                            ( $
                            {numberWithCommas(
                              Math.abs(
                                totalBudgetUpdated - props.BudgetProfile?.budget
                              )
                            )}
                            )
                          </span>{' '}
                        </>
                      )}
                      {/* {totalBudgetUpdated - props.BudgetProfile?.budget === 0
                    ? "(~)"
                    : ""} */}
                    </div>
                  </Col>

                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    <strong
                      style={{
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      {' '}
                      {numberWithCommas(
                        props.TotalLeadsGoal.budget_q1 +
                          props.TotalLeadsGoal.budget_q2 +
                          props.TotalLeadsGoal.budget_q3 +
                          props.TotalLeadsGoal.budget_q4
                      )}
                    </strong>
                  </Col>
                  <Col
                    xs={7}
                    md={6}
                    lg={7}
                    className="budget-cell"
                    style={{
                      justifyContent: 'flex-end',
                      color: '#667085',
                      fontSize: '16px',
                      fontWeight: 700,
                    }}
                  >
                    <strong
                      style={{
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      $
                      {numberWithCommas(
                        Number(
                          quarterlyRevenueGoal.budget_q1 +
                            quarterlyRevenueGoal.budget_q2 +
                            quarterlyRevenueGoal.budget_q3 +
                            quarterlyRevenueGoal.budget_q4
                        ).toFixed(0)
                      )}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={24}>
                    <div
                      className="btnActions"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <Button
                        className="tertiaryButton"
                        style={{ margin: '0 5px' }}
                        onClick={() => props.setIsShowBudgetPlannedModal(false)}
                      >
                        Cancel
                      </Button>
                      {props.userPermission?.[
                        'budgetCampaign.budgets.allocateBudget'
                      ] !== 'viewonly' &&
                        props.userPermission?.[
                          'budgetCampaign.budgets.allocateBudget'
                        ] !== 'hidden' &&
                        props.userPermission?.['budgetCampaign'] !==
                          'preview' &&
                        props.userPermission?.['budgetCampaign'] !==
                          'viewonly' && (
                          <Button
                            className="primaryButton"
                            onClick={onSubmit}
                            style={{ margin: '0 5px' }}
                          >
                            Save
                          </Button>
                        )}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default BudgetPlanning;
