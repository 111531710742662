import React, { useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";
import NewAccount from "./NewAccount";
import RequestAccess from "./RequestAccess";
import Logo from "commons/Logo";
import RequestPending from "./RequestPending";
import Auth from "@aws-amplify/auth";
import { useDispatch, useSelector } from "react-redux";
import { getaccountDetails, getCustomerAccountRequests, setLoginData, setPermission } from "store/actions/loginAction";

const NEW = "newAccount";
const ACCESS = "requestAccess";

const Selection = (props) => {
  const [step, setStep] = useState("");
  const [requestAccessState, setrequestAccessState] = useState(false);
  const [accountid, setaccountid] = useState("")
  const {accountRequests, groups} = useSelector(state => state.loginReducer)


  useEffect(() => {
    if(accountRequests?.length > 0)
    setrequestAccessState(true)
  },[accountRequests])

  const handleRequestAccessState = () => {
    setrequestAccessState(true);
  };

  useEffect(() => {
    if (requestAccessState) {
      setInterval(() => {
        fetchuser()
      }, 5000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[requestAccessState])

  useEffect(() => {
  dispatch(getCustomerAccountRequests())
    return () => {
      clearInterval()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const SETPermission = async (session) =>{
    await dispatch(setPermission(session.idToken.payload))
  }
  const refreshTokenManualy = async () =>{
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

    cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
      if (session) {
        if(session?.idToken?.payload){
          await SETPermission(session)
        }
      }
    });
  }

  const dispatch = useDispatch()

  async function fetchuser () {
    if(groups?.length === 0){
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const currentSession = await Auth.currentSession();
      await cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
        if (session) {
          const { accessToken } = session;
          if ("cognito:groups" in accessToken.payload) {
            let userAccountId = [];
            userAccountId = accessToken.payload["cognito:groups"];
            userAccountId.push(userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]);
            dispatch(getaccountDetails(userAccountId[0]))
            await refreshTokenManualy()
            clearInterval()
            const response = Auth.currentUserInfo()
            response.then((data) => {
              dispatch(setLoginData(session, data?.attributes))
              clearInterval()

            }).then(_ => {
              setTimeout(() => {
            window.location.reload()
              }, 3000);

            })
        }
        }
      })

    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }
  }

  useEffect(() => {
    if (
      window.location.href.includes("requestid=") &&
      window.location.href.includes("/setup")
    ) {
      setaccountid(window.location.href.split("requestid=")[1])
      setStep(ACCESS)
    }
  },[])

  return (
    <>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={24} style={{ marginTop: 25 }}>
          <Logo />
        </Col>
        <Col span={24}>
          {requestAccessState && !accountid ? (
            <Row justify="center" align="middle" style={{ paddingTop: 20 }}>
              <Col xs={24} md={11} lg={11} xxl={11}>
                <Row justify="center">
                  <Col span={15} lg={15} md={15} sm={24} xs={24}>
                    <RequestPending  accountid={accountid}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row justify="center" align="middle" style={{ paddingTop: 20 }}>
              <Col xs={24} md={11} lg={11} xxl={11}>
                <Row justify="center">
                  <Col span={15} lg={15} md={15} sm={24} xs={24}>
                    <NewAccount
                      select={() => setStep(NEW)}
                      expand={step === NEW}
                        reset={() => setStep("")}
                        fetchuser = {fetchuser}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={0} md={2} lg={2} xxl={2}>
                <Row justify="center">
                  <Divider type="vertical" style={{ height: "60vh" }} />
                </Row>
              </Col>
              <Col xs={18} md={0} lg={0} xxl={0}>
                <Row justify="center">
                  <Divider type="horizontal" />
                </Row>
              </Col>
              <Col xs={24} md={11} lg={11} xxl={11}>
                <Row justify="center">
                  <Col span={15} lg={15} md={15} xs={24} sm={24}>
                    <RequestAccess
                      select={() => setStep(ACCESS)}
                      expand={step === ACCESS}
                      reset={() => setStep("")}
                        requestAccess={handleRequestAccessState}
                        accountid={accountid}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
        <Col lg={18} md={18} sm={24} xs={24}>
          <div style={{ textAlign: "center" }}>
            <span
              style={{
                color: "#646C79",
                fontSize: "16px",
                textAlign: "center",
                fontWeight: "500",
                userSelect: "none",
                marginBottom: "0px",
              }}
            >
              Version{" "}
              {process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : "0.1.0"}
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "#646C79",
                textAlign: "center",
              }}
            >
              &nbsp; &copy; {new Date().getFullYear()} XFactor.io copyright all
              rights reserved.
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Selection;
