import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const SessionTracker = () => {
const {token, userData} = useSelector((state) => state.loginReducer);
  


  useEffect(() => {
    const handleBeforeUnload = () => {
      const sessionStartTime = sessionStorage.getItem('sessionStartTime');
      if (sessionStartTime) {
        const sessionEndTime = Math.floor(Date.now() / 1000);  
        const sessionDuration = sessionEndTime - parseInt(sessionStartTime, 10);
        // Send session duration to Heap
        window.heap.track('SessionEnded', { duration: sessionDuration, userName: `${userData?.given_name} ${userData?.family_name}` , userEmail: userData?.email });
        // Clear session start time from sessionStorage
        sessionStorage.removeItem('sessionStartTime');
      }
    };

    if(token){
        // Set session start time in sessionStorage if it doesn't exist
        if (!sessionStorage.getItem('sessionStartTime')) {
        const startTimeInSeconds = Math.floor(Date.now() / 1000); // Current time in seconds
        sessionStorage.setItem('sessionStartTime', startTimeInSeconds.toString());
        }
    }

    // Listen for beforeunload event to end session on window close
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [token]);

  return null; // This component doesn't render anything visible
};

export default SessionTracker;
