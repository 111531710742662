import FullPageLayout from 'containers/FullPageLayout';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

class PublicRoutes extends Component {
  render() {
    const { token, groups, render, location, ...rest } = this.props;
    // Parse the query string
    const queryParams = new URLSearchParams(window.location.search);

    // Read the value of the 'redirect_url' query parameter
    const redirectUrl = queryParams.get('redirect_url');

    console.log("queryParamsPublic",redirectUrl);
    let to;
    to = redirectUrl ? redirectUrl : '/';
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {token && groups.length > 0 && (
              <Redirect from={matchProps.path} to={to} />
            )}
            {token && groups.length === 0 && (
              <Redirect from={matchProps.path} to="/setup" />
            )}
            {!token && <FullPageLayout>{render(matchProps)}</FullPageLayout>}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.loginReducer.token,
    groups: state.loginReducer.groups,
    errors: state.errors,
  };
};

export default connect(mapStateToProps)(PublicRoutes);
