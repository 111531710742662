import { fourAMStages } from 'utility/Constants';
import * as actions from '../actions/index';

const initialState = {
  baseLineData : {},
  newRevenueGoal : 0,
  marketingSourced : 1,
  averageDealSize : 0,
  marketingSourcedRevenue : 0,
  salesSourcedRevenue : 0,
  marketingSourcedCount : 0,
  salesSourcedCount:  0,
  funnelConfiguration : {},
  funnelStages : fourAMStages,
  baselineList: [],
  planningPeriod: new Date().getFullYear(),
  planningPeriodList: [],
  fiscalYearList: [],
  fiscalYear: {},
  isLoading: false,
  isCheckLoading:false,
  masterAmStages: [],
  totalPipeLineCoverage:0
}

const revenueReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.SET_FISCAL_YEAR_LIST:
      return {...state, fiscalYearList : [...action.payload] }

    case actions.SET_FISCAL_YEAR:
      return { ...state, fiscalYear: { ...action.payload }}
    
    case actions.UPDATE_PLOT_DATA_LIST :
      return {...state, funnelStages: [...action.payload] };

    case actions.ADD_PLOT_DATA_ITEM :
      let newArray = state.funnelStages;
      newArray.push({
        text : action.payload,
        value: 750
      });
     return {...state, plotData: [...newArray] };

    case actions.SHOW_LOADER:
      return { ...state, isLoading: action.payload };

    case actions.SHOW_CHECK_LOADER:
      return { ...state, isCheckLoading: action.payload };
    
    case actions.UPDATE_METRICS_CALCULATION :
      const {
        newRevenueGoal,
        marketingSourced,
        averageDealSize,
        marketingSourcedCount,
        marketingSourcedRevenue,
        salesSourcedRevenue,
        saleSourcedCount,
      } = action.payload;

      let arr = [...state.funnelStages];
      if(marketingSourcedCount !== 0) {
        if(arr.length === 0){
          arr = fourAMStages
        }
      }
    
      return {
        ...state,
        newRevenueGoal,
        marketingSourced,
        averageDealSize,
        marketingSourcedCount,
        marketingSourcedRevenue,
        salesSourcedRevenue,
        saleSourcedCount,
        funnelStages : [...arr]
      }
    case actions.SET_BASELINE_DATA :
      return {...state, baseLineData : {...action.payload}}

    case actions.SET_BASELINE_LIST_DATA :
      let newBaselineList = [...action.payload];
      return {...state, baselineList : [...newBaselineList]}

    case actions.SET_FUNNEL_CONFIG :
      return {...state, funnelConfiguration : {...action.payload}}

    case actions.SET_FUNNEL_STAGES_LIST :
      let newPlotArray = [...action.payload];
      return {...state, funnelStages: [...newPlotArray]}

    case actions.SET_FUNNEL_STAGES :
      let updateArray = state.funnelStages;
      updateArray.push(action.payload);
      return { ...state, funnelStages : [...updateArray]}

    case actions.DELETE_STAGE_DATA :
      let removeArray = state.funnelStages;
      let index = removeArray.findIndex(item => item.id === action.payload.id)
      removeArray.splice(index,1);
      return {...state, funnelStages: [...removeArray] };

    case actions.SET_PLANNING_PERIOD:
      return {...state, planningPeriod : action.payload , funnelStages: []};

    case actions.SET_ACCOUNT_MASTER_STAGES:
      return {...state, masterAmStages : action.payload};
      
    case actions.TOTAL_PIPELINE_COVERAGE:
      return {...state, totalPipeLineCoverage : action.payload};

    case actions.SET_YEAR_LIST:
      let newYearList = [];
      action.payload.sort();
      action.payload.forEach((item) => {
        newYearList.push({value : item, name :`FY${item}` })
      });
      return {...state, planningPeriodList: [...newYearList]}

    default:
      return state;
  }
}

export default revenueReducer;
