/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Button,
  Form,
  Input,
  Tooltip,
  Drawer,
  Collapse,
  Select,
  Checkbox,
  message,
  Typography,
  Switch,
} from "antd";
import DatePicker from "react-multi-date-picker";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getChannelList,
  createCampaign,
  updateCampaign,
  createCampaignDetails,
  createCampaignMonth,
  getBudget,
  deleteCampaignMonth,
  deleteCampaignDetails,
} from "store/actions/budgetAction";
import RevenueTextField from "commons/RevenueTextField";
import EditPreventIamge from "assets/Images/edit-campaign-prevent.png"
import { getTerritorysList } from "store/actions/budgetAction";


const CreateCampaign = (props) => {
  
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const { nameOfLeadObj } = props
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [tableEdited, settableEdited] = useState(false)
  const account = useSelector((state) => state.loginReducer.groups);

  const [totalBudgetState, setTotalBudgetState] = useState(0);
  const [LeadPerMonth, setLeadPerMonth] = useState();

  const [totalLeads, setTotalLeads] = useState();
  const [costPerLead, setCostPerLead] = useState();

  const { Option } = Select;

  const { ChannelList } = useSelector(
    (state) => state.budgetReducer
  );
  const budgetProfileList = useSelector(
    (state) => state.budgetReducer.BudgetProfileList
  );
 
  const Territorys = useSelector(
    (state) => state.budgetReducer.Territorys
  );
console.log("Territorys",Territorys);
  

  const [selectedBudgets, setSelectedBudgets] = useState(
    props.isEdit || props.isCopy
      ? props.campaignObj.associatedBudgets.map((budget) => budget.id)
      : [props.selectedValue]
  );

  // for Selecting Date
  const [DateValues, setDateValues] = useState([])


  const handleLeadPerMonth = (e) => {
    let months = getMonthsDiffrence()      

    let lpm = form.getFieldsValue().leads_per_month
    let totalBudget = form.getFieldValue().budget_total
    let leads_total = lpm * months
    let cpl = Number(parseFloat(totalBudget) / leads_total).toFixed(2)
    setCostPerLead(isNaN(cpl) ? 0 : isFinite(cpl) ? cpl : 0)
    setTotalLeads(isNaN(leads_total) ? 0 : leads_total)
    setLeadPerMonth(isNaN(lpm) ? 0 : lpm)
    form.setFieldsValue({leads_per_month :isNaN(lpm) ? 0 : lpm })

  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTotalBudget = (e) => {
    let budget_total = form.getFieldsValue().budget_total
    let leads_total = form.getFieldsValue().leads_total
    let cpl = Number(budget_total / leads_total).toFixed(2)
    setCostPerLead(isNaN(cpl) ? 0 : isFinite(cpl) ? cpl : 0)
    setTotalBudgetState(budget_total)

  };

  const handleTotalLeads = (e) => {
    let months = getMonthsDiffrence()      
    let leads_total = form.getFieldsValue().leads_total
    let lpm = parseInt(leads_total / months)
    let cpl = Number(totalBudgetState / leads_total).toFixed(2)
    setCostPerLead(isNaN(cpl) ? 0 : isFinite(cpl) ? cpl : 0)
    setTotalLeads(isNaN(leads_total) ? 0 : leads_total)
    setLeadPerMonth(isNaN(lpm) ? 0 : lpm)

    form.setFieldsValue({leads_per_month :isNaN(lpm) ? 0 : lpm })

  }
  const handleCost_per_lead = (e) => {
    let months = getMonthsDiffrence()      
    
    let cpl = form.getFieldsValue().projected_cost_per_lead
    let totalLeadGoal = parseInt(totalBudgetState / cpl)
    let lpm = parseInt(totalLeadGoal / months)

    setCostPerLead(isNaN(cpl) ? 0 : isFinite(cpl) ? cpl : 0)
    setTotalLeads(isNaN(totalLeadGoal) ? 0 : totalLeadGoal)
    setLeadPerMonth(lpm)
    form.setFieldsValue({leads_per_month :isNaN(lpm) ? 0 : lpm })
  }


  useEffect(() => {
    const obj = props.campaignObj;
    console.log("obj",obj);
    form.setFieldsValue({
      name: obj?.name,
      channel: obj?.channel,
      vendor: obj?.vendor,
      attribution_source: obj?.attribution_source,
      attribution_code: obj?.attribution_code,
      budget_profiles: obj?.budget_profiles,
    });

    if(obj?.metadata){
      let metadata = JSON.parse(obj?.metadata)
      if(metadata?.["tableEdited"]){
        settableEdited(true)
        setWantResetMonthWiseData(true)
      }
      else{
        settableEdited(false)
        setWantResetMonthWiseData(false)
      }
    }

    let totalBudget = 0
    let totalLead = 0
    let timeFrameMonthObj = [];
    let leadsMonths = 0
    
    const d = new Date();
    obj?.campaign_details?.items?.map(campaign_detail => campaign_detail?.campaign_months?.items?.map(month => {
      totalBudget += month?.budget
      totalLead += month?.leads
      timeFrameMonthObj?.push(new Date(`${d.getFullYear()}-${month?.month}-01`))
      leadsMonths = month?.leads

    }))
    setTotalLeads(totalLead)
    form.setFieldsValue({
      budget_total : totalBudget.toFixed(2),

    })
    form.setFieldsValue({
      budget_timeframe : timeFrameMonthObj,

    })
    form.setFieldsValue({
      leads_per_month : leadsMonths,

    })
    form.setFieldsValue({
      territory : obj?.campaign_details?.items[0]?.territoryID,

    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campaignObj, form]);

  const getMonthsDiffrence = () => {
    return form.getFieldValue('budget_timeframe')?.length
  }

  useEffect(() => {
    handleLeadPerMonth()
      if(DateValues?.length === 12){
        setFullYearSelected(true)

      }
      else{
        setFullYearSelected(false)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DateValues]);

 
  useEffect(() => {
    dispatch(getChannelList());
  }, [dispatch]);

  const GetSortOrder = (prop) => {    
    return (a, b) => {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
  }

  const onSubmit = async () => {
    // Setting Months Weights and Months Numbers
    let quarterwiseweight = {}
    let campaignMonths = form.getFieldValue('budget_timeframe')?.map(data => 
      new Date(data).getMonth() + 1
    ).map(month => {
      quarterwiseweight[moment(month,"M").quarter()] = quarterwiseweight[moment(month,"M").quarter()] ? quarterwiseweight[moment(month,"M").quarter()] + 1 : 1
      return month
    }).map(month => {
      return ({month:month, weight: Number((100 / quarterwiseweight[moment(month,"M").quarter()]).toFixed(2)) })
    })
    campaignMonths.sort(GetSortOrder("month"))
      setIsLoading(true);
      let name = form.getFieldsValue(["name"])['name']? form.getFieldsValue(["name"])['name'].trim() : ""
    if (name.length === 0) {
      form.setFieldsValue({ name: name })
    }
      form
        .validateFields()
        .then(async (values) => {
          
          if(props.isEdit){
            if(WantResetMonthWiseData || !tableEdited){

              // RESET ALL THE MONTHWISE DATA  

                let campaignMonthsToDelete = []
                let campaignDetailsToDelete = []
                props.campaignObj?.campaign_details.items?.map((campaign_details)=>{
                    campaignDetailsToDelete.push(campaign_details?.id)
                    campaign_details?.campaign_months?.items?.forEach(month => {
                        campaignMonthsToDelete.push(month?.id)
                    })
                })
                await Promise.all(campaignMonthsToDelete.map(month => dispatch(deleteCampaignMonth({id: month}))))
                await Promise.all(campaignDetailsToDelete.map(detail => dispatch(deleteCampaignDetails({id: detail}))))
                

              let inputDataForCampaignDetails = {
              account: account[0],
              campaign_id : props.campaignObj?.id,
              date_start : moment(campaignMonths[0]?.month, "M").startOf('month').format("YYYY-MM-DD"),
              date_end : moment(campaignMonths[campaignMonths?.length -1 ]?.month, "M").endOf('month').format("YYYY-MM-DD"),
              territoryID: values?.territory
            }

            HandlecreateCampaignDetails(inputDataForCampaignDetails).then(campaignDetails => {

              let InputDataForCampaignMonth = []
              campaignMonths.forEach(item => {
              let dataobj = {
                    account: account[0],
                    campaign_detail_id : campaignDetails?.id,
                    month:item?.month,
                    budget:values?.budget_total ? Number((values?.budget_total / campaignMonths?.length).toFixed(2)) : 0 ,
                    leads: values?.leads_per_month ? values?.leads_per_month : 0 ,
                    weight:item?.weight,
                    weight_lead : item?.weight
                  }
                  InputDataForCampaignMonth.push(dataobj)
                })
              HandleCreateCampaignMonth(InputDataForCampaignMonth).then(async (budget) => {
                let campaignRequestData = {
                  id:props?.campaignObj?.id, 
                  account: account[0],
                  channel: values?.channel,
                  name: values?.name,
                  metadata : JSON.stringify({tableEdited : false}),
                  
                }
                await HandleUpdateCampaign(campaignRequestData)
                props.handleCancelCampign();
                form.resetFields();
                setDateValues([])
              })


            }).catch(error => {
              console.log("Error In Create Campaign Details", error)
            })

            }
            else{
              // EDIT ONLY CAMPAIGN
              let campaignRequestData = {
              id:props?.campaignObj?.id, 
              account: account[0],
              name:values?.name,
              channel : values?.channel,
            }
            await HandleUpdateCampaign(campaignRequestData)
            props.handleCancelCampign();
            form.resetFields();
            setDateValues([])
            }
          }
          else{
            // CREATE CAMPAIGN

          let profileArray = values["budget_profiles"]
            ? values["budget_profiles"]
            : props.selectedValue;
          if (!Array.isArray(profileArray)) {
            profileArray = [profileArray];
          }
          let inputData = {
            account: account[0],
            name:values?.name,
            channel : values?.channel,
            metadata : JSON.stringify({tableEdited : false})
          }
          
          HandleCreateCampaign(inputData, profileArray).then(campaign => {
            
            let inputDataForCampaignDetails = {
              account: account[0],
              campaign_id : campaign?.id,
              date_start : moment(campaignMonths[0]?.month, "M").startOf('month').format("YYYY-MM-DD"),
              date_end : moment(campaignMonths[campaignMonths?.length -1 ]?.month, "M").endOf('month').format("YYYY-MM-DD"),
              territoryID: values?.territory
            }

            HandlecreateCampaignDetails(inputDataForCampaignDetails).then(campaignDetails => {

              let InputDataForCampaignMonth = []
              campaignMonths.forEach(item => {
              let dataobj = {
                    account: account[0],
                    campaign_detail_id : campaignDetails?.id,
                    month:item?.month,
                    budget:values?.budget_total ? Number((values?.budget_total / campaignMonths?.length).toFixed(2)) : 0 ,
                    leads: values?.leads_per_month ? values?.leads_per_month : 0 ,
                    weight:item?.weight,
                    weight_lead : item?.weight
                  }
                  InputDataForCampaignMonth.push(dataobj)
                })
              HandleCreateCampaignMonth(InputDataForCampaignMonth).then(budget => {
                dispatch(getBudget(props.selectedValue))
                props.handleCancelCampign();
                form.resetFields();
                setDateValues([])
              }).catch(error => {message.error("Something went wrong");
              setIsLoading(false);
            }
              )


            }).catch(error => {
              console.log("Error In Create Campaign Details", error)
              setIsLoading(false);

            })



          }).catch(error => {
            console.log("Error In creating Campaign", error);
            message.error("Failed to create Campaign")
          setIsLoading(false);

          })
        } 
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
          setIsLoading(false);
        })

  };

  const HandleCreateCampaign = (value, budgetProfiles) => {
    delete value["id"];
    return dispatch(createCampaign(value, budgetProfiles));
  };

  const HandlecreateCampaignDetails = (inputData) => {
    return dispatch(createCampaignDetails(inputData))
  }

  const HandleCreateCampaignMonth = (inputData) => {
    return Promise.all(inputData.map(item => {
      return dispatch(createCampaignMonth(item))
    }))
  }

  const HandleUpdateCampaign = (inputData) => {
    return dispatch(
      updateCampaign(inputData)
    ).then(async () => {
      message.success("Campaign Edited Successfully!");
      await dispatch(getBudget(props.selectedValue))
    })
    .catch((err) => {
      console.log(err);
      message.error("Something went wrong!");
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      leads_total: totalLeads,
      projected_cost_per_lead: costPerLead,
    });
  }, [totalLeads, costPerLead]); // eslint-disable-line

  useEffect(() => {
    form.setFieldsValue({
      budget_profiles: props.selectedValue,
    });
  }, [props.selectedValue]); // eslint-disable-line

  useEffect(() => {
    setWantResetMonthWiseData(!props.isEdit)
  },[props.isEdit])

  useEffect(() => {
    form.setFieldsValue({
      channel: props.channelIdForCampaign,
    });
  }, [props.channelIdForCampaign]); // eslint-disable-line

  const handleCreateChannel = () => {
    props.showModal(true);
  };


  const [WantResetMonthWiseData, setWantResetMonthWiseData] = useState(false)
  const [FullYearSelected, setFullYearSelected] = useState(false)

  const SelectFullYear = (event) => {
    
    let timeFrameMonthObj = [];
    if (event) {
    const d = new Date();
    setFullYearSelected(true)
    new Array(12).fill().map((key, index) => {
      timeFrameMonthObj?.push(new Date(`${d.getFullYear()}-${index+1 < 10 ? "0"+(index+1) : (index+1)}-01`))
    })
    }
    else{
    setFullYearSelected(false)

    }
    form.setFieldsValue({
      budget_timeframe : timeFrameMonthObj,

    })
    setDateValues(timeFrameMonthObj)
  }

  useEffect(() => {
    dispatch(getTerritorysList());
  }, [])
  console.log("props?.budget?.lockedMonths",props.budget?.lockedMonths);
  console.log("WantResetMonthWiseData",WantResetMonthWiseData);
  console.log("tableEdited",tableEdited);

  return (
    <>
      {" "}
      <Drawer
        title={
          props.isEdit ? (
            <strong style={{color:"#344054", fontSize:'28px', fontWeight:700}}>Edit Campaign</strong>
          ) : props.isCopy ? (
            <strong style={{color:"#344054", fontSize:'28px', fontWeight:700}}>Copy Campaign</strong>
          ) : (
            <strong style={{color:"#344054", fontSize:'28px', fontWeight:700}}>Create new campaign</strong>
          )
        }
        width={500}
        onClose={props.handleCancelCampign}
        visible={props.visibleCreateCampaignDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        className="create-campaign cust_drawer"
        
        maskClosable={false}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          autoComplete="off"
          initialValues={{
            budget_profiles: props.selectedValue,
            channel: props.channelIdForCampaign,
          }}
          requiredMark={false}
        >
          <Form.Item
            name="channel"
            extra={ (props.userPermission?.['budgetCampaign.campaigns.channel'] !== 'viewonly' && props.userPermission?.['budgetCampaign.campaigns.channel'] !== 'hidden') && 
              <span
                onClick={handleCreateChannel}
                style={{
                  color: "#7F56D9",
                  cursor: "pointer",
                }}
              >
                +Create New
              </span>
            } // eslint-disable-line
            label={
              <span style={{ display: "flex", alignItems:'center' }}>
                <span className="drawer_label">Channel</span>
                <Tooltip title="Specify which channel this campaign belongs to" placement="left" >
                  <InfoCircleOutlined
                    
                    className="font-color-budget"
                    style={{marginLeft:'10px', fontSize:'14px'}}
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
          <Select
            
              size="large"
              showSearch
              style={{ width: "350px" }}
              placeholder="Select channel"
              optionFilterProp="children"
              key="channel"
              defaultValue={props.channelIdForCampaign}
              className="general-input-select"
            >
              {ChannelList &&
                ChannelList.length > 0 &&
                ChannelList.map((item, indexval) => {
                  return (
                    <Option key={indexval} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
            
          </Form.Item>
          <Form.Item
            name="name"
            label={
              <span style={{ display: "flex", alignItems:'center' }}>
                <span className="drawer_label">Campaign Name&nbsp;</span>
                <Tooltip title=" Set a custom label for this campaign" placement="left">
                  <InfoCircleOutlined
                    style={{marginLeft:'10px', fontSize:'14px'}}
                    className="font-color-budget"
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
          >
            <Input placeholder="Campaign name" key="name" className="general-input" style={{width:'350px'}}/>
          </Form.Item>


         

            {(props.isEdit && (tableEdited || props.budget?.lockedMonths?.length > 0)) && <Form.Item>
              <Checkbox className="checkBox editCampaign" style={{display: 'flex', alignItems:'center'}} onChange={(e) => setWantResetMonthWiseData(e.target.checked)}>
                <Typography.Text type="danger">Do you wish to override any budget and lead adjustments made on the table directly for this campaign?</Typography.Text>
              </Checkbox>
            </Form.Item>
}
          {/* { (WantResetMonthWiseData || !tableEdited) && (!props.budget?.lockedMonths || props.budget?.lockedMonths?.length === 0) ?  */}
         { (WantResetMonthWiseData || !tableEdited ) && !(props?.budget?.lockedMonths && props?.budget?.lockedMonths?.length > 0 ) ? 
         <>
          <Form.Item
            name="budget_total"
            label={
              <span style={{ display: "flex", alignItems:'center' }}>
                <span className="drawer_label">Budget</span>
                <Tooltip title=" Estimated budget amount for this campaign" placement="left">
                  <InfoCircleOutlined
                     style={{marginLeft:'10px', fontSize:'14px'}}
                    className="font-color-budget"
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
              {
                pattern: /^[+-]?\d+(\.\d+)?$/,
                message: "Value should contain just number",
              },
            ]}
            //extra="Cost of single event, total campaign, spread over selected schedule."
          >
            <RevenueTextField
              key="budget_total"
              placeholder="Total Budget"
              onChange={(value) => {
                handleTotalBudget(value);
              }}
              dollercirclestyle={{
                position: "absolute",
                zIndex: 1,
                left: 8,
              }}
              value={totalBudgetState === 0 ? "" : totalBudgetState}
              className="general-input drawer_input"
            />
          </Form.Item>

          <Form.Item
            label={
              <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                <span className="drawer_label">Budget Timeframe</span>
                <div 
                    style={{display:"flex", justifyContent:"end", flex:1, alignItems:"center" }}
                    >
                      <div style={{marginRight:10}}>
                <Switch onChange={SelectFullYear} size="small" checked={FullYearSelected}/> <span style={{fontSize:16}}>full year</span>
                </div>
                
                <Tooltip title="Timeframe to indicate when this campaign's budget should be allocated" placement="left">
                  <InfoCircleOutlined
                    className="font-color-budget"
                    style={{marginLeft:'10px', fontSize:'14px'}}
                  />
                </Tooltip>
                </div>
              </div>
            }
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value?.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please select proper timeframe'));
                },
              })
            ]}
            name="budget_timeframe"
          >
            <DatePicker
            onChange={(value) => {setDateValues(value)}}
            format="MMMM"
            onlyMonthPicker
            multiple
            buttons = {false}           
            hideYear
            hideMonth
            value={DateValues}
            inputClass="custom-date-picker"
            placeholder="Select Timeframe"
            containerStyle={{
              width: "100%"
            }}
            >
              <div className="date-picker-heading">Select Single/Multiple Months</div>
            </DatePicker>

          </Form.Item>

          <Form.Item
            name="leads_per_month"
            label={
              <span style={{ display: "flex", alignItems:'center' }}>
                <span className="drawer_label">{nameOfLeadObj}</span>
                <Tooltip title={`Specify this campaign's estimated monthly ${nameOfLeadObj}, total ${nameOfLeadObj} for the timeframe defined above, or its cost per lead`} placement="left">
                  <InfoCircleOutlined
                    style={{ marginLeft:'10px', fontSize:'14px' }}
                    className="font-color-budget"
                  />
                </Tooltip>
              </span>
            }
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
              {
                pattern: /^[+-]?\d+(\.\d+)?$/,
                message: "Value should contain just number",
              },
            ]}
            //extra="Per month, if repeating"
          >
            <RevenueTextField
              key="leads_per_month"
              placeholder="Estimated leads"
              onChange={(value) => {
                handleLeadPerMonth(value);
              }}
              value={LeadPerMonth === 0 ? "" : LeadPerMonth}
              notPrefix
              className="general-input drawer_input"
            />
          </Form.Item>

          <Form.Item
            name="leads_total"
            rules={[
              {
                required: true,
                message: "This field can not be empty!",
              },
            ]}
            
            label={
              <span className="drawer_label">{`Total ${nameOfLeadObj} goal`}</span>
            }
          >
            <RevenueTextField
              key="leads_total"
              placeholder={`Total ${nameOfLeadObj}`}
              notPrefix
              onChange={(value) => {
                handleTotalLeads(value);
              }}
              value={totalLeads === 0 ? "" : totalLeads}
              className="general-input drawer_input"
            />
          </Form.Item>

          <Form.Item
            name="projected_cost_per_lead"
            label={<span className="drawer_label">{`Projected Cost Per ${nameOfLeadObj}`}</span>}
          >
            <RevenueTextField
              key="projected_cost_per_lead"
              placeholder={`Cost per ${nameOfLeadObj}`}
              dollercirclestyle={{
                position: "absolute",
                zIndex: 1,
                left: 8,
              }}
              onChange={(value) => {
                handleCost_per_lead(value);
              }}
              value={costPerLead === 0 ? "" : costPerLead}
              className="general-input drawer_input"
            />
          </Form.Item>
          <Form.Item
            name="territory"
            
            label={
              <span style={{ display: "flex", alignItems:'center' }}>
                <span className="drawer_label">Territory</span>
                
              </span>
            }
            // rules={[
            //   {
            //     required: true,
            //     message: "This field can not be empty!",
            //   },
            // ]}
          >
            <Tooltip placement="top" title={'Coming Soon'}>
          <Select
            
              size="large"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Territory"
              optionFilterProp="children"
              key="territory"
              disabled
              className="general-input-select"
            >
              {Territorys &&
                Territorys.length > 0 &&
                Territorys.map((item, indexval) => {
                  return (
                    <Option key={indexval} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
            </Tooltip>
            
          </Form.Item>
        </>
        : <>
        <div className="edit-prevent-section">
          <div className="edit-prevent-disabled"></div>
          {/* <div className="edit-prevent-text"><strong>This section has been locked because you have modified this
campaign's budget details on the table directly.</strong></div> */}
        <img src={EditPreventIamge} alt="" className="edit-prevent-img" />
        </div>
        <Form.Item
            name="territory"
            style={{marginTop:'15px'}}
            label={
              <span style={{ display: "flex", alignItems:'center' }}>
                <span className="drawer_label">Territory</span>
                
              </span>
            }
            // rules={[
            //   {
            //     required: true,
            //     message: "This field can not be empty!",
            //   },
            // ]}
          >
            <Tooltip placement="top" title={'Coming Soon'}>
          <Select
            
              size="large"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Territory"
              optionFilterProp="children"
              key="territory"
              disabled
              className="general-input-select"
            >
              {Territorys &&
                Territorys.length > 0 &&
                Territorys.map((item, indexval) => {
                  return (
                    <Option key={indexval} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
            </Tooltip>
            
          </Form.Item>
        </>

        }
          <Collapse
            bordered={false}
            expandIconPosition="right"
            className="advance_settings"
          >
            <Panel header={<span style={{fontSize:'20px', color:"#344054", fontWeight:700}}>Advanced Settings</span>} key="1">
              <Form.Item
                name="budget_profiles"
                label="Budget Profile Selection"
                rules={[
                  {
                    required: true,
                    message: "This field can not be empty!",
                  },
                ]}
                extra="Select any additional profiles to extend any budget overflow"
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Budget Profile"
                  optionFilterProp="children"
                  mode="multiple"
                  key="budget_profiles"
                  defaultValue={selectedBudgets}
                  value={selectedBudgets}
                  onChange={(value) => setSelectedBudgets(value)}
                  className="general-input-select"
                >
                  {budgetProfileList &&
                    budgetProfileList.length > 0 &&
                    budgetProfileList.map((item, index) => {
                      return (
                        <Option
                          value={item.value}
                          disabled={item.value === props.selectedValue}
                          key={index}
                        >
                          {item.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            zIndex: 2,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
            display:"flex",
            alignItems:'center',
            justifyContent:'center'
          }}
        >
          <Button
            disabled={isLoading}
            onClick={props.handleCancelCampign}
            style={{ marginRight: 8, width: "48%" }}
            className="secondaryButton"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={onSubmit}
            type="primary"
            style={{ width: "48%" }}
            className="primaryButton"
          >
            Save
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default CreateCampaign;

