/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Divider, Skeleton } from "antd";
import React from "react";

export default function SdrCapacityCalculatorSkeleton() {
  return (
    <>
      <Row justify="center" style={{ padding: "10px 10px 0 10px" }}>
        <Col span={18} lg={18} md={18} sm={24} xs={24}>
          <Row gutter={10}>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <Skeleton.Input
                active
                style={{ width: "30vw", marginBottom: 10 }}
                size="large"
              />
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <Skeleton.Input
                active
                style={{ width: "30vw", marginBottom: 10 }}
                size="large"
              />
            </Col>
          </Row>

          <Row gutter={10}>
            {" "}
            <Col span={24} lg={24} md={24} sm={24} xs={24}>
              <Skeleton.Input
                active
                style={{ width: "62vw", marginBottom: 10 }}
                size="large"
              />
            </Col>
          </Row>

            <div>
              <Divider style={{ margin: 0 }} />
              <Row
                gutter={10}
                style={{
                  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: 7,
                  margin: "25px 0",
                  // padding: 20,
                }}
                className="ModalTypebox"
              >
                <Col
                  span={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: "10px 0",
                  }}
                >
                  <Skeleton.Input
                    active
                    style={{ width: "30vw", marginBottom: 10 }}
                    size="large"
                  />
                </Col>
                <Col
                  span={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    margin: "10px 0",
                  }}
                >
                  <Skeleton.Input
                    active
                    style={{ width: "30vw", marginBottom: 10 }}
                    size="large"
                  />
                </Col>
              </Row>

              <div>
                <Row className="fields">
                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <Skeleton.Input
                      active
                      style={{ width: "30vw", marginBottom: 10 }}
                      size="large"
                    />
                  </Col>
                </Row>
                <Row className="table">
                  <Skeleton.Input
                    active
                    style={{ width: "66vw", marginBottom: 10 }}
                    size="large"
                  />
                </Row>
                <Row className="fields">
                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <Skeleton.Input
                      active
                      style={{ width: "30vw", marginBottom: 10 }}
                      size="large"
                    />
                  </Col>
                </Row>
                <Row className="table">
                  <Skeleton.Input
                    active
                    style={{ width: "66vw", marginBottom: 10 }}
                    size="large"
                  />
                </Row>
                <Row className="fields">
                  <Col span={8} lg={8} md={8} sm={24} xs={24}>
                    <Skeleton.Input
                      active
                      style={{ width: "30vw", marginBottom: 10 }}
                      size="large"
                    />
                  </Col>
                </Row>
                <Row className="table">
                  <Skeleton.Input
                    active
                    style={{ width: "66vw", marginBottom: 10 }}
                    size="large"
                  />
                </Row>
                <Skeleton.Button active shape="round" size="large" />
              </div>

              <Divider />

              <Row className="table">
                <Col
                  span={12}
                  lg={24}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Skeleton
                    active
                  />
                </Col>
              </Row>
            </div>
        </Col>
      </Row>
    </>
  );
}
