import React from 'react';
import { Line } from '@ant-design/charts';

const LineGraph = (props) => {
  var config = {
    data: props.data,
    padding: 'auto',
    xField: 'duration',
    yField: 'value',
    seriesField: 'category',
  };

  return (
    <>
  {props.data?.length > 0 ?<Line {...config} />:<div style = {{color:"#c4c4c4"}}>No data available for <span style = {{color:'#030D1D'}}>{props.dimensions}</span></div>}
  </>
  );
};

export default LineGraph;