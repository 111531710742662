import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Button,
  Card,
  Col,
  Typography,
  Row,
  Space,
  Alert,
  message,
} from "antd";
import { logout } from "store/actions/loginAction";
import Reading from "assets/Images/undraw_reading.svg";

const RequestPending = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const logOut = async () => {
    setLoading(true);
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        dispatch(logout());
        history.push("/signin");
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to log out");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (props.accountid) {
      logOut()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Card style={{ border: "none" }}>
      <Row justify="center">
        <Col span={24}>
          <Space
            direction="vertical"
            align="center"
            style={{ width: "100%", height: "100%", justifyContent: "center" }}
            size={5}
          >
            <Alert
              showIcon
              type="success"
              message="Your request has been submitted"
            />
            <Row justify="center">
              <Col xs={24} md={12}>
                <div style={{ width: "100%", margin: "10px 0px" }}>
                  <img
                    src={Reading}
                    alt="request access"
                    style={{ width: "100%", maxHeight: "275px" }}
                  />
                </div>
              </Col>
            </Row>
            <Typography.Title level={5} style={{ textAlign: "center", fontSize: "22px" }}>
              Pending approval for request!
            </Typography.Title>
            <Typography.Paragraph
              style={{
                fontSize: "18px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Your request to join team has been sent to account adminstrator.
              Once it is approved, you will receive an email to login to your
              account.
            </Typography.Paragraph>
          </Space>
        </Col>
        <Button
          size="middle"
          loading={loading}
          onClick={logOut}
          style={{
            fontSize: "18px",
            border: " #0F61DB 1px solid",
            color: "#0F61DB",
            borderRadius: "7px",
            padding: " 0px 20px",
          }}
        >
          Logout
        </Button>
      </Row>
    </Card>
  );
};

export default RequestPending;
