import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Typography,
  Row,
  Form,
  Input,
  Space,
  message,
  Select,
} from "antd";

import Powerful from "assets/Images/undraw_create_account.svg";
import sdk from "sdk/Accounts";
import Modal from "antd/lib/modal/Modal";
import { CloseOutlined } from "@ant-design/icons";
import { companySize, industries } from "utility/Constants";

const NewAccount = ({ expand, select, reset, fetchuser }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    let result = await sdk.createAccount(e.company, e.url, [],false, e.industry, e.company_size);
    if (result.error) {
      message.error(result.error, 5);
    } else {
      message.success(
        `Account created successfully`,
        5
      );
      await fetchuser();
      return;
    }
    setLoading(false);
    reset();
  };


  return (
    <>
      <Card style={{ border: "none" }}>
        <Row>
          <Col span={24} style={{ maxWidth: 400 }}>
            <Typography.Title level={4} style={{ textAlign: "center", fontSize: "22px" }}>
              Start New Account
            </Typography.Title>
            <Typography.Paragraph
              style={{
                fontWeight: "normal",
                fontSize: "16px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Start your free trial now, if no one at your company has set up an
              account with us.
            </Typography.Paragraph>

            <div style={{ width: "100%", margin: "20px 0px" }}>
              <img
                src={Powerful}
                alt="new account"
                style={{ width: "100%", maxHeight: "190px" }}
              />
            </div>

            <Button
              type="primary"
              size="large"
              block
              onClick={select}
              style={{ backgroundColor: "#303030", border: "none", borderRadius: "7px", fontSize: "18px", color: "#FFFFFF" }}
            >
              Next Step
            </Button>
          </Col>
        </Row>
      </Card>
      <Modal
        className="new-account-model"
        visible={expand}
        closable={true}
        footer={[]}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
            style={{ fontSize: 20, marginTop: 10 , color: "#ffffff", marginLeft: "60px",transform: "scale(1.5)"}}
            onClick={reset}
          />
          </strong>
          
        }
      >
        <Row justify="center">

          <Col span={20} lg={20} md={20} sm={24} xs={24}>
            {/* <Typography.Title level={5} style={{ textAlign: "center" }}>
              
            </Typography.Title> */}
            <Typography.Title level={4}>Create Company</Typography.Title>
            <Form
              labelCol={{ xs: 24 }}
              requiredMark={false}
              layout="vertical"
              name="new_account"
              className="login-form"
              onFinish={(e) => handleSubmit(e)}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Form.Item
                  name="company"
				  style={{
                  marginTop: "0px",
                }}
                label={<small><strong>Company name</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of your company",
                    },
                    {
                      pattern: /(^[a-zA-Z0-9]{1})([a-zA-Z0-9& .]+)$/,
                      message: "Please enter valid company name",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="company"
                    key="company"
                    name="company"
                    placeholder="Company Name"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>
                <Form.Item
                  name="url"
                  label={<small><strong>Website</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the URL of your company",
                    },
                    {
                      pattern:
                        /(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
                      message: "Please enter valid URL",
                    },
                  ]}
                >
                  <Input
                    size="middle"
                    id="url"
                    key="url"
                    name="url"
                    placeholder="Company Website"
                    disabled={loading}
                    tabIndex={1}
                  />
                </Form.Item>

                <Form.Item
                  name="industry"
                  label={<small><strong>Industry</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Industry",
                    },
                  ]}
                >
                  <Select placeholder="Select Company industry" showSearch size="large" style={{height:43}}>
                  {industries.map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="company_size"
                  label={<small><strong>Company size</strong></small>}
                  rules={[
                    {
                      required: true,
                      message: "Please Select company size",
                    },
                  ]}
                >
                  <Select placeholder="Select Company Size" showSearch size="large" style={{height:43}}>
                  {companySize.map(item => {
                    return <Select.Option value={item} key={item}>{item}</Select.Option>
                  })}
                  </Select>
                  
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    loading={loading}
                    tabIndex={5}
                  >
                    Get Started
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewAccount;
